import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ModalBanner from '../../../assets/modal-banner-1.png';
import { isAuthenticated } from "../../../auth";
import { getAllBranches, getBranchCount } from "../../../branch/helper/api";
import config from "../../../config";
import { getAllGym, getGymCount } from "../../../gym/helper/api";
import { getUserRole, startLoader, stopLoader } from "../../../lib/utils";
import { checkUserAvailability, createUser, updateUser } from "../../../user/helper/api";
import Toast from "../../../lib/toast";
import _ from 'lodash';

var typingTimer, typingBranchTimer;
var doneTypingInterval = 1000;

const ManageUserModal = (props) => {

  const history = useHistory();
  const location = useLocation();
  const [searchKeyGym,setSearchKeyGym] = useState('');
  const [isButtonLock, setButtonLock] = useState(false);
  const path = location.pathname;
  var [pageSize, setPageSize] = useState(9);
  const [branchSearchKey,setBranchSearchKey] = useState("");
  var [pageNo, setPageNo] = useState({
    gym: 1,
    branch: 1
  });
  var [pageCount, setPageCount] = useState({
    gym: 0,
    branch: 1
  });
  const [currentElementId, setCurrentElementId] = useState("");
  const [workoutGymTypeOpenDropdown, setWorkoutGymTypeOpenDropdown] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const [isUserEditEnabled, setUserEditEnabled] = useState(true);
  const [addNewUser, setAddNewUser] = useState({
    name: "",
    email: "",
    phone: "",
    gymID: "",
    branchID: "",
    role: -1
  });
  const [isGymListOpen, setGymListOpen] = useState(false);
  const [isBranchListOpen, setBranchListOpen] = useState(false);
  const [selectedGym, setSelectedGym] = useState('')
  var [total, setTotal] = useState({
    branch: 0,
    gym: 0
  });
  const [selectedBranch, setSelectedBranch] = useState('')
  const [foundUser, setFoundUser] = useState('');
  const [Gyms, setGyms] = useState([]);
  const [Branches, setBranches] = useState([]);
  const [isGymSpinnerLoader, setGymSpinnerLoader] = useState(false);
  const [isBranchSpinnerLoader, setBranchSpinnerLoader] = useState(false);
  const [selectedGymListID, setSelectedGymListID] = useState("");

  const { user, token } = isAuthenticated();

  const [selectedGymList, setSelectedGymList] = useState([]);
  //object strcut
  /*<Object>{
    ...[Gym Info],
    branchList:[{
      ...[Branch Info]
    }]
  }*/


  const handleSearchBranch = event =>{
    setBranchSearchKey(event.target.value);
  }

  const handleSearchKeyGym=event=>{
    setSearchKeyGym(event.target.value);
  }



  useEffect(()=>{
    checkDataField();
  },[addNewUser,selectedGymList]);


  const handleBranchSelect = (index, branch) => event => {
    event.preventDefault()
    setSelectedGymList(oldstate => {
      oldstate[index].branchList.push(branch)
      return [...oldstate];
    });

    event.stopPropagation()
  }

  const removeBranch = (gymIndex, branchIndex) => event => {
    event.preventDefault()
    setSelectedGymList(oldstate => {
      oldstate[gymIndex].branchList.splice(branchIndex, 1)
      return [...oldstate]
    })
    event.stopPropagation()
  }

  const removeGym = gym => event => {
    event.preventDefault()
    let list = [...selectedGymList];
    let findIndex = list.findIndex(d => d._id == gym._id);
    if (findIndex > -1) {
      list.splice(findIndex, 1);
    }
    setBranchSearchKey("");
    setSearchKeyGym("");
    setSelectedGymList(list);
    event.preventDefault()
  }


  const checkDataField = () => {

    let unlockField=true;

    if(addNewUser.role>0){
      unlockField=selectedGymList.length?true:false;
    }

    if(addNewUser.role>4){
      unlockField=unlockField && selectedGymList.every(d=>d.branchList && d.branchList.length);
    }

    if (addNewUser.name && addNewUser.phone && addNewUser.role!=-1 && unlockField) {
      setButtonLock(false)
    } else {
      setButtonLock(true);
    }
  }

  const selectRole = (role) => event => {
    event.preventDefault()
    setAddNewUser({
      ...addNewUser,
      role
    })
    setCurrentElementId("")
    event.stopPropagation()
  }

  const handleChange = (name, value) => event => {
    value = !value ? event.target.value : value;
    setAddNewUser({
      ...addNewUser,
      [name]: value
    });
  }

  const getGymParams = () => {
    let params = {
      pageNo: pageNo.gym,
      pageSize
    }

    params.activationStatus = "active";

    if (searchKeyGym) params.q = searchKeyGym;
    return params;
  }


  const GetAllGyms = async (isReload) => {
    try {
      let params = getGymParams();
      let response = await getAllGym(user._id, token, params);
      if(!response.error){
        if(user.role>0 && response && response.length){
          setGymListOpen(false);
          let gymObject = {
            ...response[0],
            branchList: []
          };
          let list = [...selectedGymList].filter(d=>d._id != gymObject._id);
          list.push(gymObject)
          setSelectedGymList(list);
          setSelectedGymListID(response[0]._id);
        }
        if(isReload) setGyms(response)
        else setGyms([...Gyms, ...response]);
      }
    } catch (error) {

    }
  }

  const GetAllGymCount = async () => {
    try {
      let params = getGymParams()
      delete params.pageNo;
      delete params.pageNo;
      let response = await getGymCount(user._id, token, params);
      pageCount.gym = Math.ceil(response.COUNT / pageSize);
      total.gym = response.COUNT;
      setPageCount({
        ...pageCount,
      })
      setTotal({
        ...total,
        gym: response.COUNT
      })
    } catch (error) {

    }
  }


  const getBranchParams = () => {
    let params = {
      pageNo: pageNo.branch,
      pageSize,
      activationStatus: "active"
    }

    if (selectedGymListID) {
      params.gymIds = [selectedGymListID]
    }
    if (branchSearchKey) params.q = branchSearchKey;



    return params;
  }



  const GetAllBranches = async (isReload) => {
    try {
      let params = getBranchParams();
      let response = await getAllBranches(user._id, token, params)
      if (!response.error){
        if(user.role>4 && response.length){
          const index=selectedGymList.findIndex(d=>d._id==selectedGymListID);
          if(index>-1){
            setSelectedGymList(oldstate => {
              oldstate[index].branchList.push(response[0])
              return [...oldstate];
            });
          }
        } 
        setBranches(isReload?response:[...Branches, ...response]);
      }
    } catch (error) {

    }
  }

  const GetAllBranchCount = async () => {
    try {
      let params = getBranchParams()
      delete params.pageNo;
      delete params.pageSize;
      let response = await getBranchCount(user._id, token, params);
      if (!response.error) {
        setPageCount({
          ...pageCount,
          branch: Math.ceil(pageSize / response.COUNT)
        })
        setTotal({
          ...total,
          branch: response.COUNT
        })
      }
    } catch (error) {

    }
  }




  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (id == 'workout-gym-type') {
      setWorkoutGymTypeOpenDropdown(!workoutGymTypeOpenDropdown)
    } else {
      if (currentElementId == id) {
        setCurrentElementId('')
      } else {
        setCurrentElementId(id)
      }
    }
  };

  useEffect(()=>{
    if(currentElementId){
      setTimeout(() => {
        let element = document.querySelector("#"+currentElementId);
        if (element) {
          element.focus();
        }
        }, 100);
    }
  },[currentElementId]);


  const CheckUserAvailability = async event => {
    event.preventDefault()
    try {
      if (!addNewUser.email) return "";
      await startLoader()
      let response = await checkUserAvailability({
        email: addNewUser.email
      });
      if (response) {
        setUserEditEnabled(true);
        if (response.gymList && response.gymList.length) {
          await Promise.all(response.gymList.map(async gym => {
            gym.branchList = await response.branchList.filter(d => d.gymId == gym._id);
          }))
          setSelectedGymList(response.gymList);
        }
        setFoundUser(response);

      } else {
        setUserEditEnabled(false)
        setFoundUser("")
        setAddNewUser(oldstate => {
          oldstate.role = -1;
          oldstate.name = "";
          oldstate.phone = "";
          return { ...oldstate }
        })
        if (response.gymList && response.gymList.length) setSelectedGymList(response.gymList)
      }
      toggleAddNewUser();
      await stopLoader()
    } catch (error) {
      await stopLoader()
    }
    event.stopPropagation()
  }

  const toggleAddNewUser = () => {
    if (isNewUser) {
      setNewUser(false)
    } else {
      setNewUser(true)
    }
  }

  const toggleGymList = async event => {
    event.preventDefault()
    if (isGymListOpen) {
      setGymListOpen(false)
    } else {
      setGymListOpen(true)
    }
    event.stopPropagation()
  }

  const toggleBranchList = gym => event => {
    event.preventDefault();
    if (selectedGymListID == gym._id) setSelectedGymListID('');
    else setSelectedGymListID(gym._id);
    event.preventDefault();
  }


  const handleSelectedGym = (gym) => async event => {
    event.preventDefault()
    try {
      setGymListOpen(false);
      let gymObject = {
        ...gym,
        branchList: []
      }
      setSelectedGymList([...selectedGymList, gymObject]);
    } catch (error) {
      //do something
    }
    event.stopPropagation()
  }



  const handleGymScroll = async event => {
    try {

      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight - 5)) && pageNo.gym < pageCount.gym && !isGymSpinnerLoader) {

        setGymSpinnerLoader(true)
        pageNo.gym = pageNo.gym + 1;
        setPageNo({
          ...pageNo
        })
        await GetAllGyms();
        setGymSpinnerLoader(false)
      }
    } catch (error) {
      setGymSpinnerLoader(false)
    }
  }

  const handleBranchScroll = async event => {
    try {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight - 5)) && pageNo.branch < pageCount.branch && !isGymSpinnerLoader) {
        setBranchSpinnerLoader(true)
        pageNo.branch = pageNo.branch + 1;
        setPageNo({
          ...pageNo
        })
        await GetAllBranches();
        setBranchSpinnerLoader(false)
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
    }
  }



  const reselectGym = () => {
    setBranchListOpen(false);
    setSelectedBranch("")
    setGymListOpen(true);
    setSelectedGym("")
  }

  const reselectBranch = () => {
    setBranchListOpen(true);
    setSelectedBranch({
      address: ""
    })
  }


  const SaveUser = async event => {
    event.preventDefault();
    try {
      await startLoader()
      setButtonLock(true)
      let payload = {
        name: addNewUser.name,
        email: addNewUser.email,
        phone: addNewUser.phone.substring(addNewUser.phone.length - 10,addNewUser.phone.length),
        role: addNewUser.role
      }
      payload.gymIDs = []
      payload.branchIDs = []
      if (addNewUser.role > 0) {
        await Promise.all(selectedGymList.map(async gym => {
          payload.gymIDs.push(gym._id);
          if (addNewUser.role > 4) {
            let branchIDs = await gym.branchList.map(branch => branch._id)
            payload.branchIDs = [...payload.branchIDs, ...branchIDs];
          }
        }))
      }
      //if (!payload.gymIDs.length) delete payload.gymIDs;
      //if (!payload.branchIDs.length) delete payload.branchIDs;
      const response = await updateUser(user._id, token, {
        userID: foundUser._id,
        UpdateBody: payload
      });
      setButtonLock(false);
      await stopLoader()
      if(response.error){
        new Toast({
          type:"error",
          message:response.error,
          duration:3000
        })
      }else{
        props.toggleAddUserModal();
        new Toast({
          type:"success",
          message:"User updated successfully",
          duration:3000
        })
        if (path == "/users") history.push(`/users`);
      }
    } catch (error) {
      await stopLoader()
      setButtonLock(false);
      new Toast({
        type:"error",
        message:"Server error, Unable to update user",
        duration:3000
      })
    }
  }

  const AddUser = async event => {
    event.preventDefault();
    try {
      await startLoader()
      setButtonLock(true);
      let payload = {
        name: addNewUser.name,
        email: addNewUser.email,
        phone: addNewUser.phone.substring(addNewUser.phone.length - 10,addNewUser.phone.length),
        role: addNewUser.role
      }
      payload.gymIDs = []
      payload.branchIDs = []
      if (addNewUser.role > 0) {
        await Promise.all(selectedGymList.map(async gym => {
          payload.gymIDs.push(gym._id);
          if (addNewUser.role > 4) {
            let branchIDs = await gym.branchList.map(branch => branch._id)
            payload.branchIDs = [...payload.branchIDs, ...branchIDs];
          }
        }))
      }
      if (!payload.gymIDs.length) delete payload.gymIDs;
      if (!payload.branchIDs.length) delete payload.branchIDs;
      const response = await createUser(user._id, token, payload);
      setButtonLock(false);
      await stopLoader();
      if(response.error){
        new Toast({
          type:"error",
          message:response.error,
          duration:3000
        })
      }else{
        new Toast({
          type:"success",
          message:"User created successfully",
          duration:3000
        })
        props.toggleAddUserModal();
        if (path == "/users") history.push(`/users`);
      }
    } catch (error) {
      // do something
      setButtonLock(false);
      stopLoader();
      new Toast({
        type:"error",
        message:"Server error, Unable to create user",
        duration:3000
      })
    }
  }


  useEffect(async () => {

    if (props.user && props.isEdit) {
      setUserEditEnabled(true);
      if (props.user.gymList && props.user.gymList.length) {
        await Promise.all(props.user.gymList.map(async gym => {
          gym.branchList = await props.user.branchList.filter(d => d.gymId == gym._id);
        }))
        setSelectedGymList(props.user.gymList);
      }
      setAddNewUser({
        ...addNewUser,
        role: props.user.role,
        name: props.user.name,
        email: props.user.email,
        phone: props.user.phone
      })
      setFoundUser(props.user);
      toggleAddNewUser()
      setButtonLock(false)
    } else {
      setUserEditEnabled(false);
      setAddNewUser({
        ...addNewUser,
        name: "",
        email: '',
        phone: ""
      })
      setButtonLock(true)
    }
  }, [props])

  useEffect(async ()=>{
    try {
      setGymSpinnerLoader(true)
      setBranches([]);
      setPageNo({
        ...pageNo,
        gym: 1,
        branch:1
      });
      setSelectedGym("");
      await GetAllGymCount();
      await GetAllGyms(true);
      setGymSpinnerLoader(false)
    } catch (error) {
      // do something
      setGymSpinnerLoader(false)
    }
  },[searchKeyGym]);

  useEffect(async () => {
    try {
      if (selectedGymListID) {
        setBranchSpinnerLoader(true);
        setPageNo({
          ...pageNo,
          branch: 1,
        });
        await GetAllBranchCount()
        await GetAllBranches(true);
        setBranchSpinnerLoader(false);
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
    }
  }, [selectedGymListID,branchSearchKey])

  return (
    <div className="global-modal-container d-flex">
      <div className="gym-modal-add d-flex flex-item">
        <div className="creative-pannel" style={{ background: "#171D31" }}>
          <svg style={{ position: "absolute", left: 0, top: 0, margin: "20px 0 0 20px" }} width="47" height="56" viewBox="0 0 47 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.1706 26.2887C46.4572 27.0669 46.4572 28.9332 45.1706 29.7113L3.78508 54.7431C2.45216 55.5493 0.75 54.5895 0.75 53.0318L0.75 2.96822C0.75 1.41046 2.45216 0.450704 3.78508 1.25691L45.1706 26.2887Z" fill="#FFE600" />
          </svg>
          <svg style={{ position: "absolute", left: 0, top: 0, margin: "80px 0 0 50px" }} width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.87058 13.2165C0.572487 12.4403 0.572485 10.5597 1.87058 9.78348L16.9736 0.752344C18.3067 -0.0448074 20 0.915611 20 2.46886V20.5311C20 22.0844 18.3067 23.0448 16.9736 22.2477L1.87058 13.2165Z" fill="#FFE600" />
          </svg>
          <svg style={{ position: "absolute", right: 0, top: 0, margin: "20px 20px 0 0" }} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="19" fill="#C1451E" />
          </svg>
          <svg style={{ position: "absolute", right: 0, top: 0, margin: "20px 70px 0 0" }} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.5" cy="10.5" r="10.5" fill="#C1451E" />
          </svg>
          <svg style={{ position: "absolute", right: 0, top: 0, margin: "55px 60px 0 0" }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5.5" cy="5.5" r="5.5" fill="#C1451E" />
          </svg>
          <svg style={{ position: "absolute", right: 0, top: 0, margin: "140px 20px 0 0" }} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9101 6.43645C15.1848 7.23389 15.1567 9.09998 13.8585 9.8587L3.88408 15.6884C2.53918 16.4745 0.851661 15.4892 0.87511 13.9316L1.05511 1.97455C1.07856 0.416959 2.79498 -0.51707 4.11561 0.309107L13.9101 6.43645Z" fill="#FF9900" />
          </svg>
          <svg style={{ position: "absolute", right: 0, top: 0, margin: "170px 20px 0 0" }} width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.1842 0.716468C24.516 0.00158505 26.1289 0.965474 26.1302 2.47696L26.1504 26.6794C26.1517 28.2331 24.4582 29.1949 23.1244 28.3978L1.77995 15.6424C0.446201 14.8454 0.490986 12.8983 1.85997 12.1634L23.1842 0.716468Z" fill="#FF9900" />
          </svg>
          <svg style={{ position: "absolute", left: 0, top: 0, margin: "210px 0 0 20px" }} width="24" height="94" viewBox="0 0 24 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 93.0607C11.5251 93.6464 12.4749 93.6464 13.0607 93.0607L22.6066 83.5147C23.1924 82.9289 23.1924 81.9792 22.6066 81.3934C22.0208 80.8076 21.0711 80.8076 20.4853 81.3934L12 89.8787L3.51472 81.3934C2.92893 80.8076 1.97918 80.8076 1.39339 81.3934C0.807608 81.9792 0.807608 82.9289 1.39339 83.5147L10.9393 93.0607ZM10.5 2L10.5 92L13.5 92L13.5 2L10.5 2Z" fill="#FFD0D0" />
          </svg>
          <svg style={{ position: "absolute", left: 0, top: 0, margin: "180px 0 0 40px" }} width="23" height="64" viewBox="0 0 23 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 62.0317C10 62.8602 10.6716 63.5317 11.5 63.5317C12.3284 63.5317 13 62.8602 13 62.0317H10ZM12.5607 0.971074C11.9749 0.385288 11.0251 0.385288 10.4393 0.971074L0.893398 10.517C0.307612 11.1028 0.307612 12.0525 0.893398 12.6383C1.47918 13.2241 2.42893 13.2241 3.01472 12.6383L11.5 4.15305L19.9853 12.6383C20.5711 13.2241 21.5208 13.2241 22.1066 12.6383C22.6924 12.0525 22.6924 11.1028 22.1066 10.517L12.5607 0.971074ZM13 62.0317L13 2.03173H10L10 62.0317H13Z" fill="#FFD0D0" />
          </svg>
          <img src={ModalBanner} />
        </div>
        <div className="data-input-pannel">
          <div className="d-flex w-full space-between global-modal-header-container">
            <p className="flex-item modal-header-text">{isUserEditEnabled ? "Edit" : "Add"} User</p>
            <div onClick={props.toggleAddUserModal.bind(this)} className="d-flex desktop-modal-close-button">
              <svg className="flex-item" width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.9283 3.80866C4.77899 3.66445 4.57901 3.58465 4.37144 3.58646C4.16387 3.58826 3.96531 3.67152 3.81853 3.8183C3.67175 3.96508 3.58849 4.16364 3.58668 4.37121C3.58488 4.57879 3.66468 4.77876 3.80888 4.92807L8.38076 9.49995L3.80809 14.0718C3.73248 14.1449 3.67217 14.2322 3.63068 14.3288C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7395 3.58555 14.8437 3.62536 14.941C3.66516 15.0383 3.72395 15.1267 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4338 4.36495 15.4329C4.47007 15.432 4.57395 15.4101 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1912L9.50018 10.6194L14.0721 15.1912C14.2214 15.3354 14.4213 15.4152 14.6289 15.4134C14.8365 15.4116 15.035 15.3284 15.1818 15.1816C15.3286 15.0348 15.4119 14.8363 15.4137 14.6287C15.4155 14.4211 15.3357 14.2211 15.1915 14.0718L10.6196 9.49995L15.1915 4.92807C15.3357 4.77876 15.4155 4.57879 15.4137 4.37121C15.4119 4.16364 15.3286 3.96508 15.1818 3.8183C15.035 3.67152 14.8365 3.58826 14.6289 3.58646C14.4213 3.58465 14.2214 3.66445 14.0721 3.80866L9.50018 8.38053L4.9283 3.80786V3.80866Z" fill="black" />
              </svg>
            </div>
          </div>
          <div className="global-modal-scroll-container">
            <div className="w-full">
              {
                (isUserEditEnabled && foundUser) && (
                  <div className="w-full">
                    <div className="d-flex w-full m-b-16">
                      {
                        foundUser.profileID?(
                          <div className="d-flex branch-gym-logo m-r-8" style={{ background: "#f5f5f5" }}>
                          <img className="flex-item" src={`${config.API_URL}/image/file/${foundUser._id}/auto/auto/auto`} loading="lazy" />
                        </div>
                        ):(
                          <div className="d-flex branch-gym-logo m-r-8" style={{ background: foundUser.profilePlaceHolderColor?foundUser.profilePlaceHolderColor:"#5d883c" }}>
                            <p className="flex-item" style={{
                              color:"#ffffff",
                              fontSize:12,
                              margin:'0 auto'
                            }}>{`${foundUser.name.trim()[0]}`.toUpperCase()}</p>
                          </div>
                        )
                      }
                      <div className="flex-item">
                        <p className="branch-gym-name">{foundUser.name}</p>
                        <p className="branch-gym-email">{getUserRole(foundUser.role)}</p>
                      </div>
                      {
                        !props.isEdit && <svg onClick={toggleAddNewUser.bind(this)} className="m-l-12 flex-item cursor-pointer" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.1678 8C20.389 6.21646 19.1068 4.69884 17.4784 3.63311C15.8499 2.56738 13.946 1.99983 11.9998 2C6.8148 2 2.5498 5.947 2.0498 11" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M16.9999 8H21.3999C21.4787 8 21.5567 7.98448 21.6295 7.95433C21.7023 7.92417 21.7684 7.87998 21.8241 7.82426C21.8798 7.76855 21.924 7.70241 21.9542 7.62961C21.9843 7.55681 21.9999 7.47879 21.9999 7.4V3M2.88086 16C4.42486 19.532 7.94886 22 12.0489 22C17.2349 22 21.4989 18.053 21.9999 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M7.0498 16H2.6498C2.49067 16 2.33806 16.0632 2.22554 16.1757C2.11302 16.2883 2.0498 16.4409 2.0498 16.6V21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      }
                    </div>
                    <div className="d-flex w-full m-b-8">
                      <div className="d-flex" style={{ width: "20%" }}>
                        <svg className="flex-item m-r-6" width="12" height="9" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.75 0H0.75C0.551088 0 0.360322 0.0790176 0.21967 0.21967C0.0790176 0.360322 0 0.551088 0 0.75V6.75C0 6.94891 0.0790176 7.13968 0.21967 7.28033C0.360322 7.42098 0.551088 7.5 0.75 7.5H9.75C9.94891 7.5 10.1397 7.42098 10.2803 7.28033C10.421 7.13968 10.5 6.94891 10.5 6.75V0.75C10.5 0.551088 10.421 0.360322 10.2803 0.21967C10.1397 0.0790176 9.94891 0 9.75 0V0ZM8.925 0.75L5.25 3.2925L1.575 0.75H8.925ZM0.75 6.75V1.09125L5.03625 4.0575C5.09902 4.10105 5.1736 4.12438 5.25 4.12438C5.3264 4.12438 5.40098 4.10105 5.46375 4.0575L9.75 1.09125V6.75H0.75Z" fill="#055075" />
                        </svg>
                        <p className="flex-item branch-gym-details-heading">Email</p>
                      </div>
                      <p className="branch-gym-details-value">{foundUser.email}</p>
                    </div>
                    <div className="d-flex w-full m-b-30">
                      <div className="d-flex" style={{ width: "20%" }}>
                        <svg className="flex-item m-r-6" width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.74967 9.875H8.68592C1.31717 9.45125 0.270916 3.23375 0.124666 1.33625C0.112881 1.18872 0.130324 1.04031 0.175994 0.899529C0.221664 0.758749 0.294663 0.628364 0.39081 0.515845C0.486956 0.403325 0.60436 0.310883 0.736295 0.243815C0.868229 0.176747 1.0121 0.136371 1.15967 0.125H3.22592C3.37612 0.124855 3.52292 0.169816 3.64728 0.254061C3.77164 0.338306 3.86784 0.457951 3.92342 0.5975L4.49342 2C4.5483 2.13633 4.56192 2.28578 4.53258 2.42978C4.50325 2.57379 4.43225 2.706 4.32842 2.81L3.52967 3.61625C3.65443 4.32528 3.99398 4.97888 4.50238 5.48861C5.01077 5.99834 5.66347 6.33961 6.37217 6.46625L7.18592 5.66C7.29148 5.55731 7.42494 5.48797 7.56965 5.46063C7.71436 5.43329 7.86391 5.44915 7.99967 5.50625L9.41342 6.0725C9.55086 6.12983 9.66813 6.22678 9.75027 6.351C9.83241 6.47522 9.87572 6.62108 9.87467 6.77V8.75C9.87467 9.04837 9.75614 9.33452 9.54516 9.5455C9.33418 9.75647 9.04803 9.875 8.74967 9.875ZM1.24967 0.875C1.15021 0.875 1.05483 0.914509 0.984501 0.984835C0.914174 1.05516 0.874666 1.15054 0.874666 1.25V1.28C1.04717 3.5 2.15342 8.75 8.72717 9.125C8.77643 9.12804 8.82581 9.12133 8.87248 9.10525C8.91914 9.08917 8.96218 9.06403 8.99911 9.03128C9.03604 8.99854 9.06614 8.95882 9.08768 8.91441C9.10923 8.87 9.1218 8.82178 9.12467 8.7725V6.77L7.71092 6.20375L6.63467 7.2725L6.45467 7.25C3.19217 6.84125 2.74967 3.57875 2.74967 3.545L2.72717 3.365L3.79217 2.28875L3.22967 0.875H1.24967Z" fill="#055075" />
                        </svg>
                        <p className="flex-item branch-gym-details-heading">Phone</p>
                      </div>
                      <p className="branch-gym-details-value">+91 {foundUser.phone}</p>
                    </div>
                  </div>
                )
              }

              <div className="w-full">
                <div onClick={toggleInputFields('user-name')} className={`${currentElementId == "user-name" || addNewUser.name ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "user-name" || addNewUser.name ? "1px solid #055075" : "" }}>
                  <div className="flex-item d-flex">
                    <svg className="flex-item m-r-8" width={currentElementId == "user-name" || addNewUser.name ? "12" : "18"} height={currentElementId == "user-name" || addNewUser.name ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 5.15C4.9528 5.15 4.1 4.2972 4.1 3.25C4.1 2.2028 4.9528 1.35 6 1.35C7.0472 1.35 7.9 2.2028 7.9 3.25C7.9 4.2972 7.0472 5.15 6 5.15ZM10.15 10V10.15H10H1.85V10C1.85 8.26377 3.26333 6.85 5 6.85H7C8.7362 6.85 10.15 8.2638 10.15 10Z" stroke={currentElementId == "user-name" || addNewUser.name ? "#055075" : "#6d6d6d"} stroke-width="0.7" />
                    </svg>
                    <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId == "user-name" || addNewUser.name ? "10px" : "14px", color: currentElementId == "user-name" || addNewUser.name ? "#055075" : "", transition: "180ms" }}>Name*</p>
                  </div>
                  <input id="user-name" onChange={handleChange('name')} value={addNewUser.name} className={`${currentElementId == "user-name" || addNewUser.name ? "active-input-field" : "inactive-input-field"}`} type="text" />
                </div>
                <div onClick={toggleInputFields('user-phone')} className={`${currentElementId == "user-phone" || addNewUser.phone ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "user-phone" || addNewUser.phone ? "1px solid #055075" : "" }}>
                  <div className="flex-item d-flex">
                    <svg className="flex-item m-r-12" width={currentElementId == "user-phone" || addNewUser.phone ? "12" : "16"} height={currentElementId == "user-phone" || addNewUser.phone ? "12" : "16"} style={{ transition: "180ms" }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.74967 9.875H8.68592C1.31717 9.45125 0.270916 3.23375 0.124666 1.33625C0.112881 1.18872 0.130324 1.04031 0.175994 0.899529C0.221664 0.758749 0.294663 0.628364 0.39081 0.515845C0.486956 0.403325 0.60436 0.310883 0.736295 0.243815C0.868229 0.176747 1.0121 0.136371 1.15967 0.125H3.22592C3.37612 0.124855 3.52292 0.169816 3.64728 0.254061C3.77164 0.338306 3.86784 0.457951 3.92342 0.5975L4.49342 2C4.5483 2.13633 4.56192 2.28578 4.53258 2.42978C4.50325 2.57379 4.43225 2.706 4.32842 2.81L3.52967 3.61625C3.65443 4.32528 3.99398 4.97888 4.50238 5.48861C5.01077 5.99834 5.66347 6.33961 6.37217 6.46625L7.18592 5.66C7.29148 5.55731 7.42494 5.48797 7.56965 5.46063C7.71436 5.43329 7.86391 5.44915 7.99967 5.50625L9.41342 6.0725C9.55086 6.12983 9.66813 6.22678 9.75027 6.351C9.83241 6.47522 9.87572 6.62108 9.87467 6.77V8.75C9.87467 9.04837 9.75614 9.33452 9.54516 9.5455C9.33418 9.75647 9.04803 9.875 8.74967 9.875ZM1.24967 0.875C1.15021 0.875 1.05483 0.914509 0.984501 0.984835C0.914174 1.05516 0.874666 1.15054 0.874666 1.25V1.28C1.04717 3.5 2.15342 8.75 8.72717 9.125C8.77643 9.12805 8.82581 9.12133 8.87248 9.10525C8.91914 9.08917 8.96218 9.06403 8.99911 9.03128C9.03604 8.99854 9.06614 8.95882 9.08768 8.91441C9.10923 8.87 9.1218 8.82178 9.12467 8.7725V6.77L7.71092 6.20375L6.63467 7.2725L6.45467 7.25C3.19217 6.84125 2.74967 3.57875 2.74967 3.545L2.72717 3.365L3.79217 2.28875L3.22967 0.875H1.24967Z" fill={currentElementId == "user-phone" || addNewUser.phone ? "#055075" : "#6d6d6d"} />
                    </svg>
                    <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId == "user-phone" || addNewUser.phone ? "10px" : "14px", color: currentElementId == "user-phone" || addNewUser.phone ? "#055075" : "", transition: "180ms" }}>Phone*</p>
                  </div>
                  <input id="user-phone" onChange={handleChange('phone')} value={addNewUser.phone} className={`${currentElementId == "user-phone" || addNewUser.phone ? "active-input-field" : "inactive-input-field"}`} type="number" />
                </div>
                <div onClick={toggleInputFields('user-email')} className={`${currentElementId == "user-email" || addNewUser.email ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "user-email" || addNewUser.email ? "1px solid #055075" : "" }}>
                  <div className="flex-item d-flex">
                  <svg className="flex-item m-r-8" width={currentElementId == "user-email" || addNewUser.email ? "12" : "18"} height={currentElementId == "user-email" || addNewUser.email ? "12" : "18"}  style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.32234 2.3999H10.2723C11.0223 2.3999 11.4003 2.7539 11.4003 3.4739V8.5259C11.4003 9.2399 11.0223 9.5999 10.2723 9.5999H2.32234C1.57234 9.5999 1.19434 9.2399 1.19434 8.5259V3.4739C1.19434 2.7539 1.57234 2.3999 2.32234 2.3999ZM6.29434 7.5599L10.3383 4.2419C10.4823 4.1219 10.5963 3.8459 10.4163 3.5999C10.2423 3.3539 9.92434 3.3479 9.71434 3.4979L6.29434 5.8139L2.88034 3.4979C2.67034 3.3479 2.35234 3.3539 2.17834 3.5999C1.99834 3.8459 2.11234 4.1219 2.25634 4.2419L6.29434 7.5599Z"  stroke={currentElementId == "user-email" || addNewUser.email ? "#055075" : "#6d6d6d"} />
                  </svg>
                    
                    <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId == "user-email" || addNewUser.name ? "10px" : "14px", color: currentElementId == "user-email" || addNewUser.email ? "#055075" : "", transition: "180ms" }}>Email (Optional)</p>
                  </div>
                  <input id="user-email" onChange={handleChange('email')} value={addNewUser.email} className={`${currentElementId == "user-email" || addNewUser.email ? "active-input-field" : "inactive-input-field"}`} type="text" />
                </div>
                <div onClick={toggleInputFields('user-role')} className={`${(currentElementId == "user-role" || addNewUser.role > -1) ? "member-global-input-field m-b-30 flex-item" : "member-global-input-field m-b-30 flex-item d-flex"}`} style={{ border: (currentElementId == "user-role" || addNewUser.role > -1) ? "1px solid #055075" : "" }}>
                  <div className="flex-item d-flex">
                    <svg className="flex-item m-r-8" width={(currentElementId == "user-role" || addNewUser.role > -1) ? "18" : "18"} height={(currentElementId == "user-role" || addNewUser.role > -1) ? "18" : "18"} style={{ transition: "180ms" }} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.73333 1H3.1C3.00717 1 2.91815 1.03687 2.85251 1.10251C2.78687 1.16815 2.75 1.25717 2.75 1.35V6.48333C2.75 6.5293 2.75905 6.57481 2.77664 6.61727C2.79423 6.65974 2.82001 6.69832 2.85251 6.73082C2.88501 6.76332 2.9236 6.7891 2.96606 6.80669C3.00852 6.82428 3.05404 6.83333 3.1 6.83333H4.73333C4.7793 6.83333 4.82481 6.82428 4.86727 6.80669C4.90974 6.7891 4.94832 6.76332 4.98082 6.73082C5.01332 6.69832 5.0391 6.65974 5.05669 6.61727C5.07428 6.57481 5.08333 6.5293 5.08333 6.48333V1.35C5.08333 1.25717 5.04646 1.16815 4.98082 1.10251C4.91518 1.03687 4.82616 1 4.73333 1V1ZM11.7333 1H10.1C10.0072 1 9.91815 1.03687 9.85251 1.10251C9.78687 1.16815 9.75 1.25717 9.75 1.35V6.48333C9.75 6.5293 9.75905 6.57481 9.77664 6.61727C9.79423 6.65974 9.82001 6.69832 9.85251 6.73082C9.88501 6.76332 9.9236 6.7891 9.96606 6.80669C10.0085 6.82428 10.054 6.83333 10.1 6.83333H11.7333C11.7793 6.83333 11.8248 6.82428 11.8673 6.80669C11.9097 6.7891 11.9483 6.76332 11.9808 6.73082C12.0133 6.69832 12.0391 6.65974 12.0567 6.61727C12.0743 6.57481 12.0833 6.5293 12.0833 6.48333V1.35C12.0833 1.25717 12.0465 1.16815 11.9808 1.10251C11.9152 1.03687 11.8262 1 11.7333 1V1Z" stroke={(currentElementId == "workout-gym-type" || addNewUser.role > -1) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M5.08333 3.91675H9.75M1 5.31675V2.51675C1 2.42392 1.03687 2.3349 1.10251 2.26926C1.16815 2.20362 1.25717 2.16675 1.35 2.16675H2.4C2.49283 2.16675 2.58185 2.20362 2.64749 2.26926C2.71313 2.3349 2.75 2.42392 2.75 2.51675V5.31675C2.75 5.40957 2.71313 5.4986 2.64749 5.56424C2.58185 5.62987 2.49283 5.66675 2.4 5.66675H1.35C1.25717 5.66675 1.16815 5.62987 1.10251 5.56424C1.03687 5.4986 1 5.40957 1 5.31675V5.31675ZM13.8333 5.31675V2.51675C13.8333 2.42392 13.7965 2.3349 13.7308 2.26926C13.6652 2.20362 13.5762 2.16675 13.4833 2.16675H12.4333C12.3405 2.16675 12.2515 2.20362 12.1858 2.26926C12.1202 2.3349 12.0833 2.42392 12.0833 2.51675V5.31675C12.0833 5.40957 12.1202 5.4986 12.1858 5.56424C12.2515 5.62987 12.3405 5.66675 12.4333 5.66675H13.4833C13.5762 5.66675 13.6652 5.62987 13.7308 5.56424C13.7965 5.4986 13.8333 5.40957 13.8333 5.31675V5.31675Z" stroke={(currentElementId == "workout-gym-type" || addNewUser.role > -1) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "user-role" || addNewUser.role > -1) ? "10px" : "14px", color: (currentElementId == "user-role" || addNewUser.role > -1) ? "#055075" : "", transition: "180ms" }}>User Role*</p>
                  </div>
                  <div className='input-field-arrow-dropdown' style={{ margin: "12px 8px 4px 0", transition: "180ms" }}>
                    <svg className="flex-item" style={{ transform: workoutGymTypeOpenDropdown ? "rotate(180deg)" : "", transition: "180ms" }} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round" />
                    </svg>
                  </div>
                  <input id="user-role" className={`${(currentElementId == "user-role" || addNewUser.role > -1) ? "active-input-field" : "inactive-input-field"}`} type="text" placeholder="Select User Role" value={addNewUser.role > -1 ? getUserRole(addNewUser.role) : ""} style={{ pointerEvents: "none", textTransform: 'capitalize' }} />
                  {
                    currentElementId == "user-role" && (
                      <div className="global-input-field-arrow-dropdown global-input-field-arrow-dropdown-height-1">
                        {
                          [0, 1, 2, 3, 4, 5, 6, 7].map(role => {
                            if (user.role == 2 ? role != 0 && role != 1 && role != 2 && role != 3 && role != 4 && role != 6 : user.role == 5 ? role != 0 && role != 1 && role != 2 && role != 3 && role != 4 && role != 6 : role != 1 && role != 3 && role != 4 && role != 6) {
                              return (
                                <div onClick={selectRole(role)} className="global-input-field-arrow-dropdown-item">
                                  <p className="flex-item">{getUserRole(role)}</p>
                                </div>
                              )
                            }
                          })
                        }
                        {/* <div className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">Commercial</p>
                            </div>
                            <div className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">Residencial</p>
                            </div>
                            <div className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">Corporate</p>
                            </div> */}
                      </div>
                    )
                  }
                </div>
              </div>

            </div>
            {(addNewUser.role != NaN && addNewUser.role > 0) && [
              <div className="d-flex w-full m-b-14 m-l-6">
                <svg className="flex-item m-r-8" width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.04078 1H4.96078C4.78574 1 4.61786 1.06954 4.49409 1.19331C4.37032 1.31708 4.30078 1.48496 4.30078 1.66V11.34C4.30078 11.4267 4.31785 11.5125 4.35102 11.5926C4.38419 11.6726 4.4328 11.7454 4.49409 11.8067C4.55538 11.868 4.62814 11.9166 4.70821 11.9498C4.78829 11.9829 4.87411 12 4.96078 12H8.04078C8.12745 12 8.21328 11.9829 8.29335 11.9498C8.37343 11.9166 8.44618 11.868 8.50747 11.8067C8.56876 11.7454 8.61737 11.6726 8.65054 11.5926C8.68371 11.5125 8.70078 11.4267 8.70078 11.34V1.66C8.70078 1.48496 8.63125 1.31708 8.50747 1.19331C8.3837 1.06954 8.21582 1 8.04078 1V1ZM21.2408 1H18.1608C17.9857 1 17.8179 1.06954 17.6941 1.19331C17.5703 1.31708 17.5008 1.48496 17.5008 1.66V11.34C17.5008 11.4267 17.5179 11.5125 17.551 11.5926C17.5842 11.6726 17.6328 11.7454 17.6941 11.8067C17.7554 11.868 17.8281 11.9166 17.9082 11.9498C17.9883 11.9829 18.0741 12 18.1608 12H21.2408C21.3275 12 21.4133 11.9829 21.4934 11.9498C21.5734 11.9166 21.6462 11.868 21.7075 11.8067C21.7688 11.7454 21.8174 11.6726 21.8505 11.5926C21.8837 11.5125 21.9008 11.4267 21.9008 11.34V1.66C21.9008 1.48496 21.8312 1.31708 21.7075 1.19331C21.5837 1.06954 21.4158 1 21.2408 1V1Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.7 6.5002H17.5M1 9.1402V3.8602C1 3.68515 1.06954 3.51728 1.19331 3.3935C1.31708 3.26973 1.48496 3.2002 1.66 3.2002H3.64C3.81504 3.2002 3.98292 3.26973 4.10669 3.3935C4.23046 3.51728 4.3 3.68515 4.3 3.8602V9.1402C4.3 9.31524 4.23046 9.48311 4.10669 9.60689C3.98292 9.73066 3.81504 9.8002 3.64 9.8002H1.66C1.48496 9.8002 1.31708 9.73066 1.19331 9.60689C1.06954 9.48311 1 9.31524 1 9.1402V9.1402ZM25.2 9.1402V3.8602C25.2 3.68515 25.1305 3.51728 25.0067 3.3935C24.8829 3.26973 24.715 3.2002 24.54 3.2002H22.56C22.385 3.2002 22.2171 3.26973 22.0933 3.3935C21.9695 3.51728 21.9 3.68515 21.9 3.8602V9.1402C21.9 9.31524 21.9695 9.48311 22.0933 9.60689C22.2171 9.73066 22.385 9.8002 22.56 9.8002H24.54C24.715 9.8002 24.8829 9.73066 25.0067 9.60689C25.1305 9.48311 25.2 9.31524 25.2 9.1402V9.1402Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="flex-item m-r-12 fs-16">Gym</p>
                {
                  (!selectedGym) ? (
                    <div onClick={toggleGymList.bind(this)} style={{ transform: isGymListOpen ? "rotate(45deg)" : "" }} className="flex-item d-flex cursor-pointer global-modal-icon">
                      <svg className="flex-item" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="6.50977" width="2" height="15" rx="1" fill="#9A9A9A" />
                        <rect x="15" y="6.4353" width="2" height="15" rx="1" transform="rotate(89.5058 15 6.4353)" fill="#9A9A9A" />
                      </svg>
                    </div>
                  ) : (
                    <svg onClick={reselectGym.bind(this)} className="flex-item cursor-pointer" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_2137_1490)">
                        <path d="M16.7581 6.3335C16.1416 4.92153 15.1265 3.72008 13.8373 2.87637C12.5481 2.03267 11.0408 1.58336 9.50013 1.5835C5.39534 1.5835 2.01888 4.7082 1.62305 8.7085" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.4588 6.33333H16.9421C17.0045 6.33333 17.0663 6.32105 17.1239 6.29718C17.1815 6.27331 17.2339 6.23832 17.278 6.19421C17.3221 6.1501 17.3571 6.09774 17.381 6.04011C17.4048 5.98248 17.4171 5.92071 17.4171 5.85833V2.375M2.28125 12.6667C3.50358 15.4628 6.29342 17.4167 9.53925 17.4167C13.6448 17.4167 17.0205 14.292 17.4171 10.2917" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.58138 12.667H2.09805C1.97207 12.667 1.85125 12.717 1.76217 12.8061C1.67309 12.8952 1.62305 13.016 1.62305 13.142V16.6253" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_2137_1490">
                          <rect width="19" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )
                }

              </div>,
              <div>
                {
                  (selectedGymList.length > 0 && !isGymListOpen) && (
                    selectedGymList.map((gym, index) => {
                      return (
                        <div className="selected-gym-container m-b-14">
                          <div className="w-full d-flex">
                            <div className="d-flex w-full" style={{ width: "90%" }}>
                              {
                                gym.logoFileID ? (
                                  <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}${config.prefixStream}/media/file/${gym.logoFileID}`} />
                                ) : (
                                  <div className="d-flex flex-item branch-gym-user-profile m-r-8" style={{
                                    backgroundColor: gym.logoPlaceholderColor ? gym.logoPlaceholderColor : "#5d883c"
                                  }}>
                                    <p className="flex-item" style={{
                                      color: "#ffffff",
                                      margin: '0 auto'
                                    }}>{`${gym.name}`.trim()[0]}</p>
                                  </div>
                                )
                              }
                              <div className="flex-item">
                                <p className="branch-gym-user-name m-b-2 m-t-4 fs-16" style={{ fontWeight: 'bold' }}>{gym.name}</p>
                                <p className="branch-gym-details-value">{gym.email}</p>
                              </div>
                            </div>
                            <div onClick={removeGym(gym)} className='bell-menu-container header-menu-active cursor-pointer d-flex'>
                              <svg className="bell flex-item" width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 10.5V11H28V10.5C28 9.43913 27.5786 8.42172 26.8284 7.67157C26.0783 6.92143 25.0609 6.5 24 6.5C22.9391 6.5 21.9217 6.92143 21.1716 7.67157C20.4214 8.42172 20 9.43913 20 10.5ZM17.5 11V10.5C17.5 8.77609 18.1848 7.12279 19.4038 5.90381C20.6228 4.68482 22.2761 4 24 4C25.7239 4 27.3772 4.68482 28.5962 5.90381C29.8152 7.12279 30.5 8.77609 30.5 10.5V11H41.75C42.0815 11 42.3995 11.1317 42.6339 11.3661C42.8683 11.6005 43 11.9185 43 12.25C43 12.5815 42.8683 12.8995 42.6339 13.1339C42.3995 13.3683 42.0815 13.5 41.75 13.5H38.833L36.833 37.356C36.681 39.1676 35.854 40.856 34.5158 42.0866C33.1776 43.3172 31.426 44.0001 29.608 44H18.392C16.5742 43.9998 14.8228 43.3168 13.4848 42.0863C12.1468 40.8557 11.3199 39.1674 11.168 37.356L9.168 13.5H6.25C5.91848 13.5 5.60054 13.3683 5.36612 13.1339C5.1317 12.8995 5 12.5815 5 12.25C5 11.9185 5.1317 11.6005 5.36612 11.3661C5.60054 11.1317 5.91848 11 6.25 11H17.5ZM21.5 20.25C21.5 20.0858 21.4677 19.9233 21.4049 19.7716C21.342 19.62 21.25 19.4822 21.1339 19.3661C21.0178 19.25 20.88 19.158 20.7284 19.0951C20.5767 19.0323 20.4142 19 20.25 19C20.0858 19 19.9233 19.0323 19.7716 19.0951C19.62 19.158 19.4822 19.25 19.3661 19.3661C19.25 19.4822 19.158 19.62 19.0951 19.7716C19.0323 19.9233 19 20.0858 19 20.25V34.75C19 34.9142 19.0323 35.0767 19.0951 35.2284C19.158 35.38 19.25 35.5178 19.3661 35.6339C19.4822 35.75 19.62 35.842 19.7716 35.9049C19.9233 35.9677 20.0858 36 20.25 36C20.4142 36 20.5767 35.9677 20.7284 35.9049C20.88 35.842 21.0178 35.75 21.1339 35.6339C21.25 35.5178 21.342 35.38 21.4049 35.2284C21.4677 35.0767 21.5 34.9142 21.5 34.75V20.25ZM27.75 19C27.06 19 26.5 19.56 26.5 20.25V34.75C26.5 35.0815 26.6317 35.3995 26.8661 35.6339C27.1005 35.8683 27.4185 36 27.75 36C28.0815 36 28.3995 35.8683 28.6339 35.6339C28.8683 35.3995 29 35.0815 29 34.75V20.25C29 19.56 28.44 19 27.75 19Z" fill="#2b2b2b" />
                              </svg>
                            </div>
                          </div>

                          {
                            (addNewUser.role > 4) && [
                              <div className="d-flex  m-t-20 m-b-12">
                                <p className="flex-item fs-16 m-r-12" style={{ fontWeight: 'bold' }}>Branch</p>

                                <div onClick={toggleBranchList(gym)} style={{ transform: selectedGymListID == gym._id ? "rotate(45deg)" : "" }} className="flex-item d-flex cursor-pointer global-modal-icon">
                                  <svg className="flex-item" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="6.50977" width="2" height="15" rx="1" fill="#9A9A9A" />
                                    <rect x="15" y="6.4353" width="2" height="15" rx="1" transform="rotate(89.5058 15 6.4353)" fill="#9A9A9A" />
                                  </svg>
                                </div>
                              </div>,
                              (selectedGymListID == gym._id) && (
                                <div className="w-full m-t-12 m-b-20" style={{ padding: "12px", borderRadius: "12px", background: "#f5f5f5" }}>
                                  <div className="d-flex w-full space-between m-b-12">
                                    <p className="flex-item fs-16 m-l-5">Branch's List</p>
                                    <div id="search-container" className='global-search-box m-r-12'>
                                      <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1637_1318)">
                                          <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1637_1318">
                                            <rect width="16" height="16" fill="white" />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      <input id="search-branch-input" className='flex-item' type="text" placeholder="Branch Address" onChange={_.debounce(handleSearchBranch,1000)} />
                                    </div>
                                  </div>
                                  <div className="flex-item w-full suggestion-list-container-gym-1" onScroll={handleBranchScroll}>
                                    {
                                      Branches.filter(d => gym.branchList.findIndex(d2 => d2._id == d._id) == -1).map(branch => {
                                        return (
                                          <div onClick={handleBranchSelect(index, branch)} className="d-flex suggestion-list-items">
                                            <div className="flex-item">
                                              <p className="branch-gym-user-name m-b-1">{branch.name}</p>
                                              <p className="branch-gym-details-value">{branch.address ? branch.address.placeName ? branch.address.placeName : branch.address.placeAddress ? branch.address.placeAddress : branch.address : ""}</p>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                    {isBranchSpinnerLoader && <div className="loader-5 center"><span></span></div>}


                                  </div>
                                </div>
                              ),
                              gym.branchList.map((branch, branchindex) => {
                                return (
                                  <div className="w-full m-b-12 selected-gym-container" style={{
                                    borderRadius: 6
                                  }}>
                                    <div className="d-flex cursor-pointer">
                                      <p className="fs-14 flex-item" style={{ width: "90%" }}>{branch.name}</p>
                                      <div onClick={removeBranch(index, branchindex)} className='bell-menu-container header-menu-active cursor-pointer d-flex' style={{
                                        boxShadow: 'none'
                                      }}>
                                        <svg className="bell flex-item" width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M20 10.5V11H28V10.5C28 9.43913 27.5786 8.42172 26.8284 7.67157C26.0783 6.92143 25.0609 6.5 24 6.5C22.9391 6.5 21.9217 6.92143 21.1716 7.67157C20.4214 8.42172 20 9.43913 20 10.5ZM17.5 11V10.5C17.5 8.77609 18.1848 7.12279 19.4038 5.90381C20.6228 4.68482 22.2761 4 24 4C25.7239 4 27.3772 4.68482 28.5962 5.90381C29.8152 7.12279 30.5 8.77609 30.5 10.5V11H41.75C42.0815 11 42.3995 11.1317 42.6339 11.3661C42.8683 11.6005 43 11.9185 43 12.25C43 12.5815 42.8683 12.8995 42.6339 13.1339C42.3995 13.3683 42.0815 13.5 41.75 13.5H38.833L36.833 37.356C36.681 39.1676 35.854 40.856 34.5158 42.0866C33.1776 43.3172 31.426 44.0001 29.608 44H18.392C16.5742 43.9998 14.8228 43.3168 13.4848 42.0863C12.1468 40.8557 11.3199 39.1674 11.168 37.356L9.168 13.5H6.25C5.91848 13.5 5.60054 13.3683 5.36612 13.1339C5.1317 12.8995 5 12.5815 5 12.25C5 11.9185 5.1317 11.6005 5.36612 11.3661C5.60054 11.1317 5.91848 11 6.25 11H17.5ZM21.5 20.25C21.5 20.0858 21.4677 19.9233 21.4049 19.7716C21.342 19.62 21.25 19.4822 21.1339 19.3661C21.0178 19.25 20.88 19.158 20.7284 19.0951C20.5767 19.0323 20.4142 19 20.25 19C20.0858 19 19.9233 19.0323 19.7716 19.0951C19.62 19.158 19.4822 19.25 19.3661 19.3661C19.25 19.4822 19.158 19.62 19.0951 19.7716C19.0323 19.9233 19 20.0858 19 20.25V34.75C19 34.9142 19.0323 35.0767 19.0951 35.2284C19.158 35.38 19.25 35.5178 19.3661 35.6339C19.4822 35.75 19.62 35.842 19.7716 35.9049C19.9233 35.9677 20.0858 36 20.25 36C20.4142 36 20.5767 35.9677 20.7284 35.9049C20.88 35.842 21.0178 35.75 21.1339 35.6339C21.25 35.5178 21.342 35.38 21.4049 35.2284C21.4677 35.0767 21.5 34.9142 21.5 34.75V20.25ZM27.75 19C27.06 19 26.5 19.56 26.5 20.25V34.75C26.5 35.0815 26.6317 35.3995 26.8661 35.6339C27.1005 35.8683 27.4185 36 27.75 36C28.0815 36 28.3995 35.8683 28.6339 35.6339C28.8683 35.3995 29 35.0815 29 34.75V20.25C29 19.56 28.44 19 27.75 19Z" fill="#2b2b2b" />
                                        </svg>
                                      </div>
                                      {/* <svg className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#2b2b2b" />
                                            </svg> */}
                                    </div>
                                  </div>
                                )
                              })
                            ]
                          }


                          {/* <div className="member-button-global btn-center m-t-12 d-flex" style={{ width: "200px" }}>
                            <p className="flex-item">{selectedGymListID ? "Close List" : "Add Branch"}</p>
                          </div> */}


                        </div>
                      )
                    })
                  )
                }
                {
                  ((selectedGym.length == selectedBranch.length) && selectedBranch.length > 1) && (
                    <div onClick={toggleGymList.bind(this)} className="member-button-global btn-center m-t-12 d-flex" style={{ width: "200px" }}>
                      <p className="flex-item">Add Gym</p>
                    </div>
                  )
                }
                {
                  isGymListOpen && [
                    <div className="w-full" style={{ padding: "12px", borderRadius: "12px", background: "#f5f5f5" }}>
                      <div className="d-flex w-full space-between m-b-12">
                        <p className="flex-item fs-16 m-l-5">Gym's List</p>
                        <div id="search-container" className='global-search-box m-r-12'>
                          <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1637_1318)">
                              <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1637_1318">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <input id="search-gym-input" className='flex-item' type="text" placeholder="Search Gym" onChange={_.debounce(handleSearchKeyGym,1000)} />
                        </div>
                      </div>
                      <div className="flex-item w-full suggestion-list-container-gym-1" onScroll={handleGymScroll}>
                        {
                          Gyms.filter(gym => selectedGymList.findIndex(d => d._id == gym._id) == -1).map(gym => {
                            return (
                              <div onClick={handleSelectedGym(gym)} className="d-flex suggestion-list-items">
                                {
                                  gym.logoFileID ? (
                                    <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}${config.prefixStream}/media/file/${gym.logoFileID}`} />
                                  ) : (
                                    <div className="d-flex flex-item branch-gym-user-profile m-r-8" style={{
                                      backgroundColor: gym.logoPlaceholderColor ? gym.logoPlaceholderColor : "#5d883c"
                                    }}>
                                      <p className="flex-item" style={{ margin: '0 auto', color: '#ffffff' }}>{`${gym.name}`.trim()[0]}</p>
                                    </div>
                                  )
                                }

                                <div className="flex-item">
                                  <p className="branch-gym-user-name m-b-1">{gym.name}</p>
                                  <p className="branch-gym-details-value">{gym.email}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                        {isGymSpinnerLoader && <div className="loader-5 center"><span></span></div>}
                      </div>
                    </div>
                  ]
                }
              </div>,
              // addNewUser.role > 4 && (
              //   <div>
              //     {
              //       selectedGym && (
              //         <div className="d-flex w-full m-t-30 m-l-6 m-b-14">
              //           <svg className="flex-item m-r-8" width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              //             <path d="M8.04078 1H4.96078C4.78574 1 4.61786 1.06954 4.49409 1.19331C4.37032 1.31708 4.30078 1.48496 4.30078 1.66V11.34C4.30078 11.4267 4.31785 11.5125 4.35102 11.5926C4.38419 11.6726 4.4328 11.7454 4.49409 11.8067C4.55538 11.868 4.62814 11.9166 4.70821 11.9498C4.78829 11.9829 4.87411 12 4.96078 12H8.04078C8.12745 12 8.21328 11.9829 8.29335 11.9498C8.37343 11.9166 8.44618 11.868 8.50747 11.8067C8.56876 11.7454 8.61737 11.6726 8.65054 11.5926C8.68371 11.5125 8.70078 11.4267 8.70078 11.34V1.66C8.70078 1.48496 8.63125 1.31708 8.50747 1.19331C8.3837 1.06954 8.21582 1 8.04078 1V1ZM21.2408 1H18.1608C17.9857 1 17.8179 1.06954 17.6941 1.19331C17.5703 1.31708 17.5008 1.48496 17.5008 1.66V11.34C17.5008 11.4267 17.5179 11.5125 17.551 11.5926C17.5842 11.6726 17.6328 11.7454 17.6941 11.8067C17.7554 11.868 17.8281 11.9166 17.9082 11.9498C17.9883 11.9829 18.0741 12 18.1608 12H21.2408C21.3275 12 21.4133 11.9829 21.4934 11.9498C21.5734 11.9166 21.6462 11.868 21.7075 11.8067C21.7688 11.7454 21.8174 11.6726 21.8505 11.5926C21.8837 11.5125 21.9008 11.4267 21.9008 11.34V1.66C21.9008 1.48496 21.8312 1.31708 21.7075 1.19331C21.5837 1.06954 21.4158 1 21.2408 1V1Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              //             <path d="M8.7 6.5002H17.5M1 9.1402V3.8602C1 3.68515 1.06954 3.51728 1.19331 3.3935C1.31708 3.26973 1.48496 3.2002 1.66 3.2002H3.64C3.81504 3.2002 3.98292 3.26973 4.10669 3.3935C4.23046 3.51728 4.3 3.68515 4.3 3.8602V9.1402C4.3 9.31524 4.23046 9.48311 4.10669 9.60689C3.98292 9.73066 3.81504 9.8002 3.64 9.8002H1.66C1.48496 9.8002 1.31708 9.73066 1.19331 9.60689C1.06954 9.48311 1 9.31524 1 9.1402V9.1402ZM25.2 9.1402V3.8602C25.2 3.68515 25.1305 3.51728 25.0067 3.3935C24.8829 3.26973 24.715 3.2002 24.54 3.2002H22.56C22.385 3.2002 22.2171 3.26973 22.0933 3.3935C21.9695 3.51728 21.9 3.68515 21.9 3.8602V9.1402C21.9 9.31524 21.9695 9.48311 22.0933 9.60689C22.2171 9.73066 22.385 9.8002 22.56 9.8002H24.54C24.715 9.8002 24.8829 9.73066 25.0067 9.60689C25.1305 9.48311 25.2 9.31524 25.2 9.1402V9.1402Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              //           </svg>
              //           <p className="flex-item m-r-12 fs-16">Branch</p>
              //           {
              //             !selectedBranch ? (
              //               <div onClick={toggleBranchList.bind(this)} style={{ transform: isBranchListOpen ? "rotate(45deg)" : "" }} className="flex-item d-flex cursor-pointer global-modal-icon">
              //                 <svg className="flex-item" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              //                   <rect x="6.50977" width="2" height="15" rx="1" fill="#9A9A9A" />
              //                   <rect x="15" y="6.4353" width="2" height="15" rx="1" transform="rotate(89.5058 15 6.4353)" fill="#9A9A9A" />
              //                 </svg>
              //               </div>
              //             ) : (
              //               <svg onClick={reselectBranch.bind(this)} className="flex-item cursor-pointer" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              //                 <g clip-path="url(#clip0_2137_1490)">
              //                   <path d="M16.7581 6.3335C16.1416 4.92153 15.1265 3.72008 13.8373 2.87637C12.5481 2.03267 11.0408 1.58336 9.50013 1.5835C5.39534 1.5835 2.01888 4.7082 1.62305 8.7085" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              //                   <path d="M13.4588 6.33333H16.9421C17.0045 6.33333 17.0663 6.32105 17.1239 6.29718C17.1815 6.27331 17.2339 6.23832 17.278 6.19421C17.3221 6.1501 17.3571 6.09774 17.381 6.04011C17.4048 5.98248 17.4171 5.92071 17.4171 5.85833V2.375M2.28125 12.6667C3.50358 15.4628 6.29342 17.4167 9.53925 17.4167C13.6448 17.4167 17.0205 14.292 17.4171 10.2917" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              //                   <path d="M5.58138 12.667H2.09805C1.97207 12.667 1.85125 12.717 1.76217 12.8061C1.67309 12.8952 1.62305 13.016 1.62305 13.142V16.6253" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              //                 </g>
              //                 <defs>
              //                   <clipPath id="clip0_2137_1490">
              //                     <rect width="19" height="19" fill="white" />
              //                   </clipPath>
              //                 </defs>
              //               </svg>
              //             )
              //           }
              //         </div>
              //       )
              //     }

              //     {
              //       (selectedBranch && !isBranchListOpen) && (
              //         <div className="d-flex m-b-8">
              //           <div className="flex-item">
              //             <p className="branch-gym-user-name m-b-1">{selectedBranch.name}</p>
              //             <p className="branch-gym-details-value">{selectedBranch.address}</p>
              //           </div>
              //         </div>
              //       )
              //     }

              //     {
              //       isBranchListOpen && (
              //         <div className="w-full m-t-20" style={{ padding: "12px", borderRadius: "12px", background: "#f5f5f5" }}>
              //           <div className="d-flex w-full space-between m-b-12">
              //             <p className="flex-item fs-16 m-l-5">Branch's List</p>
              //             <div id="search-container" className='global-search-box m-r-12'>
              //               <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              //                 <g clip-path="url(#clip0_1637_1318)">
              //                   <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
              //                 </g>
              //                 <defs>
              //                   <clipPath id="clip0_1637_1318">
              //                     <rect width="16" height="16" fill="white" />
              //                   </clipPath>
              //                 </defs>
              //               </svg>
              //               <input id="search-branch-input" className='flex-item' type="text" placeholder="Branch Address" />
              //             </div>
              //           </div>
              //           <div className="flex-item w-full suggestion-list-container-gym-1" onScroll={handleBranchScroll}>
              //             {
              //               Branches.map(branch => {
              //                 return (
              //                   <div onClick={handleSelectedBranch(branch)} className="d-flex suggestion-list-items">
              //                     <div className="flex-item">
              //                       <p className="branch-gym-user-name m-b-1">{branch.name}</p>
              //                       <p className="branch-gym-details-value">{branch.address ? branch.address.placeName ? branch.address.placeName : branch.address.placeAddress ? branch.address.placeAddress : branch.address : ""}</p>
              //                     </div>
              //                   </div>
              //                 )
              //               })
              //             }
              //             {isBranchSpinnerLoader && <div className="loader-5 center"><span></span></div>}


              //           </div>
              //         </div>
              //       )
              //     }
              //   </div>
              // )
            ]}
            <div>
              <div onClick={(isButtonLock) ? null : props.isEdit ? SaveUser : AddUser} className={`member-button-global btn-center d-flex ${(isButtonLock) ? "member-button-global-inactive" : ""}`} style={{ position: "absolute", bottom: 0, left: 0, right: 0, width: "94%", margin: "0 auto 12px auto" }}>
                <p className="flex-item">{props.isEdit ? "Edit" : "Add"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ManageUserModal;
