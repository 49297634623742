import { set } from 'lodash';
import React, { useState, useEffect } from 'react';
import { isAuthenticated } from '../../auth';
import Dashboard from '../../core/Dashboard';
import { startLoader, stopLoader } from '../../lib/utils';
import { getAllParameters, addParameter, updateParameter, getParameter, removeParameter } from './helper/api';
import ManageConfirmationModal from '../../components/Modal/ConfirmModal';
import ManageParameter from '../Modal/desktop/ManageParameter';
import { withRouter } from 'react-router-dom';


function Parameters(props) {

    // User Authentication
    const { user, token } = isAuthenticated();

    // Hooks call for getting Dashboard Info 
    const [flag] = useState(0);
    const [Parameter, setParameter] = useState({
        type: "",
        parameterId: "",
        name: "",
        remark: "",
        photo: "",
        formData: new FormData()
    })
    const [isParameterFilterOpen, setParameterFilterOpen] = useState(false);
    const [ParameterType, setParameterType] = useState('exerciseLevel');
    const [parameterList, setParameterList] = useState([])
    const [isDeleteConfirmParameterModal, setDeleteConfirmParameterModal] = useState(false);
    const [isAddParameterModalOpen, setAddParameterModalOpen] = useState(false);



    const handleParameterUpdate = parameter => event => {
        event.preventDefault();
        try {
            setParameter({
                ...Parameter,
                parameterId: parameter._id,
                name: parameter.name,
                type: parameter.type
            })
            toggleAddParameterModal();
        } catch (error) {
            // do something
        }
        event.stopPropagation();
    }

    const toggleAddParameterModal = () => {
        if (isAddParameterModalOpen) {
            setAddParameterModalOpen(false)
        } else {
            setAddParameterModalOpen(true)
        }
    }


    const handleDeleteAction = async event => {
        event.preventDefault();
        try {
            await startLoader()
            await removeParameter(user._id, token, Parameter.parameterId);
            handleDeleteConfirmModal();
            await GetParameters();
            await stopLoader();
        } catch (error) {
            // do something
            await stopLoader()
        }
        event.stopPropagation();
    }

    const handleDeleteConfirmModal = () => {
        if (isDeleteConfirmParameterModal) {
            setDeleteConfirmParameterModal(false);
            setParameter({
                ...Parameter,
                name: "",
                parameterId: ""
            })
        } else {
            setDeleteConfirmParameterModal(true);
        }
    }



    const getParams = () => {
        let params = {
            noLimit: "noLimit"
        };
        if (ParameterType) params.type = ParameterType;
        params.sortByInstafit = "sort";
        return params;
    }

    const GetParameters = async () => {
        try {
            let params = getParams();
            let response = await getAllParameters(user._id, token, params)
            setParameterList(response);
        } catch (error) {
            // do something
        }
    }


    const handleDeleteModal = parameter => event => {
        event.preventDefault();
        setParameter({
            ...Parameter,
            parameterId: parameter._id,
            name: parameter.name
        });
        handleDeleteConfirmModal()
        event.stopPropagation();
    }





    const handleParameterFilter = () => {
        if (isParameterFilterOpen) {
            setParameterFilterOpen(false)
        } else {
            setParameterFilterOpen(true)
        }
    }


    const handleParameterType = (ParameterType) => {
        setParameterType(ParameterType)
    }



    useEffect(async () => {
        try {
            await startLoader();
            await GetParameters();
            await stopLoader()
        } catch (error) {
            // do something
        }
    }, [props, ParameterType])

    useEffect(() => {


        let searchGymInputField = document.getElementById("search-parameter-input");
        let searchContainer = document.getElementById("search-container-parameter");
        let searchIcon = document.getElementById("search-icon-parameter");
        if (searchGymInputField) {
            searchGymInputField.onfocus = e => {
                searchContainer.style.outline = "1px solid #055075";
                searchContainer.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
                searchIcon.classList.add("active-fill-svg");
            }
            searchGymInputField.onblur = e => {
                searchContainer.style.outline = "none";
                searchContainer.style.boxShadow = "none";
                searchIcon.classList.remove("active-fill-svg");
            }
        }



    }, []);


    return (
        <Dashboard flag={flag} data={{ state: "none" }} navItemData={"Gym"}>

            <div className="header-bar" style={{ display: "none" }}>
                <div className="dashboard-name-container">
                    <div className="dashboard-name">Parameters</div>
                </div>
            </div>

            <div className="header-bar d-flex space-between">
                <div className='d-flex'>
                    <svg className='flex-item m-r-12' width="52" height="52" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.8375 9.73125L8.8125 10.7062C8.95 10.8437 9.125 10.9125 9.3375 10.9125C9.55 10.9125 9.725 10.8437 9.8625 10.7062L12 8.56875V9.01875C12 9.23125 12.072 9.40625 12.216 9.54375C12.3595 9.68125 12.5375 9.75 12.75 9.75C12.9625 9.75 13.1405 9.678 13.284 9.534C13.428 9.3905 13.5 9.2125 13.5 9V6.75C13.5 6.5375 13.428 6.35925 13.284 6.21525C13.1405 6.07175 12.9625 6 12.75 6H10.4813C10.2688 6 10.0937 6.07175 9.95625 6.21525C9.81875 6.35925 9.75 6.5375 9.75 6.75C9.75 6.9625 9.822 7.1405 9.966 7.284C10.1095 7.428 10.2875 7.5 10.5 7.5H10.9312L9.3375 9.1125L8.3625 8.1375C8.225 7.9875 8.05 7.9125 7.8375 7.9125C7.625 7.9125 7.45 7.9875 7.3125 8.1375L5.025 10.425C4.875 10.5625 4.8 10.7375 4.8 10.95C4.8 11.1625 4.875 11.3375 5.025 11.475C5.1625 11.625 5.3375 11.7 5.55 11.7C5.7625 11.7 5.9375 11.625 6.075 11.475L7.8375 9.73125ZM3.75 15.75C3.3375 15.75 2.98425 15.6033 2.69025 15.3098C2.39675 15.0158 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39675 2.98425 2.69025 2.69025C2.98425 2.39675 3.3375 2.25 3.75 2.25H14.25C14.6625 2.25 15.0158 2.39675 15.3098 2.69025C15.6033 2.98425 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6033 15.0158 15.3098 15.3098C15.0158 15.6033 14.6625 15.75 14.25 15.75H3.75ZM3.75 14.25H14.25V3.75H3.75V14.25ZM3.75 3.75V14.25V3.75Z" fill="#035075" />
                    </svg>
                    <div className='flex-item'>
                        <p className="dashboard-type-heading">Parameters Dashboard</p>
                        <p className='dashboard-route-txt'>Manage all your parameters here</p>
                    </div>
                </div>
                <div className='d-flex'>
                    <div id="search-container-parameter" className='global-search-box m-r-12'>
                        <svg id="search-icon-parameter" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1637_1318)">
                                <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1637_1318">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <input id="search-parameter-input" className='flex-item' type="text" placeholder="Search Parameter" />
                    </div>
                    <div className='flex-item' onClick={e => e.stopPropagation()}>
                        <div onClick={handleParameterFilter.bind(this)} className='global-round-btn d-flex m-r-12'>
                            <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
                            </svg>
                            {
                                isParameterFilterOpen && (
                                    <div onClick={e => e.stopPropagation()} className='gym-filter-popup'>
                                        <p className='filter-heading-text m-b-12'>Parameter Type</p>
                                        <div onClick={handleParameterType.bind(this, "exerciseLevel")} className='d-flex gym-filter-type w-full m-b-8'>
                                            <input type="radio" name="user-type" checked={ParameterType == "exerciseLevel"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Exercise Level</p>
                                        </div>
                                        <div onClick={handleParameterType.bind(this, "exerciseType")} className='d-flex w-full gym-filter-type m-b-8'>
                                            <input type="radio" name="user-type" checked={ParameterType == "exerciseType"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Exercise Type</p>
                                        </div>
                                        <div onClick={handleParameterType.bind(this, "targetMuscle")} className='d-flex w-full gym-filter-type m-b-8'>
                                            <input type="radio" name="user-type" checked={ParameterType == "targetMuscle"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Target Muscles</p>
                                        </div>
                                        <div onClick={handleParameterType.bind(this, "exerciseImage")} className='d-flex w-full gym-filter-type m-b-8'>
                                            <input type="radio" name="user-type" checked={ParameterType == "exerciseImage"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Exercise Image</p>
                                        </div>
                                        <div onClick={handleParameterType.bind(this, "exerciseName")} className='d-flex w-full gym-filter-type m-b-8'>
                                            <input type="radio" name="user-type" checked={ParameterType == "exerciseName"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Workout Type</p>
                                        </div>

                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='global-round-btn d-flex'>
                        <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
                        </svg>
                    </div>
                </div>
            </div>

            <div className='dashboard-data-saperator'></div>


            <div className="body-container">
                <p className='parameter-header'>{ParameterType == "exerciseImage" ? "Exercise Image" : ParameterType == "exerciseType" ? "Exercise Type" : ParameterType == "exerciseLevel" ? "Exercise Level" : ParameterType == "exerciseName" ? "Workout Type" : ParameterType == "targetMuscle" ? "Target Muscle" : ""}</p>
                <div className='flex-item d-flex-wrap p-l-12'>
                    {
                        parameterList.map((data, index) => {
                            return (

                                <div className={`global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12 ${Parameter.parameterId == data._id ? "global-button-container-active" : ""}`} >
                                    <div className='d-flex' style={{ background: "transparent" }}>
                                        {
                                            data.type == "exerciseType" ? (
                                                <div className='flex-item'>
                                                    <p className='flex-item'>{data.name}</p>
                                                    <p className='flex-item' style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>Unit- {data.unit}</p>
                                                </div>
                                            ) : <p className='flex-item'>{data.name}</p>
                                        }
                                        <span id={"edit-exLevel" + data._id} class="material-icons-round edit-icon flex-item m-l-20" role={`button`} onClick={handleParameterUpdate(data)}>edit</span>
                                        <span id={"delete-exLevel" + data._id} class="material-icons-round delete-icon flex-item" style={{ marginLeft: "2%" }} role={`button`} onClick={handleDeleteModal(data)}>delete</span>
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
            </div>


            {
                isDeleteConfirmParameterModal && <ManageConfirmationModal handleAction={handleDeleteAction} title={`Delete this parameter`} message="Are you sure you want to delete this parameter ?, Note: By deleteing this parameter some changes will occur in your current application setting" buttonText={`Remove`} handleClose={handleDeleteConfirmModal} />
            }

            {
                isAddParameterModalOpen && <ManageParameter isEdit={true} parameter={{ ...Parameter }} toggleAddParameterModal={toggleAddParameterModal} />
            }


        </Dashboard>
    )
}

export default withRouter(Parameters)