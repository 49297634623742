import { configureStore } from '@reduxjs/toolkit';
import actionMenu from './storeFeature/actionMenu';
import appCount from './storeFeature/appCount';
import userInfo from './storeFeature/userInfo';

export default configureStore({
  reducer: {
    isMenuAction:actionMenu,
    count:appCount,
    user:userInfo
  }
})