import React, { useState, useEffect } from 'react';
import { isAuthenticated } from '../../auth';
import Dashboard from '../../core/Dashboard';
import { useHistory, useParams } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import BlockIcon from "../../assets/block.svg";
import UpdateIcon from "../../assets/edit.svg";
import { changePlannerStatus, getAllFitnessTestPlanners, getAllWorkoutPlanners, getFitnessTestPlannerCount, getWorkoutPlannerCount } from './helper/api';
import { startLoader, stopLoader } from '../../lib/utils';
import ManageConfirmationModal from '../Modal/ConfirmModal';
import { getAllParameters } from '../Parameters/helper/api';
import { getAllGym, getGymCount } from '../../gym/helper/api';
import { getAllBranches, getBranchCount } from '../../branch/helper/api';
import config from '../../config';
import ManageWorkoutPlanner from '../Modal/desktop/ManageWorkoutPlanner';
import ManageFitnessPlanner from '../Modal/desktop/ManageFitnessPlanner';

const PlannerDashBoard = (props) => {
    // User Authentication
    const { user, token } = isAuthenticated();
    const history = useHistory();
    const params = useParams();
    const [selectedPlanner, setSelectedPlanner] = useState("");
    const [selectedGym, setSelectedGym] = useState('');
    const [flag, setFlag] = useState(0);
    const [isStatusChangeOPeration, setStatusChangeOPeration] = useState(false);
    const [isDataChangedTriggered, setDataChangedTriggered] = useState(false);
    const [ContentRole, setContentRole] = useState('All');
    const [sortByAccessRight,setSortByAccessRight]=useState('');
    const [pageSize, setPageSize] = useState({
        branch: 20,
        gym: 20,
        planner: 20
    });
    const [pageCount, setPageCount] = useState({
        branch: 1,
        gym: 1,
        planner: 1
    });
    var [pageNo, setPageNo] = useState({
        branch: 1,
        gym: 1,
        planner: 1
    })
    const [total, setTotal] = useState({
        gym: 0,
        branch: 0,
        planner: 0
    })
    const [isAllPlannerChecked, setAllPlannerChecked] = useState(false);
    const [workout, setPlanner] = useState('');
    const [isContentFilterListOpen, setContentFilterOpen] = useState(false);
    const [workoutGymTypeOpenDropdown, setWorkoutGymTypeOpenDropdown] = useState(false);
    const [currentElementId, setCurrentElementId] = useState("");
    const [isFilterModalOpen, setFilterModalOpen] = useState('');
    const [selectedPlannerLevel, setSelectedPlannerLevel] = useState(false);
    const [PlannerType, setPlannerType] = useState("Workout")
    const [Planners, setPlanners] = useState([])
    const [Gyms, setGyms] = useState([])
    const [selectedBranch, setSelectedBranch] = useState('');
    const [Branches, setBranches] = useState([])
    const [sortByPlannerFrequency, setSortPlannerByFrequency] = useState(-1);
    const [isPlannerActionDropdownOpenId, setPlannerActionDropdownOpenId] = useState("");
    const [selectedPlannerIDs, setSelectedPlannerIDs] = useState([]);
    const [isPlannersActive, setPlannersActive] = useState(true);
    const [isBulkStatusChangeAction, setBulkStatusChangeAction] = useState(false)
    const [isPlannersActivationModalOpen, setPlannersActivationModalOpen] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [Parameters, setParameters] = useState([]);
    const [sortByExerciseLevelIds, setSortByExerciseLevelIds] = useState([]);
    const [tempIDs, setTempIDs] = useState([]);
    const [isExerciseLevelPopupOpen, setExerciseLevelPopupOpen] = useState(false);
    const [gymSearchKey, setGymSearchKey] = useState('');
    var [branchSearchKey, setBranchSearchKey] = useState('');
    const [SelectedBranchIDs, setSelectedBranchIDs] = useState([]);
    const [isGymSpinnerLoader, setGymSpinnerLoader] = useState(false);
    const [isBrachSpinnerLoader, setBranchSpinnerLoader] = useState(false);
    const [isModalSortPopupOpen, setModalSortPopupOpen] = useState("");
    var [isSearchBranch, setSearchBranch] = useState(false);
    const [isConfirmCreatePlannerModal, setConfirmCreatePlannerModal] = useState(false);
    const [selectedMoreItem, setSelectedMoreItem] = useState("");
    const [selectedMoreItemID, setSelectedMoreItemID] = useState("");

    const handleContentRole = (contentRole) => {
        if (contentRole == "branch" && ContentRole == "gym" && selectedGym) {
            isSearchBranch = true;
            setSearchBranch(isSearchBranch);
            setBranches([])
        }
        setContentRole(contentRole);
    }

    const toggleConfirmCreatePlannerModal = () => {
        setConfirmCreatePlannerModal(!isConfirmCreatePlannerModal)
    }

    const handleClearBranch = () => {
        setSelectedBranchIDs([]);
    }

    const confirmBranchTempSelectionIDs = event => {
        setSelectedBranchIDs(tempIDs);
        setTempIDs([])
        handleModalSortPopupOpen();
    }


    const handleBranchScroll = async event => {
        try {
            const { scrollTop, clientHeight, scrollHeight } = event.target;
            if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.branch < pageCount.branch && !isBrachSpinnerLoader) {
                setBranchSpinnerLoader(true)
                pageNo.branch = pageNo.branch + 1;
                setPageNo({
                    ...pageNo
                });
                await GetAllBranches();
                setBranchSpinnerLoader(false)
            }
        } catch (error) {
            setBranchSpinnerLoader(false)
        }
    }

    const handleBranchSearch = event => {
        branchSearchKey = event.target.value;
        setBranchSearchKey(branchSearchKey);
        setBranches([])
        isSearchBranch = true
        setSearchBranch(isSearchBranch)
    }


    const handleClearGym = async () => {
        setSelectedGym("");
        setSelectedBranchIDs([])
    }

    const handleSelectGym = gym => event => {
        try {
            setSelectedGym(gym)
            isSearchBranch = true;
            setSearchBranch(isSearchBranch);
            setBranches([]);
            handleModalSortPopupOpen();
        } catch (error) {
            // do someting
        }
    }



    const handleGymScroll = async event => {
        try {
            const { scrollTop, clientHeight, scrollHeight } = event.target;
            if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.gym < pageCount.gym && !isGymSpinnerLoader) {
                setGymSpinnerLoader(true)
                pageNo.gym = pageNo.gym + 1;
                setPageNo({
                    ...pageNo
                });
                await GetAllGyms();
                setGymSpinnerLoader(false)
            }
        } catch (error) {
            setGymSpinnerLoader(false)
        }
    }

    const handleModalSortPopupOpen = (name = "") => {
        setModalSortPopupOpen(name);
        if (!name) setTempIDs([])
        if (name && name == "branch") setTempIDs(SelectedBranchIDs);
        if (name && name == "exercise-level") setTempIDs(sortByExerciseLevelIds);
    }


    const handleGymSearch = event => {
        setGymSearchKey(event.target.value);
    }



    const handleClearExerciseLevels = event => {
        setSortByExerciseLevelIds([]);
    }


    const addConfirmExerciseLevel = event => {
        setSortByExerciseLevelIds([...tempIDs]);
        handleModalSortPopupOpen()
        setDataChangedTriggered(false);
    }

    const handleTempIDs = id => event => {
        let list = [...tempIDs]
        let index = list.indexOf(id);
        if (index == -1) {
            list.push(id);
        } else {
            list.splice(index, 1)
        }
        setTempIDs(list);
        if (!isDataChangedTriggered) {
            setDataChangedTriggered(true)
        }
    }

    const handleSearch = event => {
        setSearchKey(event.target.value)
    }

    const handleAccessRight = accessRight =>{
        setSortByAccessRight(accessRight);
    }

    const handleBulkStatusChnageAction = event => {
        event.preventDefault();
        setPlannersActivationModalOpen(!isPlannersActivationModalOpen)
        setBulkStatusChangeAction(true);
        event.stopPropagation();
    }

    const handlePlannerActivationModalOpen = () => {
        setPlannersActivationModalOpen(!isPlannersActivationModalOpen)
        setBulkStatusChangeAction(false);
    }

    const toggleTableMoreItems = (moreItem, moreItemID) => {
        if (selectedMoreItem == moreItem) {
            setSelectedMoreItem('');
            setSelectedMoreItemID('');
        } else {
            setSelectedMoreItem(moreItem);
            setSelectedMoreItemID(moreItemID);
        }
    }

    const handleAction = async event => {
        event.preventDefault();
        try {
            await startLoader();
            let status = (isBulkStatusChangeAction ? isPlannersActive : (!selectedPlanner.deletedAt || selectedPlanner.deletedAt == null)) ? "deactivate" : "activate";
            let plannerIDs = isBulkStatusChangeAction ? selectedPlannerIDs: [selectedPlanner._id];
            await changePlannerStatus(user._id, token, status, { plannerIDs });
            handlePlannerActivationModalOpen();
            setBulkStatusChangeAction(false);
            setAllPlannerChecked(false);
            setSelectedPlannerIDs([]);
            setStatusChangeOPeration(!isStatusChangeOPeration);
            await stopLoader();
        } catch (error) {
            await stopLoader();
        }
        event.stopPropagation()
    }


    const prev = async () => {
        try {
            if (pageNo.planner <= pageCount.planner && pageNo.planner != 1) {
                pageNo.planner = pageNo.planner - 1;
                setPageNo({
                    ...pageNo
                });
                await startLoader()
                await GetWorkoutPlannerCount();
                await GetWorkoutPlanners();
                await stopLoader()
            }
        } catch (error) {
            await stopLoader()
        }
    };
    const next = async () => {
        try {
            if (pageNo.planner < pageCount.planner) {
                pageNo.planner = pageNo.planner + 1;  
                setPageNo({
                    ...pageNo
                });            
                await startLoader()
                await GetWorkoutPlannerCount();
                await GetWorkoutPlanners();
                await stopLoader()
            }
        } catch (error) {
            await stopLoader()
        }
    };


    const toggleActiveInactiveButton = () => {
        if (isPlannersActive) {
            setPlannersActive(false);
        } else {
            setPlannersActive(true);
        }
    }


    const handlePlannerIDs = id => event => {
        let list = [...selectedPlannerIDs];
        let index = list.indexOf(id);
        if (index == -1) {
            list.push(id);
        } else {
            list.splice(index, 1);
        }
        setSelectedPlannerIDs(list)
    }

    const togglePlannerActionDropdown = (planner) => event => {
        event.preventDefault()
        let plannerId = planner._id;
        if (isPlannerActionDropdownOpenId == planner._id) plannerId = "";
        else setSelectedPlanner(planner);
        setPlannerActionDropdownOpenId(plannerId);
        event.stopPropagation()
    }


    //API start metod
    const GetGymParams = () => {
        let params = {
            pageNo: pageNo.gym,
            pageSize: pageSize.gym
        };

        if (gymSearchKey) params.q = gymSearchKey;


        return params;
    }
    const GetAllGyms = async () => {
        try {
            let params = GetGymParams()
            let responses = await getAllGym(user._id, token, params);
            let list = [];

            if (!responses.error) {
                list = responses;
            }

            setGyms([...Gyms, ...list])

        } catch (error) {
            // do something
        }
    }
    const GetGymCount = async () => {
        try {
            let params = GetGymParams();
            delete params.pageNo;
            delete params.pageSize;
            let response = await getGymCount(user._id, token, params);
            if (!response.error) {
                pageNo.gym = 1;
                setPageNo({
                    ...pageNo
                })
                setPageCount({
                    ...pageCount,
                    gym: 1
                })
                setTotal({
                    ...total,
                    gym: 0
                })
            } else {
                pageNo.gym = 1;
                setPageNo({
                    ...pageNo
                })
                setPageCount({
                    ...pageCount,
                    gym: Math.ceil(response.COUNT / pageSize.gym)
                })
                setTotal({
                    ...total,
                    gym: response.COUNT
                })
            }
        } catch (error) {
            // do someting
        }
    }
    const GetBranchParams = () => {
        let params = {
            pageNo: pageNo.branch,
            pageSize: pageSize.branch
        };
        if (selectedGym) params.gymIds = [selectedGym._id];
        if (branchSearchKey) params.q = branchSearchKey;
        return params;
    }
    const GetAllBranches = async () => {
        try {
            let params = GetBranchParams();
            let responses = await getAllBranches(user._id, token, params);
            let list = [];
            if (!responses.error) {
                list = responses;
            }
            setBranches([...Branches, ...list]);
        } catch (error) {
            //do someting
        }
    }
    const userAccessRight=(planner)=>{
        let isDisabled=true;
        if(user.role==0) isDisabled =false;
        else if(user.role>0 && user.role<3 && planner.gymId && user.gymIDs.indexOf(planner.gymId)!=-1){
            isDisabled=false
        }else if(user.role>2 && user.role<5 && planner.createdBy==user._id){
            isDisabled=false;
        }else if(user.role==5 && planner.branchId && user.branchIDs.indexOf(planner.branchId)!=-1) {
            isDisabled=false;
        }else if(user.role>5 && planner.createdBy==user._id){
            isDisabled=false
        }
        return isDisabled;
    }
    const GetBranchCount = async () => {
        let params = GetBranchParams()
        let response = await getBranchCount(user._id, token, params);
        if (response.error) {
            pageNo.branch = 1;
            setPageNo({
                ...pageNo
            })
            setPageCount({
                ...pageCount,
                branch: 1
            })
            setTotal({
                ...total,
                branch: 0
            })
        } else {
            pageNo.branch = 1;
            setPageNo({
                ...pageNo
            })
            setPageCount({
                ...pageCount,
                branch: Math.ceil(response.COUNT / pageSize.branch)
            })
            setTotal({
                ...total,
                branch: response.COUNT
            })
        }
    }
    const GetAllParameters = async () => {
        try {
            let params = {
                noLimit: 'noLimit',
                sortByInstafit: 'sort',
                type: "exerciseLevel"
            }
            let response = await getAllParameters(user._id, token, params);
            setParameters(response);
        } catch (error) {
            // do something
        }
    }
    //workout planner
    const GetWorkoutPlannerParams = () => {
        let params = {
            pageNo: pageNo.planner,
            pageSize: pageSize.planner
        };
        if (searchKey) params.q = searchKey;
        if(PlannerType == "OneDayPlanner") params.isOneDayPlanner=true;
        if (sortByExerciseLevelIds.length) params.exerciseLevelIDs = sortByExerciseLevelIds;
        params.activationStatus = isPlannersActive ? "activate" : "deactivate";
        if (sortByPlannerFrequency > -1) params.plannerFrequency = sortByPlannerFrequency;
        if (selectedGym){
            params.gymIDs = selectedGym._id
        }
        if (SelectedBranchIDs.length){
            params.branchIDs = SelectedBranchIDs
        }
        // (user.gymIDs[0]) params.gymId = user.gymIDs[0];
        if (ContentRole != "All") params.contentRole = ContentRole;
        if(sortByAccessRight) params.accessRight=sortByAccessRight;

        return params;
    }
    const GetWorkoutPlanners = async () => {
        try {
            await startLoader();
            let params = GetWorkoutPlannerParams();
            let responses = await getAllWorkoutPlanners(user._id, token, params);
            if (!responses.error) {
                await Promise.all(responses.map(async response => {
                    if (response.warmup && response.warmup.length > 0) {
                        await Promise.all(response.warmup.map(async exercise => {
                            let content = await response.warmupMainContent.find(d => d._id == exercise.contentId);
                            if (content) {
                                exercise.content = {
                                    ...exercise,
                                    ...content
                                }
                            } else {
                                exercise.content = {}
                            }
                            if (exercise.optionalExercises && exercise.optionalExercises.length > 0) {
                                exercise.optionalExercises = await Promise.all(exercise.optionalExercises.map(async opEx => {
                                    let optionalContent = await response.warmupOptionalContent.find(d => d._id == opEx.contentId);
                                    if (optionalContent) {
                                        opEx = {
                                            ...opEx,
                                            ...optionalContent
                                        }
                                    }

                                    return opEx;
                                }))
                            }
                        }))
                    }

                    if (response.cooldown && response.cooldown.length > 0) {
                        await Promise.all(response.cooldown.map(async exercise => {
                            let content = await response.cooldownMainContent.find(d => d._id == exercise.contentId);
                            if (content) {
                                exercise.content = {
                                    ...exercise,
                                    ...content
                                }
                            } else {
                                exercise.content = {}
                            }

                    

                            if (exercise.optionalExercises && exercise.optionalExercises.length > 0) {
                                exercise.optionalExercises = await Promise.all(exercise.optionalExercises.map(async opEx => {
                                    let optionalContent = await response.cooldownOptionalContent.find(d => d._id == opEx.contentId);
                                    if (optionalContent) {
                                        opEx = {
                                            ...opEx,
                                            ...optionalContent
                                        }
                                    }
                                    return opEx;
                                }))
                            }
                        }))
                    }

                    if (response.workout && response.workout.length > 0) {
                        await Promise.all(response.workout.map(async exercise => {
                            let content = await response.workoutMainContent.find(d => d._id == exercise.contentId);
                            if (content) {
                                exercise.content = {
                                    ...content
                                }
                            } else {
                                exercise.content = {}
                            }
                            if (!exercise.parameter) exercise.parameter = {};
                            if (exercise.optionalExercises && exercise.optionalExercises.length > 0) {
                                await Promise.all(exercise.optionalExercises.map(async opEx => {
                                    let opContent = await response.workoutOptionalContent.find(d => d._id == opEx.contentId);
                                    if (opContent) {
                                        opEx.content = {
                                            ...opContent
                                        }
                                    }
                                    if (!opEx.parameter) opEx.parameter = {};
                                }))
                            }
                        }))
                    }

                    if (response.weekdays && response.weekdays.length > 0) {
                        await Promise.all(response.weekdays.map(async weekday => {
                            if (weekday.exercises && weekday.exercises.length > 0) {
                                await Promise.all(weekday.exercises.map(async exercise => {
                                    let content = await response.daysMainContent.find(d => d._id == exercise.contentId);
                                    if (content) {
                                        exercise.content = {
                                            ...content
                                        }
                                    } else {
                                        exercise.content = {}
                                    }
                                    if (!exercise.parameter) exercise.parameter = {};
                                    if (exercise.optionalExercises && exercise.optionalExercises.length > 0) {
                                        await Promise.all(exercise.optionalExercises.map(async opEx => {
                                            let opContent = await response.daysOptionalContent.find(d => d._id == opEx.contentId);
                                            if (opContent) {
                                                opEx.content = {
                                                    ...opContent
                                                }
                                            }
                                            if (!opEx.parameter) opEx.parameter = {};
                                        }))
                                    }
                                }))
                            }
                        }))
                    }


                }));
                setPlanners(responses)
            };
        } catch (error) {
            // do something
        }
    }
    const GetWorkoutPlannerCount = async () => {
        try {
            let params = GetWorkoutPlannerParams();
            delete params.pageSize;
            delete params.pageNo;
            let response = await getWorkoutPlannerCount(user._id, token, params);
            if (!response.error) {
                setTotal({
                    ...total,
                    planner: response.COUNT
                })
                let plannerPageCount=Math.ceil(response.COUNT / pageSize.planner)
                setPageCount({
                    ...pageCount,
                    planner: plannerPageCount
                })
            }
        } catch (error) {
            // do something
        }
    }
    //fitness planner
    const GetFitnessTestParam= () =>{
        let params={
            pageNo: pageNo.planner,
            pageSize: pageSize.planner
        };
        if (searchKey) params.q = searchKey;
        params.activationStatus = isPlannersActive ? "activate" : "deactivate";
        if (selectedGym){
            params.gymIDs = selectedGym._id
        }
        if (SelectedBranchIDs.length){
            params.branchIDs = SelectedBranchIDs
        }
        if (ContentRole != "All") params.contentRole = ContentRole;
        if(sortByAccessRight) params.accessRight=sortByAccessRight;

        return params;
    }
    const GetFitnessTestPlannersCount=async ()=>{
        try{
            let params=GetFitnessTestParam();
            delete params.pageNo;
            delete params.pageSize;
            let response=await getFitnessTestPlannerCount(user._id,token,params);
            if (!response.error) {
                pageNo.planner = 1;
                setPageNo({
                    ...pageNo
                });
                setTotal({
                    ...total,
                    planner: response.COUNT
                })
                setPageCount({
                    ...pageCount,
                    planner: Math.celi(response.COUNT / pageSize.planner)
                })
            }
        }catch(error){
            // do something
        }
    }
    const GetAllFitnessTestPlanners=async ()=>{
        try{
            let params=GetFitnessTestParam();
            let responses = await getAllFitnessTestPlanners(user._id,token,params);
            if (!responses.error) {
                await Promise.all(responses.map(async response => {
                   await Promise.all(response.exercises.map(async exercise=>{
                    if(exercise.selectType == "additional" && exercise.contentId){
                        let content=await response.contents.find(d=>d._id == exercise.contentId);
                        if(content) exercise.content={
                            ...content
                        };
                    }
                   }))
                }));
                if(responses.length && selectedPlanner){
                    let findPlanner = await responses.find(d=>d._id == selectedPlanner._id);
                    if(findPlanner){
                        setSelectedPlanner(findPlanner)
                    }
                }
                setPlanners(responses)
            };
            
        }catch(error){
            // do something to handle the error
        }
    }
    // end of metod


    const onKeyDownArrageInputValue = event => {
        if (event.target.value != "" && event.target.value < 0) event.target.value = 0;
        else if (event.target.value != "" && event.target.value > 7) event.target.value = 7;
        setSortPlannerByFrequency(!event.target.value ? -1 : event.target.value)
    }

    const toggleFitnessPlannerView = (currentTarget) => {
        let currentTargetValue = currentTarget.split('-')[1];
        if (document.getElementById("view-" + currentTargetValue).style.display == "none") {
            document.getElementById("view-" + currentTargetValue).style.display = "block";
        } else {
            document.getElementById("view-" + currentTargetValue).style.display = "none";
        }
    }

    const checkAllPlanner = () => {
        let plannerIDs=Planners.filter(d=>!userAccessRight(d)).map(d=>d._id);

        setSelectedPlannerIDs(oldstate=>{

            let filerPlannerIDs=oldstate.filter(id=>plannerIDs.indexOf(id)!=-1);

            if(filerPlannerIDs.length==plannerIDs.length){
                plannerIDs.forEach(id=>{
                    if(oldstate.indexOf(id)!=-1){
                        oldstate.splice(oldstate.indexOf(id),1);
                    }
                })
            }else{
                plannerIDs.forEach(id=>{
                    if(oldstate.indexOf(id)==-1){
                        oldstate.push(id);
                    }
                })
            }

            return [
                ...oldstate
            ]
        })
        // if (isAllPlannerChecked) {
        //     setAllPlannerChecked(false)
        // } else {
        //     setAllPlannerChecked(true)
        // }
    }

    const toggleContentFilterOpen = () => {
        if (isContentFilterListOpen) {
            setContentFilterOpen(false)
        } else {
            setContentFilterOpen(true)
        }
    }

    const handlePlannerType = (PlannerType) => {
        setPlannerType(PlannerType)
    }

    const toggleInputFields = (id) => event => {
        setCurrentElementId(id);
        if (id == 'workout-gym-type') setWorkoutGymTypeOpenDropdown(!workoutGymTypeOpenDropdown);
        if (event) {
            let interval = setInterval(() => {
                let element = event.target.querySelector('input');
                if (element) {
                    element.focus();
                    clearInterval(interval)
                }
            }, 0);
        }
    }

    const handleFilterModalOpen = (filterType) => {
        if (isFilterModalOpen) {
            setFilterModalOpen('')
        } else {
            setContentFilterOpen(false);
            setFilterModalOpen(filterType)
            if (filterType == "planner-level") { setTempIDs([]); }
        }
    }


    useEffect(async () => {
        try {
            await GetGymCount();
            await GetAllGyms();
        } catch (error) {
            //do something
        }
    }, [gymSearchKey])

    useEffect(async () => {
        try {
            if (isSearchBranch && Branches.length == 0 && selectedGym) {
                setBranchSpinnerLoader(true);
                isSearchBranch = false;
                setSearchBranch(isSearchBranch);
                await GetBranchCount();
                await GetAllBranches();
                setBranchSpinnerLoader(false);
            }
        } catch (Error) {
            // do something
            setBranchSpinnerLoader(false);
        }
    }, [isSearchBranch, Branches, selectedGym])

    useEffect(async () => {
        try {
            await startLoader();
            pageNo.planner = 1;
            setPageNo({ 
                ...pageNo
            });
            if (PlannerType == "Workout" || PlannerType == "OneDayPlanner") {
                await GetWorkoutPlannerCount()
                await GetWorkoutPlanners();
            } else {
                await GetFitnessTestPlannersCount()
                await GetAllFitnessTestPlanners()
            }
            await stopLoader();
        } catch (error) {
            // do something
            await stopLoader()
        }
    }, [props,sortByAccessRight,sortByExerciseLevelIds ,ContentRole, searchKey, sortByPlannerFrequency, PlannerType, isPlannersActive, isStatusChangeOPeration, selectedGym, SelectedBranchIDs]);

    useEffect(async () => {

        let searchGymInputField = document.getElementById("search-planner-input");
        let searchContainer = document.getElementById("search-container-planner");
        let searchIcon = document.getElementById("search-icon-planner");
        if (searchGymInputField) {
            searchGymInputField.onfocus = e => {
                searchContainer.style.outline = "1px solid #055075";
                searchContainer.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
                searchIcon.classList.add("active-fill-svg");
            }
            searchGymInputField.onblur = e => {
                searchContainer.style.outline = "none";
                searchContainer.style.boxShadow = "none";
                searchIcon.classList.remove("active-fill-svg");
            }
        }

        window.addEventListener('click', () => {
            setCurrentElementId('');
        })


        try {
            await GetAllParameters()
        } catch (error) {
            // do something
        }

    }, [])

    return (
        <Dashboard flag={flag} >


            <div className="header-bar d-flex space-between">
                <div className='d-flex'>
                    <svg className='flex-item m-r-12 m-t-4' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1931_1335)">
                            <path d="M23.625 18.375C23.625 17.6788 23.9016 17.0111 24.3938 16.5188C24.8861 16.0266 25.5538 15.75 26.25 15.75H39.375V21H26.25C25.5538 21 24.8861 20.7234 24.3938 20.2312C23.9016 19.7389 23.625 19.0712 23.625 18.375ZM2.625 23.625H13.125C13.8212 23.625 14.4889 23.9016 14.9812 24.3938C15.4734 24.8861 15.75 25.5538 15.75 26.25C15.75 26.9462 15.4734 27.6139 14.9812 28.1062C14.4889 28.5984 13.8212 28.875 13.125 28.875H2.625V23.625Z" fill="#055075" />
                            <path d="M9.1875 0C9.5356 0 9.86944 0.138281 10.1156 0.384422C10.3617 0.630564 10.5 0.964403 10.5 1.3125V2.625H31.5V1.3125C31.5 0.964403 31.6383 0.630564 31.8844 0.384422C32.1306 0.138281 32.4644 0 32.8125 0C33.1606 0 33.4944 0.138281 33.7406 0.384422C33.9867 0.630564 34.125 0.964403 34.125 1.3125V2.625H36.75C38.1424 2.625 39.4777 3.17812 40.4623 4.16269C41.4469 5.14726 42 6.48261 42 7.875V36.75C42 38.1424 41.4469 39.4777 40.4623 40.4623C39.4777 41.4469 38.1424 42 36.75 42H5.25C3.85761 42 2.52226 41.4469 1.53769 40.4623C0.553124 39.4777 0 38.1424 0 36.75V7.875C0 6.48261 0.553124 5.14726 1.53769 4.16269C2.52226 3.17812 3.85761 2.625 5.25 2.625H7.875V1.3125C7.875 0.964403 8.01328 0.630564 8.25942 0.384422C8.50556 0.138281 8.8394 0 9.1875 0V0ZM2.625 10.5V36.75C2.625 37.4462 2.90156 38.1139 3.39384 38.6062C3.88613 39.0984 4.55381 39.375 5.25 39.375H36.75C37.4462 39.375 38.1139 39.0984 38.6062 38.6062C39.0984 38.1139 39.375 37.4462 39.375 36.75V10.5H2.625Z" fill="#055075" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1931_1335">
                                <rect width="42" height="42" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className='flex-item'>
                        <p className="dashboard-type-heading flex-item">Planner</p>
                        <div className='d-flex m-t-2'>
                            <p className='dashboard-route-txt flex-item m-r-6'>Gym Dashboard</p>
                            <svg className='m-r-6 flex-item' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 1L6 6.5L0.5 11.5" stroke="#6D6D6D" stroke-linecap="round" />
                            </svg>
                            <p className='dashboard-route-txt-active flex-item m-r-6'>Planners</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex'>
                    <div id="search-container-planner" className='global-search-box m-r-12'>
                        <svg id="search-icon-planner" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1637_1318)">
                                <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1637_1318">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <input onChange={_.debounce(handleSearch, 1000)} id="search-planner-input" className='flex-item' type="text" placeholder="Search" />
                    </div>
                    <div style={{position:'relative'}} className='m-r-12'>
                        <div onClick={toggleContentFilterOpen.bind(this)} className='global-round-btn d-flex'>
                            <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
                            </svg>
                        </div>
                        {
                            isContentFilterListOpen && (
                                <div onClick={e => e.stopPropagation()} className='gym-filter-popup' style={{ width: "310px" }}>
                                    <p className='filter-heading-text m-b-12'>Status</p>
                                    <div className='d-flex m-b-20'>
                                        <div onClick={toggleActiveInactiveButton.bind(this)} className='flex-item d-flex active-inactive-button m-r-12' style={{ background: !isPlannersActive ? "rgba(255, 0, 85, 0.347)" : "" }}>
                                            <div className='active-inactive-ball flex-item' style={{ background: !isPlannersActive ? "rgb(201, 0, 57)" : "", margin: !isPlannersActive ? "0 0 0 0" : "" }} />
                                        </div>
                                        <p className='flex-item active-inactive-txt'>{isPlannersActive ? "Active" : "Inactive"}</p>
                                    </div>
                                    <p className='filter-heading-text m-b-12'>Access Right</p>
                                    <div className="w-full d-flex m-b-8">
                                        <div onClick={handleAccessRight.bind(this, "")} className='d-flex w-half p-r-12'>
                                            <input type="radio" name="access-right" checked={!sortByAccessRight} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>All</p>
                                        </div>
                                        <div onClick={handleAccessRight.bind(this, "private")} className='d-flex w-half'>
                                            <input type="radio" name="access-right" checked={sortByAccessRight == "private"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Private</p>
                                        </div>
                                    </div>
                                    <div onClick={handleAccessRight.bind(this, "public")} className='d-flex w-full m-b-20 gym-filter-type'>
                                        <input type="radio" name="access-right" checked={sortByAccessRight == "public"} className='flex-item cursor-pointer m-r-6' />
                                        <p className='filter-type-text'>Public</p>
                                    </div>
                                    <p className='filter-heading-text m-b-12'>Planner Type</p>
                                    <div className="w-full d-flex m-b-8">
                                        <div onClick={handlePlannerType.bind(this, "Workout")} className='d-flex gym-filter-type w-half p-r-12'>
                                            <input type="radio" name="user-type" checked={PlannerType == "Workout"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Workout</p>
                                        </div>
                                        <div onClick={handlePlannerType.bind(this, "OneDayPlanner")} className='d-flex w-half'>
                                            <input type="radio" name="user-type" checked={PlannerType == "OneDayPlanner"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>One Day Planner</p>
                                        </div>
                                    </div>
                                    <div onClick={handlePlannerType.bind(this, "Fitness")} className='d-flex w-full gym-filter-type m-b-20'>
                                        <input type="radio" name="user-type" checked={PlannerType == "Fitness"} className='flex-item cursor-pointer m-r-6' />
                                        <p className='filter-type-text'>Fitness</p>
                                    </div>
                                    {/* <p className='filter-heading-text m-b-12'>Week Frequency</p> */}
                                    <div onClick={toggleInputFields('Week Frequency')} className={`${currentElementId == "Week Frequency" ? "member-global-input-field m-b-16 flex-item" : "member-global-input-field flex-item m-b-16 d-flex"}`} style={{ border: currentElementId == "Week Frequency" ? "1px solid #055075" : "" }}>
                                        <div className='d-flex'>
                                            <div className="flex-item d-flex">
                                                <svg className="flex-item" width={currentElementId == "Week Frequency" ? "10" : "18"} height={currentElementId == "Week Frequency" ? "10" : "18"} style={{ transition: "180ms" }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2193_1357)">
                                                        <path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0ZM2 2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V4H15V3C15 2.73478 14.8946 2.48043 14.7071 2.29289C14.5196 2.10536 14.2652 2 14 2H2ZM15 5H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V5Z" fill={currentElementId == "Week Frequency" ? "#055075" : "#6d6d6d"} />
                                                        <path d="M11 7.5C11 7.36739 11.0527 7.24021 11.1464 7.14645C11.2402 7.05268 11.3674 7 11.5 7H12.5C12.6326 7 12.7598 7.05268 12.8536 7.14645C12.9473 7.24021 13 7.36739 13 7.5V8.5C13 8.63261 12.9473 8.75979 12.8536 8.85355C12.7598 8.94732 12.6326 9 12.5 9H11.5C11.3674 9 11.2402 8.94732 11.1464 8.85355C11.0527 8.75979 11 8.63261 11 8.5V7.5ZM8 7.5C8 7.36739 8.05268 7.24021 8.14645 7.14645C8.24021 7.05268 8.36739 7 8.5 7H9.5C9.63261 7 9.75979 7.05268 9.85355 7.14645C9.94732 7.24021 10 7.36739 10 7.5V8.5C10 8.63261 9.94732 8.75979 9.85355 8.85355C9.75979 8.94732 9.63261 9 9.5 9H8.5C8.36739 9 8.24021 8.94732 8.14645 8.85355C8.05268 8.75979 8 8.63261 8 8.5V7.5ZM6 10.5C6 10.3674 6.05268 10.2402 6.14645 10.1464C6.24021 10.0527 6.36739 10 6.5 10H7.5C7.63261 10 7.75979 10.0527 7.85355 10.1464C7.94732 10.2402 8 10.3674 8 10.5V11.5C8 11.6326 7.94732 11.7598 7.85355 11.8536C7.75979 11.9473 7.63261 12 7.5 12H6.5C6.36739 12 6.24021 11.9473 6.14645 11.8536C6.05268 11.7598 6 11.6326 6 11.5V10.5ZM3 10.5C3 10.3674 3.05268 10.2402 3.14645 10.1464C3.24021 10.0527 3.36739 10 3.5 10H4.5C4.63261 10 4.75979 10.0527 4.85355 10.1464C4.94732 10.2402 5 10.3674 5 10.5V11.5C5 11.6326 4.94732 11.7598 4.85355 11.8536C4.75979 11.9473 4.63261 12 4.5 12H3.5C3.36739 12 3.24021 11.9473 3.14645 11.8536C3.05268 11.7598 3 11.6326 3 11.5V10.5Z" fill={currentElementId == "Week Frequency" ? "#055075" : "#6d6d6d"} />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2193_1357">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p className="placeholder-txt m-l-6 flex-item" style={{ fontSize: currentElementId == "Week Frequency" ? "10px" : "14px", color: currentElementId == "Week Frequency" ? "#055075" : "", transition: "180ms" }}>Week Frequency</p>
                                            </div>
                                        </div>
                                        <input id="Week Frequency" className={`${currentElementId == "Week Frequency" ? "active-input-field" : "inactive-input-field"}`} type='number' onChange={_.debounce(onKeyDownArrageInputValue, 300)} />
                                    </div>
                                    <p className='filter-heading-text m-b-12 m-t-20'>Content Role</p>
                                    <div className="w-full d-flex m-b-8">
                                        <div onClick={handleContentRole.bind(this, "All")} className='d-flex w-half p-r-12'>
                                            <input type="radio" name="content-role" checked={ContentRole == "All"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>All</p>
                                        </div>
                                        <div onClick={handleContentRole.bind(this, "gym")} className='d-flex w-half gym-filter-type'>
                                            <input type="radio" name="content-role" checked={ContentRole == "gym"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Gym Content</p>
                                        </div>
                                    </div>
                                    <div className="w-full d-flex m-b-8">
                                        <div onClick={handleContentRole.bind(this, "my")} className='d-flex w-half p-r-12'>
                                            <input type="radio" name="content-role" checked={ContentRole == "my"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>My Content</p>
                                        </div>
                                        <div onClick={handleContentRole.bind(this, "branch")} className='d-flex w-half p-r-12'>
                                            <input type="radio" name="content-role" checked={ContentRole == "branch"} className='flex-item cursor-pointer m-r-6' />
                                            <p className='filter-type-text'>Branch Content</p>
                                        </div>
                                    </div>
                                    <div onClick={handleContentRole.bind(this, "instafit")} className='d-flex'>
                                        <input type="radio" name="content-role" checked={ContentRole == "instafit"} className='flex-item cursor-pointer m-r-6' />
                                        <p className='filter-type-text'>Workout Plus Content</p>
                                    </div>

                                    {
                                        ((ContentRole == "gym" || ContentRole == "branch") && !selectedGym) && (
                                            <div className='flex-item w-full'>
                                                <div onClick={handleModalSortPopupOpen.bind(this, "gym")} className='member-button-global btn-center d-flex m-t-20'>
                                                    <p className='flex-item'>Select Gym</p>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        selectedGym && [
                                            <div className='d-flex'>
                                                <div className='d-flex m-t-20'>
                                                    <p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
                                                    <svg onClick={handleModalSortPopupOpen.bind(this, "gym")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                                    </svg>
                                                    <span className="m-l-4 m-r-4 flex-item"></span>
                                                    <svg role={`button`} onClick={handleClearGym} className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#055075" />
                                                    </svg>
                                                </div>
                                            </div>,
                                            <div className="d-flex w-full m-t-8">
                                                {
                                                    selectedGym.logoFileID ? (
                                                        <img className="flex-item m-r-8 branch-gym-logo" style={{ width: 40, height: 40 }} src={`${config.API_URL}${config.prefixStream}/media/file/${selectedGym.logoFileID}`} />
                                                    ) : (
                                                        <div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ background: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c" }}>
                                                            <p className="flex-item" style={{ margin: '0 auto', color: '#ffffff' }}>{`${selectedGym.name}`.trim()[0]}</p>
                                                        </div>
                                                    )
                                                }
                                                <div className="flex-item">
                                                    <p className="branch-gym-name">{selectedGym.name}</p>
                                                    <p className="branch-gym-email">{selectedGym.email}</p>
                                                </div>
                                            </div>
                                        ]
                                    }

                                    {
                                        (selectedGym && SelectedBranchIDs.length == 0 && ContentRole == "branch") && [
                                            <div className='flex-item w-full'>
                                                <div onClick={handleModalSortPopupOpen.bind(this, "branch")} className='member-button-global btn-center d-flex m-t-16'>
                                                    <p className='flex-item'>Select Branch</p>
                                                </div>
                                            </div>
                                        ]
                                    }

                                    {
                                        SelectedBranchIDs.length > 0 && [
                                            <div className='d-flex'>
                                                <div className='d-flex m-t-20'>
                                                    <p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
                                                    <svg onClick={handleModalSortPopupOpen.bind(this, "branch")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                                    </svg>
                                                    <span className="m-l-4 m-r-4 flex-item"></span>
                                                    <svg role={`button`} onClick={handleClearBranch} className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#055075" />
                                                    </svg>
                                                </div>
                                            </div>,
                                            <div className="w-full m-t-8">
                                                <p className="branch-gym-name">{`${SelectedBranchIDs.length} Branch(s) Selected`}</p>
                                            </div>
                                        ]
                                    }

                                    {
                                        (!sortByExerciseLevelIds.length) && [
                                            <div className='flex-item w-full'>
                                                <div onClick={handleModalSortPopupOpen.bind(this, "exercise-level")} className='member-button-global btn-center d-flex m-t-20'>
                                                    <p className='flex-item'>Select Exercise Level</p>
                                                </div>
                                            </div>
                                        ]
                                    }

                                    {
                                        sortByExerciseLevelIds.length > 0 && [
                                            <div className='d-flex'>
                                                <div className='d-flex m-t-20'>
                                                    <p className='filter-heading-text m-r-12 flex-item'>Selected Level</p>
                                                    <svg onClick={handleModalSortPopupOpen.bind(this, "exercise-level")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                                    </svg>
                                                    <span className="m-l-4 m-r-4 flex-item"></span>
                                                    <svg onClick={handleClearExerciseLevels} className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#055075" />
                                                    </svg>
                                                </div>
                                            </div>,
                                            <div className="d-flex w-full m-t-8">
                                                <p className='flex-item'>{sortByExerciseLevelIds.length + ` Exercise Level(s) Selected`}</p>
                                            </div>
                                        ]
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className='global-round-btn d-flex'>
                        <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
                        </svg>
                    </div>
                </div>
            </div>

            <div className='dashboard-data-saperator'></div>

            <div id='workout-plannerlist' className='body-container'>
                <div className='d-flex table-header-container'>
                    <div onClick={checkAllPlanner.bind(this)} className='d-flex flex-item gym-container location-container'>
                        <input className='flex-item m-r-8 cursor-pointer' type="checkbox" checked={Planners.filter(d=>!userAccessRight(d)).every(data=>selectedPlannerIDs.indexOf(data._id)!=-1)} />
                        <p className='flex-item table-heading-txt'>Planner Name</p>
                    </div>
                    {(PlannerType == "Workout" || PlannerType == "OneDayPlanner")?[<div className='email-container flex-item'>
                        <p className='flex-item table-heading-txt'>Exercise Level</p>
                    </div>,
                    <div className='email-container flex-item'>
                        <p className='flex-item table-heading-txt'>Planner Frequency</p>
                    </div>]:[
                    <div className='location-container flex-item'>
                        <p className='flex-item table-heading-txt'>Exercises</p>
                    </div>
                    ]}
                    <div className={`${(PlannerType == "Workout" || PlannerType == "OneDayPlanner") ?'number-container':'location-container'} flex-item`}>
                        <p className='flex-item table-heading-txt'>Created by</p>
                    </div>
                    <div className='action-container flex-item'>
                        <p className='flex-item table-heading-txt'>Action</p>
                    </div>
                </div>
                <div className='flex-item main-table-scroll-container w-full'>
                    {Planners.map((planner, index) => {
                        return (
                            <div className='flex-item table-row-container d-flex'>
                                <div className='flex-item gym-container location-container d-flex'>
                                    <input
                                        className='flex-item m-r-8'
                                        name="gym-checkbox"
                                        type="checkbox"
                                        disabled={userAccessRight(planner)}
                                        onClick={handlePlannerIDs(planner._id)}
                                        checked={selectedPlannerIDs.indexOf(planner._id) != -1}
                                    />
                                    <p onClick={() => history.push(`/planner/${planner._id}`)} className='flex-item'>{planner.name}</p>
                                </div>
                                {(PlannerType == "Workout" || PlannerType == "OneDayPlanner") ? [<div className='email-container flex-item'>
                                    <div onClick={e => e.stopPropagation()} className="d-flex">
                                        {
                                         planner.exerciseLevels && ( planner.exerciseLevels && planner.exerciseLevels.length == 0 ? (
                                                <p>---</p>
                                            ) : planner.exerciseLevels && planner.exerciseLevels.length == 1 ? (
                                                <p>{planner.exerciseLevels[0].name}</p>
                                            ) : (
                                                <div onClick={() => toggleTableMoreItems("Exercise-Muscles", planner._id)} className="more-table-item-container d-flex" style={{ background: selectedMoreItem == "Exercise-Muscles" && selectedMoreItemID == planner._id ? "#055075" : "" }}>
                                                    <p className='flex-item more-table-item-txt' style={{ color: selectedMoreItem == "Exercise-Muscles" && selectedMoreItemID == planner._id ? "#ffffff" : "" }}>{planner.exerciseLevels[0].name}</p>
                                                    {
                                                        planner.exerciseLevels && planner.exerciseLevels.length > 1 && (
                                                            <div className="d-flex flex-item m-l-8">
                                                                <span className="flex-item m-r-2" style={{ background: selectedMoreItem == "Exercise-Muscles" && selectedMoreItemID == planner._id ? "#ffffff" : "" }} />
                                                                <span className="flex-item m-r-2" style={{ background: selectedMoreItem == "Exercise-Muscles" && selectedMoreItemID == planner._id ? "#ffffff" : "" }} />
                                                                <span className="flex-item" style={{ background: selectedMoreItem == "Exercise-Muscles" && selectedMoreItemID == planner._id ? "#ffffff" : "" }} />
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        selectedMoreItem == "Exercise-Muscles" && selectedMoreItemID == planner._id && (
                                                            <div onClick={e => e.stopPropagation()} className="more-table-item-dropdown top-slider">
                                                                <div className="more-table-scroll-container">
                                                                    <div className="d-flex-wrap w-full">
                                                                        {
                                                                            planner.exerciseLevels.map((level) => {
                                                                                return (
                                                                                    <div className="more-table-item-dropdown-container d-flex m-r-12 m-t-6 m-b-6">
                                                                                        <p className="flex-item">{level.name}</p>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>,
                                <div className='email-container flex-item'>
                                    <p className='flex-item'>{planner.isOneDayPlanner?1:(planner.weekdays && planner.weekdays.length)} Days</p>
                                </div>]:[
                                     <div className='location-container flex-item'>
                                     <p className='flex-item'>{planner.exercises && planner.exercises.length} exercise(s)</p>
                                 </div>
                                ]}
                                <div className={`${(PlannerType == "Workout" || PlannerType == "OneDayPlanner") ?'number-container':'location-container'} flex-item`}>
                                    <p className='flex-item'>{(planner.createdByUser && planner.createdByUser.length > 0) ? planner.createdByUser[0].name : "---"}</p>
                                </div>
                                {
                                    user.role != 8 && user.role != 7 && user.role != 4 && user.role != 5 && (
                                        <div className='action-container' onClick={e => e.stopPropagation()}>
                                            <div onClick={togglePlannerActionDropdown(planner)} className='w-full flex-item'>
                                                <div className='action-horizontain-dots d-flex'>
                                                    <span className='flex-item m-r-2'></span>
                                                    <span className='flex-item m-r-2'></span>
                                                    <span className='flex-item'></span>
                                                </div>
                                            </div>
                                            <div id={"action-" + planner._id} className="table-action-container" style={{ display: isPlannerActionDropdownOpenId == planner._id ? "block" : "none" }}>
                                                <div onClick={toggleConfirmCreatePlannerModal.bind(this)} role="button" className="d-flex spacing-22">
                                                    <img src={UpdateIcon} className="body-content-two-action-icon" />
                                                    <div className="spacing-24">Edit</div>
                                                </div>
                                                <div style={{ margin: "2% 0", width: "100%", height: "1px", backgroundColor: "#f5f5f5" }}></div>
                                                <div role="button" onClick={handlePlannerActivationModalOpen} className="d-flex spacing-22">
                                                    <img src={BlockIcon} className="body-content-two-action-icon" />
                                                    <div className="spacing-24">{(!planner.deletedAt || planner.deletedAt == null) ? `Inactive` : `Active`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    user.role != 0 && user.role != 1 && user.role != 2 && user.role != 3 && user.role != 6 && (
                                        <div className='action-container flex-item' style={{height:"30px"}}>
                                            <div className="w-full" style={{display: user.gymIDs[0] == planner.gymId && user._id == planner.createdBy ? "flex" : "none"}}>
                                                <div onClick={togglePlannerActionDropdown(planner)} className='w-full flex-item'>
                                                    <div className='action-horizontain-dots d-flex'>
                                                        <span className='flex-item m-r-2'></span>
                                                        <span className='flex-item m-r-2'></span>
                                                        <span className='flex-item'></span>
                                                    </div>
                                                </div>
                                                <div id={"action-" + planner._id} className="table-action-container" style={{ display: isPlannerActionDropdownOpenId == planner._id ? "block" : "none" }}>
                                                    <div onClick={toggleConfirmCreatePlannerModal.bind(this)} role="button" className="d-flex spacing-22">
                                                        <img src={UpdateIcon} className="body-content-two-action-icon" />
                                                        <div className="spacing-24">Edit</div>
                                                    </div>
                                                    <div style={{ margin: "2% 0", width: "100%", height: "1px", backgroundColor: "#f5f5f5" }}></div>
                                                    <div role="button" onClick={handlePlannerActivationModalOpen} className="d-flex spacing-22">
                                                        <img src={BlockIcon} className="body-content-two-action-icon" />
                                                        <div className="spacing-24">{(!planner.deletedAt || planner.deletedAt == null) ? `Inactive` : `Active`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    })
                    }

                    <div className='bottom-pagination-bar space-between d-flex' style={{ transition: "180ms" }}>
                        <div className='d-flex flex-item space-between' style={{ width: (Planners.filter(d=>!userAccessRight(d)).every(d=>selectedPlannerIDs.indexOf(d._id)!=-1)) ? "65%" : "100%" }}>
                            <div onClick={prev} className='global-button-container d-flex flex-item'>
                                <p className='flex-item'>Previous</p>
                            </div>
                            <p className='flex-item pagination-txt'>{(pageNo.planner - 1) * pageSize.planner + 1} - {(pageNo.planner - 1) * pageSize.planner + Planners.length}  of  {total.planner}</p>
                            <div onClick={next} className='global-button-container d-flex flex-item'>
                                <p className='flex-item'>Next</p>
                            </div>
                        </div>
                        {
                            (Planners.length > 0 && (selectedPlannerIDs.length > 0)) && (
                                <div className='d-flex flex-item m-l-60'>
                                    <div onClick={handleBulkStatusChnageAction} class="global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20">
                                        <div role="button" className="d-flex">
                                            <svg className='flex-item svg-fill m-r-8' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1655_2069)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 9.72391 14.219 11.781 13 13C11.781 14.219 9.72391 15 8 15C6.27609 15 4.21899 14.219 3 13C1.78101 11.781 1 9.72391 1 8C1 6.27609 1.78101 4.21899 3 3C4.21899 1.78101 6.27609 1 8 1C9.72391 1 11.781 1.78101 13 3C14.219 4.21899 15 6.27609 15 8ZM16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM5.28 4.22C5.13783 4.08752 4.94978 4.0154 4.75548 4.01883C4.56118 4.02225 4.37579 4.10097 4.23838 4.23838C4.10097 4.37579 4.02225 4.56118 4.01883 4.75548C4.0154 4.94978 4.08752 5.13783 4.22 5.28L10.72 11.78C10.7887 11.8537 10.8715 11.9128 10.9635 11.9538C11.0555 11.9948 11.1548 12.0168 11.2555 12.0186C11.3562 12.0204 11.4562 12.0018 11.5496 11.9641C11.643 11.9264 11.7278 11.8703 11.799 11.799C11.8703 11.7278 11.9264 11.643 11.9641 11.5496C12.0018 11.4562 12.0204 11.3562 12.0186 11.2555C12.0168 11.1548 11.9948 11.0555 11.9538 10.9635C11.9128 10.8715 11.8537 10.7887 11.78 10.72L5.28 4.22Z" fill="#6D6D6D" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1655_2069">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p className='flex-item' style={{ whiteSpace: "nowrap" }}>{isPlannersActive ? "Mark inactive" : "Mark active"}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>


                    {/* <div className='bottom-pagination-bar'>
                        {
                            (Planners.length>0 && (isAllPlannerChecked && selectedPlannerIDs.length>0)) ? (
                                <div className='d-flex flex-item'>
                                    <div class="global-button-container d-flex flex-item p-l-12 p-r-12" style={{ margin: "0 auto" }}>
                                        <div role="button" className="d-flex">
                                            <svg className='flex-item svg-fill m-r-8' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1655_2069)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 9.72391 14.219 11.781 13 13C11.781 14.219 9.72391 15 8 15C6.27609 15 4.21899 14.219 3 13C1.78101 11.781 1 9.72391 1 8C1 6.27609 1.78101 4.21899 3 3C4.21899 1.78101 6.27609 1 8 1C9.72391 1 11.781 1.78101 13 3C14.219 4.21899 15 6.27609 15 8ZM16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM5.28 4.22C5.13783 4.08752 4.94978 4.0154 4.75548 4.01883C4.56118 4.02225 4.37579 4.10097 4.23838 4.23838C4.10097 4.37579 4.02225 4.56118 4.01883 4.75548C4.0154 4.94978 4.08752 5.13783 4.22 5.28L10.72 11.78C10.7887 11.8537 10.8715 11.9128 10.9635 11.9538C11.0555 11.9948 11.1548 12.0168 11.2555 12.0186C11.3562 12.0204 11.4562 12.0018 11.5496 11.9641C11.643 11.9264 11.7278 11.8703 11.799 11.799C11.8703 11.7278 11.9264 11.643 11.9641 11.5496C12.0018 11.4562 12.0204 11.3562 12.0186 11.2555C12.0168 11.1548 11.9948 11.0555 11.9538 10.9635C11.9128 10.8715 11.8537 10.7887 11.78 10.72L5.28 4.22Z" fill="#6D6D6D" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1655_2069">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p className='flex-item'>{isPlannersActive?"Mark inactive":"Mark active"}</p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='d-flex flex-item space-between'>
                                    <div className='global-button-container d-flex flex-item'>
                                        <p className='flex-item'>Previous</p>
                                    </div>
                                    <p className='flex-item pagination-txt'>1 - 30 of 200</p>
                                    <div className='global-button-container d-flex flex-item'>
                                        <p className='flex-item'>Next</p>
                                    </div>
                                </div>
                            )
                        }
                    </div> */}
                </div>
            </div>

            {/* <table id="workout-plannerList" className="body-container"> */}
            {/* <thead>
                    <tr>
                    <th style={{textAlign:"left", padding :"0 0 0 4.7%", width: "20%"}}>Planner Name</th>
                    <th>Level</th>
                    <th>Frequency</th>
                    <th>Created by</th>
                    <th>Status</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                   {
                        workoutPlanner.map((planner,index)=>{
                           return (

                        <tr>
                        <td style={{textAlign:"left", padding :"0 0 0 1.4%", width: "20%", fontWeight : "bold"}}>
                            <div className="d-flex">
                                <span class="material-icons-round action-icon">check_circle</span>
                                <div onClick={()=>history.push(`/planner/${planner.account_id}/${planner._id}`)} style={{padding :"2% 0 0 2%"}}>{planner.planner_name}</div>
                            </div>
                        </td>
                        <td>{planner.level}</td>
                        <td style={{fontFamily:"sans-serif"}}>{planner.planner_freq} Days</td>
                        <td>{planner.created_by}</td>
                        <td>
                            <div className="d-flex" style={{padding :"0 0 0 17%"}}>
                                <span className={`material-icons-round action-icon ${planner.active?"active":"inactive"}`}>circle</span>
                                <div style={{padding :"2.2% 0 0 2%"}} className={planner.active?"active":"inactive"}>{planner.active?"Active":"Inactive"}</div>
                            </div>
                        </td>
                        <td>
                        <span onClick={togglePlannerAction("toggleAction-plannerList_workout"+index)} class="material-icons-outlined edit-icon" style={{marginTop:"4%"}}>more_horiz</span>
                            <div id={"action-plannerList_workout"+index} className="table-action-container" style={{display:"none",zIndex:1}}>
                                <div onClick={() => {alert("not  yet")}} role="button" className="d-flex spacing-22">
                                    <img src={UpdateIcon} className="body-content-two-action-icon" />
                                    <div className="spacing-24">Update</div>
                                </div>
                                <div style={{margin:"2% 0", width:"100%", height:"1px", backgroundColor:"#f5f5f5"}}></div>
                                <div role="button" onClick={handleActiveInActiveOperation(planner)}  className="d-flex spacing-22">
                                    <img src={BlockIcon} className="body-content-two-action-icon" />
                                    <div className="spacing-24">Inactive</div>
                                </div>
                            </div>
                        </td>
                    </tr>
            
                           )
                       })
                   }
                </tbody> */}
            {/* </table> */}


            {/* <table id="fitness-plannerList"  className="body-container" style={{display:"none"}}> */}
            {/* <thead>
                    <tr>
                        <th style={{textAlign:"left", padding :"0 0 0 4.7%", width: "20%"}}>Planner Name</th>
                        <th>Level</th>
                        <th>No. of Level</th>
                        <th>Created by</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead> */}
            {/* <tbody>
                    <tr>
                        <td style={{textAlign:"left", padding :"0 0 0 1.4%", width: "20%", fontWeight : "bold"}}>
                            <div onClick={() => {toggleFitnessPlannerView("target-plannerList")}} className="d-flex">
                                <span class="material-icons-round action-icon">check_circle</span>
                                <div style={{padding :"2% 0 0 2%"}}>Planner Name</div>
                            </div>
                        </td>
                        <td>Beginner</td>
                        <td style={{fontFamily:"sans-serif"}}>10</td>
                        <td>Jodu Modhu</td>
                        <td>
                            <div className="d-flex" style={{padding :"0 0 0 17%"}}>
                                <span className="material-icons-round action-icon active">circle</span>
                                <div style={{padding :"2.2% 0 0 2%"}} className="active">Active</div>
                            </div>
                        </td>
                        <td>
                            <span onClick={togglePlannerAction("toggleAction-fitness_plannerList")} class="material-icons-outlined edit-icon" style={{marginTop:"4%"}}>more_horiz</span>
                            <div id="action-fitness_plannerList" className="table-action-container" style={{display:"none"}}>
                                <div onClick={() => {toggleFitnessPlannerCreate("target-plannerContent")}} role="button" className="d-flex spacing-22">
                                    <img src={UpdateIcon} className="body-content-two-action-icon" />
                                    <div className="spacing-24">Update</div>
                                </div>
                                <div style={{margin:"2% 0", width:"100%", height:"1px", backgroundColor:"#f5f5f5"}}></div>
                                <div role="button" className="d-flex spacing-22">
                                    <img src={BlockIcon} className="body-content-two-action-icon" />
                                    <div className="spacing-24">Inactive</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody> */}
            {/* </table> */}


            <div id="view-plannerList" className="popup-container" style={{ display: "none" }}>
                <div className="popup-view">
                    <div className="d-flex" style={{ justifyContent: "space-between" }}>
                        <div className="bold-font my-profile-heading flex-item">Planner Name</div>
                        <span onClick={() => { toggleFitnessPlannerView("close-plannerList") }} class="material-icons-round edit-icon">close</span>
                    </div>
                    <div className="table-scroll-container">
                        <table className="popup-fitness-test-table">
                            <thead>
                                <tr>
                                    <th style={{ width: "12%", background: "#f5f5f5" }}>Sl No.</th>
                                    <th style={{ width: "62%" }}>Content Name</th>
                                    <th style={{ width: "20%", textAlign: "center" }}>Unit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ width: "12%", textAlign: "center" }}>1.</td>
                                    <td style={{ width: "62%", color: "#0052af" }}>Hips Workout</td>
                                    <td style={{ width: "20%", textAlign: "center" }}>KG</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "12%", textAlign: "center" }}>2.</td>
                                    <td style={{ width: "62%", color: "#0052af" }}>Legs Workout</td>
                                    <td style={{ width: "20%", textAlign: "center" }}>KG</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {
                (isConfirmCreatePlannerModal && (PlannerType == "Workout" || PlannerType == "OneDayPlanner")) && <ManageWorkoutPlanner isEdit={true} workoutPlanner={selectedPlanner} toggleAddWorkoutPlanner={toggleConfirmCreatePlannerModal.bind(this)} />
            }

            {
                (isConfirmCreatePlannerModal && PlannerType == "Fitness") && <ManageFitnessPlanner isEdit={true} fitnessPlanner={selectedPlanner} toggleAddFitnessPlanner={toggleConfirmCreatePlannerModal.bind(this)} />
            }



            {
                isPlannersActivationModalOpen && <ManageConfirmationModal handleAction={handleAction} title={isBulkStatusChangeAction ? `${selectedPlannerIDs.length} branch(s) selected` : selectedPlanner.name} message="By performing this action all the related features to this member will get stopped" buttonText={`Mark ${isBulkStatusChangeAction ? (isPlannersActive ? "inactive" : "active") : (selectedBranch.deletedAt == null ? "inactive" : "active")}`} handleClose={handlePlannerActivationModalOpen} />
            }



            {
                isModalSortPopupOpen == "exercise-level" && (
                    <div className="global-modal-container d-flex">
                        <div className="global-filter-modal flex-item">
                            <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                                <p className="flex-item modal-header-text">Exercise Level</p>
                                {/* <div id="search-container-branch" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                                    <svg id="search-icon-branch" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1637_1318)">
                                            <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1637_1318">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <input id="search-branch-input" className='flex-item' type="text" placeholder="Search" style={{ background: "#f5f5f5" }} />
                                </div> */}
                            </div>
                            <div className='search-gym-modal-scroll'>
                                <div className="d-flex-wrap w-full m-t-12">
                                    {
                                        Parameters.map(data => {
                                            return (
                                                <div onClick={handleTempIDs(data._id)} className={`global-button-container ${tempIDs.indexOf(data._id) != -1 ? "global-button-container-active" : ""} d-flex flex-item p-l-12 p-r-12 m-r-16 m-b-16`}>
                                                    <p className='flex-item'>{data.name}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                                <div className='d-flex' >
                                    <div onClick={handleModalSortPopupOpen.bind(this)} className='member-button-global btn-center d-flex flex-item' style={{ width: "100px", margin: "0 0 0 auto" }}>
                                        <p className='flex-item'>Cancel</p>
                                    </div>
                                    {
                                        isDataChangedTriggered > 0 && <div onClick={addConfirmExerciseLevel.bind(this)} className='member-button-global btn-center d-flex flex-item' style={{ width: "100px", margin: "0 0 0 14px" }}>
                                            <p className='flex-item'>Add</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                isModalSortPopupOpen == "gym" && (
                    <div className="global-modal-container d-flex">
                        <div className="global-filter-modal flex-item">
                            <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                                <p className="flex-item modal-header-text">Select Gym</p>
                                <div id="search-container-gym" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                                    <svg id="search-icon-gym" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1637_1318)">
                                            <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1637_1318">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <input id="search-gym-input" onChange={_.debounce(handleGymSearch, 1000)} className='flex-item' type="text" placeholder="Search Gym" style={{ background: "#f5f5f5" }} />
                                </div>
                            </div>
                            <div className='search-gym-modal-scroll' onScroll={handleGymScroll}>
                                {
                                    Gyms.map(data => {
                                        return (
                                            <div onClick={handleSelectGym(data)} className="d-flex suggestion-list-items">
                                                {
                                                    data.logoFileID ? (
                                                        <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}${config.prefixStream}/media/file/${data.logoFileID}`} />
                                                    ) : (
                                                        <div className='d-flex flex-item branch-gym-user-profile m-r-8' style={{
                                                            backgroundColor: data.logoPlaceholderColor ? data.logoPlaceholderColor : "#5d883c"
                                                        }}>
                                                            <p className='flex-item fs-14' style={{
                                                                margin: '0 auto',
                                                                color: "#ffffff"
                                                            }}>{`${data.name}`.trim()[0]}</p>
                                                        </div>
                                                    )
                                                }
                                                <div className="flex-item">
                                                    <p className="branch-gym-user-name m-b-1">{data.name}</p>
                                                    <p className="branch-gym-details-value">{data.email}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {isGymSpinnerLoader && <div className="loader-5 center"><span></span></div>}
                            </div>
                            <div onClick={handleModalSortPopupOpen.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                                <div className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                                    <p className='flex-item'>Cancel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                isModalSortPopupOpen == "branch" && (
                    <div className="global-modal-container d-flex">
                        <div className="global-filter-modal flex-item">
                            <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                                <p className="flex-item modal-header-text">Select Branch</p>
                                <div id="search-container-gym" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                                    <svg id="search-icon-gym" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1637_1318)">
                                            <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1637_1318">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <input id="search-branch-input" onChange={_.debounce(handleBranchSearch, 1000)} className='flex-item' type="text" placeholder="Search" style={{ background: "#f5f5f5" }} />
                                </div>
                            </div>
                            <div className='search-gym-modal-scroll' onScroll={handleBranchScroll}>
                                <div className="d-flex-wrap w-full m-t-12">
                                    {
                                        Branches.map(data => {
                                            return (
                                                <div onClick={handleTempIDs(data._id)} className={`global-button-container ${tempIDs.findIndex(id => id == data._id) != -1 && `global-button-container-active`} d-flex flex-item p-l-12 p-r-12 m-r-16 m-b-16`}>
                                                    <p className='flex-item'>{data.name}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {isBrachSpinnerLoader && <div className="loader-5 center"><span></span></div>}
                            </div>
                            <div className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                                <div className="flex-item d-flex">
                                    <div onClick={handleModalSortPopupOpen.bind(this)} className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                                        <p className='flex-item'>Cancel</p>
                                    </div>
                                    {
                                        isDataChangedTriggered > 0 &&
                                        <div onClick={confirmBranchTempSelectionIDs} className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 14px" }}>
                                            <p className='flex-item'>Add</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </Dashboard>
    )
}

export default withRouter(PlannerDashBoard);
