import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Logo from '../assets/gymshim-full-logo.png';
import Background from "../assets/background-login.png";
import { authenticate, isAuthenticated, SignIn, thirdPartySignIn } from '../auth/index';
import { getAuth, signInWithPopup, GoogleAuthProvider,RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";
import MailBox from '../assets/envelope.svg';
// import Lock from '../assets/padlock.svg';
// import Twitter from '../assets/twitter.png';
// import Facebook from '../assets/facebook.png';
import Google from '../assets/google.png';
import Info from '../assets/info.svg';
import Play from "../assets/play.svg";
import LogoMob from '../assets/gymshim-full-logo.png';
import SpinnerLoader from '../components/SpinnerLoader'
import { startLoader, stopLoader, validateEmail, validatePhoneNumber } from '../lib/utils';
import { forgetpassword } from '../user/helper/api';
import Link from 'react-router-dom/Link';
import _ from 'lodash';
import Toast from '.././lib/toast';
import { app } from '.././lib/firebase-config';
// import config from '../config';

// const firebaseConfig = {
//   apiKey: "AIzaSyDP5qgFjK8gXLMUDgSb_u9yV3-1VJOiAms",
//   authDomain: "workoutplus-7278b.firebaseapp.com",
//   projectId: "workoutplus-7278b",
//   storageBucket: "workoutplus-7278b.appspot.com",
//   messagingSenderId: "302278372183",
//   appId: "1:302278372183:web:5102abd41923133bb0ec12",
//   measurementId: "G-GY36RBG0Z0"
// };

// initializeApp(config.firebaseConfig,"signInApp");
const provider = new GoogleAuthProvider();
const auth = getAuth(app);
let code="";
let setTimer=null;

const SigninRoute = () => {

  const [isButtonLock, setButtonLock] = useState(false);
  const [values, setValues] = useState({
    emailOrPhone:"",
    email: "",
    phone: "",
    password: "",
    error: "",
    didRedirect: false
  });

  const { email,emailOrPhone, password, error, didRedirect } = values;

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const [success, setSuccess] = useState(false);

  const [showInfo, setShowInfo] = useState(false);

  const [inputType,setInputType]=useState("");

  const [showError, setShowError] = useState(false);

  const [switchPage, setSwitchPage] = useState("signin");

  const { user } = isAuthenticated();

  const [emailInputActive, setEmailInputActive] = useState(false);

  const [passwordInputActive, setPasswordInputActive] = useState(false);

  const [loginWithPassword,setLoginPassword]=useState(false);

  const [isOTPLogin, setOTPLogin] = useState(false);


  useEffect(()=>{
    if(switchPage == 'setotppassword' && inputType=='phone' && !loginWithPassword && emailOrPhone){
      (function () {
        const inputs = document.querySelectorAll("#otp-input input");

        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];
      
          input.addEventListener("input", function () {
            // handling normal input
            if (input.value.length == 1 && i+1 < inputs.length) {
              inputs[i+1].focus();
            }
      
            // if a value is pasted, put each character to each of the next input
            if (input.value.length > 1) {
              // sanitise input
              if (isNaN(input.value)) {
                input.value = "";
                updateInput();
                return;
              }
      
              // split characters to array
              const chars = input.value.split('');
      
              for (let pos = 0; pos < chars.length; pos++) {
                // if length exceeded the number of inputs, stop
                if (pos + i >= inputs.length) break;
      
                // paste value
                let targetInput = inputs[pos + i];
                targetInput.value = chars[pos];
              }
      
              // focus the input next to the last pasted character
              let focus_index = Math.min(inputs.length - 1, i + chars.length);
              inputs[focus_index].focus();
            }
            updateInput();
          });
      
          input.addEventListener("keydown", function (e) {
            // backspace button
            if (e.keyCode == 8 && input.value == '' && i != 0) {
              // shift next values towards the left
              for (let pos = i; pos < inputs.length - 1; pos++) {
                inputs[pos].value = inputs[pos + 1].value;
              }
      
              // clear previous box and focus on it
              inputs[i-1].value = '';
              inputs[i-1].focus();
              updateInput();
              return;
            }
      
            // delete button
            if (e.keyCode == 46 && i != inputs.length - 1) {
              // shift next values towards the left
              for (let pos = i; pos < inputs.length - 1; pos++) {
                inputs[pos].value = inputs[pos + 1].value;
              }
      
              // clear the last box
              inputs[inputs.length - 1].value = '';
              input.select();
              e.preventDefault();
              updateInput();
              return;
            }
      
            // left button
            if (e.keyCode == 37) {
              if (i > 0) {
                e.preventDefault();
                inputs[i-1].focus();
                inputs[i-1].select();
              }
              return;
            }
      
            // right button
            if (e.keyCode == 39) {
              if (i+1 < inputs.length) {
                e.preventDefault();
                inputs[i+1].focus();
                inputs[i+1].select();
              }
              return;
            }
          });

        }
        
        function updateInput() {
          let inputValue = Array.from(inputs).reduce(function (otp, input) {
            otp += (input.value.length) ? input.value : ' ';
            return otp;
          }, "");
          code=inputValue;
          if(document.querySelector("input[name=otp]")) document.querySelector("input[name=otp]").value = inputValue;
        }

        sendOtp();
      })();
    }
  },[switchPage,isOTPLogin == true,emailOrPhone]);

  const validateInput=useCallback(()=>{
    setSwitchPage("setotppassword");

    if(inputType=='phone'){
      setLoginPassword(false);
      setOTPLogin(false);
    }else{
      setLoginPassword(true);
      setOTPLogin(false);
    }
  },[inputType]);

  const handleGenerateOtp=()=>{
    setLoginPassword(false);
    setOTPLogin(true);
  }

  const handleForgotPasswordAction = async event => {
    event.preventDefault()
    try {
      await startLoader()
      let response = await forgetpassword({ email: forgotPasswordEmail });
      if (response.error) {
        setShowError(true)
        setSuccess(false)
      } else {
        setShowError(true)
        setSuccess(false)
      }
      await stopLoader()
    } catch (error) {
      setShowError(true)
      setSuccess(false)
      await stopLoader()
    }
    event.stopPropagation()
  }

  const handleForgotPasswordPhonEnterAction=()=>{
    setSwitchPage("signin");
  }

  const handleChangeForgotPassword = event => {
    setForgotPasswordEmail(event.target.value)
  }

  const handleChange = name => event => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const handleGoogleSignIn = (e) => {
    e.preventDefault()
    if ('ReactNativeWebView' in window) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        trigger:"google-login"
      }))
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          if (user && user.email) {
            thirdPartySignIn({
              email: user.email,
              accessToken: token,
              loginProvider: "Google"
            }).then(response => {
              authenticate(response, () => {

                setValues({
                  ...values,
                  didRedirect: true
                });
              });
            }).catch(error => {
              console.error(error)
            })
          }
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    }
  }

  const onSubmit = () => {
    let emailInput, pswdInput;
    if (window.innerWidth < 549) {
      emailInput = document.querySelector("#email-input-field");
      pswdInput = document.querySelector("#password-input-field");
    } else {
      emailInput = document.querySelector("#email");
      pswdInput = document.querySelector("#password");
    }
    let headerDivider = document.querySelector(".header-divider");
    const payload={};
    if(inputType == "email"){
      payload.email=emailOrPhone;
    }
    if(inputType == "phone"){
      payload.phone=emailOrPhone;
    }
    if(loginWithPassword){
      payload.password=password;
    }

    payload.isLoginVerified=!loginWithPassword;
    SignIn(payload)
      .then(data => {
        if (data.err) {
          stopLoader();
          setShowError(true);
          setButtonLock(false);
          emailInput.style.border = "1.5px solid rgb(219, 0, 0)";
          pswdInput.style.border = "1.5px solid rgb(219, 0, 0)";
          if (headerDivider) headerDivider.style.margin = "1vw 0 1vw 0"
          console.log(data.err);
        } else {
          authenticate(data, () => {
            setValues({
              ...values,
              didRedirect: true
            });
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  const handleSwitchForgotPswd = () => {
    let signinId = document.querySelector("#signin");
    if (signinId) {
      signinId.style.animationDuration = "1s";
      signinId.style.animationIterationCount = "1";
      signinId.style.animation = "slide 1s";
    }
    setTimeout(() => {
      setSwitchPage("forgotpassword");
    }, 300);
  }

  const handleSwitchLogin = () => {
    let forgotPswd = document.querySelector("#forgot-pswd");
    if (forgotPswd) {
      forgotPswd.style.animationDuration = "1s";
      forgotPswd.style.animationIterationCount = "1";
      forgotPswd.style.animation = "slide 1s";
    }
    setTimeout(() => {
      setValues({
        ...values,
        phone:""
      })
      setSwitchPage("signin");
      setOTPLogin(false);
    }, 100);

  }

  const handleShowInfo = () => {
    setShowInfo(true);
  }

  window.onmouseup = () => {
    setShowInfo(false);
  }

  const OnBlurFieldChecker = useCallback((event) => {
    setShowInfo(true);
    let checker = "";
    if(validateEmail(event.target.value)) checker="email";
    if(validatePhoneNumber(event.target.value)) checker="phone";
    let emailInput = document.querySelector("#email");
    if(!emailInput) emailInput=document.querySelector("#input_field_wrapper");
    if (checker) {
      emailInput.style.border = "1.5px solid rgb(1, 122, 11)";
      setShowInfo(false);
      setInputType(checker);
    }
    else {
      emailInput.style.border = "1.5px solid rgb(219, 0, 0)";
      setShowInfo(true);
      setInputType(checker);
    }
  },[]);

  const performRedirect = () => {
    if (didRedirect || isAuthenticated()) {

      if (user && user.role == 0) {
        return <Redirect to="/admin/gym" />;
      }
      if (user && user.role < 5) {
        return <Redirect to="/admin/branch" />
      }
      if (user && user.role < 8 && user.role > 4) {
        return <Redirect to="/admin/branch" />
      }
      if (user && user.role == 8) {
        return <Redirect to="/member/Dashboard" />;
      }


    }
  };

  const onLoadFunctions = () => {

    var onkeyPress = (event) => {
      if (event.keyCode == 13 && document.getElementById("email") && document.getElementById("password")) {
        SignIn({ email: document.getElementById("email").value, password: document.getElementById("password").value }).then(data => {
          if (data.err) {
            document.getElementById('password').style.border = "1px dashed red";
            console.log(data.err); //print in error dialog or snakbar
          } else {
            authenticate(data, () => {
              setValues({
                ...values,
                didRedirect: true
              });
            });
          }
        }).catch(err => {
          console.log(err);
        });
      }
    };
    window.addEventListener('keypress', onkeyPress);
  }

  const toggleInputFields = (currentInputField) => {
    let currentInput = document.getElementById(currentInputField);
    // console.log(currentInput)

    if (currentInputField == 'email-input-field') {
      currentInput.focus();

      setPasswordInputActive(false);

      setTimeout(() => {
        if (currentInput == document.activeElement) {
          setEmailInputActive(true)
        } else {
          setEmailInputActive(false)
        }
      }, 10);

    }

    if (currentInputField == 'password-input-field') {
      currentInput.focus();

      setEmailInputActive(false);

      setTimeout(() => {
        if (currentInput == document.activeElement) {
          setPasswordInputActive(true)
        } else {
          setPasswordInputActive(false)
        }
      }, 10);

    }
  }

  useEffect(() => {
    window.addEventListener('click', () => {
      setEmailInputActive(false)
      setPasswordInputActive(false)
    })
  },[]);

  const sendOtp = async () => {
      try {
        const container=document.getElementById("recaptcha-container");
        if(container) container.remove();
        const div=document.createElement('div');
        div.id="recaptcha-container";
        document.body.append(div);
        if(window.recaptchaVerifier) window.recaptchaVerifier.clear();
        window.recaptchaVerifier = new RecaptchaVerifier(document.getElementById('recaptcha-container'), {
          size: 'invisible',
          callback: () => {
              console.log('recaptcha resolved..')
          },
          'expired-callback': () => {
          }
        },auth);

        if(!setTimer){
          var elem = document.getElementById('some_div');
          elem.style.display='block';
          let timeLeft=30;
          setTimer=setInterval(()=>{
            if (timeLeft == -1) {
              clearInterval(setTimer);
              setTimer=null;
              elem.style.display='none';
            } else {
              if(elem) elem.innerHTML = "( 00:"+timeLeft.toString().padStart(2,"0")+" )";
              timeLeft--;
            }
          },1000);
          signInWithPhoneNumber(auth,"+91"+emailOrPhone, window.recaptchaVerifier).then((result) => {  
            window.result=result;
            new Toast({
              type:"success",
              message:"OTP send to +91"+emailOrPhone,
              duration:2000
            });
          }).catch((err) => {
            new Toast({
              type:"error",
              message:"Can't send otp please wait",
              duration:2000
            });
            console.log(err )
          });
        }else{
          new Toast({
            type:"error",
            message:"Can't send otp please wait",
            duration:2000
          });
        }

      
      } catch (error) {
          //flag = false
          console.log('error sending otp ' + error)
      }
  }

const verifyOtp=() =>{
  setButtonLock(true);
  startLoader();
  if(window.result){
    window.result.confirm(code).then((result) => {
      const user = result.user;
      onSubmit();
    }).catch((error) => {
     stopLoader();
     setButtonLock(false);
     new Toast({
      type:"error",
      message:"Unable to login try after sometime",
      duration:1000
     });
    });
  }
}


  if (window.innerWidth < 549) {
    return (
      <div className='mob-dashboard mob-dashboard-scroll'>
        <div className='w-full h-full'>
          <div className='setup-header-mob d-flex space-between'>
            {/* <div className='back-arrow-button d-flex flex-item'>
              <svg className='flex-item' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#055075" stroke-linecap="round" />
              </svg>
            </div> */}
            <img className='flex-item' src={LogoMob} loading='lazy' />
          </div>
          <div className='d-flex w-full m-t-10'>
            <p className='signin-txt margin-auto font-weight-600 color-orange' style={{fontFamily: "sans-serif"}}>{switchPage == 'forgotpassword' ? "Forgot password" : "Sign In"}</p>  
          </div>
          <div className='d-flex w-full'>
            <img className='mobile-login-background' src={`https://workoutplusasset.s3.ap-south-1.amazonaws.com/workout-plus-signin-vector.png`} loading='lazy' />
          </div>
          <div className='w-full h-full'>
            <div style={{ position: "relative", bottom: 0, width: "90%", margin: "20px auto 0 auto" }}>
              <div className='signin-container' style={{ padding: "0", margin: "0 0 20px 0" }}>
                {
                  switchPage =="signin" && [
                    <div id='input_field_wrapper'  onClick={toggleInputFields.bind(this, 'email-input-field')} className={`${(emailInputActive == true || emailOrPhone) ? "member-global-input-field m-t-10 m-b-12" : "member-global-input-field d-flex m-t-10 m-b-12"}`} style={{ border: (emailInputActive == true || emailOrPhone) ? "1px solid #055075" : "" }}>
                      <div className="flex-item d-flex">
                        <svg className='flex-item m-r-8' width={(emailInputActive == true || emailOrPhone) ? "10" : "16"} height={(emailInputActive == true || emailOrPhone) ? "10" : "16"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.32234 2.39999H10.2723C11.0223 2.39999 11.4003 2.75399 11.4003 3.47399V8.52599C11.4003 9.23999 11.0223 9.59999 10.2723 9.59999H2.32234C1.57234 9.59999 1.19434 9.23999 1.19434 8.52599V3.47399C1.19434 2.75399 1.57234 2.39999 2.32234 2.39999ZM6.29434 7.55999L10.3383 4.24199C10.4823 4.12199 10.5963 3.84599 10.4163 3.59999C10.2423 3.35399 9.92434 3.34799 9.71434 3.49799L6.29434 5.81399L2.88034 3.49799C2.67034 3.34799 2.35234 3.35399 2.17834 3.59999C1.99834 3.84599 2.11234 4.12199 2.25634 4.24199L6.29434 7.55999Z" fill={(emailInputActive == true || emailOrPhone) ? "#055075" : "#9a9a9a"} />
                        </svg>
                        <p className="placeholder-txt flex-item" style={{ fontSize: (emailInputActive == true || emailOrPhone) ? "10px" : "", color: (emailInputActive == true || emailOrPhone) ? "#055075" : "", transition: "180ms" }}>Email / Phone</p>
                      </div>
                      <input onChange={handleChange("emailOrPhone")} value={emailOrPhone} id="email-input-field" onInput={_.debounce(OnBlurFieldChecker,700)} className={`${(emailInputActive == true || emailOrPhone) ? "active-input-field" : "inactive-input-field"}`} type="email" />
                    </div>
                  ]
                }
                {
                  switchPage == "forgotpassword" && [
                    <div onClick={handleSwitchLogin} className="d-flex m-t-30 m-b-14" style={{ cursor: "pointer" }}>
                      <img className='flex-item' src={Play} style={{
                        width: ' 3vw',
                        height: '3vw',
                        alignSelf: 'center',
                        transform: 'rotate(180deg)',
                        marginRight: '1vw'
                      }} loading='lazy' />
                      <div className="login-user-name">Go Back</div>
                    </div>,
                    <div onClick={toggleInputFields.bind(this, 'email-input-field')} className={`${(emailInputActive == true || forgotPasswordEmail) ? "member-global-input-field m-t-10 m-b-12" : "member-global-input-field d-flex m-t-10 m-b-12"}`} style={{ border: (emailInputActive == true || forgotPasswordEmail) ? "1px solid #055075" : "" }}>
                      {/* style={{(emailInputActive == true || email) ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
                      <div className="flex-item d-flex">
                        <svg className='flex-item m-r-8' width={(emailInputActive == true || forgotPasswordEmail) ? "10" : "16"} height={(emailInputActive == true || forgotPasswordEmail) ? "10" : "16"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.32234 2.39999H10.2723C11.0223 2.39999 11.4003 2.75399 11.4003 3.47399V8.52599C11.4003 9.23999 11.0223 9.59999 10.2723 9.59999H2.32234C1.57234 9.59999 1.19434 9.23999 1.19434 8.52599V3.47399C1.19434 2.75399 1.57234 2.39999 2.32234 2.39999ZM6.29434 7.55999L10.3383 4.24199C10.4823 4.12199 10.5963 3.84599 10.4163 3.59999C10.2423 3.35399 9.92434 3.34799 9.71434 3.49799L6.29434 5.81399L2.88034 3.49799C2.67034 3.34799 2.35234 3.35399 2.17834 3.59999C1.99834 3.84599 2.11234 4.12199 2.25634 4.24199L6.29434 7.55999Z" fill={(emailInputActive == true || forgotPasswordEmail) ? "#055075" : "#9a9a9a"} />
                        </svg>
                        <p className="placeholder-txt flex-item" style={{ fontSize: (emailInputActive == true || forgotPasswordEmail) ? "10px" : "", color: (emailInputActive == true || forgotPasswordEmail) ? "#055075" : "", transition: "180ms" }}>Email</p>
                      </div>
                      <input value={forgotPasswordEmail} onChange={handleChangeForgotPassword} id="email-input-field" className={`${(emailInputActive == true || forgotPasswordEmail) ? "active-input-field" : "inactive-input-field"}`} type="email" />
                    </div>,

                    showError && (
                      <div style={{
                        fontSize: '14px',
                        alignSelf: 'center',
                        textAlign: 'center',
                        margin: '0 auto -1.53vw auto',
                        color: 'rgb(214, 9, 60)',
                      }}>Server is not responding, please try after sometime</div>
                    ),
                    
                    success && (
                      <div style={{
                        fontSize: '14px',
                        alignSelf: 'center',
                        textAlign: 'center',
                        margin: '0 auto -1.53vw auto',
                        color: '#5d883c',
                      }}>Email have been sent, please check inbox.</div>
                    )
                  ]
                }

                {
                  switchPage == "setotppassword" && (
                    <>
                    {
                      loginWithPassword ? (
                        <>
                        <div className="d-flex" style={{marginBottom:"8px",backgroundColor:'#fff',borderRadius:'6px',width:'fit-content'}}>
                          { inputType=="phone"?(<div  style={{fontSize:'16px',padding:'0 6px',borderRadius:'4px 0 0 4px',border:'1px solid #e0e0e0',borderRight:'0'}}>+91 {emailOrPhone}</div>):(<div  style={{fontSize:'16px',padding:'0 6px',borderRadius:'4px 0 0 4px',border:'1px solid #e0e0e0',borderRight:'0'}}>{emailOrPhone}</div>)}
                          <div onClick={handleForgotPasswordPhonEnterAction} style={{fontSize:"14px",cursor:'pointer',color:'#fff',height:'100%',backgroundColor:"#055075",padding:'1px 4px',borderRadius:'0 4px 4px 0'}}>Edit</div>
                        </div>
                        <div onClick={toggleInputFields.bind(this, 'password-input-field')} className={`${(passwordInputActive == true || password) ? "member-global-input-field m-b-12" : "member-global-input-field d-flex m-b-12"}`} style={{ border: (passwordInputActive == true || password) ? "1px solid #055075" : "" }}>
                          <div className="flex-item d-flex">
                            <svg className='m-r-8 flex-item' width={(passwordInputActive == true || password) ? "7" : "13"} height={(passwordInputActive == true || password) ? "10" : "16"} style={{ transition: "180ms" }} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_928_1325)">
                                <path d="M2.5 5.45455H6.5V3.81818C6.5 3.21591 6.30469 2.7017 5.91406 2.27557C5.52344 1.84943 5.05208 1.63636 4.5 1.63636C3.94792 1.63636 3.47656 1.84943 3.08594 2.27557C2.69531 2.7017 2.5 3.21591 2.5 3.81818V5.45455ZM9 6.27273V11.1818C9 11.4091 8.92708 11.6023 8.78125 11.7614C8.63542 11.9205 8.45833 12 8.25 12H0.75C0.541667 12 0.364583 11.9205 0.21875 11.7614C0.0729167 11.6023 0 11.4091 0 11.1818V6.27273C0 6.04545 0.0729167 5.85227 0.21875 5.69318C0.364583 5.53409 0.541667 5.45455 0.75 5.45455H1V3.81818C1 2.77273 1.34375 1.875 2.03125 1.125C2.71875 0.375 3.54167 0 4.5 0C5.45833 0 6.28125 0.375 6.96875 1.125C7.65625 1.875 8 2.77273 8 3.81818V5.45455H8.25C8.45833 5.45455 8.63542 5.53409 8.78125 5.69318C8.92708 5.85227 9 6.04545 9 6.27273Z" fill={(passwordInputActive == true || password) ? "#055075" : "#9a9a9a"} />
                              </g>
                              <defs>
                                <clipPath id="clip0_928_1325">
                                  <rect width="9" height="12" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <p className="placeholder-txt flex-item" style={{ fontSize: (passwordInputActive == true || password) ? "10px" : "", color: (passwordInputActive == true || password) ? "#055075" : "", transition: "180ms" }}>Password</p>
                          </div>
                          <input onChange={handleChange("password")} value={password} id="password-input-field" className={`${(passwordInputActive == true || password) ? "active-input-field" : "inactive-input-field"}`} type="password" />
                        </div>
                        <p onClick={handleSwitchForgotPswd} className='forgot-email-pswd-txt m-b-30'>Forgot Email or Password ?</p>
                        </>
                      ):(
                        <>
                          <div className="d-flex" style={{marginBottom:"8px", marginTop:"30px", backgroundColor:'#fff',borderRadius:'6px',width:'fit-content'}}>
                            <div className='margin-auto' style={{fontSize:'16px',padding:'0 6px',borderRadius:'4px 0 0 4px',border:'1px solid #e0e0e0',borderRight:'0'}}>+91 {emailOrPhone}</div>
                            <div onClick={handleForgotPasswordPhonEnterAction} style={{fontSize:"14px",cursor:'pointer',color:'#fff',height:'100%',backgroundColor:"#055075",padding:'1px 4px',borderRadius:'0 4px 4px 0'}}>Edit</div>
                          </div>
                          <div className="w-full m-t-20" style={{justifyContent:'center'}}>
                           
                          <div style={{color:'#000',fontSize:'16px',marginBottom:'8px'}}>Enter OTP</div>

                            <div id="otp-input">
                              <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                              <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                              <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                              <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                              <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                              <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                            </div>
                            <div style={{marginBottom:0,marginTop:4,display:'flex'}}>
                              <div onClick={sendOtp} style={{cursor:'pointer'}} className='forgot-email-pswd-txt'>Resend Code</div>
                              <div id='some_div' style={{display:'none',marginRight:8,color:'#000'}} className='forgot-email-pswd-txt'></div> 
                            </div>
                          </div>
                        </>
                      )
                    }
                    </>
                  )
                }

              </div>
              <div className='w-full'>
                {
                  switchPage == "signin" ? [
                    <div onClick={validateInput} className="member-button-global btn-center w-full d-flex m-b-16 flex-item">
                      <p className='flex-item'>Next</p>
                    </div>,
                    <div onClick={handleGoogleSignIn} className="member-button-global btn-center w-full d-flex flex-item" style={{ background: "#ffffff" }}>
                      <div className='flex-item d-flex'>
                        <svg className='flex-item m-r-6' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107" />
                          <path d="M3.15332 7.3455L6.43882 9.755C7.32782 7.554 9.48082 6 12.0003 6C13.5298 6 14.9213 6.577 15.9808 7.5195L18.8093 4.691C17.0233 3.0265 14.6343 2 12.0003 2C8.15932 2 4.82832 4.1685 3.15332 7.3455Z" fill="#FF3D00" />
                          <path d="M12.0002 22C14.5832 22 16.9302 21.0115 18.7047 19.404L15.6097 16.785C14.5719 17.5742 13.3039 18.001 12.0002 18C9.39916 18 7.19066 16.3415 6.35866 14.027L3.09766 16.5395C4.75266 19.778 8.11366 22 12.0002 22Z" fill="#4CAF50" />
                          <path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2" />
                        </svg>
                        <p className='flex-item' style={{ color: "#2b2b2b", fontWeight: "400" }}>Continue with Google</p>
                      </div>
                    </div>
                  ]: switchPage == "setotppassword"?[
                    <>
                      {
                        loginWithPassword?(
                          <>
                            <div onClick={()=>onSubmit()} className="member-button-global btn-center w-full d-flex m-b-16 flex-item">
                              <p className='flex-item'>Signin</p>
                            </div>
                            {
                              inputType == 'phone' && (
                                <p onClick={validateInput} className='forgot-email-pswd-txt' style={{alignSelf:'center', margin: "0 auto 0 auto",width:'fit-content'}}>Login with OTP</p>
                              )
                            }
                          </>
                        ):(
                          <>
                            <div onClick={isButtonLock ? null : verifyOtp} className={`member-button-global ${isButtonLock && 'member-button-global-inactive'} btn-center w-full d-flex m-b-16 flex-item`}>
                              <p className='flex-item'>Submit OTP</p>
                            </div>
                            <p className='forgot-email-pswd-txt' style={{alignSelf:'center', margin: "0 auto 0 auto",width:'fit-content'}} onClick={()=>setLoginPassword(true)}>Login with password</p>
                        </>
                        )
                      }
                    </>
                  ] : [
                    <div className='w-full d-flex'>
                      <div onClick={isButtonLock ? null : handleForgotPasswordAction} className={`member-button-global ${isButtonLock && 'member-button-global-inactive'} btn-center w-full d-flex flex-item`}>
                        <p className='flex-item'>Submit</p>
                      </div>
                    </div>
                  ]
                }
                {/* <div className='w-full space-between d-flex p-l-4 p-r-4 m-t-18 m-b-18'>
                  <div className='or-line-saperator flex-item' />
                  <p className='or-text flex-item'>Don't have any account ?</p>
                  <div className='or-line-saperator flex-item' />
                </div>

                <div className="member-button-global btn-center w-full d-flex flex-item m-b-20" style={{ background: "#ffffff" }}>
                  <p className='flex-item' style={{ color: "#055075" }}>Sign Up</p>
                </div> */}
              </div>
            </div>
          </div>

          <div className='d-flex' style={{position: "absolute", bottom: 0, left: 0, right: 0, marginBottom: "14px"}}>
            <div className='d-flex-wrap flex-item' style={{margin: "0 auto"}}>
              <Link to="/terms-condition" style={{textDecoration:"none"}}>
                <p className='flex-item text-highlighter cursor-pointer m-r-40'>Terms and Conditions</p>
              </Link>
              <Link to="/privacy-policy" style={{textDecoration:"none"}}>
                <p className='flex-item text-highlighter cursor-pointer'>Privacy Policy</p>
              </Link>
            </div>
          </div>
          <SpinnerLoader />
          {performRedirect()}
        </div>
      </div>
    )
  }
  else {
    return (
      <div onLoad={onLoadFunctions} className="login-window">
        <div className="login-container">
          <div className="login-background">
            <img className="background-img" alt="bg-img" src={`https://workoutplusasset.s3.ap-south-1.amazonaws.com/workout-plus-signin-vector.png`} loading='lazy' />
          </div>
          <img className="logo-instafit" src={Logo} loading='lazy' />
          <div className="login-page" style={{position:"relative"}}>
            <div className="welcome-message">Welcome</div>
            <div className="welcome-quote">Shift the focus from what your body looks like to what it can do.</div>
            <div className="header-divider" />
            {
              showError === true && (
                <div className="alert-text">Incorrect Email or Password</div>
              )
            }

            {
              switchPage === "signin" && (
                <div id="signin" className="pswd-recovery">
                  <div className="login-user-data-container" >
                    <div className="login-user-name">Email / Phone</div>
                    <div onClick={handleShowInfo} className="login-info-icon">
                      <img className="icon-info" src={Info} loading='lazy' />
                    </div>
                    {
                      showInfo === true && (
                        <div className="login-email-suggestion">
                          <img src={Play} className="arrow-logo" loading='lazy' />
                          <div className="text-input-field" style={{padding:'0 8px 0 8px'}}>
                            <div className="text-input" style={{whiteSpace:'nowrap'}}>{"Please enter valid email / number"}</div>
                          </div>
                        </div>
                      )
                    }

                  </div>

                  <div className="input-field-container">
                    <input onChange={handleChange("emailOrPhone")} onInput={_.debounce(OnBlurFieldChecker,700)} value={emailOrPhone} className="input-field" id="email" type="email" placeholder="Enter email or phone number" autocomplete="off" autoFocus />
                  </div>
                  <div className="signin-action-container">
                      <div className="signin-button" onClick={()=>validateInput()} style={!inputType ? {
                      backgroundColor: '#e0e0e0',
                      pointerEvents: 'none',
                      margin: "34px auto 34px auto",
                    } : { margin: "34px auto 34px auto" }}>
                        <div className="signin-text" style={!inputType ? {
                        color: '#ffffff'
                      } : {}}>
                          Next
                        </div>
                      </div>
                     
                    <div className="or-saperator">Or Sign In Using</div>
                    <div className="social-media-container">
                      {/* <img className="media-icon" alt="facebook" src={Facebook}/>
                      <img className="media-icon" alt="twitter" src={Twitter}/> */}
                      <img onClick={handleGoogleSignIn} className="media-icon shadow-image-btn" alt="google" src={Google} loading='lazy' />
                    </div>
                  </div>
                </div>
              )
            }

            {
              switchPage === "forgotpassword" && (
                <div id="forgot-pswd" className="pswd-recovery">
                  <div onClick={handleSwitchLogin} className="login-user-data-container" style={{ cursor: "pointer" }}>
                    <img src={Play} className="icon-size" loading='lazy' />
                    <div className="login-user-name">Go Back</div>
                  </div>
                  <div className="login-user-data-container d-flex" style={{ marginTop: "3vw" }}>
                    <svg className='flex-item m-r-8' width="24" height="24" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.32136 2.3999H10.2714C11.0214 2.3999 11.3994 2.7539 11.3994 3.4739V8.5259C11.3994 9.2399 11.0214 9.5999 10.2714 9.5999H2.32136C1.57136 9.5999 1.19336 9.2399 1.19336 8.5259V3.4739C1.19336 2.7539 1.57136 2.3999 2.32136 2.3999ZM6.29336 7.5599L10.3374 4.2419C10.4814 4.1219 10.5954 3.8459 10.4154 3.5999C10.2414 3.3539 9.92336 3.3479 9.71336 3.4979L6.29336 5.8139L2.87936 3.4979C2.66936 3.3479 2.35136 3.3539 2.17736 3.5999C1.99736 3.8459 2.11136 4.1219 2.25536 4.2419L6.29336 7.5599Z" fill="#4D4D4D"/>
                    </svg>
                    <div className="login-user-name flex-item">E-mail</div>
                  </div>
                  <div className="input-field-container">
                    <input className="input-field" value={forgotPasswordEmail} onChange={handleChangeForgotPassword} type="email" placeholder="Type your email-id" autocomplete="off" autoFocus />
                  </div>
                  <div className="signin-action-container">
                    <div onClick={handleForgotPasswordAction} className="signin-button" style={isButtonLock ? {
                      backgroundColor: '#e0e0e0',
                      pointerEvents: 'none',
                      margin: "20px auto"
                    } : {margin: "20px auto"}}>
                      <div className="signin-text" style={isButtonLock ? {
                        color: '#6d6d6d'
                      } : {}}>
                        Submit
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

           {
              switchPage === "setotppassword" && (
                <div id="forgot-pswd" className="pswd-recovery">
                <div onClick={handleSwitchLogin} className="login-user-data-container" style={{ cursor: "pointer" }}>
                  <img src={Play} className="icon-size" loading='lazy' />
                  <div className="login-user-name">Go Back</div>
                </div>
                {/* {
                  loginWithPassword ? (
                  <> */}
                    <div className="login-user-data-container" style={{marginBottom:"0.5vw" }}>
                      {
                        inputType=="phone"?(
                          <div className="login-user-name" style={{fontSize:"16px"}}>+91 {emailOrPhone}</div>
                        ):(
                          <div className="login-user-name" style={{fontSize:"16px"}}>{emailOrPhone}</div>
                        )
                      }
                      <div onClick={handleForgotPasswordPhonEnterAction} className="login-user-name" style={{fontSize:"16px",cursor:'pointer',color:'rgba(255, 94, 0, 0.616)',textDecoration:'underline'}}>Edit</div>
                    </div>
                    <div className="login-user-data-container">
                      <div className="login-user-name">Password</div>
                    </div>
                    <div className="input-field-container">
                      <input onChange={handleChange("password")} value={password} className="input-field" id="password" type="password" placeholder="Type your password" />
                    </div>
                    <div className='signin-action-container'>
                    <div className="forgot-password color-orange" onClick={handleSwitchForgotPswd}>forgot password?</div>
                    {
                      isOTPLogin && (
                        <>
                          <div className="input-field-container" style={{marginBottom:"60px"}}>
                            <div>
                              {/* <div className="login-user-data-container" style={{marginBottom:"0.5vw" }}>
                                <div className="login-user-name" style={{fontSize:"16px"}}>+91 {emailOrPhone}</div>
                                <div onClick={handleForgotPasswordPhonEnterAction} className="login-user-name" style={{fontSize:"16px",cursor:'pointer',color:'rgba(255, 94, 0, 0.616)',textDecoration:'underline'}}>Edit</div>
                              </div> */}
                              <div className="login-user-data-container" style={{marginBottom:"0.5vw",marginTop:0 }}>
                                <div className="login-user-name">Enter OTP</div>
                              </div>
                              <div id="otp-input">
                                <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                                <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                                <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                                <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                                <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                                <input type="number" step="1" min="0" max="9" autocomplete="no" pattern="\d*"/>
                              </div>
                              <div className="login-user-data-container d-flex space-between" style={{marginTop:"6px"}}>
                                <div className='flex-item' onClick={sendOtp} style={{cursor:'pointer'}}>Resend Code</div>
                                <div id='some_div' style={{display:'none', width: "auto"}} className="flex-item"></div> 
                              </div>
                            </div>
                          </div>
                          {/* <div className="signin-action-container">
                            <div onClick={verifyOtp} className="signin-button" style={isButtonLock ? {
                              backgroundColor: '#e0e0e0',
                              pointerEvents: 'none'
                            } : {}}>
                              <div className="signin-text" style={isButtonLock ? {
                                color: '#6d6d6d'
                              } : {}}>
                                Submit OTP
                              </div>
                            </div>
                            <div className="forgot-password" style={{alignSelf:'center', margin: "0 auto 0 auto",width:'fit-content'}} onClick={()=>setLoginPassword(true)}>Login with password</div>
                          </div> */}
                        </>
                      )
                    }
                    
                    <div className="signin-button" style={{margin: "50px auto 10px auto"}} onClick={()=>onSubmit()}>
                      <div className="signin-text">
                        Sign In
                      </div>
                    </div>

                    
                    {
                      !loginWithPassword && !isOTPLogin && (
                        <div className='d-flex w-full m-t-20'>
                          <p className='flex-item margin-auto'>OR</p>
                        </div>
                      )
                    }

                    {
                      !loginWithPassword && !isOTPLogin && (
                        <div className="signin-button button-blue" style={{margin: "20px auto 0 auto", background:"#9a9a9a"}} onClick={()=>handleGenerateOtp()}>
                          <div className="signin-text">
                            Login with OTP
                          </div>
                        </div>
                      )
                    }

                    {/* {
                      inputType=="phone" && (
                        <div className="forgot-password" style={{alignSelf:'center', margin: "0 auto 0 auto",width:'fit-content', color:"#f15d22"}} onClick={validateInput}>Login with OTP</div>
                      )
                    } */}
                    </div>
                  {/* </>
                  ):(
                    <> */}
                    {/* </>
                  )
                } */}
              </div>
              )
            }

            <div className='d-flex' style={{position: "absolute", bottom: 0, left: 0, right: 0, marginBottom: "14px"}}>
              <div className='d-flex flex-item' style={{margin: "0 auto"}}>
                <Link to="/terms-condition" style={{textDecoration:"none"}}>
                  <p className='flex-item text-highlighter cursor-pointer m-r-20'>Terms and Conditions</p>
                </Link>
                <Link to="/privacy-policy" style={{textDecoration:"none"}}>
                  <p className='flex-item text-highlighter cursor-pointer'>Privacy Policy</p>
                </Link>
              </div>
            </div>

          </div>  
        </div>
        <SpinnerLoader />
        {performRedirect()}
      </div>
    )
  }
}


export default SigninRoute;











