import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ModalBanner from '../../../assets/modal-banner-1.png';
import { isAuthenticated } from "../../../auth";
import { startLoader, stopLoader } from "../../../lib/utils";
import { addParameter, updateParameter } from "../../Parameters/helper/api";

const ManageParameter = (props) => {

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const [currentElementId, setCurrentElementId] = useState("");
  const [ParameterTypeOpenDropdown, setParameterTypeOpenDropdown] = useState(false);
  const [parameter, setParameter] = useState({
    type: '',
    name: '',
    unit: "",
    parameterId: "",
    formData: new FormData()
  });
  const { formData } = parameter;
  const [parameterList, setParameterList] = useState([]);
  const [isParameterAddOpen, setParameterAddOpen] = useState(false);
  const { user, token } = isAuthenticated();
  const [isParameterUnitOpen, setParameterUnitOpen] = useState(false);



  const toggleParameterUnitOpen = () => {
    setParameterUnitOpen(!isParameterUnitOpen)
  }

  const getParameterUnit = unit => {
    setParameter({
      ...parameter,
      unit
    });
    toggleParameterUnitOpen()
  }




  const AddParameters = async event => {
    try {
      event.preventDefault();
      await startLoader();
      let List = []
      await Promise.all(parameterList.map(data => {
        List.push({
          ...data,
          type: parameter.type
        });
      }));
      formData.set("parameterList", JSON.stringify(List));
      await addParameter(user._id, token, formData);
      props.toggleAddParameterModal()
      if (path == "/Parameters") history.push("/Parameters");
      await stopLoader();
      event.stopPropagation();
    } catch (error) {
      await stopLoader()
    }
  }

  const UpdateParameter = async event => {
    try {
      event.preventDefault();
      await startLoader()
      if (parameter.name && parameter.parameterId) {
        formData.set("name", parameter.name);
        if (parameter.type == "exerciseType") {
          if (!parameter.unit) return "";
          formData.set("unit", parameter.unit);
        }
        await updateParameter(user._id, token, parameter.parameterId, formData)
        props.toggleAddParameterModal()
        if (path == "/Parameters") history.push("/Parameters");
      }
      await stopLoader();
      event.stopPropagation();
    } catch (error) {
      await stopLoader()
    }
  }

  const handleChange = name => event => {
    setParameter({
      ...parameter,
      [name]: event.target.value
    })
  }

  const handleAddParameter = event => {
    event.preventDefault();
    if (parameter.name) {
      let data = {
        name: parameter.name
      }
      if (parameter.type == "exerciseType") {
        if (!parameter.unit) return "";
        data.unit = parameter.unit;
      }
      setParameterList([...parameterList, {
        ...data
      }]);
    }
    event.stopPropagation();
  }

  const removeParameter = index => event => {
    event.preventDefault();
    setParameterList(oldstate => {
      oldstate.splice(index, 1)
      return [...oldstate]
    });
    event.stopPropagation();
  }


  const getSelectedParameterType = (parameterType) => {
    if (parameterType) {
      setParameter({
        ...parameter,
        type: parameterType
      })
      setParameterTypeOpenDropdown(false);
    }
  }

  const toggleAddParameters = () => {
    if (isParameterAddOpen) {
      setParameterAddOpen(false)
    } else {
      setParameterAddOpen(true)
    }
  }

  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (id == "parameter-unit") toggleParameterUnitOpen();
    if (id == 'parameter-type') setParameterTypeOpenDropdown(!ParameterTypeOpenDropdown);
    if (event) {
      let interval = setInterval(() => {
        let element = event.target.querySelector('input');
        if (element) {
          element.focus();
          clearInterval(interval)
        }
      }, 0);
    }
  }


  const closeCurrentElement = event => {
    setCurrentElementId("")
  }

  useEffect(() => {
    if (props.parameter && props.isEdit) {
      setParameter({
        ...parameter,
        type: props.parameter.type,
        name: props.parameter.name,
        parameterId: props.parameter.parameterId
      })
    } else {
      setParameter({
        ...parameter,
        name: "",
        parameterId: ""
      });
      setParameterList([]);
    }
  }, [props])

  return (
    <div className="mobile-bottom-modal-container" onClick={props.toggleAddParameterModal}>
      <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
        <div onClick={props.toggleAddParameterModal} className="w-full">
          <div className="draggable-pointer" />
        </div>
        <p className="flex-item modal-header-text">{props.isEdit ? "Edit" : "Add"} Parameter</p>
        <div onClick={e => e.stopPropagation()} className="global-modal-scroll-container-mob" style={{minHeight:"30vh", maxHeight:"70vh"}}>
          <div onClick={!props.isEdit ? toggleInputFields('parameter-type') : null} className={`${(currentElementId == "parameter-type" || parameter.type || props.isEdit) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: ((currentElementId == "parameter-type" || parameter.type) && !props.isEdit) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == "parameter-type" || parameter.type || props.isEdit) ? "18" : "18"} height={(currentElementId == "parameter-type" || parameter.type) ? "18" : "18"} style={{ transition: "180ms" }} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.73333 1H3.1C3.00717 1 2.91815 1.03687 2.85251 1.10251C2.78687 1.16815 2.75 1.25717 2.75 1.35V6.48333C2.75 6.5293 2.75905 6.57481 2.77664 6.61727C2.79423 6.65974 2.82001 6.69832 2.85251 6.73082C2.88501 6.76332 2.9236 6.7891 2.96606 6.80669C3.00852 6.82428 3.05404 6.83333 3.1 6.83333H4.73333C4.7793 6.83333 4.82481 6.82428 4.86727 6.80669C4.90974 6.7891 4.94832 6.76332 4.98082 6.73082C5.01332 6.69832 5.0391 6.65974 5.05669 6.61727C5.07428 6.57481 5.08333 6.5293 5.08333 6.48333V1.35C5.08333 1.25717 5.04646 1.16815 4.98082 1.10251C4.91518 1.03687 4.82616 1 4.73333 1V1ZM11.7333 1H10.1C10.0072 1 9.91815 1.03687 9.85251 1.10251C9.78687 1.16815 9.75 1.25717 9.75 1.35V6.48333C9.75 6.5293 9.75905 6.57481 9.77664 6.61727C9.79423 6.65974 9.82001 6.69832 9.85251 6.73082C9.88501 6.76332 9.9236 6.7891 9.96606 6.80669C10.0085 6.82428 10.054 6.83333 10.1 6.83333H11.7333C11.7793 6.83333 11.8248 6.82428 11.8673 6.80669C11.9097 6.7891 11.9483 6.76332 11.9808 6.73082C12.0133 6.69832 12.0391 6.65974 12.0567 6.61727C12.0743 6.57481 12.0833 6.5293 12.0833 6.48333V1.35C12.0833 1.25717 12.0465 1.16815 11.9808 1.10251C11.9152 1.03687 11.8262 1 11.7333 1V1Z" stroke={((currentElementId == "parameter-type" || parameter.type) && !props.isEdit) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.08333 3.91675H9.75M1 5.31675V2.51675C1 2.42392 1.03687 2.3349 1.10251 2.26926C1.16815 2.20362 1.25717 2.16675 1.35 2.16675H2.4C2.49283 2.16675 2.58185 2.20362 2.64749 2.26926C2.71313 2.3349 2.75 2.42392 2.75 2.51675V5.31675C2.75 5.40957 2.71313 5.4986 2.64749 5.56424C2.58185 5.62987 2.49283 5.66675 2.4 5.66675H1.35C1.25717 5.66675 1.16815 5.62987 1.10251 5.56424C1.03687 5.4986 1 5.40957 1 5.31675V5.31675ZM13.8333 5.31675V2.51675C13.8333 2.42392 13.7965 2.3349 13.7308 2.26926C13.6652 2.20362 13.5762 2.16675 13.4833 2.16675H12.4333C12.3405 2.16675 12.2515 2.20362 12.1858 2.26926C12.1202 2.3349 12.0833 2.42392 12.0833 2.51675V5.31675C12.0833 5.40957 12.1202 5.4986 12.1858 5.56424C12.2515 5.62987 12.3405 5.66675 12.4333 5.66675H13.4833C13.5762 5.66675 13.6652 5.62987 13.7308 5.56424C13.7965 5.4986 13.8333 5.40957 13.8333 5.31675V5.31675Z" stroke={((currentElementId == "parameter-type" || parameter.type) && !props.isEdit) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "parameter-type" || parameter.type || props.isEdit) ? "10px" : "14px", color: ((currentElementId == "parameter-type" || parameter.type) && !props.isEdit) ? "#055075" : "", transition: "180ms" }}>Parameter Type*</p>
            </div>
            {
              !props.isEdit && <div className='input-field-arrow-dropdown' style={{ margin: "12px 8px 4px 0", transition: "180ms" }}>
                <svg className="flex-item" style={{ transform: ParameterTypeOpenDropdown ? "rotate(180deg)" : "", transition: "180ms" }} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round" />
                </svg>
              </div>
            }
            <input id="parameter-type" className={`${(currentElementId == "parameter-type" || parameter.type || props.isEdit) ? "active-input-field" : "inactive-input-field"}`} type="text" placeholder="Select Parameter Type" value={parameter.type == "exerciseImage" ? "Exercise Image" : parameter.type == "exerciseType" ? "Exercise Type" : parameter.type == "exerciseLevel" ? "Exercise Level" : parameter.type == "exerciseName" ? "Exercise Name" : parameter.type == "targetMuscle" ? "Target Muscle" : ""} style={{ pointerEvents: "none", textTransform: 'capitalize' }} />
            {/* Dropdown Container */}
            {
              (ParameterTypeOpenDropdown && !props.isEdit) && (
                <div className="global-input-field-arrow-dropdown global-input-field-arrow-dropdown-height-1">
                  <div onClick={getSelectedParameterType.bind(this, "exerciseLevel")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Exercise Level</p>
                  </div>
                  <div onClick={getSelectedParameterType.bind(this, "exerciseType")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Exercise Type</p>
                  </div>
                  <div onClick={getSelectedParameterType.bind(this, "targetMuscle")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Target Muscles</p>
                  </div>
                  <div onClick={getSelectedParameterType.bind(this, "exerciseImage")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Exercise Image</p>
                  </div>
                  <div onClick={getSelectedParameterType.bind(this, "exerciseName")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Exercise Name</p>
                  </div>

                </div>
              )
            }
          </div>
          {
            (parameter.type && !props.isEdit) && (
              <div className="w-full">
                <div onClick={toggleAddParameters} className="d-flex m-b-16">
                  <p className="flex-item m-r-12 fs-16" style={{ fontWeight: "600" }}>{parameter.type == "exerciseImage" ? "Exercise Image" : parameter.type == "exerciseType" ? "Exercise Type" : parameter.type == "exerciseLevel" ? "Exercise Level" : parameter.type == "exerciseName" ? "Exercise Name" : parameter.type == "targetMuscle" ? "Target Muscle" : ""}</p>
                  <div className='bell-menu-container header-menu-active cursor-pointer d-flex' style={{ background: isParameterAddOpen ? "#055075" : "#ffffff" }}>
                    <svg className="menu flex-item" style={{ transform: isParameterAddOpen ? "rotate(45deg)" : "", transition: "180ms" }} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="6.61328" width="2" height="15" rx="1" fill={isParameterAddOpen ? "#FFFFFF" : "#9A9A9A"} />
                      <rect x="15.1035" y="6.43555" width="2" height="15" rx="1" transform="rotate(89.5058 15.1035 6.43555)" fill={isParameterAddOpen ? "#FFFFFF" : "#9A9A9A"} />
                    </svg>
                  </div>
                </div>
              </div>
            )
          }

          {
            (isParameterAddOpen || props.isEdit) && (
              <div className="w-full m-b-20">
                <div onClick={toggleInputFields('parameter-name')} className={`${(currentElementId == 'parameter-name' || parameter.name) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'parameter-name' || parameter.name) ? "1px solid #055075" : "" }}>
                  <div className="flex-item d-flex">
                    <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'parameter-name' || parameter.name) ? "10px" : "14px", color: (currentElementId == 'parameter-name' || parameter.name) ? "#055075" : "", transition: "180ms" }}>{parameter.type == "exerciseImage" ? "Exercise Image" : parameter.type == "exerciseType" ? "Exercise Type" : parameter.type == "exerciseLevel" ? "Exercise Level" : parameter.type == "exerciseName" ? "Exercise Name" : parameter.type == "targetMuscle" ? "Target Muscle" : ""}</p>
                  </div>
                  <input value={parameter.name} onChange={handleChange("name")} className={`${(currentElementId == 'parameter-name' || parameter.name) ? "active-input-field" : "inactive-input-field"}`} type="text" />
                </div>
                {
                  parameter.type == "exerciseType" && (
                    <div onClick={toggleInputFields('parameter-unit')} className={`${(currentElementId == "parameter-unit" || parameter.unit || props.isEdit) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: ((currentElementId == "parameter-unit" || parameter.unit) && !props.isEdit) ? "1px solid #055075" : "" }}>
                      <div className="flex-item d-flex">
                        <svg className="flex-item m-r-8" width={(currentElementId == "parameter-unit" || parameter.unit || props.isEdit) ? "18" : "18"} height={(currentElementId == "parameter-unit" || parameter.unit) ? "18" : "18"} style={{ transition: "180ms" }} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.73333 1H3.1C3.00717 1 2.91815 1.03687 2.85251 1.10251C2.78687 1.16815 2.75 1.25717 2.75 1.35V6.48333C2.75 6.5293 2.75905 6.57481 2.77664 6.61727C2.79423 6.65974 2.82001 6.69832 2.85251 6.73082C2.88501 6.76332 2.9236 6.7891 2.96606 6.80669C3.00852 6.82428 3.05404 6.83333 3.1 6.83333H4.73333C4.7793 6.83333 4.82481 6.82428 4.86727 6.80669C4.90974 6.7891 4.94832 6.76332 4.98082 6.73082C5.01332 6.69832 5.0391 6.65974 5.05669 6.61727C5.07428 6.57481 5.08333 6.5293 5.08333 6.48333V1.35C5.08333 1.25717 5.04646 1.16815 4.98082 1.10251C4.91518 1.03687 4.82616 1 4.73333 1V1ZM11.7333 1H10.1C10.0072 1 9.91815 1.03687 9.85251 1.10251C9.78687 1.16815 9.75 1.25717 9.75 1.35V6.48333C9.75 6.5293 9.75905 6.57481 9.77664 6.61727C9.79423 6.65974 9.82001 6.69832 9.85251 6.73082C9.88501 6.76332 9.9236 6.7891 9.96606 6.80669C10.0085 6.82428 10.054 6.83333 10.1 6.83333H11.7333C11.7793 6.83333 11.8248 6.82428 11.8673 6.80669C11.9097 6.7891 11.9483 6.76332 11.9808 6.73082C12.0133 6.69832 12.0391 6.65974 12.0567 6.61727C12.0743 6.57481 12.0833 6.5293 12.0833 6.48333V1.35C12.0833 1.25717 12.0465 1.16815 11.9808 1.10251C11.9152 1.03687 11.8262 1 11.7333 1V1Z" stroke={((currentElementId == "parameter-type" || parameter.unit) && !props.isEdit) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M5.08333 3.91675H9.75M1 5.31675V2.51675C1 2.42392 1.03687 2.3349 1.10251 2.26926C1.16815 2.20362 1.25717 2.16675 1.35 2.16675H2.4C2.49283 2.16675 2.58185 2.20362 2.64749 2.26926C2.71313 2.3349 2.75 2.42392 2.75 2.51675V5.31675C2.75 5.40957 2.71313 5.4986 2.64749 5.56424C2.58185 5.62987 2.49283 5.66675 2.4 5.66675H1.35C1.25717 5.66675 1.16815 5.62987 1.10251 5.56424C1.03687 5.4986 1 5.40957 1 5.31675V5.31675ZM13.8333 5.31675V2.51675C13.8333 2.42392 13.7965 2.3349 13.7308 2.26926C13.6652 2.20362 13.5762 2.16675 13.4833 2.16675H12.4333C12.3405 2.16675 12.2515 2.20362 12.1858 2.26926C12.1202 2.3349 12.0833 2.42392 12.0833 2.51675V5.31675C12.0833 5.40957 12.1202 5.4986 12.1858 5.56424C12.2515 5.62987 12.3405 5.66675 12.4333 5.66675H13.4833C13.5762 5.66675 13.6652 5.62987 13.7308 5.56424C13.7965 5.4986 13.8333 5.40957 13.8333 5.31675V5.31675Z" stroke={((currentElementId == "parameter-type" || parameter.unit) && !props.isEdit) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "parameter-type" || parameter.unit || props.isEdit) ? "10px" : "14px", color: ((currentElementId == "parameter-type" || parameter.unit) && !props.isEdit) ? "#055075" : "", transition: "180ms" }}>Parameter Type*</p>
                      </div>
                      <div className='input-field-arrow-dropdown' style={{ margin: "12px 8px 4px 0", transition: "180ms" }}>
                        <svg className="flex-item" style={{ transform: ParameterTypeOpenDropdown ? "rotate(180deg)" : "", transition: "180ms" }} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round" />
                        </svg>
                      </div>
                      <input id="parameter-unit" className={`${(currentElementId == "parameter-unit" || parameter.unit || props.isEdit) ? "active-input-field" : "inactive-input-field"}`} type="text" placeholder="Select Parameter Type" value={parameter.unit} style={{ pointerEvents: "none", textTransform: 'capitalize' }} />
                      {/* Dropdown Container */}
                      {
                        (isParameterUnitOpen) && (
                          <div className="global-input-field-arrow-dropdown global-input-field-arrow-dropdown-height-1">
                            <div onClick={getParameterUnit.bind(this, "number")} className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">Number</p>
                            </div>
                            <div onClick={getParameterUnit.bind(this, "time")} className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">Time</p>
                            </div>
                            <div onClick={getParameterUnit.bind(this, "weight")} className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">Weight</p>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                }
                <div onClick={props.isEdit ? UpdateParameter : handleAddParameter} className="member-button-global btn-center d-flex" style={{ width: "100px", margin: "12px 0 0 auto" }}>
                  <p className="flex-item">{props.isEdit ? "Save" : "Add"}</p>
                </div>
              </div>
            )
          }

          {
            !props.isEdit && parameterList.map((data, index) => {
              return (
                <div className="w-full space-between m-b-20 selected-gym-container d-flex">
                  <div className="flex-item" style={{ width: "90%" }}>
                    <p className="fs-16">{data.name}</p>
                    {
                      (data.unit && parameter.type == "exerciseType") && (
                        <p className="fs-14">Unit- {data.unit}</p>
                      )
                    }
                  </div>
                  <div onClick={removeParameter(index)} className='bell-menu-container header-menu-active cursor-pointer flex-item d-flex' style={{ alignSelf: "flex-start" }}>
                    <svg className="bell flex-item" width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 10.5V11H28V10.5C28 9.43913 27.5786 8.42172 26.8284 7.67157C26.0783 6.92143 25.0609 6.5 24 6.5C22.9391 6.5 21.9217 6.92143 21.1716 7.67157C20.4214 8.42172 20 9.43913 20 10.5ZM17.5 11V10.5C17.5 8.77609 18.1848 7.12279 19.4038 5.90381C20.6228 4.68482 22.2761 4 24 4C25.7239 4 27.3772 4.68482 28.5962 5.90381C29.8152 7.12279 30.5 8.77609 30.5 10.5V11H41.75C42.0815 11 42.3995 11.1317 42.6339 11.3661C42.8683 11.6005 43 11.9185 43 12.25C43 12.5815 42.8683 12.8995 42.6339 13.1339C42.3995 13.3683 42.0815 13.5 41.75 13.5H38.833L36.833 37.356C36.681 39.1676 35.854 40.856 34.5158 42.0866C33.1776 43.3172 31.426 44.0001 29.608 44H18.392C16.5742 43.9998 14.8228 43.3168 13.4848 42.0863C12.1468 40.8557 11.3199 39.1674 11.168 37.356L9.168 13.5H6.25C5.91848 13.5 5.60054 13.3683 5.36612 13.1339C5.1317 12.8995 5 12.5815 5 12.25C5 11.9185 5.1317 11.6005 5.36612 11.3661C5.60054 11.1317 5.91848 11 6.25 11H17.5ZM21.5 20.25C21.5 20.0858 21.4677 19.9233 21.4049 19.7716C21.342 19.62 21.25 19.4822 21.1339 19.3661C21.0178 19.25 20.88 19.158 20.7284 19.0951C20.5767 19.0323 20.4142 19 20.25 19C20.0858 19 19.9233 19.0323 19.7716 19.0951C19.62 19.158 19.4822 19.25 19.3661 19.3661C19.25 19.4822 19.158 19.62 19.0951 19.7716C19.0323 19.9233 19 20.0858 19 20.25V34.75C19 34.9142 19.0323 35.0767 19.0951 35.2284C19.158 35.38 19.25 35.5178 19.3661 35.6339C19.4822 35.75 19.62 35.842 19.7716 35.9049C19.9233 35.9677 20.0858 36 20.25 36C20.4142 36 20.5767 35.9677 20.7284 35.9049C20.88 35.842 21.0178 35.75 21.1339 35.6339C21.25 35.5178 21.342 35.38 21.4049 35.2284C21.4677 35.0767 21.5 34.9142 21.5 34.75V20.25ZM27.75 19C27.06 19 26.5 19.56 26.5 20.25V34.75C26.5 35.0815 26.6317 35.3995 26.8661 35.6339C27.1005 35.8683 27.4185 36 27.75 36C28.0815 36 28.3995 35.8683 28.6339 35.6339C28.8683 35.3995 29 35.0815 29 34.75V20.25C29 19.56 28.44 19 27.75 19Z" fill="#2b2b2b" />
                    </svg>
                  </div>
                </div>
              )
            })
          }

         
        </div>
        {
            (!props.isEdit && parameterList.length > 0) && (
              <div onClick={AddParameters} className="member-button-global btn-center d-flex" style={{ width: "100%", margin: "20px auto 12px auto" }}>
                <p className="flex-item">Add</p>
              </div>
            )
          }
      </div>
    </div>
  )
}

export default ManageParameter;