import {API} from '../../backend';
import { objectToQueryString } from '../../lib/utils';


export const getSearchHistory=(userId,token,searchhistoryId)=>{
  return fetch(`${API}/search-history/${userId}/${searchhistoryId}`,{
    method:"GET",
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(res=>res.json()).catch(console.error)
}


export const getSearchHistories=(userId,token,query)=>{
  let str=objectToQueryString(query)
  return fetch(`${API}/search-histories/${userId}?${str}`,{
    method:"GET",
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(res=>res.json()).catch(console.error)
}



export const addSearchHistory=(userId,token,body)=>{
  return fetch(`${API}/add/search-history/${userId}`,{
    method:"POST",
    headers:{
      'Content-Type':"application/json",
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    },
    body:JSON.stringify(body)
  }).then(res=>res.json()).catch(console.error)
}


export const getSearchHistoryCount=(userId,token,query)=>{
  let str=objectToQueryString(query)
  return fetch(`${API}/search-history/count/${userId}`,{
    method:"GET",
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(res=>res.json()).catch(console.error)
}


export const removeSearchHistory=(userId,token,searchhistoryId)=>{
  return fetch(`${API}/remove/search-history/${userId}/${searchhistoryId}`,{
    method:"DELETE",
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(res=>res.json()).catch(console.error)
}

export const removeAllSearchHistory=(userId,token)=>{
  return fetch(`${API}/remove-all/search-history/${userId}`,{
    method:"DELETE",
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(res=>res.json()).catch(console.error)
}