import React, { useEffect, useState } from 'react';

const TOASTCOLORS={
  success:"#2CB520",
  info: "#1E87D3",
  warning: "#FF7A00",
  error: "#FF0000"
};

export const ToastPropObject ={
  message:"Success!",
  duration:3000,
  type:'success'
}

export const ToastType ={
  success:"success",
  info: "info",
  warning: "warning",
  error: "error"
}

export const ToastTime={
  SHORT:3000,
  LONG:5000
}

const ToastMessage = ( { toast,render  } ) => {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if(toast){
      setIsVisible(true);
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, toast.duration);
      return () => clearTimeout(timeout);
    }else{
      setIsVisible(false);
    }
  }, [toast,render]);

  const handleToastClose = () => {
    setIsVisible(false);
  };

  return(
   <>
    {
      (isVisible && toast) && (
        <div className='global-toast-message space-between bottom-slider d-flex'>
          <div className='flex-item h-full d-flex' style={{width: "90%"}}>
            <div className='toast-message-stripes m-r-14 flex-item' style={{background: TOASTCOLORS[toast.type]}}/>
            {
              toast.type == "success" ? (
                <svg className='flex-item m-r-14' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="#2CB520"/>
                  <path d="M5 11.6667L8.47368 16L17 7" stroke="#2CB520" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
              ) : toast.type == "info" ? (
                <svg className='flex-item m-r-14' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="#1E87D3"/>
                  <path d="M8.80078 9.54666H11.1474V15.8533" stroke="#1E87D3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.4707 16H13.5307" stroke="#1E87D3" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M10.9995 7.34667C10.7674 7.34667 10.5406 7.27785 10.3476 7.14892C10.1547 7.02 10.0043 6.83675 9.91549 6.62235C9.82668 6.40795 9.80344 6.17203 9.84872 5.94443C9.89399 5.71682 10.0057 5.50776 10.1698 5.34366C10.3339 5.17957 10.543 5.06782 10.7706 5.02255C10.9982 4.97727 11.2341 5.00051 11.4485 5.08932C11.6629 5.17812 11.8462 5.32851 11.9751 5.52146C12.104 5.71442 12.1728 5.94127 12.1728 6.17333C12.1728 6.48452 12.0492 6.78296 11.8292 7.003C11.6091 7.22305 11.3107 7.34667 10.9995 7.34667Z" fill="#1E87D3"/>
                </svg>
              ) : toast.type == "warning" ? (
                <svg className='flex-item m-r-14' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="#FF7A00"/>
                  <path d="M11 5C10.4598 5 9.96569 5.30667 10.0019 5.66667L10.5009 14.6667C10.5009 14.7551 10.5535 14.8399 10.6471 14.9024C10.7407 14.9649 10.8676 15 11 15C11.1324 15 11.2593 14.9649 11.3529 14.9024C11.4465 14.8399 11.4991 14.7551 11.4991 14.6667L11.9981 5.66667C12.0343 5.30667 11.5402 5 11 5Z" fill="#FF7A00"/>
                  <path d="M11 18C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16C10.4477 16 10 16.4477 10 17C10 17.5523 10.4477 18 11 18Z" fill="#FF7A00"/>
                </svg>
              ) : toast.type == "error" ? (
                <svg className='flex-item m-r-14' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="6.50977" y="15.0092" width="12.3739" height="1.03116" rx="0.515578" transform="rotate(-45 6.50977 15.0092)" fill="#FF0000"/>
                  <rect x="15.2617" y="15.7384" width="12.3739" height="1.03116" rx="0.515578" transform="rotate(-135 15.2617 15.7384)" fill="#FF0000"/>
                  <circle cx="11" cy="11" r="10.5" stroke="#FF0000"/>
                </svg>
              ) : null
            }
            <div className='flex-item' style={{width: "80%"}}>
              {
                toast.type == "success" ? (
                  <p className='toast-header-txt m-b-4'>Success</p>
                ) : toast.type == "info" ? (
                  <p className='toast-header-txt m-b-4'>Info</p>
                ) : toast.type == "warning" ? (
                  <p className='toast-header-txt m-b-4'>Warning</p>
                ) : toast.type == "error" ? (
                  <p className='toast-header-txt m-b-4'>Error</p>
                ) : null
              }
              <p className='toast-message-txt'>{toast.message}</p>
            </div>
          </div>
          <svg onClick={handleToastClose} className='flex-item cursor-pointer global-fill-svg m-r-12' width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5.72852" y="18.2284" width="17.6777" height="1.47314" rx="0.73657" transform="rotate(-45 5.72852 18.2284)" fill="#6D6D6D"/>
            <rect x="18.2305" y="19.2701" width="17.6777" height="1.47314" rx="0.73657" transform="rotate(-135 18.2305 19.2701)" fill="#6D6D6D"/>
          </svg>
        </div>
      )
    }
   </>
  )
}

export default ToastMessage;