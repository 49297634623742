import React, { useEffect } from "react";
import {Route, Redirect} from "react-router-dom";
import {isAuthenticated} from "./index";

const MemberGymRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props =>
        (isAuthenticated() && isAuthenticated().user && isAuthenticated().user.role === 8) ? (
          <Component  {...props}/>
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default MemberGymRoute;