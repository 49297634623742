import {API} from "../../backend";

export const saveEnquiry=(data)=>{
    return fetch(`${API}/save/enquiries`,{
        method: "POST",
        headers:{
            Accept: "application/json",
            'Content-type':"application/json"
        },
        body:JSON.stringify(data)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}

export const getAllEnquiries = (userId, token) => {
    return fetch(`${API}/enquiries/${userId}`, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err))
};


export const deleteSelectedEnquiries=(userId,token,data)=>{
    return fetch(`${API}/delete/enquiry/${userId}`,{
        method:'post',
        headers:{
            Accept:'application/json',
            Authorization:`bearer ${token}`,
            'Content-type':"application/json"
        },
        body:JSON.stringify(data)
    }).then(response=>response.json()).catch(err=>console.log(err))
}