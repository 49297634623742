import React, { useState } from "react";

export default function ManageAppointment(){

  const [time, setTime] = useState({
    hr: ["00","01","02","03","04","05","06","07","08","09","10","11","12"],
    min: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60"]
  });

  const [appointmentDuration, setAppointmentDuration] = useState("15");

  const [startTime, setStartTime] = useState({
    hr: "",
    min: "",
    timePeriod: "AM" 
  }) 

  const [endTime, setEndTime] = useState({
    hr: "",
    min: "",
    timePeriod: "AM" 
  })

  const [timeSelectorType, setTimeSelectorTime] = useState("")

  const [timeSlots, setTimeSlots] = useState([]);

  const handleAppointmentDuration = (time) => {
    setAppointmentDuration(time);
  }


  return(
    <div className="modal-test-container d-flex">
      <div className="appointment-action-modal flex-item">
        <div className="modal-header d-flex space-between">
          <p className="flex-item font-style-4 font-weight-600">Appointment Slots</p>
          <div className="global-round-btn d-flex">
            <svg className="flex-item fill-svg" width="16" height="16" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.9283 3.80866C4.77899 3.66445 4.57901 3.58465 4.37144 3.58646C4.16387 3.58826 3.96531 3.67152 3.81853 3.8183C3.67175 3.96508 3.58849 4.16364 3.58668 4.37121C3.58488 4.57879 3.66468 4.77876 3.80888 4.92807L8.38076 9.49995L3.80809 14.0718C3.73248 14.1449 3.67217 14.2322 3.63068 14.3288C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7395 3.58555 14.8437 3.62536 14.941C3.66516 15.0383 3.72395 15.1267 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4338 4.36495 15.4329C4.47007 15.432 4.57395 15.4101 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1912L9.50018 10.6194L14.0721 15.1912C14.2214 15.3354 14.4213 15.4152 14.6289 15.4134C14.8365 15.4116 15.035 15.3284 15.1818 15.1816C15.3286 15.0348 15.4119 14.8363 15.4137 14.6287C15.4155 14.4211 15.3357 14.2211 15.1915 14.0718L10.6196 9.49995L15.1915 4.92807C15.3357 4.77876 15.4155 4.57879 15.4137 4.37121C15.4119 4.16364 15.3286 3.96508 15.1818 3.8183C15.035 3.67152 14.8365 3.58826 14.6289 3.58646C14.4213 3.58465 14.2214 3.66445 14.0721 3.80866L9.50018 8.38053L4.9283 3.80786V3.80866Z" fill="black"/>
            </svg>
          </div>
        </div>
        <div className="appointment-planner-container d-flex space-between">
          <div className="flex-item weekdays-container">
            <div className="day-container button-blue bg-blue">
              <p className="color-white font-style-2">Monday</p>
            </div>
            <div className="day-container button-blue">
              <p className="color-black font-style-2 font-weight-300">Tuesday</p>
            </div>
            <div className="day-container button-blue">
              <p className="color-black font-style-2 font-weight-300">Wednesday</p>
            </div>
            <div className="day-container button-blue">
              <p className="color-black font-style-2 font-weight-300">Thursday</p>
            </div>
            <div className="day-container button-blue">
              <p className="color-black font-style-2 font-weight-300">Friday</p>
            </div>
            <div className="day-container button-blue">
              <p className="color-black font-style-2 font-weight-300">Saturday</p>
            </div>
            <div className="day-container button-blue">
              <p className="color-black font-style-2 font-weight-300">Sunday</p>
            </div>
          </div>
          <div className="flex-item day-appt-planner-container">
            <div className="appt-planner-header space-between d-flex">
              <div className="d-flex">
                <p className="flex-item m-r-14">Copy From</p>
                <div className="d-flex">
                  <div className="global-round-btn d-flex m-r-6" style={{background:"#DFF3FF"}}>
                    <p className="text flex-item color-blue">M</p>
                  </div>
                  <div className="global-round-btn d-flex m-r-6" style={{background:"#DFF3FF"}}>
                    <p className="text flex-item color-blue">T</p>
                  </div>
                  <div className="global-round-btn d-flex m-r-6" style={{background:"#DFF3FF"}}>
                    <p className="text flex-item color-blue">W</p>
                  </div>
                  <div className="global-round-btn d-flex m-r-6" style={{background:"#DFF3FF"}}>
                    <p className="text flex-item color-blue">T</p>
                  </div>
                  <div className="global-round-btn d-flex m-r-6" style={{background:"#DFF3FF"}}>
                    <p className="text flex-item color-blue">F</p>
                  </div>
                  <div className="global-round-btn d-flex m-r-6" style={{background:"#DFF3FF"}}>
                    <p className="text flex-item color-blue">S</p>
                  </div>
                  <div className="global-round-btn d-flex m-r-6" style={{background:"#DFF3FF"}}>
                    <p className="text flex-item color-blue">S</p>
                  </div>
                </div>
              </div>
              <div className="global-round-btn button-orange bg-orange d-flex m-r-6">
                <svg className="flex-item" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.41303 4.08027C8.9564 3.62142 8.4134 3.25756 7.81539 3.00971C7.21737 2.76187 6.57618 2.63493 5.92884 2.63624C4.62163 2.63624 3.36796 3.15553 2.44362 4.07986C1.51929 5.0042 1 6.25787 1 7.56508C1.00016 8.87281 1.5193 10.127 2.44343 11.0523C3.36755 11.9776 4.62111 12.4983 5.92884 12.5001C7.23668 12.4983 8.49033 11.9775 9.41447 11.0521C10.3386 10.1267 10.8577 8.8723 10.8577 7.56446" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M8.96436 1L9.5549 3.39822C9.60697 3.61078 9.57278 3.83531 9.45979 4.02273C9.3468 4.21015 9.16421 4.34522 8.95193 4.39841L6.54688 4.9877" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div className="appt-day-planner-scroll-container">
              <p className="flex-item font-style-2 font-weight-600 color-grey-02">Select Appointment Duration</p>
              <div className="d-flex m-b-14 cursor-pointer">
                <p className="font-style-3 m-r-6 font-weight-300">Add Custom Duration</p>
                <svg className="flex-item" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.99781 2.1692C4.99654 2.09632 4.9667 2.02686 4.91472 1.97577C4.86273 1.92468 4.79276 1.89605 4.71987 1.89605C4.64698 1.89605 4.57701 1.92468 4.52502 1.97577C4.47304 2.02686 4.4432 2.09632 4.44193 2.1692L4.44193 4.4395L2.17144 4.4393C2.13453 4.43866 2.09787 4.44537 2.06358 4.45905C2.0293 4.47273 1.99809 4.4931 1.97176 4.51898C1.94543 4.54485 1.92452 4.57571 1.91025 4.60975C1.89598 4.64379 1.88863 4.68033 1.88863 4.71724C1.88863 4.75415 1.89598 4.79069 1.91025 4.82473C1.92452 4.85877 1.94543 4.88963 1.97176 4.9155C1.99809 4.94138 2.0293 4.96175 2.06359 4.97543C2.09787 4.98911 2.13453 4.99582 2.17144 4.99518L4.44193 4.99538L4.44193 7.26567C4.4432 7.33855 4.47304 7.40801 4.52502 7.4591C4.57701 7.51019 4.64698 7.53882 4.71987 7.53882C4.79276 7.53882 4.86273 7.51019 4.91472 7.4591C4.9667 7.40801 4.99654 7.33855 4.99781 7.26567L4.99781 4.99538L7.26811 4.99538C7.34098 4.99411 7.41045 4.96427 7.46154 4.91228C7.51263 4.8603 7.54126 4.79033 7.54126 4.71744C7.54126 4.64455 7.51263 4.57458 7.46154 4.52259C7.41045 4.4706 7.34098 4.44076 7.26811 4.4395L4.99781 4.4395L4.99801 2.169L4.99781 2.1692Z" fill="#4D4D4D"/>
                </svg>
              </div>
              <div className='w-full d-flex-wrap m-b-14'>
                <div className='m-r-12' style={{textAlign:"center", alignSelf:"flex-start"}}>
                  <div onClick={() => handleAppointmentDuration("15")} className={`${appointmentDuration == "15" ? "global-selection-chips global-selection-chips-active" : "global-selection-chips"} d-flex cursor-pointer`}>
                    <p className='flex-item'>15 Min</p>
                  </div>
                  <p className='fs-10' style={{color: appointmentDuration == "15" ? "#055075" : "#9a9a9a"}}>Optimal Time</p>
                </div>
                <div onClick={() => handleAppointmentDuration("30")} className={`${appointmentDuration == "30" ? "global-selection-chips global-selection-chips-active m-r-12 m-b-16" : "global-selection-chips m-r-12 m-b-16"} d-flex cursor-pointer`} style={{textAlign:"center", alignSelf:"flex-start"}}>
                  <p className='flex-item'>30 Min</p>
                </div>
                <div onClick={() => handleAppointmentDuration("45")} className={`${appointmentDuration == "45" ? "global-selection-chips global-selection-chips-active m-r-12 m-b-16" : "global-selection-chips m-r-12 m-b-16"} d-flex cursor-pointer`} style={{textAlign:"center", alignSelf:"flex-start"}}>
                  <p className='flex-item'>45 Min</p>
                </div>
                <div onClick={() => handleAppointmentDuration("00")} className={`${appointmentDuration == "00" ? "global-selection-chips global-selection-chips-active m-r-12 m-b-16" : "global-selection-chips m-r-12 m-b-16"} d-flex cursor-pointer`} style={{textAlign:"center", alignSelf:"flex-start"}}>
                  <p className='flex-item'>60 Min</p>
                </div>
              </div>
              <p className="flex-item font-style-2 font-weight-600 color-grey-02 m-b-4">Add Start & End Time</p>
              <div className="d-flex">
                <div className="member-global-input-field flex-item m-r-12" style={{border: "1px solid #055075", width: "170px", height: "46px"}}>
                  <div className="flex-item d-flex">
                    <p className="placeholder-txt flex-item" style={{ fontSize: "10px", color:"#055075", transition: "180ms"}}>From</p>
                  </div>
                  <div className="d-flex w-full space-between">
                    <div className="d-flex">
                      <p className="flex-item" style={{color: "#2b2b2b", lineHeight:"14px"}}>{startTime.min ? startTime.min : "00"}</p>
                      <svg className="flex-item m-l-4 m-r-4" width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 0.667969 2)" fill="black"/>
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 0.667969 6)" fill="black"/>
                      </svg>
                      <p className="flex-item" style={{color: "#2b2b2b", lineHeight:"14px"}}>{startTime.hr ? startTime.hr : "00"}</p>
                    </div>
                    <div className="time-period-switch d-flex" style={{marginTop:"-15px"}}>
                      <div className="active-switch">
                      </div>
                      <div className="period-container d-flex">
                        <p className="flex-item font-style-3 color-blue">AM</p>
                      </div>
                      <div className="period-container d-flex">
                        <p className="flex-item font-style-3">PM</p>
                      </div>
                    </div>
                  </div>
                  {
                    timeSelectorType == "start" && (
                      <div onClick={e => e.stopPropagation()} className="global-input-field-arrow-dropdown" style={{justifyContent:"center", textAlign:"center", width: "160px"}}>
                        <div className="d-flex space-between" style={{width: "150px", margin:"12px auto"}}>
                          <div className="d-flex flex-item" style={{width: "40%"}}>
                            <p className="flex-item" style={{margin: "0 auto", color: "#055075"}}>Hr</p>
                          </div>
                          <div className="flex-item" style={{width: "20%"}}>
                            <svg className="flex-item" width="4" height="10" viewBox="0 0 4 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="1.9987" cy="1.66667" r="1.66667" fill="#8F8F8F"/>
                              <circle cx="1.9987" cy="8.33317" r="1.66667" fill="#8F8F8F"/>
                            </svg>
                          </div>
                          <div className="d-flex flex-item" style={{width: "40%", textAlign:"center"}}>
                            <p className="flex-item"  style={{margin: "0 auto", color: "#055075"}}>Min</p>
                          </div>
                        </div>
                        <div className="d-flex space-between" style={{width: "150px", height: "200px", margin:"12px auto"}}>
                          <div className="scroll-hidden" style={{width: "40%", textAlign:"center", height: "100%", overflowY:"auto"}}>
                            {
                              time.hr.map(data => {
                                return(
                                  <p className="flex-item" style={{margin: "0 auto"}}>{data}</p>
                                )
                                // onClick={() => handleAppointmentDuration(data, "")} 
                              })
                            }
                          </div>
                          <div className="flex-item d-flex" style={{width: "20%"}}>
                            <div className="flex-item" style={{height: "200px", width: "0.5px", margin: "0 auto", background: "#0088DA"}} />
                          </div>
                          <div className="scroll-hidden" style={{width: "40%", textAlign:"center", height: "100%", overflowY:"auto"}}>
                            {
                              time.min.map(data => {
                                return(
                                  <p  className="flex-item" style={{margin: "0 auto"}}>{data}</p>
                                )
                              })
                            }
                            {/* onClick={() => handleAppointmentDuration("", data)} */}
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className="member-global-input-field flex-item m-r-12" style={{border: "1px solid #055075", width: "170px", height: "46px"}}>
                  <div className="flex-item d-flex">
                    <p className="placeholder-txt flex-item" style={{ fontSize: "10px", color:"#055075", transition: "180ms"}}>To</p>
                  </div>
                  <div className="d-flex w-full space-between">
                    <div className="d-flex">
                      <p className="flex-item" style={{color: "#2b2b2b", lineHeight:"14px"}}>{endTime.min ? endTime.min : "00"}</p>
                      <svg className="flex-item m-l-4 m-r-4" width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 0.667969 2)" fill="black"/>
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 0.667969 6)" fill="black"/>
                      </svg>
                      <p className="flex-item" style={{color: "#2b2b2b", lineHeight:"14px"}}>{endTime.hr ? endTime.hr : "00"}</p>
                    </div>
                    <div className="time-period-switch d-flex" style={{marginTop:"-15px"}}>
                      <div className="active-switch">
                      </div>
                      <div className="period-container d-flex">
                        <p className="flex-item font-style-3 color-blue">AM</p>
                      </div>
                      <div className="period-container d-flex">
                        <p className="flex-item font-style-3">PM</p>
                      </div>
                    </div>
                  </div>
                  {
                    timeSelectorType == "end" && (
                      <div onClick={e => e.stopPropagation()} className="global-input-field-arrow-dropdown" style={{justifyContent:"center", textAlign:"center", width: "160px"}}>
                        <div className="d-flex space-between" style={{width: "150px", margin:"12px auto"}}>
                          <div className="d-flex flex-item" style={{width: "40%"}}>
                            <p className="flex-item" style={{margin: "0 auto", color: "#055075"}}>Hr</p>
                          </div>
                          <div className="flex-item" style={{width: "20%"}}>
                            <svg className="flex-item" width="4" height="10" viewBox="0 0 4 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="1.9987" cy="1.66667" r="1.66667" fill="#8F8F8F"/>
                              <circle cx="1.9987" cy="8.33317" r="1.66667" fill="#8F8F8F"/>
                            </svg>
                          </div>
                          <div className="d-flex flex-item" style={{width: "40%", textAlign:"center"}}>
                            <p className="flex-item"  style={{margin: "0 auto", color: "#055075"}}>Min</p>
                          </div>
                        </div>
                        <div className="d-flex space-between" style={{width: "150px", height: "200px", margin:"12px auto"}}>
                          <div className="scroll-hidden" style={{width: "40%", textAlign:"center", height: "100%", overflowY:"auto"}}>
                            {
                              time.hr.map(data => {
                                return(
                                  <p onClick={() => handleAppointmentDuration(data)}  className="flex-item" style={{margin: "0 auto"}}>{data}</p>
                                )
                              })
                            }
                          </div>
                          <div className="flex-item d-flex" style={{width: "20%"}}>
                            <div className="flex-item" style={{height: "200px", width: "0.5px", margin: "0 auto", background: "#0088DA"}} />
                          </div>
                          <div className="scroll-hidden" style={{width: "40%", textAlign:"center", height: "100%", overflowY:"auto"}}>
                            {
                              time.min.map(data => {
                                return(
                                  <p onClick={() => handleAppointmentDuration(data)} className="flex-item" style={{margin: "0 auto"}}>{data}</p>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
} 