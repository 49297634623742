import React, { useEffect, useState } from "react";

const ManageProfile = (props) => {

  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    dob: '',
    profileLinks: {
      linkedIn: '',
      facebook: '',
      instagram: ''
    }
  })
  const [currentElementId, setCurrentElementId] = useState("");
  const [isGenderSelectionOpen, setGenderSelectionOpen] = useState(false);

  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (id == 'user-gender') setGenderSelectionOpen(!isGenderSelectionOpen);
    if (event) {
      let interval = setInterval(() => {
        let element = event.target.querySelector('input');
        if (element) {
          element.focus();
          clearInterval(interval)
        }
      }, 0);
    }
  }

  const getSelectedGender = (selectedGender) => {
    setUserDetails({
      ...userDetails,
      gender: selectedGender
    })
  }

  return(
    <div className="mobile-bottom-modal-container" onClick={props.toggleManageProfile} style={{border:"1px solid"}}>
      <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
        <div onClick={props.toggleManageProfile} className="w-full">
          <div className="draggable-pointer" />
        </div>
        <p className="flex-item modal-header-text">{props.isEdit?"Edit":"Add"} Profile</p>
        <div onClick={e => e.stopPropagation()} className="global-modal-scroll-container-mob">
          <div className="d-flex space-between w-full m-b-20">
            <div onClick={toggleInputFields('user-name')} className={`${(currentElementId == 'user-name' || userDetails.name) ? "member-global-input-field flex-item" : "member-global-input-field flex-item d-flex"}`} style={{ border: (currentElementId == 'user-name' || userDetails.name) ? "1px solid #055075" : "", width: "74%" }}>
              <div className="flex-item d-flex">
                <svg className="flex-item m-r-8" width={(currentElementId == 'user-name' || userDetails.name) ? "12" : "18"} height={(currentElementId == 'user-name' || userDetails.name) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 0C4.50555 0 4.0222 0.146622 3.61107 0.421326C3.19995 0.696029 2.87952 1.08648 2.6903 1.54329C2.50108 2.00011 2.45157 2.50277 2.54804 2.98773C2.6445 3.47268 2.8826 3.91814 3.23223 4.26777C3.58186 4.6174 4.02732 4.8555 4.51227 4.95196C4.99723 5.04843 5.49989 4.99892 5.95671 4.8097C6.41352 4.62048 6.80397 4.30005 7.07867 3.88893C7.35338 3.4778 7.5 2.99445 7.5 2.5C7.5 1.83696 7.23661 1.20107 6.76777 0.732233C6.29893 0.263392 5.66304 0 5 0ZM5 4C4.70333 4 4.41332 3.91203 4.16664 3.7472C3.91997 3.58238 3.72771 3.34811 3.61418 3.07403C3.50065 2.79994 3.47094 2.49834 3.52882 2.20736C3.5867 1.91639 3.72956 1.64912 3.93934 1.43934C4.14912 1.22956 4.41639 1.0867 4.70736 1.02882C4.99834 0.970944 5.29994 1.00065 5.57403 1.11418C5.84811 1.22771 6.08238 1.41997 6.2472 1.66664C6.41203 1.91332 6.5 2.20333 6.5 2.5C6.5 2.89782 6.34196 3.27936 6.06066 3.56066C5.77936 3.84196 5.39782 4 5 4ZM9.5 9.5V9C9.5 8.07174 9.13125 7.1815 8.47487 6.52513C7.8185 5.86875 6.92826 5.5 6 5.5H4C3.07174 5.5 2.1815 5.86875 1.52513 6.52513C0.868749 7.1815 0.5 8.07174 0.5 9V9.5H1.5V9C1.5 8.33696 1.76339 7.70107 2.23223 7.23223C2.70107 6.76339 3.33696 6.5 4 6.5H6C6.66304 6.5 7.29893 6.76339 7.76777 7.23223C8.23661 7.70107 8.5 8.33696 8.5 9V9.5H9.5Z" fill={(currentElementId == 'user-name' || userDetails.name) ? "#055075" : "#6d6d6d"}/>
                </svg>
                <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-name' || userDetails.name) ? "10px" : "14px", color: (currentElementId == 'user-name' || userDetails.name) ? "#055075" : "", transition: "180ms" }}>User Name</p>
              </div>
              <input value={userDetails.name} className={`${(currentElementId == 'user-name' || userDetails.name) ? "active-input-field" : "inactive-input-field"}`} type="text" />
            </div>
            <div className="flex-item gym-logo-viewer d-flex" style={{ cursor: 'pointer', justifyContent: 'center' }}>
              <div className="flex-item gym-input-icon d-flex">
                <svg className="flex-item" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 6.44444C4.5 6.44444 4.92507 6.25007 5.2752 5.86133C5.62507 5.4723 5.8 5 5.8 4.44444C5.8 3.88889 5.62507 3.41659 5.2752 3.02756C4.92507 2.63881 4.5 2.44444 4 2.44444C3.5 2.44444 3.07493 2.63881 2.7248 3.02756C2.37493 3.41659 2.2 3.88889 2.2 4.44444C2.2 5 2.37493 5.4723 2.7248 5.86133C3.07493 6.25007 3.5 6.44444 4 6.44444ZM0.8 8C0.58 8 0.391733 7.91304 0.2352 7.73911C0.0784 7.56489 0 7.35556 0 7.11111V1.77778C0 1.53333 0.0784 1.32415 0.2352 1.15022C0.391733 0.976 0.58 0.888889 0.8 0.888889H2.06L2.56 0.288889C2.63333 0.192593 2.7216 0.120296 2.8248 0.072C2.92827 0.024 3.03667 0 3.15 0H4.85C4.96333 0 5.07173 0.024 5.1752 0.072C5.2784 0.120296 5.36667 0.192593 5.44 0.288889L5.94 0.888889H7.2C7.42 0.888889 7.6084 0.976 7.7652 1.15022C7.92173 1.32415 8 1.53333 8 1.77778V7.11111C8 7.35556 7.92173 7.56489 7.7652 7.73911C7.6084 7.91304 7.42 8 7.2 8H0.8Z" fill="white" />
                </svg>
              </div>
              <p className="flex-item" style={{color: '#4d4d4d'}}>A</p>
              {/* {(!Gym.gymphoto && userDetails.name) && <p className="flex-item" style={{
                color: '#4d4d4d'
              }}>{`${userDetails.name}`.trim()[0]}</p>}
              {Gym.gymphoto && <img style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '100%' }} src={Gym.gymphoto} />} */}
              <input type="file" name="file" accept=".png, .jpg, .jpeg" className="image-input-field" />
            </div>
          </div>
          <div onClick={toggleInputFields('user-email')} className={`${(currentElementId == 'user-email' || userDetails.email) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == 'user-email' || userDetails.email) ? "1px solid #055075" : ""}}>
            <div className="flex-item d-flex">
              {/* <svg className="flex-item m-r-8" width={(currentElementId == 'user-email' || userDetails.email) ? "12" : "18"} height={(currentElementId == 'user-email' || userDetails.email) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 0C4.50555 0 4.0222 0.146622 3.61107 0.421326C3.19995 0.696029 2.87952 1.08648 2.6903 1.54329C2.50108 2.00011 2.45157 2.50277 2.54804 2.98773C2.6445 3.47268 2.8826 3.91814 3.23223 4.26777C3.58186 4.6174 4.02732 4.8555 4.51227 4.95196C4.99723 5.04843 5.49989 4.99892 5.95671 4.8097C6.41352 4.62048 6.80397 4.30005 7.07867 3.88893C7.35338 3.4778 7.5 2.99445 7.5 2.5C7.5 1.83696 7.23661 1.20107 6.76777 0.732233C6.29893 0.263392 5.66304 0 5 0ZM5 4C4.70333 4 4.41332 3.91203 4.16664 3.7472C3.91997 3.58238 3.72771 3.34811 3.61418 3.07403C3.50065 2.79994 3.47094 2.49834 3.52882 2.20736C3.5867 1.91639 3.72956 1.64912 3.93934 1.43934C4.14912 1.22956 4.41639 1.0867 4.70736 1.02882C4.99834 0.970944 5.29994 1.00065 5.57403 1.11418C5.84811 1.22771 6.08238 1.41997 6.2472 1.66664C6.41203 1.91332 6.5 2.20333 6.5 2.5C6.5 2.89782 6.34196 3.27936 6.06066 3.56066C5.77936 3.84196 5.39782 4 5 4ZM9.5 9.5V9C9.5 8.07174 9.13125 7.1815 8.47487 6.52513C7.8185 5.86875 6.92826 5.5 6 5.5H4C3.07174 5.5 2.1815 5.86875 1.52513 6.52513C0.868749 7.1815 0.5 8.07174 0.5 9V9.5H1.5V9C1.5 8.33696 1.76339 7.70107 2.23223 7.23223C2.70107 6.76339 3.33696 6.5 4 6.5H6C6.66304 6.5 7.29893 6.76339 7.76777 7.23223C8.23661 7.70107 8.5 8.33696 8.5 9V9.5H9.5Z" fill={(currentElementId == 'user-email' || userDetails.email) ? "#055075" : "#6d6d6d"}/>
              </svg> */}
              <svg className="flex-item m-r-8" width={(currentElementId == 'user-email' || userDetails.email) ? "12" : "18"} height={(currentElementId == 'user-email' || userDetails.email) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 2.25H1.5C1.30109 2.25 1.11032 2.32902 0.96967 2.46967C0.829018 2.61032 0.75 2.80109 0.75 3V9C0.75 9.19891 0.829018 9.38968 0.96967 9.53033C1.11032 9.67098 1.30109 9.75 1.5 9.75H10.5C10.6989 9.75 10.8897 9.67098 11.0303 9.53033C11.171 9.38968 11.25 9.19891 11.25 9V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25ZM9.675 3L6 5.5425L2.325 3H9.675ZM1.5 9V3.34125L5.78625 6.3075C5.84902 6.35105 5.9236 6.37438 6 6.37438C6.0764 6.37438 6.15098 6.35105 6.21375 6.3075L10.5 3.34125V9H1.5Z" fill={(currentElementId == 'user-email' || userDetails.email) ? "#055075" : "#6d6d6d"}/>
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-email' || userDetails.email) ? "10px" : "14px", color: (currentElementId == 'user-email' || userDetails.email) ? "#055075" : "", transition: "180ms" }}>Email</p>
            </div>
            <input value={userDetails.email} className={`${(currentElementId == 'user-email' || userDetails.email) ? "active-input-field" : "inactive-input-field"}`} type="email" />
          </div>
          <div onClick={toggleInputFields('user-phone')} className={`${(currentElementId == 'user-phone' || userDetails.phone) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == 'user-phone' || userDetails.phone) ? "1px solid #055075" : ""}}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == 'user-phone' || userDetails.phone) ? "12" : "18"} height={(currentElementId == 'user-phone' || userDetails.phone) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.74967 10.875H9.68592C2.31717 10.4513 1.27092 4.23375 1.12467 2.33625C1.11288 2.18872 1.13032 2.04031 1.17599 1.89953C1.22166 1.75875 1.29466 1.62836 1.39081 1.51584C1.48696 1.40333 1.60436 1.31088 1.73629 1.24381C1.86823 1.17675 2.0121 1.13637 2.15967 1.125H4.22592C4.37612 1.12485 4.52292 1.16982 4.64728 1.25406C4.77164 1.33831 4.86784 1.45795 4.92342 1.5975L5.49342 3C5.5483 3.13633 5.56192 3.28578 5.53258 3.42978C5.50325 3.57379 5.43225 3.706 5.32842 3.81L4.52967 4.61625C4.65443 5.32528 4.99398 5.97888 5.50238 6.48861C6.01077 6.99834 6.66347 7.33961 7.37217 7.46625L8.18592 6.66C8.29148 6.55731 8.42494 6.48797 8.56965 6.46063C8.71436 6.43329 8.86391 6.44915 8.99967 6.50625L10.4134 7.0725C10.5509 7.12983 10.6681 7.22678 10.7503 7.351C10.8324 7.47522 10.8757 7.62108 10.8747 7.77V9.75C10.8747 10.0484 10.7561 10.3345 10.5452 10.5455C10.3342 10.7565 10.048 10.875 9.74967 10.875ZM2.24967 1.875C2.15021 1.875 2.05483 1.91451 1.9845 1.98484C1.91417 2.05516 1.87467 2.15054 1.87467 2.25V2.28C2.04717 4.5 3.15342 9.75 9.72717 10.125C9.77643 10.128 9.82581 10.1213 9.87248 10.1053C9.91914 10.0892 9.96218 10.064 9.99911 10.0313C10.036 9.99854 10.0661 9.95882 10.0877 9.91441C10.1092 9.87 10.1218 9.82178 10.1247 9.7725V7.77L8.71092 7.20375L7.63467 8.2725L7.45467 8.25C4.19217 7.84125 3.74967 4.57875 3.74967 4.545L3.72717 4.365L4.79217 3.28875L4.22967 1.875H2.24967Z" fill={(currentElementId == 'user-phone' || userDetails.phone) ? "#055075" : "#6d6d6d"}/>
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-phone' || userDetails.phone) ? "10px" : "14px", color: (currentElementId == 'user-phone' || userDetails.phone) ? "#055075" : "", transition: "180ms" }}>Phone</p>
            </div>
            <input value={userDetails.phone} className={`${(currentElementId == 'user-phone' || userDetails.phone) ? "active-input-field" : "inactive-input-field"}`} type="number" />
          </div>
          <div onClick={toggleInputFields('user-gender')} className={`${(currentElementId == "user-gender" || userDetails.gender) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == "user-gender" || userDetails.gender) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == "user-gender" || userDetails.gender) ? "18" : "18"} height={(currentElementId == "user-gender" || userDetails.gender) ? "18" : "18"} style={{ transition: "180ms" }} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.73333 1H3.1C3.00717 1 2.91815 1.03687 2.85251 1.10251C2.78687 1.16815 2.75 1.25717 2.75 1.35V6.48333C2.75 6.5293 2.75905 6.57481 2.77664 6.61727C2.79423 6.65974 2.82001 6.69832 2.85251 6.73082C2.88501 6.76332 2.9236 6.7891 2.96606 6.80669C3.00852 6.82428 3.05404 6.83333 3.1 6.83333H4.73333C4.7793 6.83333 4.82481 6.82428 4.86727 6.80669C4.90974 6.7891 4.94832 6.76332 4.98082 6.73082C5.01332 6.69832 5.0391 6.65974 5.05669 6.61727C5.07428 6.57481 5.08333 6.5293 5.08333 6.48333V1.35C5.08333 1.25717 5.04646 1.16815 4.98082 1.10251C4.91518 1.03687 4.82616 1 4.73333 1V1ZM11.7333 1H10.1C10.0072 1 9.91815 1.03687 9.85251 1.10251C9.78687 1.16815 9.75 1.25717 9.75 1.35V6.48333C9.75 6.5293 9.75905 6.57481 9.77664 6.61727C9.79423 6.65974 9.82001 6.69832 9.85251 6.73082C9.88501 6.76332 9.9236 6.7891 9.96606 6.80669C10.0085 6.82428 10.054 6.83333 10.1 6.83333H11.7333C11.7793 6.83333 11.8248 6.82428 11.8673 6.80669C11.9097 6.7891 11.9483 6.76332 11.9808 6.73082C12.0133 6.69832 12.0391 6.65974 12.0567 6.61727C12.0743 6.57481 12.0833 6.5293 12.0833 6.48333V1.35C12.0833 1.25717 12.0465 1.16815 11.9808 1.10251C11.9152 1.03687 11.8262 1 11.7333 1V1Z" stroke={(currentElementId == "user-gender" || userDetails.gender) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.08333 3.91675H9.75M1 5.31675V2.51675C1 2.42392 1.03687 2.3349 1.10251 2.26926C1.16815 2.20362 1.25717 2.16675 1.35 2.16675H2.4C2.49283 2.16675 2.58185 2.20362 2.64749 2.26926C2.71313 2.3349 2.75 2.42392 2.75 2.51675V5.31675C2.75 5.40957 2.71313 5.4986 2.64749 5.56424C2.58185 5.62987 2.49283 5.66675 2.4 5.66675H1.35C1.25717 5.66675 1.16815 5.62987 1.10251 5.56424C1.03687 5.4986 1 5.40957 1 5.31675V5.31675ZM13.8333 5.31675V2.51675C13.8333 2.42392 13.7965 2.3349 13.7308 2.26926C13.6652 2.20362 13.5762 2.16675 13.4833 2.16675H12.4333C12.3405 2.16675 12.2515 2.20362 12.1858 2.26926C12.1202 2.3349 12.0833 2.42392 12.0833 2.51675V5.31675C12.0833 5.40957 12.1202 5.4986 12.1858 5.56424C12.2515 5.62987 12.3405 5.66675 12.4333 5.66675H13.4833C13.5762 5.66675 13.6652 5.62987 13.7308 5.56424C13.7965 5.4986 13.8333 5.40957 13.8333 5.31675V5.31675Z" stroke={(currentElementId == "user-gender" || userDetails.gender) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "user-gender" || userDetails.gender) ? "10px" : "14px", color: (currentElementId == "user-gender" || userDetails.gender) ? "#055075" : "", transition: "180ms" }}>Gender</p>
            </div>
            <div className='input-field-arrow-dropdown' style={{ margin: "12px 8px 4px 0", transition: "180ms" }}>
              <svg className="flex-item" style={{ transform: isGenderSelectionOpen ? "rotate(180deg)" : "", transition: "180ms" }} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round" />
              </svg>
            </div>
            <input id="user-gender" className={`${(currentElementId == "user-gender" || userDetails.gender) ? "active-input-field" : "inactive-input-field"}`} type="text" placeholder="Select Gender" value={userDetails.gender} style={{ pointerEvents: "none",textTransform:'capitalize' }} />
            {/* Dropdown Container */}
            {
              isGenderSelectionOpen && (
                <div className="global-input-field-arrow-dropdown global-input-field-arrow-dropdown-height-1">
                  <div onClick={getSelectedGender.bind(this, "Male")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Male</p>
                  </div>
                  <div onClick={getSelectedGender.bind(this, "Female")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Female</p>
                  </div>
                </div>
              )
            }
          </div>
          <div onClick={toggleInputFields('user-dob')} className={`${(currentElementId == 'user-dob' || userDetails.dob) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == 'user-dob' || userDetails.dob) ? "1px solid #055075" : ""}}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == 'user-dob' || userDetails.dob) ? "12" : "18"} height={(currentElementId == 'user-dob' || userDetails.dob) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2384_1577)">
                  <path d="M0 9.0625C0 9.58008 0.411352 10 0.918367 10H7.65306C8.16008 10 8.57143 9.58008 8.57143 9.0625V3.75H0V9.0625ZM6.12245 5.23438C6.12245 5.10547 6.22577 5 6.35204 5H7.11735C7.24362 5 7.34694 5.10547 7.34694 5.23438V6.01562C7.34694 6.14453 7.24362 6.25 7.11735 6.25H6.35204C6.22577 6.25 6.12245 6.14453 6.12245 6.01562V5.23438ZM6.12245 7.73438C6.12245 7.60547 6.22577 7.5 6.35204 7.5H7.11735C7.24362 7.5 7.34694 7.60547 7.34694 7.73438V8.51562C7.34694 8.64453 7.24362 8.75 7.11735 8.75H6.35204C6.22577 8.75 6.12245 8.64453 6.12245 8.51562V7.73438ZM3.67347 5.23438C3.67347 5.10547 3.77679 5 3.90306 5H4.66837C4.79464 5 4.89796 5.10547 4.89796 5.23438V6.01562C4.89796 6.14453 4.79464 6.25 4.66837 6.25H3.90306C3.77679 6.25 3.67347 6.14453 3.67347 6.01562V5.23438ZM3.67347 7.73438C3.67347 7.60547 3.77679 7.5 3.90306 7.5H4.66837C4.79464 7.5 4.89796 7.60547 4.89796 7.73438V8.51562C4.89796 8.64453 4.79464 8.75 4.66837 8.75H3.90306C3.77679 8.75 3.67347 8.64453 3.67347 8.51562V7.73438ZM1.22449 5.23438C1.22449 5.10547 1.32781 5 1.45408 5H2.21939C2.34566 5 2.44898 5.10547 2.44898 5.23438V6.01562C2.44898 6.14453 2.34566 6.25 2.21939 6.25H1.45408C1.32781 6.25 1.22449 6.14453 1.22449 6.01562V5.23438ZM1.22449 7.73438C1.22449 7.60547 1.32781 7.5 1.45408 7.5H2.21939C2.34566 7.5 2.44898 7.60547 2.44898 7.73438V8.51562C2.44898 8.64453 2.34566 8.75 2.21939 8.75H1.45408C1.32781 8.75 1.22449 8.64453 1.22449 8.51562V7.73438ZM7.65306 1.25H6.73469V0.3125C6.73469 0.140625 6.59694 0 6.42857 0H5.81633C5.64796 0 5.5102 0.140625 5.5102 0.3125V1.25H3.06122V0.3125C3.06122 0.140625 2.92347 0 2.7551 0H2.14286C1.97449 0 1.83673 0.140625 1.83673 0.3125V1.25H0.918367C0.411352 1.25 0 1.66992 0 2.1875V3.125H8.57143V2.1875C8.57143 1.66992 8.16008 1.25 7.65306 1.25Z" fill={(currentElementId == 'user-dob' || userDetails.dob) ? "#055075" : "#6d6d6d"}/>
                </g>
                <defs>
                <clipPath id="clip0_2384_1577">
                <rect width="8.57143" height="10" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-dob' || userDetails.dob) ? "10px" : "14px", color: (currentElementId == 'user-dob' || userDetails.dob) ? "#055075" : "", transition: "180ms" }}>Date of Birth</p>
            </div>
            <input value={userDetails.dob} className={`${(currentElementId == 'user-dob' || userDetails.dob) ? "active-input-field" : "inactive-input-field"}`} type="number" />
          </div>
          {/* <p className="modal-sub-heading flex-item m-r-12 m-b-20">Profile  Links</p>
          <div className="w-full space-between m-b-12 selected-gym-container d-flex">
            <div className="flex-item d-flex" style={{width: "85%"}}>
              <svg className="flex-item m-r-12" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.0409 17.0412H14.0776V12.4004C14.0776 11.2937 14.0578 9.86911 12.5363 9.86911C10.9929 9.86911 10.7567 11.0749 10.7567 12.3198V17.0409H7.79344V7.49747H10.6382V8.80169H10.678C10.9627 8.31489 11.3741 7.91443 11.8684 7.64295C12.3627 7.37147 12.9214 7.23914 13.4849 7.26005C16.4884 7.26005 17.0422 9.23567 17.0422 11.8058L17.0409 17.0412ZM4.44969 6.19302C3.49992 6.19317 2.72984 5.42333 2.72969 4.47356C2.72953 3.5238 3.4993 2.75372 4.44906 2.75356C5.39883 2.75333 6.16891 3.52317 6.16906 4.47294C6.16915 4.92904 5.98805 5.36649 5.6656 5.68906C5.34316 6.01164 4.90578 6.19291 4.44969 6.19302ZM5.93141 17.0413H2.96492V7.49747H5.93133V17.0412L5.93141 17.0413ZM18.5183 0.00145562H1.47586C0.67039 -0.00760688 0.00984375 0.637627 0 1.4431V18.5566C0.00953125 19.3625 0.67 20.0083 1.47578 19.9999H18.5183C19.3258 20.0099 19.9888 19.364 20 18.5566V1.44177C19.9885 0.634737 19.3254 -0.0104194 18.5183 0.000127492" fill="#0A66C2"/>
              </svg>
              <p className="fs-12 flex-item" style={{color: "#0088da", lineHeight: '16px', width: "70%", wordWrap: "break-word"}}>https://www.linkedin.com/in/anik-roy-0b7756181/</p>
            </div>
            <div className='bell-menu-container header-menu-active cursor-pointer d-flex' style={{alignSelf: "flex-start"}}>
              <svg className="bell flex-item" width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 10.5V11H28V10.5C28 9.43913 27.5786 8.42172 26.8284 7.67157C26.0783 6.92143 25.0609 6.5 24 6.5C22.9391 6.5 21.9217 6.92143 21.1716 7.67157C20.4214 8.42172 20 9.43913 20 10.5ZM17.5 11V10.5C17.5 8.77609 18.1848 7.12279 19.4038 5.90381C20.6228 4.68482 22.2761 4 24 4C25.7239 4 27.3772 4.68482 28.5962 5.90381C29.8152 7.12279 30.5 8.77609 30.5 10.5V11H41.75C42.0815 11 42.3995 11.1317 42.6339 11.3661C42.8683 11.6005 43 11.9185 43 12.25C43 12.5815 42.8683 12.8995 42.6339 13.1339C42.3995 13.3683 42.0815 13.5 41.75 13.5H38.833L36.833 37.356C36.681 39.1676 35.854 40.856 34.5158 42.0866C33.1776 43.3172 31.426 44.0001 29.608 44H18.392C16.5742 43.9998 14.8228 43.3168 13.4848 42.0863C12.1468 40.8557 11.3199 39.1674 11.168 37.356L9.168 13.5H6.25C5.91848 13.5 5.60054 13.3683 5.36612 13.1339C5.1317 12.8995 5 12.5815 5 12.25C5 11.9185 5.1317 11.6005 5.36612 11.3661C5.60054 11.1317 5.91848 11 6.25 11H17.5ZM21.5 20.25C21.5 20.0858 21.4677 19.9233 21.4049 19.7716C21.342 19.62 21.25 19.4822 21.1339 19.3661C21.0178 19.25 20.88 19.158 20.7284 19.0951C20.5767 19.0323 20.4142 19 20.25 19C20.0858 19 19.9233 19.0323 19.7716 19.0951C19.62 19.158 19.4822 19.25 19.3661 19.3661C19.25 19.4822 19.158 19.62 19.0951 19.7716C19.0323 19.9233 19 20.0858 19 20.25V34.75C19 34.9142 19.0323 35.0767 19.0951 35.2284C19.158 35.38 19.25 35.5178 19.3661 35.6339C19.4822 35.75 19.62 35.842 19.7716 35.9049C19.9233 35.9677 20.0858 36 20.25 36C20.4142 36 20.5767 35.9677 20.7284 35.9049C20.88 35.842 21.0178 35.75 21.1339 35.6339C21.25 35.5178 21.342 35.38 21.4049 35.2284C21.4677 35.0767 21.5 34.9142 21.5 34.75V20.25ZM27.75 19C27.06 19 26.5 19.56 26.5 20.25V34.75C26.5 35.0815 26.6317 35.3995 26.8661 35.6339C27.1005 35.8683 27.4185 36 27.75 36C28.0815 36 28.3995 35.8683 28.6339 35.6339C28.8683 35.3995 29 35.0815 29 34.75V20.25C29 19.56 28.44 19 27.75 19Z" fill="#2b2b2b"/>
              </svg>
            </div>
          </div>
          <div className="w-full space-between m-b-16 selected-gym-container d-flex">
            <div className="flex-item d-flex" style={{width: "85%"}}>
              <svg className="flex-item m-r-12" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2384_1592)">
                <path d="M20.1222 10.0611C20.1222 4.50455 15.6177 0 10.0611 0C4.50455 0 0 4.50447 0 10.0611C0 15.0829 3.67922 19.2453 8.48906 20V12.9694H5.93448V10.0611H8.48906V7.84452C8.48906 5.32296 9.99116 3.93012 12.2893 3.93012C13.3901 3.93012 14.5415 4.12663 14.5415 4.12663V6.60261H13.2728C12.0229 6.60261 11.6332 7.37818 11.6332 8.17387V10.0611H14.4235L13.9775 12.9694H11.6332V20C16.443 19.2453 20.1222 15.0829 20.1222 10.0611Z" fill="#1877F2"/>
                <path d="M13.8926 12.8906L14.3359 10H11.5625V8.12422C11.5625 7.33328 11.9499 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93047 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C8.95439 19.9595 9.4768 20.0001 10 20C10.5232 20.0002 11.0456 19.9595 11.5625 19.8785V12.8906H13.8926Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_2384_1592">
                <rect width="20" height="20" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <p className="fs-12 flex-item" style={{color: "#0088da", lineHeight: '16px', width: "70%", wordWrap: "break-word"}}>https://www.facebook.com/profile.php?id=100006665351485</p>
            </div>
            <div className='bell-menu-container header-menu-active cursor-pointer d-flex' style={{alignSelf: "flex-start"}}>
              <svg className="bell flex-item" width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 10.5V11H28V10.5C28 9.43913 27.5786 8.42172 26.8284 7.67157C26.0783 6.92143 25.0609 6.5 24 6.5C22.9391 6.5 21.9217 6.92143 21.1716 7.67157C20.4214 8.42172 20 9.43913 20 10.5ZM17.5 11V10.5C17.5 8.77609 18.1848 7.12279 19.4038 5.90381C20.6228 4.68482 22.2761 4 24 4C25.7239 4 27.3772 4.68482 28.5962 5.90381C29.8152 7.12279 30.5 8.77609 30.5 10.5V11H41.75C42.0815 11 42.3995 11.1317 42.6339 11.3661C42.8683 11.6005 43 11.9185 43 12.25C43 12.5815 42.8683 12.8995 42.6339 13.1339C42.3995 13.3683 42.0815 13.5 41.75 13.5H38.833L36.833 37.356C36.681 39.1676 35.854 40.856 34.5158 42.0866C33.1776 43.3172 31.426 44.0001 29.608 44H18.392C16.5742 43.9998 14.8228 43.3168 13.4848 42.0863C12.1468 40.8557 11.3199 39.1674 11.168 37.356L9.168 13.5H6.25C5.91848 13.5 5.60054 13.3683 5.36612 13.1339C5.1317 12.8995 5 12.5815 5 12.25C5 11.9185 5.1317 11.6005 5.36612 11.3661C5.60054 11.1317 5.91848 11 6.25 11H17.5ZM21.5 20.25C21.5 20.0858 21.4677 19.9233 21.4049 19.7716C21.342 19.62 21.25 19.4822 21.1339 19.3661C21.0178 19.25 20.88 19.158 20.7284 19.0951C20.5767 19.0323 20.4142 19 20.25 19C20.0858 19 19.9233 19.0323 19.7716 19.0951C19.62 19.158 19.4822 19.25 19.3661 19.3661C19.25 19.4822 19.158 19.62 19.0951 19.7716C19.0323 19.9233 19 20.0858 19 20.25V34.75C19 34.9142 19.0323 35.0767 19.0951 35.2284C19.158 35.38 19.25 35.5178 19.3661 35.6339C19.4822 35.75 19.62 35.842 19.7716 35.9049C19.9233 35.9677 20.0858 36 20.25 36C20.4142 36 20.5767 35.9677 20.7284 35.9049C20.88 35.842 21.0178 35.75 21.1339 35.6339C21.25 35.5178 21.342 35.38 21.4049 35.2284C21.4677 35.0767 21.5 34.9142 21.5 34.75V20.25ZM27.75 19C27.06 19 26.5 19.56 26.5 20.25V34.75C26.5 35.0815 26.6317 35.3995 26.8661 35.6339C27.1005 35.8683 27.4185 36 27.75 36C28.0815 36 28.3995 35.8683 28.6339 35.6339C28.8683 35.3995 29 35.0815 29 34.75V20.25C29 19.56 28.44 19 27.75 19Z" fill="#2b2b2b"/>
              </svg>
            </div>
          </div>
          <div onClick={toggleInputFields('user-insta-link')} className={`${(currentElementId == 'user-insta-link' || userDetails.profileLinks.instagram) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == 'user-insta-link' || userDetails.profileLinks.instagram) ? "1px solid #055075" : ""}}>
            <div className="flex-item d-flex">
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-insta-link' || userDetails.profileLinks.instagram) ? "10px" : "14px", color: (currentElementId == 'user-insta-link' || userDetails.profileLinks.instagram) ? "#055075" : "", transition: "180ms" }}>Instagram</p>
            </div>
            <input className={`${(currentElementId == 'user-insta-link' || userDetails.profileLinks.instagram) ? "active-input-field" : "inactive-input-field"}`} type="text" />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ManageProfile;