import React, {useEffect, useState} from "react";
import Dashboard from "../../../core/Dashboard";
import { startLoader, stopLoader, useAsyncState } from "../../../lib/utils";
import { getAllEnquiries, deleteSelectedEnquiries } from "../../../home/helper/api";
import { isAuthenticated } from "../../../auth";
import { formatDateTime } from '../../../lib/utils';
import * as config from '../../../config';
import { getAllBranches, getBranchCount } from '../../../branch/helper/api';
import { getAllGym, getGymCount } from '../../../gym/helper/api';
import _ from 'lodash';


const ManageEnquiry = () => {

  const flag = 0;
  // const pageSize = 14;
  const { user, token } = isAuthenticated();
  const [isButtonLock, setButtonLock] = useState(true);
  const [isEnqFilterOpen, setEnqFilterOpen] = useState(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [page, setpage] = useAsyncState(1);
  // const [total, setTotal] = useAsyncState(0);
  const [isItemCopy, setItemCopy] = useState({
    memberId: "",
    copyType: "",
    copied: false
  });
  const [enqList, setEnqList] = useState([]);
  const [isMemberFilterListOpen, setMemberFilterOpen] = useState(false);
  
  const [Branches, setBranches] = useState([]);
  const [isBranchFilterType, setBranchFilterType] = useState("");
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedGym, setSelectedGym] = useState('');
  const [isFilterModalOpen, setFilterModalOpen] = useState('');
  const [isBrachSpinnerLoader, setBranchSpinnerLoader] = useState(false);
  var [branchSearchKey, setBranchSearchKey] = useState("");
  const [gymSearchKey, setGymSearchKey] = useState("");
  const [isGymSpinnerLoader, setGymSpinnerLoader] = useState(false);
  const [Gyms, setGyms] = useState([]);
  var [isSearchBranch, setSearchBranch] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [enquiryIDs, setSelectedEnquiries] = useState([]);
  const [isBottomActionEnabled, setBottomActionEnabled] = useState(false);
  
  const [pageSize, setPageSize] = useState({
    branch: 30,
    gym: 30,
  });
  const [pageCount, setPageCount] = useState({
    branch: 0,
    gym: 0,
  });
  var [pageNo, setPageNo] = useState({
    branch: 0,
    gym: 0,
  })
  const [total, setTotal] = useState({
    gym: 0,
    branch: 0
  });



  const handleFilterModalOpen = async (filterType) => {
    try {

      if (isFilterModalOpen) {
        if (isFilterModalOpen == "branch") {
          setBranches([])
        }
        setFilterModalOpen('')
      } else {
        if (filterType == "branch") {
          setBranchSpinnerLoader(true);
          await GetBranchCount();
          await GetAllBranches();
          setBranchSpinnerLoader(false);
        }
        setFilterModalOpen(filterType)
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
      // do something
    }
  }

  // Gym Filter Actions

  const getGymParams = () => {
    let param = {
      pageSize: pageSize.gym,
      pageNo: pageNo.gym
    };
    if (gymSearchKey) param.q = gymSearchKey;
    param.activationStatus = "active";
    return param;
  }


  const handleClearGymFilter = () => {
    setSelectedGym("");
    setSelectedBranch("")
    setBranches([])
  }

  const GetGymCount = async () => {
    try {
      let params = getGymParams();
      let attr = await getGymCount(user._id, token, params);
      pageNo.gym = 1;
      setPageNo({
        ...pageNo
      })
      setTotal({
        ...total,
        gym: attr.COUNT
      });
      setPageCount({
        ...pageCount,
        gym: Math.ceil(attr.COUNT / pageSize.gym)
      });
    } catch (error) {
      // do something
    }
  }

  const GetAllGyms = async () => {
    try {
      let params = getGymParams();
      let gyms = await getAllGym(user._id, token, params);
      setGyms(gyms);
      if(gyms.length){
        isSearchBranch = true
        setSearchBranch(isSearchBranch);
        setSelectedGym(gyms[0]);
        setSelectedBranch("")
        setBranches([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGymSearch = event => {
    try {
      setGymSearchKey(event.target.value)
    } catch (error) {
      // do something
    }
  }

  const handleGymScroll = async event => {
    try {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.gym < pageCount.gym && !isGymSpinnerLoader) {
        setGymSpinnerLoader(true)
        pageNo.gym = pageNo.gym + 1;
        setPageNo({
          ...pageNo
        });
        await GetAllGyms();
        setGymSpinnerLoader(false)
      }
    } catch (error) {
      setGymSpinnerLoader(false)
    }
  }

  const handleGymSelect = gym => async event => {
    event.preventDefault()
    try {
      setSelectedGym(gym);
      setSelectedBranch("")
      setBranches([])
      handleFilterModalOpen()
    } catch (error) {
      // do something
    }
    event.stopPropagation()
  }

  // Branch Filter Actions

  const handleBranchFilter = (filterType, singleBranch) => {
    if(singleBranch){
      setBranchFilterType(filterType.name);
      setSelectedBranch(filterType);
    }else{
      setBranchFilterType(filterType);
    }
  }

  const GetBranchParams = () => {
    let params = {};
    params.noLimit = "noLimit";
    if (branchSearchKey) params.q = branchSearchKey;
    if (selectedGym) params.gymIds = [selectedGym._id];
    params.activationStatus = "active";
    return params;
  }

  const GetAllBranches = async () => {
    try {
      if(!selectedGym){
         setBranches([])
         return;
      }
      let params = GetBranchParams();
      let branches = await getAllBranches(user._id, token, params)
      setBranches(branches);
      if(branches.length == 1){
        setBranchFilterType(branches[0].name);
        setSelectedBranch(branches[0]);
      }else{
        setBranchFilterType("All");
      }
    } catch (error) {
      // do something 
    }
  }


  const GetBranchCount = async () => {
    try {
      let params = GetBranchParams();
      let attr = await getBranchCount(user._id, token, params);
      pageNo.branch = pageNo.branch + 1;
      setPageNo({
        ...pageNo
      })
      setTotal({
        ...total,
        branch: attr.COUNT
      });
      setPageCount({
        ...pageCount,
        branch: Math.ceil(attr.COUNT / pageSize.branch)
      });
    } catch (error) {
      // do something
    }
  }

  const handleBranchSearch = event => {
    branchSearchKey = event.target.value;
    setBranchSearchKey(branchSearchKey);
    setBranches([])
    isSearchBranch = true
    setSearchBranch(isSearchBranch)
  }

  const handleBranch = branch => async event => {
    event.preventDefault()
    setSelectedBranch(branch)
    handleFilterModalOpen()
    event.stopPropagation()
  }

  const handleBranchScroll = async event => {
    try {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.branch < pageCount.branch && !isBrachSpinnerLoader) {
        setBranchSpinnerLoader(true)
        pageNo.branch = pageNo.branch + 1;
        setPageNo({
          ...pageNo
        });
        await GetAllBranches();
        setBranchSpinnerLoader(false)
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
    }
  }


  const handleClearBranchFilter = () => {
    setSelectedBranch("")
  }

  const handleSearch = event => {
    try {
      setSearchKey(event.target.value);
    } catch (error) {
      // do something
    }
  }


  const prev = async () => {
    await startLoader()
    if (currentpage <= page && currentpage != 1) {
        currentpage=currentpage-1;
        setcurrentpage(currentpage);
    }
    await stopLoader()
  };

  const next = async () => {
      await startLoader()
      if (currentpage < page) {
          currentpage=currentpage+1;
          setcurrentpage(currentpage);
      }
      await stopLoader()
  };

  const handleCopy = (ID, copyType) => {
    if(ID){
      setItemCopy({
        ...isItemCopy,
        memberId: ID,
        copyType: copyType,
        copied: false,
      })
    }else{
      setItemCopy({
        ...isItemCopy,
        memberId: "",
        copyType: "",
        copied: false,
      })
    }
  }

  const handleCopied = (data, dataType) => {
    let formattedData = (dataType == "phone" && data.startsWith("+91")) ? data.substring(3) : data;
    navigator.clipboard.writeText(formattedData)
    .then(() => {
      setItemCopy({
        ...isItemCopy,
        copied: true,
      })
    })
    .catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  }

  const GetAllEnquiries = async () => {
    try {
      let enquiries = await getAllEnquiries(user._id, token);
      console.log(enquiries);
      if(selectedBranch && enquiries.length > 0){
        enquiries.map((data) => {
          if (data.branch.some((item) => item._id === selectedBranch._id)) {
            enqList.push(data);
          }
        });
      }else{
        setEnqList.push("")
      }   
      // setEnqList(enquiries);
      // console.log("Getting All Enquieriessssssssss", enquiries);
      if (!enquiries.error) {
        console.log(enquiries.error)
      }
    } catch (error) {
      //do something
      console.log(error)
    }
  }

  const toggleMemberFilterOpen = () => {
    if (isMemberFilterListOpen) {
      setMemberFilterOpen(false)
    } else {
      setMemberFilterOpen(true)
    }
  }

  const handleSelectEnq = (enq) => {
    setSelectedEnquiries((enquiryIDs) => {
      const exists = enquiryIDs.some(existingEnq => existingEnq === enq);
  
      if (exists) {
        return enquiryIDs.filter(existingEnq => existingEnq !== enq);
      } else {
        return [...enquiryIDs, enq];
      }
    });
  }

  const handleDeleteEnq = () => {
    deleteSelectedEnquiries(user._id, token, {enquiryIDs}).then(async data => {
      if (data.error) {
          console.log('error in deletion')
      } else {
          alert('successfully deleted');
          setEnqList(prevList =>
            prevList.filter(item => !enquiryIDs.includes(item.id))
          );
          // await GetAllEnquiries();
          setSelectedEnquiries([])
          setBottomActionEnabled(false)
      }
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    console.log(enquiryIDs)
    if(enquiryIDs.length > 0) {
      setBottomActionEnabled(true)
    }else{
      setBottomActionEnabled(false)
    }
  },[enquiryIDs])

  useEffect(async () => {
    try {
      await startLoader();
      if(selectedBranch == ""){
        setEnqList([])
        setSelectedEnquiries([])
        setBottomActionEnabled(false)
      }else{
        await GetAllEnquiries();
        setSelectedEnquiries([])
        setBottomActionEnabled(false)
      }
      await stopLoader();
    } catch (error) {
      await stopLoader()
    }
  }, [selectedBranch || selectedBranch == ""]);

  useEffect(async () => {
    try {
      setGymSpinnerLoader(true);
      await GetGymCount();
      await GetAllGyms();
      setGymSpinnerLoader(false);
    } catch (error) {
      setGymSpinnerLoader(false);
    }
  }, [gymSearchKey]);

  useEffect(async () => {
    await GetBranchCount();
    await GetAllBranches();
  },[user & user.role == 5])

  useEffect(async () => {
    if (isSearchBranch && Branches.length == 0) {
      setBranchSpinnerLoader(true)
      await GetBranchCount();
      await GetAllBranches();
      setBranchSpinnerLoader(false)
      isSearchBranch = false
      setSearchBranch(isSearchBranch)
    }
  }, [isSearchBranch, Branches])



  return(
    <Dashboard flag={flag}>
      <div className="header-bar d-flex space-between">
        <div className='d-flex'>
            {/* <svg className='flex-item m-r-8' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.92018 19.3041L4.45535 22.7689C4.25844 22.9658 4.14781 23.2329 4.14781 23.5114C4.14781 23.7898 4.25844 24.0569 4.45535 24.2538L15.3448 35.1433C15.4423 35.2408 15.558 35.3181 15.6854 35.3709C15.8128 35.4236 15.9494 35.4508 16.0873 35.4508C16.2251 35.4508 16.3617 35.4236 16.4891 35.3709C16.6165 35.3181 16.7322 35.2408 16.8297 35.1433L20.2945 31.6784C20.392 31.5809 20.4694 31.4652 20.5222 31.3378C20.5749 31.2104 20.6021 31.0739 20.6021 30.936C20.6021 30.7981 20.5749 30.6615 20.5222 30.5342C20.4694 30.4068 20.392 30.291 20.2945 30.1935L9.4051 19.3041C9.20819 19.1072 8.94112 18.9965 8.66264 18.9965C8.38416 18.9965 8.11709 19.1072 7.92018 19.3041V19.3041ZM22.7694 4.45483L19.3046 7.91965C19.1077 8.11656 18.9971 8.38363 18.9971 8.66211C18.9971 8.94059 19.1077 9.20766 19.3046 9.40457L30.194 20.294C30.2915 20.3915 30.4073 20.4689 30.5347 20.5216C30.6621 20.5744 30.7986 20.6016 30.9365 20.6016C31.0744 20.6016 31.2109 20.5744 31.3383 20.5216C31.4657 20.4689 31.5815 20.3915 31.679 20.294L35.1438 16.8292C35.2413 16.7317 35.3186 16.6159 35.3714 16.4886C35.4242 16.3612 35.4513 16.2246 35.4513 16.0867C35.4513 15.9488 35.4242 15.8123 35.3714 15.6849C35.3186 15.5575 35.2413 15.4418 35.1438 15.3443L24.2543 4.45482C24.0574 4.25791 23.7904 4.14729 23.5119 4.14729C23.2334 4.14729 22.9663 4.25791 22.7694 4.45483V4.45483Z" stroke="#055075" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.849 24.7487L24.7485 14.8492M9.15677 36.3806L3.21707 30.4409C3.02016 30.244 2.90953 29.977 2.90953 29.6985C2.90953 29.42 3.02016 29.1529 3.21707 28.956L5.44446 26.7286C5.64137 26.5317 5.90844 26.4211 6.18692 26.4211C6.4654 26.4211 6.73247 26.5317 6.92938 26.7286L12.8691 32.6683C13.066 32.8652 13.1766 33.1323 13.1766 33.4108C13.1766 33.6893 13.066 33.9563 12.8691 34.1533L10.6417 36.3806C10.4448 36.5776 10.1777 36.6882 9.89923 36.6882C9.62075 36.6882 9.35368 36.5776 9.15677 36.3806V36.3806ZM36.3804 9.15703L30.4407 3.21734C30.2438 3.02043 29.9767 2.9098 29.6982 2.9098C29.4197 2.9098 29.1527 3.02043 28.9558 3.21734L26.7284 5.44472C26.5315 5.64164 26.4208 5.90871 26.4208 6.18719C26.4208 6.46566 26.5315 6.73274 26.7284 6.92965L32.6681 12.8693C32.865 13.0663 33.1321 13.1769 33.4105 13.1769C33.689 13.1769 33.9561 13.0663 34.153 12.8693L36.3804 10.642C36.5773 10.445 36.6879 10.178 36.6879 9.8995C36.6879 9.62102 36.5773 9.35395 36.3804 9.15703V9.15703Z" stroke="#055075" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg> */}
            <svg className='flex-item m-r-8' width="46" height="46" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.29 21.961H9.39C6.142 21.961 4.517 21.961 3.508 20.936C2.499 19.911 2.5 18.261 2.5 14.961V9.961C2.5 6.661 2.5 5.011 3.51 3.987C4.517 2.96 6.142 2.96 9.39 2.96H12.343C15.592 2.96 17.49 3.016 18.499 4.04C19.509 5.066 19.499 6.66 19.499 9.96V11.147M15.945 2V4M10.945 2V4M5.945 2V4M7 15H11M7 10H15" stroke="#055075" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path opacity="0.93" d="M20.7601 14.8789C19.8541 13.8639 19.3111 13.9249 18.7081 14.1059C18.2851 14.1659 16.8371 15.8559 16.2331 16.3959C15.2421 17.3739 14.2461 18.3819 14.1811 18.5139C13.9931 18.8189 13.8191 19.3589 13.7341 19.9629C13.5771 20.8689 13.3511 21.8879 13.6371 21.9759C13.9231 22.0639 14.7241 21.8959 15.6291 21.7629C16.2331 21.6529 16.6551 21.5329 16.9571 21.3519C17.3801 21.0979 18.1641 20.2049 19.5171 18.8759C20.3641 17.9829 21.1821 17.3659 21.4241 16.7629C21.6651 15.8569 21.3041 15.3739 20.7601 14.8789Z" stroke="#055075" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div className='flex-item'>
                <p className="dashboard-type-heading">Enquiries</p>
                <p className='dashboard-route-txt'>Manage all your Enquiries here</p>
            </div>
        </div>
        {/* <div className='d-flex space-between' style={{width: "70%"}}> */}
            {/* <div className='filter-horizontal-scroll d-flex flex-item' style={{width:"50%", direction:"rtl"}}>
              {
                !isGymActive && (
                  <div className={`member-button-global ${isButtonLock?'member-button-global-inactive':""} btn-center d-flex`} style={{padding: "0 12px", height:"30px", marginRight:"12px"}}>
                    <p className='flex-item' style={{fontStyle: "12px"}}>Share With GOS</p>
                  </div>
                )
              }
            </div> */}
            <div className="d-flex flex-item">
              <div onClick={() => alert("Feature under development")} className={`member-button-global ${isButtonLock?'member-button-global-inactive':""} btn-center d-flex`} style={{padding: "0 12px", height:"30px", marginRight:"12px"}}>
                <p className='flex-item' style={{fontStyle: "12px"}}>Share With GOS</p>
              </div>
              <div className='d-flex flex-item'>
                <div id="search-container" className='global-search-box m-r-12'>
                  <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1637_1318)">
                          <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                      </g>
                      <defs>
                          <clipPath id="clip0_1637_1318">
                              <rect width="16" height="16" fill="white" />
                          </clipPath>
                      </defs>
                  </svg>
                  <input id="search-gym-input" className='flex-item' type="text" placeholder="Search Name" />
                </div>
                <div className='flex-item' style={{position:'relative'}}>
                  <div onClick={toggleMemberFilterOpen.bind(this)} className='global-round-btn d-flex m-r-12'>
                    <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill={isEnqFilterOpen ? "#055075" : "#6D6D6D"} />
                    </svg>
                  </div>
                    {
                      isMemberFilterListOpen && (
                        <div onClick={e => e.stopPropagation()} className='gym-filter-popup' style={{ width: "210px" }}>
                          {
                            user.role == 5 ? (
                              <div className='flex-item w-full'>
                                <p className='filter-heading-text m-b-6'>Branch(s)</p>
                                {
                                  Branches.length > 1 && (
                                    <div onClick={() => handleBranchFilter("All")} className='d-flex'>
                                      <input className='flex-item m-r-8' type='radio' name="branch" checked={isBranchFilterType == "All" ? true : false} />
                                      <p className='flex-item' style={{color : isBranchFilterType ? "#055075" : "", fontSize:"14px", fontWeight:"500"}}>ALL</p>
                                    </div>                              
                                  )
                                }
      
                                {
                                  Branches.map(data => {
                                    return(
                                      <div onClick={() => handleBranchFilter(data, true)} className='d-flex'>
                                        <input className='flex-item m-r-8' type='radio' name="branch" checked={isBranchFilterType == data.name ? true : false} />
                                        <p className='flex-item font-weight-500' style={{color : isBranchFilterType ? "#055075" : "", fontSize:"14px", fontWeight:"500"}}>{data.name}</p>
                                      </div>
                                    )
                                  })
                                }
      
                              </div>
                            ) : (
                              <div className='flex-item w-full'>
                                {
                                  !selectedGym.email && [
                                    <div className='flex-item w-full'>
                                      <div onClick={handleFilterModalOpen.bind(this, "gym")} className='member-button-global btn-center d-flex'>
                                        <p className='flex-item'>Filter By Gym</p>
                                      </div>
                                    </div>
                                  ]
                                }
                                {
                                  selectedGym && [
                                    <div className='d-flex'>
                                      <div className='d-flex'>
                                        <div className='d-flex flex-item' style={{ width: 'auto !important' }} >
                                          <p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
                                          <div className='flex-item d-flex m-r-12'>
                                            <svg onClick={handleFilterModalOpen.bind(this, "gym")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                            </svg>
                                          </div>
                                          <div className='flex-item d-flex'>
                                            <svg onClick={handleClearGymFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>,
                                    <div className="d-flex w-full m-t-8">
                                      {
                                        selectedGym.logoFileID  ? (
                                          <img className='flex-item m-r-8 branch-gym-logo' src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/25/25/70`} />
                                        ) : (
                                          <div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ background: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c" }}>
                                            <p className='flex-item' style={{ color: '#ffffff', margin: '0 auto' }}>{`${selectedGym.name}`.trim()[0]}</p>
                                          </div>
                                        )
                                      }
                                      <div className="flex-item">
                                        <p className="branch-gym-name">{selectedGym.name}</p>
                                        <p className="branch-gym-email">{selectedGym.email}</p>
                                      </div>
                                    </div>
                                  ]
                                }
                                {
                                  selectedGym &&!selectedBranch && [
                                    <div onClick={handleFilterModalOpen.bind(this, "branch")} className='member-button-global btn-center d-flex m-t-20'>
                                      <p className='flex-item'>Filter By Branch</p>
                                    </div>
                                  ]
                                }
                                {
                                  selectedBranch && [
                                    <div className='d-flex'>
                                      <div className='d-flex m-t-20'>
                                        <p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
                                        <div onClick={handleFilterModalOpen.bind(this, "branch")} className='flex-item d-flex m-r-12'>
                                          <svg className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                          </svg>
                                        </div>
                                        <div className='flex-item d-flex'>
                                          <svg onClick={handleClearBranchFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                                          </svg>
                                        </div>
                                      </div>
                                    </div>,
                                    <div className="w-full m-t-8">
                                      <p className="branch-gym-name">{selectedBranch.name}</p>
                                      <p className="branch-gym-email">{selectedBranch.address ? selectedBranch.address.placeName ? selectedBranch.address.placeName : selectedBranch.address.placeAddress ? selectedBranch.address.placeAddress : selectedBranch.address : ""}</p>
                                    </div>
                                  ]
                                }

                              </div>
                            )
                          }
                        </div>
                      )
                    }
                </div>
                <div className='global-round-btn m-r-12 d-flex'>
                  <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
                  </svg>
                </div>
              </div>
            </div>
        {/* </div> */}
      </div>

      <div className='dashboard-data-saperator'></div>
      
      <div className="body-container">
        <div className='d-flex table-header-container'>
          <div className='d-flex flex-item m-l-12 w-20'>
            <input className='flex-item m-r-8 cursor-pointer' type="checkbox" />
            <p className='flex-item table-heading-txt'>Name</p>
          </div>
          <div className='flex-item w-20 m-r-8'>
            <p className='flex-item table-heading-txt'>Email</p>
          </div>
          <div className='flex-item w-20'>
            <p className='flex-item table-heading-txt'>Contact No.</p>
          </div>
          <div className='flex-item w-20 m-r-8'>
            <p className='flex-item table-heading-txt'>Source</p>
          </div>
          <div className='flex-item w-15'>
            <p className='flex-item table-heading-txt'>Attendant</p>
          </div>
          <div className='flex-item w-10'>
            <p className='flex-item table-heading-txt'>Date</p>
          </div>
        </div>
        <div className='flex-item main-table-scroll-container w-full'>
          {
            enqList.map((data) => {
              return(  
                <div className='flex-item table-row-container d-flex' style={{ backgroundColor:  "#ffffff" }}>
                  <div className='flex-item d-flex m-l-12 w-20'>
                    <input onClick={()=>handleSelectEnq(data._id)} className='flex-item m-r-8' name="gym-checkbox" type="checkbox" checked={enquiryIDs.some(enq => enq === data._id)} style={{ cursor: 'pointer' }} />
                    <div className="table-logo-icon m-r-8 flex-item d-flex" style={{backgroundColor: "#5d883c"}}>
                      <p className='flex-item' style={{color: "#ffffff", margin: "0 auto", fontSize: 14}}>{`${data.userInfo.full_name}`.trim()[0]}</p>
                    </div>
                    <div onMouseOver={()=>data.userInfo.full_name && data.userInfo.full_name.length != 0 && handleCopy(data._id, "name")} onMouseLeave={()=>data.userInfo.full_name && data.userInfo.full_name.length != 0 && handleCopy()} className='flex-item copy-container'>
                      <div onClick={()=>handleCopied(data.userInfo.full_name && data.userInfo.full_name, "name")} className={`${isItemCopy.memberId == data._id && isItemCopy.copyType == "name" && "more-table-item-container"} d-flex`}>
                        <p className='flex-item m-r-8' style={{color: isItemCopy.memberId == data._id && isItemCopy.copyType == "name" && "#ffffff"}}>{data.userInfo.full_name ? data.userInfo.full_name.length > 18 ? data.userInfo.full_name.substring(0,18)+"...":data.userInfo.full_name : "-"}</p>
                        {
                          isItemCopy.memberId == data._id && isItemCopy.copyType == "name" && data.userInfo.full_name.length != 0 && [
                            isItemCopy.copied == false ? (
                              <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6992 1.66663 11.2753 1.66663 10.8333V3.33329C1.66663 2.89127 1.84222 2.46734 2.15478 2.15478C2.46734 1.84222 2.89127 1.66663 3.33329 1.66663H10.8333C11.2753 1.66663 11.6992 1.84222 12.0118 2.15478C12.3244 2.46734 12.5 2.89127 12.5 3.33329V4.16663M9.16663 7.49996H16.6666C17.5871 7.49996 18.3333 8.24615 18.3333 9.16663V16.6666C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6666V9.16663C7.49996 8.24615 8.24615 7.49996 9.16663 7.49996Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            ) : (
                              <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 10.5L7 15L16.5 4.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                              </svg>                          
                            )
                          ]
                        }
                      </div>
                    </div>
                  </div>
                  <div onMouseOver={()=>data.userInfo.email && data.userInfo.email.length != 0 && handleCopy(data._id, "email")} onMouseLeave={()=>data.userInfo.email && data.userInfo.email.length != 0 && handleCopy()} className='flex-item w-20 m-r-8 copy-container'>
                    <div onClick={()=>handleCopied(data.userInfo.email && data.userInfo.email, "email")} className={`${isItemCopy.memberId == data._id && isItemCopy.copyType == "email" && "more-table-item-container"} d-flex space-between`}>
                      <p className='flex-item' style={{color: isItemCopy.memberId == data._id && isItemCopy.copyType == "email" && "#ffffff"}}>{data.userInfo.email ? data.userInfo.email.length > 18 ? data.userInfo.email.substring(0,18)+"...":data.userInfo.email : "-"}</p>
                      {
                        isItemCopy.memberId == data._id && isItemCopy.copyType == "email" && data.userInfo.email.length != 0 && [
                          isItemCopy.copied == false ? (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6992 1.66663 11.2753 1.66663 10.8333V3.33329C1.66663 2.89127 1.84222 2.46734 2.15478 2.15478C2.46734 1.84222 2.89127 1.66663 3.33329 1.66663H10.8333C11.2753 1.66663 11.6992 1.84222 12.0118 2.15478C12.3244 2.46734 12.5 2.89127 12.5 3.33329V4.16663M9.16663 7.49996H16.6666C17.5871 7.49996 18.3333 8.24615 18.3333 9.16663V16.6666C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6666V9.16663C7.49996 8.24615 8.24615 7.49996 9.16663 7.49996Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          ) : (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 10.5L7 15L16.5 4.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                            </svg>                          
                          )
                        ]
                      }
                    </div>
                  </div>
                  <div onMouseOver={()=>data.userInfo.phone && data.userInfo.phone.length != 0 && handleCopy(data._id, "phone")} onMouseLeave={()=>data.userInfo.phone && data.userInfo.phone.length != 0 && handleCopy()} className='flex-item w-20 m-r-8 d-flex copy-container'>
                    <div onClick={()=>handleCopied(data.userInfo.phone && data.userInfo.phone, "phone")} className={`${isItemCopy.memberId == data._id && isItemCopy.copyType == "phone" && "more-table-item-container"} d-flex flex-item`}>
                      <p className='flex-item m-r-8' style={{color: isItemCopy.memberId == data._id && isItemCopy.copyType == "phone" && "#ffffff"}}>{data.userInfo.phone ? data.userInfo.phone.length > 18 ? data.userInfo.phone.substring(0,18)+"...":data.userInfo.phone : "-"}</p>
                      {
                        isItemCopy.memberId == data._id && isItemCopy.copyType == "phone" && data.userInfo.phone.length != 0 && [
                          isItemCopy.copied == false ? (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6992 1.66663 11.2753 1.66663 10.8333V3.33329C1.66663 2.89127 1.84222 2.46734 2.15478 2.15478C2.46734 1.84222 2.89127 1.66663 3.33329 1.66663H10.8333C11.2753 1.66663 11.6992 1.84222 12.0118 2.15478C12.3244 2.46734 12.5 2.89127 12.5 3.33329V4.16663M9.16663 7.49996H16.6666C17.5871 7.49996 18.3333 8.24615 18.3333 9.16663V16.6666C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6666V9.16663C7.49996 8.24615 8.24615 7.49996 9.16663 7.49996Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          ) : (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 10.5L7 15L16.5 4.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                            </svg>                          
                          )
                        ]
                      }
                    </div>
                  </div>
                  <div onMouseOver={()=>data.sourceOfEnquiry && data.sourceOfEnquiry.length != 0 && handleCopy(data._id, "source")} onMouseLeave={()=>data.sourceOfEnquiry && data.sourceOfEnquiry.length != 0 && handleCopy()} className='flex-item w-20 m-r-8 d-flex copy-container'>
                    <div onClick={()=>handleCopied(data.sourceOfEnquiry && data.sourceOfEnquiry, "source")} className={`${isItemCopy.memberId == data._id && isItemCopy.copyType == "source" && "more-table-item-container"} d-flex flex-item`}>
                      <p className='flex-item m-r-8' style={{color: isItemCopy.memberId == data._id && isItemCopy.copyType == "source" && "#ffffff"}}>{data.sourceOfEnquiry ? data.sourceOfEnquiry.length > 18 ? data.sourceOfEnquiry.substring(0,18)+"...":data.sourceOfEnquiry : "-"}</p>
                      {
                        isItemCopy.memberId == data._id && isItemCopy.copyType == "source" && data.sourceOfEnquiry.length != 0 && [
                          isItemCopy.copied == false ? (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6992 1.66663 11.2753 1.66663 10.8333V3.33329C1.66663 2.89127 1.84222 2.46734 2.15478 2.15478C2.46734 1.84222 2.89127 1.66663 3.33329 1.66663H10.8333C11.2753 1.66663 11.6992 1.84222 12.0118 2.15478C12.3244 2.46734 12.5 2.89127 12.5 3.33329V4.16663M9.16663 7.49996H16.6666C17.5871 7.49996 18.3333 8.24615 18.3333 9.16663V16.6666C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6666V9.16663C7.49996 8.24615 8.24615 7.49996 9.16663 7.49996Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          ) : (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 10.5L7 15L16.5 4.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                            </svg>                          
                          )
                        ]
                      }
                    </div>
                  </div>
                  <div onMouseOver={()=>data.gosEmployeeDetail.first_name && data.gosEmployeeDetail.first_name.length != 0 && handleCopy(data._id, "attendant")} onMouseLeave={()=>data.gosEmployeeDetail.first_name && data.gosEmployeeDetail.first_name.length != 0 && handleCopy()} className='flex-item w-15 d-flex copy-container'>
                    <div onClick={()=>handleCopied(data.gosEmployeeDetail.first_name && data.gosEmployeeDetail.last_name && data.gosEmployeeDetail.first_name + data.gosEmployeeDetail.last_name, "attendant")} className={`${isItemCopy.memberId == data._id && isItemCopy.copyType == "attendant" && "more-table-item-container"} d-flex flex-item`}>
                      <p className='flex-item m-r-8' style={{color: isItemCopy.memberId == data._id && isItemCopy.copyType == "attendant" && "#ffffff"}}>{data.gosEmployeeDetail.first_name ? data.gosEmployeeDetail.first_name.length > 18 ? data.gosEmployeeDetail.first_name.substring(0,18)+"...":data.gosEmployeeDetail.first_name : "-"}</p>
                      {
                        isItemCopy.memberId == data._id && isItemCopy.copyType == "attendant" && data.gosEmployeeDetail.first_name.length != 0 && [
                          isItemCopy.copied == false ? (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6992 1.66663 11.2753 1.66663 10.8333V3.33329C1.66663 2.89127 1.84222 2.46734 2.15478 2.15478C2.46734 1.84222 2.89127 1.66663 3.33329 1.66663H10.8333C11.2753 1.66663 11.6992 1.84222 12.0118 2.15478C12.3244 2.46734 12.5 2.89127 12.5 3.33329V4.16663M9.16663 7.49996H16.6666C17.5871 7.49996 18.3333 8.24615 18.3333 9.16663V16.6666C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6666V9.16663C7.49996 8.24615 8.24615 7.49996 9.16663 7.49996Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          ) : (
                            <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 10.5L7 15L16.5 4.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                            </svg>                          
                          )
                        ]
                      }
                    </div>
                  </div>
                  <div className='flex-item w-10'>
                    <p className='flex-item table-heading-txt'>{formatDateTime(data.createdAt)}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className='bottom-pagination-bar'>
          <div className='d-flex flex-item space-between'>
            <div onClick={prev} className='global-button-container d-flex flex-item'>
              <p className='flex-item'>Previous</p>
            </div>
            {/* <p className='flex-item pagination-txt'>{((currentpage - 1) * pageSize) + 1} - {((currentpage - 1) * pageSize) + enqList.length}  of  {total}</p> */}
            <div onClick={next} className='global-button-container d-flex flex-item'>
              <p className='flex-item'>Next</p>
            </div>
            {
              isBottomActionEnabled && (
                <div onClick={handleDeleteEnq} className='global-button-container d-flex flex-item'>
                  <p className='flex-item'>Delete Selected Enquiries</p>
                </div>
              )
            }
          </div>
        </div>
      </div>

      {
        isFilterModalOpen == "gym" && (
          <div className="global-modal-container d-flex">
            <div className="global-filter-modal flex-item">
              <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                <p className="flex-item modal-header-text">Select Gym</p>
                <div id="search-container-gym" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                  <svg id="search-icon-gym" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1637_1318)">
                      <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1637_1318">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input id="search-gym-input" onChange={_.debounce(handleGymSearch, 1000)} className='flex-item' type="text" placeholder="Search Gym" style={{ background: "#f5f5f5" }} />
                </div>
              </div>
              <div className='search-gym-modal-scroll' onScroll={handleGymScroll}>
                {
                  Gyms.map(data => {
                    return (
                      <div onClick={handleGymSelect(data)} className="d-flex suggestion-list-items">
                        {
                          data.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${data.logoFileID}/25/25/70`} />
                          ) : (
                            <div className='d-flex flex-item branch-gym-user-profile m-r-8' style={{
                              backgroundColor: data.logoPlaceholderColor ? data.logoPlaceholderColor : "#5d883c"
                            }}>
                              <p className='flex-item fs-14' style={{
                                margin: '0 auto',
                                color: "#ffffff"
                              }}>{`${data.name}`.trim()[0]}</p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.email}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {isGymSpinnerLoader && <div className="loader-5 center"><span></span></div>}
              </div>
              <div onClick={handleFilterModalOpen.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                <div className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                  <p className='flex-item'>Cancel</p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        isFilterModalOpen == "branch" && (
          <div className="global-modal-container d-flex">
            <div className="global-filter-modal flex-item">
              <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                <p className="flex-item modal-header-text">Select Branch</p>
                <div id="search-container-branch" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                  <svg id="search-icon-branch" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1637_1318)">
                      <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1637_1318">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input id="search-branch-input" onChange={_.debounce(handleBranchSearch, 1000)} className='flex-item' type="text" placeholder="Search" style={{ background: "#f5f5f5" }} />
                </div>
              </div>
              <div className='search-gym-modal-scroll' onScroll={handleBranchScroll}>
                {
                  Branches.map(data => {
                    return (
                      <div onClick={handleBranch(data)} className="d-flex suggestion-list-items">
                        {
                          selectedGym.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/25/25/70`} />
                          ) : (
                            <div className='d-flex flex-item branch-gym-user-profile m-r-8' style={{
                              backgroundColor: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c"
                            }}>
                              <p className='flex-item fs-14' style={{
                                margin: '0 auto',
                                color: "#ffffff"
                              }}>{`${selectedGym.name}`.trim()[0]}</p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.address ? data.address.placeName ? data.address.placeName : data.address.placeAddress ? data.address.placeAddress : data.address : ""}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {isBrachSpinnerLoader && <div className="loader-5 center"><span></span></div>}
              </div>
              <div onClick={handleFilterModalOpen.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                <div className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                  <p className='flex-item'>Cancel</p>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </Dashboard>
  )
}

export default ManageEnquiry