const SpinnerLoader=()=>{
  return (
    <div id="app-loader" style={{
      zIndex:999,
      display:'none',
      position: 'fixed',
      top: '50%', 
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}>
      <div className="loader-5 center"><span></span></div>
    </div>
  )
}

export default SpinnerLoader;