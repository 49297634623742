import { API } from "../../../backend"
import { objectToQueryString } from "../../../lib/utils"


//workout planner method 
export const getWorkoutPlanner = (userId, token, plannerId) => {
  return fetch(`${API}/workout/planner/${userId}/${plannerId}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}


export const getAllWorkoutPlanners = (userId, token,query) => {
  let str=objectToQueryString(query);
  return fetch(`${API}/workout/planners/${userId}?${str}`, {
    method: 'get',
    headers: {
      Accept: 'applicaton/json',
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}


export const getWorkoutPlannerCount = (userId, token, query) => {
  let str=objectToQueryString(query);
  return fetch(`${API}/workout/planners/count/${userId}?${str}`, {
    method: 'get',
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}

export const createPlanner = (userId, token, payload) => {
  return fetch(`${API}/create/workout/planner/${userId}`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  }).then(res => res.json()).catch(console.error)
}

export const editPlanner = (userId, token, plannerId, body) => {
  return fetch(`${API}/update/workout/planner/${userId}/${plannerId}`, {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  }).then(res => res.json()).catch(console.error)
}

export const changePlannerStatus = (userId, token,status, body) => {
  return fetch(`${API}/change/workout/planner/status/${userId}/${status}`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  }).then(res => res.json()).catch(console.error)
}


export const deletePlanner = (userId, token, plannerId) => {
  return fetch(`${API}/delete/workout/planner/${userId}/${plannerId}`, {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}


export const assignPlanner=(userId,token,body)=>{
  return fetch(`${API}/assign/workout/planner/${userId}`,{
    method:'post',
    headers:{
      Accept:'application/json',
      'Content-type':'application/json',
      Authorization:`Bearer ${token}`
    },
    body:JSON.stringify(body)
  }).then(res=>res.json()).catch(console.error)
}

// end of workout  planner method



// Fitness planner method

export const createFitnessTestPlanner=(userId,token,body)=>{
  return fetch(`${API}/fitness-test/create/${userId}`,{
    method:"post",
    headers:{
      'Content-Type':'application/json',
      Authorization:`Bearer ${token}`,
      Accept:'application/json'
    },
    body:JSON.stringify(body)
  }).then(response=>response.json()).catch(console.error)
}

export const updateFitnesTestPlanner=(userId,token,fitnessPlannerId,body)=>{
  return fetch(`${API}/fitness-test/update/${userId}/${fitnessPlannerId}`,{
    method:"put",
    headers:{
      'Content-Type':'application/json',
      Authorization:`Bearer ${token}`,
      Accept:'application/json'
    },
    body:JSON.stringify(body)
  }).then(response=>response.json()).catch(console.error)
}

export const getFitnessTestPlanner = (userId, token, plannerId) => {
  return fetch(`${API}/fitness-test/planner/${userId}/${plannerId}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}


export const getAllFitnessTestPlanners = (userId, token,query) => {
  let str=objectToQueryString(query);
  return fetch(`${API}/fitness-test/planners/${userId}?${str}`, {
    method: 'get',
    headers: {
      Accept: 'applicaton/json',
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}


export const getFitnessTestPlannerCount = (userId, token, query) => {
  let str=objectToQueryString(query);
  return fetch(`${API}/fitness-test/planners/count/${userId}?${str}`, {
    method: 'get',
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}


export const changeFitnesTestPlannerStatus = (userId, token,status, body) => {
  return fetch(`${API}/change/fitness-test/planner/status/${userId}/${status}`, {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  }).then(res => res.json()).catch(console.error)
}


export const deleteFitnessTestPlanner = (userId, token, plannerId) => {
  return fetch(`${API}/fitness-test/planner/delete/${userId}/${plannerId}`, {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}


export const assignFitnessTestPlanner=(userId,token,body)=>{
  return fetch(`${API}/assign/fitness-test/planner/${userId}`,{
    method:'post',
    headers:{
      Accept:'application/json',
      'Content-type':'application/json',
      Authorization:`Bearer ${token}`
    },
    body:JSON.stringify(body)
  }).then(res=>res.json()).catch(console.error)
}

// end of planner metod
