import React, {useState, useEffect} from "react";

const TermsAndConditions = () => {
  return(
    <div class="contract">
      <div class='contract-scroll-container'>
        <h7><strong>TERMS & CONDITIONS</strong></h7>
        <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p><br/>
        <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of <a href="https://workoutplus.in/" target="_blank"> https://workoutplus.in</a> website or application.</p><br/>
        <p>Please read the following terms and conditions very carefully as your use of the service is subject to your acceptance of and compliance with the following terms and conditions ("Terms").</p><br/>
        <p>By subscribing to or using any of our services you agree that you have read, understood and are bound by the Terms, regardless of how you subscribe to or use the services. If you do not want to be bound by the Terms, you must not subscribe to or use our services. These Terms and various other policies are binding as per the provisions of the Information Technology (Intermediaries guidelines) Rules, 2011 formulated under the Information Technology Act of 2000.</p><br/>
        <p>In these Terms, references to "you", "User" shall mean the end user accessing the Website, its contents and using the Services offered through the Website, "Service Providers" mean independent third party service providers, and "we", "us" and "our" shall mean <strong>AR Efitness Solutions Private Limited</strong> and its affiliates.</p><br/>
        <h7><strong>1. Introduction:</strong></h7>
        <p>a) The domain name <a href = "https://workoutplus.in/" target="_blank"> https://workoutplus.in/</a> (hereinafter referred to as "Website") is owned by <strong>AR Efitness Solutions Private Limited</strong> a company incorporated under the Companies Act, 1956, CIN-U92190PN2016PTC167726 with its registered office at Office No:531, Amanora Chambers, Above Amanora Park Town, Hadspsar, Pune, India (hereinafter referred to as <strong>"Workout Plus”</strong>)</p><br/>
        <p>b) Use of the Website is offered to you conditioned on acceptance without modification of all the terms, conditions and notices contained in these Terms, as may be posted on the Website from time to time. Workout Plus at its sole discretion reserves the right not to accept a User from registering on the Website without assigning any reason thereof.</p><br/>
        <p><strong>2. User Account, Password, and Security:</strong></p><br/>
        <p>You will receive a registration email upon completing the Website's registration process. You are responsible for maintaining the confidentiality of your password and account, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Workout Plus of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. Workout Plus cannot and will not be liable for any loss or damage arising from your failure to comply with this Section 2.</p><br/>
        <p><strong>3. Services Offered:</strong></p><br/>
        <p>Workout Plus provides a number of Internet-based services through the Web Site (all such services, collectively, the "Service"). One such service enables users to purchase merchandise such as clothing, footwear and accessories (collectively, "Products"). The Products can be purchased through the Website through various methods of payments offered. The sale/purchase of Products shall be additionally governed by specific policies of sale, like cancellation policy, return policy, etc., and all of which are incorporated here by reference. In addition, these terms and policies may be further supplemented by Product specific conditions, which may be displayed on the webpage of that Product.</p><br/>
        <p><strong>4. Privacy:</strong></p><br/>
        <p>The User hereby consents, expresses and agrees that he or she has read and fully understands the Privacy Policy of Workout Plus available in the site. The user further consents that the terms and contents of such Privacy Policy are acceptable to him or her.</p><br/>
        <p><strong>5. Limited User:</strong></p><br/>
        <p>The User agrees and undertakes not to reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or images obtained from the Website. Limited reproduction and copying of the content of the Website is permitted provided that Workout Plus's name is stated as the source and prior written permission of Workout Plus is sought. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of the Website is not permitted.</p><br/>
        <p><strong>6. User Conduct and Rules:</strong></p><br/>
        <p>You agree and undertake to use the Website and the Service only to post and upload messages and images that are proper. By way of example, and not as a limitation, you agree and undertake that when using a Service, you will not:</p><br/>
        <p>(a)  Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;</p><br/>
        <p>(b)  Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;</p><br/>
        <p>(c)  Upload files that contain software or other material protected by intellectual property laws unless you own or control the rights thereto or have received all necessary consents; you own or control the rights thereto or have received all necessary consents;</p><br/>
        <p>(d)  Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer;</p><br/>
        <p>(e)  Conduct or forward surveys, contests, pyramid schemes or chain letters;</p><br/>
        <p>(f)  Download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such manner;</p><br/>
        <p>(g)  Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</p><br/>
        <p>(h)  Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;</p><br/>
        <p>(i)  Violate any applicable laws or regulations for the time being in force in or outside India; and</p><br/>
        <p>(j)  Violate, abuse, unethically manipulate or exploit, any of the terms and conditions of this Agreement or any other terms and conditions for the use of the Website contained elsewhere.</p><br/>
        <p>k) You shall not use any "deep-link", "page-scrape", "robot,'' "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Website or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Website. We reserve Our right to bar any such activity.</p><br/>
        <p>l) You shall not attempt to gain unauthorized access to any portion or feature of the Website, or any other systems or networks connected to the Website or to any server, computer, network, or to any of the services offered on or through the Website, by hacking, password "mining" or any other illegitimate means.</p><br/>
        <p>m) You shall not probe, scan or test the vulnerability of the Website or any network connected to the Website nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to Website, or any other customer, including any account on the Website not owned by You, to its source, or exploit the Website or any service or information made available or offered by or through the Website, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Website.</p><br/>
        <p>n) You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us including the terms Workout Plus, or otherwise engage in any conduct or action that might tarnish the image or reputation, of Workout Plus or our product suppliers or artisans or otherwise tarnish or dilute any of our trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us.</p><br/>
        <p>o) You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or any transaction being conducted on the Website, or with any other person's use of the Website.</p><br/>
        <p>p) You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal You send to Us on or through the Website or any service offered on or through the Website. You may not pretend that You are, or that You represent, someone else, or impersonate any other individual or entity.</p><br/>
        <p>q) You may not use the Website or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes on our rights or others.</p><br/>
        <p>r) You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time and also all applicable Domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or Regulations in Force) , Income Tax, GST) regarding Your use of Our service and Your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</p><br/>
        <p><strong>7. User Warranty and Representation:</strong></p><br/>
        <p>The user guarantees, warrants, and certifies that you are the owner of the content which you submit or otherwise authorized to use the content and that the content does not infringe upon the property rights, intellectual property rights or other rights of others. You further warrant that to your knowledge, no action, suit, proceeding, or investigation has been instituted or threatened relating to any content, including trademark, trade name service mark, and copyright formerly or currently used by you in connection with the Services rendered by Workout Plus.</p><br/>
        <p><strong>8. Intellectual Property Rights:</strong></p><br/>
        <p>a) Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, Workout Plus owns all Intellectual Property Rights to and into the Website, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, trademarks, trade names, service marks, designs, know-how, trade secrets and inventions (whether patentable or not), goodwill, source code, curriculum videos, meta tags, databases, text, content, graphics, icons, and hyperlinks. You acknowledge and agree that you shall not use, reproduce or distribute any content from the Website belonging to Workout Plus without obtaining authorization from Workout Plus.</p><br/>
        <p>b) Notwithstanding the foregoing, it is expressly clarified that you will retain ownership and shall solely be responsible for any content that you provide or upload when using any Service, including any text, data, information, images, photographs, music, sound, video or any other material which you may upload, transmit or store when making use of our various Service.</p><br/>
        <p><strong>9. Links To Third Party Sites:</strong></p><br/>
        <p>The Website may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Workout Plus or the Website and Workout Plus is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Workout Plus is not responsible for any form of transmission, whatsoever, received by you from any Linked Site <a href="https://workoutplus.in" target="_blank"> https://workoutplus.in</a> is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Workout Plus or the Website of the Linked Sites or any association with its operators or owners including the legal heirs or assigns thereof. The users are requested to verify the accuracy of all information on their own before undertaking any reliance on such information.</p><br/>
        <p><strong>10. Disclaimer Of Warranties/Limitation Of Liability:</strong></p><br/>
        <p>Workout Plus has endeavored to ensure that all the information on the Website is correct, but Workout Plus neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, product or Service. In no event shall Workout Plus be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from: (a) the use or the inability to use the Services or Products; (b) unauthorized access to or alteration of a user's transmissions or data; (c) any other matter relating to the services; including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Website or Service. Neither shall Workout Plus be responsible for the delay or inability to use the Website or related services, the provision of or failure to provide Services, or for any information, software, products, services and related graphics obtained through the Website, or otherwise arising out of the use of the website, whether based on contract, tort, negligence, strict liability or otherwise. Further, Workout Plus shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the website that may occur due to technical reasons or for any reason beyond our control. The user understands and agrees that any material and/or data downloaded or otherwise obtained through the Website is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data.</p><br/>
        <p><strong>11. Indemnification:</strong></p><br/>
        <p>You agree to indemnify, defend and hold harmless Workout Plus from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Workout Plus that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by you pursuant to these Terms.</p><br/>
        <p><strong>12. Termination:</strong></p><br/>
        <p>a)  Workout Plus may suspend or terminate your use of the Website or any Service if it believes, in its sole and absolute discretion that you have breached, violated, abused, or unethically manipulated or exploited any term of these Terms or otherwise acted unethically.</p><br/>
        <p>b) Notwithstanding Section 15.a above, these Terms will survive indefinitely unless and until Workout Plus chooses to terminate them.</p><br/>
        <p>c)  If you or Workout Plus terminates your use of the Website or any Service, Workout Plus may delete any content or other materials relating to your use of the Service and Workout Plus will have no liability to you or any third party for doing so.</p><br/>
        <p><strong>13. Governing Law:</strong></p><br/>
        <p>These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Pune.</p><br/>
        <p><strong>14. Severability:</strong></p><br/>
        <p>If any provision of the Terms is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of these Terms shall continue to be in full force and effect.</p><br/>
        <p><strong>15. Report Abuse:</strong></p><br/>
        <p>As per these Terms, users are solely responsible for every material or content uploaded onto the Website. Users can be held legally liable for their contents and may be held legally accountable if their contents or material include, for example, defamatory comments or material protected by copyright, trademark, etc. If you come across any abuse or violation of these Terms, please report to <a href="mailto:info@workoutplus.in ">info@workoutplus.in.</a></p><br/>
        {/* <p>BY USING THIS WEBSITE AND MOBILE APPLICATION, YOU SIGNIFY YOUR AGREEMENT TO THE TERMS OF THIS PRIVACY POLICY, SHAPE INDIA RESERVES THE RIGHT, IN OUR SOLE DISCRETION, TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY AT ANY TIME.</p><br/><br/> */}
        <p><strong>Reach us at <a href="https://www.workoutplus.in" target="_blank">workoutplus.in</a></strong></p>
        {/* <p><strong>Raju Deb - Co-Founder & Director</strong></p> */}
        {/* <p><strong>Workout Plus Team</strong></p>
        <p><strong>A Venture by AR Efitness Solutions Private Limited</strong></p>
        <p><strong>Mobile: +91 9372675439.</strong></p>
        <p><strong>Email: </strong><a href="mailto: info@workoutplus.in" >info@workoutplus.in</a></p>
        <p><a href="www.workoutplus.in" target="_blank">www.workoutplus.in</a></p><br/> */}
      </div>
    </div>
  )
}

export default TermsAndConditions;