import React, { useState, useEffect } from "react";
import Dashboard from "../../../core/Dashboard";
import FloatingMenu from "../../floatingMenu";
import MenuMob from '../../menu';
import { startLoader, stopLoader, useAsyncState } from "../../../lib/utils";
import SampleIcon from "../../../assets/gymshim-logo-icon.png";
import _ from 'lodash';
import InstafitLogo from "../../../assets/gymshim-logo-icon.png";
import InstafitLogoLong from "../../../assets/logo_long.png";
import { Html5Qrcode } from "html5-qrcode";
import { changeDeviceStatus, createDevice, getAllDevice, getDeviceCount } from "../helper";
import { isAuthenticated } from "../../../auth";
import config from "../../../config";
import { useLongPress } from 'use-long-press';
import { getAllGym, getGymCount } from "../../../gym/helper/api";
import { getAllBranches, getBranchCount } from "../../../branch/helper/api";
import ManageConfirmationModal from '../../../components/Modal/ConfirmModal';


let html5QrCode;

const ManageScreens = (props) => {

  var [searchKey, setSearchKey] = useState("");
  const [isStatusChangeModalOpen, setStatusChangeModal] = useState(false);
  const [isBulkStatusChangeAction, setBulkStatusChangeAction] = useState(false)
  const [selectedDeviceIDs, setSelectedDeviceIDs] = useState([]);
  const [startGymLoader, setStartGymLoader] = useState(false);
  const [startBranchLoader, setStartBranchLoader] = useState(false);
  var [prevSearchKeys, setPrevSearchKeys] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [isStartLoader, setStartLoader] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentActionItem, setCurrentActionItem] = useState('');
  const [isDeviceActive, setDeviceActive] = useAsyncState(true);
  const [deviceList, setDeviceList] = useAsyncState([]);
  const [isScreenApiAction, setScreenApiAction] = useState(false);
  const [isAllBranchChecked, setAllBranchChecked] = useState(false);
  const [isQRScannerOpen, setQRScannerOpen] = useState(false);
  const [WebCamWindow, setWebcamWindow] = useState();
  const [isGymRegistrationOpen, setGymRegistrationOpen] = useState(false);
  const [isInputFieldActive, setInputFieldActive] = useState("");
  const [isGymSelectionOpen, setGymSelectionOpen] = useState(false);
  const [isGymFilterOpen, setGymFilterOpen] = useState(false);
  const [gymType, setGymType] = useState("All");
  const [CurrentFilterFilterSearch, setCurrentFilterFilterSearch] = useState("");
  const [selectedGym, setSelectedGym] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [isBranchSelectionOpen, setBranchSelectionOpen] = useState(false);
  const [sortByBranchIDs, setSortByBranchIDs] = useState([]);
  const [sortByGym, setSortByGym] = useState("");
  const [isLongPressActivated, setLongPressActivated] = useState(false);
  var [pageNo, setPageNo] = useState({
    device: 1,
    branch: 1,
    gym: 1
  })
  const [pageSize, setPageSize] = useState({
    device: 20,
    branch: 20,
    gym: 20
  })
  const [pageCount, setPageCount] = useState({
    device: 1,
    branch: 1,
    gym: 1
  })
  const [total, setTotal] = useState({
    device: 0,
    branch: 0,
    gym: 0
  })

  const [regDevice, setRegDevice] = useState({
    name: "",
    gymId: "",
    branchId: "",
    secret: "",
    token: ""
  });
  const [Gyms, setGyms] = useState([]);
  const [Branches, setBranches] = useState([]);
  const [searchGymKey, setSearchGymKey] = useState("");
  const [searchBranchKey, setSearchBranchKey] = useState("");
  const [isButtonLock, setButtonLock] = useState(true);
  const [prevCurrentActionItem, setPrevCurrentActionItem] = useState("");

  const [tempIDs, setTempIDs] = useState([]);


  const { user, token } = isAuthenticated();


  const handleClearGym = async () => {
    setSortByGym("");
    setSortByBranchIDs([])
  }


  const toggleFilterSearch = (currentFilterItem) => {
    setCurrentFilterFilterSearch(currentFilterItem);
  }

  const handleBulkStatusChangeAction = event => {
    setStatusChangeModal(!isStatusChangeModalOpen);
    setBulkStatusChangeAction(true);
  }

  const handleClearBranch = () => {
    setSortByBranchIDs([]);
  }

  const handleTempIDs = id => event => {
      let ids = [...tempIDs];
      let index = ids.findIndex(d => d == id);
      if (index == -1) {
          ids.push(id);
      } else {
          ids.splice(index, 1);
      }
      setTempIDs(ids);
  }

  const confirmTempSelectionIDs = async event => {
    setSortByBranchIDs(tempIDs);
    backToCurrentActionItem();
    setTempIDs([]);
  }



const toggleActiveInactiveButton = () => {
  if (isDeviceActive) {
    setDeviceActive(false);
  } else {
    setDeviceActive(true);
  }
}


const handleStatusChangeAction = device => event => {
  setSelectedDevice(device);
  setStatusChangeModal(!isStatusChangeModalOpen);
  setBulkStatusChangeAction(false);
  event.stopPropagation()
}


const backToCurrentActionItem = event => {
  setCurrentActionItem(prevCurrentActionItem)
  setPrevCurrentActionItem("")
}


const handleAction = async event => {
  event.preventDefault();
  try {
    await startLoader()
    let status = (isBulkStatusChangeAction ? isDeviceActive : (!selectedDevice.deletedAt || selectedDevice.deletedAt == null)) ? "deactivate" : "activate";
    let deviceIDs = isBulkStatusChangeAction ? selectedDeviceIDs : [selectedDevice._id];
    await changeDeviceStatus(user._id, token, status, { deviceIDs });
    setStatusChangeModal(false)
    setBulkStatusChangeAction(false);
    setSelectedDeviceIDs([]);
    setSelectedDevice("")
    setScreenApiAction(!isScreenApiAction);
    await stopLoader()
  } catch (error) {
    await stopLoader()
  }
  event.stopPropagation()
}


const clearDeviceIDs = () => {
  setSelectedDeviceIDs([])
}


const handleSelectDeviceID = (deviceID) => e => {
  e.preventDefault();
  let ids = [...selectedDeviceIDs]
  let index = ids.findIndex(id => id == deviceID);
  if (index == -1) {
    ids.push(deviceID)
  } else {
    ids.splice(index, 1)
  }

  if (ids.length == 0) {
    setLongPressActivated(false)
  }

  setSelectedDeviceIDs(ids);
}



const checkDataField = () => {
  if (regDevice.gymId && regDevice.branchId && regDevice.secret && regDevice.token) {
    setButtonLock(false)
  } else {
    setButtonLock(true)
  }
}

const clearSelectedGym = event => {
  setSelectedGym("")
  setSelectedBranch("")
  setGymSelectionOpen(true);
  setRegDevice({
    ...regDevice,
    gymId: ""
  })
  setBranchSelectionOpen(false);
}

const clearSelectedBranch = event => {
  setSelectedBranch("")
  setBranchSelectionOpen(true)
  setRegDevice({
    ...regDevice,
    branchId: ""
  })
}

const LongPress = useLongPress((e, deviceId) => {
  if (!isLongPressActivated) {
    setSelectedDeviceIDs(oldArray => [...oldArray, deviceId.context]);
    setTimeout(() => {
      setLongPressActivated(true)
    }, 300)
  }
});


const handleSelectGym = gym => event => {
  setSelectedGym(gym)
  toggleSelectGym()
  toggleSelectBranch();
  setRegDevice({
    ...regDevice,
    gymId: gym._id
  })
}

const handleSelectBranch = branch => event => {
  setSelectedBranch(branch)
  toggleSelectBranch()
  setRegDevice({
    ...regDevice,
    branchId: branch._id
  })
}

const searchGym = event => {
  setSearchGymKey(event.target.value);
}

const searchBranch = event => {
  setSearchBranchKey(event.target.value)
}

const handleChange = name => event => {
  setRegDevice({
    ...regDevice,
    [name]: event.target.value
  })
}

const backToScanCode = event => {
  setSelectedGym("");
  setSelectedBranch("");
  setRegDevice({
    ...regDevice,
    gymId: "",
    branchId: "",
    name: "",
    secret: "",
    token: ""
  })
  setGymSelectionOpen(true)
  setBranchSelectionOpen(false)
  setGymRegistrationOpen(false)
  handleQRCodeScanner()
}


//API Calls

const RegisterDevice = async event => {
  try {
    setButtonLock(true);
    await startLoader()
    let payload = {
      ...regDevice
    }
    await createDevice(user._id, token, payload);
    setScreenApiAction(!isScreenApiAction)
    setRegDevice({
      ...regDevice,
      gymId: "",
      branchId: "",
      name: "",
      secret: "",
      token: ""
    })
    setGymRegistrationOpen(false);
    await stopLoader()
  } catch (error) {
    setButtonLock(false);
  }
}

const getGymParams = () => {
  let params = {
    pageNo: pageNo.gym,
    pageSize: pageSize.gym
  };

  if (searchGymKey) {
    params.q = searchGymKey;
  }

  return params;
}

const GetAllGym = async (isReUpdateGym) => {
  try {
    let params = getGymParams();
    let responses = await getAllGym(user._id, token, params);
    if (responses.error) {

    } else {
      if (isReUpdateGym) setGyms(responses)
      else setGyms([...Gyms, ...responses])
    }
  } catch (Error) {
    console.error(Error)
  }
}

const GetGymCount = async () => {
  try {
    let params = getGymParams();
    let response = await getGymCount(user._id, token, params);
    if (response.error) {

    } else {
      pageNo.gym = 1;
      setPageNo({
        ...pageNo
      })
      setPageCount({
        ...pageCount,
        gym: Math.ceil(response.COUNT / pageSize.gym)
      })
      setTotal({
        ...total,
        gym: response.COUNT
      })
    }
  } catch (Error) {
    console.error(Error)
  }
}



const getBranchParams = () => {
  let params = {
    pageNo: pageNo.branch,
    pageSize: pageSize.branch
  };

  if (searchBranchKey) {
    params.q = searchBranchKey;
  }

  if (isGymRegistrationOpen) {
    if (selectedGym) params.gymIds = selectedGym._id;
  } else {

  }

  return params;
}

const GetAllBranch = async (isReUpdateBranch) => {
  try {
    let params = getBranchParams();
    let responses = await getAllBranches(user._id, token, params);
    if (responses.error) {

    } else {
      if (isReUpdateBranch) setBranches(responses)
      else setBranches([...Branches, ...responses])
    }
  } catch (Error) {
    console.error(Error)
  }
}

const GetBranchCount = async () => {
  try {
    let params = getBranchParams();
    let response = await getBranchCount(user._id, token, params);
    if (response.error) {

    } else {
      pageNo.branch = 1;
      setPageNo({
        ...pageNo
      })
      setPageCount({
        ...pageCount,
        branch: Math.ceil(response.COUNT / pageSize.branch)
      })
      setTotal({
        ...total,
        branch: response.COUNT
      })
    }
  } catch (Error) {
    console.error(Error)
  }
}


const getParams = () => {
  let params = {
    pageNo: pageNo.device,
    type: "smartScreen",
    pageSize: pageSize.device
  }

  params.activationStatus = isDeviceActive ? "active" : "inactive";


  if (sortByGym) {
    params.gymId = sortByGym._id;
  }

  if (sortByBranchIDs.length) {
    params.branchIds = sortByBranchIDs;
  }

  return params;

}

const GetAllDevice = async (isReUpdateDevices) => {
  try {
    let params = getParams()
    let response = await getAllDevice(user._id, token, params)
    if (response.error) {

    } else {
      if (isReUpdateDevices) setDeviceList(response);
      else setDeviceList([...deviceList, ...response])
    }
  } catch (error) {
    console.error(error)
  }
}

const GetDeviceCount = async () => {
  let params = getParams()
  delete params.pageNo;
  delete params.pageSize;

  let response = await getDeviceCount(user._id, token, params);
  if (response.error) {

  } else {
    pageNo.device = 1;
    setPageNo({
      ...pageNo
    })
    setPageCount({
      ...pageCount,
      device: Math.ceil(response.COUNT / pageSize.device)
    })
    setTotal({
      ...total,
      device: response.COUNT
    })
  }
}

//end of API Calls


const handleScroll = async event => {
  const { offsetHeight, scrollTop, scrollHeight } = event.target;

  if (offsetHeight + scrollTop === scrollHeight && pageNo.device < pageCount.device) {
    pageNo.device = pageNo.device + 1;
    setPageNo(pageNo)
    setStartLoader(true)
    await GetAllDevice();
    setStartLoader(false)
  }
}

const handleGymScroll = async event => {
  const { offsetHeight, scrollTop, scrollHeight } = event.target;

  if (offsetHeight + scrollTop === scrollHeight && pageNo.gym < pageCount.gym) {
    pageNo.gym = pageNo.gym + 1;
    setPageNo(pageNo)
    setStartGymLoader(true)
    await GetAllGym();
    setStartGymLoader(false)
  }
}

const handleBranchScroll = async event => {
  const { offsetHeight, scrollTop, scrollHeight } = event.target;

  if (offsetHeight + scrollTop === scrollHeight && pageNo.branch < pageCount.branch) {
    pageNo.branch = pageNo.branch + 1;
    setPageNo(pageNo)
    setStartBranchLoader(true)
    await GetAllBranch();
    setStartBranchLoader(false)
  }
}


const handleMenuVisibility = () => {
  if (!isMenuOpen) {
    setTimeout(() => {
      setIsMenuOpen(true)
    }, 50);
  } else {
    let container = document.getElementById("mobile-bottom-modal-container-menu");
    let menu = document.getElementById('menu-bar-mobile-id');
    container.style.background = "rgba(0, 0, 0, 0.10)";
    menu.style.marginRight = "-70%";
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 180);
  }
}

const toggleCurrentActionItem = (actionItem = "") => {
  setPrevCurrentActionItem(currentActionItem);
  setCurrentActionItem(actionItem);
}
const handleSearchKey = event => {
  setSearchKey(event.target.value);
}

const clearSearch = async event => {
  await setDeviceList([])
  searchKey = "";
  setSearchKey(searchKey);
  setPrevSearchKeys([]);
  setScreenApiAction(!isScreenApiAction);
  toggleCurrentActionItem();
  event.stopPropagation()
}

const startVideo = () => {
  let All_mediaDevices = navigator.mediaDevices
  if (!All_mediaDevices || !All_mediaDevices.getUserMedia) {
    console.log("getUserMedia() not supported.");
    return;
  }
  All_mediaDevices.getUserMedia({
    audio: false,
    video: true
  })
    .then(function (vidStream) {
      setWebcamWindow(document.getElementById('face-detector'));
      if ("srcObject" in WebCamWindow) {
        WebCamWindow.srcObject = vidStream;
      } else {
        WebCamWindow.src = window.URL.createObjectURL(vidStream);
      }
      WebCamWindow.onloadedmetadata = function (e) {
        WebCamWindow.play();
      };
    })
    .catch(function (e) {
      console.log(e.name + ": " + e.message);
    });
}

const handleQRCodeScanner = () => {
  if (isQRScannerOpen) {
    if (html5QrCode) {
      setQRScannerOpen(false)
      html5QrCode.stop();
    } else {
      setQRScannerOpen(false)
    }
  } else {
    setQRScannerOpen(true);
    setTimeout(() => {
      html5QrCode = new Html5Qrcode("reader");
      const qrCodeSuccessCallback = (qrCodeMessage) => {
        if (qrCodeMessage) {
          html5QrCode.stop().then((ignore) => {
            if (qrCodeMessage.includes("secret") && qrCodeMessage.includes("token")) {
              let data = qrCodeMessage.split("&");
              let token = data[0].split("=")[1];
              let secret = data[1].split("=")[1];
              if (secret && token) {
                setRegDevice({
                  ...regDevice,
                  secret,
                  token
                })
                setQRScannerOpen(false);
                setGymRegistrationOpen(true);
              }
            }
          }).catch((err) => {
          });
        }
      };
      const config = { fps: 10, qrbox: { width: 330, height: 330 } };
      html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);
    }, 100);
  }
}

const toggleInputFields = (currentInputField) => {
  setInputFieldActive(currentInputField);
}

const toggleSelectGym = () => {
  if (!isGymSelectionOpen) {
    setGymSelectionOpen(true)
  } else {
    setGymSelectionOpen(false)
  }
}

const toggleSelectBranch = () => {
  if (!isBranchSelectionOpen) {
    setBranchSelectionOpen(true)
  } else {
    setBranchSelectionOpen(false)
  }
}

const handleGymType = item => async event => {
  event.preventDefault();
  setGymType(item);
  event.stopPropagation();
}

const toggleGymFilter = () => {
  if (!isGymFilterOpen) {
    setGymFilterOpen(true);
  } else {
    setGymFilterOpen(false);
  }
}


useEffect(() => {
  checkDataField()
}, [regDevice]);

useEffect(async () => {
  try {
    setStartLoader(true)
    await GetDeviceCount()
    await GetAllDevice(true)
    setStartLoader(false)
  } catch (error) {
    setStartLoader(false)
  }

}, [props,isDeviceActive, sortByGym, sortByBranchIDs, isScreenApiAction]);

const handleSelectSortByGym=gym=>event=>{
  setSortByGym(gym)
  backToCurrentActionItem();
  event.stopPropagation()
}

useEffect(async () => {
  setStartGymLoader(true);
  await GetGymCount()
  await GetAllGym(true)
  setStartGymLoader(false)
}, [searchGymKey]);

useEffect(async () => {
  if (selectedGym || sortByGym) {
    setStartBranchLoader(true);
    await GetBranchCount()
    await GetAllBranch(true)
    setStartBranchLoader(false)
  }
}, [searchBranchKey, selectedGym, sortByGym]);


if (isQRScannerOpen) {
  return (
    <div className="w-full h-full">
      <div className="w-full m-t-20">
        <div className="d-flex w-full space-between p-l-12 p-r-12">
          <div onClick={handleQRCodeScanner} className='bell-menu-container header-menu-active d-flex'>
            <svg className='flex-item menu' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#055075" stroke-linecap="round" />
            </svg>
          </div>
          <img className="flex-item" style={{ height: "20px" }} src={InstafitLogoLong} />
          <div className='bell-menu-container header-menu-active d-flex' style={{ visibility: "hidden" }} />
        </div>
      </div>
      <div className="registration-qr-scanner m-t-50 d-flex">
        <svg style={{ position: "absolute", top: 0, left: 0 }} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 36V15C1.33333 10.3333 4.6 1 15 1C25.4 1 33.3333 1 36 1" stroke="#0088DA" stroke-width="2" stroke-linecap="round" />
        </svg>
        <svg style={{ position: "absolute", top: 0, right: 0 }} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L22 1C26.6667 1.33333 36 4.6 36 15C36 25.4 36 33.3333 36 36" stroke="#0088DA" stroke-width="2" stroke-linecap="round" />
        </svg>
        <svg style={{ position: 'absolute', bottom: 0, left: 0 }} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M36 36L15 36C10.3333 35.6667 1 32.4 1 22C1 11.6 1 3.66667 1 1" stroke="#0088DA" stroke-width="2" stroke-linecap="round" />
        </svg>
        <svg style={{ position: 'absolute', bottom: 0, right: 0 }} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M36 1L36 22C35.6667 26.6667 32.4 36 22 36C11.6 36 3.66667 36 1 36" stroke="#0088DA" stroke-width="2" stroke-linecap="round" />
        </svg>
        <div class="row error-qr-scanner d-flex flex-item" style={{ margin: "0 auto", width: "90%" }}>
          <div class="col flex-item" style={{ height: "auto", margin: "0 auto" }}>
            <div id="reader" class="flex-item"></div>
          </div>
        </div>
      </div>
      <div className="flex-item m-t-40" style={{ justifyContent: "center", textAlign: "center" }}>
        <p className="fs-16" style={{ lineHeight: "20px", color: "#2b2b2b" }}>Scan the QR code</p>
        <p className="fs-16" style={{ lineHeight: "20px", color: "#2b2b2b" }}>to Register the Gym</p>
      </div>
    </div>
  )
}

else if (isGymRegistrationOpen) {
  return (
    <div className="w-full h-full mob-dashboard">
      <div className="w-full m-t-20">
        <div className="d-flex w-full p-l-12 p-r-12">
          <div onClick={backToScanCode} className='bell-menu-container header-menu-active d-flex m-r-12'>
            <svg className='flex-item menu' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#055075" stroke-linecap="round" />
            </svg>
          </div>
          <p className="flex-item fs-20">Screen Registration</p>
        </div>
      </div>
      <div className="w-full m-t-24 p-l-12 p-r-12" style={{ height: "80%" }}>
        <div onClick={toggleInputFields.bind(this, 'device-id')} className="member-global-input-field m-b-20" style={{ border: "1px solid #055075" }}>
          <div className="flex-item d-flex">
            <svg className='flex-item m-r-8' width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 10C3.1145 10 2.1715 10 1.586 9.414C1 8.8285 1 7.8855 1 6C1 4.1145 1 3.1715 1.586 2.586C2.1715 2 3.1145 2 5 2H7C8.8855 2 9.8285 2 10.414 2.586C11 3.1715 11 4.1145 11 6C11 7.8855 11 8.8285 10.414 9.414C9.8285 10 8.8855 10 7 10H5Z" stroke="#055075" stroke-width="0.5" />
              <path d="M7.5 8.5H4.5" stroke="#055075" stroke-width="0.5" stroke-linecap="round" />
            </svg>
            <p className="placeholder-txt flex-item" style={{ fontSize: "10px", color: "#055075", transition: "180ms" }}>Device Name</p>
          </div>
          <input onChange={handleChange("name")} id="select-device-id" placeholder="Device Name (Optional)" className="active-input-field" type="text" value={regDevice.name} />
        </div>
        <div className="d-flex m-b-12">
          <p className="flex-item fs-20 m-r-12" style={{ lineHeight: "22px", color: "#4d4d4d", fontWeight: "600" }}>Gym</p>
          {
            !selectedGym && (
              <div onClick={toggleSelectGym} className="flex-item d-flex bell-menu-container header-menu-active cursor-pointer" style={{ background: isGymSelectionOpen == true ? "#055075" : "#ffffff" }}>
                <svg className="menu flex-item" style={{ transform: isGymSelectionOpen == true ? "rotate(45deg)" : "", transition: "180ms" }} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="6.61328" width="2" height="15" rx="1" fill={isGymSelectionOpen == true ? "#FFFFFF" : "#9A9A9A"} />
                  <rect x="15.1035" y="6.43555" width="2" height="15" rx="1" transform="rotate(89.5058 15.1035 6.43555)" fill={isGymSelectionOpen == true ? "#FFFFFF" : "#9A9A9A"} />
                </svg>
              </div>
            )
          }
        </div>

        {
          selectedGym && (
            <div className="d-flex suggestion-list-items w-full" style={{ background: "#ffffff" }}>

              <div className="d-flex flex-item" style={{ width: "90%" }}>
                {
                  selectedGym.logoFileID ? (
                    <img className="branch-gym-user-profile flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}${config.prefixStream}/media/file/${selectedGym.logoFileID}`} />
                  ) : (
                    <div className='branch-gym-user-profile d-flex flex-item m-r-8' style={{
                      backgroundColor: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c"
                    }}>
                      <p className='flex-item' style={{ color: '#ffffff', margin: '0 auto' }}>{`${selectedGym.name}`.trim()[0]}</p>
                    </div>
                  )
                }
                <div className="flex-item">
                  <p className="branch-gym-user-name m-b-1">{selectedGym.name}</p>
                  <p className="branch-gym-details-value">{selectedGym.email}</p>
                </div>
              </div>
              <svg onClick={clearSelectedGym} className="flex-item" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.9283 3.80872C4.77899 3.66451 4.57901 3.58471 4.37144 3.58652C4.16387 3.58832 3.96531 3.67158 3.81853 3.81836C3.67175 3.96514 3.58849 4.1637 3.58668 4.37127C3.58488 4.57885 3.66468 4.77882 3.80888 4.92813L8.38076 9.50001L3.80809 14.0719C3.73248 14.1449 3.67217 14.2323 3.63068 14.3289C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7396 3.58555 14.8438 3.62536 14.9411C3.66516 15.0384 3.72395 15.1268 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4339 4.36495 15.433C4.47007 15.432 4.57395 15.4102 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1913L9.50018 10.6194L14.0721 15.1913C14.2214 15.3355 14.4213 15.4153 14.6289 15.4135C14.8365 15.4117 15.035 15.3284 15.1818 15.1817C15.3286 15.0349 15.4119 14.8363 15.4137 14.6287C15.4155 14.4212 15.3357 14.2212 15.1915 14.0719L10.6196 9.50001L15.1915 4.92813C15.3357 4.77882 15.4155 4.57885 15.4137 4.37127C15.4119 4.1637 15.3286 3.96514 15.1818 3.81836C15.035 3.67158 14.8365 3.58832 14.6289 3.58652C14.4213 3.58471 14.2214 3.66451 14.0721 3.80872L9.50018 8.38059L4.9283 3.80793V3.80872Z" fill="black" />
              </svg>
            </div>
          )
        }

        {
          isGymSelectionOpen && [
            <div className="d-flex space-between">
              <div id="search-container" className='global-search-box' style={{ width: "84%" }}>
                <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1637_1318)">
                    <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1637_1318">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input onInput={_.debounce(searchGym, 1000)} id="search-gym-input" className='flex-item' type="text" placeholder="Search Gym" />
              </div>
              <div onClick={toggleGymFilter} className='global-round-btn d-flex'>
                <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
                </svg>
                {
                  isGymFilterOpen && (
                    <div onClick={e => e.stopPropagation()} className='gym-filter-popup'>
                      <p className='filter-heading-text m-b-12'>Gym Type</p>
                      <div onClick={handleGymType("all")} className='d-flex w-full m-b-8'>
                        <input type="radio" name="gym-type" checked={gymType == "all"} className='flex-item cursor-pointer m-r-6' />
                        <p className='filter-type-text'>All</p>
                      </div>
                      <div onClick={handleGymType("commercial")} className='d-flex w-full m-b-8'>
                        <input type="radio" name="gym-type" checked={gymType == "commercial"} className='flex-item cursor-pointer m-r-6' />
                        <p className='filter-type-text'>Commercial</p>
                      </div>
                      <div onClick={handleGymType("residential")} className='d-flex w-full m-b-8 gym-filter-type'>
                        <input type="radio" name="gym-type" checked={gymType == "residential"} className='flex-item cursor-pointer m-r-6' />
                        <p className='filter-type-text'>Residential</p>
                      </div>
                      <div onClick={handleGymType("corporate")} className='d-flex w-full'>
                        <input type="radio" name="gym-type" checked={gymType == "corporate"} className='flex-item cursor-pointer m-r-6' />
                        <p className='filter-type-text'>Corporate</p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>,
            <div className="w-full m-t-20 screen-registration-gym-container" onScroll={handleGymScroll}>
              {
                Gyms.map((gym, index) => {
                  return (
                    <div className="d-flex suggestion-list-items m-b-0" onClick={handleSelectGym(gym)}>
                      {
                        gym.logoFileID ? (
                          <img className="branch-gym-user-profile flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}${config.prefixStream}/media/file/${gym.logoFileID}`} />
                        ) : (
                          <div className='branch-gym-user-profile d-flex flex-item m-r-8' style={{
                            backgroundColor: gym.logoPlaceholderColor ? gym.logoPlaceholderColor : "#5d883c"
                          }}>
                            <p className='flex-item' style={{ color: '#ffffff', margin: '0 auto' }}>{`${gym.name}`.trim()[0]}</p>
                          </div>
                        )
                      }
                      <div className="flex-item">
                        <p className="branch-gym-user-name m-b-1">{gym.name}</p>
                        <p className="branch-gym-details-value">{gym.email}</p>
                      </div>
                    </div>
                  )
                })
              }

            </div>
          ]
        }



        {
          selectedGym && [
            <div className="d-flex m-b-12 m-t-20">
              <p className="flex-item fs-20 m-r-12" style={{ lineHeight: "22px", color: "#4d4d4d", fontWeight: "600" }}>Branch</p>
              {!selectedBranch && <div onClick={toggleSelectBranch} className="flex-item d-flex bell-menu-container header-menu-active cursor-pointer" style={{ background: isBranchSelectionOpen == true ? "#055075" : "#ffffff" }}>
                <svg className="menu flex-item" style={{ transform: isBranchSelectionOpen == true ? "rotate(45deg)" : "", transition: "180ms" }} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="6.61328" width="2" height="15" rx="1" fill={isBranchSelectionOpen == true ? "#FFFFFF" : "#9A9A9A"} />
                  <rect x="15.1035" y="6.43555" width="2" height="15" rx="1" transform="rotate(89.5058 15.1035 6.43555)" fill={isBranchSelectionOpen == true ? "#FFFFFF" : "#9A9A9A"} />
                </svg>
              </div>}
            </div>,
            (selectedBranch) && (
              <div className="d-flex suggestion-list-items w-full" style={{ background: "#ffffff" }}>

                <div className="d-flex flex-item" style={{ width: "90%" }}>

                  <div className="flex-item">
                    <p className="branch-gym-user-name m-b-1">{selectedBranch.name}</p>
                    <p className="branch-gym-details-value">{selectedBranch.email}</p>
                  </div>
                </div>
                <svg onClick={clearSelectedBranch} className="flex-item" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.9283 3.80872C4.77899 3.66451 4.57901 3.58471 4.37144 3.58652C4.16387 3.58832 3.96531 3.67158 3.81853 3.81836C3.67175 3.96514 3.58849 4.1637 3.58668 4.37127C3.58488 4.57885 3.66468 4.77882 3.80888 4.92813L8.38076 9.50001L3.80809 14.0719C3.73248 14.1449 3.67217 14.2323 3.63068 14.3289C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7396 3.58555 14.8438 3.62536 14.9411C3.66516 15.0384 3.72395 15.1268 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4339 4.36495 15.433C4.47007 15.432 4.57395 15.4102 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1913L9.50018 10.6194L14.0721 15.1913C14.2214 15.3355 14.4213 15.4153 14.6289 15.4135C14.8365 15.4117 15.035 15.3284 15.1818 15.1817C15.3286 15.0349 15.4119 14.8363 15.4137 14.6287C15.4155 14.4212 15.3357 14.2212 15.1915 14.0719L10.6196 9.50001L15.1915 4.92813C15.3357 4.77882 15.4155 4.57885 15.4137 4.37127C15.4119 4.1637 15.3286 3.96514 15.1818 3.81836C15.035 3.67158 14.8365 3.58832 14.6289 3.58652C14.4213 3.58471 14.2214 3.66451 14.0721 3.80872L9.50018 8.38059L4.9283 3.80793V3.80872Z" fill="black" />
                </svg>
              </div>
            )

          ]
        }

        {
          selectedGym && !selectedBranch && isBranchSelectionOpen && [
            <div id="search-container" className='global-search-box' style={{ width: "100%" }}>
              <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1637_1318)">
                  <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                </g>
                <defs>
                  <clipPath id="clip0_1637_1318">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input onInput={_.debounce(searchBranch, 1000)} id="search-branch-input" className='flex-item' type="text" placeholder="Search branch" />
            </div>,
            <div className="w-full m-t-20 screen-registration-branch-container" onScroll={handleBranchScroll}>
              {
                Branches.map((branch, index) => {
                  return (
                    <div className="d-flex suggestion-list-items m-b-0" onClick={handleSelectBranch(branch)}>
                      <div className="flex-item">
                        <p className="branch-gym-user-name m-b-1">{branch.name}</p>
                        <p className="branch-gym-details-value">{branch.email}</p>
                      </div>
                    </div>
                  )
                })
              }

            </div>
          ]
        }


      </div>
      <div className="w-full" style={{ position: "absolute", bottom: 0, right: 0, left: 0, padding: "0 12px 12px 12px" }}>
        <div onClick={isButtonLock ? null : RegisterDevice} className={`member-button-global btn-center d-flex ${isButtonLock && `member-button-global-inactive`}`} style={{
          pointerEvents: isButtonLock ? 'none' : 'auto'
        }}>
          <p className="flex-item">Register</p>
        </div>
      </div>
    </div>
  )
}

else if (!isQRScannerOpen && !isGymRegistrationOpen) {
  return (
    <Dashboard flag={0}>
      <div className='mob-dashboard'>
        {
          selectedDeviceIDs.length == 0 ? (
            <div className='profile-header-mob d-flex space-between' style={{ boxShadow: "none" }}>

              <div className='flex-item d-flex'>
                <div className='flex-item d-flex'>
                  <svg className='flex-item m-r-8' width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.33268 16.6668C5.19018 16.6668 3.61852 16.6668 2.64268 15.6902C1.66602 14.7143 1.66602 13.1427 1.66602 10.0002C1.66602 6.85766 1.66602 5.286 2.64268 4.31016C3.61852 3.3335 5.19018 3.3335 8.33268 3.3335H11.666C14.8085 3.3335 16.3802 3.3335 17.356 4.31016C18.3327 5.286 18.3327 6.85766 18.3327 10.0002C18.3327 13.1427 18.3327 14.7143 17.356 15.6902C16.3802 16.6668 14.8085 16.6668 11.666 16.6668H8.33268Z" fill="#055075" stroke="#055075" stroke-width="1.5" />
                    <path d="M12.5 14.167H7.5H12.5Z" fill="#055075" />
                    <path d="M12.5 14.167H7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                  <p className='flex-item mob-dashboard-heading m-r-8'>Screens</p>
                  <div className="flex-item ">
                  </div>
                </div>
              </div>

              <div className='d-flex flex-item' style={{ flexDirection: 'row-reverse' }}>
                <div onClick={handleMenuVisibility} className='bell-menu-container header-menu-active d-flex'>
                  <svg className='flex-item menu' width="16" height="16" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="15" height="1" rx="0.5" fill="#2b2b2b" />
                    <rect y="4" width="7" height="1" rx="0.5" fill="#2b2b2b" />
                    <rect y="8" width="12" height="1" rx="0.5" fill="#2b2b2b" />
                  </svg>
                </div>


                <div className='bell-menu-container header-menu-active d-flex m-r-14'>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.49099 5.82435C7.78036 1.94382 6.89054 0.469739 4.09577 0.531423C3.10015 0.552775 3.33874 -0.229342 2.57946 0.0672142C1.82169 0.36377 2.47217 0.797927 1.69639 1.46933C-0.480905 3.35384 -0.280582 5.0968 0.729287 9.25095C1.15469 11.001 -0.296338 11.0864 0.277622 12.7819C0.697025 14.0188 3.78815 14.5368 7.04885 13.262C10.3103 11.9864 12.3653 9.45498 11.9459 8.21815C11.3719 6.52185 10.212 7.45817 9.49099 5.82435ZM6.69547 12.2165C3.7829 13.3553 1.38953 12.6863 1.28824 12.3881C1.11418 11.8741 2.22833 10.1612 5.55805 8.85871C8.88777 7.55623 10.7462 8.03942 10.9405 8.61276C11.0553 8.95202 9.60878 11.0769 6.69547 12.2165ZM5.75912 9.4534C4.23682 10.0489 3.17893 10.7298 2.49318 11.3427C2.97561 11.8045 3.87894 11.9168 4.77852 11.5649C5.92419 11.1181 6.62869 10.0892 6.35034 9.26835L6.33834 9.23988C6.15002 9.30473 5.9572 9.37511 5.75912 9.4534Z" fill="#2b2b2b" />
                  </svg>
                </div>


                <div onClick={handleQRCodeScanner.bind(this)} className='bell-menu-container header-menu-active d-flex m-r-14'>
                  <svg className='flex-item menu m-r-14' width="22" height="22" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.90277 8.50031C3.77169 8.50259 3.64674 8.55626 3.55485 8.64977C3.46295 8.74327 3.41146 8.86913 3.41146 9.00023C3.41146 9.13134 3.46295 9.2572 3.55485 9.3507C3.64674 9.44421 3.77169 9.49788 3.90277 9.50016L7.98631 9.50016L7.98596 13.5841C7.98481 13.6504 7.99689 13.7164 8.02149 13.778C8.0461 13.8397 8.08274 13.8959 8.12927 13.9432C8.17581 13.9906 8.23131 14.0282 8.29254 14.0538C8.35377 14.0795 8.41949 14.0927 8.48589 14.0927C8.55228 14.0927 8.618 14.0795 8.67923 14.0538C8.74046 14.0282 8.79596 13.9906 8.8425 13.9432C8.88903 13.8959 8.92567 13.8397 8.95028 13.778C8.97489 13.7164 8.98696 13.6504 8.98581 13.5841L8.98616 9.50016H13.0697C13.2008 9.49788 13.3257 9.44421 13.4176 9.3507C13.5095 9.2572 13.561 9.13134 13.561 9.00023C13.561 8.86913 13.5095 8.74327 13.4176 8.64977C13.3257 8.55626 13.2008 8.50259 13.0697 8.50031L8.98616 8.50031L8.98616 4.41677C8.98388 4.28569 8.93021 4.16074 8.83671 4.06885C8.7432 3.97695 8.61734 3.92546 8.48624 3.92546C8.35514 3.92546 8.22928 3.97695 8.13577 4.06885C8.04226 4.16074 7.98859 4.28569 7.98631 4.41677L7.98631 8.50031L3.90242 8.49996L3.90277 8.50031Z" fill="black" />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <div className='profile-header-mob d-flex' style={{ boxShadow: "none" }}>

              <div onClick={clearDeviceIDs.bind(this)} className='bell-menu-container header-menu-active d-flex flex-item m-r-12'>
                <svg className='menu flex-item' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#055075" stroke-linecap="round" />
                </svg>
              </div>

              <div onClick={handleBulkStatusChangeAction} className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20' style={{ background: "#FFFFFF" }}>
                <div className='d-flex'>
                  <svg className='svg-fill svg-stroke flex-item m-r-8' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="6.5" stroke="#6D6D6D" />
                    <rect x="4" y="9.65674" width="8" height="1" rx="0.5" transform="rotate(-45 4 9.65674)" fill="#6D6D6D" />
                  </svg>
                  <p className='flex-item' style={{ whiteSpace: "nowrap" }}>Mark {isDeviceActive ? "Inactive" : "Active"}</p>
                </div>
              </div>


            </div>
          )
        }
        <div className='w-full d-flex space-between m-t-12' style={{ height: "30px" }}>
          {
            !isAllBranchChecked ? [
              <div className='d-flex flex-item' style={{ width: "100%" }}>
                <div className='w-full d-flex space-between'>
                  <div className='d-flex flex-item filter-horizontal-scroll' style={{ width: "82%" }}>
                    <div className='d-flex filter-chips'>
                      <p className='flex-item m-r-12'>Test</p>
                      <svg className='flex-item' width="14" height="14" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33456 1.80412C2.26384 1.73581 2.16911 1.69801 2.07079 1.69887C1.97246 1.69972 1.87841 1.73916 1.80888 1.80869C1.73935 1.87822 1.69991 1.97227 1.69906 2.07059C1.6982 2.16892 1.736 2.26364 1.80431 2.33437L3.96994 4.49999L1.80394 6.66562C1.76812 6.70021 1.73955 6.74159 1.7199 6.78734C1.70024 6.8331 1.6899 6.8823 1.68947 6.9321C1.68903 6.98189 1.69852 7.03127 1.71738 7.07735C1.73623 7.12344 1.76408 7.16531 1.79929 7.20052C1.8345 7.23573 1.87637 7.26357 1.92245 7.28243C1.96854 7.30128 2.01792 7.31077 2.06771 7.31034C2.1175 7.30991 2.16671 7.29956 2.21246 7.27991C2.25821 7.26025 2.29959 7.23169 2.33419 7.19587L4.50019 5.03024L6.66581 7.19587C6.73654 7.26418 6.83126 7.30198 6.92959 7.30112C7.02791 7.30027 7.12196 7.26083 7.19149 7.1913C7.26102 7.12177 7.30046 7.02772 7.30131 6.9294C7.30217 6.83107 7.26437 6.73635 7.19606 6.66562L5.03044 4.49999L7.19606 2.33437C7.26437 2.26364 7.30217 2.16892 7.30131 2.07059C7.30046 1.97227 7.26102 1.87822 7.19149 1.80869C7.12196 1.73916 7.02791 1.69972 6.92959 1.69887C6.83126 1.69801 6.73654 1.73581 6.66581 1.80412L4.50019 3.96974L2.33456 1.80374V1.80412Z" fill="#9A9A9A" />
                      </svg>
                    </div>
                    <div className='d-flex filter-chips'>
                      <p className='flex-item m-r-12'>New Test</p>
                      <svg className='flex-item' width="14" height="14" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33456 1.80412C2.26384 1.73581 2.16911 1.69801 2.07079 1.69887C1.97246 1.69972 1.87841 1.73916 1.80888 1.80869C1.73935 1.87822 1.69991 1.97227 1.69906 2.07059C1.6982 2.16892 1.736 2.26364 1.80431 2.33437L3.96994 4.49999L1.80394 6.66562C1.76812 6.70021 1.73955 6.74159 1.7199 6.78734C1.70024 6.8331 1.6899 6.8823 1.68947 6.9321C1.68903 6.98189 1.69852 7.03127 1.71738 7.07735C1.73623 7.12344 1.76408 7.16531 1.79929 7.20052C1.8345 7.23573 1.87637 7.26357 1.92245 7.28243C1.96854 7.30128 2.01792 7.31077 2.06771 7.31034C2.1175 7.30991 2.16671 7.29956 2.21246 7.27991C2.25821 7.26025 2.29959 7.23169 2.33419 7.19587L4.50019 5.03024L6.66581 7.19587C6.73654 7.26418 6.83126 7.30198 6.92959 7.30112C7.02791 7.30027 7.12196 7.26083 7.19149 7.1913C7.26102 7.12177 7.30046 7.02772 7.30131 6.9294C7.30217 6.83107 7.26437 6.73635 7.19606 6.66562L5.03044 4.49999L7.19606 2.33437C7.26437 2.26364 7.30217 2.16892 7.30131 2.07059C7.30046 1.97227 7.26102 1.87822 7.19149 1.80869C7.12196 1.73916 7.02791 1.69972 6.92959 1.69887C6.83126 1.69801 6.73654 1.73581 6.66581 1.80412L4.50019 3.96974L2.33456 1.80374V1.80412Z" fill="#9A9A9A" />
                      </svg>
                    </div>
                    <div className='d-flex filter-chips'>
                      <p className='flex-item m-r-12'>New 1 Test</p>
                      <svg className='flex-item' width="14" height="14" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33456 1.80412C2.26384 1.73581 2.16911 1.69801 2.07079 1.69887C1.97246 1.69972 1.87841 1.73916 1.80888 1.80869C1.73935 1.87822 1.69991 1.97227 1.69906 2.07059C1.6982 2.16892 1.736 2.26364 1.80431 2.33437L3.96994 4.49999L1.80394 6.66562C1.76812 6.70021 1.73955 6.74159 1.7199 6.78734C1.70024 6.8331 1.6899 6.8823 1.68947 6.9321C1.68903 6.98189 1.69852 7.03127 1.71738 7.07735C1.73623 7.12344 1.76408 7.16531 1.79929 7.20052C1.8345 7.23573 1.87637 7.26357 1.92245 7.28243C1.96854 7.30128 2.01792 7.31077 2.06771 7.31034C2.1175 7.30991 2.16671 7.29956 2.21246 7.27991C2.25821 7.26025 2.29959 7.23169 2.33419 7.19587L4.50019 5.03024L6.66581 7.19587C6.73654 7.26418 6.83126 7.30198 6.92959 7.30112C7.02791 7.30027 7.12196 7.26083 7.19149 7.1913C7.26102 7.12177 7.30046 7.02772 7.30131 6.9294C7.30217 6.83107 7.26437 6.73635 7.19606 6.66562L5.03044 4.49999L7.19606 2.33437C7.26437 2.26364 7.30217 2.16892 7.30131 2.07059C7.30046 1.97227 7.26102 1.87822 7.19149 1.80869C7.12196 1.73916 7.02791 1.69972 6.92959 1.69887C6.83126 1.69801 6.73654 1.73581 6.66581 1.80412L4.50019 3.96974L2.33456 1.80374V1.80412Z" fill="#9A9A9A" />
                      </svg>
                    </div>
                  </div>
                  <div onClick={toggleCurrentActionItem.bind(this, "Filter")} className='bell-menu-container header-menu-active d-flex flex-item m-r-12'>
                    <svg className='flex-item bell' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#000000" />
                    </svg>
                  </div>
                </div>
              </div>
            ] : (
              <div className='d-flex flex-item m-r-12'>
                <svg className='flex-item m-r-12' width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 16.5C7.9625 16.5 6.9875 16.303 6.075 15.909C5.1625 15.5155 4.36875 14.9812 3.69375 14.3062C3.01875 13.6312 2.4845 12.8375 2.091 11.925C1.697 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.697 6.9875 2.091 6.075C2.4845 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48425 6.075 2.09025C6.9875 1.69675 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69675 11.925 2.09025C12.8375 2.48425 13.6312 3.01875 14.3062 3.69375C14.9812 4.36875 15.5155 5.1625 15.909 6.075C16.303 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.303 11.0125 15.909 11.925C15.5155 12.8375 14.9812 13.6312 14.3062 14.3062C13.6312 14.9812 12.8375 15.5155 11.925 15.909C11.0125 16.303 10.0375 16.5 9 16.5ZM9 15C10.675 15 12.0937 14.4187 13.2562 13.2562C14.4187 12.0937 15 10.675 15 9C15 8.325 14.8905 7.675 14.6715 7.05C14.453 6.425 14.1375 5.85 13.725 5.325L5.325 13.725C5.85 14.1375 6.425 14.453 7.05 14.6715C7.675 14.8905 8.325 15 9 15ZM4.275 12.675L12.675 4.275C12.15 3.8625 11.575 3.547 10.95 3.3285C10.325 3.1095 9.675 3 9 3C7.325 3 5.90625 3.58125 4.74375 4.74375C3.58125 5.90625 3 7.325 3 9C3 9.675 3.1095 10.325 3.3285 10.95C3.547 11.575 3.8625 12.15 4.275 12.675V12.675Z" fill="#055075" />
                </svg>
                <svg className='flex-item' width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.25065 6.56249V9.32249C8.25065 9.58499 8.39315 9.83249 8.61815 9.96749L10.9582 11.355C11.2282 11.5125 11.5732 11.4225 11.7307 11.16C11.8882 10.89 11.8057 10.545 11.5357 10.3875L9.38315 9.10499V6.55499C9.37565 6.25499 9.12065 5.99999 8.81315 5.99999C8.50565 5.99999 8.25065 6.25499 8.25065 6.56249ZM15.7507 7.12499V3.15749C15.7507 2.81999 15.3457 2.65499 15.1132 2.89499L13.7782 4.22999C13.0756 3.5274 12.2268 2.98838 11.2921 2.65133C10.3574 2.31428 9.35992 2.18749 8.37065 2.27999C5.22815 2.56499 2.64065 5.09249 2.29565 8.23499C2.11328 9.99702 2.63089 11.7601 3.73681 13.1439C4.84272 14.5278 6.44831 15.4214 8.20718 15.632C9.96606 15.8426 11.7372 15.3534 13.1386 14.2698C14.54 13.1863 15.4593 11.5953 15.6982 9.83999C15.7507 9.38999 15.3982 8.99999 14.9482 8.99999C14.5732 8.99999 14.2582 9.27749 14.2132 9.64499C13.8907 12.2625 11.6332 14.2875 8.92565 14.25C6.14315 14.2125 3.79565 11.865 3.75065 9.07499C3.70565 6.14999 6.08315 3.74999 9.00065 3.74999C10.4482 3.74999 11.7607 4.34249 12.7132 5.28749L11.1457 6.85499C10.9057 7.09499 11.0707 7.49999 11.4082 7.49999H15.3757C15.5857 7.49999 15.7507 7.33499 15.7507 7.12499Z" fill="#055075" />
                </svg>
              </div>
            )
          }
        </div>

        <div className='mobile-dashboard-appointments-scroll-container m-t-12' onScroll={handleScroll}>
          {
            deviceList.map((device, deviceIndex) => {
              return (
                <div {...LongPress(device._id)} onClick={isLongPressActivated ? handleSelectDeviceID(device._id) : null} className="d-flex space-between global-table-item-mob-container m-b-8" style={{ background: Boolean(selectedDeviceIDs.find(data => data == device._id)) ? "#ffffff" : "#f5f5f5" }}>
                  <div className="flex-item">
                    <div className="d-flex">
                      <p className="flex-item m-r-8" style={{ fontWeight: "600" }}>{device.name ? device.name : "Device001"}</p>
                      <div className='flex-item' style={{ width: "6px", height: "6px", background: (device.deletedAt == null) ? "rgb(45, 227, 63)" : "#dddddd", borderRadius: "100%" }} />
                    </div>
                    <div className="d-flex">
                      <div className="flex-item d-flex">
                        {
                          (device.gym && device.gym.length) && [
                            device.gym[0].logoFileID ? (
                              <img className="flex-item" src={`${config.API_URL}${config.prefixStream}/media/file/${device.gym[0].logoFileID}`} style={{ width: "20px", borderRadius: '100%', height: "20px" }} loading="lazy" />
                            ) : (
                              <div className="d-flex flex-item" style={{
                                width: "20px", height: "20px", borderRadius: '100%', backgroundColor: device.gym[0].logoPlaceholderColor ? device.gym[0].logoPlaceholderColor : "#5d883c"
                              }}>
                                <p style={{
                                  color: "#ffffff",
                                  margin: "0 auto",
                                  fontSize: 14,
                                }}>{`${device.gym[0].name}`.trim()[0]}</p>
                              </div>
                            ),
                            <p className="m-l-8 flex-item">{device.gym[0].name}{device.gym[0].address ? device.gym[0].address.placeName ? ", " + device.gym[0].address.placeName : device.gym[0].address.placeAddress ? ", " + device.gym[0].address.placeAddress : ", " + device.gym[0].address : ""}</p>
                          ]
                        }
                      </div>
                    </div>
                  </div>
                  {
                    Boolean(selectedDeviceIDs.find(data => data == device._id)) && (
                      <div className='more-action d-flex flex-item'>
                        <svg onClick={handleStatusChangeAction(device)} className='flex-item m-r-12' width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 16.5C7.9625 16.5 6.9875 16.303 6.075 15.909C5.1625 15.5155 4.36875 14.9812 3.69375 14.3062C3.01875 13.6312 2.4845 12.8375 2.091 11.925C1.697 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.697 6.9875 2.091 6.075C2.4845 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48425 6.075 2.09025C6.9875 1.69675 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69675 11.925 2.09025C12.8375 2.48425 13.6312 3.01875 14.3062 3.69375C14.9812 4.36875 15.5155 5.1625 15.909 6.075C16.303 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.303 11.0125 15.909 11.925C15.5155 12.8375 14.9812 13.6312 14.3062 14.3062C13.6312 14.9812 12.8375 15.5155 11.925 15.909C11.0125 16.303 10.0375 16.5 9 16.5ZM9 15C10.675 15 12.0937 14.4187 13.2562 13.2562C14.4187 12.0937 15 10.675 15 9C15 8.325 14.8905 7.675 14.6715 7.05C14.453 6.425 14.1375 5.85 13.725 5.325L5.325 13.725C5.85 14.1375 6.425 14.453 7.05 14.6715C7.675 14.8905 8.325 15 9 15ZM4.275 12.675L12.675 4.275C12.15 3.8625 11.575 3.547 10.95 3.3285C10.325 3.1095 9.675 3 9 3C7.325 3 5.90625 3.58125 4.74375 4.74375C3.58125 5.90625 3 7.325 3 9C3 9.675 3.1095 10.325 3.3285 10.95C3.547 11.575 3.8625 12.15 4.275 12.675V12.675Z" fill="#9A9A9A" />
                        </svg>

                      </div>
                    )
                  }
                </div>
              )
            })
          }
          {isStartLoader && <div className="loader-5 center"><span></span></div>}
        </div>

      </div>

      {
        isMenuOpen && (
          // <div id="mobile-bottom-modal-container-menu" onClick={handleMenuVisibility} className="mobile-bottom-modal-container">
            // <div id="menu-bar-mobile-id" onClick={e => e.stopPropagation()} className="menu-bar-mobile right-slider">
              <MenuMob toggleMenuVisibility={handleMenuVisibility} />
            // </div>
          // </div>
        )
      }



      {
        currentActionItem == "Filter" && (
          <div className="mobile-bottom-modal-container" onClick={backToCurrentActionItem}>
            <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={toggleCurrentActionItem.bind(this)} className="w-full">
                <div className="draggable-pointer" />
              </div>

              <p className="filter-heading-text m-b-12">Status</p>
              <div className='d-flex m-t-12 m-b-24'>
                <div onClick={toggleActiveInactiveButton.bind(this)} className='flex-item d-flex active-inactive-button m-r-12' style={{ background: !isDeviceActive ? "rgba(255, 0, 85, 0.347)" : "" }}>
                  <div className='active-inactive-ball flex-item' style={{ background: !isDeviceActive ? "rgb(201, 0, 57)" : "", margin: !isDeviceActive ? "0 0 0 0" : "" }} />
                </div>
                <p className='flex-item active-inactive-txt'>{isDeviceActive ? "Active" : "Inactive"}</p>
              </div>




              {
                sortByGym && [
                  <div className='d-flex'>
                    <div className='d-flex m-t-20'>
                      <p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
                      <svg onClick={toggleCurrentActionItem.bind(this, "gym")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                      </svg>
                      <span className="m-l-4 m-r-4 flex-item"></span>
                      <svg role={`button`} onClick={handleClearGym} className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#055075" />
                      </svg>
                    </div>
                  </div>,
                  <div className="d-flex w-full m-t-8">
                    {
                      sortByGym.logoFileID ? (
                        <img className="flex-item m-r-8 branch-gym-logo" style={{ width: 40, height: 40 }} src={`${config.API_URL}${config.prefixStream}/media/file/${sortByGym.logoFileID}`} />
                      ) : (
                        <div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ background: sortByGym.logoPlaceholderColor ? sortByGym.logoPlaceholderColor : "#5d883c" }}>
                          <p className="flex-item" style={{ margin: '0 auto', color: '#ffffff' }}>{`${sortByGym.name}`.trim()[0]}</p>
                        </div>
                      )
                    }
                    <div className="flex-item">
                      <p className="branch-gym-name">{sortByGym.name}</p>
                      <p className="branch-gym-email">{sortByGym.email}</p>
                    </div>
                  </div>
                ]
              }

              {
                (sortByGym && sortByBranchIDs.length == 0) && [
                  <div onClick={toggleCurrentActionItem.bind(this, "branch")} className='member-button-global btn-center d-flex m-t-16' style={{ width: "46%" }}>
                    <p className='flex-item'>Select Branch</p>
                  </div>
                ]
              }

              {
                sortByBranchIDs.length > 0 && [
                  <div className='d-flex'>
                    <div className='d-flex m-t-20'>
                      <p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
                      <svg onClick={toggleCurrentActionItem.bind(this, "branch")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                      </svg>
                      <span className="m-l-4 m-r-4 flex-item"></span>
                      <svg role={`button`} onClick={handleClearBranch} className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#055075" />
                      </svg>
                    </div>
                  </div>,
                  <div className="w-full m-t-8">
                    <p className="branch-gym-name">{`${sortByBranchIDs.length} Branch(s) Selected`}</p>
                  </div>
                ]
              }



              <div className="w-full m-t-20 d-flex-wrap">
                {
                  (!sortByGym) && <div onClick={toggleCurrentActionItem.bind(this, "gym")} className='member-button-global btn-center d-flex' style={{ width: "46%", margin: "2%" }}>
                    <p className='flex-item'>Select Gym</p>
                  </div>
                }


              </div>

            </div>
          </div>
        )
      }

      {
        currentActionItem == "branch" && (
          <div className="mobile-bottom-modal-container" onClick={backToCurrentActionItem}>
            <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={backToCurrentActionItem.bind(this)} className="w-full">
                <div className="draggable-pointer" />
              </div>

              {
                CurrentFilterFilterSearch == "branch" ? (
                  <div id="search-branch-container" className='global-search-box d-flex w-full m-r-12' style={{ background: "#f5f5f5" }}>
                    <svg id="search-branch-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1637_1318)">
                        <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1637_1318">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <input onChange={_.debounce(searchBranch, 1000)} style={{ background: "#f5f5f5" }} className='flex-item' type="text" placeholder="Search Gym" />
                    <svg onClick={toggleFilterSearch.bind(this, "")} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                    </svg>
                  </div>
                ) : (
                  <div className="d-flex w-full space-between">
                    <p className='filter-heading-text fs-16 m-l-4 flex-item'>Select Branch(s)</p>
                    <div onClick={toggleFilterSearch.bind(this, "branch")} className='bell-menu-container header-menu-active flex-item d-flex'>
                      <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                      </svg>
                    </div>
                  </div>
                )
              }

              <div className='search-gym-modal-scroll' onScroll={handleBranchScroll}>
                <div className="d-flex-wrap w-full m-t-12">
                  {
                    Branches.map(data => {
                      return (
                        <div onClick={handleTempIDs(data._id)} className={`global-button-container ${tempIDs.findIndex(id => id == data._id) != -1 && `global-button-container-active`} d-flex flex-item p-l-12 p-r-12 m-r-16 m-b-16`}>
                          <p className='flex-item'>{data.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
                {startBranchLoader && <div className="loader-5 center"><span></span></div>}
              </div>
              <div className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                <div className="flex-item d-flex">
                  <div onClick={toggleCurrentActionItem.bind(this)} className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                    <p className='flex-item'>Cancel</p>
                  </div>
                  {
                    tempIDs.length > 0 &&
                    <div onClick={confirmTempSelectionIDs} className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 14px" }}>
                      <p className='flex-item'>Add</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        currentActionItem == "gym" && (
          <div className="mobile-bottom-modal-container" onClick={backToCurrentActionItem}>
            <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={backToCurrentActionItem.bind(this)} className="w-full">
                <div className="draggable-pointer" />
              </div>

              {
                CurrentFilterFilterSearch == "gym" ? (
                  <div id="search-gym-container" className='global-search-box d-flex w-full m-r-12' style={{ background: "#f5f5f5" }}>
                    <svg id="search-gym-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1637_1318)">
                        <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1637_1318">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <input onChange={_.debounce(searchGym, 1000)} style={{ background: "#f5f5f5" }} className='flex-item' type="text" placeholder="Search Gym" />
                    <svg onClick={toggleFilterSearch.bind(this, "")} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                    </svg>
                  </div>
                ) : (
                  <div className="d-flex w-full space-between">
                    <p className='filter-heading-text fs-16 m-l-4 flex-item'>Select Gym</p>
                    <div onClick={toggleFilterSearch.bind(this, "gym")} className='bell-menu-container header-menu-active flex-item d-flex'>
                      <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                      </svg>
                    </div>
                  </div>
                )
              }

              <div className='search-gym-modal-scroll' onScroll={handleGymScroll}>
                {
                  Gyms.map(data => {
                    return (
                      <div onClick={handleSelectSortByGym(data)} className="d-flex suggestion-list-items">
                        {
                          data.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}${config.prefixStream}/media/file/${data.logoFileID}`} />
                          ) : (
                            <div className='d-flex flex-item branch-gym-user-profile m-r-8' style={{
                              backgroundColor: data.logoPlaceholderColor ? data.logoPlaceholderColor : "#5d883c"
                            }}>
                              <p className='flex-item fs-14' style={{
                                margin: '0 auto',
                                color: "#ffffff"
                              }}>{`${data.name}`.trim()[0]}</p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.email}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {startGymLoader && <div className="loader-5 center"><span></span></div>}
              </div>
              <div onClick={toggleCurrentActionItem.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                <div className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                  <p className='flex-item'>Cancel</p>
                </div>
              </div>
            </div>
          </div>
        )
      }



      {
        isStatusChangeModalOpen && <ManageConfirmationModal isMobile={true} handleAction={handleAction} title={isBulkStatusChangeAction ? `${selectedDeviceIDs.length} device(s) selected` : selectedDevice.name} message="By performing this action all the user's under this member will get effected" buttonText={`Mark ${isBulkStatusChangeAction ? (isDeviceActive ? "inactive" : "active") : (selectedDevice.deletedAt == null ? "inactive" : "active")}`} handleClose={handleStatusChangeAction("")} />
      }


      <FloatingMenu />
    </Dashboard>
  )
}
}

export default ManageScreens;