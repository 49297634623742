import React, {useState, useEffect} from 'react';
import WorkoutHistory from '../../assets/workout-history-member.png';
import FitnessTest from '../../assets/fitness-test.png';
import NoDataFound from "../../assets/no_data.png";
import WorkoutHistoryMob from './workoutHistory';
import { useLocation, useHistory } from 'react-router-dom';
import { getMember } from '../helper/api';
import { isAuthenticated } from '../../auth';
import Link from 'react-router-dom/Link';
import { formatDateTime } from '../../lib/utils';
import queryString from 'query-string';

export default function MemberReport(props) {
  const location = useLocation();
  const { replace } = useHistory();
  const pathMob = location.pathname;
  const { user, token } = isAuthenticated();
  const [filterByWorkoutDate,setFilterByWorkoutDate]=useState({startDate:'',endDate:''});
  const [myReportType, SetMyReportType] = useState('workout-history');
  const [reportItemModal, SetReportItemModal] = useState('');
  const [memberDetails, setMemberDetails] = useState('');


  const handleCurrentReportType = (currentReportType) => {
    SetMyReportType(currentReportType)
    replace(location.pathname+'?tab='+currentReportType);
  }

  const handleReportItem = () => {
    if(reportItemModal == false){
      SetReportItemModal(true)
    }else{
      SetReportItemModal(false)
    }
  }

  const getMemberDetails = async () => {
    try{
      let response = await getMember(user._id, token, props.match && props.match.params && props.match.params.memberId);
      setMemberDetails(response.userInfo);
    } catch (error) {
      //do something
    }
  }

  useEffect(() => {
    const query=queryString.parse(location.search);
    const { tab='' } = query;
    SetMyReportType(tab=='fitness-test'?'fitness-test':'workout-history');
    getMemberDetails();
  },[])

  return(
    <div className='w-full h-full'>
      {/* <div className="profile-body p-0 m-t-0">
        <p className="welcome-msg">Report</p>
      </div>
      <div className='w-full d-flex m-t-40'>
        <div className='w-full flex-item'>
          <img className="flex-item" style={{ width: "95%", margin: "0 auto" }} src={NoDataFound} />
          <div className='w-full' style={{margin: "20px auto 0 auto", textAlign:"center"}}>
            <p className='fs-14' style={{lineHeight:"18px", color:"#055075"}}>No Report Found</p>
          </div>
        </div>
      </div> */}

      {
        pathMob == props.match && props.match.params && `/member/details/${props.match.params.memberId}` && (
          <div className='d-flex' style={{padding: "12px 12px 0px 12px"}}>
            <Link to={`/members`} className='global-round-btn d-flex flex-item m-r-12'>
              <svg className='flex-item' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#6D6D6D" stroke-linecap="round"/>
              </svg>
            </Link>
            <p className='flex-item fs-16' style={{fontWeight:"600"}}>{memberDetails.name}</p>
          </div>
        )
      }
      
      <div className='w-full' style={{padding: '16px 12px', position: "sticky", top: 0, background: "#f8f8f8", boxShadow: "none", zIndex: 2 }} >
        <div className='d-flex w-full'>
          <div onClick={handleCurrentReportType.bind(this, "fitness-test")} className='flex-item d-flex my-report-type-container-mob' style={{outline: myReportType == "fitness-test" ? "1px solid #055075" : "", background: myReportType == "fitness-test" ? "#ffffff" : ""}}>
            <img className='flex-item' src={FitnessTest} />
          </div>
          <div onClick={handleCurrentReportType.bind(this, "workout-history")} className='flex-item d-flex my-report-type-container-mob m-l-16' style={{outline: myReportType == "workout-history" ? "1px solid #055075" : "", background: myReportType == "workout-history" ? "#ffffff" : ""}}>
            <img className='flex-item' src={WorkoutHistory} />
          </div>
        </div>
        {
          myReportType == "fitness-test" ? (
            <p className='report-type-heading-mob m-t-18'>Fitness Test</p>
          ) : myReportType == "workout-history" ? (
            <div className='d-flex space-between flex-item m-t-18'>
              <div className='flex-item'>
                <p className='report-type-heading-mob'>Workout history</p>
                {
                  (filterByWorkoutDate.startDate && filterByWorkoutDate.endDate)?(
                    <p className='flex-item fs-11'>{formatDateTime(new Date(filterByWorkoutDate.startDate))} - {formatDateTime(new Date(filterByWorkoutDate.endDate))}</p>
                  ):(
                    <p className='flex-item fs-11'>Sort By Date</p>
                  )
                }
              </div>
              <div className='global-round-btn d-flex flex-item'>
                <svg className='flex-item' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3160_1589)">
                  <path d="M8.26628 1.34998C8.42183 1.18946 8.60776 1.06148 8.81325 0.973513C9.01874 0.885543 9.23967 0.839334 9.46319 0.837577C9.68671 0.835819 9.90835 0.878548 10.1152 0.963275C10.322 1.048 10.51 1.17303 10.668 1.33109C10.8261 1.48915 10.9511 1.67707 11.0358 1.88392C11.1206 2.09076 11.1633 2.3124 11.1615 2.53592C11.1598 2.75944 11.1136 2.98037 11.0256 3.18586C10.9376 3.39135 10.8097 3.57728 10.6491 3.73284L9.98913 4.39284L7.60628 2.00998L8.26628 1.34998V1.34998ZM7.15199 2.46426L1.92899 7.68726C1.74042 7.87584 1.60328 8.10984 1.53128 8.36698L0.868276 10.7348C0.852957 10.7897 0.852507 10.8477 0.866971 10.9028C0.881435 10.9579 0.910294 11.0082 0.95059 11.0485C0.990885 11.0888 1.04117 11.1177 1.09629 11.1321C1.15141 11.1466 1.20939 11.1462 1.26428 11.1308L3.6317 10.4678C3.88882 10.3959 4.12306 10.2589 4.31185 10.0701L9.53528 4.84755L7.15242 2.46469L7.15199 2.46426Z" fill="black"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_3160_1589">
                  <rect width="12" height="12" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          ) :  null
        }
      </div>
      <div style={{padding: "0 4px"}}>
      {
        myReportType == "fitness-test" ? (
          <div className='w-full'>
            <img className="flex-item" style={{ width: "80%", margin: "40px 0 0 10%" }} src={NoDataFound} />
            {/* <div onClick={handleReportItem.bind(this)} className="custom-table-row d-flex space-between m-b-16">
              <div className="flex-item d-flex">
                <svg className="flex-item" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_760_1721)">
                  <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_760_1721">
                  <rect width="12" height="12" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <p className="flex-item m-l-8">21/04/2022</p>
              </div>
              <svg className="flex-item" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round"/>
              </svg>
            </div> */}
          </div>
        ) : myReportType == "workout-history" ? (
          <WorkoutHistoryMob filterByWorkoutDate={filterByWorkoutDate} memberId={props && props.match && props.match.params && props.match.params.memberId}/>
        ) : null
      }

      {
        reportItemModal && (
          <div onClick={handleReportItem} className="mobile-bottom-modal-container">
            {
              myReportType == "fitness-test" ? (
                <div id="persobal-details-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
                  <div onClick={handleReportItem} className="w-full">
                    <div className="draggable-pointer" />
                  </div>
                  <div className='mob-modal-scroll-container'>
                    <div className='d-flex m-b-16'>
                     <svg className='flex-item m-r-8' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_760_1736)">
                        <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_760_1736">
                        <rect width="12" height="12" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <p className='flex-item'>21/01/2022</p>
                    </div>
                    <div className='report-type-content-container m-b-16'>
                      <p className='heading-txt m-b-12'>Muscle Endurance</p>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Results</p>
                        <p className='flex-item report-content-value'>12</p>
                      </div>  
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Unit</p>
                        <p className='flex-item report-content-value'>cm</p>
                      </div> 
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Goal</p>
                        <p className='flex-item report-content-value'>Abs Muscles</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : myReportType == "workout-history" ? (
                <div id="persobal-details-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
                  <div onClick={handleReportItem} className="w-full">
                    <div className="draggable-pointer" />
                  </div>
                  <div className='mob-modal-scroll-container'>
                    <div className='d-flex m-b-20'>
                     <svg className='flex-item m-r-8' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_760_1736)">
                        <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_760_1736">
                        <rect width="12" height="12" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <p className='flex-item'>21/04/2022 - 24/04/2022</p>
                    </div>
                    <div className='w-full d-flex'>
                      <p className='history-main-heading-txt flex-item m-b-20'>Planner Beginner Chest</p>
                    </div>
                    <div className='report-type-content-container m-b-16'>
                      <p className='heading-txt m-b-12'>Planner Exercise Bi-Ceps</p>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Set</p>
                        <p className='flex-item report-content-value'>3</p>
                      </div>  
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Repetation</p>
                        <p className='flex-item report-content-value'>10</p>
                      </div> 
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Weight</p>
                        <p className='flex-item report-content-value'>45</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Distance</p>
                        <p className='flex-item report-content-value'>14 Km</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Count</p>
                        <p className='flex-item report-content-value'>3</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Duration</p>
                        <p className='flex-item report-content-value'>10 Min</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Date</p>
                        <p className='flex-item report-content-value'>21/04/2022</p>
                      </div>
                    </div>
                    <div className='report-type-content-container m-b-16'>
                      <p className='heading-txt m-b-12'>Planner Exercise Bi-Ceps</p>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Set</p>
                        <p className='flex-item report-content-value'>3</p>
                      </div>  
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Repetation</p>
                        <p className='flex-item report-content-value'>10</p>
                      </div> 
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Weight</p>
                        <p className='flex-item report-content-value'>45</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Distance</p>
                        <p className='flex-item report-content-value'>14 Km</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Count</p>
                        <p className='flex-item report-content-value'>3</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Duration</p>
                        <p className='flex-item report-content-value'>10 Min</p>
                      </div>
                      <div className='d-flex space-between m-b-10'>
                        <p className='flex-item report-content-type'>Date</p>
                        <p className='flex-item report-content-value'>21/04/2022</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            }
          </div>
        )
      }
      </div>
    </div>
  )
}