import React, {useState, useEffect} from 'react';

export default function PersonalDetails(){

  const [workoutHistoryStartDateActive, setWorkoutHistoryStartDateActive] = useState(false);

  const toggleInputFields = (currentInputField) => {
    let currentInput = document.getElementById(currentInputField);
    console.log(currentInput)

    if(currentInputField == 'workout-history-start-date'){
      currentInput.focus();

      console.log(currentInput == document.activeElement)

      setTimeout(() => { 
        if(currentInput == document.activeElement){
          setWorkoutHistoryStartDateActive(true)
        }else{
          setWorkoutHistoryStartDateActive(false)
        }
      }, 10);
      
    }
  }

  return(
    <div className='mob-modal-scroll-container'>
      <div className='d-flex m-t-16 m-b-14'>
        <svg className='flex-item m-r-8' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 0C7.00272 0 6.52581 0.180612 6.17418 0.502103C5.82254 0.823594 5.625 1.25963 5.625 1.71429C5.625 2.66571 6.45937 3.42857 7.5 3.42857C8.54062 3.42857 9.375 2.66571 9.375 1.71429C9.375 1.25963 9.17746 0.823594 8.82582 0.502103C8.47419 0.180612 7.99728 0 7.5 0ZM5.625 4.28571C5.37187 4.28571 5.15625 4.38 4.97813 4.52571H4.96875L0 9.07714L1.33125 10.2857L4.6875 7.20857V18H6.5625V12H8.4375V18H10.3125V7.20857L13.6687 10.2857L15 9.07714L10.0312 4.52571C9.84375 4.38 9.62813 4.28571 9.375 4.28571" fill="#F22000"/>
        </svg>
        <p className='flex-item personal-details-heading-txt'>Body Composition</p>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={`${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Body Type</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={`${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Height</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={`${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Weight</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div className='d-flex m-t-30 m-b-14'>
        <svg className='flex-item m-r-8' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1.25C8.1773 1.25215 6.42987 1.97717 5.14102 3.26602C3.85218 4.55486 3.12716 6.3023 3.12501 8.125C3.12282 9.61452 3.60937 11.0636 4.51001 12.25C4.51001 12.25 4.69751 12.4969 4.72813 12.5325L10 18.75L15.2744 12.5294C15.3019 12.4963 15.49 12.25 15.49 12.25L15.4906 12.2481C16.3908 11.0623 16.8771 9.61383 16.875 8.125C16.8729 6.3023 16.1478 4.55486 14.859 3.26602C13.5701 1.97717 11.8227 1.25215 10 1.25ZM10 10.625C9.50555 10.625 9.0222 10.4784 8.61108 10.2037C8.19996 9.92897 7.87953 9.53852 7.69031 9.08171C7.50109 8.62489 7.45158 8.12223 7.54804 7.63727C7.64451 7.15232 7.88261 6.70686 8.23224 6.35723C8.58187 6.0076 9.02733 5.7695 9.51228 5.67304C9.99723 5.57657 10.4999 5.62608 10.9567 5.8153C11.4135 6.00452 11.804 6.32495 12.0787 6.73607C12.3534 7.1472 12.5 7.63055 12.5 8.125C12.4992 8.78779 12.2355 9.42319 11.7669 9.89185C11.2982 10.3605 10.6628 10.6242 10 10.625Z" fill="#F22000"/>
        </svg>
        <p className='flex-item personal-details-heading-txt'>Location</p>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Country</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>State</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>City</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Location</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 2.25H1.5C1.30109 2.25 1.11032 2.32902 0.96967 2.46967C0.829018 2.61032 0.75 2.80109 0.75 3V9C0.75 9.19891 0.829018 9.38968 0.96967 9.53033C1.11032 9.67098 1.30109 9.75 1.5 9.75H10.5C10.6989 9.75 10.8897 9.67098 11.0303 9.53033C11.171 9.38968 11.25 9.19891 11.25 9V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25V2.25ZM9.675 3L6 5.5425L2.325 3H9.675ZM1.5 9V3.34125L5.78625 6.3075C5.84902 6.35105 5.9236 6.37438 6 6.37438C6.0764 6.37438 6.15098 6.35105 6.21375 6.3075L10.5 3.34125V9H1.5Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
          </svg>
          <p className="placeholder-txt flex-item" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Email</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.74967 10.875H9.68592C2.31717 10.4513 1.27092 4.23375 1.12467 2.33625C1.11288 2.18872 1.13032 2.04031 1.17599 1.89953C1.22166 1.75875 1.29466 1.62836 1.39081 1.51584C1.48696 1.40333 1.60436 1.31088 1.73629 1.24381C1.86823 1.17675 2.0121 1.13637 2.15967 1.125H4.22592C4.37612 1.12485 4.52292 1.16982 4.64728 1.25406C4.77164 1.33831 4.86784 1.45795 4.92342 1.5975L5.49342 3C5.5483 3.13633 5.56192 3.28578 5.53258 3.42978C5.50325 3.57379 5.43225 3.706 5.32842 3.81L4.52967 4.61625C4.65443 5.32528 4.99398 5.97888 5.50238 6.48861C6.01077 6.99834 6.66347 7.33961 7.37217 7.46625L8.18592 6.66C8.29148 6.55731 8.42494 6.48797 8.56965 6.46063C8.71436 6.43329 8.86391 6.44915 8.99967 6.50625L10.4134 7.0725C10.5509 7.12983 10.6681 7.22678 10.7503 7.351C10.8324 7.47522 10.8757 7.62108 10.8747 7.77V9.75C10.8747 10.0484 10.7561 10.3345 10.5452 10.5455C10.3342 10.7565 10.048 10.875 9.74967 10.875ZM2.24967 1.875C2.15021 1.875 2.05483 1.91451 1.9845 1.98484C1.91417 2.05516 1.87467 2.15054 1.87467 2.25V2.28C2.04717 4.5 3.15342 9.75 9.72717 10.125C9.77643 10.128 9.82581 10.1213 9.87248 10.1053C9.91914 10.0892 9.96218 10.064 9.99911 10.0313C10.036 9.99854 10.0661 9.95882 10.0877 9.91441C10.1092 9.87 10.1218 9.82178 10.1247 9.7725V7.77L8.71092 7.20375L7.63467 8.2725L7.45467 8.25C4.19217 7.84125 3.74967 4.57875 3.74967 4.545L3.72717 4.365L4.79217 3.28875L4.22967 1.875H2.24967Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
          </svg>
          <p className="placeholder-txt flex-item" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Phone</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.74967 10.875H9.68592C2.31717 10.4513 1.27092 4.23375 1.12467 2.33625C1.11288 2.18872 1.13032 2.04031 1.17599 1.89953C1.22166 1.75875 1.29466 1.62836 1.39081 1.51584C1.48696 1.40333 1.60436 1.31088 1.73629 1.24381C1.86823 1.17675 2.0121 1.13637 2.15967 1.125H4.22592C4.37612 1.12485 4.52292 1.16982 4.64728 1.25406C4.77164 1.33831 4.86784 1.45795 4.92342 1.5975L5.49342 3C5.5483 3.13633 5.56192 3.28578 5.53258 3.42978C5.50325 3.57379 5.43225 3.706 5.32842 3.81L4.52967 4.61625C4.65443 5.32528 4.99398 5.97888 5.50238 6.48861C6.01077 6.99834 6.66347 7.33961 7.37217 7.46625L8.18592 6.66C8.29148 6.55731 8.42494 6.48797 8.56965 6.46063C8.71436 6.43329 8.86391 6.44915 8.99967 6.50625L10.4134 7.0725C10.5509 7.12983 10.6681 7.22678 10.7503 7.351C10.8324 7.47522 10.8757 7.62108 10.8747 7.77V9.75C10.8747 10.0484 10.7561 10.3345 10.5452 10.5455C10.3342 10.7565 10.048 10.875 9.74967 10.875ZM2.24967 1.875C2.15021 1.875 2.05483 1.91451 1.9845 1.98484C1.91417 2.05516 1.87467 2.15054 1.87467 2.25V2.28C2.04717 4.5 3.15342 9.75 9.72717 10.125C9.77643 10.128 9.82581 10.1213 9.87248 10.1053C9.91914 10.0892 9.96218 10.064 9.99911 10.0313C10.036 9.99854 10.0661 9.95882 10.0877 9.91441C10.1092 9.87 10.1218 9.82178 10.1247 9.7725V7.77L8.71092 7.20375L7.63467 8.2725L7.45467 8.25C4.19217 7.84125 3.74967 4.57875 3.74967 4.545L3.72717 4.365L4.79217 3.28875L4.22967 1.875H2.24967Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
          </svg>
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Emergency Contact No.</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1C5.50555 1 5.0222 1.14662 4.61107 1.42133C4.19995 1.69603 3.87952 2.08648 3.6903 2.54329C3.50108 3.00011 3.45157 3.50277 3.54804 3.98773C3.6445 4.47268 3.8826 4.91814 4.23223 5.26777C4.58186 5.6174 5.02732 5.8555 5.51227 5.95196C5.99723 6.04843 6.49989 5.99892 6.95671 5.8097C7.41352 5.62048 7.80397 5.30005 8.07867 4.88893C8.35338 4.4778 8.5 3.99445 8.5 3.5C8.5 2.83696 8.23661 2.20107 7.76777 1.73223C7.29893 1.26339 6.66304 1 6 1ZM6 5C5.70333 5 5.41332 4.91203 5.16664 4.7472C4.91997 4.58238 4.72771 4.34811 4.61418 4.07403C4.50065 3.79994 4.47094 3.49834 4.52882 3.20736C4.5867 2.91639 4.72956 2.64912 4.93934 2.43934C5.14912 2.22956 5.41639 2.0867 5.70736 2.02882C5.99834 1.97094 6.29994 2.00065 6.57403 2.11418C6.84811 2.22771 7.08238 2.41997 7.2472 2.66664C7.41203 2.91332 7.5 3.20333 7.5 3.5C7.5 3.89782 7.34196 4.27936 7.06066 4.56066C6.77936 4.84196 6.39782 5 6 5ZM10.5 10.5V10C10.5 9.07174 10.1313 8.1815 9.47487 7.52513C8.8185 6.86875 7.92826 6.5 7 6.5H5C4.07174 6.5 3.1815 6.86875 2.52513 7.52513C1.86875 8.1815 1.5 9.07174 1.5 10V10.5H2.5V10C2.5 9.33696 2.76339 8.70107 3.23223 8.23223C3.70107 7.76339 4.33696 7.5 5 7.5H7C7.66304 7.5 8.29893 7.76339 8.76777 8.23223C9.23661 8.70107 9.5 9.33696 9.5 10V10.5H10.5Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
          </svg>
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Emergency Contact Name</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
      <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
        {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
        <div className="flex-item d-flex">
          <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_733_1957)">
            <path d="M8.25 4.875C8.25 4.77554 8.28951 4.68016 8.35983 4.60984C8.43016 4.53951 8.52554 4.5 8.625 4.5H9.375C9.47446 4.5 9.56984 4.53951 9.64017 4.60984C9.71049 4.68016 9.75 4.77554 9.75 4.875V5.625C9.75 5.72446 9.71049 5.81984 9.64017 5.89016C9.56984 5.96049 9.47446 6 9.375 6H8.625C8.52554 6 8.43016 5.96049 8.35983 5.89016C8.28951 5.81984 8.25 5.72446 8.25 5.625V4.875Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
            <path d="M2.625 0C2.72446 0 2.81984 0.0395088 2.89016 0.109835C2.96049 0.180161 3 0.275544 3 0.375V0.75H9V0.375C9 0.275544 9.03951 0.180161 9.10983 0.109835C9.18016 0.0395088 9.27554 0 9.375 0C9.47446 0 9.56984 0.0395088 9.64017 0.109835C9.71049 0.180161 9.75 0.275544 9.75 0.375V0.75H10.5C10.8978 0.75 11.2794 0.908035 11.5607 1.18934C11.842 1.47064 12 1.85218 12 2.25V10.5C12 10.8978 11.842 11.2794 11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12H1.5C1.10218 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V2.25C0 1.85218 0.158035 1.47064 0.43934 1.18934C0.720644 0.908035 1.10218 0.75 1.5 0.75H2.25V0.375C2.25 0.275544 2.28951 0.180161 2.35984 0.109835C2.43016 0.0395088 2.52554 0 2.625 0V0ZM0.75 3V10.5C0.75 10.6989 0.829018 10.8897 0.96967 11.0303C1.11032 11.171 1.30109 11.25 1.5 11.25H10.5C10.6989 11.25 10.8897 11.171 11.0303 11.0303C11.171 10.8897 11.25 10.6989 11.25 10.5V3H0.75Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
            </g>
            <defs>
            <clipPath id="clip0_733_1957">
            <rect width="12" height="12" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Date of Birth</p>
        </div>
        <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
      </div>
    </div>
  )
}