import { useEffect, useState } from "react";

const ManageConfirmationModal = (props) => {
  const {
    title = "Do you really want to Continue ?",
    message = "Confirmation text or Important note will go here ",
    buttonText = "Confirm",
    handleClose = () => { },
    handleAction = () => { },
    isButtonLock = false,
    isMobile = false
  } = props;
  return (
    <div className={isMobile ? "mobile-bottom-modal-container" : "global-modal-container d-flex"} onClick={isMobile?handleClose:null}>
      <div id={isMobile ? "mobile-video-modal" : "desktop-video-modal"} onClick={e => isMobile ? e.stopPropagation() : ""} className={isMobile ? "mobile-bottom-modal" : "global-confirmation-modal flex-item"}>
        {
          isMobile && <div onClick={handleClose} className="w-full">
            <div className="draggable-pointer" />
          </div>
        }
        <div className="w-full">
          <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0", position: "relative" }}>
            <p className="flex-item modal-header-text">{title}</p>
            <div onClick={handleClose} className="d-flex desktop-modal-close-button">
              <svg className="flex-item" width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.9283 3.80866C4.77899 3.66445 4.57901 3.58465 4.37144 3.58646C4.16387 3.58826 3.96531 3.67152 3.81853 3.8183C3.67175 3.96508 3.58849 4.16364 3.58668 4.37121C3.58488 4.57879 3.66468 4.77876 3.80888 4.92807L8.38076 9.49995L3.80809 14.0718C3.73248 14.1449 3.67217 14.2322 3.63068 14.3288C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7395 3.58555 14.8437 3.62536 14.941C3.66516 15.0383 3.72395 15.1267 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4338 4.36495 15.4329C4.47007 15.432 4.57395 15.4101 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1912L9.50018 10.6194L14.0721 15.1912C14.2214 15.3354 14.4213 15.4152 14.6289 15.4134C14.8365 15.4116 15.035 15.3284 15.1818 15.1816C15.3286 15.0348 15.4119 14.8363 15.4137 14.6287C15.4155 14.4211 15.3357 14.2211 15.1915 14.0718L10.6196 9.49995L15.1915 4.92807C15.3357 4.77876 15.4155 4.57879 15.4137 4.37121C15.4119 4.16364 15.3286 3.96508 15.1818 3.8183C15.035 3.67152 14.8365 3.58826 14.6289 3.58646C14.4213 3.58465 14.2214 3.66445 14.0721 3.80866L9.50018 8.38053L4.9283 3.80786V3.80866Z" fill="black" />
              </svg>
            </div>
          </div>

        </div>
        <div className="w-full p-l-12" style={{ textAlign: "left" }}>
          <p style={{ color: "#6d6d6d" }}>{message}</p>
        </div>

        <div onClick={isButtonLock ? null : handleAction} className={`member-button-global ${isButtonLock && `member-button-global-inactive`} btn-center d-flex`} style={isMobile?{ margin: "30px 12px 12px 0",pointerEvents:isButtonLock?'none':'auto'}:{ width: "40%", position: "absolute", right: "0", bottom: "0", margin: "0 12px 12px 0", pointerEvents: isButtonLock ? 'none' : 'auto' }}>
          <p className="flex-item">{buttonText}</p>
        </div>
      </div>
    </div>
  )
}

export default ManageConfirmationModal;