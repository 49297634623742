import { API } from "../../backend";
import {objectToQueryString} from '../../lib/utils'
const unAuthParam="secret=instafit-app-secret134&token=jhsasjalsjlksjakjsn121n";

export const getUnAuthGym=(gymID)=>{
    return fetch(`${API}/gym/${gymID}?${unAuthParam}`,{
      method:'GET',
      headers:{
        Accept:"application/json"
      }
    }).then(response=>response.json()).catch(console.error)
}

export const getGym = (userId, token, gymId) => {
    return fetch(`${API}/gym/${userId}/${gymId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json();
        })
        .catch(err => err);
}



export const createGym = (userId, token, gym) => {
    return fetch(`${API}/create/gym/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: gym
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


export const updateGym = (userId, token, gymId, gym) => {
    return fetch(`${API}/update/gym/${userId}/${gymId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: gym
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


export const changeGymStatus =(userId,token,activationStatus,body)=>{
    return fetch(`${API}/change/gym/status/${userId}/${activationStatus}`,{
        method:"PUT",
        headers:{
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}` 
        },
        body:JSON.stringify(body)
    }).then(res=>res.json()).catch(err=>console.log(err))
}

export const assignGymAdmin = (userId, token, gymId, user) => {
    return fetch(`${API}/create/gym/admin/user/${userId}/${gymId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


export const removeGymAdmin = (userId, token, gymId) => {
    return fetch(`${API}/remove/gym/admin/user/${userId}/${gymId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

export const getAllGym=(userId,token,query)=>{
    let str=objectToQueryString(query)
    return fetch(`${API}/gyms/${userId}?${str}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err));
}

export const getGymCount=(userId,token,query)=>{
    let str=objectToQueryString(query);
    return fetch(`${API}/gym/count/${userId}?${str}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err));
}
