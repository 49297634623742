import { createSlice } from '@reduxjs/toolkit'

export const appCountSlice = createSlice({
  name: 'appCount',
  initialState: {
    member: 0,
    branch: 0,
    user:0
  },
  reducers: {
    setAppCountValue: (state, action) => {
      if (action.payload.countType == "member") {
        state.member = action.payload.value;
      }else if(action.payload.countType=="user"){
        state.user=action.payload.value;
      } else {
        state.branch = action.payload.value;
      }
    },
    incrementCountValue:(state,action)=>{
      if (action.payload == "member") {
        state.member++;
      }else if(action.payload=="user"){
        state.user++;
      } else {
        state.branch++;
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAppCountValue,incrementCountValue } = appCountSlice.actions

export default appCountSlice.reducer