import { API } from "../../../backend";
import { objectToQueryString } from "../../../lib/utils";

export const getParameter=(userId,token,param,parameterId)=>{
  return fetch(`${API}/parameter/${parameterId}`,{
    method:'get',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    }
  }).then(res=>{
    return res.json();
  }).catch(console.error);
}

export const getParameterCount=(userId,token,query)=>{
  let str=objectToQueryString(query);
  return fetch(`${API}/parameter/count/${userId}?${str}`,{
    method:'get',
    headers:{
      Accept:'application/json',
      Authorization:`Bearer ${token}`
    }
  }).then(res=>res.json()).catch(console.error);
}


export const  getAllParameters=(userId,token,query)=>{
  let str=objectToQueryString(query);
  return fetch(`${API}/parameters/${userId}?${str}`,{
    method:'get',
    headers:{
      Accept:'application/json',
      Authorization:`Bearer ${token}`
    }
  }).then(res=>res.json()).catch(console.error)
}


export const addParameter=(userId,token,formData)=>{
  return fetch(`${API}/parameter/create/${userId}`,{
    method:"post",
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    },
    body:formData
  }).then(res=>res.json()).catch(console.error);
}



export const updateParameter=(userId,token,parameterId,formData)=>{
  return fetch(`${API}/parameter/update/${userId}/${parameterId}`,{
    method:'put',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    },
    body:formData
  }).then(res=>res.json()).catch(console.error);
}


export const removeParameter=(userId,token,parameterId)=>{
  return fetch(`${API}/parameter/delete/${userId}/${parameterId}`,{
    method:'delete',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    }
  }).then(res=>res.json()).catch(console.error);
}