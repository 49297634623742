import { createSlice } from '@reduxjs/toolkit'

export const actionMenuSlice = createSlice({
  name: 'actionMenu',
  initialState: {
    value: "",
  },
  reducers: {
    closeMenu: (state) => {
      if (state.value=="menu-profile") {
        let container = document.getElementById("mobile-bottom-modal-container-menu");
        if (container) {
          let menu = document.getElementById('menu-bar-mobile-id');
          if (menu) {
            container.style.background = "rgba(0, 0, 0, 0.10)";
            menu.style.marginRight = "-70%";
          }
        }
      }
      state.value = "";
    },
    openMenu: (state, action) => {
      state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { openMenu, closeMenu } = actionMenuSlice.actions

export default actionMenuSlice.reducer