import React, { useEffect, useState, useRef } from 'react';
import Dashboard from '../../core/Dashboard';
import { getMemberCount, getAllMembers, changeMemberStatus, updateUserProfileImage } from '../helper/api';
import { getAllBranches, getBranchCount } from '../../branch/helper/api';
import { isAuthenticated } from '../../auth';
import { getAllGym, getGymCount } from '../../gym/helper/api';
import _ from 'lodash';
import GoogleIcon from "../../assets/google.png";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import FloatingMenu from '../../components/floatingMenu';
import GymLogo from '../../assets/gymshim-logo-icon.png';
import MenuMob from '../../components/menu';
import ManageExecutePlanner from '../../components/Modal/mobile/ManageExecutePlanner';
import { useLongPress } from 'use-long-press';
import { startLoader, stopLoader, useAsyncState } from '../../lib/utils';
import config from '../../config';
import ManageConfirmationModal from '../../components/Modal/ConfirmModal';
import ManageMemberModal from '../../components/Modal/mobile/ManageMember';
import { addSearchHistory, getSearchHistories, getSearchHistoryCount, removeAllSearchHistory, removeSearchHistory } from '../../core/helper/SearchHistory';
import { SwipeableDrawer } from '@material-ui/core';
import ManageFitnessTest from '../../components/Modal/mobile/ManageFitnessTest';
import { useDispatch, useSelector } from 'react-redux';
import { closeMenu, openMenu } from '../../lib/storeFeature/actionMenu';
import Menu from '../../components/menu';

let selectedFile;


export default function Member(props) {
  const history = useHistory()
  const location = useLocation();
  const path = location.pathname;
  const [selectedMember, setSelectedMember] = useState('') ;
  // const [selectedMember, setSelectedMember] = useState('');
  const [gymIDs, setGymIDs] = useState([]);
  const [branchIDs, setBranchIDs] = useState([]);
  const [selectedGym, setSelectedGym] = useState('');
  const [Members, setMembers] = useAsyncState([]);
  const [flag, setflag] = useState(3);
  const [Gyms, setGyms] = useAsyncState([]);
  const [currentActionItem, setCurrentActionItem] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [isGymFilterSearchOpen, setGymFilterSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPlannerExecutionModalOpen, setPlannerExecutionModalOpen] = useState(false);
  const [isSelectedMembers, setSelectedMembers] = useState([]);
  var [prevSearchKeys, setPrevSearchKeys] = useState([]);
  const [pageSize, setPageSize] = useState({
    branch: 30,
    gym: 30,
    member: 30
  });
  const [pageCount, setPageCount] = useState({
    branch: 0,
    gym: 0,
    member: 0
  });
  var [pageNo, setPageNo] = useState({
    branch: 0,
    gym: 0,
    member: 0
  })
  const [total, setTotal] = useState({
    gym: 0,
    branch: 0,
    member: 0
  });
  const [isStatusChangeOPeration, setStatusChangeOPeration] = useState(false);
  const [Branches, setBranches] = useAsyncState([]);
  const [isAllMembersChecked, setAllMembersChecked] = useState(false);
  const [isBulkStatusChangeAction, setBulkStatusChangeAction] = useState(false)
  const [isMembersActive, setMembersActive] = useAsyncState(true);
  const [selectedMemberIds, setSelectedMemberIds] = useState([])
  const [isMemberViewOpen, setMemberViewOpen] = useState(false);
  var [searchKey, setSearchKey] = useState("");
  const [gymSearchKey, setGymSearchKey] = useState("");
  const [isGymSpinnerLoader, setGymSpinnerLoader] = useState(false);
  const [isBrachSpinnerLoader, setBranchSpinnerLoader] = useState(false);
  const [branchSearchKey, setBranchSearchKey] = useState("");
  const [isStatusChangeModalOpen, setStatusChangeModal] = useState(false);
  const [isAddMemberModal, setAddMemberModal] = useState(false);
  const [prevCurrentActionItem, setPrevCurrentActionItem] = useState('');
  const { user, token } = isAuthenticated();
  const [isMemberSpinnerLoader, setMemberSpinnerLoader] = useState(false);
  const [isBranchFilterSearchOpen, setBranchFilterSearchOpen] = useState(false);
  var [prevSearchKeys, setPrevSearchKeys] = useState([])
  const [isMemberApiAction, setMemberApiAction] = useState(false);
  const [searchHistories, setSearchHistories] = useAsyncState([]);
  var [searchHistoryPageNo, setSearchHistoryPageNo] = useState(1);
  const [searchHistoryPageSize, setSearchHistoryPageSize] = useState(40);
  const [searchHistoryTotal, setSearchHistoryTotal] = useState(0);
  const [searchHistoryPageCount, setSearchHistoryPageCount] = useState(1);
  const [isSearchBoxLoaderStart, setSearchBoxLoaderStart] = useState(false);
  const [isLongPressActivated, setLongPressActivated] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [drawerState, setDrawerState] = useState({
    bottom: false,
  });
  const [isFitnessTakeTestModalOpen, setFitnessTakeTestModalOpen] = useState(false);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isMenuAction = useSelector((state) => state.isMenuAction.value);
  const dispatch = useDispatch();
  const [isManageProfileOpen, setManageProfileOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isProfileViewOpen, setProfileViewOpen] = useState(false);


  const handleBulkStatusChangeAction = event => {
    setStatusChangeModal(!isStatusChangeModalOpen);
    setBulkStatusChangeAction(true);
  }

  const RemoveSearchHistory = (data, index) => async e => {
    e.preventDefault()
    try {
      setSearchHistories(oldstate => {
        oldstate.splice(index, 1);
        return [...oldstate];
      })
      await removeSearchHistory(user._id, token, data._id);
    } catch (error) {
      console.error(error)
    }
    e.stopPropagation()
  }

  const RemoveAllSearchHistory = async event => {
    event.preventDefault()
    try {
      if (searchHistories.length == 0) return "";
      setSearchHistoryPageCount(1)
      setSearchHistoryPageNo(1);
      setSearchHistoryTotal(0)
      setSearchHistories([]);
      await removeAllSearchHistory(user._id, token);
    } catch (error) {
      console.error(error)
    }
    event.stopPropagation()
  }



  const handleSelectSearchHistoryKey = data => async e => {
    try {
      setMemberApiAction(!isMemberApiAction)
      searchKey = data.searchKey;
      setSearchKey(data.searchKey)
      prevSearchKeys.push(data.searchKey);
      setPrevSearchKeys(prevSearchKeys)
      toggleCurrentActionItem()
    } catch (error) {
      console.error(error)
    }
  }


  const handleSearchHistoryScroll = async event => {
    const { offsetHeight, scrollTop, scrollHeight } = event.target;

    if (offsetHeight + scrollTop === scrollHeight && searchHistoryPageNo < searchHistoryPageCount) {
      searchHistoryPageNo = searchHistoryPageNo + 1;
      setSearchHistoryPageNo(searchHistoryPageNo)
      setSearchBoxLoaderStart(true)
      await GetSearchHistories();
      setSearchBoxLoaderStart(false)
    }
  }


  const GetSearchHistoryParams = () => {
    let params = {
      pageNo: searchHistoryPageNo,
      pageSize: searchHistoryPageSize
    };
    params.userId = user._id;
    params.dashboardType = "Member";
    return params;
  }

  const GetSearchHistories = async (isReUpdateSearchHistory) => {
    try {
      setSearchBoxLoaderStart(true)
      let params = GetSearchHistoryParams();
      let response = await getSearchHistories(user._id, token, params);
      if (response.error) {
        setSearchHistories([])
      } else {
        if (isReUpdateSearchHistory) {
          setSearchHistories(response)
        } else {
          setSearchHistories([...searchHistories, ...response]);
        }
      }
      setSearchBoxLoaderStart(false);
    } catch (error) {
      setSearchBoxLoaderStart(false)
    }
  }

  const GetSearchHistoryCount = async () => {
    try {
      let params = GetSearchHistoryParams();
      let attr = await getSearchHistoryCount(user._id, token, params);
      let COUNT = attr.COUNT ? attr.COUNT : 0;
      setSearchHistoryTotal(COUNT);
      setSearchHistoryPageCount(Math.ceil(COUNT / searchHistoryPageSize));
      setSearchHistoryPageNo(0);
    } catch (error) {
      setSearchBoxLoaderStart(false)
    }
  }


  const clearSearch = async event => {
    await setMembers([])
    searchKey = "";
    setSearchKey(searchKey);
    setPrevSearchKeys([])
    setMemberApiAction(!isMemberApiAction)
    toggleCurrentActionItem();
    event.stopPropagation()
  }
  const onKeyDown = async event => {
    if (event.keyCode == 13) {
      await setMembers([])
      setMemberApiAction(!isMemberApiAction)
      prevSearchKeys.push(searchKey);
      setPrevSearchKeys(prevSearchKeys)
      toggleCurrentActionItem()
      if (searchKey) {
        await addSearchHistory(user._id, token, {
          searchKey,
          userId: user._id,
          dashboardType: "Member"
        })
      }
    }
  }


  const handleSearchKey = event => {
    setSearchKey(event.target.value);
  }

  const handleGymSearch = async event => {
    setGymSearchKey(event.target.value)
  }

  const handleBranchSearch = async event => {
    setBranchSearchKey(event.target.value)
  }

  const handleSelectBranch = branch => async event => {
    await setMembers([])
    setSelectedBranch(branch);
    backCurrentActionItem()
    event.stopPropagation()
  }



  const handleClearBranchFilter = () => {
    setSelectedBranch("")
  }


  const handleClearGymFilter = () => {
    setSelectedGym("");
    setSelectedBranch("")
    setBranches([])
  }

  const handleSelectMemberID = (gymId) => e => {
    e.preventDefault();
    let ids = [...isSelectedMembers]
    let index = ids.findIndex(id => id == gymId);
    if (index == -1) {
      ids.push(gymId)
    } else {
      ids.splice(index, 1)
    }

    if (ids.length == 0) {
      setLongPressActivated(false)
    }

    setSelectedMembers(ids);
  }


  const handleGymScroll = async event => {
    try {
      const { offsetHeight, scrollTop, scrollHeight } = event.target;
      if (offsetHeight + scrollTop === scrollHeight && pageNo.gym < pageCount.gym) {
        pageNo.gym = pageNo.gym + 1;
        setPageNo({ ...pageNo })
        setGymSpinnerLoader(true)
        await GetAllGyms();
        setGymSpinnerLoader(false)
      }
    } catch (error) {
      setGymSpinnerLoader(false)
    }
  }


  const handleBranchScroll = async event => {
    try {
      const { offsetHeight, scrollTop, scrollHeight } = event.target;
      if (offsetHeight + scrollTop === scrollHeight && pageNo.branch < pageCount.branch) {
        pageNo.branch = pageNo.branch + 1;
        setPageNo({ ...pageNo })
        setBranchSpinnerLoader(true)
        await GetAllBranches();
        setBranchSpinnerLoader(false)
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
    }
  }



  const handleMemberScroll = async event => {
    try {
      const { offsetHeight, scrollTop, scrollHeight } = event.target;
      if (offsetHeight + scrollTop === scrollHeight && pageNo.member < pageCount.member) {
        pageNo.member = pageNo.member + 1;
        setPageNo({ ...pageNo })
        setMemberSpinnerLoader(true)
        await GetAllMember();
        setMemberSpinnerLoader(false)
      }
    } catch (error) {
      setMemberSpinnerLoader(false)
    }
  }






  const handleAction = async event => {
    event.preventDefault();
    try {
      await startLoader()
      let status = (isBulkStatusChangeAction ? isMembersActive : (!selectedMember.deletedAt || selectedMember.deletedAt == null)) ? "deactivate" : "activate";
      let memberIDs = isBulkStatusChangeAction ? isSelectedMembers : [selectedMember._id];
      if (isAllMembersChecked) {
        memberIDs = await Gyms.map(gym => gym._id);
      }
      await changeMemberStatus(user._id, token, status, { memberIDs });
      setStatusChangeModal(false);
      setBulkStatusChangeAction(false);
      setAllMembersChecked(false);
      setSelectedMemberIds([]);
      setSelectedMember('')
      setSelectedMembers([])
      await GetMemberCount();
      await GetAllMember(true);
      await stopLoader()
    } catch (error) {
      await stopLoader()
    }
    event.stopPropagation()
  }



  const toggleAddMemberModal = () => {
    if (isAddMemberModal) {
      setAddMemberModal(false)
    } else {
      setAddMemberModal(true)
    }
  }


  const handleSelectGym = gym => async event => {
    await setMembers([])
    await setBranches([])
    setSelectedGym(gym)
    backCurrentActionItem()
    event.stopPropagation();
  }

  const handleUpdate = member => event => {
    setSelectedMember(member);
    toggleAddMemberModal();
    event.stopPropagation();
  }


  

  const handleStatusChangeAction=member => event => {
    setSelectedMember(member)
    setStatusChangeModal(!isStatusChangeModalOpen);
    setBulkStatusChangeAction(false);
    event.stopPropagation()
  }




  const backToPrevSearchState = event => {
    event.preventDefault();
    if (prevSearchKeys.length > 1) {
      searchKey = prevSearchKeys[prevSearchKeys.length - 2];
      setSearchKey(searchKey)
      prevSearchKeys.splice(prevSearchKeys.length - 1, 1);
      setPrevSearchKeys(prevSearchKeys);
      setMemberApiAction(!isMemberApiAction)
    } else {
      searchKey = "";
      setSearchKey(searchKey)
      setPrevSearchKeys([])
      setMemberApiAction(!isMemberApiAction)
      toggleCurrentActionItem()
    }
    event.preventDefault();
  }



  const prev = event => {
    event.preventDefault();
    if (pageNo.member <= pageCount.member && pageNo.member != 1) {
      pageNo.member = pageNo.member - 1;
      setPageNo({
        ...pageNo
      });
    }
  };
  const next = event => {
    event.preventDefault();
    if (pageNo.member < pageCount.member) {
      pageNo.member = pageNo.member + 1;
      setPageNo({
        ...pageNo
      })
    }
  };



  const onSelectMember = member => event => {
    event.preventDefault();
    setSelectedMember(member)
    setMemberViewOpen(true);
    event.stopPropagation()
  };



  const getGymParams = () => {
    let param = {
      pageSize: pageSize.gym,
      pageNo: pageNo.gym
    };
    if (gymSearchKey) param.q = gymSearchKey;
    param.activationStatus = "active";
    return param;
  }

  const GetAllGyms = async (isReload = false) => {
    try {
      let params = getGymParams();
      let gyms = await getAllGym(user._id, token, params);
      if (!gyms.error) setGyms(isReload?[...gyms]:[...Gyms, ...gyms])
    } catch (error) {
      console.log(error)
    }
  }

  const GetGymCount = async () => {
    try {
      let params = getGymParams();
      let attr = await getGymCount(user._id, token, params);
      pageNo.gym = 1;
      setPageNo({
        ...pageNo
      })
      setTotal({
        ...total,
        gym: attr.COUNT
      });
      setPageCount({
        ...pageCount,
        gym: Math.ceil(attr.COUNT / pageSize.gym)
      });
    } catch (error) {
      // do something
    }
  }



  const GetBranchParams = () => {
    let params = {};
    params.noLimit = "noLimit";
    if (branchSearchKey) params.q = branchSearchKey;
    if (selectedGym) params.gymIds = [selectedGym._id];
    params.activationStatus = "active";
    return params;
  }

  const GetAllBranches = async (isReload = false) => {
    try {
      let params = GetBranchParams();
      let branches = await getAllBranches(user._id, token, params)
      if (!branches.error) setBranches(isReload?[...branches]:[...Branches, ...branches]);
    } catch (error) {
      // do something 
    }
  }

  const GetBranchCount = async () => {
    try {
      let params = GetBranchParams();
      let attr = await getBranchCount(user._id, token, params);
      pageNo.branch = pageNo.branch + 1;
      setPageNo({
        ...pageNo
      })
      setTotal({
        ...total,
        branch: attr.COUNT
      });
      setPageCount({
        ...pageCount,
        branch: Math.ceil(attr.COUNT / pageSize.branch)
      });
    } catch (error) {
      // do something
    }
  }


  const GetMemberParams = () => {
    let params = {
      pageNo: pageNo.member,
      pageSize: pageSize.member
    };
    params.activationStatus = isMembersActive ? "activate" : "deactivate";
    if (searchKey) params.q = searchKey;
    if (gymIDs.length > 0) params.gymIDs = gymIDs;
    if (branchIDs.length > 0) params.branchIDs = branchIDs;
    if (selectedGym) params.gymIDs = [selectedGym._id];
    if (selectedBranch) params.branchIDs = [selectedBranch._id];
    return params;
  }

  const GetAllMember = async (isReUpdateMembers) => {
    try {
      let params = GetMemberParams();
      let members = await getAllMembers(user._id, token, params);
      if (!members.error) {
        if (isReUpdateMembers) setMembers(members)
        else setMembers([...Members, ...members]);
      }
    } catch (error) {
      //do something
    }
  }

  const GetMemberCount = async () => {
    try {
      let params = GetMemberParams();
      delete params.pageNo;
      delete params.pageSize;
      let response = await getMemberCount(user._id, token, params);
      pageNo.member = 1;
      setPageNo({
        ...pageNo
      })
      setPageCount({
        ...pageCount,
        member: Math.ceil(response.COUNT / pageSize.member)
      })
      setTotal({
        ...total,
        member: response.COUNT
      })
    } catch (error) {
      // so something
    }
  }
  const checkAllMembers = () => {
    if (isAllMembersChecked) {
      setAllMembersChecked(false)
    } else {
      setAllMembersChecked(true)
    }
  }

  const toggleCurrentActionItem = async (actionItem) => {
    try {
      setPrevCurrentActionItem(currentActionItem)
      setCurrentActionItem(actionItem)
      if (actionItem == "Search") {
        setSearchBoxLoaderStart(true)
        await setSearchHistories([])
        await GetSearchHistoryCount();
        await GetSearchHistories(true);
        setSearchBoxLoaderStart(false)
      }
    } catch (error) {
      setSearchBoxLoaderStart(false);
      console.error(error)
    }
  }

  const backCurrentActionItem = () => {
    setCurrentActionItem(prevCurrentActionItem);
    setPrevCurrentActionItem('');
  }

  const toggleActiveInactiveButton = () => {
    if (isMembersActive) {
      setMembersActive(false);
    } else {
      setMembersActive(true);
    }
  }

  const toggleSearchFilterBranch = () => {
    if (isBranchFilterSearchOpen) {
      setBranchFilterSearchOpen(false)
    } else {
      setBranchFilterSearchOpen(true)
    }
  }

  const toggleSearchFilterGym = () => {
    if (isGymFilterSearchOpen) {
      setGymFilterSearchOpen(false)
    } else {
      setGymFilterSearchOpen(true)
    }
  }

  const handleMenuVisibility = () => {
    if (!isMenuOpen) {
      setTimeout(() => {
        setIsMenuOpen(true)
      }, 50);
    } else {
      let container = document.getElementById("mobile-bottom-modal-container-menu");
      let menu = document.getElementById('menu-bar-mobile-id');
      container.style.background = "rgba(0, 0, 0, 0.10)";
      menu.style.marginRight = "-70%";
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 180);
    }
  }

  const toggleManageExecutePlanner = () => {
    if (isPlannerExecutionModalOpen) {
      setPlannerExecutionModalOpen(false)
    } else {
      setPlannerExecutionModalOpen(true)
    }
  }

  const toggleMemberActions = () => {
    setSelectedMembers([])
    setLongPressActivated(false)
  }

  const LongPress = useLongPress((e, memberId) => {
    if (!isLongPressActivated) {
      setSelectedMembers(oldArray => [...oldArray, memberId.context]);
      setTimeout(() => {
        setLongPressActivated(true)
      }, 300)
    }
  });

  const toggleFitnessTakeTestModalOpen = () => {
    setFitnessTakeTestModalOpen(true);
    setDrawerState({
      ...drawerState, bottom : true
    })
  }

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (open === false) {
      toggleFitnessTakeTestModalOpen(false);
    }

    setDrawerState({ ...drawerState, bottom: open });
  };

  const closeSearchOption = () => {
    setCurrentActionItem("")
  }

  const handleSideMenuVisibility = () => {
    if (isMenuAction != "menu-profile") {
      setTimeout(() => { dispatch(openMenu("menu-profile")) }, 50)
    } else {
      setTimeout(() => { dispatch(closeMenu()) }, 180);
    }
  }

  // Member Profile Update

  const handleMemberSelect = (member) => event => {
    event.preventDefault();
    setSelectedMember(member);
    // selectedMember.current=member;
    event.stopPropagation();
  }

  const handleProfileView = (member) => {
    if(isManageProfileOpen){
      setManageProfileOpen(false);
      stopCamera();
    }else{
      setSelectedMember(member);
      setManageProfileOpen(true);
    }
  }

  const startCamera = async () => {
    try {

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: "environment" } }
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsCameraOn(true);
      }

    } catch (error) {
        console.error('Error accessing camera:', error);

        try {
          // Fallback to front camera if back camera is not available
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "user" },
          });
    
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            setIsCameraOn(true);
          }
        } catch (fallbackError) {
          console.error('Error accessing front camera:', fallbackError);
        }
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      setIsCameraOn(false);
    }
  };
  

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setImageSrc(canvas.toDataURL('image/png'));
      const blob = dataURLToBlob(canvas.toDataURL('image/png'));
      const file = new File([blob], 'captured-image.png', { type: 'image/png' });
      selectedFile=file;
      stopCamera();
    }
  };

  const dataURLToBlob = (dataURL) => {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const bytes = atob(data);
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  };

  const handleUpdateProfile = (updateType) => {
    if(updateType == "cancel"){
      setImageSrc('');
    }else{
      handleProfileView();
      // Function to update the profile image
      setImageSrc('');
    }
  }
  
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      selectedFile=file;
      setImageSrc(URL.createObjectURL(file));
    }
  }

  const SaveMemberProfile = () => {

    let formData = new FormData();
    formData.set("photo", selectedFile);
    startLoader();
    updateUserProfileImage (user._id, selectedMember._id, token, formData).then(async data => {
      if (data.error) {
        console.log(data.error);
      } else {
        setImageSrc("");
        GetAllMember(true);
        stopLoader();
        // handleProfileView();
      }
    });
  }

  const handleProfileViewOpen = () => {
    if(!isProfileViewOpen){
      setManageProfileOpen(false);
      setProfileViewOpen(true);
    }else{
      setManageProfileOpen(true);
      setProfileViewOpen(false);
    }
  }


  useEffect(async () => {
    if (selectedGym) {
      setBranchSpinnerLoader(true)
      await GetBranchCount();
      await GetAllBranches(true);
      setBranchSpinnerLoader(false)
    }
  }, [branchSearchKey, selectedGym])


  useEffect(async () => {
    try {
      setGymSpinnerLoader(true);
      await GetGymCount();
      await GetAllGyms(true);
      setGymSpinnerLoader(false);
    } catch (error) {
      setGymSpinnerLoader(false);
    }
  }, [gymSearchKey]);


  useEffect(async () => {
    await setMembers([])
    setMemberApiAction(!isMemberApiAction)
  }, [props])


  useEffect(async () => {
    try {
      await startLoader();
      await GetMemberCount();
      await GetAllMember(true);
      await stopLoader();
    } catch (error) {
      //do something 
      await stopLoader()
    }
  }, [isMembersActive, isStatusChangeOPeration, isMemberApiAction, selectedGym, selectedBranch]);

  useEffect(() => {
    window.addEventListener('click', e => {
      setMemberViewOpen(false)
      setAddMemberModal(false)
    })
  }, [])

  return (
    <Dashboard member={isMemberViewOpen ? selectedMember : ""} navItemData={"Member"} flag={flag}>
      <div className='mob-dashboard'>
        {
          isSelectedMembers.length !== 0 ? (
            <div className='profile-header-mob d-flex' style={{ boxShadow: "none" }}>
              <div onClick={toggleMemberActions} className='bell-menu-container header-menu-active d-flex flex-item m-r-12'>
                <svg className='menu flex-item' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#055075" stroke-linecap="round" />
                </svg>
              </div>
              <div className='d-flex flex-item filter-horizontal-scroll' style={{ width: "84%", height: "auto" }}>
                {
                  isSelectedMembers.length == 1 && (
                  <div onClick={toggleFitnessTakeTestModalOpen} className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-12' style={{ background: "#FFFFFF" }}>
                    <div className='d-flex'>
                      <svg className='svg-fill flex-item m-r-8' width="14" height="14" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.3702 14.0796C19.0762 11.0766 18.1072 2.80762 12.7972 0.956624C8.32718 -1.06138 2.64818 1.32462 0.93218 5.91762C-0.98582 9.23562 0.55318 13.0696 1.15918 16.5236C2.64018 20.9956 7.12718 23.2636 9.94618 26.7796C12.4742 29.3596 14.7712 32.3556 17.9082 34.2036C21.7502 35.4216 24.0362 31.2396 26.5492 29.1536C29.6352 26.2866 32.8612 23.5536 35.6742 20.4076C37.6892 16.8726 39.3782 12.9446 38.9262 8.78962C38.7142 3.85362 34.1592 -0.417377 29.1742 0.0326232C19.5612 0.616623 18.7202 14.2906 28.1052 16.1226C26.2272 17.0696 23.9592 18.3656 22.1152 18.9096C21.0252 15.0426 18.6642 10.7846 14.3702 14.0796Z" fill="#6d6d6d"/>
                        <path d="M27.3105 6.22743C29.4695 3.27943 34.4505 7.05043 31.9415 9.90443C29.6015 13.0714 24.6935 9.20143 27.3105 6.22743Z" fill="white"/>
                      </svg>
                      <p className='flex-item' style={{ whiteSpace: "nowrap" }}>Take Test</p>
                    </div>
                  </div>
                  )
                }
                <div onClick={toggleManageExecutePlanner} className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20' style={{ background: "#FFFFFF" }}>
                  <div className='d-flex'>
                    <svg className='svg-fill flex-item m-r-8' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1726_1963)">
                        <path d="M7.875 6.125C7.875 5.89294 7.96719 5.67038 8.13128 5.50628C8.29538 5.34219 8.51794 5.25 8.75 5.25H13.125V7H8.75C8.51794 7 8.29538 6.90781 8.13128 6.74372C7.96719 6.57962 7.875 6.35706 7.875 6.125ZM0.875 7.875H4.375C4.60706 7.875 4.82962 7.96719 4.99372 8.13128C5.15781 8.29538 5.25 8.51794 5.25 8.75C5.25 8.98206 5.15781 9.20462 4.99372 9.36872C4.82962 9.53281 4.60706 9.625 4.375 9.625H0.875V7.875Z" fill="#6d6d6d" />
                        <path d="M3.0625 0C3.17853 0 3.28981 0.0460936 3.37186 0.128141C3.45391 0.210188 3.5 0.321468 3.5 0.4375V0.875H10.5V0.4375C10.5 0.321468 10.5461 0.210188 10.6281 0.128141C10.7102 0.0460936 10.8215 0 10.9375 0C11.0535 0 11.1648 0.0460936 11.2469 0.128141C11.3289 0.210188 11.375 0.321468 11.375 0.4375V0.875H12.25C12.7141 0.875 13.1592 1.05937 13.4874 1.38756C13.8156 1.71575 14 2.16087 14 2.625V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184374 13.1592 0 12.7141 0 12.25V2.625C0 2.16087 0.184374 1.71575 0.512563 1.38756C0.840752 1.05937 1.28587 0.875 1.75 0.875H2.625V0.4375C2.625 0.321468 2.67109 0.210188 2.75314 0.128141C2.83519 0.0460936 2.94647 0 3.0625 0V0ZM0.875 3.5V12.25C0.875 12.4821 0.967187 12.7046 1.13128 12.8687C1.29538 13.0328 1.51794 13.125 1.75 13.125H12.25C12.4821 13.125 12.7046 13.0328 12.8687 12.8687C13.0328 12.7046 13.125 12.4821 13.125 12.25V3.5H0.875Z" fill="#6d6d6d" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1726_1963">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className='flex-item' style={{ whiteSpace: "nowrap" }}>Assign Planner</p>
                  </div>
                </div>
                <div onClick={handleBulkStatusChangeAction} className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20' style={{ background: "#FFFFFF" }}>
                  <div className='d-flex'>
                    <svg className='svg-fill svg-stroke flex-item m-r-8' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="7" cy="7" r="6.5" stroke="#6D6D6D" />
                      <rect x="4" y="9.65674" width="8" height="1" rx="0.5" transform="rotate(-45 4 9.65674)" fill="#6D6D6D" />
                    </svg>
                    <p className='flex-item' style={{ whiteSpace: "nowrap" }}>Mark {isMembersActive ? "Inactive" : "Active"}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='profile-header-mob d-flex space-between' style={{ boxShadow: "none" }}>
              {/* <svg className='flex-item m-r-8' width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49974 3.09999C5.99016 3.09999 4.76641 4.32375 4.76641 5.83333C4.76641 7.34291 5.99016 8.56666 7.49974 8.56666C9.00932 8.56666 10.2331 7.34291 10.2331 5.83333C10.2331 4.32375 9.00932 3.09999 7.49974 3.09999ZM3.56641 5.83333C3.56641 3.66101 5.32742 1.89999 7.49974 1.89999C9.67206 1.89999 11.4331 3.66101 11.4331 5.83333C11.4331 8.00565 9.67206 9.76666 7.49974 9.76666C5.32742 9.76666 3.56641 8.00565 3.56641 5.83333Z" fill="#055075" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7525 2.4595C12.8347 2.13848 13.1615 1.94488 13.4825 2.02707C14.3286 2.2437 15.0785 2.73576 15.6141 3.42568C16.1496 4.11559 16.4403 4.96412 16.4403 5.83749C16.4403 6.71086 16.1496 7.55939 15.6141 8.2493C15.0785 8.93922 14.3286 9.43128 13.4825 9.64791C13.1615 9.7301 12.8347 9.5365 12.7525 9.21548C12.6703 8.89446 12.8639 8.5676 13.1849 8.48541C13.7729 8.33487 14.294 7.99293 14.6661 7.51349C15.0383 7.03406 15.2403 6.44441 15.2403 5.83749C15.2403 5.23057 15.0383 4.64092 14.6661 4.16149C14.294 3.68205 13.7729 3.34011 13.1849 3.18957C12.8639 3.10738 12.6703 2.78052 12.7525 2.4595ZM5.83372 13.1C5.1088 13.1 4.41356 13.388 3.90097 13.9006C3.38837 14.4132 3.10039 15.1084 3.10039 15.8333V17.5C3.10039 17.8314 2.83176 18.1 2.50039 18.1C2.16902 18.1 1.90039 17.8314 1.90039 17.5V15.8333C1.90039 14.7901 2.31479 13.7897 3.05244 13.052C3.79008 12.3144 4.79054 11.9 5.83372 11.9H9.16706C10.2102 11.9 11.2107 12.3144 11.9483 13.052C12.686 13.7897 13.1004 14.7901 13.1004 15.8333V17.5C13.1004 17.8314 12.8318 18.1 12.5004 18.1C12.169 18.1 11.9004 17.8314 11.9004 17.5V15.8333C11.9004 15.1084 11.6124 14.4132 11.0998 13.9006C10.5872 13.388 9.89198 13.1 9.16706 13.1H5.83372ZM14.4194 12.475C14.5023 12.1541 14.8295 11.9612 15.1504 12.044C15.991 12.2611 16.7361 12.7501 17.2697 13.4349C17.8033 14.1197 18.0954 14.9617 18.1004 15.8299L18.1004 15.8333L18.1004 17.5C18.1004 17.8314 17.8318 18.1 17.5004 18.1C17.169 18.1 16.9004 17.8314 16.9004 17.5V15.8352C16.8966 15.2325 16.6937 14.6479 16.3232 14.1724C15.9524 13.6966 15.4345 13.3568 14.8504 13.2059C14.5296 13.1231 14.3366 12.7958 14.4194 12.475Z" fill="#055075" />
              </svg> */}
              <div className='d-flex'>
                <div className='flex-item'>
                  <p className='flex-item fs-24' style={{fontWeight:"600", lineHeight:"28px"}}>Member</p>
                  <p className='fs-12' style={{color: "#6d6d6d"}}>{Members.length} Results</p>    
                </div>
                {
                  !searchKey && (
                    <div onClick={toggleCurrentActionItem.bind(this, "Search")} className='bell-menu-container header-menu-active d-flex m-l-14' style={{alignSelf:"flex-start"}}>
                      <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                      </svg>
                    </div>
                  )
                }

                <div onClick={toggleCurrentActionItem.bind(this, "Filter")} className='bell-menu-container header-menu-active d-flex m-l-12' style={{alignSelf:"flex-start"}}>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#000000" />
                  </svg>
                </div>
              </div>
              <div className='d-flex flex-item' style={{flexDirection: 'row-reverse',alignSelf: "flex-start"}}>

                <div onClick={handleSideMenuVisibility} className="header-profile-picture d-flex m-l-16 flex-item" style={{ alignSelf: "flex-start" }}>
                    {
                      !user.profileID ? (
                        <div className="flex-item side-profile-image m-r-12">
                          <svg width="100%" height="100%" className=' side-profile-image' viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M44 24C44 35.046 35.046 44 24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24ZM30 18C30 19.5913 29.3679 21.1174 28.2426 22.2426C27.1174 23.3679 25.5913 24 24 24C22.4087 24 20.8826 23.3679 19.7574 22.2426C18.6321 21.1174 18 19.5913 18 18C18 16.4087 18.6321 14.8826 19.7574 13.7574C20.8826 12.6321 22.4087 12 24 12C25.5913 12 27.1174 12.6321 28.2426 13.7574C29.3679 14.8826 30 16.4087 30 18ZM24 41C27.4326 41.0055 30.7856 39.9669 33.614 38.022C34.822 37.192 35.338 35.612 34.634 34.326C33.18 31.66 30.18 30 24 30C17.82 30 14.82 31.66 13.364 34.326C12.662 35.612 13.178 37.192 14.386 38.022C17.2144 39.9669 20.5674 41.0055 24 41Z" fill="#9A9A9A" />
                          </svg>
                        </div>
                      ) : (
                        <img className="flex-item side-profile-image m-r-12" src={`${config.API_URL}${config.prefixStream}/media/file/${user.profileID}?${Date.now()}`} />
                      )
                    }
                    <div className="menu-indicator d-flex">
                      <svg className="flex-item" style={{ margin: "0 auto" }} width="8" height="8" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1H17" stroke="black" stroke-width="2" stroke-linecap="round" />
                        <path d="M1 5H17" stroke="black" stroke-width="2" stroke-linecap="round" />
                        <path d="M1 9H17" stroke="black" stroke-width="2" stroke-linecap="round" />
                      </svg>
                    </div>
                </div>

                {
                  isMenuAction == "menu-profile" && (
                    // <div id="mobile-bottom-modal-container-menu" onClick={handleSideMenuVisibility} className="mobile-bottom-modal-container">
                      // <div id="menu-bar-mobile-id" onClick={e => e.stopPropagation()} className="menu-bar-mobile right-slider">
                        <Menu toggleMenuVisibility={handleSideMenuVisibility} />
                      // </div>
                    // </div>
                  )
                }
                
                {/* {
                  searchKey && (
                    <div className='mob-search-box m-r-14' style={{ maxWidth: '60%' }}>
                      <div id="search-container" className='global-search-box d-flex w-full m-r-12' onClick={toggleCurrentActionItem.bind(this, "Search")}>
                        <svg id="search-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1637_1318)">
                            <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1637_1318">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <input value={searchKey} className='flex-item' type="text" />
                        <svg onClick={clearSearch} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                        </svg>
                      </div>
                    </div>
                  )
                } */}

                {/* {
                  notifications.length > 0 ? (
                    <svg className='flex-item m-l-14' width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 1.5C3.8505 1.5 1.5 3.8505 1.5 6.75V13.2578C1.5 16.1573 3.8505 18.5078 6.75 18.5078H13.2578C16.1573 18.5078 18.5078 16.1573 18.5078 13.2578V8.15316C18.5078 7.73891 18.8436 7.40312 19.2578 7.40312C19.672 7.40312 20.0078 7.73891 20.0078 8.15316V13.2578C20.0078 16.9858 16.9857 20.0078 13.2578 20.0078H6.75C3.02208 20.0078 0 16.9858 0 13.2578V6.75C0 3.02207 3.02208 0 6.75 0H11.8547C12.2689 0 12.6047 0.33579 12.6047 0.75C12.6047 1.16421 12.2689 1.5 11.8547 1.5H6.75Z" fill="#2b2b2b"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 13.5001C6.75 13.0858 7.08579 12.7501 7.5 12.7501H12.5C12.9142 12.7501 13.25 13.0858 13.25 13.5001C13.25 13.9143 12.9142 14.2501 12.5 14.2501H7.5C7.08579 14.2501 6.75 13.9143 6.75 13.5001Z" fill="#2b2b2b"/>
                      <circle cx="16.5" cy="3.5" r="3.5" fill="#FF0000"/>
                    </svg>
                  ) : (
                    <svg className='flex-item m-l-14' width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 1.5C3.8505 1.5 1.5 3.8505 1.5 6.75V13.2578C1.5 16.1573 3.8505 18.5078 6.75 18.5078H13.2578C16.1573 18.5078 18.5078 16.1573 18.5078 13.2578V8.15316C18.5078 7.73891 18.8436 7.40312 19.2578 7.40312C19.672 7.40312 20.0078 7.73891 20.0078 8.15316V13.2578C20.0078 16.9858 16.9857 20.0078 13.2578 20.0078H6.75C3.02208 20.0078 0 16.9858 0 13.2578V6.75C0 3.02207 3.02208 0 6.75 0H11.8547C12.2689 0 12.6047 0.33579 12.6047 0.75C12.6047 1.16421 12.2689 1.5 11.8547 1.5H6.75Z" fill="#2b2b2b"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 13.5001C6.75 13.0858 7.08579 12.7501 7.5 12.7501H12.5C12.9142 12.7501 13.25 13.0858 13.25 13.5001C13.25 13.9143 12.9142 14.2501 12.5 14.2501H7.5C7.08579 14.2501 6.75 13.9143 6.75 13.5001Z" fill="#2b2b2b"/>
                      <circle cx="16.5" cy="3.5" r="3.5" fill="#959595"/>
                    </svg>
                  )
                } */}

                {/* <div onClick={toggleCurrentActionItem.bind(this, "Search")} className='bell-menu-container header-menu-active d-flex flex-item m-l-14'>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                  </svg>
                </div>

                <div onClick={toggleCurrentActionItem.bind(this, "Filter")} className='bell-menu-container header-menu-active d-flex flex-item m-l-14'>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#000000" />
                  </svg>
                </div> */}

                {/* <div onClick={handleMenuVisibility} className='bell-menu-container header-menu-active d-flex'>
                  <svg className='flex-item menu' width="16" height="16" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="15" height="1" rx="0.5" fill="#2b2b2b" />
                    <rect y="4" width="7" height="1" rx="0.5" fill="#2b2b2b" />
                    <rect y="8" width="12" height="1" rx="0.5" fill="#2b2b2b" />
                  </svg>
                </div>
                <div className='bell-menu-container header-menu-active d-flex m-r-14'>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.49099 5.82435C7.78036 1.94382 6.89054 0.469739 4.09577 0.531423C3.10015 0.552775 3.33874 -0.229342 2.57946 0.0672142C1.82169 0.36377 2.47217 0.797927 1.69639 1.46933C-0.480905 3.35384 -0.280582 5.0968 0.729287 9.25095C1.15469 11.001 -0.296338 11.0864 0.277622 12.7819C0.697025 14.0188 3.78815 14.5368 7.04885 13.262C10.3103 11.9864 12.3653 9.45498 11.9459 8.21815C11.3719 6.52185 10.212 7.45817 9.49099 5.82435ZM6.69547 12.2165C3.7829 13.3553 1.38953 12.6863 1.28824 12.3881C1.11418 11.8741 2.22833 10.1612 5.55805 8.85871C8.88777 7.55623 10.7462 8.03942 10.9405 8.61276C11.0553 8.95202 9.60878 11.0769 6.69547 12.2165ZM5.75912 9.4534C4.23682 10.0489 3.17893 10.7298 2.49318 11.3427C2.97561 11.8045 3.87894 11.9168 4.77852 11.5649C5.92419 11.1181 6.62869 10.0892 6.35034 9.26835L6.33834 9.23988C6.15002 9.30473 5.9572 9.37511 5.75912 9.4534Z" fill="#2b2b2b" />
                  </svg>
                </div>
                {(!searchKey) ? <div onClick={toggleCurrentActionItem.bind(this, "Search")} className='bell-menu-container header-menu-active d-flex m-r-14'>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                  </svg>
                </div> : (
                  <div className='mob-search-box m-r-14' style={{ maxWidth: '60%' }}>
                    <div id="search-container" className='global-search-box d-flex w-full m-r-12' onClick={toggleCurrentActionItem.bind(this, "Search")}>
                      <svg id="search-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1637_1318)">
                          <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1637_1318">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <input value={searchKey} className='flex-item' type="text" />
                      <svg onClick={clearSearch} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                      </svg>
                    </div>

                  </div>
                )} */}
              </div>
            </div>
            // <div>
            //   <div onClick={backToPrevSearchState} className='bell-menu-container header-menu-active flex-item d-flex'>
            //     <svg className='bell flex-item' width="10" height="17" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            //       <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#6D6D6D" stroke-linecap="round" />
            //     </svg>
            //   </div>
            // </div>
          )
        }
        <div className='w-full d-flex space-between' style={{ height: "30px" }}>

          {
            !isAllMembersChecked ? (
              <div className='d-flex flex-item' style={{ width: "100%" }}>
                <div className='d-flex flex-item filter-horizontal-scroll' style={{ width: "100%" }}>
                  {
                    isMembersActive && (
                      <div className='d-flex filter-chips'>
                        <p className='flex-item'>Active Members</p>
                      </div>
                    )
                  }

                  {
                    !isMembersActive && (
                      <div className='d-flex filter-chips'>
                        <p className='flex-item'>In-Active Members</p>
                      </div>
                    )
                  }

                  {
                    selectedGym && (
                      <div className='d-flex filter-chips'>
                        <p className='flex-item'>Gym : {selectedGym.name}</p>
                      </div>
                    )
                  }

                  {
                    selectedBranch && (
                      <div className='d-flex filter-chips'>
                        <p className='flex-item'>Branch : {selectedBranch.name}</p>
                      </div>
                    )
                  }

                  {
                    searchKey && (
                      <div className='d-flex filter-chips'>
                        <p className='flex-item m-r-8'>{searchKey}</p>
                        <svg onClick={clearSearch} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                        </svg>
                      </div>
                    )
                  }
                  {/* <div id="search-container" className='global-search-box d-flex w-full m-r-12' onClick={toggleCurrentActionItem.bind(this, "Search")}>
                        <svg id="search-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1637_1318)">
                            <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1637_1318">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <input value={searchKey} className='flex-item' type="text" />
                        <svg onClick={clearSearch} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                        </svg>
                      </div> */}

                  {/* <div className='d-flex filter-chips'>
                    <p className='flex-item'>Test</p>
                  </div>
                  <div className='d-flex filter-chips'>
                    <p className='flex-item'>New Test</p>
                  </div>
                  <div className='d-flex filter-chips'>
                    <p className='flex-item'>New 1 Test</p>
                  </div> */}
                </div>
              </div>
            ) : (
              <div className='d-flex flex-item m-r-12'>
                <svg className='flex-item m-r-12' width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 16.5C7.9625 16.5 6.9875 16.303 6.075 15.909C5.1625 15.5155 4.36875 14.9812 3.69375 14.3062C3.01875 13.6312 2.4845 12.8375 2.091 11.925C1.697 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.697 6.9875 2.091 6.075C2.4845 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48425 6.075 2.09025C6.9875 1.69675 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69675 11.925 2.09025C12.8375 2.48425 13.6312 3.01875 14.3062 3.69375C14.9812 4.36875 15.5155 5.1625 15.909 6.075C16.303 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.303 11.0125 15.909 11.925C15.5155 12.8375 14.9812 13.6312 14.3062 14.3062C13.6312 14.9812 12.8375 15.5155 11.925 15.909C11.0125 16.303 10.0375 16.5 9 16.5ZM9 15C10.675 15 12.0937 14.4187 13.2562 13.2562C14.4187 12.0937 15 10.675 15 9C15 8.325 14.8905 7.675 14.6715 7.05C14.453 6.425 14.1375 5.85 13.725 5.325L5.325 13.725C5.85 14.1375 6.425 14.453 7.05 14.6715C7.675 14.8905 8.325 15 9 15ZM4.275 12.675L12.675 4.275C12.15 3.8625 11.575 3.547 10.95 3.3285C10.325 3.1095 9.675 3 9 3C7.325 3 5.90625 3.58125 4.74375 4.74375C3.58125 5.90625 3 7.325 3 9C3 9.675 3.1095 10.325 3.3285 10.95C3.547 11.575 3.8625 12.15 4.275 12.675V12.675Z" fill="#055075" />
                </svg>
                <svg className='flex-item' width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.25065 6.56249V9.32249C8.25065 9.58499 8.39315 9.83249 8.61815 9.96749L10.9582 11.355C11.2282 11.5125 11.5732 11.4225 11.7307 11.16C11.8882 10.89 11.8057 10.545 11.5357 10.3875L9.38315 9.10499V6.55499C9.37565 6.25499 9.12065 5.99999 8.81315 5.99999C8.50565 5.99999 8.25065 6.25499 8.25065 6.56249ZM15.7507 7.12499V3.15749C15.7507 2.81999 15.3457 2.65499 15.1132 2.89499L13.7782 4.22999C13.0756 3.5274 12.2268 2.98838 11.2921 2.65133C10.3574 2.31428 9.35992 2.18749 8.37065 2.27999C5.22815 2.56499 2.64065 5.09249 2.29565 8.23499C2.11328 9.99702 2.63089 11.7601 3.73681 13.1439C4.84272 14.5278 6.44831 15.4214 8.20718 15.632C9.96606 15.8426 11.7372 15.3534 13.1386 14.2698C14.54 13.1863 15.4593 11.5953 15.6982 9.83999C15.7507 9.38999 15.3982 8.99999 14.9482 8.99999C14.5732 8.99999 14.2582 9.27749 14.2132 9.64499C13.8907 12.2625 11.6332 14.2875 8.92565 14.25C6.14315 14.2125 3.79565 11.865 3.75065 9.07499C3.70565 6.14999 6.08315 3.74999 9.00065 3.74999C10.4482 3.74999 11.7607 4.34249 12.7132 5.28749L11.1457 6.85499C10.9057 7.09499 11.0707 7.49999 11.4082 7.49999H15.3757C15.5857 7.49999 15.7507 7.33499 15.7507 7.12499Z" fill="#055075" />
                </svg>
              </div>
            )
          }
        </div>
        <div className='mobile-dashboard-scroll-container' onScroll={handleMemberScroll}>
          {
            Members.map((member) => {
              return (
                <div {...LongPress(member._id)} onClick={isLongPressActivated ? handleSelectMemberID(member._id) :onSelectMember(member)} className='dashboard-card space-between p-t-12 p-b-12 d-flex' style={{ background: Boolean(isSelectedMembers.find(data => data == member._id)) ? "#ffffff" : "#f5f5f5" }}>
                  <div className='d-flex'>
                    
                    <div onClick={e => e.stopPropagation()}>
                      {
                        member.user.length > 0 && (
                          member.user[0].profileID ? (
                            <img onClick={() => handleProfileView(member)} className='flex-item card-profile-image' src={`${config.API_URL}/image/file/${member.user[0].profileID}/auto/auto/70?${Date.now()}`} />
                          ) : (
                            <div onClick={() => handleProfileView(member)} className='d-flex flex-item card-profile-image' style={{
                              backgroundColor: member.user[0].profilePlaceHolderColor ? member.user[0].profilePlaceHolderColor : '#5d883c'
                            }}>
                              <p className='flex-item' style={{ color: '#ffffff', fontSize: 14, margin: '0 auto' }}>{`${member.userInfo.name}`.trim()[0]}</p>
                            </div>
                          )
                        )
                      }
                    </div>

                    <div className='flex-item'>
                      <p className='flex-item gym-name m-b-2'>{member.userInfo.name}</p>
                      <p className='flex-item gym-address'>{member.userInfo.email}</p>
                    </div>
                  </div>
                  {
                    Boolean(isSelectedMembers.find(data => data == member._id)) ? (
                      <div className='more-action d-flex'>
                        {user.role < 8 && [(user.role < 3 || user.role == 5 || user.role == 6) && <svg onClick={handleStatusChangeAction(member)} className='flex-item m-r-12' width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 16.5C7.9625 16.5 6.9875 16.303 6.075 15.909C5.1625 15.5155 4.36875 14.9812 3.69375 14.3062C3.01875 13.6312 2.4845 12.8375 2.091 11.925C1.697 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.697 6.9875 2.091 6.075C2.4845 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48425 6.075 2.09025C6.9875 1.69675 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69675 11.925 2.09025C12.8375 2.48425 13.6312 3.01875 14.3062 3.69375C14.9812 4.36875 15.5155 5.1625 15.909 6.075C16.303 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.303 11.0125 15.909 11.925C15.5155 12.8375 14.9812 13.6312 14.3062 14.3062C13.6312 14.9812 12.8375 15.5155 11.925 15.909C11.0125 16.303 10.0375 16.5 9 16.5ZM9 15C10.675 15 12.0937 14.4187 13.2562 13.2562C14.4187 12.0937 15 10.675 15 9C15 8.325 14.8905 7.675 14.6715 7.05C14.453 6.425 14.1375 5.85 13.725 5.325L5.325 13.725C5.85 14.1375 6.425 14.453 7.05 14.6715C7.675 14.8905 8.325 15 9 15ZM4.275 12.675L12.675 4.275C12.15 3.8625 11.575 3.547 10.95 3.3285C10.325 3.1095 9.675 3 9 3C7.325 3 5.90625 3.58125 4.74375 4.74375C3.58125 5.90625 3 7.325 3 9C3 9.675 3.1095 10.325 3.3285 10.95C3.547 11.575 3.8625 12.15 4.275 12.675V12.675Z" fill="#9A9A9A" />
                        </svg>,
                        (user.role < 3 || user.role == 5 || user.role == 6) && <svg onClick={handleUpdate(member)} className='flex-item' width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.25065 6.56249V9.32249C8.25065 9.58499 8.39315 9.83249 8.61815 9.96749L10.9582 11.355C11.2282 11.5125 11.5732 11.4225 11.7307 11.16C11.8882 10.89 11.8057 10.545 11.5357 10.3875L9.38315 9.10499V6.55499C9.37565 6.25499 9.12065 5.99999 8.81315 5.99999C8.50565 5.99999 8.25065 6.25499 8.25065 6.56249ZM15.7507 7.12499V3.15749C15.7507 2.81999 15.3457 2.65499 15.1132 2.89499L13.7782 4.22999C13.0756 3.5274 12.2268 2.98838 11.2921 2.65133C10.3574 2.31428 9.35992 2.18749 8.37065 2.27999C5.22815 2.56499 2.64065 5.09249 2.29565 8.23499C2.11328 9.99702 2.63089 11.7601 3.73681 13.1439C4.84272 14.5278 6.44831 15.4214 8.20718 15.632C9.96606 15.8426 11.7372 15.3534 13.1386 14.2698C14.54 13.1863 15.4593 11.5953 15.6982 9.83999C15.7507 9.38999 15.3982 8.99999 14.9482 8.99999C14.5732 8.99999 14.2582 9.27749 14.2132 9.64499C13.8907 12.2625 11.6332 14.2875 8.92565 14.25C6.14315 14.2125 3.79565 11.865 3.75065 9.07499C3.70565 6.14999 6.08315 3.74999 9.00065 3.74999C10.4482 3.74999 11.7607 4.34249 12.7132 5.28749L11.1457 6.85499C10.9057 7.09499 11.0707 7.49999 11.4082 7.49999H15.3757C15.5857 7.49999 15.7507 7.33499 15.7507 7.12499Z" fill="#9A9A9A" />
                        </svg>]}
                      </div>
                    ) : (
                      <svg className='flex-item m-r-4' width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round" />
                      </svg>
                    )
                  }
                </div>
              )
            })
          }
          {isMemberSpinnerLoader && <div className="loader-5 center"><span></span></div>}
        </div>

        {
          isMenuOpen && (
            // <div id="mobile-bottom-modal-container-menu" onClick={handleMenuVisibility} className="mobile-bottom-modal-container">
              // <div id="menu-bar-mobile-id" onClick={e => e.stopPropagation()} className="menu-bar-mobile right-slider">
                <MenuMob toggleMenuVisibility={handleMenuVisibility} />
              // </div>
            // </div>
          )
        }

        <FloatingMenu />
      </div>

      {
        isStatusChangeModalOpen && <ManageConfirmationModal isMobile={true} handleAction={handleAction} title={isBulkStatusChangeAction ? `${isAllMembersChecked ? Members.length : isSelectedMembers.length} member(s) selected` : selectedMember.name} message="By performing this action all the user's under this member will get effected" buttonText={`Mark ${isBulkStatusChangeAction ? (isMembersActive ? "inactive" : "active") : (selectedMember.deletedAt == null ? "inactive" : "active")}`} handleClose={handleStatusChangeAction("")} />
      }


      {
        isManageProfileOpen && (
          <div className='global-modal-container d-flex'>
            <div className="profile-edit-modal flex-item">
              <div className="d-flex w-full space-between" style={{background: "#DEF3FF", padding:"12px", borderRadius: "8px 8px 0 0"}}>
                <p className="flex-item modal-header-text">Edit profile</p>
                <div onClick={() => handleProfileView()} className="d-flex desktop-modal-close-button" style={{width: "30px", height:"30px", background:"#f9f9f9"}}>
                  <svg className="flex-item" width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.9283 3.80866C4.77899 3.66445 4.57901 3.58465 4.37144 3.58646C4.16387 3.58826 3.96531 3.67152 3.81853 3.8183C3.67175 3.96508 3.58849 4.16364 3.58668 4.37121C3.58488 4.57879 3.66468 4.77876 3.80888 4.92807L8.38076 9.49995L3.80809 14.0718C3.73248 14.1449 3.67217 14.2322 3.63068 14.3288C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7395 3.58555 14.8437 3.62536 14.941C3.66516 15.0383 3.72395 15.1267 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4338 4.36495 15.4329C4.47007 15.432 4.57395 15.4101 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1912L9.50018 10.6194L14.0721 15.1912C14.2214 15.3354 14.4213 15.4152 14.6289 15.4134C14.8365 15.4116 15.035 15.3284 15.1818 15.1816C15.3286 15.0348 15.4119 14.8363 15.4137 14.6287C15.4155 14.4211 15.3357 14.2211 15.1915 14.0718L10.6196 9.49995L15.1915 4.92807C15.3357 4.77876 15.4155 4.57879 15.4137 4.37121C15.4119 4.16364 15.3286 3.96508 15.1818 3.8183C15.035 3.67152 14.8365 3.58826 14.6289 3.58646C14.4213 3.58465 14.2214 3.66445 14.0721 3.80866L9.50018 8.38053L4.9283 3.80786V3.80866Z" fill="black" />
                  </svg>
                </div>
              </div>
              <div className='w-full h-full' style={{padding: "20px", display: isCameraOn ? "block" : "none"}}>
                <video ref={videoRef} autoPlay style={{ width: '300px', height: '200px', display: isCameraOn ? "block" : "none"}}></video>
                <div className='d-flex w-full m-t-20'>
                  <div className='flex-item d-flex margin-auto'>
                    <div onClick={stopCamera} className="rectangle-round-btn d-flex m-r-12" style={{background:"#6d6d6d", padding: "6px 20px", position:"relative"}}>
                      <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Cancel</p>
                    </div>
                    <div onClick={captureImage} className="rectangle-round-btn d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                      <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Capture Image</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full h-full' style={{padding: "20px", display: isCameraOn ? "none" : "block"}}>
                <div className='d-flex w-full'>
                  <div className='profile-edit-image bg-green d-flex margin-auto'>
                    {imageSrc ? (
                      <img src={imageSrc} alt="Captured" />
                    ) : (selectedMember.user && selectedMember.user[0].profileID) ? [
                        console.log(selectedMember),
                        <img id="gym-ImgWrapper" src={`${config.API_URL}/image/file/${selectedMember.user[0].profileID}/auto/auto/100?${Date.now()}`} loading='lazy' />,
                        <div onClick={() => handleProfileViewOpen()} className='eye-view d-flex cursor-pointer'>
                          <svg className='flex-item margin-auto' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 13C6.6 5 17.4 5 21 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 17C11.606 17 11.2159 16.9224 10.8519 16.7716C10.488 16.6209 10.1573 16.3999 9.87868 16.1213C9.6001 15.8427 9.37913 15.512 9.22836 15.1481C9.0776 14.7841 9 14.394 9 14C9 13.606 9.0776 13.2159 9.22836 12.8519C9.37913 12.488 9.6001 12.1573 9.87868 11.8787C10.1573 11.6001 10.488 11.3791 10.8519 11.2284C11.2159 11.0776 11.606 11 12 11C12.7956 11 13.5587 11.3161 14.1213 11.8787C14.6839 12.4413 15 13.2044 15 14C15 14.7956 14.6839 15.5587 14.1213 16.1213C13.5587 16.6839 12.7956 17 12 17Z" stroke="#6d6d6d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                    ] : (
                        <p className='flex-item margin-auto font-style-4'>{`${selectedMember.user[0].name}`.trim()[0]}</p>
                      )
                    }
                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

                  </div>
                </div>
                <div className='w-full d-flex m-t-12'>
                  <p className='margin-auto font-style-1 font-weight-600 color-grey-02'>Hello, {selectedMember.user[0].name}</p>
                </div>
                <div className='m-t-20 m-b-20' style={{width:"100%", height:"1px", background:"#f5f5f5"}} />
                <div className='d-flex'>
                  {
                    imageSrc ? (
                      <div className='d-flex flex-item margin-auto'>
                        <div onClick={() => handleUpdateProfile("cancel")} className="rectangle-round-btn d-flex m-r-12" style={{background:"#6d6d6d", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Cancel</p>
                        </div>
                        <div onClick={() => SaveMemberProfile()} className="rectangle-round-btn d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Update Profile</p>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex flex-item margin-auto'>
                        <div onClick={startCamera} className="rectangle-round-btn m-r-12 d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Capture Image</p>
                        </div>
                        <div className="rectangle-round-btn d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Upload</p>
                          <input className='cursor-pointer' style={{width:"100%", height: "100%", zIndex: 2, position:"absolute", top: 0}} onChange={handleImageUpload} type="file" id="imageInput" name="image" accept="image/*"/>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
          
            </div>
          </div>
        ) 
      }

      {
        isProfileViewOpen && (
          <div onClick={() => handleProfileViewOpen()} className='global-modal-container d-flex'>
            <img className="profile-view-modal flex-item" src={`${config.API_URL}/image/file/${selectedMember.user[0].profileID}/auto/auto/100?${Date.now()}`} />
          </div>
        )
      }


      {
        currentActionItem == "Search" && (
          <div onClick={closeSearchOption} className='mob-search-container'>
            <div onClick={e => e.stopPropagation()} className='mob-search-box' style={{height:"auto"}}>
              <div id="search-container" className='global-search-box d-flex w-full m-r-12'>
                <svg id="search-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1637_1318)">
                    <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1637_1318">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input id="search-member-input" value={searchKey} onChange={handleSearchKey} className='flex-item' type="text" placeholder="Search Member" onKeyDown={onKeyDown} />
                <svg onClick={clearSearch} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                </svg>
              </div>
              {/* <div className='m-t-10' style={{
                height: "92%"
              }}>
                <div className='w-full'>
                  <div onClick={RemoveAllSearchHistory} className='d-flex' style={{ float: 'right', justifyContent: 'center' }}>
                    <p className='flex-item' style={{ color: '#055075' }}>Clear All</p>
                  </div>
                </div>
                <div className='w-full' onScroll={handleSearchHistoryScroll} style={{
                  height: "95%",
                  overflowY: 'auto',
                  paddingBottom: 30
                }}>
                  {
                    searchHistories.map((data, index) => {
                      return (
                        <div className='d-flex w-full space-between' style={{ padding: "4px 8px" }}>
                          <div onClick={handleSelectSearchHistoryKey(data)} className='flex-item d-flex' style={{ width: '90%' }}>
                            <p className='flex-item'>{data.searchKey}</p>
                          </div>
                          <div className='flex-item d-flex' onClick={RemoveSearchHistory(data, index)}>
                            <svg className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                            </svg>
                          </div>
                        </div>
                      )
                    })
                  }
                  {isSearchBoxLoaderStart && <div className="loader-5 center"><span></span></div>}
                </div>
              </div> */}
            </div>
          </div>
        )
      }

      {
        currentActionItem == "Filter" && (
          <div className="mobile-bottom-modal-container" onClick={backCurrentActionItem.bind(this)}>
            <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={toggleCurrentActionItem.bind(this)} className="w-full">
                <div className="draggable-pointer" />
              </div>
              <p className='filter-heading-text-mob m-t-20 m-b-12'>Status</p>
              <div className='d-flex m-b-16'>
                <div onClick={toggleActiveInactiveButton.bind(this)} className='flex-item d-flex active-inactive-button m-r-12' style={{ background: !isMembersActive ? "rgba(255, 0, 85, 0.347)" : "" }}>
                  <div className='active-inactive-ball flex-item' style={{ background: !isMembersActive ? "rgb(201, 0, 57)" : "", margin: !isMembersActive ? "0 0 0 0" : "" }} />
                </div>
                <p className='flex-item active-inactive-txt'>{isMembersActive ? "Active" : "Inactive"}</p>
              </div>
              {
                !selectedGym && [
                  <div onClick={toggleCurrentActionItem.bind(this, "gym")} className='member-button-global btn-center d-flex m-t-16'>
                    <p className='flex-item'>Filter By Gym</p>
                  </div>
                ]
              }

              {
                selectedGym && [
                  <div className='d-flex'>
                    <div className='d-flex m-t-12'>
                      <p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
                      <div className='flex-item d-flex m-r-12'>
                        <svg onClick={toggleCurrentActionItem.bind(this, "gym")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                        </svg>
                      </div>
                      <div className='flex-item d-flex'>
                        <svg onClick={handleClearGymFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                        </svg>
                      </div>
                    </div>
                  </div>,
                  <div className="d-flex w-full m-t-8 m-b-12">
                    {
                      selectedGym.logoFileID ? (
                        <img className="flex-item m-r-8 branch-gym-logo" src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/auto/auto/70`} />
                      ) : (
                        <div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ backgroundColor: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#f5f5f5" }}>
                          <p style={{ margin: '0 auto', color: '#ffffff', fontSize: 14 }} className='flex-item'>{`${selectedGym.name}`.trim()[0]}</p>
                        </div>
                      )
                    }
                    <div className="flex-item">
                      <p className="branch-gym-name">{selectedGym.name ? selectedGym.name : ""}</p>
                      <p className="branch-gym-email">{selectedGym.email ? selectedGym.email : ""}</p>
                    </div>
                  </div>
                ]
              }

              {
                (selectedGym && !selectedBranch) && [
                  <div onClick={toggleCurrentActionItem.bind(this, "branch")} className='member-button-global btn-center d-flex m-t-20'>
                    <p className='flex-item'>Filter By Branch</p>
                  </div>
                ]
              }

              {
                selectedBranch && [
                  <div className='d-flex'>
                    <div className='d-flex m-t-20'>
                      <p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
                      <div className='flex-item d-flex m-r-12'>
                        <svg onClick={toggleCurrentActionItem.bind(this, "branch")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                        </svg>
                      </div>
                      <div className='flex-item d-flex'>
                        <svg onClick={handleClearBranchFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                        </svg>
                      </div>
                    </div>
                  </div>,
                  <div className="w-full m-t-8">
                    <p className="branch-gym-name">{selectedBranch.name}</p>
                    <p className="branch-gym-email">{selectedBranch.address ? selectedBranch.address.placeName ? selectedBranch.address.placeName : selectedBranch.address.placeAddress ? selectedBranch.address.placeAddress : selectedBranch.address : ""}</p>
                  </div>
                ]
              }
            </div>
          </div>
        )
      }

      {
        currentActionItem == "gym" && (
          <div className="mobile-bottom-modal-container" onClick={backCurrentActionItem.bind(this)}>
            <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={toggleCurrentActionItem.bind(this)} className="w-full">
                <div className="draggable-pointer" />
              </div>
              <div className='d-flex space-between m-b-12'>
                {
                  !isGymFilterSearchOpen ? [
                    <p className='filter-heading-text-mob flex-item fs-16 m-l-8'>Select Gym</p>,
                    <div onClick={toggleSearchFilterGym.bind(this)} className='bell-menu-container header-menu-active flex-item d-flex'>
                      <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                      </svg>
                    </div>
                  ] : [
                    <div id="search-container" className='global-search-box d-flex w-full m-r-12' style={{ background: "#f5f5f5" }}>
                      <svg id="search-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1637_1318)">
                          <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1637_1318">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <input id="search-gym-input" onChange={_.debounce(handleGymSearch, 1000)} style={{ background: "#f5f5f5" }} className='flex-item' type="text" placeholder="Search Gym" />
                      <svg onClick={toggleSearchFilterGym.bind(this)} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                      </svg>
                    </div>
                  ]
                }
              </div>
              <div className='search-gym-modal-scroll-mob' onScroll={handleGymScroll}>
                {
                  Gyms.map(data => {
                    return (
                      <div className="d-flex suggestion-list-items" onClick={handleSelectGym(data)}>
                        {
                          data.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${data.logoFileID}/auto/auto/70`} />
                          ) : (
                            <div className="flex-item branch-gym-user-profile m-r-8 d-flex" style={{
                              backgroundColor: data.logoPlaceholderColor ? data.logoPlaceholderColor : '#5d883c'
                            }}>
                              <p className='flex-item' style={{
                                color: '#ffffff', fontSize: 14, margin: '0 auto'
                              }}>{`${data.name}`.trim()[0]}</p>
                            </div>
                          )
                        }

                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.email}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {isGymSpinnerLoader && <div className="loader-5 center"><span></span></div>}
              </div>
            </div>
          </div>
        )
      }

      {
        currentActionItem == "branch" && (
          <div className="mobile-bottom-modal-container" onClick={backCurrentActionItem.bind(this)}>
            <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={toggleCurrentActionItem.bind(this)} className="w-full">
                <div className="draggable-pointer" />
              </div>
              <div className='d-flex space-between m-b-12'>
                {
                  !isBranchFilterSearchOpen ? [
                    <p className='filter-heading-text-mob flex-item fs-16 m-l-8'>Select Branch</p>,
                    <div onClick={toggleSearchFilterBranch.bind(this)} className='bell-menu-container header-menu-active flex-item d-flex'>
                      <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                      </svg>
                    </div>
                  ] : [
                    <div id="search-branch-container" className='global-search-box d-flex w-full m-r-12' style={{ background: "#f5f5f5" }}>
                      <svg id="search-branch-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1637_1318)">
                          <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1637_1318">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <input id="search-branch-input" onChange={_.debounce(handleBranchSearch, 1000)} style={{ background: "#f5f5f5" }} className='flex-item' type="text" placeholder="Search Gym" />
                      <svg onClick={toggleSearchFilterBranch.bind(this)} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                      </svg>
                    </div>
                  ]
                }
              </div>
              <div className='search-gym-modal-scroll-mob' onScroll={handleBranchScroll}>
                {
                  Branches.map(data => {
                    return (
                      <div className="d-flex suggestion-list-items" onClick={handleSelectBranch(data)}>
                        {
                          selectedGym.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/auto/auto/70`} />
                          ) : (
                            <div className='flex-item d-flex branch-gym-user-profile m-r-8' style={{
                              backgroundColor: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : '#5d883c'
                            }}>
                              <p className='flex-item' style={{
                                color: '#ffffff', margin: '0 auto', fontSize: 14
                              }}>
                                {`${selectedGym.name}`.trim()[0]}
                              </p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.address ? data.address.placeName ? data.address.placeName : data.address.placeAddress ? data.address.placeAddress : data.address : ""}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {isBrachSpinnerLoader && <div className="loader-5 center"><span></span></div>}
              </div>
            </div>
          </div>
        )
      }


      {
        isAddMemberModal && <ManageMemberModal
          isEdit={true} member={{ ...selectedMember }}
          toggleMemberModal={toggleAddMemberModal.bind(this)} />
      }


      {
        isPlannerExecutionModalOpen && (
          <ManageExecutePlanner toggleManageExecutePlanner={toggleManageExecutePlanner} members={Members.every(d=>isSelectedMembers.indexOf(d._id)!=-1) ? Members : isSelectedMembers.length > 0 ? Members.filter(member => isSelectedMembers.indexOf(member._id) != -1) : []} />
        )
      }

      {
        isFitnessTakeTestModalOpen && (
          <SwipeableDrawer anchor={"bottom"} open={drawerState.bottom} disableBackdropTransition={!iOS} disableDiscovery={iOS} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
            <div className="mobile-bottom-modal" style={{ position: "relative" }} onClick={e => e.stopPropagation()} onKeyDown={toggleDrawer(true)}>
              <div className="w-full" onClick={toggleDrawer(true)}>
                <div className="draggable-pointer"/>
              </div>
              <ManageFitnessTest member={selectedMember} toggleFitnessTestModal={toggleDrawer(false)} />
            </div>
          </SwipeableDrawer>
        )
      }

    </Dashboard>
  )
}