import React, {useState, useEffect} from 'react';
import NoDataFound from "../../assets/no_data.png";
import FloatingMenu from '../floatingMenu';
import Dashboard from '../../core/Dashboard';


export default function UserNotification() {

  const [myReportType, SetMyReportType] = useState('body-composition');
  const [reportItemModal, SetReportItemModal] = useState('');
  const [notification, SetNotification] = useState(1);
  const [activeNotification, SetActiveNotification] = useState(1);

  const handleCurrentReportType = (currentReportType) => {
    SetMyReportType(currentReportType)
  }

  const handleReportItem = () => {
    if(reportItemModal == false){
      SetReportItemModal(true)
    }else{
      SetReportItemModal(false)
    }
  }

  return(
    <Dashboard flag={0} navItemData={"Notification"} >
      <div className='mob-dashboard'>
        <div className='profile-body m-t-12 w-full d-flex space-between'>
          {/* <p className="welcome-msg">Notification</p> */}
          <p className='flex-item fs-18' style={{fontWeight:"600", lineHeight:"22px"}}>Notification</p>
          {
            activeNotification > 0 && (
              <div className='flex-item d-flex'>
                <svg className='flex-item m-r-8' width="20" height="20" viewBox="0 0 46 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 14L12 24.5L32.5 1" stroke="#055075" stroke-width="3" stroke-linecap="round"/>
                  <path d="M13.5 14L24 24.5L44.5 1" stroke="#055075" stroke-width="3" stroke-linecap="round"/>
                </svg>
                <p className='flex-item' style={{lineHeight: "16px", fontSize: "12px", color:"#055075", fontWeight: 600}}>Mark All As Read</p>
              </div>
            )
          }
          {/* <p className='flex-item mark-read'>Mark as Read</p> */}
        </div>
        {
          notification > 0 ? (
            <div className='mobile-dashboard-scroll-container m-t-8 p-l-0 p-r-0'>
              <div className='w-full m-t-12'>
                <div className='w-full notification-tile notification-unread-tile space-between d-flex'>
                  <div className='d-flex' style={{width:"85%"}}>
                    <div className='notification-unread-dot m-t-4 m-r-8'/>
                    <div style={{width:"96%"}}>
                      <p className='notification-heading'>Notification Heading Notification Heading Notification Heading</p>
                      <p className='notification-timing m-t-4'>Jul 23, 2021 at 09:15 AM</p>
                    </div>
                  </div>
                  <div className='notification-type-container d-flex' style={{alignSelf:"flex-start"}}>
                  </div>
                </div> 
                <div className='notification-separator' />
                <div className='w-full notification-tile notification-unread-tile d-flex'>
                  <div className='notification-unread-dot m-t-4 m-r-8'/>
                  <div style={{width:"80%"}}>
                    <p className='notification-heading'>Notification Heading</p>
                    <p className='notification-timing m-t-4'>Jul 23, 2021 at 09:15 AM</p>
                  </div>
                  <div className='notification-type-container d-flex' style={{alignSelf:"flex-start"}}>
                  </div>
                </div> 
                <div className='notification-separator' />
                <div className='w-full notification-tile d-flex'>
                  <div className='notification-read-dot m-t-4 m-r-8'/>
                  <div style={{width:"80%"}}>
                    <p className='notification-heading'>Notification Heading Notification Heading Notification Heading</p>
                    <p className='notification-timing m-t-4'>17 hours ago</p>
                  </div>
                  <div className='notification-type-container d-flex' style={{alignSelf:"flex-start"}}>
                  </div>
                </div> 
                <div className='notification-separator' />
                <div className='w-full notification-tile d-flex'>
                  <div className='notification-read-dot m-t-4 m-r-8'/>
                  <div style={{width:"80%"}}>
                    <p className='notification-heading'>Notification Heading Notification Heading</p>
                    <p className='notification-timing m-t-4'>23 hours ago</p>
                  </div>
                  <div className='notification-type-container d-flex' style={{alignSelf:"flex-start"}}>
                  </div>
                </div> 
              </div>
            </div>
          ) : (
            <div className='w-full d-flex m-t-40'>
              <div className='flex-item' style={{margin: "0 0 0 3%"}}>
                <img className="flex-item" style={{ width: "95%", margin: "0 auto"}} src={NoDataFound} />
                <div className='w-full' style={{margin: "20px auto 0 auto", textAlign:"center"}}>
                  <p className='fs-14' style={{lineHeight:"18px", color:"#055075"}}>No Notification Available</p>
                </div>
              </div>
            </div>
          )
        }
        <FloatingMenu />
      </div>
    </Dashboard>
  )
}
