import React, { useEffect, useState, useRef } from 'react';
import Dashboard from '../core/Dashboard';
import { getAllBranches, getBranchCount } from '../branch/helper/api';
import { isAuthenticated } from '../auth';
import { getAllGym, getGymCount } from '../gym/helper/api';
import ManageExecutePlannerDesktop from '../components/Modal/desktop/ManageExecutePlanner';
import _ from 'lodash';
import FilterIcon from "../assets/filter.svg";
import ArrowLeft from "../assets/arrow-left.svg";
import ArrowRight from "../assets/arrow-right.svg";
import SwapIcon from "../assets/swap.svg";
import ArrowUp from "../assets/arrow-sign.svg";
import BlockIcon from "../assets/block.svg";
import UpdateIcon from "../assets/edit.svg";
import { useHistory } from 'react-router-dom'
import { changeMemberStatus, enrollGosMemberToBioMetric, getAllMembers, getMemberCount, syncMemberWithGos, updateMemberMembershipData, updateUserProfileImage } from './helper/api';
import { getUserInfo } from '../user/helper/api';
import { formatDateTime, startLoader, stopLoader } from '../lib/utils';
import * as config from '../config';
import ManageMemberModal from '../components/Modal/desktop/ManageMember';
import ManageConfirmationModal from '../components/Modal/ConfirmModal';
import ManageFitnessTest from '../components/Modal/desktop/ManageFitnessTest';
import Link from 'react-router-dom/Link';
import Toast from '../lib/toast';

let selectedFile;

export default function Member(props) {
  const pageSize = {
    branch: 30,
    gym: 30,
    member: 30
  };
  const [pageCount, setPageCount] = useState({
    branch: 0,
    gym: 0,
    member: 0
  });
  var [pageNo, setPageNo] = useState({
    branch: 0,
    gym: 0,
    member: 0
  })
  const [total, setTotal] = useState({
    gym: 0,
    branch: 0,
    member: 0
  });
  const [isMemberActionDropdownOpenId, setMemberActionDropdownOpenId] = useState("");
  const [isStatusChangeOPeration, setStatusChangeOPeration] = useState(false);
  const selectedMember = useRef('');
  const [selectedGym, setSelectedGym] = useState('');
  const [Members, setMembers] = useState([]);//save members after fetched from the server
  const flag = 0;
  const [gosDeviceList,setGosDeviceList] = useState([])
  const [mapAction, setmapAction] = useState(0);
  const [Gyms, setGyms] = useState([]);
  const [Branches, setBranches] = useState([]);
  const [isMemberFilterListOpen, setMemberFilterOpen] = useState(false);
  const [isFilterModalOpen, setFilterModalOpen] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [isAddMemberModalOpen, setAddUserModalOpen] = useState(false);
  const [isBulkStatusChangeAction, setBulkStatusChangeAction] = useState(false)
  const [isMemberActivationModalOpen, setMemberActivationModalOpen] = useState(false);
  const [isMembersActive, setMembersActive] = useState(true);
  const [selectedMemberIds, setSelectedMemberIds] = useState([])
  const [searchKey, setSearchKey] = useState("");
  const [gymSearchKey, setGymSearchKey] = useState("");
  const [isGymSpinnerLoader, setGymSpinnerLoader] = useState(false);
  const [isBrachSpinnerLoader, setBranchSpinnerLoader] = useState(false);
  var [branchSearchKey, setBranchSearchKey] = useState("");
  var [isSearchBranch, setSearchBranch] = useState(false);
  const [isDesktopPlannerExecutionModalOpen, setPlannerExecutionModalOpen] = useState(false);
  const [isFitnessTestModalOpen, setFitnessTestModalOpen] = useState(false);
  const [isManageProfileOpen, setManageProfileOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isProfileViewOpen, setProfileViewOpen] = useState(false);
  const [isButtonLock,setButtonLock] = useState(false);
  const [isBranchFilterType, setBranchFilterType] = useState("");
  const [isItemCopy, setItemCopy] = useState({
    memberId: "",
    copyType: "",
    copied: false
  });
  const [isMemberSyncModalOpen, setMemberSyncModalOpen] = useState(false);
  const [currentElementId, setCurrentElementId] = useState("");
  const [userPhoneNo, setUserPhoneNo] = useState("");
  const [isSuccessMessage, setSuccessMessage] = useState(false);
  const [lastSync, setLastSync] = useState(new Date('2024-08-24T16:06:20'));
  const [currentActionType, setCurrentActionType] = useState("");
  const [getAllBiometricDevices, setAllBiometricDevices] = useState([]);
  //API functions
  const { user, token } = isAuthenticated();



  const handleBranchSearch = event => {
    branchSearchKey = event.target.value;
    setBranchSearchKey(branchSearchKey);
    setBranches([])
    isSearchBranch = true
    setSearchBranch(isSearchBranch)
  }

  const handleClearBranchFilter = () => {
    setSelectedBranch("")
  }

  const handleBranch = branch => async event => {
    event.preventDefault()
    setSelectedBranch(branch)
    handleFilterModalOpen()
    event.stopPropagation()
  }


  const handleBranchScroll = async event => {
    try {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.branch < pageCount.branch && !isBrachSpinnerLoader) {
        setBranchSpinnerLoader(true)
        pageNo.branch = pageNo.branch + 1;
        setPageNo({
          ...pageNo
        });
        await GetAllBranches();
        setBranchSpinnerLoader(false)
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
    }
  }


  const handleClearGymFilter = () => {
    setSelectedGym("");
    setSelectedBranch("")
    setBranches([])
  }

  const handleGymSelect = gym => async event => {
    event.preventDefault()
    try {
      setSelectedGym(gym);
      setSelectedBranch("")
      setBranches([])
      handleFilterModalOpen()
    } catch (error) {
      // do something
    }
    event.stopPropagation()
  }

  const handleGymScroll = async event => {
    try {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.gym < pageCount.gym && !isGymSpinnerLoader) {
        setGymSpinnerLoader(true)
        pageNo.gym = pageNo.gym + 1;
        setPageNo({
          ...pageNo
        });
        await GetAllGyms();
        setGymSpinnerLoader(false)
      }
    } catch (error) {
      setGymSpinnerLoader(false)
    }
  }

  const SyncMemberWithGos = async () =>{
    try{

      await startLoader()
      setButtonLock(true)
      const response = await syncMemberWithGos(user._id,token,selectedBranch._id,selectedBranch.gosInfo.gym_slug);
      if(response.error){
        new Toast({
            message:response.error,
            duration:3000,
            type:'error'
        })
      }else{
        new Toast({
            message:"Gos member successfully synched",
            duration:3000,
            type:'success'
        })
        await GetMemberCount();
        await GetAllMember();
      }
      setButtonLock(false)
      await stopLoader();

    }catch(error){
      new Toast({
        message:JSON.stringify(error),
        duration:3000,
        type:'error'
      })
      await stopLoader();
    }
  }

  const UpdateMemberMembershipData = async () =>{
    try{
      await startLoader();
      let response = await updateMemberMembershipData(user._id,token,selectedBranch._id,{mobile_number:userPhoneNo});
      if(response.error){
        new Toast({
          message:response.error,
          duration:3000,
          type:'error'
        })
        setSuccessMessage(false);
      }else{
        setSuccessMessage(true);
      }
      await stopLoader();
    }catch(error){
      setSuccessMessage(false);
      await stopCamera();
    }
  }

  const handleSearch = event => {
    try {
      setSearchKey(event.target.value);
    } catch (error) {
      // do something
    }
  }

  const handleGymSearch = event => {
    try {
      setGymSearchKey(event.target.value)
    } catch (error) {
      // do something
    }
  }


  const handleMemberIds = id => event => {

    let list = [...selectedMemberIds];
    let findIndex = list.findIndex(itemId => itemId == id);
    if (findIndex == -1) list.push(id);
    else list.splice(findIndex, 1);
    setSelectedMemberIds(list);

  }


  const handleBulkStatusChnageAction = event => {
    event.preventDefault();
    setMemberActivationModalOpen(!isMemberActivationModalOpen)
    setBulkStatusChangeAction(true);
    event.stopPropagation();
  }

  const handleAction = async event => {
    event.preventDefault();
    try {
      await startLoader();
      let status = (isBulkStatusChangeAction ? isMembersActive : (!selectedMember.current.deletedAt || selectedMember.current.deletedAt == null)) ? "deactivate" : "activate";
      let memberIDs = selectedMemberIds.length ? selectedMemberIds : [selectedMember.current._id];
      await changeMemberStatus(user._id, token, status, { memberIDs });
      handleMemberActivationModalOpen();
      setBulkStatusChangeAction(false);
      setSelectedMemberIds([]);
      setStatusChangeOPeration(!isStatusChangeOPeration);
      await stopLoader();
    } catch (error) {
      await stopLoader();
    }
    event.stopPropagation()
  }


  const toggleAddMemberModal = () => {
    if (isAddMemberModalOpen) {
      setAddUserModalOpen(false)
    } else {
      setAddUserModalOpen(true)
    }
  }

  const toggleFitnessTestModal = () => {
    if (isFitnessTestModalOpen) {
      setFitnessTestModalOpen(false)
    } else {
      setFitnessTestModalOpen(true)
    }
  }

  const handleMemberActivationModalOpen = () => {
    setMemberActivationModalOpen(!isMemberActivationModalOpen)
    setBulkStatusChangeAction(false);
  }

  //gym and branch pagination function
  const prev = async () => {
    try {
      if (pageNo.member <= pageCount.member && pageNo.member != 1) {
        pageNo.member = pageNo.member - 1;
        setPageNo({
          ...pageNo
        });
        await startLoader()
        await GetAllMember();
        await stopLoader()
      }
    } catch (error) {
      await stopLoader()
    }
  };
  const next = async () => {
    try {
      if (pageNo.member < pageCount.member) {
        pageNo.member = pageNo.member + 1;
        setPageNo({
          ...pageNo
        })
        await startLoader()
        await GetAllMember();
        await stopLoader()
      }
    } catch (error) {
      await stopLoader()
    }
  };


  const EnrollGosMemberToBioMetric = async (mobile_number,SerialNumber) =>{
    try{
      if(!mobile_number || !SerialNumber) return;
      await startLoader();
      const payload = {mobile_number,SerialNumber};
      const response = await enrollGosMemberToBioMetric(user._id,token,payload);
      if(response.error){
        new Toast({
            message:response.error,
            duration:3000,
            type:'error'
        })
        await stopLoader()
        return;
      }

      new Toast({
          message:"Command executed successfully, Kindly check your device.",
          duration:3000,
          type:'success'
      })
      await stopLoader()
    }catch(error){
      new Toast({
        message:JSON.stringify(error),
        duration:3000,
        type:'error'
      })
      await stopLoader()
    }
  }

  //user medthods
  const toggleMemberActionDropdown = (member, type) => event => {
    event.preventDefault()
    let memberId = member._id;
    if(type == "biometric-device"){
      const deviceList = (member.branch.length>0 && member.branch[0].gosInfo && member.branch[0].gosInfo.deviceList && member.branch[0].gosInfo.deviceList.length>0)?member.branch[0].gosInfo.deviceList:[];
      let allDevices = deviceList;
      if(allDevices.length>0){
        if(allDevices.length>1){
          setAllBiometricDevices([...allDevices])
        }else{
          EnrollGosMemberToBioMetric(member.userInfo.phone,allDevices);
        }
      }else{
        new Toast({
          message:"Device not synched, Kindly press <b>Sync With Gos</b> button or contact admin.",
          duration:3000,
          type:'info'
      })
      }
    }
    if (isMemberActionDropdownOpenId == member._id) {
      memberId = "";
      setCurrentActionType('')
      setAllBiometricDevices([])
    } else {
      selectedMember.current=member;
      setCurrentActionType(type)
    }
    setMemberActionDropdownOpenId(memberId);
    event.stopPropagation()
  }
  

  const getGymParams = () => {
    let param = {
      pageSize: pageSize.gym,
      pageNo: pageNo.gym
    };
    if (gymSearchKey) param.q = gymSearchKey;
    param.activationStatus = "active";
    return param;
  }

  const GetAllGyms = async () => {
    try {
      let params = getGymParams();
      let gyms = await getAllGym(user._id, token, params);
      setGyms(gyms);
      if(gyms.length){
        isSearchBranch = true
        setSearchBranch(isSearchBranch);
        setSelectedGym(gyms[0]);
        setSelectedBranch("")
        setBranches([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const GetGymCount = async () => {
    try {
      let params = getGymParams();
      let attr = await getGymCount(user._id, token, params);
      pageNo.gym = 1;
      setPageNo({
        ...pageNo
      })
      setTotal({
        ...total,
        gym: attr.COUNT
      });
      setPageCount({
        ...pageCount,
        gym: Math.ceil(attr.COUNT / pageSize.gym)
      });
    } catch (error) {
      // do something
    }
  }



  const GetBranchParams = () => {
    let params = {};
    params.noLimit = "noLimit";
    if (branchSearchKey) params.q = branchSearchKey;
    if (selectedGym) params.gymIds = [selectedGym._id];
    params.activationStatus = "active";
    return params;
  }

  const GetAllBranches = async () => {
    try {
      if(!selectedGym){
         setBranches([])
         return;
      }
      let params = GetBranchParams();
      let branches = await getAllBranches(user._id, token, params)
      setBranches(branches);
      if(branches.length == 1){
        setBranchFilterType(branches[0].name);
        setSelectedBranch(branches[0]);
      }else{
        setBranchFilterType("All");
      }
    } catch (error) {
      // do something 
    }
  }

  const GetBranchCount = async () => {
    try {
      let params = GetBranchParams();
      let attr = await getBranchCount(user._id, token, params);
      pageNo.branch = pageNo.branch + 1;
      setPageNo({
        ...pageNo
      })
      setTotal({
        ...total,
        branch: attr.COUNT
      });
      setPageCount({
        ...pageCount,
        branch: Math.ceil(attr.COUNT / pageSize.branch)
      });
    } catch (error) {
      // do something
    }
  }

  const GetMemberParams = () => {
    let params = {
      pageNo: pageNo.member,
      pageSize: pageSize.member
    };
    params.activationStatus = isMembersActive ? "activate" : "deactivate";
    if (searchKey) params.q = searchKey;
    if (selectedGym) params.gymIDs = selectedGym._id;
    if (selectedBranch) params.branchIDs = selectedBranch._id;
    return params;
  }



  const GetAllMember = async () => {
    try {
      let params = GetMemberParams();
      let members = await getAllMembers(user._id, token, params);
      if(!members.error) {
        setMembers(members);
      }else{
        //handle error
      }
    } catch (error) {
      //do something
    }
  }

  const GetMemberCount = async () => {
    try {
      let params = GetMemberParams();
      delete params.pageNo;
      delete params.pageSize;
      let response = await getMemberCount(user._id, token, params);
      pageNo.member = 1;
      setPageNo({
        ...pageNo
      })
      setPageCount({
        ...pageCount,
        member: Math.ceil(response.COUNT / pageSize.member)
      })
      setTotal({
        ...total,
        member: response.COUNT
      })
    } catch (error) {
      // so something
    }
  }

  const checkAllMembers = async () => {
    let list=[...selectedMemberIds]
    let isAllExist = Members.every(member => list.indexOf(member._id) != -1);
    if (isAllExist) {
      list=await list.filter(id=>Members.findIndex(d=>d._id==id)==-1);
    } else {
      let memberIDs = Members.map(member => member._id)
      list = [...list, ...memberIDs];
      list = Array.from(new Set(list));
    }
    
    setSelectedMemberIds(list)
  }



  const toggleActiveInactiveButton = () => {
    if (isMembersActive) {
      setMembersActive(false);
    } else {
      setMembersActive(true);
    }
  }

  const toggleMemberFilterOpen = () => {
    if (isMemberFilterListOpen) {
      setMemberFilterOpen(false)
    } else {
      setMemberFilterOpen(true)
    }
  }

  const handleFilterModalOpen = async (filterType) => {
    try {

      if (isFilterModalOpen) {
        if (isFilterModalOpen == "branch") {
          setBranches([])
        }
        setFilterModalOpen('')
      } else {
        if (filterType == "branch") {
          setBranchSpinnerLoader(true);
          await GetBranchCount();
          await GetAllBranches();
          setBranchSpinnerLoader(false);
        }
        setFilterModalOpen(filterType)
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
      // do something
    }
  }

  const handleMemberSelect = (member) => event => {
    event.preventDefault();
    selectedMember.current=member;
    event.stopPropagation();
  }


  const handleProfileView = (member) => {
    if(isManageProfileOpen){
      setManageProfileOpen(false);
      stopCamera();
    }else{
      setManageProfileOpen(true);
      handleMemberSelect(member);
    }
  }

  const startCamera = async () => {
    try {

      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

        if (videoRef.current) {
            videoRef.current.srcObject = stream;
            setIsCameraOn(true);
        }
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // if (videoRef.current) {
        //     videoRef.current.srcObject = stream;
        //     setIsCameraOn(true);
        // }
    } catch (error) {
        console.error('Error accessing camera:', error);
    }
  };

  const stopCamera = () => {
      if (videoRef.current && videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject;
          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
          setIsCameraOn(false);
      }
  };
  

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setImageSrc(canvas.toDataURL('image/png'));
      const blob = dataURLToBlob(canvas.toDataURL('image/png'));
      const file = new File([blob], 'captured-image.png', { type: 'image/png' });
      selectedFile=file;
      stopCamera();
    }
  };

  const dataURLToBlob = (dataURL) => {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const bytes = atob(data);
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  };

  const handleUpdateProfile = (updateType) => {
    if(updateType == "cancel"){
      setImageSrc('');
    }else{
      handleProfileView();
      // Function to update the profile image
      setImageSrc('');
    }
  }
  
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      selectedFile=file;
      setImageSrc(URL.createObjectURL(file));
    }
  }

  const SaveMemberProfile = () => {

    let formData = new FormData();
    formData.set("photo", selectedFile);
    startLoader();
    updateUserProfileImage (user._id, selectedMember.current._id, token, formData).then(async data => {
      if (data.error) {
        console.log(data.error);
      } else {
        setImageSrc("");
        GetAllMember();
        stopLoader();
        // handleProfileView();
      }
    });
  }

  const dataURLToFile = (dataURL, fileName) => {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const bytes = atob(data);
    const arrayBuffer = new ArrayBuffer(bytes.length);
    const uintArray = new Uint8Array(arrayBuffer);
    
    for (let i = 0; i < bytes.length; i++) {
      uintArray[i] = bytes.charCodeAt(i);
    }
  
    const blob = new Blob([arrayBuffer], { type: mime });
    return new File([blob], fileName, { type: mime });
  };


  const handleBranchFilter = (filterType, singleBranch) => {
    if(singleBranch){
      setBranchFilterType(filterType.name);
      setSelectedBranch(filterType);
    }else{
      setBranchFilterType(filterType);
    }
  }

  const handleCopy = (ID, copyType) => {
    if(ID){
      setItemCopy({
        ...isItemCopy,
        memberId: ID,
        copyType: copyType,
        copied: false,
      })
    }else{
      setItemCopy({
        ...isItemCopy,
        memberId: "",
        copyType: "",
        copied: false,
      })
    }
  }

  const handleCopied = (data, dataType) => {
    let formattedData = (dataType == "phone" && data.startsWith("+91")) ? data.substring(3) : data;
    navigator.clipboard.writeText(formattedData)
    .then(() => {
      setItemCopy({
        ...isItemCopy,
        copied: true,
      })
    })
    .catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  }

  const handleBiometricMemberUpdate = () => {
    setMemberSyncModalOpen(!isMemberSyncModalOpen);
    setSuccessMessage(false);
    setCurrentElementId("");
    setUserPhoneNo("");
  }

  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (event) {
      let interval = setInterval(() => {
        let element = event.target.querySelector('input');
        if (element) {
            element.focus();
            clearInterval(interval)
        }
      }, 0);
    }
  }

  const handleUserPhoneNumber = event => {
    setUserPhoneNo(event.target.value)
  }

  const handleSyncBiometricUser = () => {
    const phoneNumberRegex = /^[6-9]\d{9}$/;
    if(phoneNumberRegex.test(userPhoneNo)){
      UpdateMemberMembershipData();
    }else{
      setSuccessMessage(false);
      // alert("Phone No. Incorrect")
    }
  }
  
  const calculateTimeLeft = () => {
    const endTime = new Date(lastSync.getTime() + 24 * 60 * 60 * 1000);
    const currentTime = new Date().getTime();
    const timeLeft = endTime.getTime() - currentTime;

    return {
      hours: Math.floor((timeLeft / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeLeft / (1000 * 60)) % 60),
      seconds: Math.floor((timeLeft / 1000) % 60),
      timeLeft,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const handleWhatsappMessage = (phoneNumber) => {
    const whatsappAppURL = `whatsapp://send?phone=${phoneNumber}`;
    const whatsappWebURL = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
  
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // On mobile, try opening the WhatsApp app
      window.location.href = whatsappAppURL;

      // If WhatsApp is not installed, the app URL will fail silently, 
      // so we can use a timeout to redirect to WhatsApp Web after a delay.
      setTimeout(() => {
        window.open(whatsappWebURL, '_blank');
      }, 2000); // Adjust delay as needed
    } else {
      // If on desktop, redirect to WhatsApp Web
      window.open(whatsappWebURL, '_blank');
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear interval when the countdown ends
    if (timeLeft.timeLeft < 0) {
      clearInterval(timer);
    }

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [timeLeft.timeLeft]);

  useEffect(async () => {
    if (isSearchBranch && Branches.length == 0) {
      setBranchSpinnerLoader(true)
      await GetBranchCount();
      await GetAllBranches();
      setBranchSpinnerLoader(false)
      isSearchBranch = false
      setSearchBranch(isSearchBranch)
    }
  }, [isSearchBranch, Branches])

  useEffect(async () => {
    try {
      setGymSpinnerLoader(true);
      await GetGymCount();
      await GetAllGyms();
      setGymSpinnerLoader(false);
    } catch (error) {
      setGymSpinnerLoader(false);
    }
  }, [gymSearchKey]);



  useEffect(async () => {
    try {
      await startLoader();
      await GetMemberCount();
      await GetAllMember();
      await stopLoader();
    } catch (error) {
      //do something 
      await stopLoader()
    }
  }, [props, isMembersActive, isStatusChangeOPeration, searchKey, selectedGym, selectedBranch]);


  const toggleManageExecutePlanner = () => {
    if (isDesktopPlannerExecutionModalOpen) {
      setPlannerExecutionModalOpen(false)
    } else {
      setPlannerExecutionModalOpen(true)
    }
  }

  const handleProfileViewOpen = () => {
    if(!isProfileViewOpen){
      setManageProfileOpen(false);
      setProfileViewOpen(true);
    }else{
      setManageProfileOpen(true);
      setProfileViewOpen(false);
    }
  }

  useEffect(async () => {
    await GetBranchCount();
    await GetAllBranches();
  },[user & user.role == 5])

  useEffect(() => {
    let searchMemberInputField = document.getElementById("search-member-input");
    let searchContainer = document.getElementById("search-container");
    let searchIcon = document.getElementById("search-icon");
    let searchBranchInputField = document.getElementById("search-branch-input");
    let searchContainerBranch = document.getElementById("search-container-branch");
    let searchIconBranch = document.getElementById("search-icon-branch");
    let searchGymInputField = document.getElementById("search-gym-input");
    let searchContainerGym = document.getElementById("search-container-gym");
    let searchIconGym = document.getElementById("search-icon-gym");

    if (searchMemberInputField) {
      searchMemberInputField.onfocus = e => {
        searchContainer.style.outline = "1px solid #055075";
        searchContainer.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
        searchIcon.classList.add("active-fill-svg");
      }
      searchMemberInputField.onblur = e => {
        searchContainer.style.outline = "none";
        searchContainer.style.boxShadow = "none";
        searchIcon.classList.remove("active-fill-svg");
      }
    }
    if (searchBranchInputField) {
      searchBranchInputField.onfocus = e => {
        searchContainerBranch.style.outline = "1px solid #055075";
        searchContainerBranch.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
        searchIconBranch.classList.add("active-fill-svg");
      }
      searchBranchInputField.onblur = e => {
        searchContainerBranch.style.outline = "none";
        searchContainerBranch.style.boxShadow = "none";
        searchIconBranch.classList.remove("active-fill-svg");
      }
    }
    if (searchGymInputField) {
      searchGymInputField.onfocus = e => {
        searchContainerGym.style.outline = "1px solid #055075";
        searchContainerGym.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
        searchIconGym.classList.add("active-fill-svg");
      }
      searchGymInputField.onblur = e => {
        searchContainerGym.style.outline = "none";
        searchContainerGym.style.boxShadow = "none";
        searchIconGym.classList.remove("active-fill-svg");
      }
    }
  }, [])

  return (
    <Dashboard member={selectedMember.current} navItemData={"Member"} flag={flag}>

      <div className="header-bar" style={{ display: "none" }}>
        <div>
          <div className="dashboard-name-container">
            <div className="dashboard-name">Member Account's</div>

            <span class="material-icons-round" style={{ color: "#bdbdbd", margin: "-0.8% 0 0 8%", cursor: "pointer" }}>add_circle_outline</span>
            <div id="global-action-container" className="add-global-action-container" style={{ display: "none" }}>
              <div role="button" className="action-item d-flex">
                <span class="material-icons-round flex-item">fitness_center</span>
                <div className="flex-item spacing-19">Add Gym</div>
              </div>
              <div role="button" className="action-item d-flex">
                <span class="material-icons-round flex-item">store</span>
                <div className="flex-item spacing-19">Add Branch</div>
              </div>
              <div role="button" className="action-item d-flex">
                <span class="material-icons-round flex-item">person_add_alt</span>
                <div className="flex-item spacing-19">Add Member</div>
              </div>
              <div className="action-item d-flex">
                <span class="material-icons-round flex-item">playlist_add</span>
                <div className="flex-item spacing-19">Add Member Report</div>
              </div>
              <div role="button" className="action-item d-flex">
                <span class="material-icons-round flex-item">group_add</span>
                <div className="flex-item spacing-19">Add User</div>
              </div>
              <div className="action-item d-flex">
                <span class="material-icons-round flex-item">post_add</span>
                <div className="flex-item spacing-19">Add Content</div>
              </div>
              <div className="action-item d-flex">
                <span class="material-icons-round flex-item">addchart</span>
                <div className="flex-item spacing-19">Create Planner</div>
              </div>
              <div className="action-item d-flex">
                <span class="material-icons-round flex-item">insert_chart</span>
                <div className="flex-item spacing-19">Assign Planner</div>
              </div>

            </div>

          </div>
          <div className="active-inactive-container">
            <img src={SwapIcon} className="active-inactive-icon" />
            <div id="switch-gym" className="active-inactive-text">{mapAction == 0 ? `Active` : `InActive`}</div>
          </div>
        </div>

        <div>
          <div className="search-field-container">
            <input type="text" className="search-field" placeholder="search" />
            <div className="filter-container" >
              <img src={FilterIcon} className="filter-icon" />
            </div>
            <div id="filter-container-toggle" className="filter-dropdown" style={{ display: "none" }}>
              <div className="d-flex">
                <div className="flex-item container-spacing bold-font">
                  Sort By
                </div>
                <input
                  className="flex-item"
                  type="checkbox"
                  style={{ marginTop: "4%" }}
                />
              </div>
              <div className="container-spacing">
                <span className="g-font-1 inactive">State</span>
                <div

                  type="text"
                  className="d-flex select-dropdown"
                >
                  <div className="select-exercise-text">Select State</div>
                  <img
                    id="select-state-icon"
                    src={ArrowUp}
                    className="select-exercise-icon"
                  />
                </div>
                <div id="state-list" className="dropdown-menu-items" style={{ display: "none" }}>
                  <div className="menu-text-spacing">Andhra Pradesh</div>
                  <div className="menu-text-spacing">Aurnachal Pradesh</div>
                  <div className="menu-text-spacing">Assam</div>
                  <div className="menu-text-spacing">Bihar</div>
                  <div className="menu-text-spacing">Chattisgarh</div>
                  <div className="menu-text-spacing">Goa</div>
                  <div className="menu-text-spacing">Gujarat</div>
                  <div className="menu-text-spacing">Haryana</div>
                  <div className="menu-text-spacing">Himachal Pradesh</div>
                  <div className="menu-text-spacing">Jarkhand</div>
                  <div className="menu-text-spacing">Karnataka</div>
                  <div className="menu-text-spacing">Kerela</div>
                  <div className="menu-text-spacing">Madhya Pradesh</div>
                  <div className="menu-text-spacing">Maharashtra</div>
                  <div className="menu-text-spacing">Manipur</div>
                  <div className="menu-text-spacing">Meghalaya</div>
                  <div className="menu-text-spacing">Mizoram</div>
                  <div className="menu-text-spacing">Nagaland</div>
                  <div className="menu-text-spacing">Odisha</div>
                  <div className="menu-text-spacing">Punjab</div>
                  <div className="menu-text-spacing">Rajasthan</div>
                  <div className="menu-text-spacing">Sikkim</div>
                  <div className="menu-text-spacing">Tamil Nadu</div>
                  <div className="menu-text-spacing">Telengana</div>
                  <div className="menu-text-spacing">Tripura</div>
                  <div className="menu-text-spacing">Uttarakhand</div>
                  <div className="menu-text-spacing">Uttar Pradesh</div>
                  <div className="menu-text-spacing">West Bengal</div>
                </div>
              </div>
              <div className="container-spacing">
                <div
                  id="select-Exercise"
                  type="text"
                  className="d-flex select-dropdown"
                >
                  <div className="select-exercise-text">Select City</div>
                  <img
                    id="select-city-icon"
                    src={ArrowUp}
                    className="select-exercise-icon"
                  />
                </div>
                <div id="city-list" className="dropdown-menu-items" style={{ display: "none" }}>
                  <div className="menu-text-spacing">Andhra Pradesh</div>
                  <div className="menu-text-spacing">Aurnachal Pradesh</div>
                </div>
              </div>
              <div className="container-spacing">
                <span className="g-font-1 inactive">Location</span>
                <div
                  id="select-Exercise"
                  type="text"
                  className="d-flex select-dropdown"
                >
                  <div className="select-exercise-text">Select Location</div>
                  <img
                    id="select-location-icon"
                    src={ArrowUp}
                    className="select-exercise-icon"
                  />
                </div>
                <div id="location-list" className="dropdown-menu-items" style={{ display: "none" }}>
                  <div className="menu-text-spacing">Andhra Pradesh</div>
                  <div className="menu-text-spacing">Aurnachal Pradesh</div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-bar d-flex space-between">
            <div className='d-flex'>
              <svg className='flex-item m-r-12 m-t-4' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7493 18.9167C19.3517 18.9167 23.0827 15.1857 23.0827 10.5833C23.0827 5.98096 19.3517 2.25 14.7493 2.25C10.147 2.25 6.41602 5.98096 6.41602 10.5833C6.41602 15.1857 10.147 18.9167 14.7493 18.9167Z" stroke="#035075" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.25 39.7499V35.5833C2.25 33.3731 3.12797 31.2535 4.69078 29.6907C6.25358 28.1279 8.3732 27.2499 10.5833 27.2499H18.9167C21.1268 27.2499 23.2464 28.1279 24.8092 29.6907C26.372 31.2535 27.25 33.3731 27.25 35.5833V39.7499M29.3333 2.52075C31.1259 2.97971 32.7147 4.02221 33.8492 5.4839C34.9838 6.94558 35.5997 8.74331 35.5997 10.5937C35.5997 12.444 34.9838 14.2418 33.8492 15.7034C32.7147 17.1651 31.1259 18.2076 29.3333 18.6666M39.75 39.7499V35.5833C39.7394 33.744 39.1207 31.9599 37.9901 30.5091C36.8596 29.0583 35.2809 28.0222 33.5 27.5624" stroke="#035075" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <div className='flex-item'>
                <p className="dashboard-type-heading flex-item">Member</p>
                <p className='dashboard-route-txt'>{selectedBranch ? <span className='color-green'>{selectedBranch.name}</span> : "Manage all your member's here"}</p>
              </div>
            </div>
            <div className='d-flex'>
              <div id="search-container" className='global-search-box m-r-12'>
                <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1637_1318)">
                    <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1637_1318">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input id="search-member-input" className='flex-item' type="text" placeholder="Search" />
              </div>
              <div style={{position:'relative'}}>
                <div onClick={toggleMemberFilterOpen.bind(this)} className='global-round-btn d-flex m-r-12'>
                  <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
                  </svg>
                </div>
                {
                  isMemberFilterListOpen && (
                    <div onClick={e => e.stopPropagation()} className='gym-filter-popup' style={{ width: "210px" }}>
                      <p className='filter-heading-text m-b-12'>Status</p>
                      <div className='d-flex'>
                        <div onClick={toggleActiveInactiveButton.bind(this)} className='flex-item d-flex active-inactive-button m-r-12' style={{ background: !isMembersActive ? "rgba(255, 0, 85, 0.347)" : "" }}>
                          <div className='active-inactive-ball flex-item' style={{ background: !isMembersActive ? "rgb(201, 0, 57)" : "", margin: !isMembersActive ? "0 0 0 0" : "" }} />
                        </div>
                        <p className='flex-item active-inactive-txt'>{isMembersActive ? "Active" : "Inactive"}</p>
                      </div>
                      {
                        user.role == 5 ? (
                          <div className='flex-item w-full'>
                            <p className='filter-heading-text m-b-6'>Branch(s)</p>
                            {
                              Branches.length > 1 && (
                                <div onClick={() => handleBranchFilter("All")} className='d-flex'>
                                  <input className='flex-item m-r-8' type='radio' name="branch" checked={isBranchFilterType == "All" ? true : false} />
                                  <p className='flex-item' style={{color : isBranchFilterType ? "#055075" : "", fontSize:"14px", fontWeight:"500"}}>ALL</p>
                                </div>                              
                              )
                            }
  
                            {
                              Branches.map(data => {
                                return(
                                  <div onClick={() => handleBranchFilter(data, true)} className='d-flex'>
                                    <input className='flex-item m-r-8' type='radio' name="branch" checked={isBranchFilterType == data.name ? true : false} />
                                    <p className='flex-item font-weight-500' style={{color : isBranchFilterType ? "#055075" : "", fontSize:"14px", fontWeight:"500"}}>{data.name}</p>
                                  </div>
                                )
                              })
                            }
  
                          </div>
                        ) : (
                          <div className='flex-item w-full'>
                            {
                              !selectedGym.email && [
                                <div className='flex-item w-full'>
                                  <div onClick={handleFilterModalOpen.bind(this, "gym")} className='member-button-global btn-center d-flex m-t-20'>
                                    <p className='flex-item'>Filter By Gym</p>
                                  </div>
                                </div>
                              ]
                            }
                            {
                              selectedGym && [
                                <div className='d-flex'>
                                  <div className='d-flex m-t-20'>
                                    <div className='d-flex flex-item' style={{ width: 'auto !important' }} >
                                      <p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
                                      <div className='flex-item d-flex m-r-12'>
                                        <svg onClick={handleFilterModalOpen.bind(this, "gym")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                        </svg>
                                      </div>
                                      <div className='flex-item d-flex'>
                                        <svg onClick={handleClearGymFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>,
                                <div className="d-flex w-full m-t-8">
                                  {
                                    selectedGym.logoFileID  ? (
                                      <img className='flex-item m-r-8 branch-gym-logo' src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/25/25/70`} />
                                    ) : (
                                      <div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ background: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c" }}>
                                        <p className='flex-item' style={{ color: '#ffffff', margin: '0 auto' }}>{`${selectedGym.name}`.trim()[0]}</p>
                                      </div>
                                    )
                                  }
                                  <div className="flex-item">
                                    <p className="branch-gym-name">{selectedGym.name}</p>
                                    <p className="branch-gym-email">{selectedGym.email}</p>
                                  </div>
                                </div>
                              ]
                            }
                            {
                              selectedGym &&!selectedBranch && [
                                <div onClick={handleFilterModalOpen.bind(this, "branch")} className='member-button-global btn-center d-flex m-t-20'>
                                  <p className='flex-item'>Filter By Branch</p>
                                </div>
                              ]
                            }
                            {
                              selectedBranch && [
                                <div className='d-flex'>
                                  <div className='d-flex m-t-20'>
                                    <p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
                                    <div onClick={handleFilterModalOpen.bind(this, "branch")} className='flex-item d-flex m-r-12'>
                                      <svg className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                      </svg>
                                    </div>
                                    <div className='flex-item d-flex'>
                                      <svg onClick={handleClearBranchFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>,
                                <div className="w-full m-t-8">
                                  <p className="branch-gym-name">{selectedBranch.name}</p>
                                  <p className="branch-gym-email">{selectedBranch.address ? selectedBranch.address.placeName ? selectedBranch.address.placeName : selectedBranch.address.placeAddress ? selectedBranch.address.placeAddress : selectedBranch.address : ""}</p>
                                </div>
                              ]
                            }

                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
              <div className='global-round-btn d-flex'>
                <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
                </svg>
              </div>
            </div>
            <img onClick={prev} src={ArrowLeft} className="pagination-icon" />
            <img onClick={next} src={ArrowRight} className="pagination-icon" />
          </div>
        </div>
      </div>

      <div className="header-bar d-flex space-between">
        <div className='d-flex'>
          <svg className='flex-item m-r-12 m-t-4' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7493 18.9167C19.3517 18.9167 23.0827 15.1857 23.0827 10.5833C23.0827 5.98096 19.3517 2.25 14.7493 2.25C10.147 2.25 6.41602 5.98096 6.41602 10.5833C6.41602 15.1857 10.147 18.9167 14.7493 18.9167Z" stroke="#035075" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.25 39.7499V35.5833C2.25 33.3731 3.12797 31.2535 4.69078 29.6907C6.25358 28.1279 8.3732 27.2499 10.5833 27.2499H18.9167C21.1268 27.2499 23.2464 28.1279 24.8092 29.6907C26.372 31.2535 27.25 33.3731 27.25 35.5833V39.7499M29.3333 2.52075C31.1259 2.97971 32.7147 4.02221 33.8492 5.4839C34.9838 6.94558 35.5997 8.74331 35.5997 10.5937C35.5997 12.444 34.9838 14.2418 33.8492 15.7034C32.7147 17.1651 31.1259 18.2076 29.3333 18.6666M39.75 39.7499V35.5833C39.7394 33.744 39.1207 31.9599 37.9901 30.5091C36.8596 29.0583 35.2809 28.0222 33.5 27.5624" stroke="#035075" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div className='flex-item'>
            <p className="dashboard-type-heading flex-item">Member</p>
            <p className='dashboard-route-txt'>{selectedBranch ? <span className='color-green'>{selectedBranch.name}</span> : "Manage all your member's here"}</p>
          </div>
        </div>
        <div className='d-flex'>
          {
            ((selectedBranch && selectedBranch.gosInfo && selectedBranch.gosInfo.gym_slug) &&  (user.role == 5 || user.role == 6 || user.role == 0))&& (
              <div onClick={SyncMemberWithGos}  className={`member-button-global ${isButtonLock?'member-button-global-inactive':""} btn-center d-flex`} style={{padding: "0 12px", height:"30px", marginRight:"12px"}}>
                <p className='flex-item' style={{fontStyle: "12px"}}>Sync With GOS</p>
              </div>
            )
          }
          {
            ((selectedBranch && selectedBranch.gosInfo && selectedBranch.gosInfo.gym_slug) &&  (user.role == 5 || user.role == 6 || user.role == 0))&& (
              <div onClick={handleBiometricMemberUpdate}  className={`member-button-global ${isButtonLock?'member-button-global-inactive':""} btn-center d-flex`} style={{padding: "0 12px 0 8px", height:"30px", marginRight:"12px"}}>
                <svg className='flex-item m-r-6 svg-stroke' width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0359 12.158C12.0359 12.158 11.0499 16.894 16.5279 19.151" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.7517 20.9519C10.8807 19.3309 8.66571 16.2259 9.02671 12.8249C9.02969 12.1749 9.18907 11.5351 9.4914 10.9597C9.79373 10.3843 10.2301 9.8901 10.7637 9.51888C11.4498 9.17875 12.2417 9.12024 12.9703 9.35583C13.6989 9.59142 14.3066 10.1025 14.6637 10.7799C15.6057 12.1679 14.9387 13.9589 15.6277 15.4209C16.6877 17.2219 19.3777 16.7669 20.3947 15.1239C21.9207 12.7719 21.1097 9.18088 19.5517 7.01888C17.1047 3.68188 13.5087 1.91188 8.41171 3.68088C3.6227 5.31288 1.24971 12.0619 3.77171 16.5439" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.98221 19.0018C7.11047 17.9929 6.47379 16.8029 6.11821 15.5178C5.76435 14.2369 5.70182 12.893 5.93521 11.5848C6.06679 10.7142 6.37658 9.88018 6.84521 9.1348C7.3168 8.38423 7.93476 7.73634 8.66221 7.2298C9.74173 6.5903 10.9876 6.2877 12.2402 6.3608C13.0512 6.3858 13.8502 6.5688 14.5892 6.8978C15.3282 7.2268 15.9952 7.6958 16.5492 8.2778C17.7549 9.85255 18.3553 11.8078 18.2412 13.7878" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p className='flex-item' style={{fontStyle: "12px"}}>Biometric Sync</p>
              </div>
            )
          }
          <div id="search-container" className='global-search-box m-r-12'>
            <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1637_1318)">
                <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
              </g>
              <defs>
                <clipPath id="clip0_1637_1318">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input onChange={_.debounce(handleSearch, 1000)} className='flex-item' type="text" placeholder="Search Member" />
          </div>
          <div style={{position:'relative'}}>
            <div onClick={toggleMemberFilterOpen.bind(this)} className='global-round-btn d-flex m-r-12'>
              <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
              </svg>
            </div>
            {
              isMemberFilterListOpen && (
                <div onClick={e => e.stopPropagation()} className='gym-filter-popup' style={{ width: "210px" }}>
                  <p className='filter-heading-text m-b-12'>Status</p>
                  <div className='d-flex'>
                    <div onClick={toggleActiveInactiveButton.bind(this)} className='flex-item d-flex active-inactive-button m-r-12' style={{ background: !isMembersActive ? "rgba(255, 0, 85, 0.347)" : "" }}>
                      <div className='active-inactive-ball flex-item' style={{ background: !isMembersActive ? "rgb(201, 0, 57)" : "", margin: !isMembersActive ? "0 0 0 0" : "" }} />
                    </div>
                    <p className='flex-item active-inactive-txt'>{isMembersActive ? "Active" : "Inactive"}</p>
                  </div>
                  {
                    user.role == 5 ? (
                      <div className='flex-item w-full m-t-16'>
                        <p className='filter-heading-text m-b-6'>Branch(s)</p>
                        {
                          Branches.length > 1 && (
                            <div onClick={() => handleBranchFilter("All")} className='d-flex'>
                              <input className='flex-item m-r-8' type='radio' name="branch" checked={isBranchFilterType == "All" ? true : false} />
                              <p className='flex-item' style={{color : isBranchFilterType ? "#055075" : "", fontSize:"14px", fontWeight:"500"}}>ALL</p>
                            </div>                              
                          )
                        }

                        {
                          Branches.map(data => {
                            return(
                              <div onClick={() => handleBranchFilter(data, true)} className='d-flex'>
                                <input className='flex-item m-r-8' type='radio' name="branch" checked={isBranchFilterType == data.name ? true : false} />
                                <p className='flex-item font-weight-500' style={{color : isBranchFilterType ? "#055075" : "", fontSize:"14px", fontWeight:"500"}}>{data.name}</p>
                              </div>
                            )
                          })
                        }

                      </div>
                    ) : (
                      <div className='flex-item w-full'>
                      {
                        !selectedGym.email && [
                          <div className='flex-item w-full'>
                            <div onClick={handleFilterModalOpen.bind(this, "gym")} className='member-button-global btn-center d-flex m-t-20'>
                              <p className='flex-item'>Filter By Gym</p>
                            </div>
                          </div>
                        ]
                      }
                      {
                        selectedGym && [
                          <div className='d-flex'>
                            <div className='d-flex m-t-20'>
                              <div className='d-flex flex-item' style={{ width: 'auto !important' }} >
                                <p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
                                <div className='flex-item d-flex m-r-12'>
                                  <svg onClick={handleFilterModalOpen.bind(this, "gym")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                  </svg>
                                </div>
                                <div className='flex-item d-flex'>
                                  <svg onClick={handleClearGymFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>,
                          <div className="d-flex w-full m-t-8">
                            {
                              selectedGym.logoFileID ? (
                                <img className='flex-item m-r-8 branch-gym-logo' src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/25/25/70`} style={{
                                  width:"30px",
                                  height:"30px"
                                }}/>
                              ) : (
                                <div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ background: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c" }}>
                                  <p className='flex-item' style={{ color: '#ffffff', margin: '0 auto' }}>{`${selectedGym.name}`.trim()[0]}</p>
                                </div>
                              )
                            }
                            <div className="flex-item">
                              <p className="branch-gym-name">{selectedGym.name}</p>
                              <p className="branch-gym-email">{selectedGym.email}</p>
                            </div>
                          </div>
                        ]
                      }
                      {
                        selectedGym && !selectedBranch && [
                          <div onClick={handleFilterModalOpen.bind(this, "branch")} className='member-button-global btn-center d-flex m-t-20'>
                            <p className='flex-item'>Filter By Branch</p>
                          </div>
                        ]
                      }
                      {
                        selectedBranch && [
                          <div className='d-flex'>
                            <div className='d-flex m-t-20'>
                              <p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
                              <div onClick={handleFilterModalOpen.bind(this, "branch")} className='flex-item d-flex m-r-12'>
                                <svg className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                                </svg>
                              </div>
                              <div className='flex-item d-flex'>
                                <svg onClick={handleClearBranchFilter} className='flex-item' width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.7541 3.35742H6.90859C6.83125 3.35742 6.76797 3.42071 6.76797 3.49805V8.33731C6.76797 8.38301 6.78906 8.4252 6.82598 8.45156L9.73164 10.5732C9.79492 10.6189 9.88281 10.6066 9.92852 10.5434L10.4313 9.85781C10.4787 9.79278 10.4646 9.70488 10.4014 9.66094L7.89473 7.84863V3.49805C7.89473 3.42071 7.83144 3.35742 7.7541 3.35742ZM11.6178 4.6793L14.374 5.35254C14.4619 5.37363 14.548 5.30684 14.548 5.21719L14.5621 2.37832C14.5621 2.26055 14.4268 2.19375 14.3354 2.26758L11.565 4.43145C11.5441 4.44764 11.5282 4.46942 11.5191 4.49428C11.5099 4.51913 11.508 4.54605 11.5135 4.57194C11.519 4.59783 11.5318 4.62164 11.5502 4.64064C11.5686 4.65963 11.5921 4.67303 11.6178 4.6793ZM14.5656 9.97207L13.5689 9.6293C13.5342 9.61739 13.4962 9.61955 13.463 9.63531C13.4298 9.65108 13.4041 9.67921 13.3914 9.71367C13.358 9.80332 13.3229 9.89121 13.2859 9.9791C12.973 10.7191 12.5248 11.3854 11.9518 11.9566C11.3851 12.5251 10.7135 12.9782 9.97422 13.2908C9.20839 13.6146 8.38518 13.7808 7.55371 13.7795C6.71348 13.7795 5.89961 13.616 5.1332 13.2908C4.39394 12.9782 3.72235 12.5251 3.15566 11.9566C2.58437 11.3854 2.13613 10.7191 1.82148 9.9791C1.49948 9.21284 1.33451 8.38976 1.33633 7.5586C1.33633 6.71836 1.4998 5.90274 1.825 5.13633C2.13789 4.39629 2.58613 3.73008 3.15918 3.15879C3.72586 2.59035 4.39745 2.13725 5.13672 1.82461C5.89961 1.49942 6.71523 1.33594 7.55547 1.33594C8.3957 1.33594 9.20957 1.49942 9.97598 1.82461C10.7152 2.13725 11.3868 2.59035 11.9535 3.15879C12.1328 3.33985 12.3016 3.52793 12.4562 3.72656L13.5074 2.90391C12.124 1.13555 9.9707 -0.00175578 7.55195 2.03474e-06C3.34023 0.00175985 -0.0417971 3.42246 0.000390432 7.63594C0.0425779 11.7756 3.40879 15.1172 7.55547 15.1172C10.8162 15.1172 13.5936 13.05 14.6518 10.1549C14.6781 10.0811 14.6395 9.99844 14.5656 9.97207Z" fill="#055075" />
                                </svg>
                              </div>
                            </div>
                          </div>,
                          <div className="w-full m-t-8">
                            <p className="branch-gym-name">{selectedBranch.name}</p>
                            <p className="branch-gym-email">{selectedBranch.address ? selectedBranch.address.placeName ? selectedBranch.address.placeName : selectedBranch.address.placeAddress ? selectedBranch.address.placeAddress : selectedBranch.address : ""}</p>
                          </div>
                        ]
                      }
                    </div>
                  )
                }
                </div>
              )
            }
          </div>
          <div className='global-round-btn d-flex'>
            <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
            </svg>
          </div>
        </div>
        {/* <div>
                <div className="dashboard-name-container">
                    <div className="dashboard-name">Gym Accounts</div>

                    <span onClick={toggleGlobalActionContainer} class="material-icons-round" style={{ color: "#bdbdbd", margin: "-0.8% 0 0 8%", cursor: "pointer" }}>add_circle_outline</span>
                    <div id="global-action-container" className="add-global-action-container" style={{ display: "none" }}>
                        <div role="button" onClick={handleCreateOpen} className="action-item d-flex">
                            <span class="material-icons-round flex-item">fitness_center</span>
                            <div className="flex-item spacing-19">Add Gym</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("Branch")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">store</span>
                            <div className="flex-item spacing-19">Add Branch</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("Member")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">person_add_alt</span>
                            <div className="flex-item spacing-19">Add Member</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("MemberReport")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">playlist_add</span>
                            <div className="flex-item spacing-19">Add Member Report</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("BranchUser")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">group_add</span>
                            <div className="flex-item spacing-19">Add User</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute('Content')} className="action-item d-flex">
                            <span class="material-icons-round flex-item">post_add</span>
                            <div className="flex-item spacing-19">Add Content</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("Planner")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">addchart</span>
                            <div className="flex-item spacing-19">Create Planner</div>
                        </div>
                        <div className="action-item d-flex">
                            <span class="material-icons-round flex-item">insert_chart</span>
                            <div className="flex-item spacing-19">Assign Planner</div>
                        </div>

                    </div>

                </div>
                <div onClick={handleActiveInactiveGym} className="active-inactive-container">
                    <img src={SwapIcon} className="active-inactive-icon" />
                    <div className="active-inactive-text">{mapAction == 0 ? "Inactive" : "Active"}</div>
                </div>
            </div>

            <div>
                <div className="search-field-container">
                    <input type="text" id="search-member-input" className="search-field" placeholder="search" />
                    <div className="filter-container" onClick={toggleFilter}>
                        <img src={FilterIcon} className="filter-icon" />
                    </div>
                    {
                        isToggleFilter && (
                            <div className="filter-dropdown">
                                <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="flex-item container-spacing bold-font">
                                            Sort By
                                        </div>
                                    </div>

                                    <div className="container-spacing" style={{
                                        minHeight: '40vh', maxHeight: '70vh'
                                    }}>
                                        <span className="g-font-1 inactive">Gym Type</span>

                                        <div
                                            onClick={tooggleInputState.bind(this, "target-gym-type")}
                                            type="text"
                                            className="d-flex select-dropdown"
                                        >
                                            <div className="select-exercise-text">{gymSortAttribute.sortGymType}</div>
                                            <img
                                                id="select-gymtype-icon"
                                                src={ArrowUp}
                                                className="select-exercise-icon"
                                            />
                                        </div>
                                        {
                                            isCurrentElement == "target-gym-type" && (
                                                <div className="dropdown-menu-items">
                                                    {
                                                        ['All', 'Comercial', 'Corporate', 'Residential'].map(name => <div onClick={handleSortBy('sortGymType', name)} className="menu-text-spacing">{name}</div>)
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='d-flex filter-dropdown-footer'>
                                        <div onClick={applyFilter} className='filter-dropdown-footer-apply-btn'>
                                            Apply
                                        </div>
                                        <div onClick={toggleFilter} className='filter-dropdown-footer-clear-btn' style={{ marginRight: "10px" }}>
                                            Clear
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>

                <div className="pagination-container">
                    <div className="pagination-tracker">
                        {(currentpage - 1) * pageSize + 1} - {(currentpage - 1) * pageSize + Gyms.length}  of  {total}
                    </div>
                    <img onClick={prev} src={ArrowLeft} className="pagination-icon" />
                    <img onClick={next} src={ArrowRight} className="pagination-icon" />
                </div>
            </div> */}

      </div>

      <div className='dashboard-data-saperator'></div>

      <div className="body-container">
        <div className='d-flex table-header-container'>
          <div onClick={checkAllMembers.bind(this)} className='d-flex flex-item gym-container' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : "24%"}}>
            <input className='flex-item m-r-8 cursor-pointer' type="checkbox" checked={Members.every(d => selectedMemberIds.indexOf(d._id) != -1)}/>
            <p className='flex-item table-heading-txt'>Member <span style={{color:"#055075"}}>(Active)</span></p>
          </div>
          <div className='number-container flex-item m-r-12' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : "18%"}}>
            <p className='flex-item table-heading-txt'>Contact No.</p>
          </div>
          <div className='location-container flex-item' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : "24%"}}>
            <p className='flex-item table-heading-txt'>Email</p>
          </div>
          <div className='email-container flex-item' style={{textAlign:"center", width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : ""}}>
            <p className='flex-item table-heading-txt margin-auto'>Validity</p>
          </div>
          {/* <div className='email-container flex-item' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : ""}}>
            <p className='flex-item table-heading-txt'>Branch</p>
          </div> */}
          {
            user.role != 8 && user.role != 7 && user.role != 4 && (
              <div className='action-container flex-item' style={{width:user.role == 0 || user.role == 5 ? "20%" : ""}}>
                <p className='flex-item table-heading-txt'>Action</p>
              </div>
            )
          }
        </div>
        <div className='flex-item main-table-scroll-container w-full'>
          {Members.map((member) => {
            return (
              <div onMouseOver={handleMemberSelect(member)} style={{ backgroundColor: (selectedMember.current && selectedMember.current._id == member._id) ? "rgb(0, 0, 0, 0.08)" : "#ffffff", boxShadow: (selectedMember.current && selectedMember.current._id == member._id) ? "none" : "0px 0.01px 3px 3px rgb(0, 0, 0, 0.05)" }} className='flex-item table-row-container d-flex'>
                <div className='flex-item gym-container d-flex' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : "24%"}}>
                  <input
                    className='flex-item m-r-8'
                    name="gym-checkbox"
                    type="checkbox"
                    onChange={e => { }}
                    style={{ cursor: 'pointer' }}
                    onClick={handleMemberIds(member._id)}
                    checked={selectedMemberIds.indexOf(member._id) != -1}
                  />
                  {
                    (member.user && member.user.length > 0) && (
                      (member.user[0].profileID) ?
                        <div onClick={() => handleProfileView(member)} className='gym-logo flex-item m-r-8'>
                          <img id="gym-ImgWrapper" src={`${config.API_URL}/image/file/${member.user[0].profileID}/30/30/70?${Date.now()}`} loading='lazy' className="gym-logo" />
                        </div>
                        : <div onClick={() => handleProfileView(member)} className="gym-logo m-r-8 flex-item d-flex" style={{
                          backgroundColor: member.user[0].profilePlaceHolderColor ? member.user[0].profilePlaceHolderColor : "#5d883c"
                        }}>
                          <p className='flex-item' style={{
                            color: "#ffffff",
                            margin: "0 auto",
                            fontSize: 14,
                          }}>{`${member.user[0].name}`.trim()[0]}</p>
                        </div>
                    )
                  }
                 <Link className='d-flex p-l-8' style={{color:"inherit",textDecoration:"none"}}
                 to={'/member/details/' + member._id}>
                    <p className='flex-item m-r-4'>{member.user.length > 0 ? member.user[0].name.length>25?member.user[0].name.substring(0,25)+"...":member.user[0].name : ""}</p>
                  </Link>
                </div>
                <div onMouseOver={()=>member.user[0].phone && member.user[0].phone.length != 0 && handleCopy(member._id, "phone")} onMouseLeave={()=>member.user[0].phone && member.user[0].phone.length != 0 && handleCopy()}  className='number-container flex-item  m-r-12' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : "18%"}}> 
                  <div onClick={()=>handleCopied(member.user[0].phone && member.user[0].phone, "phone")} className={`${isItemCopy.memberId == member._id && isItemCopy.copyType == "phone" && "more-table-item-container"} d-flex space-between`}>
                    <p className='flex-item m-r-8' style={{color: isItemCopy.memberId == member._id && isItemCopy.copyType == "phone" && "#ffffff"}}>{(member.user.length > 0 && member.user[0].phone) ? member.user[0].phone : "-"}</p>

                    {
                      isItemCopy.memberId == member._id && isItemCopy.copyType == "phone" && member.user[0].phone.length != 0 && [
                        isItemCopy.copied == false ? (
                          <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6992 1.66663 11.2753 1.66663 10.8333V3.33329C1.66663 2.89127 1.84222 2.46734 2.15478 2.15478C2.46734 1.84222 2.89127 1.66663 3.33329 1.66663H10.8333C11.2753 1.66663 11.6992 1.84222 12.0118 2.15478C12.3244 2.46734 12.5 2.89127 12.5 3.33329V4.16663M9.16663 7.49996H16.6666C17.5871 7.49996 18.3333 8.24615 18.3333 9.16663V16.6666C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6666V9.16663C7.49996 8.24615 8.24615 7.49996 9.16663 7.49996Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        ) : (
                          <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 10.5L7 15L16.5 4.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                          </svg>                          
                        )
                      ]
                    }
                  </div>
                </div>
                <div onMouseOver={()=>member.user[0].email && member.user[0].email.length != 0 && handleCopy(member._id, "email")} onMouseLeave={()=>member.user[0].email && member.user[0].email.length != 0 && handleCopy()} className='location-container flex-item p-r-20' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : "24%"}}>
                  <div onClick={()=>handleCopied(member.user[0].email && member.user[0].email, "email")} className={`${isItemCopy.memberId == member._id && isItemCopy.copyType == "email" && "more-table-item-container"} d-flex space-between`}>
                    <p className='flex-item' style={{color: isItemCopy.memberId == member._id && isItemCopy.copyType == "email" && "#ffffff"}}>{(member.user.length > 0 && member.user[0].email) ? member.user[0].email.length>18?member.user[0].email.substring(0,18)+"...":member.user[0].email : "-"}</p>
                    {
                      isItemCopy.memberId == member._id && isItemCopy.copyType == "email" && member.user[0].email.length != 0 && [
                        isItemCopy.copied == false ? (
                          <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6992 1.66663 11.2753 1.66663 10.8333V3.33329C1.66663 2.89127 1.84222 2.46734 2.15478 2.15478C2.46734 1.84222 2.89127 1.66663 3.33329 1.66663H10.8333C11.2753 1.66663 11.6992 1.84222 12.0118 2.15478C12.3244 2.46734 12.5 2.89127 12.5 3.33329V4.16663M9.16663 7.49996H16.6666C17.5871 7.49996 18.3333 8.24615 18.3333 9.16663V16.6666C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6666V9.16663C7.49996 8.24615 8.24615 7.49996 9.16663 7.49996Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        ) : (
                          <svg className='flex-item m-r-6' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 10.5L7 15L16.5 4.5" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                          </svg>                          
                        )
                      ]
                    }
                  </div>
                </div>
                {/* <div className='email-container flex-item' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : ""}}>
                  <p className='flex-item'>{(member.gym && member.gym.length > 0) ? member.gym[0].name : ""}</p>
                </div> */}
                {/* <div className='email-container flex-item' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : ""}}>
                  <p className='flex-item'>{(member.branch && member.branch.length > 0) ? member.branch[0].address : ""}</p>
                </div> */}
                <div className='email-container flex-item' style={{width:user.role == 8 || user.role == 7 || user.role == 4 ? "20%" : "", textAlign:"center"}}>
                  <p className='flex-item margin-auto'>{!(member.gosInfo && member.gosInfo.membership_starts_at && member.gosInfo.membership_ends_at)?"-":`${formatDateTime(member.gosInfo.membership_starts_at)} - ${formatDateTime(member.gosInfo.membership_ends_at)}`}</p>
                </div>
                {
                  user.role != 8 && user.role != 7 && user.role != 4 && (
                    <div className='action-container d-flex flex-item' style={{width:user.role == 0 || user.role == 5 ? "20%" : ""}}>
                      
                      <div onClick={() => handleWhatsappMessage(member.user[0].phone)} className='action-horizontain-dots bg-grey-09 d-flex flex-item' style={{margin:"0 20px 0 0"}}>
                        <svg className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_308_1240)">
                        <path d="M0.34123 7.90452C0.340855 9.24883 0.694855 10.5615 1.36798 11.7184L0.276855 15.6714L4.35386 14.6107C5.4815 15.2198 6.74495 15.539 8.02886 15.5391H8.03223C12.2707 15.5391 15.7209 12.1169 15.7227 7.91054C15.7235 5.87227 14.9242 3.95562 13.4721 2.51364C12.0202 1.07178 10.0893 0.277297 8.03192 0.276367C3.79298 0.276367 0.343043 3.69838 0.341293 7.90452" fill="url(#paint0_linear_308_1240)"/>
                        <path d="M0.066875 7.90177C0.0664375 9.29445 0.433125 10.654 1.13025 11.8524L0 15.9471L4.22319 14.8484C5.38681 15.4779 6.69694 15.8098 8.03006 15.8103H8.0335C12.424 15.8103 15.9981 12.265 16 7.90809C16.0007 5.79659 15.1727 3.81104 13.6687 2.3174C12.1646 0.823938 10.1646 0.000868217 8.0335 0C3.64225 0 0.068625 3.54481 0.066875 7.90177ZM2.58194 11.646L2.42425 11.3976C1.76137 10.3518 1.4115 9.14326 1.412 7.90226C1.41337 4.28087 4.38363 1.33457 8.036 1.33457C9.80475 1.33532 11.467 2.01947 12.7172 3.26078C13.9674 4.5022 14.6554 6.15243 14.6549 7.9076C14.6533 11.529 11.683 14.4757 8.0335 14.4757H8.03088C6.84256 14.475 5.67712 14.1584 4.66075 13.56L4.41888 13.4177L1.91275 14.0696L2.58194 11.646Z" fill="url(#paint1_linear_308_1240)"/>
                        <path d="M6.04223 4.5988C5.8931 4.26993 5.73616 4.26329 5.59435 4.25753C5.47823 4.25256 5.34548 4.25294 5.21285 4.25294C5.0801 4.25294 4.86441 4.30249 4.6821 4.50001C4.4996 4.69771 3.98535 5.17548 3.98535 6.1472C3.98535 7.11892 4.69866 8.05808 4.7981 8.18999C4.89766 8.32165 6.17516 10.3796 8.19841 11.1713C9.87991 11.8292 10.2221 11.6983 10.587 11.6653C10.952 11.6325 11.7648 11.1877 11.9306 10.7265C12.0965 10.2653 12.0965 9.87005 12.0468 9.78745C11.997 9.70515 11.8643 9.65573 11.6652 9.557C11.4662 9.45827 10.4875 8.98038 10.305 8.91446C10.1225 8.8486 9.98985 8.81573 9.8571 9.01349C9.72435 9.21095 9.34316 9.65573 9.22698 9.78745C9.11091 9.91948 8.99473 9.93591 8.79573 9.83712C8.59654 9.73802 7.95548 9.52971 7.19491 8.8569C6.60316 8.33337 6.20366 7.68686 6.08754 7.48909C5.97141 7.29163 6.0751 7.1846 6.17491 7.08618C6.26435 6.99768 6.37404 6.85554 6.47366 6.74025C6.57291 6.62491 6.60604 6.54261 6.67241 6.41089C6.73885 6.27904 6.7056 6.1637 6.65591 6.06491C6.60604 5.96611 6.21923 4.98931 6.04223 4.5988Z" fill="white"/>
                        </g>
                        <defs>
                        <linearGradient id="paint0_linear_308_1240" x1="772.568" y1="1539.78" x2="772.568" y2="0.276367" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#1FAF38"/>
                        <stop offset="1" stop-color="#60D669"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_308_1240" x1="800" y1="1594.71" x2="800" y2="0" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#F9F9F9"/>
                        <stop offset="1" stop-color="white"/>
                        </linearGradient>
                        <clipPath id="clip0_308_1240">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                      </div>

                      <div onClick={toggleMemberActionDropdown(member, "biometric-device")} className='action-horizontain-dots bg-grey-09 d-flex flex-item m-r-4' style={{margin:"0 20px 0 0", position:"relative"}}>
                        <svg className='flex-item svg-stroke' width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.0359 12.158C12.0359 12.158 11.0499 16.894 16.5279 19.151" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M13.7517 20.9519C10.8807 19.3309 8.66571 16.2259 9.02671 12.8249C9.02969 12.1749 9.18907 11.5351 9.4914 10.9597C9.79373 10.3843 10.2301 9.8901 10.7637 9.51888C11.4498 9.17875 12.2417 9.12024 12.9703 9.35583C13.6989 9.59142 14.3066 10.1025 14.6637 10.7799C15.6057 12.1679 14.9387 13.9589 15.6277 15.4209C16.6877 17.2219 19.3777 16.7669 20.3947 15.1239C21.9207 12.7719 21.1097 9.18088 19.5517 7.01888C17.1047 3.68188 13.5087 1.91188 8.41171 3.68088C3.6227 5.31288 1.24971 12.0619 3.77171 16.5439" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.98221 19.0018C7.11047 17.9929 6.47379 16.8029 6.11821 15.5178C5.76435 14.2369 5.70182 12.893 5.93521 11.5848C6.06679 10.7142 6.37658 9.88018 6.84521 9.1348C7.3168 8.38423 7.93476 7.73634 8.66221 7.2298C9.74173 6.5903 10.9876 6.2877 12.2402 6.3608C13.0512 6.3858 13.8502 6.5688 14.5892 6.8978C15.3282 7.2268 15.9952 7.6958 16.5492 8.2778C17.7549 9.85255 18.3553 11.8078 18.2412 13.7878" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <div id={"deviceId-" + member._id} className="table-action-container" style={{ display: isMemberActionDropdownOpenId == member._id && currentActionType == "biometric-device" && getAllBiometricDevices.length > 1 ? "block" : "none", width:"auto", right: 0, padding: '0 12px' }}>
                          <div className='d-flex'>
                            <svg className='flex-item m-r-8' width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.0359 12.158C12.0359 12.158 11.0499 16.894 16.5279 19.151" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M13.7517 20.9519C10.8807 19.3309 8.66571 16.2259 9.02671 12.8249C9.02969 12.1749 9.18907 11.5351 9.4914 10.9597C9.79373 10.3843 10.2301 9.8901 10.7637 9.51888C11.4498 9.17875 12.2417 9.12024 12.9703 9.35583C13.6989 9.59142 14.3066 10.1025 14.6637 10.7799C15.6057 12.1679 14.9387 13.9589 15.6277 15.4209C16.6877 17.2219 19.3777 16.7669 20.3947 15.1239C21.9207 12.7719 21.1097 9.18088 19.5517 7.01888C17.1047 3.68188 13.5087 1.91188 8.41171 3.68088C3.6227 5.31288 1.24971 12.0619 3.77171 16.5439" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.98221 19.0018C7.11047 17.9929 6.47379 16.8029 6.11821 15.5178C5.76435 14.2369 5.70182 12.893 5.93521 11.5848C6.06679 10.7142 6.37658 9.88018 6.84521 9.1348C7.3168 8.38423 7.93476 7.73634 8.66221 7.2298C9.74173 6.5903 10.9876 6.2877 12.2402 6.3608C13.0512 6.3858 13.8502 6.5688 14.5892 6.8978C15.3282 7.2268 15.9952 7.6958 16.5492 8.2778C17.7549 9.85255 18.3553 11.8078 18.2412 13.7878" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='flex-item'>Devices</p>
                          </div>
                          {
                            getAllBiometricDevices.map(device=>{
                              return (
                                <div onClick={()=>EnrollGosMemberToBioMetric(member.userInfo.phone,device.serial_number)}>
                                    <div style={{ margin: "2% 0", width: "100%", height: "1px", backgroundColor: "#f5f5f5" }}/>
                                    <p  className='flex-item font-weight-600 menu-list' style={{whiteSpace:"nowrap"}}>Sl No. {device.serial_number}</p>
                                </div>
                              )
                            })
                          }
                        </div>

                      </div>

                      <div onClick={toggleMemberActionDropdown(member, "more-actions")} className='action-horizontain-dots bg-grey-09 d-flex' style={{margin:"0 0 0 0"}}>
                        <span className='flex-item m-r-2'></span>
                        <span className='flex-item m-r-2'></span>
                        <span className='flex-item'></span>

                        <div id={"action-" + member._id} className="table-action-container" style={{ display: isMemberActionDropdownOpenId == member._id && currentActionType == "more-actions" ? "block" : "none" }}>
                          {
                            (user.role < 3 || user.role == 5 || user.role == 6) && [
                              <div onClick={toggleAddMemberModal} role="button" className="d-flex spacing-22">
                                <img src={UpdateIcon} className="body-content-two-action-icon" />
                                <div className="spacing-24">Edit</div>
                              </div>, 
                              <div style={{ margin: "2% 0", width: "100%", height: "1px", backgroundColor: "#f5f5f5" }}/>
                            ]
                          }
                          {
                            (user.role < 3 || user.role == 5 || user.role == 6) && [
                              <div onClick={toggleFitnessTestModal} role="button" className="d-flex spacing-22">
                                <img src={UpdateIcon} className="body-content-two-action-icon" />
                                <div className="spacing-24">Take Test</div>
                              </div>, 
                              <div style={{ margin: "2% 0", width: "100%", height: "1px", backgroundColor: "#f5f5f5"}}/>
                            ]
                          }
                          {
                            (user.role < 3 || user.role == 5 || user.role == 6) && (
                              <div role="button" onClick={handleMemberActivationModalOpen} className="d-flex spacing-22">
                                <img src={BlockIcon} className="body-content-two-action-icon" />
                                <div className="spacing-24">{(!member.deletedAt || member.deletedAt == null) ? `Inactive` : `Active`}</div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          })
          }

        </div>
        {/* <div className='bottom-pagination-bar'>
          {
            (Members.length && (isAllMembersChecked || selectedMemberIds.length > 0)) ? (
              <div className='d-flex flex-item'>
                <div onClick={handleBulkStatusChnageAction} class="global-button-container d-flex flex-item p-l-12 p-r-12" style={{ margin: "0 auto" }}>
                  <div role="button" className="d-flex">
                    <svg className='flex-item svg-fill m-r-8' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1655_2069)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 9.72391 14.219 11.781 13 13C11.781 14.219 9.72391 15 8 15C6.27609 15 4.21899 14.219 3 13C1.78101 11.781 1 9.72391 1 8C1 6.27609 1.78101 4.21899 3 3C4.21899 1.78101 6.27609 1 8 1C9.72391 1 11.781 1.78101 13 3C14.219 4.21899 15 6.27609 15 8ZM16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM5.28 4.22C5.13783 4.08752 4.94978 4.0154 4.75548 4.01883C4.56118 4.02225 4.37579 4.10097 4.23838 4.23838C4.10097 4.37579 4.02225 4.56118 4.01883 4.75548C4.0154 4.94978 4.08752 5.13783 4.22 5.28L10.72 11.78C10.7887 11.8537 10.8715 11.9128 10.9635 11.9538C11.0555 11.9948 11.1548 12.0168 11.2555 12.0186C11.3562 12.0204 11.4562 12.0018 11.5496 11.9641C11.643 11.9264 11.7278 11.8703 11.799 11.799C11.8703 11.7278 11.9264 11.643 11.9641 11.5496C12.0018 11.4562 12.0204 11.3562 12.0186 11.2555C12.0168 11.1548 11.9948 11.0555 11.9538 10.9635C11.9128 10.8715 11.8537 10.7887 11.78 10.72L5.28 4.22Z" fill="#6D6D6D" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1655_2069">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className='flex-item'>{isMembersActive ? "Mark inactive" : "Mark active"}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className='d-flex flex-item space-between'>
                <div onClick={prev} className='global-button-container d-flex flex-item'>
                  <p className='flex-item'>Previous</p>
                </div>
                <p className='flex-item pagination-txt'>{(pageNo.member - 1) * pageSize.member + 1} - {(pageNo.member - 1) * pageSize.member + Members.length}  of  {total.member}</p>
                <div onClick={next} className='global-button-container d-flex flex-item'>
                  <p className='flex-item'>Next</p>
                </div>
              </div>
            )
          }
        </div> */}


        <div className='bottom-pagination-bar space-between d-flex' style={{ transition: "180ms" }}>
          <div className='d-flex flex-item space-between' style={{ width: selectedMemberIds.length>0 ? "65%" : "100%" }}>
            <div onClick={prev} className='global-button-container d-flex flex-item'>
              <p className='flex-item'>Previous</p>
            </div>
            <p className='flex-item pagination-txt'>{(pageNo.member - 1) * pageSize.member + 1} - {(pageNo.member - 1) * pageSize.member + Members.length}  of  {total.member}</p>
            <div onClick={next} className='global-button-container d-flex flex-item'>
              <p className='flex-item'>Next</p>
            </div>
          </div>
          {
            (Members.length > 0 && (selectedMemberIds.length > 0)) && (
              <div className='d-flex flex-item m-l-60'>
                <div onClick={handleBulkStatusChnageAction} class="global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20">
                  <div role="button" className="d-flex">
                    <svg className='flex-item svg-fill m-r-8' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1655_2069)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 9.72391 14.219 11.781 13 13C11.781 14.219 9.72391 15 8 15C6.27609 15 4.21899 14.219 3 13C1.78101 11.781 1 9.72391 1 8C1 6.27609 1.78101 4.21899 3 3C4.21899 1.78101 6.27609 1 8 1C9.72391 1 11.781 1.78101 13 3C14.219 4.21899 15 6.27609 15 8ZM16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM5.28 4.22C5.13783 4.08752 4.94978 4.0154 4.75548 4.01883C4.56118 4.02225 4.37579 4.10097 4.23838 4.23838C4.10097 4.37579 4.02225 4.56118 4.01883 4.75548C4.0154 4.94978 4.08752 5.13783 4.22 5.28L10.72 11.78C10.7887 11.8537 10.8715 11.9128 10.9635 11.9538C11.0555 11.9948 11.1548 12.0168 11.2555 12.0186C11.3562 12.0204 11.4562 12.0018 11.5496 11.9641C11.643 11.9264 11.7278 11.8703 11.799 11.799C11.8703 11.7278 11.9264 11.643 11.9641 11.5496C12.0018 11.4562 12.0204 11.3562 12.0186 11.2555C12.0168 11.1548 11.9948 11.0555 11.9538 10.9635C11.9128 10.8715 11.8537 10.7887 11.78 10.72L5.28 4.22Z" fill="#6D6D6D" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1655_2069">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className='flex-item' style={{ whiteSpace: "nowrap" }}>{isMembersActive ? "Mark inactive" : "Mark active"}</p>
                  </div>
                </div>
                <div onClick={toggleManageExecutePlanner} class="global-button-container d-flex flex-item p-l-12 p-r-12">
                  <div role="button" className="d-flex">
                    <svg className='flex-item svg-fill m-r-8' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1726_1963)">
                        <path d="M7.875 6.125C7.875 5.89294 7.96719 5.67038 8.13128 5.50628C8.29538 5.34219 8.51794 5.25 8.75 5.25H13.125V7H8.75C8.51794 7 8.29538 6.90781 8.13128 6.74372C7.96719 6.57962 7.875 6.35706 7.875 6.125ZM0.875 7.875H4.375C4.60706 7.875 4.82962 7.96719 4.99372 8.13128C5.15781 8.29538 5.25 8.51794 5.25 8.75C5.25 8.98206 5.15781 9.20462 4.99372 9.36872C4.82962 9.53281 4.60706 9.625 4.375 9.625H0.875V7.875Z" fill="#2B2B2B" />
                        <path d="M3.0625 0C3.17853 0 3.28981 0.0460936 3.37186 0.128141C3.45391 0.210188 3.5 0.321468 3.5 0.4375V0.875H10.5V0.4375C10.5 0.321468 10.5461 0.210188 10.6281 0.128141C10.7102 0.0460936 10.8215 0 10.9375 0C11.0535 0 11.1648 0.0460936 11.2469 0.128141C11.3289 0.210188 11.375 0.321468 11.375 0.4375V0.875H12.25C12.7141 0.875 13.1592 1.05937 13.4874 1.38756C13.8156 1.71575 14 2.16087 14 2.625V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184374 13.1592 0 12.7141 0 12.25V2.625C0 2.16087 0.184374 1.71575 0.512563 1.38756C0.840752 1.05937 1.28587 0.875 1.75 0.875H2.625V0.4375C2.625 0.321468 2.67109 0.210188 2.75314 0.128141C2.83519 0.0460936 2.94647 0 3.0625 0V0ZM0.875 3.5V12.25C0.875 12.4821 0.967187 12.7046 1.13128 12.8687C1.29538 13.0328 1.51794 13.125 1.75 13.125H12.25C12.4821 13.125 12.7046 13.0328 12.8687 12.8687C13.0328 12.7046 13.125 12.4821 13.125 12.25V3.5H0.875Z" fill="#2B2B2B" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1726_1963">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className='flex-item' style={{ whiteSpace: "nowrap" }}>Execute Planner</p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>

      {
        isAddMemberModalOpen && (
          <ManageMemberModal isEdit={true} member={{ ...selectedMember.current }} toggleAddMemberModal={toggleAddMemberModal.bind(this)} />
        )
      }

      {
        isMemberActivationModalOpen && <ManageConfirmationModal handleAction={handleAction} title={isBulkStatusChangeAction ? `${Members.every(d=>selectedMemberIds.indexOf(d._id)!=-1) ? Members.length : selectedMemberIds.length} member(s) selected` : selectedMember.current.userInfo.name} message="By performing this action all the related features to this member will get stopped" buttonText={`Mark ${isBulkStatusChangeAction ? (isMembersActive ? "inactive" : "active") : (selectedBranch.deletedAt == null ? "inactive" : "active")}`} handleClose={handleMemberActivationModalOpen} />
      }

      {
        isManageProfileOpen && (
          <div className='global-modal-container d-flex'>
            <div className="profile-edit-modal flex-item">
              <div className="d-flex w-full space-between" style={{background: "#DEF3FF", padding:"12px", borderRadius: "8px 8px 0 0"}}>
                <p className="flex-item modal-header-text">Edit profile</p>
                <div onClick={() => handleProfileView()} className="d-flex desktop-modal-close-button" style={{width: "30px", height:"30px", background:"#f9f9f9"}}>
                  <svg className="flex-item" width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.9283 3.80866C4.77899 3.66445 4.57901 3.58465 4.37144 3.58646C4.16387 3.58826 3.96531 3.67152 3.81853 3.8183C3.67175 3.96508 3.58849 4.16364 3.58668 4.37121C3.58488 4.57879 3.66468 4.77876 3.80888 4.92807L8.38076 9.49995L3.80809 14.0718C3.73248 14.1449 3.67217 14.2322 3.63068 14.3288C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7395 3.58555 14.8437 3.62536 14.941C3.66516 15.0383 3.72395 15.1267 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4338 4.36495 15.4329C4.47007 15.432 4.57395 15.4101 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1912L9.50018 10.6194L14.0721 15.1912C14.2214 15.3354 14.4213 15.4152 14.6289 15.4134C14.8365 15.4116 15.035 15.3284 15.1818 15.1816C15.3286 15.0348 15.4119 14.8363 15.4137 14.6287C15.4155 14.4211 15.3357 14.2211 15.1915 14.0718L10.6196 9.49995L15.1915 4.92807C15.3357 4.77876 15.4155 4.57879 15.4137 4.37121C15.4119 4.16364 15.3286 3.96508 15.1818 3.8183C15.035 3.67152 14.8365 3.58826 14.6289 3.58646C14.4213 3.58465 14.2214 3.66445 14.0721 3.80866L9.50018 8.38053L4.9283 3.80786V3.80866Z" fill="black" />
                  </svg>
                </div>
              </div>
              <div className='w-full h-full' style={{padding: "20px", display: isCameraOn ? "block" : "none"}}>
                <video ref={videoRef} autoPlay style={{ width: '300px', height: '200px', display: isCameraOn ? "block" : "none"}}></video>
                <div className='d-flex w-full m-t-20'>
                  <div className='flex-item d-flex margin-auto'>
                    <div onClick={stopCamera} className="rectangle-round-btn d-flex m-r-12" style={{background:"#6d6d6d", padding: "6px 20px", position:"relative"}}>
                      <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Cancel</p>
                    </div>
                    <div onClick={captureImage} className="rectangle-round-btn d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                      <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Capture Image</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full h-full' style={{padding: "20px", display: isCameraOn ? "none" : "block"}}>
                <div className='d-flex w-full'>
                  <div className='profile-edit-image bg-green d-flex margin-auto'>
                    {imageSrc ? (
                      <img src={imageSrc} alt="Captured" />
                    ) : (selectedMember.current && selectedMember.current.user[0].profileID) ? [
                        <img id="gym-ImgWrapper" src={`${config.API_URL}/image/file/${selectedMember.current.user[0].profileID}/auto/auto/100?${Date.now()}`} loading='lazy' />,
                        <div onClick={() => handleProfileViewOpen()} className='eye-view d-flex cursor-pointer'>
                          <svg className='flex-item margin-auto' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 13C6.6 5 17.4 5 21 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 17C11.606 17 11.2159 16.9224 10.8519 16.7716C10.488 16.6209 10.1573 16.3999 9.87868 16.1213C9.6001 15.8427 9.37913 15.512 9.22836 15.1481C9.0776 14.7841 9 14.394 9 14C9 13.606 9.0776 13.2159 9.22836 12.8519C9.37913 12.488 9.6001 12.1573 9.87868 11.8787C10.1573 11.6001 10.488 11.3791 10.8519 11.2284C11.2159 11.0776 11.606 11 12 11C12.7956 11 13.5587 11.3161 14.1213 11.8787C14.6839 12.4413 15 13.2044 15 14C15 14.7956 14.6839 15.5587 14.1213 16.1213C13.5587 16.6839 12.7956 17 12 17Z" stroke="#6d6d6d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                     ] : (
                        <p className='flex-item margin-auto font-style-4'>{`${selectedMember.current.user[0].name}`.trim()[0]}</p>
                      )
                    }
                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

                  </div>
                </div>
                <div className='w-full d-flex m-t-12'>
                  <p className='margin-auto font-style-1 font-weight-600 color-grey-02'>Hello, {selectedMember.current.user[0].name}</p>
                </div>
                <div className='m-t-20 m-b-20' style={{width:"100%", height:"1px", background:"#f5f5f5"}} />
                <div className='d-flex'>
                  {
                    imageSrc ? (
                      <div className='d-flex flex-item margin-auto'>
                        <div onClick={() => handleUpdateProfile("cancel")} className="rectangle-round-btn d-flex m-r-12" style={{background:"#6d6d6d", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Cancel</p>
                        </div>
                        <div onClick={() => SaveMemberProfile()} className="rectangle-round-btn d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Update Profile</p>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex flex-item margin-auto'>
                        <div onClick={startCamera} className="rectangle-round-btn m-r-12 d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Capture Image</p>
                        </div>
                        <div className="rectangle-round-btn d-flex" style={{background:"#055075", padding: "6px 20px", position:"relative"}}>
                          <p className="font-style-1 font-weight-300" style={{color: "#ffffff"}}>Upload</p>
                          <input className='cursor-pointer' style={{width:"100%", height: "100%", zIndex: 2, position:"absolute", top: 0}} onChange={handleImageUpload} type="file" id="imageInput" name="image" accept="image/*"/>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
          
            </div>
          </div>
        ) 
      }

      {
        isProfileViewOpen && (
          <div onClick={() => handleProfileViewOpen()} className='global-modal-container d-flex'>
            <img className="profile-view-modal flex-item" src={`${config.API_URL}/image/file/${selectedMember.current.user[0].profileID}/auto/auto/100?${Date.now()}`} />
          </div>
        )
      }

      {
        isFilterModalOpen == "gym" && (
          <div className="global-modal-container d-flex">
            <div className="global-filter-modal flex-item">
              <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                <p className="flex-item modal-header-text">Select Gym</p>
                <div id="search-container-gym" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                  <svg id="search-icon-gym" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1637_1318)">
                      <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1637_1318">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input id="search-gym-input" onChange={_.debounce(handleGymSearch, 1000)} className='flex-item' type="text" placeholder="Search Gym" style={{ background: "#f5f5f5" }} />
                </div>
              </div>
              <div className='search-gym-modal-scroll' onScroll={handleGymScroll}>
                {
                  Gyms.map(data => {
                    return (
                      <div onClick={handleGymSelect(data)} className="d-flex suggestion-list-items">
                        {
                          data.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${data.logoFileID}/25/25/70`} />
                          ) : (
                            <div className='d-flex flex-item branch-gym-user-profile m-r-8' style={{
                              backgroundColor: data.logoPlaceholderColor ? data.logoPlaceholderColor : "#5d883c"
                            }}>
                              <p className='flex-item fs-14' style={{
                                margin: '0 auto',
                                color: "#ffffff"
                              }}>{`${data.name}`.trim()[0]}</p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.email}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {isGymSpinnerLoader && <div className="loader-5 center"><span></span></div>}
              </div>
              <div onClick={handleFilterModalOpen.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                <div className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                  <p className='flex-item'>Cancel</p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        isFilterModalOpen == "branch" && (
          <div className="global-modal-container d-flex">
            <div className="global-filter-modal flex-item">
              <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                <p className="flex-item modal-header-text">Select Branch</p>
                <div id="search-container-branch" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                  <svg id="search-icon-branch" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1637_1318)">
                      <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1637_1318">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input id="search-branch-input" onChange={_.debounce(handleBranchSearch, 1000)} className='flex-item' type="text" placeholder="Search" style={{ background: "#f5f5f5" }} />
                </div>
              </div>
              <div className='search-gym-modal-scroll' onScroll={handleBranchScroll}>
                {
                  Branches.map(data => {
                    return (
                      <div onClick={handleBranch(data)} className="d-flex suggestion-list-items">
                        {
                          selectedGym.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/25/25/70`} />
                          ) : (
                            <div className='d-flex flex-item branch-gym-user-profile m-r-8' style={{
                              backgroundColor: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c"
                            }}>
                              <p className='flex-item fs-14' style={{
                                margin: '0 auto',
                                color: "#ffffff"
                              }}>{`${selectedGym.name}`.trim()[0]}</p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.address ? data.address.placeName ? data.address.placeName : data.address.placeAddress ? data.address.placeAddress : data.address : ""}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {isBrachSpinnerLoader && <div className="loader-5 center"><span></span></div>}
              </div>
              <div onClick={handleFilterModalOpen.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                <div className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                  <p className='flex-item'>Cancel</p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        isMemberSyncModalOpen && (
          <div className="global-modal-container d-flex">
            <div className="biometric-sync-modal flex-item">
              <div className="d-flex w-full space-between" style={{background: "#DEF3FF", padding:"12px", borderRadius: "16px 16px 0 0"}}>
                <div className='d-flex'>
                  <svg className='flex-item m-r-8' width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0359 12.158C12.0359 12.158 11.0499 16.894 16.5279 19.151" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.7517 20.9519C10.8807 19.3309 8.66571 16.2259 9.02671 12.8249C9.02969 12.1749 9.18907 11.5351 9.4914 10.9597C9.79373 10.3843 10.2301 9.8901 10.7637 9.51888C11.4498 9.17875 12.2417 9.12024 12.9703 9.35583C13.6989 9.59142 14.3066 10.1025 14.6637 10.7799C15.6057 12.1679 14.9387 13.9589 15.6277 15.4209C16.6877 17.2219 19.3777 16.7669 20.3947 15.1239C21.9207 12.7719 21.1097 9.18088 19.5517 7.01888C17.1047 3.68188 13.5087 1.91188 8.41171 3.68088C3.6227 5.31288 1.24971 12.0619 3.77171 16.5439" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.98221 19.0018C7.11047 17.9929 6.47379 16.8029 6.11821 15.5178C5.76435 14.2369 5.70182 12.893 5.93521 11.5848C6.06679 10.7142 6.37658 9.88018 6.84521 9.1348C7.3168 8.38423 7.93476 7.73634 8.66221 7.2298C9.74173 6.5903 10.9876 6.2877 12.2402 6.3608C13.0512 6.3858 13.8502 6.5688 14.5892 6.8978C15.3282 7.2268 15.9952 7.6958 16.5492 8.2778C17.7549 9.85255 18.3553 11.8078 18.2412 13.7878" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p className="flex-item modal-header-text">Biometric Sync</p>
                </div>
                <div onClick={() => handleBiometricMemberUpdate()} className="d-flex desktop-modal-close-button" style={{width: "30px", height:"30px", background:"#f9f9f9"}}>
                  <svg className="flex-item" width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.9283 3.80866C4.77899 3.66445 4.57901 3.58465 4.37144 3.58646C4.16387 3.58826 3.96531 3.67152 3.81853 3.8183C3.67175 3.96508 3.58849 4.16364 3.58668 4.37121C3.58488 4.57879 3.66468 4.77876 3.80888 4.92807L8.38076 9.49995L3.80809 14.0718C3.73248 14.1449 3.67217 14.2322 3.63068 14.3288C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7395 3.58555 14.8437 3.62536 14.941C3.66516 15.0383 3.72395 15.1267 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4338 4.36495 15.4329C4.47007 15.432 4.57395 15.4101 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1912L9.50018 10.6194L14.0721 15.1912C14.2214 15.3354 14.4213 15.4152 14.6289 15.4134C14.8365 15.4116 15.035 15.3284 15.1818 15.1816C15.3286 15.0348 15.4119 14.8363 15.4137 14.6287C15.4155 14.4211 15.3357 14.2211 15.1915 14.0718L10.6196 9.49995L15.1915 4.92807C15.3357 4.77876 15.4155 4.57879 15.4137 4.37121C15.4119 4.16364 15.3286 3.96508 15.1818 3.8183C15.035 3.67152 14.8365 3.58826 14.6289 3.58646C14.4213 3.58465 14.2214 3.66445 14.0721 3.80866L9.50018 8.38053L4.9283 3.80786V3.80866Z" fill="black" />
                  </svg>
                </div>
              </div>
              <div className='w-full space-between d-flex' style={{padding:"12px"}}>
                <div onClick={toggleInputFields('phone-no')} className={`${(currentElementId == 'phone-no' || userPhoneNo) ? "member-global-input-field flex-item" : "member-global-input-field flex-item d-flex"}`} style={{ border: (currentElementId == 'phone-no' || userPhoneNo) ? "1px solid #055075" : "", width: "66%" }}>
                  <div className="flex-item d-flex">
                    <svg className="flex-item m-r-8" width={(currentElementId == 'phone-no' || userPhoneNo) ? "12" : "18"} height={(currentElementId == 'phone-no' || userPhoneNo) ? "12" : "18"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.48303 3.7933C5.70003 2.5833 7.70403 2.7983 8.72303 4.1603L9.98503 5.8443C10.815 6.9523 10.741 8.5003 9.75603 9.4793L9.51803 9.7173C9.49104 9.81721 9.4883 9.92211 9.51003 10.0233C9.57303 10.4313 9.91403 11.2953 11.342 12.7153C12.77 14.1353 13.64 14.4753 14.054 14.5393C14.1583 14.5603 14.2661 14.5572 14.369 14.5303L14.777 14.1243C15.653 13.2543 16.997 13.0913 18.081 13.6803L19.991 14.7203C21.628 15.6083 22.041 17.8323 20.701 19.1653L19.28 20.5773C18.832 21.0223 18.23 21.3933 17.496 21.4623C15.686 21.6313 11.469 21.4153 7.03603 17.0083C2.89903 12.8943 2.10503 9.3063 2.00403 7.5383C1.95403 6.6443 2.37603 5.8883 2.91403 5.3543L4.48303 3.7933ZM7.52303 5.0593C7.01603 4.3823 6.07203 4.3283 5.54003 4.8573L3.97003 6.4173C3.64003 6.7453 3.48203 7.1073 3.50203 7.4533C3.58203 8.8583 4.22203 12.0953 8.09403 15.9453C12.156 19.9833 15.907 20.1043 17.357 19.9683C17.653 19.9413 17.947 19.7873 18.222 19.5143L19.642 18.1013C20.22 17.5273 20.093 16.4813 19.275 16.0373L17.365 14.9983C16.837 14.7123 16.219 14.8063 15.835 15.1883L15.38 15.6413L14.85 15.1093C15.38 15.6413 15.379 15.6423 15.378 15.6423L15.377 15.6443L15.374 15.6473L15.367 15.6533L15.352 15.6673C15.3098 15.7065 15.2643 15.7419 15.216 15.7733C15.136 15.8263 15.03 15.8853 14.897 15.9343C14.627 16.0353 14.269 16.0893 13.827 16.0213C12.96 15.8883 11.811 15.2973 10.284 13.7793C8.75803 12.2613 8.16203 11.1193 8.02803 10.2533C7.95903 9.8113 8.01403 9.4533 8.11603 9.1833C8.17216 9.03137 8.25254 8.88953 8.35403 8.7633L8.38603 8.7283L8.40003 8.7133L8.40603 8.7073L8.40903 8.7043L8.41103 8.7023L8.69903 8.4163C9.12703 7.9893 9.18703 7.2823 8.78403 6.7433L7.52303 5.0593Z" fill={(currentElementId == 'phone-no' || userPhoneNo) ? "#055075" : "#6d6d6d"}/>
                    </svg>
                    <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'phone-no' || userPhoneNo) ? "10px" : "14px", color: (currentElementId == 'phone-no' || userPhoneNo) ? "#055075" : "", transition: "180ms" }}>Member Phone</p>
                  </div>
                  <input value={userPhoneNo} onChange={handleUserPhoneNumber} style={{color: userPhoneNo.length == 10 ? "#5d883c" : userPhoneNo.length > 10 ? "#ef4035" : "#2b2b2b"}} className={`${(currentElementId == 'phone-no' || userPhoneNo) ? "active-input-field" : "inactive-input-field"}`} type="number" />
                </div>
                <div onClick={handleSyncBiometricUser} className='member-button-global btn-center d-flex flex-item' style={{height:"50px", width:"30%"}}>
                  <p className='flex-item font-weight-600' style={{fontSize:"16px"}}>Sync</p>
                </div>
              </div>
              {
                isSuccessMessage ? (
                  <div className='d-flex w-full' style={{padding:"0 12px", display:"none"}}>
                    <svg className='m-r-10 flex-item' width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="13" cy="13" r="13" fill="#009A06"/>
                      <path d="M5 15L9.5 19.5L20.5 7" stroke="#F3FFF0" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    <p className='flex-item font-weight-600 color-grey-04' style={{width:"90%", lineHeight:"18px", fontSize:"12px"}}>Request Success. Wait for few minutes to reflect changes in biometric</p>
                  </div>
                ) : (
                  <div className='d-flex w-full' style={{padding:"0 12px", display:"none"}}>
                    <svg className='m-r-10 flex-item' width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.9987 0C20.1792 0 26 5.82082 26 13.0013C26 20.1805 20.1792 26 12.9987 26C5.81822 26 2.17716e-07 20.1805 2.17716e-07 13.0013C-0.00129965 5.82082 5.81822 0 12.9987 0ZM12.9987 1.9498C11.5374 1.93396 10.0875 2.20809 8.73281 2.75634C7.37816 3.30459 6.1457 4.11606 5.10675 5.1438C4.0678 6.17154 3.24301 7.39513 2.6801 8.74376C2.11719 10.0924 1.82735 11.5393 1.82735 13.0007C1.82735 14.462 2.11719 15.9089 2.6801 17.2575C3.24301 18.6062 4.0678 19.8298 5.10675 20.8575C6.1457 21.8852 7.37816 22.6967 8.73281 23.245C10.0875 23.7932 11.5374 24.0673 12.9987 24.0515C15.9088 24.0199 18.689 22.8418 20.7357 20.7727C22.7824 18.7037 23.9303 15.9109 23.9303 13.0007C23.9303 10.0904 22.7824 7.29756 20.7357 5.22855C18.689 3.15955 15.9088 1.98137 12.9987 1.9498ZM12.9935 11.0489C13.2293 11.0486 13.4572 11.1338 13.6351 11.2886C13.8129 11.4435 13.9285 11.6576 13.9606 11.8912L13.9697 12.0238L13.9749 19.1757C13.9774 19.4244 13.8848 19.6648 13.7159 19.8474C13.547 20.0301 13.3147 20.1413 13.0665 20.1582C12.8183 20.1752 12.573 20.0966 12.3808 19.9386C12.1887 19.7806 12.0642 19.5551 12.0329 19.3083L12.0251 19.177L12.0199 12.0251C12.0199 11.7665 12.1226 11.5186 12.3054 11.3357C12.4883 11.1529 12.7362 11.0502 12.9948 11.0502M13.0013 6.50455C13.1752 6.49905 13.3484 6.52854 13.5107 6.59129C13.673 6.65404 13.821 6.74876 13.946 6.86983C14.0709 6.9909 14.1703 7.13584 14.2381 7.29605C14.306 7.45627 14.341 7.62848 14.341 7.80247C14.341 7.97646 14.306 8.14867 14.2381 8.30888C14.1703 8.4691 14.0709 8.61404 13.946 8.73511C13.821 8.85618 13.673 8.9509 13.5107 9.01365C13.3484 9.0764 13.1752 9.10589 13.0013 9.10039C12.6642 9.08972 12.3444 8.9483 12.1097 8.70606C11.875 8.46381 11.7438 8.13976 11.7438 7.80247C11.7438 7.46518 11.875 7.14113 12.1097 6.89888C12.3444 6.65664 12.6642 6.51522 13.0013 6.50455Z" fill="#0088DA"/>
                    </svg>
                    <p className='flex-item font-weight-600 color-grey-04' style={{width:"90%", lineHeight:"18px", fontSize:"12px"}}>Enter Member Phone Number & click Sync to block / unblock in Biometric</p>
                  </div>
                )
              }
              <div className='d-flex w-full m-t-40 m-b-40' style={{position:"relative", padding:"0 12px"}}>
                <div style={{width:"100%", height:"1px", background:"#e0e0e0"}} />
                <div className="global-round-btn d-flex bg-grey-02" style={{width: "30px", height:"30px", position:"absolute", top: 0, left: 0, margin: "-14px -50% 0 47%"}}>
                  <p className='flex-item margin-auto'>OR</p>
                </div>
              </div>
              {
                timeLeft.hours < 0 ? (
                  <div className='d-flex w-full' style={{position:"relative", padding:"0 12px"}}>
                    <div onClick={()=>alert("Feature is under development")} className='member-button-global btn-center d-flex flex-item' style={{height:"60px", width:"100%", background:"#f5f5f5"}}>
                      <p className='flex-item font-weight-600' style={{fontSize:"16px"}}>Sync All Members</p>
                    </div>
                  </div>
                ) : (
                  <div className='d-flex w-full' style={{position:"relative", padding:"0 12px"}}>
                    <div onClick={()=>alert("Feature is under development")} className='member-button-global btn-center d-flex flex-item' style={{height:"60px", width:"100%", background:"#f5f5f5"}}>
                      <div className='flex-item' style={{margin:"0 auto"}}>
                        <p style={{fontSize:"16px", marginBottom:"4px", color:"#6d6d6d"}}>All Member Synced - 14/05/24, 12:06 PM</p>
                        <p className='flex-item' style={{color:"#ef4035", fontWeight:"600"}}>Try After  {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s</p>
                      </div>
                      {/* <p className='flex-item font-weight-600' style={{fontSize:"16px"}}>Sync All Members</p> */}
                    </div>
                  </div>
                )
              }
            </div>
          </div> 
        )
      }

      {
        isDesktopPlannerExecutionModalOpen && (
          <ManageExecutePlannerDesktop toggleManageExecutePlanner={toggleManageExecutePlanner} members={Members.every(d=>selectedMemberIds.indexOf(d._id)!=-1) ? Members : selectedMemberIds.length > 0 ? Members.filter(member => selectedMemberIds.indexOf(member._id) != -1) : []} />
        )
      }

      {
        isFitnessTestModalOpen && (
          <ManageFitnessTest member={selectedMember.current} toggleFitnessTestModal={toggleFitnessTestModal} />
        )
      }

    </Dashboard>
  )
}
