import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isAuthenticated } from "../../../auth";
import { startLoader, stopLoader } from "../../../lib/utils";
import { getAllGym, getGymCount } from "../../../gym/helper/api";
import { getAllBranches, getBranchCount } from "../../../branch/helper/api";
import config from "../../../config";
import { checkMemberAvailability, createMember, updateMember } from "../../../member/helper/api";
import Toast from '../../../lib/toast';

var typingTimer, typingBranchTimer;
var doneTypingInterval = 1000;

const ManageMemberModal = (props) => {

  const history = useHistory();
  const location = useLocation();
  const { user, token } = isAuthenticated();
  const [isButtonLock, setButtonLock] = useState(false);
  const path = location.pathname;
  const [currentElementId, setCurrentElementId] = useState("");
  const [toggleAdd, setToggleAddItem] = useState(false);
  const [isAddGymModalOpen, setAddGymModalOpen] = useState(false);
  const [isAddBranchModalOpen, setAddbranchModalOpen] = useState(false);
  const [isAddMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [workoutHistoryStartDateActive, setWorkoutHistoryStartDateActive] = useState(false);
  const [workoutGymType, setWorkoutGymType] = useState(false);
  const [workoutGymTypeValue, setWorkoutGymTypeValue] = useState("");
  const [workoutGymTypeOpenDropdown, setWorkoutGymTypeOpenDropdown] = useState(false);
  const [selectedGym, setSelectedGym] = useState('');
  const [isGymListOpen, setGymListOpen] = useState(false);
  const [isBranchListOpen, setBranchListOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [Gyms, setGyms] = useState([]);
  const [isGymSpinnerLoader, setGymSpinnerLoader] = useState(false);
  const [isBranchSpinnerLoader, setBranchSpinnerLoader] = useState(false);
  var [pageSize, setPageSize] = useState(9);
  var [pageNo, setPageNo] = useState({
    gym: 1,
    branch: 1
  });
  var [pageCount, setPageCount] = useState({
    gym: 0,
    branch: 1
  });
  var [total, setTotal] = useState({
    branch: 0,
    gym: 0
  });
  const [Branches, setBranches] = useState([]);

  var [pageSize, setPageSize] = useState(9);
  var [pageNo, setPageNo] = useState({
    gym: 1,
    branch: 1
  });
  var [pageCount, setPageCount] = useState({
    gym: 0,
    branch: 1
  });
  var [total, setTotal] = useState({
    branch: 0,
    gym: 0
  });
  const [selectedGymListID, setSelectedGymListID] = useState("");
  const [isNewMember, setNewMember] = useState(false);
  const [isMemberEditEnabled, setMemberEditEnabled] = useState(false);
  const [addNewMember, setAddNewMember] = useState({
    name: "",
    email: "",
    phone: "",
    gymID: "",
    branchID: "",
    role: -1
  });
  const [foundMember, setFoundMember] = useState('');


  const AddMember = async event => {
    event.preventDefault();
    try {
      await startLoader();
      setButtonLock(true);
      let payload = {
        gymId: selectedGym._id,
        phone: addNewMember.phone,
        name: addNewMember.name
      };
      if(addNewMember.email){
        payload.email=addNewMember.email;
      }
      if (selectedBranch) payload.branchId = selectedBranch._id;
      const response=await createMember(user._id, token, payload);
      setButtonLock(false);
      await stopLoader()
      if(response.error){
        new Toast({
          message:response.error,
          type:'error',
          duration:3000
        });
      }else{
        new Toast({
          message:"Member created successfully!",
          type:'success',
          duration:3000
        });
        props.toggleMemberModal();
        if (path == "/members") history.push(`/members`);
      }
    } catch (error) {
      setButtonLock(false);
      new Toast({
        message:"Member not created try after sometime",
        type:'error',
        duration:3000
      });
      await stopLoader()
    }
    event.stopPropagation();
  }

  const SaveMember = async event => {
    event.preventDefault();
    try {
      await startLoader();
      const payload = {
        gymId: selectedGym._id,
        phone: addNewMember.phone,
        name: addNewMember.name
      };
      if (selectedBranch) payload.branchId = selectedBranch._id;
      if(addNewMember.email) payload.email=addNewMember.email;
      const response=await updateMember(user._id, token, foundMember._id, payload);
      setButtonLock(false);
      await stopLoader()
      if(response.error){
        new Toast({
          message:response.error,
          type:'error',
          duration:3000
        });
      }else{
        new Toast({
          message:"Member updated successfully",
          type:'success',
          duration:3000
        });
        props.toggleMemberModal();
        if (path == "/members") history.push(`/members`);
      }
    } catch (error) {
      new Toast({
        message:"Server error, Unable to update member",
        type:'error',
        duration:3000
      });
      setButtonLock(false);
      await stopLoader();
    }
    event.stopPropagation();
  }

  const handleBranchSelect = branch => event => {
    event.preventDefault()
    setSelectedBranch(branch);
    event.stopPropagation()
  }

  const removeBranch = event => {
    event.preventDefault()
    setSelectedBranch("");
    event.stopPropagation()
  }

  const removeGym = event => {
    event.preventDefault()
    setSelectedGym("")
    event.preventDefault()
  }

  const checkDataField = () => {
    if (addNewMember.name && addNewMember.phone && selectedGym && selectedBranch) {
      setButtonLock(false)
    } else {
      setButtonLock(true);
    }
  }



  const handleChange = (name, value) => event => {
    value = !value ? event.target.value : value;
    setAddNewMember({
      ...addNewMember,
      [name]: value
    });
  }




  const getGymParams = () => {
    let params = {
      pageNo: pageNo.gym,
      pageSize
    }

    params.activationStatus = "active";

    if (document.getElementById("search-gym-input") && document.getElementById("search-gym-input").value) params.q = document.getElementById("search-gym-input").value;
    return params;
  }


  const GetAllGyms = async () => {
    try {
      let params = getGymParams();
      let response = await getAllGym(user._id, token, params)
      setGyms([...Gyms, ...response]);
    } catch (error) {

    }
  }

  const GetAllGymCount = async () => {
    try {
      let params = getGymParams()
      delete params.pageNo;
      delete params.pageNo;
      let response = await getGymCount(user._id, token, params);
      pageCount.gym = Math.ceil(response.COUNT / pageSize);
      total.gym = response.COUNT;
      setPageCount({
        ...pageCount,
      })
      setTotal({
        ...total,
        gym: response.COUNT
      })
    } catch (error) {

    }
  }


  const getBranchParams = () => {
    let params = {
      pageNo: pageNo.branch,
      pageSize,
      activationStatus: "active"
    }

    if (selectedGymListID) {
      params.gymIds = [selectedGymListID]
    }
    if (document.getElementById("search-branch-input") && document.getElementById("search-branch-input").value) params.q = document.getElementById("search-branch-input").value;


    return params;
  }



  const GetAllBranches = async () => {
    try {
      let params = getBranchParams();
      let response = await getAllBranches(user._id, token, params)
      setBranches(response);
    } catch (error) {

    }
  }

  const GetAllBranchCount = async () => {
    try {
      let params = getBranchParams()
      delete params.pageNo;
      delete params.pageSize;
      let response = await getBranchCount(user._id, token, params);
      setPageCount({
        ...pageCount,
        branch: Math.ceil(pageSize / response.COUNT)
      })
      setTotal({
        ...total,
        branch: response.COUNT
      })
    } catch (error) {

    }
  }





  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (id == 'workout-gym-type') setWorkoutGymTypeOpenDropdown(!workoutGymTypeOpenDropdown);
  }

  useEffect(()=>{
    setTimeout(()=>{
      let element = document.getElementById(currentElementId);
      if (element) {
        element.focus();
      }
    },100);
  },[currentElementId]);


  const CheckMemberAvailability = async event => {
    event.preventDefault()
    try {
      if (!addNewMember.email) return "";
      await startLoader()
      let response = await checkMemberAvailability(user._id, token, {
        email: addNewMember.email,
        gymId: selectedGym._id
      });
      if (response.error) {
        // do something
        setMemberEditEnabled(false);
        setFoundMember("");
        setAddNewMember(oldstate => {
          oldstate.name = "";
          oldstate.phone = "";
          return { ...oldstate }
        });
        new Toast({
          message:response.error,
          type:'error',
          duration:3000
        });
      } else {
        setMemberEditEnabled(true);
        if (response.gymList && response.gymList.length) {
          setSelectedGym(response.gymList[0])
        }
        if (response.branchList && response.branchList.length) {
          setSelectedBranch(response.branchList[0])
        }
        setFoundMember(response);
      }
      toggleAddNewMember();
      await stopLoader()
    } catch (error) {
      await stopLoader()
    }
    event.stopPropagation()
  }

  const toggleAddNewMember = () => {
    if (isNewMember) {
      setNewMember(false)
    } else {
      setNewMember(true)
    }
  }


  const toggleGymList = async event => {
    event.preventDefault()
    if (isGymListOpen) {
      setGymListOpen(false)
    } else {
      setGymListOpen(true)
    }
    event.stopPropagation()
  }



  const toggleBranchList = gym => event => {
    event.preventDefault();
    if (selectedGymListID == gym._id) setSelectedGymListID('');
    else setSelectedGymListID(gym._id);
    event.preventDefault();
  }


  const handleSelectedGym = (gym) => async event => {
    event.preventDefault()
    try {
      setGymListOpen(false);
      setSelectedGym(gym);
    } catch (error) {
      //do something
    }
    event.stopPropagation()
  }



  const handleGymScroll = async event => {
    try {

      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.gym < pageCount.gym && !isGymSpinnerLoader) {

        setGymSpinnerLoader(true)
        pageNo.gym = pageNo.gym + 1;
        setPageNo({
          ...pageNo
        })
        await GetAllGyms();
        setGymSpinnerLoader(false)
      }
    } catch (error) {
      setGymSpinnerLoader(false)
    }
  }

  const handleBranchScroll = async event => {
    try {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if ((scrollTop > 0) && ((Math.ceil((scrollTop + clientHeight)) >= scrollHeight)) && pageNo.branch < pageCount.branch && !isGymSpinnerLoader) {
        setBranchSpinnerLoader(true)
        pageNo.branch = pageNo.branch + 1;
        setPageNo({
          ...pageNo
        })
        await GetAllBranchCount();
        setBranchSpinnerLoader(false)
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
    }
  }



  const reselectGym = () => {
    setBranchListOpen(false);
    setSelectedBranch("")
    setGymListOpen(true);
    setSelectedGym("")
  }

  const reselectBranch = () => {
    setBranchListOpen(true);
    setSelectedBranch({
      address: ""
    })
  }



  const searchGym = async () => {
    try {
      setGymSpinnerLoader(true)
      setGyms([]);
      setPageCount({
        ...pageCount,
        gym: 1
      })
      setPageNo({
        ...pageNo,
        gym: 1
      });
      setTotal({
        ...total,
        gym: 0
      })
      await GetAllGymCount();
      await GetAllGyms();
      setGymSpinnerLoader(false)
    } catch (error) {
      // do something
      setGymSpinnerLoader(false)
    }
  }

  const searchBranch = async () => {
    try {
      setBranchSpinnerLoader(true)
      setBranches([]);
      setPageCount({
        ...pageCount,
        branch: 1,
      })
      setPageNo({
        ...pageNo,
        branch: 1,
      });
      setTotal({
        ...total,
        branch: 0
      })
      await GetAllBranchCount();
      await GetAllBranches();
      setBranchSpinnerLoader(false)
    } catch (error) {
      // do something
      setBranchSpinnerLoader(false)
    }
  }

  useEffect(()=>{
    checkDataField();
  },[addNewMember,selectedBranch,selectedGym]);

  useEffect(async () => {

    if (props.member && props.isEdit) {
      setMemberEditEnabled(true);
      if (props.member.gym && props.member.gym.length) setSelectedGym(props.member.gym[0]);
      if (props.member.branch && props.member.branch.length) setSelectedBranch(props.member.branch[0]);
      if (props.member.user && props.member.user.length) setAddNewMember({
        ...addNewMember,
        email: props.member.user[0].email,
        phone: props.member.user[0].phone,
        name: props.member.user[0].name
      });
      setFoundMember(props.member);
      setButtonLock(false)
      setGymListOpen(false)
    } else {
      setMemberEditEnabled(false);
      setFoundMember("");
      setAddNewMember({
        ...addNewMember,
        name: "",
        email: '',
        phone: ""
      })
      setButtonLock(true)
      setSelectedBranch("");
      setSelectedGym("");
    }
  }, [props])

  useEffect(async () => {
    try {
      let searchGymInputField = document.getElementById("search-gym-input");
      if (searchGymInputField) {
        searchGymInputField.onkeyup = e => {
          clearTimeout(typingTimer);
          typingTimer = setTimeout(searchGym, doneTypingInterval);
        };
        searchGymInputField.onkeydown = e => {
          clearTimeout(typingTimer);
        }
      }

      let searchBranchInputField = document.getElementById("search-branch-input");
      if (searchBranchInputField) {
        searchBranchInputField.onkeyup = e => {
          clearTimeout(typingBranchTimer);
          typingBranchTimer = setTimeout(searchBranch, doneTypingInterval)
        }
        searchBranchInputField.onkeydown = e => {
          clearTimeout(typingBranchTimer)
        }
      }
      setGyms([]);
      setBranches([]);
      setPageCount({
        ...pageCount,
        branch: 1,
        gym: 1
      })
      setPageNo({
        ...pageNo,
        branch: 1,
        gym: 1
      });
      setTotal({
        ...total,
        branch: 0,
        gym: 0
      })
      await startLoader()
      await GetAllGymCount()
      await GetAllGyms();
      await stopLoader();
    } catch (error) {
      await stopLoader()
    }
  }, [])

  useEffect(async () => {
    try {
      if (selectedGymListID) {
        setBranchSpinnerLoader(true);
        await GetAllBranchCount()
        await GetAllBranches();
        setBranchSpinnerLoader(false);
      }
    } catch (error) {
      setBranchSpinnerLoader(false)
    }
  }, [selectedGymListID])


  return (
    <div className="mobile-bottom-modal-container" onClick={props.toggleMemberModal}>
      <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
        <div onClick={props.toggleMemberModal} className="w-full">
          <div className="draggable-pointer" />
        </div>
        <p className="flex-item modal-header-text">{props.isEdit ? "Edit" : "Add"} Member</p>
        <div onClick={e => e.stopPropagation()} className="global-modal-scroll-container-mob">
        
              {(isMemberEditEnabled && foundMember) && (
                <div className="w-full">
                  <div className="d-flex w-full m-b-16">
                    {
                      foundMember.user.length > 0 && (
                        foundMember.user[0].profileID ? (
                          <img className="branch-gym-logo m-r-8 flex-item" src={`${config.API_URL}/image/file/${foundMember.user[0].profileID}/auto/auto/70`} />
                        ) : (
                          <div className="d-flex branch-gym-logo m-r-8" style={{ background: foundMember.user[0].profilePlaceHolderColor ? foundMember.user[0].profilePlaceHolderColor : "#5d883c" }}>
                            <p className="flex-item" style={{ color: '#ffffff', margin: '0 auto' }}>{`${foundMember.user[0].name}`.trim()[0]}</p>
                          </div>
                        )
                      )
                    }
                    <div className="flex-item">
                      <p className="branch-gym-name">{foundMember.user.length > 0 ? foundMember.user[0].name : ""}</p>
                      <p className="branch-gym-email">Member</p>
                    </div>
                    {
                      !props.isEdit && <svg onClick={toggleAddNewMember.bind(this)} className="m-l-12 flex-item cursor-pointer" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.1678 8C20.389 6.21646 19.1068 4.69884 17.4784 3.63311C15.8499 2.56738 13.946 1.99983 11.9998 2C6.8148 2 2.5498 5.947 2.0498 11" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.9999 8H21.3999C21.4787 8 21.5567 7.98448 21.6295 7.95433C21.7023 7.92417 21.7684 7.87998 21.8241 7.82426C21.8798 7.76855 21.924 7.70241 21.9542 7.62961C21.9843 7.55681 21.9999 7.47879 21.9999 7.4V3M2.88086 16C4.42486 19.532 7.94886 22 12.0489 22C17.2349 22 21.4989 18.053 21.9999 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.0498 16H2.6498C2.49067 16 2.33806 16.0632 2.22554 16.1757C2.11302 16.2883 2.0498 16.4409 2.0498 16.6V21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    }
                  </div>
                  <div className="d-flex w-full m-b-8">
                    <div className="d-flex" style={{ width: "20%" }}>
                      <svg className="flex-item m-r-6" width="12" height="9" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75 0H0.75C0.551088 0 0.360322 0.0790176 0.21967 0.21967C0.0790176 0.360322 0 0.551088 0 0.75V6.75C0 6.94891 0.0790176 7.13968 0.21967 7.28033C0.360322 7.42098 0.551088 7.5 0.75 7.5H9.75C9.94891 7.5 10.1397 7.42098 10.2803 7.28033C10.421 7.13968 10.5 6.94891 10.5 6.75V0.75C10.5 0.551088 10.421 0.360322 10.2803 0.21967C10.1397 0.0790176 9.94891 0 9.75 0V0ZM8.925 0.75L5.25 3.2925L1.575 0.75H8.925ZM0.75 6.75V1.09125L5.03625 4.0575C5.09902 4.10105 5.1736 4.12438 5.25 4.12438C5.3264 4.12438 5.40098 4.10105 5.46375 4.0575L9.75 1.09125V6.75H0.75Z" fill="#055075" />
                      </svg>
                      <p className="flex-item branch-gym-details-heading">Email</p>
                    </div>
                    <p className="branch-gym-details-value">{foundMember.user.length > 0 ? foundMember.user[0].email : ""}</p>
                  </div>
                  <div className="d-flex w-full m-b-30">
                    <div className="d-flex" style={{ width: "20%" }}>
                      <svg className="flex-item m-r-6" width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.74967 9.875H8.68592C1.31717 9.45125 0.270916 3.23375 0.124666 1.33625C0.112881 1.18872 0.130324 1.04031 0.175994 0.899529C0.221664 0.758749 0.294663 0.628364 0.39081 0.515845C0.486956 0.403325 0.60436 0.310883 0.736295 0.243815C0.868229 0.176747 1.0121 0.136371 1.15967 0.125H3.22592C3.37612 0.124855 3.52292 0.169816 3.64728 0.254061C3.77164 0.338306 3.86784 0.457951 3.92342 0.5975L4.49342 2C4.5483 2.13633 4.56192 2.28578 4.53258 2.42978C4.50325 2.57379 4.43225 2.706 4.32842 2.81L3.52967 3.61625C3.65443 4.32528 3.99398 4.97888 4.50238 5.48861C5.01077 5.99834 5.66347 6.33961 6.37217 6.46625L7.18592 5.66C7.29148 5.55731 7.42494 5.48797 7.56965 5.46063C7.71436 5.43329 7.86391 5.44915 7.99967 5.50625L9.41342 6.0725C9.55086 6.12983 9.66813 6.22678 9.75027 6.351C9.83241 6.47522 9.87572 6.62108 9.87467 6.77V8.75C9.87467 9.04837 9.75614 9.33452 9.54516 9.5455C9.33418 9.75647 9.04803 9.875 8.74967 9.875ZM1.24967 0.875C1.15021 0.875 1.05483 0.914509 0.984501 0.984835C0.914174 1.05516 0.874666 1.15054 0.874666 1.25V1.28C1.04717 3.5 2.15342 8.75 8.72717 9.125C8.77643 9.12804 8.82581 9.12133 8.87248 9.10525C8.91914 9.08917 8.96218 9.06403 8.99911 9.03128C9.03604 8.99854 9.06614 8.95882 9.08768 8.91441C9.10923 8.87 9.1218 8.82178 9.12467 8.7725V6.77L7.71092 6.20375L6.63467 7.2725L6.45467 7.25C3.19217 6.84125 2.74967 3.57875 2.74967 3.545L2.72717 3.365L3.79217 2.28875L3.22967 0.875H1.24967Z" fill="#055075" />
                      </svg>
                      <p className="flex-item branch-gym-details-heading">Phone</p>
                    </div>
                    <p className="branch-gym-details-value">+91 {foundMember.user.length > 0 ? foundMember.user[0].phone : ""}</p>
                  </div>
                </div>
              )} 
              <>
              <div onClick={toggleInputFields('user-name')} className={`${currentElementId == "user-name" || addNewMember.name ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "user-name" || addNewMember.name ? "1px solid #055075" : "" }}>
                <div className="flex-item d-flex">
                  <svg className="flex-item m-r-8" width={currentElementId == "user-name" || addNewMember.name ? "12" : "18"} height={currentElementId == "user-name" || addNewMember.name ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 5.15C4.9528 5.15 4.1 4.2972 4.1 3.25C4.1 2.2028 4.9528 1.35 6 1.35C7.0472 1.35 7.9 2.2028 7.9 3.25C7.9 4.2972 7.0472 5.15 6 5.15ZM10.15 10V10.15H10H1.85V10C1.85 8.26377 3.26333 6.85 5 6.85H7C8.7362 6.85 10.15 8.2638 10.15 10Z" stroke={currentElementId == "user-name" || addNewMember.name ? "#055075" : "#6d6d6d"} stroke-width="0.7" />
                  </svg>
                  <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId == "user-name" || addNewMember.name ? "10px" : "14px", color: currentElementId == "user-name" || addNewMember.name ? "#055075" : "", transition: "180ms" }}>Name*</p>
                </div>
                <input id="user-name" onChange={handleChange('name')} value={addNewMember.name} className={`${currentElementId == "user-name" || addNewMember.name ? "active-input-field" : "inactive-input-field"}`} type="text" />
              </div>
              <div onClick={toggleInputFields('user-phone')} className={`${currentElementId == "user-phone" || addNewMember.phone ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "user-phone" || addNewMember.phone ? "1px solid #055075" : "" }}>
                <div className="flex-item d-flex">
                  <svg className="flex-item m-r-12" width={currentElementId == "user-phone" || addNewMember.phone ? "12" : "16"} height={currentElementId == "user-phone" || addNewMember.phone ? "12" : "16"} style={{ transition: "180ms" }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.74967 9.875H8.68592C1.31717 9.45125 0.270916 3.23375 0.124666 1.33625C0.112881 1.18872 0.130324 1.04031 0.175994 0.899529C0.221664 0.758749 0.294663 0.628364 0.39081 0.515845C0.486956 0.403325 0.60436 0.310883 0.736295 0.243815C0.868229 0.176747 1.0121 0.136371 1.15967 0.125H3.22592C3.37612 0.124855 3.52292 0.169816 3.64728 0.254061C3.77164 0.338306 3.86784 0.457951 3.92342 0.5975L4.49342 2C4.5483 2.13633 4.56192 2.28578 4.53258 2.42978C4.50325 2.57379 4.43225 2.706 4.32842 2.81L3.52967 3.61625C3.65443 4.32528 3.99398 4.97888 4.50238 5.48861C5.01077 5.99834 5.66347 6.33961 6.37217 6.46625L7.18592 5.66C7.29148 5.55731 7.42494 5.48797 7.56965 5.46063C7.71436 5.43329 7.86391 5.44915 7.99967 5.50625L9.41342 6.0725C9.55086 6.12983 9.66813 6.22678 9.75027 6.351C9.83241 6.47522 9.87572 6.62108 9.87467 6.77V8.75C9.87467 9.04837 9.75614 9.33452 9.54516 9.5455C9.33418 9.75647 9.04803 9.875 8.74967 9.875ZM1.24967 0.875C1.15021 0.875 1.05483 0.914509 0.984501 0.984835C0.914174 1.05516 0.874666 1.15054 0.874666 1.25V1.28C1.04717 3.5 2.15342 8.75 8.72717 9.125C8.77643 9.12805 8.82581 9.12133 8.87248 9.10525C8.91914 9.08917 8.96218 9.06403 8.99911 9.03128C9.03604 8.99854 9.06614 8.95882 9.08768 8.91441C9.10923 8.87 9.1218 8.82178 9.12467 8.7725V6.77L7.71092 6.20375L6.63467 7.2725L6.45467 7.25C3.19217 6.84125 2.74967 3.57875 2.74967 3.545L2.72717 3.365L3.79217 2.28875L3.22967 0.875H1.24967Z" fill={currentElementId == "user-phone" || addNewMember.phone ? "#055075" : "#6d6d6d"} />
                  </svg>
                  <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId == "user-phone" || addNewMember.phone ? "10px" : "14px", color: currentElementId == "user-phone" || addNewMember.phone ? "#055075" : "", transition: "180ms" }}>Phone*</p>
                </div>
                <input id="user-phone" onChange={handleChange('phone')} value={addNewMember.phone} className={`${currentElementId == "user-phone" || addNewMember.phone ? "active-input-field" : "inactive-input-field"}`} type="number" />
              </div>
              <div onClick={toggleInputFields('email')} className={`${( currentElementId == "email" || addNewMember.email) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: ( currentElementId == "email" || addNewMember.email) ? "1px solid #055075" : "" }}>
                <div className="flex-item d-flex">
                  <svg className="flex-item m-r-12" width={( currentElementId == "email" || addNewMember.email) ? "14" : "18"} height={( currentElementId == "email" || addNewMember.email) ? "14" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0.25H1.5C1.30109 0.25 1.11032 0.329018 0.96967 0.46967C0.829018 0.610322 0.75 0.801088 0.75 1V7C0.75 7.19891 0.829018 7.38968 0.96967 7.53033C1.11032 7.67098 1.30109 7.75 1.5 7.75H10.5C10.6989 7.75 10.8897 7.67098 11.0303 7.53033C11.171 7.38968 11.25 7.19891 11.25 7V1C11.25 0.801088 11.171 0.610322 11.0303 0.46967C10.8897 0.329018 10.6989 0.25 10.5 0.25ZM9.675 1L6 3.5425L2.325 1H9.675ZM1.5 7V1.34125L5.78625 4.3075C5.84902 4.35105 5.9236 4.37438 6 4.37438C6.0764 4.37438 6.15098 4.35105 6.21375 4.3075L10.5 1.34125V7H1.5Z" fill={( currentElementId == "email" || addNewMember.email) ? "#055075" : "#6d6d6d"} />
                  </svg>
                  <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId === "email" ? "10px" : "14px", color: ( currentElementId == "email" || addNewMember.email) ? "#055075" : "", transition: "180ms" }}>Email (Optional)</p>
                </div>
                <input id="email" value={addNewMember.email} onChange={handleChange("email")} className={`${( currentElementId == "email" || addNewMember.email) ? "active-input-field" : "inactive-input-field"}`} type="text" />
              </div>
              </>
              <div className="d-flex w-full m-b-14 m-l-6">
                <svg className="flex-item m-r-8" width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.04078 1H4.96078C4.78574 1 4.61786 1.06954 4.49409 1.19331C4.37032 1.31708 4.30078 1.48496 4.30078 1.66V11.34C4.30078 11.4267 4.31785 11.5125 4.35102 11.5926C4.38419 11.6726 4.4328 11.7454 4.49409 11.8067C4.55538 11.868 4.62814 11.9166 4.70821 11.9498C4.78829 11.9829 4.87411 12 4.96078 12H8.04078C8.12745 12 8.21328 11.9829 8.29335 11.9498C8.37343 11.9166 8.44618 11.868 8.50747 11.8067C8.56876 11.7454 8.61737 11.6726 8.65054 11.5926C8.68371 11.5125 8.70078 11.4267 8.70078 11.34V1.66C8.70078 1.48496 8.63125 1.31708 8.50747 1.19331C8.3837 1.06954 8.21582 1 8.04078 1V1ZM21.2408 1H18.1608C17.9857 1 17.8179 1.06954 17.6941 1.19331C17.5703 1.31708 17.5008 1.48496 17.5008 1.66V11.34C17.5008 11.4267 17.5179 11.5125 17.551 11.5926C17.5842 11.6726 17.6328 11.7454 17.6941 11.8067C17.7554 11.868 17.8281 11.9166 17.9082 11.9498C17.9883 11.9829 18.0741 12 18.1608 12H21.2408C21.3275 12 21.4133 11.9829 21.4934 11.9498C21.5734 11.9166 21.6462 11.868 21.7075 11.8067C21.7688 11.7454 21.8174 11.6726 21.8505 11.5926C21.8837 11.5125 21.9008 11.4267 21.9008 11.34V1.66C21.9008 1.48496 21.8312 1.31708 21.7075 1.19331C21.5837 1.06954 21.4158 1 21.2408 1V1Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.7 6.5002H17.5M1 9.1402V3.8602C1 3.68515 1.06954 3.51728 1.19331 3.3935C1.31708 3.26973 1.48496 3.2002 1.66 3.2002H3.64C3.81504 3.2002 3.98292 3.26973 4.10669 3.3935C4.23046 3.51728 4.3 3.68515 4.3 3.8602V9.1402C4.3 9.31524 4.23046 9.48311 4.10669 9.60689C3.98292 9.73066 3.81504 9.8002 3.64 9.8002H1.66C1.48496 9.8002 1.31708 9.73066 1.19331 9.60689C1.06954 9.48311 1 9.31524 1 9.1402V9.1402ZM25.2 9.1402V3.8602C25.2 3.68515 25.1305 3.51728 25.0067 3.3935C24.8829 3.26973 24.715 3.2002 24.54 3.2002H22.56C22.385 3.2002 22.2171 3.26973 22.0933 3.3935C21.9695 3.51728 21.9 3.68515 21.9 3.8602V9.1402C21.9 9.31524 21.9695 9.48311 22.0933 9.60689C22.2171 9.73066 22.385 9.8002 22.56 9.8002H24.54C24.715 9.8002 24.8829 9.73066 25.0067 9.60689C25.1305 9.48311 25.2 9.31524 25.2 9.1402V9.1402Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="flex-item m-r-12 fs-16">Gym</p>
                {
                  (!selectedGym) ? (
                    <div onClick={toggleGymList.bind(this)} style={{ transform: isGymListOpen ? "rotate(45deg)" : "" }} className="flex-item d-flex cursor-pointer global-modal-icon">
                      <svg className="flex-item" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="6.50977" width="2" height="15" rx="1" fill="#9A9A9A" />
                        <rect x="15" y="6.4353" width="2" height="15" rx="1" transform="rotate(89.5058 15 6.4353)" fill="#9A9A9A" />
                      </svg>
                    </div>
                  ) : (
                    <svg onClick={reselectGym.bind(this)} className="flex-item cursor-pointer" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_2137_1490)">
                        <path d="M16.7581 6.3335C16.1416 4.92153 15.1265 3.72008 13.8373 2.87637C12.5481 2.03267 11.0408 1.58336 9.50013 1.5835C5.39534 1.5835 2.01888 4.7082 1.62305 8.7085" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.4588 6.33333H16.9421C17.0045 6.33333 17.0663 6.32105 17.1239 6.29718C17.1815 6.27331 17.2339 6.23832 17.278 6.19421C17.3221 6.1501 17.3571 6.09774 17.381 6.04011C17.4048 5.98248 17.4171 5.92071 17.4171 5.85833V2.375M2.28125 12.6667C3.50358 15.4628 6.29342 17.4167 9.53925 17.4167C13.6448 17.4167 17.0205 14.292 17.4171 10.2917" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.58138 12.667H2.09805C1.97207 12.667 1.85125 12.717 1.76217 12.8061C1.67309 12.8952 1.62305 13.016 1.62305 13.142V16.6253" stroke="#6D6D6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_2137_1490">
                          <rect width="19" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )
                }
              </div>
              <div className="m-b-24">
               

                {
                  (selectedGym && !isGymListOpen) && (
                    <div className="selected-gym-container m-b-14">
                      <div className="w-full d-flex">
                        <div className="d-flex w-full" style={{ width: "90%" }}>
                          {
                            selectedGym.logoFileID ? (
                              <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${selectedGym.logoFileID}/auto/auto/70`} />
                            ) : (
                              <div className="d-flex flex-item branch-gym-user-profile m-r-8" style={{
                                backgroundColor: selectedGym.logoPlaceholderColor ? selectedGym.logoPlaceholderColor : "#5d883c"
                              }}>
                                <p className="flex-item" style={{
                                  color: "#ffffff",
                                  margin: '0 auto'
                                }}>{`${selectedGym.name}`.trim()[0]}</p>
                              </div>
                            )
                          }
                          <div className="flex-item">
                            <p className="branch-gym-user-name m-b-2 m-t-4 fs-16" style={{ fontWeight: 'bold' }}>{selectedGym.name}</p>
                            <p className="branch-gym-details-value">{selectedGym.email}</p>
                          </div>
                        </div>
                        <div onClick={removeGym} className='bell-menu-container header-menu-active cursor-pointer d-flex'>
                          <svg className="bell flex-item" width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 10.5V11H28V10.5C28 9.43913 27.5786 8.42172 26.8284 7.67157C26.0783 6.92143 25.0609 6.5 24 6.5C22.9391 6.5 21.9217 6.92143 21.1716 7.67157C20.4214 8.42172 20 9.43913 20 10.5ZM17.5 11V10.5C17.5 8.77609 18.1848 7.12279 19.4038 5.90381C20.6228 4.68482 22.2761 4 24 4C25.7239 4 27.3772 4.68482 28.5962 5.90381C29.8152 7.12279 30.5 8.77609 30.5 10.5V11H41.75C42.0815 11 42.3995 11.1317 42.6339 11.3661C42.8683 11.6005 43 11.9185 43 12.25C43 12.5815 42.8683 12.8995 42.6339 13.1339C42.3995 13.3683 42.0815 13.5 41.75 13.5H38.833L36.833 37.356C36.681 39.1676 35.854 40.856 34.5158 42.0866C33.1776 43.3172 31.426 44.0001 29.608 44H18.392C16.5742 43.9998 14.8228 43.3168 13.4848 42.0863C12.1468 40.8557 11.3199 39.1674 11.168 37.356L9.168 13.5H6.25C5.91848 13.5 5.60054 13.3683 5.36612 13.1339C5.1317 12.8995 5 12.5815 5 12.25C5 11.9185 5.1317 11.6005 5.36612 11.3661C5.60054 11.1317 5.91848 11 6.25 11H17.5ZM21.5 20.25C21.5 20.0858 21.4677 19.9233 21.4049 19.7716C21.342 19.62 21.25 19.4822 21.1339 19.3661C21.0178 19.25 20.88 19.158 20.7284 19.0951C20.5767 19.0323 20.4142 19 20.25 19C20.0858 19 19.9233 19.0323 19.7716 19.0951C19.62 19.158 19.4822 19.25 19.3661 19.3661C19.25 19.4822 19.158 19.62 19.0951 19.7716C19.0323 19.9233 19 20.0858 19 20.25V34.75C19 34.9142 19.0323 35.0767 19.0951 35.2284C19.158 35.38 19.25 35.5178 19.3661 35.6339C19.4822 35.75 19.62 35.842 19.7716 35.9049C19.9233 35.9677 20.0858 36 20.25 36C20.4142 36 20.5767 35.9677 20.7284 35.9049C20.88 35.842 21.0178 35.75 21.1339 35.6339C21.25 35.5178 21.342 35.38 21.4049 35.2284C21.4677 35.0767 21.5 34.9142 21.5 34.75V20.25ZM27.75 19C27.06 19 26.5 19.56 26.5 20.25V34.75C26.5 35.0815 26.6317 35.3995 26.8661 35.6339C27.1005 35.8683 27.4185 36 27.75 36C28.0815 36 28.3995 35.8683 28.6339 35.6339C28.8683 35.3995 29 35.0815 29 34.75V20.25C29 19.56 28.44 19 27.75 19Z" fill="#2b2b2b" />
                          </svg>
                        </div>
                      </div>

                      <div className="d-flex  m-t-20 m-b-12">
                        <p className="flex-item fs-16 m-r-12" style={{ fontWeight: 'bold' }}>Branch</p>
                        <div onClick={toggleBranchList(selectedGym)} style={{ transform: selectedGymListID == selectedGym._id ? "rotate(45deg)" : "" }} className="flex-item d-flex cursor-pointer global-modal-icon">
                          <svg className="flex-item" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="6.50977" width="2" height="15" rx="1" fill="#9A9A9A" />
                            <rect x="15" y="6.4353" width="2" height="15" rx="1" transform="rotate(89.5058 15 6.4353)" fill="#9A9A9A" />
                          </svg>
                        </div>
                      </div>
                      {
                        (selectedGymListID == selectedGym._id) && (
                          <div className="w-full m-t-12 m-b-20" style={{ padding: "12px", borderRadius: "12px", background: "#f5f5f5" }}>
                            <div className="d-flex w-full space-between m-b-12">
                              <p className="flex-item fs-16 m-l-5">Branch's List</p>
                              <div id="search-container" className='global-search-box m-r-12'>
                                <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1637_1318)">
                                    <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1637_1318">
                                      <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <input id="search-branch-input" className='flex-item' type="text" placeholder="Branch Address" />
                              </div>
                            </div>
                            <div className="flex-item w-full suggestion-list-container-gym-1" onScroll={handleBranchScroll}>
                              {
                                Branches.filter(d => selectedBranch._id != d._id).map(branch => {
                                  return (
                                    <div onClick={handleBranchSelect(branch)} className="d-flex suggestion-list-items">
                                      <div className="flex-item">
                                        <p className="branch-gym-user-name m-b-1">{branch.name}</p>
                                        <p className="branch-gym-details-value">{branch.address ? branch.address.placeName ? branch.address.placeName : branch.address.placeAddress ? branch.address.placeAddress : branch.address : ""}</p>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                              {isBranchSpinnerLoader && <div className="loader-5 center"><span></span></div>}


                            </div>
                          </div>
                        )
                      }


                      {
                        selectedBranch && (
                          <div className="w-full m-b-12 selected-gym-container" style={{
                            borderRadius: 6
                          }}>
                            <div className="d-flex cursor-pointer">
                              <p className="fs-14 flex-item" style={{ width: "90%" }}>{selectedBranch.name}</p>
                              <div onClick={removeBranch} className='bell-menu-container header-menu-active cursor-pointer d-flex' style={{
                                boxShadow: 'none'
                              }}>
                                <svg className="bell flex-item" width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20 10.5V11H28V10.5C28 9.43913 27.5786 8.42172 26.8284 7.67157C26.0783 6.92143 25.0609 6.5 24 6.5C22.9391 6.5 21.9217 6.92143 21.1716 7.67157C20.4214 8.42172 20 9.43913 20 10.5ZM17.5 11V10.5C17.5 8.77609 18.1848 7.12279 19.4038 5.90381C20.6228 4.68482 22.2761 4 24 4C25.7239 4 27.3772 4.68482 28.5962 5.90381C29.8152 7.12279 30.5 8.77609 30.5 10.5V11H41.75C42.0815 11 42.3995 11.1317 42.6339 11.3661C42.8683 11.6005 43 11.9185 43 12.25C43 12.5815 42.8683 12.8995 42.6339 13.1339C42.3995 13.3683 42.0815 13.5 41.75 13.5H38.833L36.833 37.356C36.681 39.1676 35.854 40.856 34.5158 42.0866C33.1776 43.3172 31.426 44.0001 29.608 44H18.392C16.5742 43.9998 14.8228 43.3168 13.4848 42.0863C12.1468 40.8557 11.3199 39.1674 11.168 37.356L9.168 13.5H6.25C5.91848 13.5 5.60054 13.3683 5.36612 13.1339C5.1317 12.8995 5 12.5815 5 12.25C5 11.9185 5.1317 11.6005 5.36612 11.3661C5.60054 11.1317 5.91848 11 6.25 11H17.5ZM21.5 20.25C21.5 20.0858 21.4677 19.9233 21.4049 19.7716C21.342 19.62 21.25 19.4822 21.1339 19.3661C21.0178 19.25 20.88 19.158 20.7284 19.0951C20.5767 19.0323 20.4142 19 20.25 19C20.0858 19 19.9233 19.0323 19.7716 19.0951C19.62 19.158 19.4822 19.25 19.3661 19.3661C19.25 19.4822 19.158 19.62 19.0951 19.7716C19.0323 19.9233 19 20.0858 19 20.25V34.75C19 34.9142 19.0323 35.0767 19.0951 35.2284C19.158 35.38 19.25 35.5178 19.3661 35.6339C19.4822 35.75 19.62 35.842 19.7716 35.9049C19.9233 35.9677 20.0858 36 20.25 36C20.4142 36 20.5767 35.9677 20.7284 35.9049C20.88 35.842 21.0178 35.75 21.1339 35.6339C21.25 35.5178 21.342 35.38 21.4049 35.2284C21.4677 35.0767 21.5 34.9142 21.5 34.75V20.25ZM27.75 19C27.06 19 26.5 19.56 26.5 20.25V34.75C26.5 35.0815 26.6317 35.3995 26.8661 35.6339C27.1005 35.8683 27.4185 36 27.75 36C28.0815 36 28.3995 35.8683 28.6339 35.6339C28.8683 35.3995 29 35.0815 29 34.75V20.25C29 19.56 28.44 19 27.75 19Z" fill="#2b2b2b" />
                                </svg>
                              </div>

                            </div>
                          </div>
                        )
                      }

                    </div>
                  )
                }

                {
                  isGymListOpen && [
                    <div className="w-full" style={{ padding: "12px", borderRadius: "12px", background: "#f5f5f5" }}>
                      <div className="d-flex w-full space-between m-b-12">
                        <p className="flex-item fs-16 m-l-5">Gym's List</p>
                        <div id="search-container" className='global-search-box m-r-12'>
                          <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1637_1318)">
                              <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1637_1318">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <input id="search-gym-input" className='flex-item' type="text" placeholder="Search Gym" />
                        </div>
                      </div>
                      <div className="flex-item w-full suggestion-list-container-gym-1" onScroll={handleGymScroll}>
                        {
                          Gyms.filter(gym => selectedGym._id != gym._id).map(gym => {
                            return (
                              <div onClick={handleSelectedGym(gym)} className="d-flex suggestion-list-items">
                                {
                                  gym.logoFileID ? (
                                    <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${gym.logoFileID}/auto/auto/70`} />
                                  ) : (
                                    <div className="d-flex flex-item branch-gym-user-profile m-r-8" style={{
                                      backgroundColor: gym.logoPlaceholderColor ? gym.logoPlaceholderColor : "#5d883c"
                                    }}>
                                      <p className="flex-item" style={{ margin: '0 auto', color: '#ffffff' }}>{`${gym.name}`.trim()[0]}</p>
                                    </div>
                                  )
                                }

                                <div className="flex-item">
                                  <p className="branch-gym-user-name m-b-1">{gym.name}</p>
                                  <p className="branch-gym-details-value">{gym.email}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                        {isGymSpinnerLoader && <div className="loader-5 center"><span></span></div>}
                      </div>
                    </div>
                  ]
                }
              </div>
              <div>

              <div
                onClick={(isButtonLock) ? null : props.isEdit ? SaveMember : AddMember}
                className={`member-button-global btn-center d-flex ${(isButtonLock) ? "member-button-global-inactive" : ""}`} style={{ width: "94%", margin: "12px auto 12px auto" }}>
                <p className="flex-item">{props.isEdit ? "Edit" : "Add"}</p>
              </div>

            </div>
            
        </div>
      </div>
    </div>
  )
}

export default ManageMemberModal;