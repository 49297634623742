import {API} from "../../backend";
import config from "../../config";
import { objectToQueryString } from "../../lib/utils";

export const getUnAuthBranch = (branchId) =>{
    return fetch(`${API}/branch/${branchId}?${config.unAuthParam}`,{
        method:'GET',
        headers:{
            Accept:'application/json',
        },
    }).then(res=>res.json()).catch(console.error);
}


export const getBranch = (userId, token,branchId) => {
    return fetch(`${API}/branch/${userId}/${branchId}`,{
        method: "GET",
        headers:{
          Accept: "application/json",
          Authorization: `Bearer ${token}`
      },
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};




export const createBranch=(userId,token,gymId,branch)=>{
    return fetch(`${API}/create/branch/${userId}/${gymId}`,{
        method: "POST",
        headers:{
          Accept: "application/json",
          "Content-type":"application/json",
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(branch)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


export const updateBranch=(userId,token,branchId,branch)=>{
    return fetch(`${API}/update/branch/${userId}/${branchId}`,{
        method: "PUT",
        headers:{
          Accept: "application/json",
          "Content-type":"application/json",
          Authorization: `Bearer ${token}`
      },
      body:JSON.stringify(branch)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


export const getAllBranches=(userId,token,query)=>{
    let str=objectToQueryString(query);
    return fetch(`${API}/branches/${userId}?${str}`,{
        method:'GET',
        headers:{
            Accept:"application/json",
            Authorization:`Bearer ${token}`
        }
    }).then(response=>{
        return response.json();
    }).catch(err=>console.log(err));
};

export const getBranchCount = (userId, token, query) => {
    const  str=objectToQueryString(query)
    return fetch(`${API}/branches/count/${userId}?${str}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json()).catch(err => console.log(err))
};

export const changeBranchStatus =(userId,token,activationStatus,body)=>{
    return fetch(`${API}/change/branch/status/${userId}/${activationStatus}`,{
        method:"PUT",
        headers:{
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}` 
        },
        body:JSON.stringify(body)
    }).then(res=>res.json()).catch(err=>console.log(err))
};