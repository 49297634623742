import React, {useState, useEffect} from 'react';
import GymLogo from '../../assets/gymshim-full-logo.png';
import QrScanner from '../../assets/qr-scan.png';

export default function Scanner(){

  const [scanner, SetScanner] =  useState(false);

  const handleScanner = () => {
    if(!scanner){
      SetScanner(true)
    }else{
      SetScanner(false)
    }
  }

  if(!scanner){
    return(
      <div className="mob-dashboard">
        <div className='d-flex m-t-24' style={{marginBottom:"10vh"}}>
          <img className='flex-item mob-gym-logo' src={GymLogo} />
        </div>
        <div className='d-flex'>
          <img className='qr-scanner-image flex-item' src={QrScanner} />
        </div>
        <div className='w-full' style={{width: "94%", position: "absolute", bottom: 0, marginLeft: "3%", marginBottom: "16px"}}>
          <p className='m-t-36 m-b-24 m-l-4 scanner-grettings-txt'>Scan QR Code <br/>to Login / Register</p>
          <div onClick={handleScanner} className="member-button-global space-between d-flex" style={{background: "#ffffff"}}>
            <p className="flex-item" style={{color: "#055075"}}>Let’s Get Started</p>
            <svg className="flex-item" width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.7071 8.70711C24.0976 8.31658 24.0976 7.68342 23.7071 7.29289L17.3431 0.928932C16.9526 0.538408 16.3195 0.538408 15.9289 0.928932C15.5384 1.31946 15.5384 1.95262 15.9289 2.34315L21.5858 8L15.9289 13.6569C15.5384 14.0474 15.5384 14.6805 15.9289 15.0711C16.3195 15.4616 16.9526 15.4616 17.3431 15.0711L23.7071 8.70711ZM0 9L23 9V7L0 7L0 9Z" fill="#000000"/>
            </svg>
          </div>
        </div>
      </div>
    )
  }else{
    return(
      <div className="mob-dashboard">
        <div className='d-flex m-t-24' style={{marginBottom:"30px"}}>
          <img className='flex-item mob-gym-logo' src={GymLogo} />
        </div>
        <div className='d-flex qr-scanner-container'>
          
        </div>
        <div className='d-flex w-full m-t-40'>
          <svg style={{margin: "0 auto"}} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9997 34.8333C20.7486 34.8333 22.1663 33.4156 22.1663 31.6667C22.1663 29.9178 20.7486 28.5 18.9997 28.5C17.2508 28.5 15.833 29.9178 15.833 31.6667C15.833 33.4156 17.2508 34.8333 18.9997 34.8333Z" fill="#0088DA"/>
            <path d="M18.9997 9.50008C20.7486 9.50008 22.1663 8.08232 22.1663 6.33341C22.1663 4.58451 20.7486 3.16675 18.9997 3.16675C17.2508 3.16675 15.833 4.58451 15.833 6.33341C15.833 8.08232 17.2508 9.50008 18.9997 9.50008Z" fill="#0088DA"/>
            <path d="M10.0426 31.1236C11.7915 31.1236 13.2093 29.7059 13.2093 27.9569C13.2093 26.208 11.7915 24.7903 10.0426 24.7903C8.29374 24.7903 6.87598 26.208 6.87598 27.9569C6.87598 29.7059 8.29374 31.1236 10.0426 31.1236Z" fill="#0088DA"/>
            <path d="M27.9567 13.2098C29.7056 13.2098 31.1234 11.792 31.1234 10.0431C31.1234 8.29423 29.7056 6.87646 27.9567 6.87646C26.2078 6.87646 24.79 8.29423 24.79 10.0431C24.79 11.792 26.2078 13.2098 27.9567 13.2098Z" fill="#0088DA"/>
            <path d="M6.33329 22.1683C8.08307 22.1683 9.50154 20.7498 9.50154 19C9.50154 17.2503 8.08307 15.8318 6.33329 15.8318C4.58351 15.8318 3.16504 17.2503 3.16504 19C3.16504 20.7498 4.58351 22.1683 6.33329 22.1683Z" fill="#0088DA"/>
            <path d="M31.6667 22.1666C33.4156 22.1666 34.8333 20.7488 34.8333 18.9999C34.8333 17.251 33.4156 15.8333 31.6667 15.8333C29.9178 15.8333 28.5 17.251 28.5 18.9999C28.5 20.7488 29.9178 22.1666 31.6667 22.1666Z" fill="#0088DA"/>
            <path d="M10.0426 13.2113C11.7915 13.2113 13.2093 11.7935 13.2093 10.0446C13.2093 8.29569 11.7915 6.87793 10.0426 6.87793C8.29374 6.87793 6.87598 8.29569 6.87598 10.0446C6.87598 11.7935 8.29374 13.2113 10.0426 13.2113Z" fill="#0088DA"/>
            <path d="M27.9567 31.1251C29.7056 31.1251 31.1234 29.7073 31.1234 27.9584C31.1234 26.2095 29.7056 24.7917 27.9567 24.7917C26.2078 24.7917 24.79 26.2095 24.79 27.9584C24.79 29.7073 26.2078 31.1251 27.9567 31.1251Z" fill="#0088DA"/>
          </svg>
        </div>
        <div className='w-full' style={{width: "94%", position: "absolute", bottom: 0, marginLeft: "3%", marginBottom: "18px"}}>
          <p className='m-l-4 scanner-grettings-txt'>QR Code, Getting<br/>Scanned, Please Wait !</p>
        </div>
      </div>
    )
  }
}