import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../../auth';
import Dashboard from '../../core/Dashboard';
import { getGym } from '../../gym/helper/api';
import Flatpickr from "flatpickr";
import ArrowDown from '../../assets/arrow-down.svg';
import Cross from "../../assets/cross.svg";
import _ from 'lodash';
import FilterIcon from "../../assets/filter.svg";
import ArrowLeft from "../../assets/arrow-left.svg";
import ArrowRight from "../../assets/arrow-right.svg";
import BlockIcon from "../../assets/block.svg";
import SuccessIcon from "../../assets/success.png";
import ExtendIcon from "../../assets/extend.png";
import ScheduleIcon from "../../assets/schedule.png"; 
import UpdateIcon from "../../assets/edit.svg";
import ArrowUp from "../../assets/arrow-sign.svg";
import GoogleIcon from "../../assets/google.png";
import GymLogo from "../../assets/gymshim-logo-icon.png";
import { useParams } from 'react-router-dom';
import NoDataFound from "../../assets/no_data.png";

const Appoienment = (props) => {
    const params = useParams();
    const [page,setpage]=useState(1);
    const [currentpage,setcurrentpage]=useState(1);
    const [Gym,setGym]=useState({ gymName:"",email:"",phone:""});//save gym info ( name,phone,email )
    const { gymName }=Gym;//destrucuring gym
    const [Branch,setBranch]=useState({branchName:"",totalmembers:0,totaladminusers:0,state:"",location:"",area:"",address:"",branchactive:true,branchId:"", gymId:"",error:"",success:""});//save branch info
    const {branchName}=Branch;//destructuring the branch info 
    const [openDialog,setopenDialog]=useState(false);//Dialog hooks
    const [appoientment,setappoientment]=useState({
        isComplete:false,
        complete:{
            date:"",
            time:"",
            remark:""
        }
    })
    const { complete}=appoientment;
    const [Appointments,setAppointments]=useState([])
    const [Planner,setPlanner]=useState({
        planner_name:"",
        planner_startDate:"",
        planner_duration:"",
        reason:""
    })
    const {planner_startDate,reason}=Planner;

    const [formdialog,setformdialog]=useState({formrole:0,title:"Planner Re Assign"});
    const {formrole,title}=formdialog;
    const [Member,setMember]=useState({
        memberId:"",
        mfname:"",
        mlname:"",
        mphone:"",
        memail:""
    });
    const {mfname,}=Member;
    const [isAppointmentFilterListOpen, setAppointmentFilterOpen] = useState(false);
    const [currentElementId, setCurrentElementId] = useState("");
    const [workoutGymTypeOpenDropdown, setWorkoutGymTypeOpenDropdown] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState({
        branchAddress: ""
    });
    const [isFilterModalOpen, setFilterModalOpen] = useState('');
    const [selectedGym,setSelectedGym]=useState('');
    const [isAllAppointmentChecked, setAllAppointmentChecked]  = useState(false);
    const {user, token} = isAuthenticated();


    const handleOpenDialog=()=>{
        setopenDialog(true);
    };//open dialog handler
    const handleCloseDialog=()=>{
        setopenDialog(false);
    };//close dialog handle
    //pagination
    const prev=()=>{
		if(currentpage<=page&&currentpage!=1){
		 	setcurrentpage(currentpage-1);
	    }
	};
	const next=()=>{
		if(currentpage<page){
		   setcurrentpage(currentpage+1);
		}
	};
    //branchadmin handler
    const handleChange=name=>event=>{
        const value =  event.target.value;
        setappoientment(oldstate=>{
            if(name=="date") oldstate['complete'][name]=value;
            else [name]=value;
            return ({...oldstate})
        })
    };
    //branchadmin handler
    const handleDropdownItem=(name,value)=>event=>{
        event.preventDefault();
        switch(name){
            case "branch":
                setBranch({
                    ...Branch,
                    branchName:value
                })
                break;
            case "member":
                setMember({
                    ...Member,
                    mfname:value
                })
                break;
            case "gym":
                setGym({
                    ...Gym,
                    gymName:value
                })
                break;
            case "reason":
                setPlanner({
                    ...Planner,
                    reason:value
                })
              break;
            default:
                console.log('something went wrong')
        }
        document.getElementById(name+"-list").style.display="none";
        document.getElementById(name+"-list-icon").style.transform='rotate(-180deg)';

    }
    const hanleDropDown=id=>{
        let dropdown=document.querySelector('#'+id);
        let arrowicon=document.querySelector("#"+id+"-icon");

        if(dropdown.style.display=="none"){
            dropdown.style.display="block";
            arrowicon.style.transform='rotate(0deg)';
        }else {
            dropdown.style.display="none";
            arrowicon.style.transform='rotate(-180deg)';
        }
    }
    const toggleAppointmentAction = () => {
        if(document.getElementById("action-memberAppointmentId").style.display == "block"){
            document.getElementById("action-memberAppointmentId").style.display = "none"
        }else{
            document.getElementById("action-memberAppointmentId").style.display = "block";
        }
    }
    const toggleFilter = () => {
        if(document.getElementById("filter-container-toggle").style.display == "none"){
            document.getElementById("filter-container-toggle").style.display = "block";
        }else{
            document.getElementById("filter-container-toggle").style.display = "none";
        }
    }
    const toggleSortingItem = (event) => {
        event.preventDefault();
        let currentSortingItem = event.target.id.split('-')[1];
        if(document.getElementById("list-" + currentSortingItem).style.display == "none"){
            document.getElementById("icon-" + currentSortingItem).style.transform = "rotate(-180deg)";
            document.getElementById("list-" + currentSortingItem).style.display = "block";

            document.getElementById("itemId-" + currentSortingItem).addEventListener("click", function(event) {
                let currentSelectedItem = event.target.innerHTML;
                document.getElementById("text-" + currentSortingItem).innerHTML = currentSelectedItem;
                document.getElementById("icon-" + currentSortingItem).style.transform = "rotate(0deg)";
                document.getElementById("list-" + currentSortingItem).style.display = "none";
            })
        }else{
            document.getElementById("icon-" + currentSortingItem).style.transform = "rotate(0deg)";
            document.getElementById("list-" + currentSortingItem).style.display = "none";
        }
    }
    const handleAppointment=value=>event=>{
        event.preventDefault();
        let title="";
        if(value==1) title="Book New";
        if(value==0) title="Extand Planner";
        if(value==2) title="Reschedule";
        if(value==4) title="Complete";
        if(value==5) title="Cancel";
        if(value==3) title="Book Appointment";

        setformdialog({
            ...formdialog,
            formrole:value,
            title
        });
        setopenDialog(true)
    }
    const getProfile = async () => {
        // if(params.gymId){
        //     gymID =  params.gymId
        // }else if(params.branchId){
        //     branchID = params.branchId
        // }else{
        //     memberID = params.memberID
        // }
        if(params.gymId){
            try {
                let currentGym = await getGym(user._id, token, params.gymId);
                console.log(currentGym)
                if (currentGym && currentGym.error) {
                    setSelectedGym([])
                } else {
                    setSelectedGym(currentGym);
                }
            } catch (error) {
            }
        }
    }
    const checkAllAppointment = () => {
        if(isAllAppointmentChecked){
            setAllAppointmentChecked(false)
        }else{
            setAllAppointmentChecked(true)
        }
    }
    const toggleAppointmentFilterOpen = () => {
        if(isAppointmentFilterListOpen){
            setAppointmentFilterOpen(false)
        }else{
            setAppointmentFilterOpen(true)
        }
    }
    const toggleInputFields = (id) => event => {
        setCurrentElementId(id);
        if (id == 'workout-gym-type') setWorkoutGymTypeOpenDropdown(!workoutGymTypeOpenDropdown);
        if (event) {
            let interval = setInterval(() => {
                let element = event.target.querySelector('input');
                if (element) {
                    element.focus();
                    clearInterval(interval)
                }
            }, 0);
        }
    }
    const handleFilterModalOpen = (filterType) => {
        if(isFilterModalOpen){
            setFilterModalOpen('')
            if(isFilterModalOpen == "gym"){
                setAppointmentFilterOpen(true);
            }
        }else{
            setAppointmentFilterOpen(false);
            setFilterModalOpen(filterType)
        }
    }


    // const handlerCalendar = () => {
    //     var calendarEl = document.getElementById("calendar");
    //     var today = new Date();
    //     var dd = String(today.getDate()).padStart(2);
    //     var mm = String(today.getMonth());
    //     var prevBtn = document.getElementById("my-prev-button");
    //     var nextBtn = document.getElementById("my-next-button");
    //     var calendarTitle = document.getElementById("calendar-title");
    //     var calendarPopup = document.getElementById("calender-event-popup");
    //     var calendar = new FullCalendar.Calendar(calendarEl, {
    //         initialDate: today,
    //         initialView: "timeGridWeek",
    //         nowIndicator: true,
    //         headerToolbar: false,
    //         scrollTime: '09:00:00',
    //         eventStartEditable: false,
    //         droppable: false,
    //         draggable: false,
    //         allDaySlot: false,
    //         views: {
    //         timeGridWeek: {
    //             dayHeaderContent: ({ date }) => {
    //             // alert(date)
    //             if (date.getDate() == dd && date.getMonth() == mm) {
    //                 return {
    //                 html: `<div>
    //                 <p style="margin: 8px 0 4px 1px; font-size: 24px; color: #3473BA; font-weight: 600; line-height: 28px;">${`${date.getDate()}`.length == 1
    //                     ? "0" + date.getDate()
    //                     : date.getDate()
    //                     }</p>
    //                 <p style="margin: 0 0 8px 0; font-weight: normal; color: #3473BA; font-size: 14px; line-height: 18px;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                         ? "Monday"
    //                         : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                             ? "Wednesday"
    //                             : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                                 ? "Friday"
    //                                 : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p></div>`,
    //                 };
    //             } else {
    //                 return {
    //                 html: `<div>
    //                 <p style="margin: 8px 0 4px 1px; font-size: 24px; color: #6D6D6D; font-weight: 600; line-height: 28px">${`${date.getDate()}`.length == 1
    //                     ? "0" + date.getDate()
    //                     : date.getDate()
    //                     }</p>
    //                 <p style="margin: 0 0 8px 0; font-weight: normal; font-size: 14px; line-height: 18px; color: #6D6D6D;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                         ? "Monday"
    //                         : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                             ? "Wednesday"
    //                             : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                                 ? "Friday"
    //                                 : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p></div>`,
    //                 };
    //             }
    //             },
    //         },
    //         timeGridDay: {
    //             dayHeaderContent: ({ date }) => {
    //             if (date.getDate() == dd && date.getMonth() == mm) {
    //                 return {
    //                 html: `<div>
    //                 <p style="margin: 8px 0 4px 1px; font-size: 24px; color: #3473BA; font-weight: 600; line-height: 28px;">${`${date.getDate()}`.length == 1
    //                     ? "0" + date.getDate()
    //                     : date.getDate()
    //                     }</p>
    //                 <p style="margin: 0 0 8px 0; font-weight: normal; color: #3473BA; font-size: 14px; line-height: 18px;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                         ? "Monday"
    //                         : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                             ? "Wednesday"
    //                             : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                                 ? "Friday"
    //                                 : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p></div>`,
    //                 };
    //             } else {
    //                 return {
    //                 html: `<div>
    //                 <p style="margin: 8px 0 4px 1px; font-size: 24px; color: #6D6D6D; font-weight: 600; line-height: 28px">${`${date.getDate()}`.length == 1
    //                     ? "0" + date.getDate()
    //                     : date.getDate()
    //                     }</p>
    //                 <p style="margin: 0 0 8px 0; font-weight: normal; font-size: 14px; line-height: 18px; color: #6D6D6D;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                         ? "Monday"
    //                         : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                             ? "Wednesday"
    //                             : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                                 ? "Friday"
    //                                 : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p></div>`,
    //                 };
    //             }
    //             },
    //         },
    //         dayGridMonth: {
    //             dayHeaderContent: ({ date }) => {
    //             if (date.getDate() == dd && date.getMonth() == mm) {
    //                 return {
    //                 html: `<div><p style="margin: 8px 0 8px 0; font-weight: normal; color: #3473BA; font-size: 18px; line-height: 24px;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                     ? "Monday"
    //                     : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                         ? "Wednesday"
    //                         : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                             ? "Friday"
    //                             : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p></div>`,
    //                 };
    //             } else {
    //                 return {
    //                 html: `<div><p style="margin: 8px 0 8px 0; font-weight: normal; font-size: 18px; line-height: 24px; color: #6D6D6D;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                     ? "Monday"
    //                     : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                         ? "Wednesday"
    //                         : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                             ? "Friday"
    //                             : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p></div>`,
    //                 };
    //             }
    //             },
    //         },
    //         listMonth: {
    //             dayHeaderContent: ({ date }) => {
    //             if (date.getDate() == dd && date.getMonth() == mm) {
    //                 return {
    //                 html: `
    //                 <div class="d-flex">
    //                     <p class="flex-item" style="margin: 0 0 0 0; font-weight: normal; font-size: 16px; line-height: 20px; color: #3473BA;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                         ? "Monday"
    //                         : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                             ? "Wednesday"
    //                             : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                                 ? "Friday"
    //                                 : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p>
    //                     <div style="width: 28px; height:28px; border-radius: 100%; background: #3473BA; font-weight: 600; color: #ffffff; font-size:14px" class="flex-item m-l-12 d-flex">
    //                     <p class="flex-item" style="margin: 0 auto">${date.getDate()}</p>
    //                     </div>
    //                 </div>`,
    //                 };
    //             } else {
    //                 return {
    //                 html: `
    //                 <div class="d-flex">
    //                     <p class="flex-item" style="margin: 0 0 0 0; font-weight: normal; font-size: 16px; line-height: 20px; color: #4d4d4d;">${date.getDay() == 0
    //                     ? "Sunday"
    //                     : date.getDay() == 1
    //                         ? "Monday"
    //                         : date.getDay() == 2
    //                         ? "Tuesday"
    //                         : date.getDay() == 3
    //                             ? "Wednesday"
    //                             : date.getDay() == 4
    //                             ? "Thursday"
    //                             : date.getDay() == 5
    //                                 ? "Friday"
    //                                 : date.getDay() == 6
    //                                 ? "Saturday"
    //                                 : ""
    //                     }</p>
    //                 <div style="width: 28px; height:28px; border-radius: 100%; background: #f5f5f5; font-weight: 600; color: #4d4d4d; font-size:14px" class="flex-item m-l-12 d-flex">
    //                     <p class="flex-item" style="margin: 0 auto">${date.getDate()}</p>
    //                 </div>
    //                 </div>`,
    //                 };
    //             }
    //             },
    //         },
    //         listYear: {
    //             dayHeaderContent: ({ date }) => {
    //             if (date.getDate() == dd && date.getMonth() == mm) {
    //                 return {
    //                 html: `
    //                 <div class="d-flex space-between">
    //                     <div class="flex-item d-flex">  
    //                     <p class="flex-item" style="margin: 0 0 0 0; font-weight: normal; font-size: 16px; line-height: 20px; color: #3473BA;">
    //                     ${date.getMonth() == 0
    //                     ? "January"
    //                     : date.getMonth() == 1
    //                         ? "February"
    //                         : date.getMonth() == 2
    //                         ? "March"
    //                         : date.getMonth() == 3
    //                             ? "April"
    //                             : date.getMonth() == 4
    //                             ? "May"
    //                             : date.getMonth() == 5
    //                                 ? "June"
    //                                 : date.getMonth() == 6
    //                                 ? "July"
    //                                 : date.getMonth() == 7
    //                                     ? "August"
    //                                     : date.getMonth() == 8
    //                                     ? "September"
    //                                     : date.getMonth() == 9
    //                                         ? "October"
    //                                         : date.getMonth() == 10
    //                                         ? "November"
    //                                         : date.getMonth() == 11
    //                                             ? "December"
    //                                             : ""
    //                     }</p>
    //                         <div style="width: 28px; height:28px; border-radius: 100%; background: #3473BA; font-weight: 600; color: #ffffff; font-size:14px" class="flex-item m-l-12 d-flex">
    //                         <p class="flex-item" style="margin: 0 auto">${date.getDate()}</p>
    //                         </div>
    //                     </div>
    //                     <p class="flex-item" style="margin: 0 0 0 0; font-weight: normal; color: #3473BA; font-weight: bold; font-size: 14px; line-height: 18px;">${date.getDay() == 0
    //                     ? "Sun"
    //                     : date.getDay() == 1
    //                         ? "Mon"
    //                         : date.getDay() == 2
    //                         ? "Tue"
    //                         : date.getDay() == 3
    //                             ? "Wed"
    //                             : date.getDay() == 4
    //                             ? "Thu"
    //                             : date.getDay() == 5
    //                                 ? "Fri"
    //                                 : date.getDay() == 6
    //                                 ? "Sat"
    //                                 : ""
    //                     }</p>
    //                 </div>`,
    //                 };
    //             } else {
    //                 return {
    //                 html: `
    //                 <div class="d-flex space-between" style="background: #fafafa; outline: 4px solid #fafafa; width: 101%; padding: 0 4px">
    //                     <div class="flex-item d-flex">  
    //                     <p class="flex-item" style="margin: 0 0 0 0; font-weight: normal; font-size: 18px; font-weight: 600; line-height: 22px; color: #3473BA;">
    //                     ${date.getMonth() == 0
    //                     ? "January"
    //                     : date.getMonth() == 1
    //                         ? "February"
    //                         : date.getMonth() == 2
    //                         ? "March"
    //                         : date.getMonth() == 3
    //                             ? "April"
    //                             : date.getMonth() == 4
    //                             ? "May"
    //                             : date.getMonth() == 5
    //                                 ? "June"
    //                                 : date.getMonth() == 6
    //                                 ? "July"
    //                                 : date.getMonth() == 7
    //                                     ? "August"
    //                                     : date.getMonth() == 8
    //                                     ? "September"
    //                                     : date.getMonth() == 9
    //                                         ? "October"
    //                                         : date.getMonth() == 10
    //                                         ? "November"
    //                                         : date.getMonth() == 11
    //                                             ? "December"
    //                                             : ""
    //                     }</p>
    //                     <div style="width: 28px; height:28px; border-radius: 100%; background: #e0e0e0; font-weight: 600; color: #4d4d4d; font-size:14px" class="flex-item m-l-12 d-flex">
    //                         <p class="flex-item" style="margin: 0 auto">${date.getDate()}</p>
    //                     </div>
    //                     </div>
    //                     <p class="flex-item" style="margin: 0 0 0 0; font-weight: normal; font-size: 14px; line-height: 18px; color: #2b2b2b; font-weight: 600">${date.getDay() == 0
    //                     ? "Sun"
    //                     : date.getDay() == 1
    //                         ? "Mon"
    //                         : date.getDay() == 2
    //                         ? "Tue"
    //                         : date.getDay() == 3
    //                             ? "Wed"
    //                             : date.getDay() == 4
    //                             ? "Thu"
    //                             : date.getDay() == 5
    //                                 ? "Fri"
    //                                 : date.getDay() == 6
    //                                 ? "Sat"
    //                                 : ""
    //                     }</p>
    //                     </div>
    //                 </div>`,
    //                 };
    //             }
    //             },
    //         },
    //         },
    //         select: function (start, end, allDay) {
    //         return false;
    //         },
    //         navLinks: false,
    //         editable: false,
    //         selectable: false,
    //         selectMirror: true,
    //         dayMaxEvents: true,
    //         eventColor: "#3473BA",
    //         events: this.state.eventList,
    //         eventClick: async (info) => {

    //         await startLoader();
    //         ////console.log(info)
    //         let prevShcheduleID;
    //         if (this.state.selectedSchedule) {
    //             prevShcheduleID = this.state.selectedSchedule._id;
    //         }
    //         let selectedSchedule = await this.state.schedules.find(data => data._id == info.event.id);
    //         selectedSchedule.schoolList = [];

    //         if (selectedSchedule) {
    //             if (selectedSchedule.trainers.length > 0) {
    //             await Promise.all(selectedSchedule.trainers.map(trainer => {
    //                 if (!colorList[trainer._id]) {
    //                 colorList[trainer._id] = colorItems[Math.floor(Math.random() * colorItems.length)];
    //                 }
    //             }))
    //             }
    //             if (selectedSchedule.aditionalActivities && selectedSchedule.aditionalActivities.length > 0) {
    //             await Promise.all(selectedSchedule.aditionalActivities.map(activity => {
    //                 if (selectedSchedule.activities.findIndex(d => d._id == activity._id) == -1) {
    //                 selectedSchedule.activities.push(activity)
    //                 }
    //             }))
    //             }


    //             if (selectedSchedule.extraTestList && selectedSchedule.extraTestList.length > 0) {
    //             await Promise.all(selectedSchedule.extraTestList.map(activity => {
    //                 if (selectedSchedule.activities.findIndex(d => d._id == activity._id) == -1) {
    //                 selectedSchedule.activities.push(activity)
    //                 }
    //             }))
    //             }


    //             if (selectedSchedule.aditionalTrainers.length > 0) {
    //             await Promise.all(selectedSchedule.aditionalTrainers.map(trainer => {
    //                 if (!colorList[trainer._id]) {
    //                 colorList[trainer._id] = colorItems[Math.floor(Math.random() * colorItems.length)];
    //                 }
    //             }))
    //             }
    //             await Promise.all(selectedSchedule.sections.map(section => {
    //             section.grades.map(grade => {
    //                 grade.schools.map(school => {
    //                 let findIndex = selectedSchedule.schoolList.findIndex(data => data._id == school._id);
    //                 if (findIndex > -1) {
    //                     let findGradeIndex = selectedSchedule.schoolList[findIndex].gradeList.findIndex(data => data._id == grade._id);
    //                     if (findGradeIndex > -1) {
    //                     let findSectionIndex = selectedSchedule.schoolList[findIndex].gradeList[findGradeIndex].sectionList.findIndex(data => data._id == section._id);
    //                     if (findSectionIndex == -1) {
    //                         selectedSchedule.schoolList[findIndex].gradeList[findGradeIndex].sectionList.push(section)
    //                     }
    //                     } else {
    //                     let object = {
    //                         ...grade,
    //                         sectionList: [section]
    //                     }
    //                     selectedSchedule.schoolList[findIndex].gradeList.push(object)
    //                     }
    //                 } else {
    //                     grade.sectionList = [section];
    //                     if (colorList[school._id]) {
    //                     school.color = colorList[school._id];
    //                     } else {
    //                     colorList[school._id] = colorItems[Math.floor(Math.random() * colorItems.length)];
    //                     school.color = colorList[school._id];
    //                     }
    //                     let object = {
    //                     ...school,
    //                     gradeList: [grade]
    //                     }
    //                     selectedSchedule.schoolList.push(object)
    //                 }
    //                 })
    //             })
    //             }));
    //         }
    //         await this.setStateAsync({ selectedSchedule })
    //         //alert(prevShcheduleID)
    //         if (prevShcheduleID && selectedSchedule && prevShcheduleID == selectedSchedule._id) {
    //             calendarPopup.style.display = "none";
    //             this.setState({
    //             isViewActivityDetailsOpen: false
    //             })
    //             // alert(this.state.isViewActivityDetailsOpen);

    //             if (calendarEl) {

    //             if (calendarEl.querySelector(
    //                 ".fc-scroller-liquid-absolute"
    //             )) {
    //                 calendarEl.querySelector(
    //                 ".fc-scroller-liquid-absolute"
    //                 ).style.overflow = "auto";
    //             }

    //             if (calendarEl.querySelector('.fc-scroller-liquid')) {
    //                 calendarEl.querySelector(
    //                 ".fc-scroller-liquid"
    //                 ).style.overflow = "auto";
    //             }

    //             }
    //             calendarPopup.setAttribute('data-dropdown', 'No')
    //             this.setState({ selectedSchedule: "" })
    //         } 
    //         else {
    //             let eventPosition = info.el.getBoundingClientRect();
    //             if (!calendarPopup.classList.contains("mob-modal-bottom-slide")) calendarPopup.classList.add("mob-modal-bottom-slide");
    //             calendarPopup.style.display = "block";

    //             this.setState({
    //             isViewActivityDetailsOpen: true
    //             })
    //             // alert(this.state.isViewActivityDetailsOpen);


    //             if (screen.width > 549) {

    //             calenderPopupAttr = eventPosition;

    //             if (calendarEl) {

    //                 if (calendarEl.querySelector(
    //                 ".fc-scroller-liquid-absolute"
    //                 )) {
    //                 calendarEl.querySelector(
    //                     ".fc-scroller-liquid-absolute"
    //                 ).style.overflow = "hidden";
    //                 }

    //                 if (calendarEl.querySelector('.fc-scroller-liquid')) {
    //                 calendarEl.querySelector(
    //                     ".fc-scroller-liquid"
    //                 ).style.overflow = "hidden";
    //                 }
    //             }


    //             if (eventPosition.top > 300 && eventPosition.left < 900) {
    //                 calendarPopup.style.marginLeft = eventPosition.left + "px";
    //                 calendarPopup.style.marginTop =
    //                 eventPosition.top - eventPosition.top + 350 + "px";
    //             } else if (eventPosition.top < 0 && eventPosition.left < 900) {
    //                 calendarPopup.style.marginLeft = eventPosition.left + "px";
    //                 calendarPopup.style.marginTop =
    //                 eventPosition.top + -eventPosition.top + 220 + "px";
    //             } else if (
    //                 eventPosition.top < 500 &&
    //                 Math.round(eventPosition.left) > 900
    //             ) {
    //                 calendarPopup.style.marginLeft =
    //                 eventPosition.left - eventPosition.left + 940 + "px";
    //                 calendarPopup.style.marginTop =
    //                 eventPosition.top + -eventPosition.top + 220 + "px";
    //             } else if (
    //                 eventPosition.top > 500 &&
    //                 Math.round(eventPosition.left) > 900
    //             ) {
    //                 calendarPopup.style.marginLeft =
    //                 eventPosition.left - eventPosition.left + 940 + "px";
    //                 calendarPopup.style.marginTop = 350 + "px";
    //             } else {
    //                 calendarPopup.style.marginLeft = eventPosition.left + "px";
    //                 calendarPopup.style.marginTop = eventPosition.top + 20 + "px";
    //             }
    //             }

    //             calendarPopup.setAttribute('data-dropdown', 'Yes');

    //         }
    //         await stopLoader();


    //         },
    //         eventDidMount: async (info) => {
    //         try {
    //             if (info && info.event) {
    //             let selectedSchedule = await this.state.schedules.find(data => data._id == info.event.id);


    //             if (selectedSchedule) {
    //                 info.el.id = `event-${info.event.id}`;

    //                 if (this.state.calendarView == "timeGridWeek") {

    //                 if (info.el.querySelector('.fc-event-time')) {
    //                     let html = `<div class="d-flex-wrap">
    //                 <p class="fs-12 m-r-12" style="text-transform: capitalize; font-weight: normal !important; color: #ffffff">Grade - ${selectedSchedule.sections[0].grades[0].name} </p> <p class="fs-12">Division - ${selectedSchedule.sections[0].name} </p>
    //                 </div>`;
    //                     info.el.querySelector('.fc-event-time').insertAdjacentHTML('beforeend', html)
    //                 }
    //                 }


    //                 if (this.state.calendarView == "listMonth" || this.state.calendarView == "listYear") {

    //                 if (info.event._def.ui.backgroundColor == "#5d883c") {
    //                     document.getElementById("event-" + info.event.id).style.backgroundColor = "#207B2A";

    //                     info.el.style.borderRadius = "12px"
    //                     info.el.getElementsByTagName('td')[0].style.borderRadius = "12px 0 0 12px";
    //                     info.el.getElementsByTagName('td')[0].style.color = "#ffffff";
    //                     info.el.getElementsByTagName('td')[1].getElementsByTagName('span')[0].style.borderColor = "#000000";
    //                     info.el.getElementsByTagName('td')[2].style.borderRadius = "0 12px 12px 0";
    //                     info.el.getElementsByTagName('td')[2].getElementsByTagName('a')[0].style.color = "#C2FFC9";
    //                     //document.getElementById("event-" + info.event.id).
    //                     // document.getElementById("event-" + info.event.id).style.outline = "1px solid #00b436";
    //                 }else{
    //                     info.el.getElementsByTagName('td')[2].getElementsByTagName('a')[0].style.color = "#000000";
    //                 }

    //                 if (info.el.querySelector('.fc-list-event-time')) {
    //                     let html;
    //                     if(info.event._def.ui.backgroundColor == "#5d883c"){
    //                     html = `<p class="fs-12" style="text-transform: capitalize;font-weight: normal; color: #C2FFC9">Grade - ${selectedSchedule.sections[0].grades[0].name} <br/> Division - ${selectedSchedule.sections[0].name}</p>`;
    //                     }else{
    //                     html = `<p class="fs-12" style="text-transform: capitalize; color: #000000; font-weight: normal">Grade - ${selectedSchedule.sections[0].grades[0].name} <br/> Division - ${selectedSchedule.sections[0].name}</p>`;
    //                     }
    //                     info.el.querySelector('.fc-list-event-time').insertAdjacentHTML('beforeend', html)
    //                 }

    //                 document.getElementById("event-" + info.event.id).style.boxShadow="0px 0px 8px rgba(0, 0, 0, 0.15)";
    //                 document.getElementById("event-" + info.event.id).style.borderRadius="12px";


    //                 }

    //                 if (this.state.calendarView == "timeGridDay") {
    //                 if (info.el.querySelector('.fc-event-time')) {
    //                     let html;
    //                     html = `<p class="fs-12" style="text-transform: capitalize; white-space: pre">Grade - ${selectedSchedule.sections[0].grades[0].name}   Div - ${selectedSchedule.sections[0].name}</p>`;
                        
    //                     info.el.querySelector('.fc-event-time').insertAdjacentHTML('afterbegin', html)
    //                 }
    //                 }
    //             }


    //             }
    //         } catch (error) {
    //             // do something
    //         }
    //         },
    //         eventAdd: (addInfo) => {
    //         //console.log("New INfo Added")
    //         }


    //     });
    //     calendarRef = calendar;
    //     calendar.render();

    //     //console.log(calendar.cell)

    //     calendar.changeView(this.state.calendarView);


    //     prevBtn.addEventListener("click", () => {
    //         calendar.prev();
    //         this.setState({ currentDate: calendar.getDate() })
    //         this.getCustomMonth(formatDateTime(calendar.getDate()).split('/')[1])

    //         if (this.state.calendarView == 'timeGridWeek') {
    //         let activeEnd = new Date(calendar.view.activeEnd.setDate(calendar.view.activeEnd.getDate() - 1))
    //         this.setState({
    //             weekStartDate: formatDateTime(calendar.view.activeStart).split('/')[0].toString(),
    //             weekEndDate: formatDateTime(activeEnd).split('/')[0].toString(),
    //             calenderStartDate: calendar.view.activeStart,
    //             calenderEndDate: activeEnd
    //         })
    //         this.getCustomNextMonth(formatDateTime(activeEnd).split('/')[1].toString());
    //         }
    //     });

    //     nextBtn.addEventListener("click", () => {
    //         calendar.next();
    //         this.setState({ currentDate: calendar.getDate() })
    //         this.getCustomMonth(formatDateTime(calendar.getDate()).split('/')[1]);

    //         if (this.state.calendarView == 'timeGridWeek') {
    //         let activeEnd = new Date(calendar.view.activeEnd.setDate(calendar.view.activeEnd.getDate() - 1))
    //         this.setState({
    //             weekStartDate: formatDateTime(calendar.view.activeStart).split('/')[0].toString(),
    //             weekEndDate: formatDateTime(activeEnd).split('/')[0].toString(),
    //             calenderStartDate: calendar.view.activeStart,
    //             calenderEndDate: activeEnd
    //         })
    //         this.getCustomNextMonth(formatDateTime(activeEnd).split('/')[1].toString());
    //         }

    //     });

    //     if (formatDateTime(this.state.currentDate) != formatDateTime(today)) {

    //         let Year = formatDateTime(this.state.currentDate).split('/')[2].toString();
    //         let Month = formatDateTime(this.state.currentDate).split('/')[1].toString();
    //         let Day = formatDateTime(this.state.currentDate).split('/')[0].toString();

    //         calendar.gotoDate(`${`${Year}`.padStart(4, '0')}-${`${Month}`.padStart(2, '0')}-${`${Day}`.padStart(2, '0')}`)
    //     }

    //     if (this.state.calendarView == 'timeGridWeek') {
    //         let activeEnd = new Date(calendar.view.activeEnd.setDate(calendar.view.activeEnd.getDate() - 1))
    //         this.setState({
    //         weekStartDate: formatDateTime(calendar.view.activeStart).split('/')[0].toString(),
    //         weekEndDate: formatDateTime(activeEnd).split('/')[0].toString(),
    //         calenderStartDate: calendar.view.activeStart,
    //         calenderEndDate: activeEnd
    //         })
    //         this.getCustomNextMonth(formatDateTime(activeEnd).split('/')[1].toString());
    //     }

    // }

    useEffect(() => {

        window.addEventListener('click', () => {
            setCurrentElementId('');
        })

        getProfile();

        let searchAppointmentInputField = document.getElementById("search-appointment-input");
        let searchContainer  = document.getElementById("search-container");
        let searchIcon = document.getElementById("search-icon");
        if (searchAppointmentInputField) {
            searchAppointmentInputField.onfocus = e => {
                        searchContainer.style.outline = "1px solid #055075";
                        searchContainer.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
                        searchIcon.classList.add("active-fill-svg");
                }
                searchAppointmentInputField.onblur = e => {
                        searchContainer.style.outline = "none";
                        searchContainer.style.boxShadow = "none";
                        searchIcon.classList.remove("active-fill-svg");
                }
        }


        let searchGymInputField = document.getElementById("search-gym-input");
        let searchContainerGym = document.getElementById("search-container-gym");
        let searchIconGym = document.getElementById("search-icon-gym");
        if (searchGymInputField) {
            searchGymInputField.onfocus = e => {
                searchContainerGym.style.outline = "1px solid #055075";
                searchContainerGym.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
                searchIconGym.classList.add("active-fill-svg");
            }
            searchGymInputField.onblur = e => {
                searchContainerGym.style.outline = "none";
                searchContainerGym.style.boxShadow = "none";
                searchIconGym.classList.remove("active-fill-svg");
            }
        }


        let searchBranchInputField = document.getElementById("search-branch-input");
        let searchContainerBranch = document.getElementById("search-container-branch");
        let searchIconBranch = document.getElementById("search-icon-branch");
        if (searchBranchInputField) {
            searchBranchInputField.onfocus = e => {
                searchContainerBranch.style.outline = "1px solid #055075";
                searchContainerBranch.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
                searchIconBranch.classList.add("active-fill-svg");
            }
            searchBranchInputField.onblur = e => {
                searchContainerBranch.style.outline = "none";
                searchContainerBranch.style.boxShadow = "none";
                searchIconBranch.classList.remove("active-fill-svg");
            }
        }
    },[])

    useEffect(() => {
        
    },[])

    return(
        <Dashboard
        flag={0}
        gym={selectedGym}
        navItemData={"Gym"}
        data={{
            gymMenuType : "Appointment"  
        }}>

        <div className="header-bar" style={{display: "none"}}>
            <div>
                <div className="dashboard-name-container">
                    <div className="dashboard-name">Appointments</div>
                </div>
                <div onClick={handleAppointment(1)}   role="button" className="active-inactive-container d-flex" style={{margin:"2% 0 0 -3%"}}>
                    <span class="material-icons-round active-inactive-icon flex-item" style={{color:"#bdbdbd"}}>add_circle_outline</span>
                    {/* <img src={AddIcon} className="active-inactive-icon"/> */}
                    <div id="switch-gym" className="active-inactive-text flex-item" style={{margin: "4% 0 0 3%"}}>Book New</div>
                </div>
            </div>

            <div>
                <div className="search-field-container">
                    <input type="text" className="search-field" placeholder="search"/>
                    <div className="filter-container" onClick={toggleFilter}>
                        <img src={FilterIcon} className="filter-icon"/>
                    </div>
                    <div id="filter-container-toggle" className="filter-dropdown" style={{display:"none"}}>
                        <div className="d-flex">
                            <div className="flex-item container-spacing bold-font field-text">Sort By</div>
                            <input className="flex-item" type="checkbox" style={{ marginTop: "4%" }}/>
                        </div>
                        <div className="container-spacing">
                            <span className="g-font-1 inactive">Gym</span>
                            <div onClick={toggleSortingItem} id="select-gym" type="text" className="d-flex select-dropdown">
                                <div id="text-gym" className="select-sort-text">Select</div>
                                <img id="icon-gym" src={ArrowUp} className="select-sort-icon"/>
                            </div>
                            <div id="list-gym" className="dropdown-menu-items" style={{display:"none"}}>
                                <div id="itemId-gym" className="menu-text-spacing">Red Gold</div>
                                <div className="menu-text-spacing">Black Panther</div>
                                <div className="menu-text-spacing">Fitter</div>
                                <div className="menu-text-spacing">Workout Plus</div>
                            </div>
                        </div>
                        <div className="container-spacing">
                            <span className="g-font-1 inactive">Branch</span>
                            <div onClick={toggleSortingItem} id="select-branch" type="text" className="d-flex select-dropdown">
                                <div id="text-branch" className="select-sort-text">Select</div>
                                <img id="icon-branch" src={ArrowUp} className="select-sort-icon"/>
                            </div>
                            <div id="list-branch" className="dropdown-menu-items" style={{display:"none"}}>
                                <div id="itemId-branch" className="menu-text-spacing">Red Gold Branch</div>
                                <div className="menu-text-spacing">Black Panther Branch</div>
                                <div className="menu-text-spacing">Fitter Branch</div>
                                <div className="menu-text-spacing">Workout Plus Branch</div>
                            </div>
                        </div>
                        <div className="container-spacing">
                            <span className="g-font-1 inactive">Appointment Type</span>
                            <div onClick={toggleSortingItem} id="select-appointmentType" type="text" className="d-flex select-dropdown">
                                <div id="text-appointmentType" className="select-exercise-text">Select</div>
                                <img id="icon-appointmentType" src={ArrowUp} className="select-exercise-icon"/>
                            </div>
                            <div id="list-appointmentType" className="dropdown-menu-items" style={{display:"none"}}>
                                <div id="itemId-appointmentType" className="menu-text-spacing">auto</div>
                                <div className="menu-text-spacing">self</div>
                            </div>
                        </div>
                        <div className="container-spacing">
                            <span className="g-font-1 inactive">Appointment Status</span>
                            <div onClick={toggleSortingItem} id="select-appointmentStatus" type="text" className="d-flex select-dropdown">
                                <div id="text-appointmentStatus" className="select-exercise-text">Select</div>
                                <img id="icon-appointmentStatus" src={ArrowUp} className="select-exercise-icon"/>
                            </div>
                            <div id="list-appointmentStatus" className="dropdown-menu-items" style={{display:"none"}}>
                                <div id="itemId-appointmentStatus" className="menu-text-spacing">pending</div>
                                <div className="menu-text-spacing">cancelled</div>
                                <div className="menu-text-spacing">done</div>
                            </div>
                        </div>
                        <div className="container-spacing">
                            <span className="g-font-1 inactive">Date</span>
                           <div className="d-flex" style={{width:"100%", justifyContent: "space-between", marginTop:"2%"}}>
                                <div>
                                    <div className="g-font-1">From</div>
                                    <div className="d-flex">
                                        <input id="input-dob" className="my-profile-field" placeholder="DD-MM-YY" style={{width:"7vw", fontSize:"0.9vw"}}/>
                                        {/* <span id="edit-dob" class="material-icons-round edit-icon">edit</span>
                                        <span id="save-dob" class="material-icons-round edit-icon" style={{display: "none"}}>done</span> */}
                                    </div>
                                </div>
                                <div>
                                    <div className="g-font-1">To</div>
                                    <div className="d-flex">
                                        <input id="input-dob" className="my-profile-field" placeholder="DD-MM-YY" style={{width:"7vw", fontSize:"0.9vw"}} />
                                        {/* <span id="edit-dob" class="material-icons-round edit-icon">edit</span>
                                        <span id="save-dob" class="material-icons-round edit-icon" style={{display: "none"}}>done</span> */}
                                    </div>
                                </div>
                           </div>

                        </div>
                    </div>

                </div>

                <div className="pagination-container">
                    <div className="pagination-tracker">
                        1 - 9  of  56 
                    </div>
                    <img onClick={prev} src={ArrowLeft}  className="pagination-icon"/>
                    <img onClick={next} src={ArrowRight} className="pagination-icon"/>
                </div>
            </div>

        </div>

        <div className="header-bar d-flex space-between">
            <div className='d-flex'>
                <svg className='flex-item m-r-12 m-t-4' width="50" height="50" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.0013 18.25C26.7062 18.25 29.7096 15.2466 29.7096 11.5417C29.7096 7.83677 26.7062 4.83334 23.0013 4.83334C19.2964 4.83334 16.293 7.83677 16.293 11.5417C16.293 15.2466 19.2964 18.25 23.0013 18.25Z" stroke="#055075" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.83398 40.2917C3.83398 31.8229 11.5572 24.9583 21.084 24.9583" stroke="#055075" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M32.584 42.2083C37.3474 42.2083 41.209 38.3468 41.209 33.5833C41.209 28.8199 37.3474 24.9583 32.584 24.9583C27.8205 24.9583 23.959 28.8199 23.959 33.5833C23.959 38.3468 27.8205 42.2083 32.584 42.2083Z" stroke="#055075" stroke-width="2.5"/>
                    <path d="M31.625 30.7083V34.5417H35.4583" stroke="#055075" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div className='flex-item'>
                    <p className="dashboard-type-heading flex-item">Appointment</p>
                    <div className='d-flex m-t-4'>
                        <p className='dashboard-route-txt flex-item m-r-6'>Gym Dashboard</p>
                        <svg className='m-r-6 flex-item' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 1L6 6.5L0.5 11.5" stroke="#6D6D6D" stroke-linecap="round"/>
                        </svg>
                        <p className='dashboard-route-txt-active flex-item m-r-6'>Appointments</p>
                    </div>
                </div>
            </div>
            <div className='d-flex'>
                <div id="search-container" className='global-search-box m-r-12'>
									<svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_1637_1318)">
										<path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D"/>
										</g>
										<defs>
										<clipPath id="clip0_1637_1318">
										<rect width="16" height="16" fill="white"/>
										</clipPath>
										</defs>
									</svg>
									<input id="search-appointment-input" className='flex-item' type="text" placeholder="Search Appointment" />
                </div>
                <div onClick={toggleAppointmentFilterOpen.bind(this)} className='global-round-btn d-flex m-r-12'>
                    <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D"/>
                    </svg>
                    {
											isAppointmentFilterListOpen  && (
												<div onClick={e => e.stopPropagation()} className='gym-filter-popup' style={{width: "210px"}}>
													<p className='filter-heading-text m-b-12'>Date Range</p>
													<div onClick={toggleInputFields('Start Date')} className={`${currentElementId == "Start Date" ? "member-global-input-field m-b-12 flex-item" : "member-global-input-field m-b-12 flex-item d-flex"}`} style={{ border: currentElementId == "Start Date" ? "1px solid #055075" : "" }}>
														<div className='d-flex'>
															<div className="flex-item d-flex">
																<svg className="flex-item" width={currentElementId == "Start Date" ? "10" : "18"} height={currentElementId == "Start Date" ? "10" : "18"} style={{ transition: "180ms" }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<g clip-path="url(#clip0_2193_1361)">
																	<path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0ZM2 2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V4H15V3C15 2.73478 14.8946 2.48043 14.7071 2.29289C14.5196 2.10536 14.2652 2 14 2H2ZM15 5H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V5Z" fill={currentElementId == "Start Date" ? "#055075" : "#6d6d6d"}/>
																	<path d="M9 7.5C9 7.36739 9.05268 7.24021 9.14645 7.14645C9.24021 7.05268 9.36739 7 9.5 7H15V9H9.5C9.36739 9 9.24021 8.94732 9.14645 8.85355C9.05268 8.75979 9 8.63261 9 8.5V7.5ZM7 10.5V11.5C7 11.6326 6.94732 11.7598 6.85355 11.8536C6.75979 11.9473 6.63261 12 6.5 12H1V10H6.5C6.63261 10 6.75979 10.0527 6.85355 10.1464C6.94732 10.2402 7 10.3674 7 10.5Z" fill={currentElementId == "Start Date" ? "#055075" : "#6d6d6d"}/>
																	</g>
																	<defs>
																	<clipPath id="clip0_2193_1361">
																	<rect width="16" height="16" fill="white"/>
																	</clipPath>
																	</defs>
																</svg>
																<p className="placeholder-txt m-l-6 flex-item" style={{ fontSize: currentElementId == "Start Date" ? "10px" : "14px", color: currentElementId == "Start Date" ? "#055075" : "", transition: "180ms" }}>Start Date</p>
															</div>
														</div>
														<input id="Start Date" className={`${currentElementId == "Start Date" ? "active-input-field" : "inactive-input-field"}`} type="text" />
													</div>
													<div onClick={toggleInputFields('End Date')} className={`${currentElementId == "End Date" ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "End Date" ? "1px solid #055075" : "" }}>
														<div className='d-flex'>
															<div className="flex-item d-flex">
																<svg className="flex-item" width={currentElementId == "End Date" ? "10" : "18"} height={currentElementId == "End Date" ? "10" : "18"} style={{ transition: "180ms" }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<g clip-path="url(#clip0_2193_1361)">
																	<path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0ZM2 2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V4H15V3C15 2.73478 14.8946 2.48043 14.7071 2.29289C14.5196 2.10536 14.2652 2 14 2H2ZM15 5H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V5Z" fill={currentElementId == "End Date" ? "#055075" : "#6d6d6d"}/>
																	<path d="M9 7.5C9 7.36739 9.05268 7.24021 9.14645 7.14645C9.24021 7.05268 9.36739 7 9.5 7H15V9H9.5C9.36739 9 9.24021 8.94732 9.14645 8.85355C9.05268 8.75979 9 8.63261 9 8.5V7.5ZM7 10.5V11.5C7 11.6326 6.94732 11.7598 6.85355 11.8536C6.75979 11.9473 6.63261 12 6.5 12H1V10H6.5C6.63261 10 6.75979 10.0527 6.85355 10.1464C6.94732 10.2402 7 10.3674 7 10.5Z" fill={currentElementId == "End Date" ? "#055075" : "#6d6d6d"}/>
																	</g>
																	<defs>
																	<clipPath id="clip0_2193_1361">
																	<rect width="16" height="16" fill="white"/>
																	</clipPath>
																	</defs>
																</svg>
																<p className="placeholder-txt m-l-6 flex-item" style={{ fontSize: currentElementId == "End Date" ? "10px" : "14px", color: currentElementId == "End Date" ? "#055075" : "", transition: "180ms" }}>End Date</p>
															</div>
														</div>
														<input id="End Date" className={`${currentElementId == "End Date" ? "active-input-field" : "inactive-input-field"}`} type="text" />
													</div>

													{
														!selectedGym.email && [
															<div className='flex-item w-full'>
																{/* onClick={handleFilterModalOpen.bind(this, "gym")} */}
                                                                <div className='member-button-global btn-center d-flex m-t-20'>
																	<p className='flex-item'>Filter By Gym</p>
																</div>
															</div>
														]
                      		}

													{
														selectedGym.email && [
															<div className='d-flex'>
																<div className='d-flex m-t-20'>
																	<p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
																	<svg className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075"/>
																	</svg>
																</div>
															</div>,
															<div className="d-flex w-full m-t-8">
																<div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ background: "#f5f5f5" }}>
																	<img className="flex-item" src={GymLogo} />
																</div>
																<div className="flex-item">
																	<p className="branch-gym-name">Gold Gym</p>
																	<p className="branch-gym-email">goldgym.cs@gmail.com</p>
																</div>
															</div>
														]
													}

													{
														selectedGym.email && [
															<div onClick={handleFilterModalOpen.bind(this, "branch")} className='member-button-global btn-center d-flex m-t-20'>
																<p className='flex-item'>Filter By Branch</p>
															</div>
														]
													}
													
													{
														selectedBranch.branchAddress && [
															<div className='d-flex'>
																<div className='d-flex m-t-20'>
																	<p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
																	<svg className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075"/>
																	</svg>
																</div>
															</div>,
															<div className="d-flex w-full m-t-8">
																<p>C-911, Alfa Landmark, Wagholi, Pune, Maharashtra</p>
															</div>
														]
													}
												</div>
											)
                    }
                </div>
                <div className='global-round-btn d-flex'>
                    <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D"/>
                    </svg>
                </div>
            </div>
            {/* <div>
                <div className="dashboard-name-container">
                    <div className="dashboard-name">Gym Accounts</div>

                    <span onClick={toggleGlobalActionContainer} class="material-icons-round" style={{ color: "#bdbdbd", margin: "-0.8% 0 0 8%", cursor: "pointer" }}>add_circle_outline</span>
                    <div id="global-action-container" className="add-global-action-container" style={{ display: "none" }}>
                        <div role="button" onClick={handleCreateOpen} className="action-item d-flex">
                            <span class="material-icons-round flex-item">fitness_center</span>
                            <div className="flex-item spacing-19">Add Gym</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("Branch")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">store</span>
                            <div className="flex-item spacing-19">Add Branch</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("Member")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">person_add_alt</span>
                            <div className="flex-item spacing-19">Add Member</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("MemberReport")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">playlist_add</span>
                            <div className="flex-item spacing-19">Add Member Report</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("BranchUser")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">group_add</span>
                            <div className="flex-item spacing-19">Add User</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute('Content')} className="action-item d-flex">
                            <span class="material-icons-round flex-item">post_add</span>
                            <div className="flex-item spacing-19">Add Content</div>
                        </div>
                        <div role="button" onClick={NavigateToRoute("Planner")} className="action-item d-flex">
                            <span class="material-icons-round flex-item">addchart</span>
                            <div className="flex-item spacing-19">Create Planner</div>
                        </div>
                        <div className="action-item d-flex">
                            <span class="material-icons-round flex-item">insert_chart</span>
                            <div className="flex-item spacing-19">Assign Planner</div>
                        </div>

                    </div>

                </div>
                <div onClick={handleActiveInactiveGym} className="active-inactive-container">
                    <img src={SwapIcon} className="active-inactive-icon" />
                    <div className="active-inactive-text">{mapAction == 0 ? "Inactive" : "Active"}</div>
                </div>
            </div>

            <div>
                <div className="search-field-container">
                    <input type="text" id="search-gym-input" className="search-field" placeholder="search" />
                    <div className="filter-container" onClick={toggleFilter}>
                        <img src={FilterIcon} className="filter-icon" />
                    </div>
                    {
                        isToggleFilter && (
                            <div className="filter-dropdown">
                                <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="flex-item container-spacing bold-font">
                                            Sort By
                                        </div>
                                    </div>

                                    <div className="container-spacing" style={{
                                        minHeight: '40vh', maxHeight: '70vh'
                                    }}>
                                        <span className="g-font-1 inactive">Gym Type</span>

                                        <div
                                            onClick={tooggleInputState.bind(this, "target-gym-type")}
                                            type="text"
                                            className="d-flex select-dropdown"
                                        >
                                            <div className="select-exercise-text">{gymSortAttribute.sortGymType}</div>
                                            <img
                                                id="select-gymtype-icon"
                                                src={ArrowUp}
                                                className="select-exercise-icon"
                                            />
                                        </div>
                                        {
                                            isCurrentElement == "target-gym-type" && (
                                                <div className="dropdown-menu-items">
                                                    {
                                                        ['All', 'Comercial', 'Corporate', 'Residential'].map(name => <div onClick={handleSortBy('sortGymType', name)} className="menu-text-spacing">{name}</div>)
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='d-flex filter-dropdown-footer'>
                                        <div onClick={applyFilter} className='filter-dropdown-footer-apply-btn'>
                                            Apply
                                        </div>
                                        <div onClick={toggleFilter} className='filter-dropdown-footer-clear-btn' style={{ marginRight: "10px" }}>
                                            Clear
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>

                <div className="pagination-container">
                    <div className="pagination-tracker">
                        {(currentpage - 1) * pageSize + 1} - {(currentpage - 1) * pageSize + Gyms.length}  of  {total}
                    </div>
                    <img onClick={prev} src={ArrowLeft} className="pagination-icon" />
                    <img onClick={next} src={ArrowRight} className="pagination-icon" />
                </div>
            </div> */}

        </div>

        <div className='dashboard-data-saperator'></div>

        {Appointments.length ? ( 
            <div className='body-container'>
                <div className='w-full'>
                    {/* TODO */}
                    {/* <div class="calendar-action-bar d-flex w-full space-between">
                        <div class="d-flex flex-item action-bar-one">
                        <div class="isMobile flex-item">
                            <div class="d-flex">
                            {
                                state.calendarView == 'timeGridDay' && (
                                <p class="flex-item p-t-2 month-year-txt-mob" style="width : 90px">{formatDateTime(this.state.currentDate).split('/')[0] + ' ' + `${this.state.currentMonthMob}` + ' ' + formatDateTime(this.state.currentDate).split('/')[2]}</p>
                                )
                            }

                            {
                                state.calendarView == 'listMonth' && (
                                <p class="flex-item p-t-2 month-year-txt-mob" style="width : 90px">{`${this.state.currentMonthMob}` + ' ' + formatDateTime(this.state.currentDate).split('/')[2]}</p>
                                )
                            }

                            {
                                state.calendarView == 'listYear' && (
                                <p class="flex-item p-t-2 month-year-txt-mob" style="width : 50px">{formatDateTime(this.state.currentDate).split('/')[2]}</p>
                                )
                            }

                            </div>
                        </div>

                        <div class="flex-item d-flex">
                            <div class="isDesktop">
                            <div class="calendar-action-button space-between flex-item d-flex m-r-14">
                                <p class="flex-item m-r-8">
                                {state.calendarView === "timeGridWeek"
                                    ? "Week"
                                    : state.calendarView === "timeGridDay"
                                    ? "Day"
                                    : state.calendarView === "dayGridMonth"
                                        ? "Month"
                                        : "NAN"}
                                </p>

                            </div>
                            </div>
                            <div id="my-prev-button" class="date-navigation-container d-flex m-r-12 " >
                            <svg
                                class="flex-item m-r-8"
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M7 11L2 6L7 1"
                                stroke="#5D883C"
                                stroke-width="2"
                                stroke-linecap="round"
                                />
                            </svg>
                            </div>
                            <div id="my-next-button" class="date-navigation-container d-flex" >
                            <svg
                                class="flex-item m-l-8"
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M1 1L6 6L1 11"
                                stroke="#5D883C"
                                stroke-width="2"
                                stroke-linecap="round"
                                />
                            </svg>
                            </div>
                            <div class="flex-item d-flex m-l-24" style="position: relative">
                            <svg class="flex-item isDesktop" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8333 18.3332H4.16667C3.72464 18.3332 3.30072 18.1576 2.98816 17.845C2.67559 17.5325 2.5 17.1085 2.5 16.6665V4.99984C2.5 4.55781 2.67559 4.13389 2.98816 3.82133C3.30072 3.50877 3.72464 3.33317 4.16667 3.33317H5.83333V1.6665H7.5V3.33317H12.5V1.6665H14.1667V3.33317H15.8333C16.2754 3.33317 16.6993 3.50877 17.0118 3.82133C17.3244 4.13389 17.5 4.55781 17.5 4.99984V16.6665C17.5 17.1085 17.3244 17.5325 17.0118 17.845C16.6993 18.1576 16.2754 18.3332 15.8333 18.3332ZM4.16667 8.33317V16.6665H15.8333V8.33317H4.16667ZM4.16667 4.99984V6.6665H15.8333V4.99984H4.16667ZM14.1667 14.9998H12.5V13.3332H14.1667V14.9998ZM10.8333 14.9998H9.16667V13.3332H10.8333V14.9998ZM7.5 14.9998H5.83333V13.3332H7.5V14.9998ZM14.1667 11.6665H12.5V9.99984H14.1667V11.6665ZM10.8333 11.6665H9.16667V9.99984H10.8333V11.6665ZM7.5 11.6665H5.83333V9.99984H7.5V11.6665Z" fill="#9A9A9A" />
                            </svg>
                            <input id="calander-date-btn-desktop" type="text" style="border: 0; width: 14px; outline: 0; position: absolute;">
                            </input>
                            </div>
                        </div>
                        </div>

                        {
                        state.calendarView == 'timeGridWeek' && (
                            <p class="flex-item month-year-txt-mob isDesktop">{state.weekStartDate + ' ' + `${this.state.currentMonth}` + ' ' + '-' + ' ' + state.weekEndDate + ' ' + `${this.state.currentNextMonth}` + ' ' + formatDateTime(this.state.currentDate).split('/')[2]}</p>
                        )
                        }

                        {
                        state.calendarView == 'dayGridMonth' && (
                            <p class="flex-item month-year-txt-mob isDesktop">{`${this.state.currentMonth}` + ' ' + formatDateTime(this.state.currentDate).split('/')[2]}</p>
                        )
                        }

                        {
                        state.calendarView == 'timeGridDay' && (
                            <p class="flex-item month-year-txt-mob isDesktop">{`${this.state.currentMonth}` + ' ' + formatDateTime(this.state.currentDate).split('/')[2]}</p>
                        )
                        }

                        <div class="d-flex flex-item action-bar-two">
                        {
                            state.userInfo.isParent && (
                            <p style="white-space: nowrap; color: #4d4d4d; font-size: 14px;">Academic 2022 - 23</p>
                            )
                        }

                        {
                            ((state.userInfo.isShapeAdmin || state.userInfo.isSchoolAdmin || state.userInfo.isFranchiseAdmin || state.userInfo.isContentAdmin) && state.selectedAcademicYear && state.selectedAcademicYear._id == state.academicYears[0]._id) && (
                            <div onClick={this.toggleHolidayList.bind(this)} class="schedule-meeting-button flex-item isDesktop m-l-12">
                                <div class="d-flex">
                                <p class="flex-item schedule calendar-txt m-l-8">Holiday</p>
                                <svg class="flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.38086 6.19038H17.619M4.28562 2.38086H15.7142C16.2194 2.38086 16.7039 2.58154 17.0611 2.93875C17.4183 3.29596 17.619 3.78045 17.619 4.28562V15.7142C17.619 16.2194 17.4183 16.7039 17.0611 17.0611C16.7039 17.4183 16.2194 17.619 15.7142 17.619H4.28562C3.78045 17.619 3.29596 17.4183 2.93875 17.0611C2.58154 16.7039 2.38086 16.2194 2.38086 15.7142V4.28562C2.38086 3.78045 2.58154 3.29596 2.93875 2.93875C3.29596 2.58154 3.78045 2.38086 4.28562 2.38086Z" stroke="#3473ba" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.99926 10.9526C10.5252 10.9526 10.9516 10.5262 10.9516 10.0002C10.9516 9.47425 10.5252 9.04785 9.99926 9.04785C9.47327 9.04785 9.04688 9.47425 9.04688 10.0002C9.04688 10.5262 9.47327 10.9526 9.99926 10.9526Z" fill="#3473ba" />
                                    <path d="M6.19066 10.9526C6.71665 10.9526 7.14304 10.5262 7.14304 10.0002C7.14304 9.47425 6.71665 9.04785 6.19066 9.04785C5.66468 9.04785 5.23828 9.47425 5.23828 10.0002C5.23828 10.5262 5.66468 10.9526 6.19066 10.9526Z" fill="#3473ba" />
                                    <path d="M6.19066 14.7622C6.71665 14.7622 7.14304 14.3358 7.14304 13.8098C7.14304 13.2838 6.71665 12.8574 6.19066 12.8574C5.66468 12.8574 5.23828 13.2838 5.23828 13.8098C5.23828 14.3358 5.66468 14.7622 6.19066 14.7622Z" fill="#3473ba" />
                                </svg>
                                </div>
                            </div>
                            )
                        }
                        </div>
                    </div> */}
                    {/* <div style={`${state.calendarView == 'listMonth' ? "margin-top: -10px;" : ""} ${state.selectedContent && `display:none !important;`}`} class={!state.userInfo.isParent && screen.width < 549 ? "calendar-view-mob" : "calendar-view" } id="calendar" /> */}
                </div>
            </div>
            // <div className="body-container">
            //     <div className='d-flex table-header-container'>
            //         <div onClick={checkAllAppointment.bind(this)} className='d-flex flex-item appointment-member-container'>
            //             <input className='flex-item m-r-8 cursor-pointer' type="checkbox" checked={isAllAppointmentChecked} />
            //             <p className='flex-item table-heading-txt'>Member</p>
            //         </div>
            //         <div className='appointment-date-container flex-item'>
            //             <p className='flex-item table-heading-txt'>Date</p>
            //         </div>
            //         <div className='appointment-reason-container flex-item'>
            //             <p className='flex-item table-heading-txt'>Reason</p>
            //         </div>
            //         <div className='appointment-date-container flex-item'>
            //             <p className='flex-item table-heading-txt'>Appointment Date</p>
            //         </div>
            //         <div className='appointment-time-container flex-item'>
            //             <p className='flex-item table-heading-txt'>Time</p>
            //         </div>
            //         <div className='appointment-status-container flex-item'>
            //             <p className='flex-item table-heading-txt'>Status</p>
            //         </div>
            //         <div className='action-container flex-item'>
            //             <p className='flex-item table-heading-txt'>Action</p>
            //         </div>
            //     </div>
            //     <div className='flex-item main-table-scroll-container w-full'>

            //         <div className='flex-item table-row-container d-flex'>
            //             <div className='flex-item appointment-member-container d-flex'>
            //                 <input className='flex-item m-r-8' name="gym-checkbox" type="checkbox"  checked={isAllAppointmentChecked}/> 
            //                 <div className='member-logo flex-item m-r-8'>
            //                     <img id="gym-ImgWrapper" src={GoogleIcon} loading='lazy' className="member-logo"/>
            //                 </div>
            //                 {/* {
            //                     gym.logoFileID ?
            //                         <div className='gym-logo flex-item m-r-8'>
            //                             <img id="gym-ImgWrapper" src={`${API}/photo/gym/${gym._id}`} loading='lazy' className="gym-logo"/>
            //                         </div>
            //                         : <div className="gym-logo m-r-8 flex-item d-flex" style={{
            //                             backgroundColor: gym.logoPlaceholderColor ? gym.logoPlaceholderColor : "#5d883c"
            //                         }}>
            //                             <p className='flex-item' style={{
            //                                 color: "#ffffff",
            //                                 margin: "0 auto",
            //                                 fontSize: 14,
            //                             }}>{`${gym.gymName}`.trim()[0]}</p>
            //                         </div>
            //                 } */}
            //                 <p className='flex-item'>Member Name</p>
            //             </div>
            //             <div className='appointment-date-container flex-item'>
            //                 <p className='flex-item'>21 Jan 2022</p>
            //             </div>
            //             <div className='appointment-reason-container flex-item'>
            //                 <p className='flex-item'>Councelling</p>
            //             </div>
            //             <div className='appointment-date-container flex-item'>
            //                 <p className='flex-item'>21 Jan 2022</p>
            //             </div>
            //             <div className='appointment-time-container flex-item'>
            //                 <p className='flex-item'>22:00</p>
            //             </div>
            //             <div className='appointment-status-container d-flex flex-item'>
            //                 <div className='flex-item m-r-6' style={{width: "6px", height: "6px", background: "#2DE33F", borderRadius: "100%"}}/>
            //                 <p className='flex-item' style={{color:"#2DE33F"}}>Complete</p>
            //             </div>
            //             <div className='action-container flex-item'>
            //                 <div onClick={toggleAppointmentAction} className='action-horizontain-dots d-flex'>
            //                     <span className='flex-item m-r-2'></span>
            //                     <span className='flex-item m-r-2'></span>
            //                     <span className='flex-item'></span>
            //                 </div>
            //                 <div id="action-memberAppointmentId" className="table-action-container" style={{display:"none", width:"14.2vw",  margin: "-0.4% 0 0 -120px", zIndex:1}}>
            //                     <div onClick={handleAppointment(3)} role="button" className="d-flex spacing-22">
            //                         <img src={UpdateIcon} className="body-content-two-action-icon" />
            //                         <div className="spacing-24">Book Appointment</div>
            //                     </div>
            //                     <div style={{margin:"2% 0", width:"100%", height:"1px", backgroundColor:"#f5f5f5"}}></div>
            //                     <div  onClick={handleAppointment(2)}   role="button" className="d-flex spacing-22">
            //                         <img src={ScheduleIcon} className="body-content-two-action-icon" />
            //                         <div className="spacing-24">Reschedule</div>
            //                     </div>
            //                     <div style={{margin:"2% 0", width:"100%", height:"1px", backgroundColor:"#f5f5f5"}}></div>
            //                     <div onClick={handleAppointment(0)}  role="button" className="d-flex spacing-22">
            //                         <img src={ExtendIcon} className="body-content-two-action-icon" />
            //                         <div className="spacing-24">Extend Same Planner</div>
            //                     </div>
            //                     <div style={{margin:"2% 0", width:"100%", height:"1px", backgroundColor:"#f5f5f5"}}></div>
            //                     <div onClick={handleAppointment(4)}  role="button" className="d-flex spacing-22">
            //                         <img src={SuccessIcon} className="body-content-two-action-icon" />
            //                         <div className="spacing-24">Complete</div>
            //                     </div>
            //                     <div style={{margin:"2% 0", width:"100%", height:"1px", backgroundColor:"#f5f5f5"}}></div>
            //                     <div onClick={handleAppointment(5)} role="button" className="d-flex spacing-22">
            //                         <img src={BlockIcon} className="body-content-two-action-icon" />
            //                         <div className="spacing-24">Cancel</div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>

            //         <div className='bottom-pagination-bar'>
            //             {
            //                 isAllAppointmentChecked ? (
            //                     <div className='d-flex flex-item'>
            //                         <div class="global-button-container d-flex flex-item p-l-12 p-r-12" style={{margin: "0 auto"}}>
            //                             <div role="button" className="d-flex">
            //                                 <svg className='flex-item svg-fill m-r-8' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                                     <g clip-path="url(#clip0_1655_2069)">
            //                                     <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 9.72391 14.219 11.781 13 13C11.781 14.219 9.72391 15 8 15C6.27609 15 4.21899 14.219 3 13C1.78101 11.781 1 9.72391 1 8C1 6.27609 1.78101 4.21899 3 3C4.21899 1.78101 6.27609 1 8 1C9.72391 1 11.781 1.78101 13 3C14.219 4.21899 15 6.27609 15 8ZM16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM5.28 4.22C5.13783 4.08752 4.94978 4.0154 4.75548 4.01883C4.56118 4.02225 4.37579 4.10097 4.23838 4.23838C4.10097 4.37579 4.02225 4.56118 4.01883 4.75548C4.0154 4.94978 4.08752 5.13783 4.22 5.28L10.72 11.78C10.7887 11.8537 10.8715 11.9128 10.9635 11.9538C11.0555 11.9948 11.1548 12.0168 11.2555 12.0186C11.3562 12.0204 11.4562 12.0018 11.5496 11.9641C11.643 11.9264 11.7278 11.8703 11.799 11.799C11.8703 11.7278 11.9264 11.643 11.9641 11.5496C12.0018 11.4562 12.0204 11.3562 12.0186 11.2555C12.0168 11.1548 11.9948 11.0555 11.9538 10.9635C11.9128 10.8715 11.8537 10.7887 11.78 10.72L5.28 4.22Z" fill="#6D6D6D"/>
            //                                     </g>
            //                                     <defs>
            //                                     <clipPath id="clip0_1655_2069">
            //                                     <rect width="16" height="16" fill="white"/>
            //                                     </clipPath>
            //                                     </defs>
            //                                 </svg>
            //                                 <p className='flex-item'>Block All</p>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 ) : (
            //                     <div className='d-flex flex-item space-between'>
            //                         <div className='global-button-container d-flex flex-item'>
            //                             <p className='flex-item'>Previous</p>
            //                         </div>
            //                         <p className='flex-item pagination-txt'>1 - 30 of 200</p>
            //                         <div className='global-button-container d-flex flex-item'>
            //                             <p className='flex-item'>Next</p>
            //                         </div>
            //                     </div>
            //                 )
            //             }
                        
            //         </div>
                    
            //     </div>
            // </div>
        ):(
            <div className="member-menu-body d-flex w-full" style={{height:"80vh"}}>
                <div className="w-full flex-item" style={{justifyContent:"center", textAlign:"center"}}>
                    <img className="flex-item" style={{ width: "40%", margin: "0 auto" }} src={NoDataFound} />
                    <p className='fs-20 m-t-20' style={{lineHeight:"24px", color:"#055075"}}>No Appointments</p>
                    <div className="m-b-40 w-full"/>
                </div>
            </div>
        )}

      

        {/* popup section code */}    
        <div  className={`content-add-section content-add-section-rs-size`}  style={{display:openDialog?"block":"none"}}>
            <div className="exerise-header-bar">
                <div style={{display:"flex", alignSelf:"center"}} >

                     {
                         formrole!=5&&(
                            <div className="exercise-header-text" style={{marginRight:"6%", alignSelf:"center", whiteSpace:"nowrap"}}>{title}</div>
                         )
                     }
                    {
                        (formrole==0||formrole==5)&&(
                            <div className="popup-selected-item">
                              <div>{formrole==0?`Planner Name`:`Appointment`}</div>
                             </div>
                        )
                    }

                </div>

                <img  src={Cross} role="button" onClick={handleCloseDialog} className="exercise-header-close"/>
            </div>

            <div style={{transition:'0.5s',overflowY:'hidden'}} className="exercise-body-container">

            <div id="owner-info-container" className="popcontainer-wrapper"> 


                {
                    formrole==0?[

                    <input   onMouseDown={()=>{
                        Flatpickr("#date-planner",{
                            onChange:()=>{
                                setPlanner(oldstate=>{
                                    oldstate['planner_startDate']=document.getElementById("date-planner").value
                                    return ({...oldstate});
                                })
                            }
                        }).open()
                    }} id="date-planner" value={planner_startDate} style={{marginRight:5}} className="input-popup input-popup-space" placeholder="Start Date"/>
                    ,
                    <div style={{marginTop:'2%'}} className="workout-freq mb-4">
                        <div className="planner-assign-text-header">Weekly Workout Frequency</div>
                        <div className="mt-3 sliderrange-wrapper">
                            <div style={{alignSelf:'center'}} class="slidecontainer">
                                <input type="range" class="slider" id="myRange"></input>
                            </div>
                            <div style={{alignSelf:'center'}}>
                                30
                            </div>
                        </div>
                    </div>,

                    <div className="week-days-select" >
                        <div className="planner-assign-text-header">Mark Offdays</div>
                        <div class="weekDays-selector mt-3">
                                <input type="checkbox" id="weekday-mon" class="weekday" />
                                <label htmlFor="weekday-mon">M</label>
                                <input type="checkbox" id="weekday-tue" class="weekday" />
                                <label htmlFor="weekday-tue">T</label>
                                <input type="checkbox" id="weekday-wed" class="weekday" />
                                <label htmlFor="weekday-wed">W</label>
                                <input type="checkbox" id="weekday-thu" class="weekday" />
                                <label htmlFor="weekday-thu">T</label>
                                <input type="checkbox" id="weekday-fri" class="weekday" />
                                <label htmlFor="weekday-fri">F</label>
                                <input type="checkbox" id="weekday-sat" class="weekday" />
                                <label htmlFor="weekday-sat">S</label>
                                <input type="checkbox" id="weekday-sun" class="weekday" />
                                <label htmlFor="weekday-sun">S</label>
                        </div>
                    </div>


                    ]:formrole==5?[
                        <h6 style={{padding:'2% 5%',textAlign:'center'}}>
                            Are you sure you want to  cancel this appointment ?
                        </h6>
                    ]:[



                    formrole==1&&(

                        <div  className="popcontainer-sub-wrapper">
                                
                        <div style={{marginRight:5,position:'relative'}} className="input-popup-space">

                            

                            <div onClick={()=>hanleDropDown("gym-list")} id="branch-wrapper" style={{width:'100%',cursor:'pointer'}} type="text" className="input-popup-flex input-popup">
                                <div id="gym-txt" className="select-exercise-text">{gymName==""? "Select Gym" : gymName}</div>
                                <img id="gym-list-icon" src={ArrowDown} style={{transition:'0.3s',transform:'rotate(-180deg)'}} className="select-exercise-icon"/>
                            </div>

                            <div id="gym-list" className="select-exercise-list" style={{display:"none",zIndex:1,position:'absolute'}}>
                                    {
                                        ['Gym 1', 'Gym 2', 'Gym 3'].map((gym,index)=>{
                                            return (
                                                <div  role="button" onClick={handleDropdownItem("gym",gym)}  className="exercise-list-container">
                                                    <div className="exercise-list">
                                                        {index+1}. {gym}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                            </div>
                        
                        </div>

                        <div style={{position:'relative'}} className="input-popup-space">                          

                                <div onClick={()=>hanleDropDown("branch-list")} id="branch-wrapper" style={{width:'100%',cursor:'pointer'}} type="text" className="input-popup-flex input-popup">
                                    <div id="branch-txt" className="select-exercise-text">{branchName==""?"Select Branch":branchName}</div>
                                    <img id="branch-list-icon" src={ArrowDown} style={{transition:'0.3s',transform:'rotate(-180deg)'}} className="select-exercise-icon"/>
                                </div>

                                <div id="branch-list" className="select-exercise-list" style={{display:"none",zIndex:1,position:'absolute'}}>
                                        {
                                            ['branch 1', 'branch 2', 'branch 3'].map((branch,index)=>{
                                                return (
                                                    <div  role="button" onClick={handleDropdownItem("branch",branch)}  className="exercise-list-container">
                                                        <div className="exercise-list">
                                                            {index+1}. {branch}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                </div>

                                </div>
                    
                       </div>
              
              
                    )
              ,
                   
                 formrole==1&&(
                    <div   className="popcontainer-sub-wrapper">
                                
                    <div  style={{marginRight:5,position:'relative'}} className="input-popup-space" >
                    <div onClick={()=>hanleDropDown("member-list")} id="member-Exercise" style={{width:'100%',cursor:'pointer'}} type="text" className="input-popup-flex input-popup">
                        <div id="member-txt" className="select-exercise-text">{mfname==""?"Select Member":mfname}</div>
                        <img id="member-list-icon" src={ArrowDown} style={{transition:'0.3s',transform:'rotate(-180deg)'}} className="select-exercise-icon"/>
                    </div>

                    <div id="member-list" className="select-exercise-list" style={{display:"none",position:'absolute'}}>
                          {
                              ['member 1', 'member 2', 'member 3'].map((member,index)=>{
                                  return (
                                      <div  role="button"  onClick={handleDropdownItem("member",member)}  className="exercise-list-container">
                                          <div className="exercise-list">
                                              {index+1}. {member}
                                          </div>
                                      </div>
                                  )
                              })
                          }
                    </div>
                    
            </div>

            <div  style={{position:'relative'}} className="input-popup-space" >
                    <div onClick={()=>hanleDropDown("reason-list")} id="reason-Exercise" style={{width:'100%',cursor:'pointer'}} type="text" className="input-popup-flex input-popup">
                        <div id="reason-txt" className="select-exercise-text">{reason==""?"Select Reason":reason}</div>
                        <img id="reason-list-icon" src={ArrowDown} style={{transition:'0.3s',transform:'rotate(-180deg)'}} className="select-exercise-icon"/>
                    </div>

                    <div id="reason-list" className="select-exercise-list" style={{display:"none",position:'absolute'}}>
                          {
                              ['Counciling', 'Planner Re-assign'].map((reason,index)=>{
                                  return (
                                      <div  role="button"  onClick={handleDropdownItem("reason",reason)}  className="exercise-list-container">
                                          <div className="exercise-list">
                                              {index+1}. {reason}
                                          </div>
                                      </div>
                                  )
                              })
                          }
                    </div>
                    
            </div>
    </div>
   
   
                 )
               ,

                    <div  className="popcontainer-sub-wrapper">
                    <input   onMouseDown={()=>{
                    Flatpickr("#date",{
                        onChange:()=>{
                            setappoientment(oldstate=>{
                                oldstate['complete']['date']=document.getElementById("date").value
                                return ({...oldstate});
                            })
                        }
                    }).open()
                    }} id="date" value={complete['date']} style={{marginRight:5}} className="input-popup input-popup-space" placeholder="Date"/>
                    <input onMouseDown={()=>{
                        Flatpickr("#time",{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            time_24hr: false,
                            onChange:()=>{
                                setappoientment(oldstate=>{
                                    oldstate['complete']['time']=document.getElementById("time").value
                                    return ({...oldstate});
                                })
                            }
                        }).open()
                    }} id="time" value={complete['time']}  className="input-popup input-popup-space" type="text" placeholder="Time" />
                    </div>,
                    (formrole!=2 && formrole!=1)&& (<textarea   id="remark" onInput={handleChange("remark")}  value={complete['remark']} className="input-popup input-message-box" placeholder="remark"/>),
                    formrole==4&&(
                        <div style={{   width: '80%',margin: '5% 0 1% 0',alignItems:'flex-start',justifyContent:'flex-start'}} className="terms-condition-class">   
                            <input id="terms-condition" type="checkbox"/>
                            <div>Member planner updated. ( <a href="#">Assign here</a> )</div>
                        </div>
                    ),
                    formrole==4&&(
                        <div style={{   width: '80%',margin: '1% 0',alignItems:'flex-start',justifyContent:'flex-start'}} className="terms-condition-class">   
                        <input id="terms-condition" type="checkbox"/>
                        <div >Member history updated. ( <a href="#">Add here</a> )</div>  
                        </div>
                    )



                    ]
                }



            </div>




            </div>

            <div>
                <center>
                    <div  className="register-button">
                        <div>{formrole==0?"Update":formrole==1?"Create":formrole==5?"Cancel":"Submit"}</div>
                    </div>  
                </center>
            </div>



        </div>

			{
				isFilterModalOpen == "gym" && (
					<div className="global-modal-container d-flex">
						<div className="global-filter-modal flex-item">
							<div className="d-flex w-full space-between global-modal-header-container" style={{borderRadius: "8px 8px 0 0"}}>
								<p className="flex-item modal-header-text">Select Gym</p>
								<div id="search-container-gym" className='global-search-box flex-item' style={{background: "#f5f5f5", width: "50%"}}>
									<svg id="search-icon-gym" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_1637_1318)">
										<path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D"/>
										</g>
										<defs>
										<clipPath id="clip0_1637_1318">
										<rect width="16" height="16" fill="white"/>
										</clipPath>
										</defs>
									</svg>
									<input id="search-gym-input" className='flex-item' type="text" placeholder="Search" style={{background: "#f5f5f5"}} />
								</div>
							</div>
							<div className='search-gym-modal-scroll'>
								{
									[1,2,3,4,5,6,7,8,9].map(data => {
										return(
											<div className="d-flex suggestion-list-items">
												<img className="flex-item branch-gym-user-profile m-r-8" src={GymLogo} />
												<div className="flex-item">
													<p className="branch-gym-user-name m-b-1">User Name</p>
													<p className="branch-gym-details-value">goldgym2@gmail.com</p>
												</div>
											</div>
										)
									})
								}
							</div>
							<div onClick={handleFilterModalOpen.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px"}}>
								<div className='member-button-global btn-center d-flex' style={{width:"100px", margin: "0 0 0 auto"}}>
									<p className='flex-item'>Cancel</p>
								</div>
							</div>
						</div>
					</div>
				)
      }

      {
        isFilterModalOpen == "branch" && (
          <div className="global-modal-container d-flex">
            <div className="global-filter-modal flex-item">
              <div className="d-flex w-full space-between global-modal-header-container" style={{borderRadius: "8px 8px 0 0"}}>
                <p className="flex-item modal-header-text">Select Branch</p>
                <div id="search-container-branch" className='global-search-box flex-item' style={{background: "#f5f5f5", width: "50%"}}>
                  <svg id="search-icon-branch" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1637_1318)">
                    <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1637_1318">
                    <rect width="16" height="16" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <input id="search-branch-input" className='flex-item' type="text" placeholder="Search" style={{background: "#f5f5f5"}} />
                </div>
              </div>
              <div className='search-gym-modal-scroll'>
                {
                  [1,2,3,4,5,6,7,8,9].map(data => {
                    return(
                      <div className="d-flex suggestion-list-items">
                        <img className="flex-item branch-gym-user-profile m-r-8" src={GymLogo} />
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">Gym Name, Wagholi</p>
                          <p className="branch-gym-details-value">C-911, Alfa LandMark, Pune, Maharashtra</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div onClick={handleFilterModalOpen.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px"}}>
                <div className='member-button-global btn-center d-flex' style={{width:"100px", margin: "0 0 0 auto"}}>
                  <p className='flex-item'>Cancel</p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      </Dashboard>
    )
}

export default Appoienment;