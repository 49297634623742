import React, {useState, useEffect, useRef} from "react";
import Lottie from 'lottie-web';
import animationData from '../../assets/confetti-lotti.json';
import Toast from "../../lib/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getUnAuthBranch } from "../../branch/helper/api";
import { getAllGosEmployeesByGym } from "../../member/helper/api";
import { saveEnquiry } from "../../home/helper/api";
import { getAuth, GoogleAuthProvider,RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";
import config from "../../config";
import { startLoader, stopLoader } from "../../lib/utils";
import { app } from "../../lib/firebase-config";
let setTimer=null;
// import { app } from '.././lib/firebase-config';


// initializeApp(config.firebaseConfig,'enquiryApp');
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

const Enquiry = (props) => {

  const history = useHistory();
  const [view, setView] = useState("enquiry");
  const [Branch,setBranch]=useState('');
  const [Employees,setEmployees] = useState([]);
  const [Enquiry,setEnquiry]=useState({
    full_name:'',
    email:'',
    phone:'',
    message:'',
    type:'Gym',
    sourceOfEnquiry:"",
    gosEmployeeDetail:{
        first_name:'',
        middle_name:'',
        last_name:'',
        mobile_number:'',
        email:'',
        profileId:'',
        user_id:''
    },
    gymId:'',
    branchId:''
  });


  const [currentElementId, setCurrentElementId] = useState("");

  const [handlePhoneNoEdit, setPhoneNoEdit] = useState(false);

  const [handleButtonLock, setButtonLock] = useState(true);

  const [sourceList, setSourceList] = useState([
    "Google or other search", "Word of mouth", "Hoarding", "Article or Blog post", "Social Media", "Radio", "Television", "Justdial", "Leaflet", "Magazine/Newspaper", "Others"
  ]);

  const [otpValue, setOtpValue] = useState('');
  const animationContainer = useRef(null);
  const inputRefs = useRef([]);


  
  const handleView = (view, resubmit) => {
    setView(view);
    if(view == "otp"){
      setTimeout(()=>{
        sendOtp();
      },1000);
      setButtonLock(true)
    }
    if(view == "celebration"){
      //verifyOtp();
    }
    if(resubmit == "resubmit"){

      const generateEmptyState = () => ({
        full_name:'',
        email:'',
        phone:'',
        message:'',
        type:'Gym',
        sourceOfEnquiry:"",
        gosEmployeeDetail:{
            first_name:'',
            middle_name:'',
            last_name:'',
            mobile_number:'',
            email:'',
            profileId:'',
            user_id:''
        },
        gymId:'',
        branchId:''
      });

      setEnquiry(generateEmptyState());
      setButtonLock(true)
    }
  }
  

  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    let element = document.getElementById(id);
    if (element) {
      element.focus();
      // let interval = setInterval(() => {
      //   if (element) {
      //     element.focus();
      //     clearInterval(interval)
      //   }
      // }, 0);
    }

    if(currentElementId == "source"){
      setCurrentElementId("");
    }

    if(currentElementId == "attendant"){
      setCurrentElementId("");
    }
  }

  const verifyOtp=() =>{
    startLoader();
    if(window.result){
      window.result.confirm(otpValue).then((result) => {
        const user = result.user;
        const container=document.getElementById("recaptcha-container");
        if(container) container.remove();
        createEnquiry(true);
        stopLoader()
      }).catch((error) => {
       stopLoader();
       new Toast({
        type:"error",
        message:"Phone number not verified",
        duration:1000
       });
       createEnquiry(false);
      });
    }
  }

  const handleSelectFromDropdown = (dropdownType, value) => {
    if(dropdownType == "source"){
      setEnquiry({
        ...Enquiry,
        sourceOfEnquiry:value
      });
      if(Enquiry.name != "" && Enquiry.email != "" && Enquiry.phone != "" && value != "" && Enquiry.gosEmployeeDetail.first_name != ""){
        setButtonLock(false);
      }else{
        setButtonLock(true);
      }
    }

    if(dropdownType == "attendant"){
      setEnquiry({
        ...Enquiry,
        gosEmployeeDetail: {
          first_name:value.first_name,
          middle_name:value.middle_name,
          last_name:value.last_name,
          mobile_number:value.middle_name,
          email:value.email,
          profileId:value.profile_id,
          user_id:value.user_id
        }
      });
      if(Enquiry.name != "" && Enquiry.email != "" && Enquiry.phone != "" && Enquiry.source != "" && value != ""){
        setButtonLock(false);
      }else{
        setButtonLock(true);
      }
    }

  }

  const GetAllGosEmployeesByGym = async (branchId) =>{
    try{
      const response = await getAllGosEmployeesByGym(branchId);
      if(!response.error){
        setEmployees(response);
      }else{
        console.log(response);
      }
    }catch(error){
      console.log(error)
    }
  }

  const handleChange = (name, value) => event => {
    value = !value ? event.target.value : value;
    setEnquiry({
      ...Enquiry,
      [name]: value
    });
    checkDataField();
  }

  const checkDataField = () => {
    if(Enquiry.full_name != "" && Enquiry.email != "" && Enquiry.phone != "" && Enquiry.phone.length == 10 && Enquiry.sourceOfEnquiry != "" && Enquiry.gosEmployeeDetail.first_name != ""){
      setButtonLock(false);
    }else{
      setButtonLock(true);
    }
  }


  // OTP

  const handleOTP = (e, index) => {
    const { value } = e.target;
    if (value.length > 1) return;

    const newOtpValue = otpValue.split('');
    newOtpValue[index] = value;
    setOtpValue(newOtpValue.join(''));

    if (value.length === 1 && index < 5) {
      // Move to the next field
      inputRefs.current[index + 1].focus();
    } else if (value.length === 0 && index > 0) {
      // Move to the previous field
      inputRefs.current[index - 1].focus();
    }
  };

  const setRef = (element, index) => {
    inputRefs.current[index] = element;
  };

  const handleUpdatePhone = () => {
    if(handlePhoneNoEdit){
      setPhoneNoEdit(false);      
    }else{
      setPhoneNoEdit(true);      
    }
  } 

  useEffect(()=>{
    checkDataField()
  },[Enquiry])

  const createEnquiry = async (isPhoneNumberVerified=false) =>{
    try{
      const payload = { 
        userInfo:{
          full_name:Enquiry.full_name,
          email:Enquiry.email,
          phone:Enquiry.phone,
          isPhoneNumberVerified
        },
        message:Enquiry.message,
        type:Enquiry.type,
        sourceOfEnquiry:Enquiry.sourceOfEnquiry,
        gosEmployeeDetail:Enquiry.gosEmployeeDetail,
        gymId:Branch.gymId,
        branchId:Branch._id,
        token:config.wpAuthToken 
      };
      const response = await saveEnquiry(payload);
      if(response.error){
        new Toast({
          type:"error",
          message:response.error,
          duration:3000
        });
      }else{
        handleView("celebration")
        // new Toast({
        //   type:"success",
        //   message:"Enquiry submitted successfully",
        //   duration:3000
        // });
      }
    }catch(error){
      //console.error(error);
      new Toast({
        type:"info",
        message:"Unable to create enquiry, try after sometime",
        duration:3000
      });
    }
  }

  const sendOtp = async () => {
    try {

      const container=document.getElementById("recaptcha-container");
      if(container) container.remove();
      const div=document.createElement('div');
      div.id="recaptcha-container";
      document.body.append(div);
      if(window.recaptchaVerifier) window.recaptchaVerifier.clear();
      window.recaptchaVerifier = new RecaptchaVerifier(document.getElementById('recaptcha-container'), {
        size: 'invisible',
        callback: () => {
            console.log('recaptcha resolved..')
        },
        'expired-callback': () => {
        }
      },auth);

      if(!setTimer){
        var elem = document.getElementById('some_div');
        elem.style.display='block';
        let timeLeft=30;

        setTimer=setInterval(()=>{
          if (timeLeft == -1) {
            clearInterval(setTimer);
            setTimer=null;
            elem.style.display='none';
          } else {
            if(elem) elem.innerHTML = "( 00:"+timeLeft.toString().padStart(2,"0")+" )";
            timeLeft--;
          }
        },1000);
        signInWithPhoneNumber(auth,"+91"+Enquiry.phone, window.recaptchaVerifier).then((result) => {  
          window.result=result;
          new Toast({
            type:"success",
            message:"OTP send to +91"+Enquiry.phone,
            duration:2000
          });
        }).catch((err) => {
          new Toast({
            type:"error",
            message:"Can't send otp please wait",
            duration:2000
          });
          console.log(err )
        });
      }else{
        new Toast({
          type:"error",
          message:"Can't send otp please wait",
          duration:2000
        });
      }
    
    } catch (error) {
        console.log('error sending otp ' + error)
    }
}

  useEffect(() => {
    if(otpValue.length === 6){
      setButtonLock(false)
    }else{
      setButtonLock(true)
    }
  },[otpValue])


  // Lotti Animation


  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => anim.destroy(); // Clean up on unmount
  }, [view === "celebration"]);

  useEffect(async ()=>{
    try{
    const branchId = props.match.params.branchId
    if(branchId){
      const response = await getUnAuthBranch(branchId);
      if(response.error){
        new Toast({
          message:"Branch not found",
          duration:3000,
          type:'error'
        })
        history.push("/")
      }else{
        setBranch(response);
        GetAllGosEmployeesByGym(response._id);
      }
    }else{
      
      new Toast({
        message:"Branch not found",
        duration:3000,
        type:'error'
      })
      history.push('/')
    }
    }catch(error){

    }
  },[props]);

  return(
    <div className="form-container">
      <svg className="top-styling" width="320" height="95" viewBox="0 0 320 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M320 22.6642C215.5 47 15.2716 91.3737 0 95V0H320V22.6642Z" fill="#ACDDF2"/>
      </svg>
      {
        view == "enquiry" ? (
          <div className="form-container d-flex">
          
            <div className="form-inner-container">
              <div className="d-flex">
                <p className="font-style-5 font-weight-700" style={{alignSelf:"flex-end"}}>Hello</p>
                <div className="fancy-dot" style={{alignSelf:"flex-end"}} />
              </div>
              <p className="font-style-6 color-purple m-t-12 m-b-20">Welcome to {Branch && Branch.gym.length && Branch.gym[0].name}</p>
              <div onClick={toggleInputFields('name')} className={`${currentElementId == "name" || Enquiry.full_name ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "name" || Enquiry.full_name ? "1px solid #055075" : "" }}>
                <div className="flex-item d-flex">
                  <svg className="flex-item m-r-8" width={currentElementId == "name" || Enquiry.full_name ? "14" : "18"} height={currentElementId == "email" || Enquiry.full_name ? "14" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 3.25C3.75 4.4905 4.7595 5.5 6 5.5C7.2405 5.5 8.25 4.4905 8.25 3.25C8.25 2.0095 7.2405 1 6 1C4.7595 1 3.75 2.0095 3.75 3.25ZM10 10.5H10.5V10C10.5 8.0705 8.9295 6.5 7 6.5H5C3.07 6.5 1.5 8.0705 1.5 10V10.5H10Z" fill={currentElementId == "name" || Enquiry.full_name ? "#055075" : "#6d6d6d"}/>
                  </svg>
                  <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId === "name" || Enquiry.full_name ? "10px" : "14px", color: currentElementId == "name" || Enquiry.full_name ? "#055075" : "", transition: "180ms" }}>Name</p>
                </div>
                <input id="name" value={Enquiry.full_name} onChange={handleChange("full_name")} className={`${currentElementId == "name" || Enquiry.full_name ? "active-input-field" : "inactive-input-field"}`} type="text" />
              </div>
              <div onClick={toggleInputFields('email')} className={`${currentElementId == "email" || Enquiry.email ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "email" || Enquiry.email ? "1px solid #055075" : "" }}>
                <div className="flex-item d-flex">
                  <svg className="flex-item m-r-8" width={currentElementId == "email" || Enquiry.email ? "14" : "18"}  height={currentElementId == "email" || Enquiry.email ? "14" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.32136 2.3999H10.2714C11.0214 2.3999 11.3994 2.7539 11.3994 3.4739V8.5259C11.3994 9.2399 11.0214 9.5999 10.2714 9.5999H2.32136C1.57136 9.5999 1.19336 9.2399 1.19336 8.5259V3.4739C1.19336 2.7539 1.57136 2.3999 2.32136 2.3999ZM6.29336 7.5599L10.3374 4.2419C10.4814 4.1219 10.5954 3.8459 10.4154 3.5999C10.2414 3.3539 9.92336 3.3479 9.71336 3.4979L6.29336 5.8139L2.87936 3.4979C2.66936 3.3479 2.35136 3.3539 2.17736 3.5999C1.99736 3.8459 2.11136 4.1219 2.25536 4.2419L6.29336 7.5599Z" fill={currentElementId == "email" || Enquiry.email ? "#055075" : "#6d6d6d"}/>
                  </svg>
                  <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId === "email" || Enquiry.email ? "10px" : "14px", color: currentElementId == "email" || Enquiry.email ? "#055075" : "", transition: "180ms" }}>Email</p>
                </div>
                <input id="email" value={Enquiry.email} onChange={handleChange("email")} className={`${currentElementId == "email" || Enquiry.email ? "active-input-field" : "inactive-input-field"}`} type="text" />
              </div>
              <div onClick={toggleInputFields('phone')} className={`${currentElementId == "phone" || Enquiry.phone ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: currentElementId == "phone" || Enquiry.phone ? "1px solid #055075" : "" }}>
                <div className="flex-item d-flex">
                  <svg className="flex-item m-r-8" width={currentElementId == "phone" || Enquiry.phone ? "14" : "18"}  height={currentElementId == "phone" || Enquiry.phone ? "14" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.89011 8.09206C5.34168 9.54341 7.10954 10.6635 8.55082 10.6635C9.19882 10.6635 9.76625 10.4374 10.2233 9.93513C10.4895 9.63877 10.6553 9.29227 10.6553 8.95091C10.6553 8.69977 10.5598 8.4587 10.3189 8.28791L8.78182 7.19291C8.54589 7.0322 8.35004 6.95184 8.16918 6.95184C7.93818 6.95184 7.73718 7.08256 7.50618 7.30841L7.14961 7.66006C7.09919 7.71105 7.0306 7.73995 6.95889 7.74041C6.87854 7.74041 6.80804 7.71041 6.75296 7.68513C6.44654 7.51948 5.91425 7.06241 5.41689 6.5702C4.92468 6.07799 4.46761 5.5457 4.30689 5.23434C4.2722 5.17292 4.25328 5.10386 4.25182 5.03334C4.25182 4.9682 4.27175 4.90263 4.32704 4.84756L4.67846 4.48113C4.90475 4.24991 5.03525 4.04891 5.03525 3.81791C5.03525 3.63727 4.95489 3.44141 4.78925 3.20527L3.70925 1.68341C3.53354 1.44234 3.28754 1.33691 3.01625 1.33691C2.68475 1.33691 2.33825 1.48756 2.04704 1.77406C1.55975 2.2412 1.34375 2.81849 1.34375 3.4562C1.34375 4.8977 2.44368 6.64563 3.89011 8.09206Z" fill={currentElementId == "phone" || Enquiry.phone ? "#055075" : "#6d6d6d"}/>
                  </svg>
                  <p className="placeholder-txt flex-item" style={{ fontSize: currentElementId === "phone" || Enquiry.phone ? "10px" : "14px", color: currentElementId == "phone" || Enquiry.phone ? "#055075" : "", transition: "180ms" }}>Phone</p>
                </div>
                <input id="phone" value={Enquiry.phone} onChange={handleChange("phone")} className={`${currentElementId == "phone" || Enquiry.phone ? "active-input-field" : "inactive-input-field"}`} type="number" />
              </div>
              <p className="color-purple font-size-12 m-b-4">Where did you hear about us ?</p>
              <div onClick={toggleInputFields('source')} className="member-global-input-field m-b-20 flex-item d-flex space-between" style={{ border: Enquiry.sourceOfEnquiry ? "1px solid #055075" : "", height:"40px" }}>
                <p className="flex-item font-style-4" style={{color: Enquiry.sourceOfEnquiry ? "#000000" : "#9a9a9a"}}>{Enquiry.sourceOfEnquiry ? Enquiry.sourceOfEnquiry : "Select a type"}</p>
                <svg className="flex-item" width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 5.5L12 1" stroke="#055075" stroke-linecap="round"/>
                </svg>

                {
                  currentElementId === "source" && (
                    <div className="global-input-field-arrow-dropdown" style={{marginTop: "50px", height: "200px", overflowY:'auto'}}>
                      {
                        sourceList.map(source => {
                          return(
                            <div onClick={() => handleSelectFromDropdown("source", source)} className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">{source}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                }
              </div>

              <p className="color-purple font-size-12 m-b-4">Name of Attendant</p>
              <div onClick={toggleInputFields('attendant')} className="member-global-input-field m-b-20 flex-item d-flex space-between" style={{ border: Enquiry.gosEmployeeDetail.first_name ? "1px solid #055075" : "", height:"40px" }}>
                <p className="flex-item font-style-4" style={{color: Enquiry.gosEmployeeDetail.first_name ? "#000000" : "#9a9a9a"}}>{Enquiry.gosEmployeeDetail.first_name ? Enquiry.gosEmployeeDetail.first_name + " " + Enquiry.gosEmployeeDetail.last_name : "Select Attendant"}</p>
                <svg className="flex-item" width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 5.5L12 1" stroke="#055075" stroke-linecap="round"/>
                </svg>

                {
                  currentElementId === "attendant" && (
                    <div className="global-input-field-arrow-dropdown" style={{marginTop: "50px", maxHeight: "200px", overflowY:'auto'}}>
                      {
                        Employees.map(employee => {
                          return(
                            <div onClick={() => handleSelectFromDropdown("attendant", employee)} className="global-input-field-arrow-dropdown-item">
                              <p className="flex-item">{employee.first_name} {employee.last_name}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                }
              </div>

              <div className="d-flex enq-form-submit-button-header">
                <div onClick={() => !handleButtonLock && handleView("otp")} className="rectangle-round-btn enq-form-submit-button" style={{background:handleButtonLock ? "#e9e9e9" : "#055075"}}>
                  <p className="font-style-4" style={{color:handleButtonLock ? "#7a7a7a" : "#ffffff"}}>Submit</p>
                </div>
              </div>

            </div>
          </div>
        ) : view == "otp" ? (
          <div className="form-container">
            <div className="form-otp-container d-flex">
              <div onClick={() => handleView("enquiry")} className="round-btn d-flex" style={{backgroundColor: "#BA52FF", position:"absolute", top: 0, left: 0, margin: "0 0 0 20px"}}>
                <svg className="flex-item" style={{margin:"0 auto"}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 9C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7V9ZM0.292893 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM15 7L1 7V9L15 9V7Z" fill="white"/>
                </svg>
              </div>
              <div className="d-flex" style={{width: "100%", height:"100%", justifyContent:"center", textAlign:"center"}}>
                <div className="w-full flex-item">
                  <p className="font-style-6 color-purple font-weight-600">{Branch && Branch.gym && Branch.gym.length>0 && Branch.gym[0].name}</p>
                  <p className="font-style-4 color-grey-06 font-weight-300 m-b-16">Please verify your Phone No.</p>
                  <div className="d-flex flex-item m-b-30">
                    <div className="d-flex rectangle-round-btn" style={{margin: "0 auto"}}>
                      <input onChange={handleChange("phone")} className={!handlePhoneNoEdit ? "pointer-event-toggle" : ""} type="number" value={Enquiry.phone} style={{width:"90px", color: "#4d4d4d"}} />
                      {
                        handlePhoneNoEdit ? (
                          <svg  onClick={() => handleUpdatePhone()} className="flex-item" width="12" height="12" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 7L6.5 12L15 1" stroke="#5D883C" stroke-width="2" stroke-linecap="round"/>
                          </svg>
                        ) : (
                          <svg onClick={() => handleUpdatePhone()} className="flex-item" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M11.424 0.576267C11.2415 0.393581 11.0248 0.248655 10.7863 0.149775C10.5477 0.0508951 10.292 0 10.0338 0C9.77561 0 9.51992 0.0508951 9.28139 0.149775C9.04286 0.248655 8.82616 0.393581 8.64367 0.576267L1.25121 7.96907C1.03123 8.18905 0.871239 8.46203 0.787245 8.762L0.0138011 11.5242C-0.00406902 11.5883 -0.00459431 11.6559 0.0122793 11.7202C0.0291529 11.7845 0.0628187 11.8432 0.109827 11.8902C0.156835 11.9372 0.215496 11.9708 0.279799 11.9877C0.344102 12.0046 0.411734 12.0041 0.475767 11.9862L3.23757 11.2128C3.53774 11.1294 3.81114 10.9695 4.03101 10.7488L11.424 3.35601C11.6066 3.17354 11.7515 2.95687 11.8503 2.71839C11.9491 2.47991 12 2.22429 12 1.96614C12 1.70799 11.9491 1.45237 11.8503 1.21388C11.7515 0.975404 11.6066 0.758737 11.424 0.576267ZM9.17413 1.10622C9.4022 0.878219 9.71151 0.750157 10.034 0.750204C10.3565 0.750251 10.6658 0.878402 10.8938 1.10647C11.1218 1.33453 11.2498 1.64383 11.2498 1.96631C11.2497 2.2888 11.1216 2.59806 10.8935 2.82606L10.1236 3.59548L8.40419 1.87565L9.17413 1.10622ZM7.87423 2.4061L9.5941 4.12593L3.50055 10.2189C3.37185 10.3484 3.21159 10.4422 3.03558 10.4908L0.915236 11.0843L1.50869 8.96448C1.55801 8.78875 1.65166 8.62864 1.78067 8.49952L7.87423 2.4061Z" fill="#B0B0B0"/>
                          </svg>
                        )
                      }
                      
                    </div>
                  </div>
                  <div className="otp-container m-b-12" style={{margin:"0 auto"}}>
                    {[0, 1, 2, 3, 4,5].map((i) => (
                      <input
                        className="otp-input"
                        key={i}
                        style={{margin:'0 2px'}}
                        ref={(el) => setRef(el, i)}
                        type="text"
                        maxLength="1"
                        onChange={(e) => handleOTP(e, i)}
                        onFocus={(e) => e.target.select()}
                      />
                    ))}
                  </div>
                  <p className="font-weight-500 color-orange" style={{display:"none"}}>Invalid OTP</p>
                  <div className="d-flex m-t-60 m-b-8">
                    <div onClick={() => !handleButtonLock && verifyOtp()} className="rectangle-round-btn otp-form-submit-button" style={{background:handleButtonLock ? "#e9e9e9" : "#055075"}}>
                      <p className="font-style-4" style={{color:handleButtonLock ? "#7a7a7a" : "#ffffff"}}>Confirm</p>
                    </div>
                  </div>
                  {/* <div className="login-user-data-container" style={{marginBottom:"30px",marginTop:"12px",display:'flex',flexDirection:'row-reverse' }}>
                            <div onClick={sendOtp} style={{cursor:'pointer'}} className="forgot-password">Resend Code</div>
                            <div id='some_div' style={{display:'none',marginRight:8,color:'#000'}} className='forgot-email-pswd-txt'></div> 
                          </div> */}
                  <p onClick={sendOtp} className="font-weight-400 color-blue" style={{textDecoration:"underline"}}>Resend OTP</p>
                  <p className="font-weight-400 color-grey-08">OTP has been sent <span id='some_div' className="m-l-8 font-weight-700 color-green">00:00</span></p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="form-container d-flex">
            <div className="form-celebrate-container flex-item d-flex">
              <div className="flex-item" style={{margin:"0 auto", textAlign:"center"}}>
                <p className="font-style-6 font-weight-700">Thank You</p>
                <p className="font-style-7 font-weight-500 color-purple m-t-12">{Enquiry.full_name}</p>
                <p className="m-t-8 font-style-4 color-purple">For Enquiring with us</p>
                <p className="color-grey-04 m-t-30 font-style-4" style={{fontStyle:"italic"}}>Our Executive will get it touch with you</p>
                <div className="d-flex">
                  <div onClick={() => handleView("enquiry", "resubmit")} className="d-flex" style={{margin:"200px auto 0 auto"}}>
                    <svg className="flex-item m-r-4" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 15.5625C7.55849 15.5605 6.1766 14.987 5.1573 13.9677C4.138 12.9484 3.56448 11.5665 3.5625 10.125C3.5625 9.97582 3.62176 9.83274 3.72725 9.72725C3.83274 9.62176 3.97582 9.5625 4.125 9.5625C4.27418 9.5625 4.41726 9.62176 4.52275 9.72725C4.62824 9.83274 4.6875 9.97582 4.6875 10.125C4.6875 10.9779 4.94042 11.8117 5.41429 12.5209C5.88815 13.2301 6.56167 13.7828 7.34968 14.1092C8.13768 14.4356 9.00478 14.521 9.84133 14.3546C10.6779 14.1882 11.4463 13.7775 12.0494 13.1744C12.6525 12.5713 13.0632 11.8029 13.2296 10.9663C13.396 10.1298 13.3106 9.26268 12.9842 8.47468C12.6578 7.68667 12.1051 7.01315 11.3959 6.53929C10.6867 6.06542 9.85293 5.8125 9 5.8125H7.125C6.97582 5.8125 6.83274 5.75324 6.72725 5.64775C6.62176 5.54226 6.5625 5.39918 6.5625 5.25C6.5625 5.10082 6.62176 4.95774 6.72725 4.85225C6.83274 4.74676 6.97582 4.6875 7.125 4.6875H9C10.4421 4.6875 11.8252 5.26038 12.8449 6.28011C13.8646 7.29984 14.4375 8.68289 14.4375 10.125C14.4375 11.5671 13.8646 12.9502 12.8449 13.9699C11.8252 14.9896 10.4421 15.5625 9 15.5625Z" fill="#A7A7A7"/>
                      <path d="M8.99951 8.06237C8.9256 8.06272 8.85237 8.0483 8.78411 8.01997C8.71585 7.99164 8.65394 7.94995 8.60201 7.89737L6.35201 5.64737C6.24667 5.5419 6.1875 5.39893 6.1875 5.24987C6.1875 5.10081 6.24667 4.95784 6.35201 4.85237L8.60201 2.60237C8.6535 2.54711 8.7156 2.50278 8.7846 2.47204C8.8536 2.44129 8.92809 2.42476 9.00361 2.42343C9.07914 2.42209 9.15416 2.43599 9.2242 2.46428C9.29424 2.49257 9.35787 2.53468 9.41128 2.58809C9.4647 2.64151 9.50681 2.70513 9.5351 2.77517C9.56339 2.84521 9.57728 2.92024 9.57595 2.99576C9.57462 3.07129 9.55808 3.14578 9.52734 3.21477C9.4966 3.28377 9.45227 3.34587 9.397 3.39737L7.54451 5.24987L9.397 7.10237C9.50234 7.20784 9.56151 7.35081 9.56151 7.49987C9.56151 7.64893 9.50234 7.7919 9.397 7.89737C9.34507 7.94995 9.28316 7.99164 9.2149 8.01997C9.14664 8.0483 9.07341 8.06272 8.99951 8.06237Z" fill="#A7A7A7"/>
                    </svg>
                    <p className="flex-item color-grey-06 font-weight-300 m-t-2">Resubmit Form</p>
                  </div>
                </div>
              </div>
            </div>
            <div ref={animationContainer} style={{ width: "60%", height: "60%", position: "absolute", top: 0, margin:"20% 20%", }} />

          </div>
        )
      }
      <svg className="bottom-styling" width="297" height="67" viewBox="0 0 297 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M297 0L0 67.5H297V0Z" fill="#ACDDF2"/>
      </svg>
    </div>
  )
}

export default Enquiry;