import React, {useState, useEffect} from "react";
import sampleProfileImage from '../../assets/sample-profile.png';
import NoDataFound from "../../assets/no_data.png";


export default function MemberAppointment(){

  const[modalType, setModalType] = useState("");
  const [isAppointmentInputActive, setWeightInputActive] = useState(false);
  const [appointmentReason, setWeightValue] = useState('Appointment Reason');

  const handleAppointmentModal = (currentModalType) => {
    if(modalType == currentModalType){
      setModalType("");
    }else{
      setModalType(currentModalType)
    }
  }

  const toggleInputFields = (currentInputField) => {
    let currentInput = document.getElementById(currentInputField);
    console.log(currentInput);

    if(currentInputField == 'appointment-reason-input-field'){
      currentInput.focus();
      
      setTimeout(() => { 
        if(currentInput == document.activeElement){
          setWeightInputActive(true)
        }else{
          setWeightInputActive(false);
        }
      }, 10);
    }
  }

  return(
    <div className="w-full h-full">
      <div className="member-menu-header d-flex-auto w-full space-between">
        <div className="menu-type-container">
          <p className="welcome-msg m-b-4">My Appointment</p>
          <div className="d-flex">
            <svg className="flex-item m-r-6" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.4375 11.25H9.5625V13.5H8.4375V11.25ZM11.25 10.125H12.375V13.5H11.25V10.125ZM5.625 7.875H6.75V13.5H5.625V7.875Z" fill="#055075"/>
              <path d="M14.0625 2.8125H12.375V2.25C12.375 1.95163 12.2565 1.66548 12.0455 1.4545C11.8345 1.24353 11.5484 1.125 11.25 1.125H6.75C6.45163 1.125 6.16548 1.24353 5.95451 1.4545C5.74353 1.66548 5.625 1.95163 5.625 2.25V2.8125H3.9375C3.63913 2.8125 3.35298 2.93103 3.142 3.142C2.93103 3.35298 2.8125 3.63913 2.8125 3.9375V15.75C2.8125 16.0484 2.93103 16.3345 3.142 16.5455C3.35298 16.7565 3.63913 16.875 3.9375 16.875H14.0625C14.3609 16.875 14.647 16.7565 14.858 16.5455C15.069 16.3345 15.1875 16.0484 15.1875 15.75V3.9375C15.1875 3.63913 15.069 3.35298 14.858 3.142C14.647 2.93103 14.3609 2.8125 14.0625 2.8125V2.8125ZM6.75 2.25H11.25V4.5H6.75V2.25ZM14.0625 15.75H3.9375V3.9375H5.625V5.625H12.375V3.9375H14.0625V15.75Z" fill="#055075"/>
            </svg>
            <p className="flex-item gym-calendar-txt">2022</p>
          </div>
        </div>
        <div className="notification-button cursor-pointer d-flex-auto m-t-4">
          <svg className="flex-item" width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D"/>
          </svg>
        </div>
      </div>
      <div className="member-menu-saperator"/>
      {/* <div className="member-menu-body d-flex w-full">
        <div className="w-full flex-item" style={{justifyContent:"center", textAlign:"center"}}>
          <img className="flex-item" style={{ width: "40%", margin: "0 auto" }} src={NoDataFound} />
          <p className='fs-20 m-t-20' style={{lineHeight:"24px", color:"#055075"}}>No Appointments</p>
          <div className="m-b-40 w-full"/>
        </div>
      </div> */}
      {true && (
        <div className="appointment-scroll-container">
          <div className="d-flex-wrap w-full space-between">
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available On 21'st June</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available Tomorrow</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available Tomorrow</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available Tomorrow</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available Tomorrow</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available Tomorrow</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available Tomorrow</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
            <div className="m-b-24 appointment-users">
              <div className="programmer-profile" style={{margin: "0 auto"}}>
                <img src={sampleProfileImage} />
                <div className="programmer-likes d-flex">
                  <p className="flex-item m-r-4">74%</p>
                  <svg className="flex-item" width="11" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <p className="programmer-name m-t-22 m-b-4">Jonathan Akbar</p>
              <p className="user-type">Programmer</p>
              <p className="slot-available-text m-t-20 m-b-8">Available Tomorrow</p>
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="choose-slot-button m-b-8">
                <p>Choose Your Slot</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {
        modalType == "appointment-view-container" && (
          <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="desktop-bottom-modal-container d-flex">
            <div onClick={e => e.stopPropagation()} className="appointment-slot-book-container flex-item">
              <div className="d-flex w-full space-between">
                <div className="d-flex member-appointment-slot-selection-container">
                  <img src={sampleProfileImage} className="flex-item m-r-20" />
                  <div className="flex-item">
                    <p className="appointment-slot-name m-b-2">Jonathan Akbar</p>
                    <p className="appointment-slot-container">Programmer</p>
                  </div>
                </div>
                <div className="flex-item d-flex m-r-12">
                  <div className="d-flex switch-slot-date-btn m-r-30">
                    <svg className="flex-item" width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 1L2 9.67347L12 18" stroke="#055075" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                  <div className="d-flex switch-slot-date-btn">
                    <svg className="flex-item" width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 18L10 9.32653L1 0.999999" stroke="#055075" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="appointment-line-saperator" />
              <div className="d-flex w-full space-between">
                <div className="flex-item appointment-slot-date-container">
                  <p>Today</p>
                  <p>No Slots Available</p>
                </div>
                <div className="flex-item appointment-slot-date-container">
                  <p>Tomorrow</p>
                  <p style={{color: "#0088da"}}>12 Slots Available</p>
                </div>
                <div className="flex-item appointment-slot-date-container">
                  <p>Tuesday, 4th Oct</p>
                  <p style={{color: "#0088da"}}>16 Slots Available</p>
                </div>
              </div>
              <div className="appointment-date-line-tracker d-flex space-between">
                <div className="appointment-line"/>
                <div className="appointment-line appointment-line-active"/>
                <div className="appointment-line"/>
              </div>
              <div className="d-flex-wrap space-between w-full" style={{justifyContent: "center"}}>
                <div onClick={handleAppointmentModal.bind(this, 'appointment-selected-date-container')} className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
                <div className="slot-time-chips d-flex">
                  <p className="flex-item">10:00 AM</p>
                </div>
              </div>
              <div className='modal-action-footer'>
                <div className='d-flex' style={{float: "right"}}>
                  {/* <p className='flex-item m-r-20 cursor-pointer' style={{color: "#9a9a9a"}}>Cancel</p> */}
                  <div className='global-button-controller global-purple-button-controller-active d-flex m-r-4'>
                    <p className='flex-item'>Cancel</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        modalType == "appointment-selected-date-container" && (
          <div onClick={handleAppointmentModal.bind(this, 'appointment-selected-date-container')} className="desktop-bottom-modal-container d-flex">
            <div onClick={e => e.stopPropagation()} className="appointment-slot-book-container flex-item">
              <div className="d-flex w-full space-between">
                <div className="flex-item">
                  <div className="d-flex cursor-pointer" onClick={handleAppointmentModal.bind(this, 'appointment-view-container')}>
                    <p className="flex-item m-r-12 book-appointment-txt">Book Appointment</p>
                    <svg className="flex-item" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 5C16.97 5 21 7.69 21 11C21 12.68 19.96 14.2 18.29 15.29C19.36 14.42 20 13.32 20 12.13C20 9.29 16.42 7 12 7V10L8 6L12 2V5ZM12 19C7.03 19 3 16.31 3 13C3 11.32 4.04 9.8 5.71 8.71C4.64 9.58 4 10.68 4 11.88C4 14.71 7.58 17 12 17V14L16 18L12 22V19Z" fill="black"/>
                    </svg>
                  </div>
                  <div className="flex-item d-flex">
                    <svg className="flex-item m-r-8" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1383_2055)">
                      <path d="M8 3.5C8 3.36739 7.94732 3.24021 7.85355 3.14645C7.75979 3.05268 7.63261 3 7.5 3C7.36739 3 7.24021 3.05268 7.14645 3.14645C7.05268 3.24021 7 3.36739 7 3.5V9C7.00003 9.08813 7.02335 9.17469 7.06761 9.25091C7.11186 9.32712 7.17547 9.39029 7.252 9.434L10.752 11.434C10.8669 11.4961 11.0014 11.5108 11.127 11.4749C11.2525 11.4391 11.3591 11.3556 11.4238 11.2422C11.4886 11.1288 11.5065 10.9946 11.4736 10.8683C11.4408 10.7419 11.3598 10.6334 11.248 10.566L8 8.71V3.5Z" fill="#0088DA"/>
                      <path d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" fill="#0088DA"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1383_2055">
                      <rect width="16" height="16" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <p className="flex-item selected-slot-time">10:00 AM</p>
                  </div>
                </div>
                <div onClick={handleAppointmentModal.bind(this, 'appointment-selected-date-container')} className="desktop-modal-close-button flex-item d-flex">
                  <svg className="flex-item" width="30" height="30" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="14.5107" y="4.46472" width="1.57856" height="14.2071" rx="0.789282" transform="rotate(45 14.5107 4.46472)" fill="#2B2B2B"/>
                    <rect x="4.46484" y="5.58093" width="1.57856" height="14.2071" rx="0.789282" transform="rotate(-45 4.46484 5.58093)" fill="#2B2B2B"/>
                  </svg>
                </div>
              </div>
              <div className="d-flex w-full space-between m-t-24">
                <div>
                  <p className="your-guide-txt">Your Guide</p>
                  <div className="d-flex member-appointment-slot-selection-container" style={{transform: "scale(0.6)", margin: "0 0 0 -50px"}}>
                    <img src={sampleProfileImage} className="flex-item m-r-20" />
                    <div className="flex-item">
                      <p className="appointment-slot-name m-b-2">Jonathan Akbar</p>
                      <p className="appointment-slot-container">Programmer</p>
                    </div>
                  </div>
                </div>
                <div onClick={toggleInputFields.bind(this, 'appointment-reason-input-field')} className={`${isAppointmentInputActive == true || appointmentReason.length > 0 ? "member-global-input-field flex-item m-b-16" : "member-global-input-field flex-item d-flex m-b-16"}`} style={{border : isAppointmentInputActive == true ? "1px solid #055075" : "", width: "70%", height: "80px"}}> 
                  <div className='d-flex w-full space-between'>
                    <div className='flex-item'>
                      <div className="flex-item d-flex">
                        {/* <svg className='flex-item m-r-8' width={isAppointmentInputActive == true || appointmentReason.length > 0 ? "10" : "16"} height={isAppointmentInputActive == true || appointmentReason.length > 0 ? "10" : "16"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 0C5.10273 0 4.36364 0.739091 4.36364 1.63636C4.36364 1.82836 4.404 2.00945 4.46618 2.18182H1.73891L1.65327 2.62527L0.0169092 10.8071L0 10.8578V12H12V10.8578L11.9831 10.8071L10.3467 2.62527L10.2611 2.18182H7.53382C7.59934 2.00731 7.63403 1.82275 7.63636 1.63636C7.63636 0.739091 6.89727 0 6 0ZM6 1.09091C6.30709 1.09091 6.54545 1.32982 6.54545 1.63636C6.54545 1.94345 6.30655 2.18182 6 2.18182C5.69291 2.18182 5.45455 1.94291 5.45455 1.63636C5.45455 1.32927 5.69345 1.09091 6 1.09091ZM2.62473 3.27273H9.37527L10.9091 10.9091H1.09091L2.62473 3.27273Z" fill={isAppointmentInputActive == true || appointmentReason.length > 0 ? "#055075" : "#9a9a9a"}/>
                        </svg> */}
                        <svg className='flex-item m-r-8' width={isAppointmentInputActive == true || appointmentReason.length > 0 ? "10" : "16"} height={isAppointmentInputActive == true || appointmentReason.length > 0 ? "10" : "16"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1384_2074)">
                          <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill={isAppointmentInputActive == true || appointmentReason.length > 0 ? "#055075" : "#9a9a9a"}/>
                          </g>
                          <defs>
                          <clipPath id="clip0_1384_2074">
                          <rect width="12" height="12" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                        <p className="placeholder-txt flex-item" style={{fontSize : isAppointmentInputActive == true || appointmentReason.length > 0 ? "10px" : "", color : isAppointmentInputActive == true || appointmentReason.length > 0 ? "#055075" : "", transition: "180ms"}}>Appointment Reason</p>
                      </div>
                      <textarea id="appointment-reason-input-field" style={{width:"96%", resize: "none", height: "60%"}} value={appointmentReason} type="number" className={`${isAppointmentInputActive == true || appointmentReason.length > 0 ? "active-input-field" : "inactive-input-field"}`} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-action-footer'>
                <div className='d-flex' style={{float: "right"}}>
                  <p className='flex-item m-r-20 cursor-pointer' style={{color: "#9a9a9a"}}>Cancel</p>
                  <div className='global-button-controller global-purple-button-controller-active d-flex m-r-4'>
                    <p className='flex-item'>Book</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
} 