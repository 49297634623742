import React, { useEffect, useState } from 'react';
import Dashboard from '../../core/Dashboard';
import { getMember } from '../helper/api';
import { isAuthenticated } from '../../auth';
import { useAsyncState } from '../../lib/utils';
import { Link } from 'react-router-dom';

const FitnessMeasurement = (props) => {

  const { user, token } = isAuthenticated();
  const [selectedMember, setSelectedMember] = useAsyncState('');
  const [isFitnessViewerOpen, setFitnessViewerOpen] = useAsyncState(false);


  const getMemberDetails = async () => {
    try{
      let response = await getMember(user._id, token, props.match.params.memberID);
      setSelectedMember(response);
    }catch{
      setSelectedMember('');
    }
  }

  const toggleFitnessViewerModal = () => {
    if(isFitnessViewerOpen){
      setFitnessViewerOpen(false)
    }else{
      setFitnessViewerOpen(true)
    }
  }

  useEffect(() => {
    getMemberDetails();
  },[])

  return(
    <Dashboard member={selectedMember} navItemData={"Member"} data={{workoutHistory : "fitness measurement"}} flag={3}>
      <div className="header-bar d-flex space-between">
        <div className='d-flex'>
          <svg className='flex-item m-r-12 m-t-4' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1435 18.957C16.6535 19.158 17.2735 19.509 17.6165 19.511C17.7726 19.5318 17.9314 19.5143 18.0792 19.4599C18.227 19.4055 18.3593 19.316 18.4646 19.199C18.57 19.082 18.6453 18.9411 18.684 18.7884C18.7226 18.6358 18.7235 18.476 18.6865 18.323C18.3795 17.458 16.9295 17.11 15.7995 17.383C14.1705 17.778 13.5425 19.123 14.1615 20.182C14.9735 21.574 17.4105 22.098 19.3265 21.513C21.7105 20.786 22.5925 18.751 21.5265 17.156C20.2465 15.243 16.8165 14.544 14.1375 15.438C11.0075 16.483 9.87254 19.205 11.3825 21.324C13.1145 23.752 17.5325 24.626 20.9595 23.425C24.8295 22.07 26.2145 18.665 24.2635 16.032C22.0885 13.093 16.6925 12.046 12.5255 13.55C7.92354 15.211 6.29054 19.291 8.67554 22.436C11.2885 25.88 17.6555 27.098 22.5555 25.294C27.8825 23.331 29.7625 18.58 26.9455 14.93C23.8985 10.984 16.5675 9.59398 10.9425 11.698C4.89254 13.96 2.76754 19.378 6.01454 23.529C8.07954 26.17 12.0085 27.942 16.3105 28.237" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.3109 28.237H43.5009V37.28H16.2409C9.62286 37.28 4.50586 32.87 4.50586 27.107V19.195M9.59186 35.358V33.098M12.9829 36.715V32.193M16.3739 37.28V35.02M19.7649 37.28V32.758M23.1549 37.28V35.02M26.5469 37.28V32.758M29.9369 37.28V35.02M36.7189 37.28V35.02M33.3289 37.28V32.758M40.1099 37.28V32.758M28.2419 28.124V18.291" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div className='flex-item'>
            <p className="dashboard-type-heading flex-item">Fitness Measurement</p>
            <div className='d-flex m-t-4'>
              <Link to={`/members`} style={{textDecoration:"none"}}>
                <p className='dashboard-route-txt cursor-pointer m-r-6'>Members</p>
              </Link>
              <svg className='m-r-6 flex-item' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 1L6 6.5L0.5 11.5" stroke="#6D6D6D" stroke-linecap="round"/>
              </svg>
              <p className='dashboard-route-txt-active cursor-pointer flex-item m-r-6'>{selectedMember && selectedMember.userInfo && selectedMember.userInfo.name}</p>
            </div>
          </div>
        </div>
        <div className='d-flex'>
          <div className='global-round-btn d-flex m-r-12'>
            <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
            </svg>
          </div>
          <div className='global-round-btn d-flex'>
            <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
            </svg>
          </div>
        </div>
      </div>
      <div className='dashboard-data-saperator'></div>  
      <div className='member-history-scroll-container'>
        <div className='m-b-14 d-flex'>
          <p className='fs-20 m-r-8' style={{color: "#055075", fontWeight: "600"}}>January</p>
          <p className='fs-10 m-t-4' style={{color: "#6d6d6d"}}>2022</p>
        </div>
        <div className='d-flex-wrap m-b-20'>
          <div onClick={toggleFitnessViewerModal} className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>10</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>23</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>4</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>30</p>
          </div>
        </div>
        <div className='m-b-14 d-flex'>
          <p className='fs-20 m-r-8' style={{color: "#055075", fontWeight: "600"}}>February</p>
          <p className='fs-10 m-t-4' style={{color: "#6d6d6d"}}>2022</p>
        </div>
        <div className='d-flex-wrap m-b-20'>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>5</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>19</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>20</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>30</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>5</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>19</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>20</p>
          </div>
          <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20 m-b-12' style={{ background: "#FFFFFF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}>
            <p className='flex-item'>30</p>
          </div>
        </div>
      </div>

      {
        isFitnessViewerOpen && (
          <div className="global-modal-container d-flex">
            <div className="gym-modal-add flex-item" style={{width: "60%", height: "auto"}}>
              <div className="data-input-pannel w-full">
                <div className="d-flex w-full space-between global-modal-header-container" style={{borderRadius: "8px 8px 0 0", marginTop: "-20px"}}>
                  <p className="flex-item modal-header-text">21 Jan 1998</p>
                  <div onClick={toggleFitnessViewerModal} className="d-flex desktop-modal-close-button">
                    <svg className="flex-item" width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.9283 3.80866C4.77899 3.66445 4.57901 3.58465 4.37144 3.58646C4.16387 3.58826 3.96531 3.67152 3.81853 3.8183C3.67175 3.96508 3.58849 4.16364 3.58668 4.37121C3.58488 4.57879 3.66468 4.77876 3.80888 4.92807L8.38076 9.49995L3.80809 14.0718C3.73248 14.1449 3.67217 14.2322 3.63068 14.3288C3.58919 14.4254 3.56735 14.5293 3.56644 14.6344C3.56552 14.7395 3.58555 14.8437 3.62536 14.941C3.66516 15.0383 3.72395 15.1267 3.79828 15.2011C3.87261 15.2754 3.961 15.3342 4.0583 15.374C4.15559 15.4138 4.25983 15.4338 4.36495 15.4329C4.47007 15.432 4.57395 15.4101 4.67054 15.3687C4.76712 15.3272 4.85448 15.2669 4.92751 15.1912L9.50018 10.6194L14.0721 15.1912C14.2214 15.3354 14.4213 15.4152 14.6289 15.4134C14.8365 15.4116 15.035 15.3284 15.1818 15.1816C15.3286 15.0348 15.4119 14.8363 15.4137 14.6287C15.4155 14.4211 15.3357 14.2211 15.1915 14.0718L10.6196 9.49995L15.1915 4.92807C15.3357 4.77876 15.4155 4.57879 15.4137 4.37121C15.4119 4.16364 15.3286 3.96508 15.1818 3.8183C15.035 3.67152 14.8365 3.58826 14.6289 3.58646C14.4213 3.58465 14.2214 3.66445 14.0721 3.80866L9.50018 8.38053L4.9283 3.80786V3.80866Z" fill="black" />
                    </svg>
                  </div>
                </div>
                <div className="global-modal-scroll-container p-t-20" style={{ paddingBottom: 20 }}>
                  <div className="med-body-table-header">
                    <div className="med-body-table-body med-body-txt-header p-l-0" style={{textAlign:"left"}}>Parameters</div>
                    <div className="med-body-table-body med-body-txt-header">Results</div>
                    <div className="med-body-table-body med-body-txt-header">Unit</div>
                    <div className="med-body-table-body med-body-txt-header">Goal</div>
                    <div className="med-body-table-body med-body-txt-header">Remark</div>
                  </div>
                  <div className="med-body-table-header">
                    <div className="med-body-table-body med-body-txt p-l-0" style={{textAlign:"left"}}>Chest</div>
                    <div className="med-body-table-body med-body-txt">12</div>
                    <div className="med-body-table-body med-body-txt">cm</div>
                    <div className="med-body-table-body med-body-txt">I have no goal</div>
                    <div className="med-body-table-body med-body-txt-header"></div>
                  </div>
                  <div className="med-body-table-header">
                    <div className="med-body-table-body med-body-txt p-l-0" style={{textAlign:"left"}}>Thigh Circumference</div>
                    <div className="med-body-table-body med-body-txt">cm</div>
                    <div className="med-body-table-body med-body-txt">32</div>
                    <div className="med-body-table-body med-body-txt">I have no goal</div>
                    <div className="med-body-table-body med-body-txt-header"></div>
                  </div> 
                  <div className="med-body-table-header">
                    <div className="med-body-table-body med-body-txt p-l-0" style={{textAlign:"left"}}>Mid Arm Circumference</div>
                    <div className="med-body-table-body med-body-txt">cm</div>
                    <div className="med-body-table-body med-body-txt">20</div>
                    <div className="med-body-table-body med-body-txt">I have no goal</div>
                    <div className="med-body-table-body med-body-txt-header"></div>
                  </div>    
                  <div className="med-body-table-header">
                    <div className="med-body-table-body med-body-txt p-l-0" style={{textAlign:"left"}}>Waist</div>
                    <div className="med-body-table-body med-body-txt">cm</div>
                    <div className="med-body-table-body med-body-txt">67</div>
                    <div className="med-body-table-body med-body-txt">I have no goal</div>
                    <div className="med-body-table-body med-body-txt-header"></div>
                  </div> 
                  <div className="med-body-table-header">
                    <div className="med-body-table-body med-body-txt p-l-0" style={{textAlign:"left"}}>Hip</div>
                    <div className="med-body-table-body med-body-txt">cm</div>
                    <div className="med-body-table-body med-body-txt">100</div>
                    <div className="med-body-table-body med-body-txt">I have no goal</div>
                    <div className="med-body-table-body med-body-txt-header"></div>
                  </div> 
                  <div className="med-body-table-header">
                    <div className="med-body-table-body med-body-txt p-l-0" style={{textAlign:"left"}}>Calf</div>
                    <div className="med-body-table-body med-body-txt">cm</div>
                    <div className="med-body-table-body med-body-txt">28</div>
                    <div className="med-body-table-body med-body-txt">I have no goal</div>
                    <div className="med-body-table-body med-body-txt-header"></div>
                  </div>  
                </div>
              </div>
              
            </div>
          </div>
        )
      }

    </Dashboard>
  )
}

export default FitnessMeasurement;
