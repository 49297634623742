module.exports={
  //API_URL:"http://192.168.1.11:5000",
  // API_URL:"http://localhost:5000",
  // API_URL:"https://test.workoutplus.in",
  API_URL:"https://workoutplus.gymshim.com",
  prefixAPI:"/api",
  prefixStream:"/stream",
  unAuthParam:"secret=instafit-app-secret134&token=jhsasjalsjlksjakjsn121n",
  wpAuthToken:"$2b$10$9giflEYsfxzoxug98PEJTeohqCZWtZp.siSq3WTNOhZ1o6duy1cvK",
  firebaseConfig:{
    apiKey: "AIzaSyDP5qgFjK8gXLMUDgSb_u9yV3-1VJOiAms",
    authDomain: "workoutplus-7278b.firebaseapp.com",
    projectId: "workoutplus-7278b",
    storageBucket: "workoutplus-7278b.appspot.com",
    messagingSenderId: "302278372183",
    appId: "1:302278372183:web:5102abd41923133bb0ec12",
    measurementId: "G-GY36RBG0Z0"
  },
  weekDays:['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
  monthNames:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
  weekDayDisplayNames:{
    'Sun':'Sunday',
    'Mon':'Monday',
    'Tue':'Tuesday',
    'Wed':'Wednesday',
    'Thu':'Thursday',
    'Fri':'Friday',
    'Sat':'Saturday'
  }
}