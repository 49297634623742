import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UserLogo from '../../assets/sample-profile.png';
import { SignOut, isAuthenticated } from '../../auth/index';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector,useDispatch } from "react-redux";
import { openMenu, closeMenu } from '../../lib/storeFeature/actionMenu';
import { setUserInfo } from "../../lib/storeFeature/userInfo";
import {getUserRole} from '../../lib/utils';
import config from "../../config";
import ManageProfile from "../Modal/mobile/ManageProfile";


const Menu = ({ toggleMenuVisibility }) => {

  const history = useHistory();

  
  // const userInfo=useSelector(state=>state.user.email);
  const userInfo = useSelector(state => state.user.value);
  const [isEditSideProfile, handleEditSideProfile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSideProfileOpen, setSideProfileOpen] = useState(false);
  const [isProfileViewOpen, setProfileViewOpen] = useState(true)

  const dispatch=useDispatch()
  const {user,token}=isAuthenticated();


  const handleSignOut = () => {
    dispatch(closeMenu())
    SignOut(() => {
      history.push("/signin");
    });
  }

  const handleRedirectProfile=()=>{
    dispatch(closeMenu())
    history.push("/member/profile");
  }

  const toggleManageProfile = (type) => {
    if(type == "profile-update"){
      setProfileViewOpen(false);
    } 
    handleEditSideProfile(!isEditSideProfile);
  }

  const handleCloseProfile = () => {
    let container = document.getElementById("mobile-bottom-modal-container-menu");
    let menu = document.getElementById('menu-bar-mobile-id');
    container.style.background = "rgba(0, 0, 0, 0.10)";
    menu.style.marginRight = "-70%";
  }

  useEffect(() => {
    // let container = document.getElementById("mobile-bottom-modal-container-menu");
    // let menu = document.getElementById('menu-bar-mobile-id');
    // container.style.background = "rgba(0, 0, 0, 0.10)";
    // menu.style.marginRight = "-100%";
  },[])


  return (
    <div onClick={toggleMenuVisibility} className="W-full h-full">
      {
        isEditSideProfile && (
          <div onClick={e=>e.stopPropagation()} className="w-full h-full">
            <ManageProfile toggleManageProfile={toggleManageProfile}/>
          </div>
        )
      }

      {
        isProfileViewOpen && (
          <div id="mobile-bottom-modal-container-menu" onClick={()=>handleCloseProfile()} className="mobile-bottom-modal-container">
            <div id="menu-bar-mobile-id" onClick={e => e.stopPropagation()} className="menu-bar-mobile right-slider">
              <div className="w-full h-full">
                <div onClick={handleRedirectProfile} className="user-side-profile-container w-full">
                  <div className="d-flex w-full space-between" onClick={e => e.stopPropagation()}>
                        <div className="d-flex">
                        {
                          !user.profileID ? (
                            <div className="flex-item side-profile-image m-r-12">
                              <svg width="100%" height="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M44 24C44 35.046 35.046 44 24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24ZM30 18C30 19.5913 29.3679 21.1174 28.2426 22.2426C27.1174 23.3679 25.5913 24 24 24C22.4087 24 20.8826 23.3679 19.7574 22.2426C18.6321 21.1174 18 19.5913 18 18C18 16.4087 18.6321 14.8826 19.7574 13.7574C20.8826 12.6321 22.4087 12 24 12C25.5913 12 27.1174 12.6321 28.2426 13.7574C29.3679 14.8826 30 16.4087 30 18ZM24 41C27.4326 41.0055 30.7856 39.9669 33.614 38.022C34.822 37.192 35.338 35.612 34.634 34.326C33.18 31.66 30.18 30 24 30C17.82 30 14.82 31.66 13.364 34.326C12.662 35.612 13.178 37.192 14.386 38.022C17.2144 39.9669 20.5674 41.0055 24 41Z" fill="#9A9A9A" />
                              </svg>
                            </div>
                          ) : (
                            <img className="flex-item side-profile-image m-r-12" src={`${config.API_URL}${config.prefixStream}/media/file/${user.profileID}?${Date.now()}`} />
                          )
                        }
                        <div className="flex-item">
                          <p className="side-profile-name">{user.name}</p>
                          <p className="side-profile-email">{getUserRole(user.role)}</p>
                        </div>
                      </div>
                  
                    <svg onClick={()=>toggleManageProfile("profile-update")} className="flex-item" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8204 22H10.1804C9.9523 22 9.73107 21.9221 9.55337 21.7792C9.37567 21.6362 9.25218 21.4368 9.20337 21.214L8.79637 19.33C8.25343 19.0921 7.73861 18.7946 7.26137 18.443L5.42437 19.028C5.20694 19.0973 4.97232 19.0902 4.75949 19.0078C4.54666 18.9254 4.36842 18.7727 4.25437 18.575L2.43037 15.424C2.31752 15.2261 2.27516 14.9958 2.31021 14.7708C2.34527 14.5457 2.45568 14.3392 2.62337 14.185L4.04837 12.885C3.98357 12.2961 3.98357 11.7019 4.04837 11.113L2.62337 9.816C2.45544 9.66177 2.34489 9.45507 2.30982 9.22978C2.27476 9.00449 2.31726 8.77397 2.43037 8.576L4.25037 5.423C4.36442 5.22532 4.54266 5.07259 4.75549 4.99019C4.96832 4.90778 5.20294 4.90066 5.42037 4.97L7.25737 5.555C7.50137 5.375 7.75537 5.207 8.01737 5.055C8.27037 4.913 8.53037 4.784 8.79637 4.669L9.20437 2.787C9.25294 2.5642 9.3762 2.36469 9.55372 2.22155C9.73123 2.07841 9.95234 2.00024 10.1804 2H13.8204C14.0484 2.00024 14.2695 2.07841 14.447 2.22155C14.6245 2.36469 14.7478 2.5642 14.7964 2.787L15.2084 4.67C15.7506 4.90927 16.2653 5.20668 16.7434 5.557L18.5814 4.972C18.7987 4.90292 19.0331 4.91017 19.2457 4.99256C19.4583 5.07495 19.6364 5.22753 19.7504 5.425L21.5704 8.578C21.8024 8.985 21.7224 9.5 21.3774 9.817L19.9524 11.117C20.0172 11.7059 20.0172 12.3001 19.9524 12.889L21.3774 14.189C21.7224 14.507 21.8024 15.021 21.5704 15.428L19.7504 18.581C19.6363 18.7787 19.4581 18.9314 19.2453 19.0138C19.0324 19.0962 18.7978 19.1033 18.5804 19.034L16.7434 18.449C16.2665 18.8003 15.752 19.0975 15.2094 19.335L14.7964 21.214C14.7476 21.4366 14.6243 21.6359 14.4468 21.7788C14.2693 21.9218 14.0483 21.9998 13.8204 22V22ZM11.9964 8C10.9355 8 9.91809 8.42143 9.16795 9.17157C8.4178 9.92172 7.99637 10.9391 7.99637 12C7.99637 13.0609 8.4178 14.0783 9.16795 14.8284C9.91809 15.5786 10.9355 16 11.9964 16C13.0572 16 14.0747 15.5786 14.8248 14.8284C15.5749 14.0783 15.9964 13.0609 15.9964 12C15.9964 10.9391 15.5749 9.92172 14.8248 9.17157C14.0747 8.42143 13.0572 8 11.9964 8V8Z" fill="#2b2b2b" />
                    </svg>
                  </div>
                  <div className="m-t-12 m-b-12 side-profile-saperator" />
                  {
                      user.role !== 0 && (
                          <div className='m-t-20 w-full m-b-40' style={{height:"auto", borderRadius:"16px", background:"#f5f5f5", padding:"12px"}}>
                              <div className='d-flex flex-item margin-auto'>
                                  <div className='d-flex margin-auto'>
                                      <svg className='flex-item m-r-8' width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7.92018 19.3039L4.45535 22.7688C4.25844 22.9657 4.14781 23.2328 4.14781 23.5112C4.14781 23.7897 4.25844 24.0568 4.45535 24.2537L15.3448 35.1431C15.4423 35.2406 15.558 35.318 15.6854 35.3707C15.8128 35.4235 15.9494 35.4507 16.0873 35.4507C16.2251 35.4507 16.3617 35.4235 16.4891 35.3707C16.6165 35.318 16.7322 35.2406 16.8297 35.1431L20.2945 31.6783C20.392 31.5808 20.4694 31.4651 20.5222 31.3377C20.5749 31.2103 20.6021 31.0737 20.6021 30.9359C20.6021 30.798 20.5749 30.6614 20.5222 30.534C20.4694 30.4066 20.392 30.2909 20.2945 30.1934L9.4051 19.3039C9.20819 19.107 8.94112 18.9964 8.66264 18.9964C8.38416 18.9964 8.11709 19.107 7.92018 19.3039V19.3039ZM22.7694 4.4547L19.3046 7.91953C19.1077 8.11644 18.9971 8.38351 18.9971 8.66199C18.9971 8.94047 19.1077 9.20754 19.3046 9.40445L30.194 20.2939C30.2915 20.3914 30.4073 20.4687 30.5347 20.5215C30.6621 20.5743 30.7986 20.6014 30.9365 20.6014C31.0744 20.6014 31.2109 20.5743 31.3383 20.5215C31.4657 20.4687 31.5815 20.3914 31.679 20.2939L35.1438 16.8291C35.2413 16.7316 35.3186 16.6158 35.3714 16.4884C35.4242 16.361 35.4513 16.2245 35.4513 16.0866C35.4513 15.9487 35.4242 15.8122 35.3714 15.6848C35.3186 15.5574 35.2413 15.4416 35.1438 15.3441L24.2543 4.4547C24.0574 4.25779 23.7904 4.14716 23.5119 4.14716C23.2334 4.14716 22.9663 4.25779 22.7694 4.4547V4.4547Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M14.849 24.749L24.7485 14.8495M9.15677 36.3809L3.21707 30.4412C3.02016 30.2443 2.90953 29.9772 2.90953 29.6987C2.90953 29.4203 3.02016 29.1532 3.21707 28.9563L5.44446 26.7289C5.64137 26.532 5.90844 26.4213 6.18692 26.4213C6.4654 26.4213 6.73247 26.532 6.92938 26.7289L12.8691 32.6686C13.066 32.8655 13.1766 33.1326 13.1766 33.411C13.1766 33.6895 13.066 33.9566 12.8691 34.1535L10.6417 36.3809C10.4448 36.5778 10.1777 36.6884 9.89923 36.6884C9.62075 36.6884 9.35368 36.5778 9.15677 36.3809V36.3809ZM36.3804 9.15728L30.4407 3.21758C30.2438 3.02067 29.9767 2.91004 29.6982 2.91004C29.4197 2.91004 29.1527 3.02067 28.9558 3.21758L26.7284 5.44497C26.5315 5.64188 26.4208 5.90895 26.4208 6.18743C26.4208 6.46591 26.5315 6.73298 26.7284 6.92989L32.6681 12.8696C32.865 13.0665 33.1321 13.1771 33.4105 13.1771C33.689 13.1771 33.9561 13.0665 34.153 12.8696L36.3804 10.6422C36.5773 10.4453 36.6879 10.1782 36.6879 9.89974C36.6879 9.62126 36.5773 9.35419 36.3804 9.15728V9.15728Z" stroke="#055075" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                      <p className='flex-item font-weight-600' style={{fontSize:"18px", color:"#055075"}}>{userInfo && userInfo.gymList && userInfo.gymList.length > 0 && userInfo.gymList[0].name && userInfo.gymList[0].name}</p>
                                  </div>
                              </div>
                          </div>
                      )
                  }
                </div>
                <Link to={`/terms-condition`} target="_blank" className="d-flex m-t-16 m-l-8" style={{ textDecoration: "none" }}>
                  <svg className="flex-item" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 12V5.749C19.0001 5.67006 18.9845 5.59189 18.9543 5.51896C18.9241 5.44603 18.8798 5.37978 18.824 5.324L15.676 2.176C15.5636 2.06345 15.4111 2.00014 15.252 2H3.6C3.44087 2 3.28826 2.06321 3.17574 2.17574C3.06321 2.28826 3 2.44087 3 2.6V21.4C3 21.5591 3.06321 21.7117 3.17574 21.8243C3.28826 21.9368 3.44087 22 3.6 22H12M7 10H15M7 6H11M7 14H10" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 2V5.4C15 5.55913 15.0632 5.71174 15.1757 5.82426C15.2883 5.93679 15.4409 6 15.6 6H19M18.992 15.125L21.548 15.774C21.814 15.842 22.001 16.084 21.993 16.358C21.821 22.116 18.5 23 18.5 23C18.5 23 15.179 22.116 15.007 16.358C15.0043 16.2247 15.0468 16.0944 15.1276 15.9883C15.2084 15.8823 15.3227 15.8067 15.452 15.774L18.008 15.125C18.331 15.043 18.669 15.043 18.992 15.125Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p className="menu-type-txt flex-item m-l-20">Terms & Conditions</p>
                </Link>
                <Link to={`/privacy-policy`} target="_blank" className="d-flex m-t-16 m-l-8" style={{ textDecoration: "none" }}>
                  <svg className="flex-item" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0997 2.43L3.34473 5.625V13.5C3.34473 17.72 11.9997 21.75 11.9997 21.75C11.9997 21.75 20.6547 17.72 20.6547 13.5V5.625L12.8997 2.43C12.6145 2.31118 12.3087 2.25 11.9997 2.25C11.6908 2.25 11.3849 2.31118 11.0997 2.43Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.62988 11.545L11.6199 14.515L16.4299 8.33496" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <p className="menu-type-txt flex-item m-l-20">Privacy Policy</p>
                </Link>
                <div onClick={handleSignOut} className="d-flex m-t-16" style={{ position: "absolute", bottom: "0", left: "0", padding: "16px" }}>
                  <svg className="flex-item" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 12H10.5M18 15L21 12L18 9M13 7V6C13 5.46957 12.7893 4.96086 12.4142 4.58579C12.0391 4.21071 11.5304 4 11 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H11C11.5304 20 12.0391 19.7893 12.4142 19.4142C12.7893 19.0391 13 18.5304 13 18V17" stroke="#6d6d6d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <p className="menu-type-txt flex-item m-l-14 color-red">Log Out</p>
                </div>
              </div>   
            </div>
          </div>
        )
      }
    </div>
  )
}


export default Menu;