
//import reportWebVitals from './reportWebVitals';
//import { stopReportingRuntimeErrors } from "react-error-overlay";
import React from "react";
import Routes from "./Routes";
import ReactDOM from "react-dom";
import './style/main.scss';
import store from './lib/store';
import { Provider } from 'react-redux'


// if (process.env.NODE_ENV === "development") {
//   stopReportingRuntimeErrors(); // disables error overlays
// }

ReactDOM.render(<Provider store={store}><Routes/></Provider>, document.getElementById("root"));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
