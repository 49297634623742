import React, { useState, useEffect } from "react";
import Dashboard from "../../core/Dashboard";
import { getAllParameters } from './helper/api';
import { isAuthenticated } from '../../auth';
import FloatingMenu from "../floatingMenu";
import Menu from '../../components/menu';
import { startLoader, stopLoader } from "../../lib/utils";
import { removeParameter } from "./helper/api";
import ManageConfirmationModal from "../Modal/ConfirmModal";
import ManageParameter from "../Modal/mobile/ManageParameter";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu, openMenu } from "../../lib/storeFeature/actionMenu";
import config from "../../config";



const ManageParamenterMobile = (props) => {


  // User Authentication
  const { user, token } = isAuthenticated();
  const [parameterList, setParameterList] = useState([])
  const [isDeleteConfirmParameterModal, setDeleteConfirmParameterModal] = useState(false);
  const [isAddParameterModalOpen, setAddParameterModalOpen] = useState(false);
  const [flag] = useState(0);
  const [currentActionItem, setCurrentActionItem] = useState('');
  const [ParameterType, setParameterType] = useState('exerciseLevel');
  const [Parameter, setParameter] = useState({
    type: "",
    parameterId: "",
    name: "",
    remark: "",
    photo: "",
    targetMuscleList: [],
    exerciseLevelList: [],
    exerciseTypeList: [],
    exerciseImageList: [],
    formData: new FormData()
  })
  const { type, parameterId, name, remark, photo, targetMuscleList, exerciseImageList, exerciseTypeList, exerciseLevelList, formData } = Parameter;
  const [isParameterFilterOpen, setParameterFilterOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const isMenuAction = useSelector((state) => state.isMenuAction.value);
  const dispatch = useDispatch();
  var [searchKey, setSearchKey] = useState("");



  const handleParameterUpdate = parameter => event => {
    event.preventDefault();
    try {
      setParameter({
        ...Parameter,
        parameterId: parameter._id,
        name: parameter.name,
        type: parameter.type
      })
      toggleAddParameterModal();
    } catch (error) {
      // do something
    }
    event.stopPropagation();
  }

  const toggleAddParameterModal = () => {
    if (isAddParameterModalOpen) {
      setAddParameterModalOpen(false)
    } else {
      setAddParameterModalOpen(true)
    }
  }


  const handleDeleteAction = async event => {
    event.preventDefault();
    try {
      await startLoader()
      await removeParameter(user._id, token, Parameter.parameterId);
      handleDeleteConfirmModal();
      await GetParameters();
      await stopLoader();
    } catch (error) {
      // do something
      await stopLoader()
    }
    event.stopPropagation();
  }

  const handleDeleteConfirmModal = () => {
    if (isDeleteConfirmParameterModal) {
      setDeleteConfirmParameterModal(false);
      setParameter({
        ...Parameter,
        name: "",
        parameterId: ""
      })
    } else {
      setDeleteConfirmParameterModal(true);
    }
  }



  const getParams = () => {
    let params = {
      noLimit: "noLimit"
    };
    if (ParameterType) params.type = ParameterType;
    params.sortByInstafit = "sort";
    return params;
  }

  const GetParameters = async () => {
    try {
      let params = getParams();
      let response = await getAllParameters(user._id, token, params)
      setParameterList(response);
    } catch (error) {
      // do something
    }
  }


  const handleDeleteModal = parameter => event => {
    event.preventDefault();
    setParameter({
      ...Parameter,
      parameterId: parameter._id,
      name: parameter.name
    });
    handleDeleteConfirmModal()
    event.stopPropagation();
  }





  const handleParameterFilter = () => {
    if (isParameterFilterOpen) {
      setParameterFilterOpen(false)
    } else {
      setParameterFilterOpen(true)
    }
  }


  const handleParameterType = (ParameterType) => {
    setParameterType(ParameterType)
  }


  const handleMenuVisibility = () => {
    if (!isMenuOpen) {
      setTimeout(() => {
        setIsMenuOpen(true)
      }, 50);
    } else {
      let container = document.getElementById("mobile-bottom-modal-container-menu");
      let menu = document.getElementById('menu-bar-mobile-id');
      container.style.background = "rgba(0, 0, 0, 0.10)";
      menu.style.marginRight = "-70%";
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 180);
    }
  }


  const toggleCurrentActionItem = (actionItem) => {
    setCurrentActionItem(actionItem)
  }

  const handleSideMenuVisibility = () => {
    if (isMenuAction != "menu-profile") {
      setTimeout(() => { dispatch(openMenu("menu-profile")) }, 50)
    } else {
      setTimeout(() => { dispatch(closeMenu()) }, 180);
    }
  }



  useEffect(async () => {
    try {
      await startLoader();
      await GetParameters();
      await stopLoader()
    } catch (error) {
      // do something
    }
  }, [props, ParameterType])

  useEffect(() => {
    let searchGymInputField = document.getElementById("search-parameter-input");
    let searchContainer = document.getElementById("search-container-parameter");
    let searchIcon = document.getElementById("search-icon-parameter");
    if (searchGymInputField) {
      searchGymInputField.onfocus = e => {
        searchContainer.style.outline = "1px solid #055075";
        searchContainer.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
        searchIcon.classList.add("active-fill-svg");
      }
      searchGymInputField.onblur = e => {
        searchContainer.style.outline = "none";
        searchContainer.style.boxShadow = "none";
        searchIcon.classList.remove("active-fill-svg");
      }
    }



  }, []);

  return (
    <Dashboard data={{ state: "none" }} navItemData={"Gym"} flag={flag}>
      <div className='mob-dashboard'>
        <div className='profile-header-mob d-flex space-between' style={{ boxShadow: "none" }}>
          <div className="d-flex">
            <div className='flex-item'>
              {/* <svg className='flex-item m-r-8' width="26" height="26" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.66743 3.93851L7.66695 3.93901C7.45331 4.16276 7.35 4.44223 7.35 4.75C7.35 5.06323 7.46045 5.34466 7.68316 5.56684C7.79389 5.67784 7.91935 5.76095 8.05612 5.8164L7.33584 6.54516L6.65087 5.86018C6.43473 5.62817 6.15333 5.5125 5.8375 5.5125C5.52166 5.5125 5.24027 5.62817 5.02413 5.86018L2.74768 8.13663C2.51567 8.35277 2.4 8.63417 2.4 8.95C2.4 9.26324 2.51378 9.54261 2.74199 9.75803C2.9574 9.98623 3.23677 10.1 3.55 10.1C3.86544 10.1 4.14653 9.98462 4.36255 9.75319L5.83599 8.29543L6.52966 8.98909C6.7495 9.20894 7.02975 9.3125 7.3375 9.3125C7.64525 9.3125 7.9255 9.20894 8.14534 8.98909L9.21409 7.92034L9.68084 7.45359C9.73713 7.59374 9.8231 7.72163 9.93967 7.83296C10.163 8.0468 10.4424 8.15 10.75 8.15C11.0632 8.15 11.3446 8.03958 11.5667 7.81694C11.7895 7.59474 11.9 7.31328 11.9 7V4.75C11.9 4.43655 11.7894 4.15498 11.5668 3.93241C11.3445 3.71005 11.063 3.6 10.75 3.6H8.48125C8.16812 3.6 7.88601 3.71039 7.66743 3.93851ZM1.75 13.35C1.4445 13.35 1.19277 13.246 0.973269 13.0271C0.754083 12.8075 0.65 12.5557 0.65 12.25V1.75C0.65 1.44442 0.754027 1.19264 0.973093 0.973093C1.19264 0.754027 1.44442 0.65 1.75 0.65H12.25C12.5557 0.65 12.8075 0.754083 13.0271 0.973269C13.246 1.19277 13.35 1.4445 13.35 1.75V12.25C13.35 12.5556 13.246 12.8074 13.0269 13.0269C12.8074 13.246 12.5556 13.35 12.25 13.35H1.75Z" stroke="#055075" stroke-width="0.8" />
              </svg> */}
              <p className='flex-item fs-24' style={{fontWeight:"600", lineHeight:"28px"}}>Parameter</p>
              <p className='fs-12' style={{color: "#6d6d6d"}}>{parameterList.length} Results</p>
              {/* {ParameterType == "exerciseImage" ? "Exercise Image" : ParameterType == "exerciseType" ? "Exercise Type" : ParameterType == "exerciseLevel" ? "Exercise Level" : ParameterType == "exerciseName" ? "Exercise Name" : ParameterType == "targetMuscle" ? "Target Muscle" : ""} */}
            </div>

            {
              !searchKey && (
                <div onClick={toggleCurrentActionItem.bind(this, "Search")} className='bell-menu-container header-menu-active d-flex m-l-14' style={{alignSelf:"flex-start"}}>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
                  </svg>
                </div>
              )
            }

            <div onClick={toggleCurrentActionItem.bind(this, "Filter")} className='bell-menu-container header-menu-active d-flex m-l-12' style={{alignSelf:"flex-start"}}>
              <svg className='flex-item bell' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#000000" />
              </svg>
            </div>
          </div>

          <div className='d-flex' style={{alignSelf: "flex-start"}}>
            <div onClick={handleSideMenuVisibility} className="header-profile-picture d-flex m-l-16 flex-item" style={{ alignSelf: "flex-start" }}>
              {
                !user.profileID ? (
                  <div className="flex-item side-profile-image m-r-12">
                    <svg width="100%" height="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M44 24C44 35.046 35.046 44 24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24ZM30 18C30 19.5913 29.3679 21.1174 28.2426 22.2426C27.1174 23.3679 25.5913 24 24 24C22.4087 24 20.8826 23.3679 19.7574 22.2426C18.6321 21.1174 18 19.5913 18 18C18 16.4087 18.6321 14.8826 19.7574 13.7574C20.8826 12.6321 22.4087 12 24 12C25.5913 12 27.1174 12.6321 28.2426 13.7574C29.3679 14.8826 30 16.4087 30 18ZM24 41C27.4326 41.0055 30.7856 39.9669 33.614 38.022C34.822 37.192 35.338 35.612 34.634 34.326C33.18 31.66 30.18 30 24 30C17.82 30 14.82 31.66 13.364 34.326C12.662 35.612 13.178 37.192 14.386 38.022C17.2144 39.9669 20.5674 41.0055 24 41Z" fill="#9A9A9A" />
                    </svg>
                  </div>
                ) : (
                  <img className="flex-item side-profile-image m-r-12" src={`${config.API_URL}${config.prefixStream}/media/file/${user.profileID}?${Date.now()}`} />
                )
              }
              <div className="menu-indicator d-flex">
                <svg className="flex-item" style={{ margin: "0 auto" }} width="8" height="8" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H17" stroke="black" stroke-width="2" stroke-linecap="round" />
                  <path d="M1 5H17" stroke="black" stroke-width="2" stroke-linecap="round" />
                  <path d="M1 9H17" stroke="black" stroke-width="2" stroke-linecap="round" />
                </svg>
              </div>
            </div>
            {
              isMenuAction == "menu-profile" && (
                // <div id="mobile-bottom-modal-container-menu" onClick={handleSideMenuVisibility} className="mobile-bottom-modal-container">
                  // <div id="menu-bar-mobile-id" onClick={e => e.stopPropagation()} className="menu-bar-mobile right-slider">
                    <Menu toggleMenuVisibility={handleSideMenuVisibility} />
                  // </div>
                // </div>
              )
            }
            {/* <div onClick={toggleCurrentActionItem.bind(this, "Search")} className='bell-menu-container header-menu-active d-flex m-r-14'>
              <svg className='bell flex-item' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
              </svg>
            </div> */}
            {/* <div onClick={toggleCurrentActionItem.bind(this, "Filter")} className='bell-menu-container header-menu-active d-flex flex-item m-l-14'>
              <svg className='flex-item bell' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#000000" />
              </svg>
            </div>
            <div onClick={toggleCurrentActionItem.bind(this, "Search")} className='bell-menu-container header-menu-active d-flex flex-item m-l-14'>
              <svg className='flex-item bell' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07475 10.2746 9.04816V9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43033 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567V5.68567Z" fill="#2b2b2b" />
              </svg>
            </div> */}
            {/* {
              notifications.length > 0 ? (
                <svg className='flex-item m-l-14' width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 1.5C3.8505 1.5 1.5 3.8505 1.5 6.75V13.2578C1.5 16.1573 3.8505 18.5078 6.75 18.5078H13.2578C16.1573 18.5078 18.5078 16.1573 18.5078 13.2578V8.15316C18.5078 7.73891 18.8436 7.40312 19.2578 7.40312C19.672 7.40312 20.0078 7.73891 20.0078 8.15316V13.2578C20.0078 16.9858 16.9857 20.0078 13.2578 20.0078H6.75C3.02208 20.0078 0 16.9858 0 13.2578V6.75C0 3.02207 3.02208 0 6.75 0H11.8547C12.2689 0 12.6047 0.33579 12.6047 0.75C12.6047 1.16421 12.2689 1.5 11.8547 1.5H6.75Z" fill="#2b2b2b"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 13.5001C6.75 13.0858 7.08579 12.7501 7.5 12.7501H12.5C12.9142 12.7501 13.25 13.0858 13.25 13.5001C13.25 13.9143 12.9142 14.2501 12.5 14.2501H7.5C7.08579 14.2501 6.75 13.9143 6.75 13.5001Z" fill="#2b2b2b"/>
                  <circle cx="16.5" cy="3.5" r="3.5" fill="#FF0000"/>
                </svg>
              ) : (
                <svg className='flex-item m-l-14' width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 1.5C3.8505 1.5 1.5 3.8505 1.5 6.75V13.2578C1.5 16.1573 3.8505 18.5078 6.75 18.5078H13.2578C16.1573 18.5078 18.5078 16.1573 18.5078 13.2578V8.15316C18.5078 7.73891 18.8436 7.40312 19.2578 7.40312C19.672 7.40312 20.0078 7.73891 20.0078 8.15316V13.2578C20.0078 16.9858 16.9857 20.0078 13.2578 20.0078H6.75C3.02208 20.0078 0 16.9858 0 13.2578V6.75C0 3.02207 3.02208 0 6.75 0H11.8547C12.2689 0 12.6047 0.33579 12.6047 0.75C12.6047 1.16421 12.2689 1.5 11.8547 1.5H6.75Z" fill="#2b2b2b"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 13.5001C6.75 13.0858 7.08579 12.7501 7.5 12.7501H12.5C12.9142 12.7501 13.25 13.0858 13.25 13.5001C13.25 13.9143 12.9142 14.2501 12.5 14.2501H7.5C7.08579 14.2501 6.75 13.9143 6.75 13.5001Z" fill="#2b2b2b"/>
                  <circle cx="16.5" cy="3.5" r="3.5" fill="#959595"/>
                </svg>
              )
            } */}
            {/* <div className='bell-menu-container header-menu-active d-flex m-r-14'>
              <svg className='bell flex-item' width="16" height="16" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.49099 5.82435C7.78036 1.94382 6.89044 0.469739 4.09577 0.531423C3.10015 0.552775 3.33874 -0.229342 2.57946 0.0672142C1.82169 0.36377 2.47217 0.797927 1.69639 1.46933C-0.480905 3.35384 -0.280582 5.0968 0.729287 9.25095C1.15469 11.001 -0.296338 11.0864 0.277622 12.7819C0.697025 14.0188 3.78815 14.5368 7.04885 13.262C10.3103 11.9864 12.3653 9.45498 11.9459 8.21815C11.3719 6.52185 10.212 7.45817 9.49099 5.82435ZM6.69547 12.2165C3.7829 13.3553 1.38953 12.6863 1.28824 12.3881C1.11418 11.8741 2.22833 10.1612 5.55805 8.85871C8.88777 7.55623 10.7462 8.03942 10.9405 8.61276C11.0553 8.95202 9.60878 11.0769 6.69547 12.2165ZM5.75912 9.4534C4.23682 10.0489 3.17893 10.7298 2.49318 11.3427C2.97561 11.8045 3.87894 11.9168 4.77852 11.5649C5.92419 11.1181 6.62869 10.0892 6.35034 9.26835L6.33834 9.23988C6.15002 9.30473 5.9572 9.37511 5.75912 9.4534Z" fill="#2b2b2b" />
              </svg>
            </div> */}
            {/* <div onClick={handleMenuVisibility} className='bell-menu-container header-menu-active d-flex'>
              <svg className='bell flex-item menu' width="16" height="16" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="15" height="1" rx="0.5" fill="#2b2b2b" />
                <rect y="4" width="7" height="1" rx="0.5" fill="#2b2b2b" />
                <rect y="8" width="12" height="1" rx="0.5" fill="#2b2b2b" />
              </svg>
            </div> */}
          </div>
        </div>
        {/* <div className='w-full d-flex space-between' style={{ height: "30px" }}>
          <p className="flex-item fs-16 m-l-12">{ParameterType == "exerciseImage" ? "Exercise Image" : ParameterType == "exerciseType" ? "Exercise Type" : ParameterType == "exerciseLevel" ? "Exercise Level" : ParameterType == "exerciseName" ? "Exercise Name" : ParameterType == "targetMuscle" ? "Target Muscle" : ""}</p>
          <div onClick={toggleCurrentActionItem.bind(this, "Filter")} className='bell-menu-container d-flex flex-item m-r-12'>
            <svg className='bell flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
            </svg>
          </div>
        </div> */}
        <div className='w-full d-flex space-between' style={{height: "30px"}}>
          <div className='d-flex flex-item' style={{width: "100%"}}>
            <div className='w-full d-flex space-between'>
              <div className='d-flex flex-item filter-horizontal-scroll' style={{width: "82%"}}>
                <div className='d-flex filter-chips'>
                  <p className='flex-item'>{ParameterType == "exerciseImage" ? "Exercise Image" : ParameterType == "exerciseType" ? "Exercise Type" : ParameterType == "exerciseLevel" ? "Exercise Level" : ParameterType == "exerciseName" ? "Exercise Name" : ParameterType == "targetMuscle" ? "Target Muscle" : ""}</p>
                </div>
              </div>
            </div>
          </div> 
        </div>
        <div className="w-full parameters-scroll-container-mob">
            {
              parameterList.map((data, index) => {
                return (
                  <div className={`global-button-container w-full d-flex flex-item p-l-12 p-r-12 m-r-12 m-b-12`} style={{ background: "#ffffff", borderRadius: "8px" }}>
                    <div className='d-flex w-full space-between' style={{ background: "transparent" }}>
                      <div className="flex-item d-flex">
                        {
                          data.type == "exerciseType" ? (
                            <div className='flex-item'>
                              <p className='flex-item'>{data.name}</p>
                              <p className='flex-item' style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>Unit- {data.unit}</p>
                            </div>
                          ) : <p className='flex-item'>{data.name}</p>
                        }
                      </div>
                      <div className="flex-item d-flex">
                        <span id={"edit-exLevel" + data._id} class="material-icons-round edit-icon flex-item m-l-20" role={`button`} onClick={handleParameterUpdate(data)}>edit</span>
                        <span id={"delete-exLevel" + data._id} class="material-icons-round delete-icon flex-item" style={{ marginLeft: "2%" }} role={`button`} onClick={handleDeleteModal(data)}>delete</span>
                      </div>
                    </div>
                  </div>
                )
              })
            }
        </div>
        <FloatingMenu />
      </div>

      {
        currentActionItem == "Search" && (
          <div className='mob-search-container'>
            <div className='mob-search-box'>
              <div id="search-container" className='global-search-box d-flex w-full m-r-12'>
                <svg id="search-icon" className='flex-item' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1637_1318)">
                    <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1637_1318">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input id="search-gym-input" className='flex-item' type="text" placeholder={"Search " + ParameterType} />
                <svg onClick={toggleCurrentActionItem.bind(this)} className='flex-item' style={{ margin: "0 0 0 auto" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.22419 4.81099C6.03559 4.62883 5.78299 4.52803 5.52079 4.53031C5.2586 4.53259 5.00778 4.63776 4.82238 4.82317C4.63697 5.00858 4.5318 5.25939 4.52952 5.52158C4.52724 5.78378 4.62804 6.03638 4.81019 6.22499L10.5852 12L4.80919 17.775C4.71368 17.8672 4.6375 17.9776 4.58509 18.0996C4.53268 18.2216 4.5051 18.3528 4.50394 18.4856C4.50279 18.6184 4.52809 18.75 4.57837 18.8729C4.62865 18.9958 4.70291 19.1075 4.7968 19.2014C4.89069 19.2953 5.00234 19.3695 5.12524 19.4198C5.24814 19.4701 5.37982 19.4954 5.5126 19.4942C5.64537 19.4931 5.77659 19.4655 5.8986 19.4131C6.0206 19.3607 6.13095 19.2845 6.22319 19.189L11.9992 13.414L17.7742 19.189C17.9628 19.3711 18.2154 19.4719 18.4776 19.4697C18.7398 19.4674 18.9906 19.3622 19.176 19.1768C19.3614 18.9914 19.4666 18.7406 19.4689 18.4784C19.4711 18.2162 19.3704 17.9636 19.1882 17.775L13.4132 12L19.1882 6.22499C19.3704 6.03638 19.4711 5.78378 19.4689 5.52158C19.4666 5.25939 19.3614 5.00858 19.176 4.82317C18.9906 4.63776 18.7398 4.53259 18.4776 4.53031C18.2154 4.52803 17.9628 4.62883 17.7742 4.81099L11.9992 10.586L6.22419 4.80999V4.81099Z" fill="#6D6D6D" />
                </svg>
              </div>
            </div>
          </div>
        )
      }

      {
        currentActionItem == "Filter" && (
          <div className="mobile-bottom-modal-container" onClick={toggleCurrentActionItem.bind(this)}>
            <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={toggleCurrentActionItem.bind(this)} className="w-full">
                <div className="draggable-pointer" />
              </div>
              <p className='filter-heading-text m-b-12'>Parameter Type</p>
              <div onClick={handleParameterType.bind(this, "exerciseLevel")} className='d-flex gym-filter-type w-full m-b-8'>
                <input type="radio" name="user-type" checked={ParameterType == "exerciseLevel"} className='flex-item cursor-pointer m-r-6' />
                <p className='filter-type-text'>Exercise Level</p>
              </div>
              <div onClick={handleParameterType.bind(this, "exerciseType")} className='d-flex w-full gym-filter-type m-b-8'>
                <input type="radio" name="user-type" checked={ParameterType == "exerciseType"} className='flex-item cursor-pointer m-r-6' />
                <p className='filter-type-text'>Exercise Type</p>
              </div>
              <div onClick={handleParameterType.bind(this, "targetMuscle")} className='d-flex w-full gym-filter-type m-b-8'>
                <input type="radio" name="user-type" checked={ParameterType == "targetMuscle"} className='flex-item cursor-pointer m-r-6' />
                <p className='filter-type-text'>Target Muscles</p>
              </div>
              <div onClick={handleParameterType.bind(this, "exerciseImage")} className='d-flex w-full gym-filter-type m-b-8'>
                <input type="radio" name="user-type" checked={ParameterType == "exerciseImage"} className='flex-item cursor-pointer m-r-6' />
                <p className='filter-type-text'>Exercise Image</p>
              </div>
              <div onClick={handleParameterType.bind(this, "exerciseName")} className='d-flex w-full gym-filter-type m-b-8'>
                <input type="radio" name="user-type" checked={ParameterType == "exerciseName"} className='flex-item cursor-pointer m-r-6' />
                <p className='filter-type-text'>Exercise Name</p>
              </div>
            </div>
          </div>
        )
      }

      {
        isDeleteConfirmParameterModal && <ManageConfirmationModal isMobile={true} handleAction={handleDeleteAction} title={`Delete this parameter`} message="Are you sure you want to delete this parameter ?, Note: By deleteing this parameter some changes will occur in your current application setting" buttonText={`Remove`} handleClose={handleDeleteConfirmModal} />
      }

      {
        isAddParameterModalOpen && <ManageParameter isEdit={true} parameter={{ ...Parameter }} toggleAddParameterModal={toggleAddParameterModal} />
      }
    </Dashboard>
  )
}

export default ManageParamenterMobile;