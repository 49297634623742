import React, {useEffect, useState} from "react";
import Dashboard from "../../core/Dashboard";

const UserDashboardDesktop = () => {
  return(
    <Dashboard flag={0} navItemData={"Home"}>
      <div className="header-bar d-flex space-between">
        <div className='flex-item'>
          <p className="dashboard-type-heading">Users Dashboard</p>
          <p className='dashboard-route-txt'>Manage all User's here</p>
        </div>
        <div className='d-flex'>
          <div className='flex-item' onClick={e => e.stopPropagation()}>
            <div className='global-round-btn d-flex'>
              <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className='dashboard-data-saperator'></div>
    </Dashboard>
  )
}

export default UserDashboardDesktop;