import React, {useEffect, useState} from "react";
import { getUserInfo,updateUserProfile } from "../../user/helper/api";
import { isAuthenticated } from "../../auth";
import { startLoader, stopLoader } from "../../lib/utils";
import config from "../../config";


export default function MemberProfile() {

  const [isCurrentModel, setCurrentModel] = useState('');
  const [workoutHistoryStartDateActive, setWorkoutHistoryStartDateActive] = useState(false);
  const [userInfo,setUserInfo]=useState("");
  const {user,token}=isAuthenticated();
  const [medicalReportDocument, setMedicalReportDocument] = useState(null);
  const [questionSet, SetQuestionSet] = useState([
    {
      question: "Are you currently under doctor's supervision ?",
      answer: false,
      type: 1
    },
    {
      question: "Have you ever had an exercise stress test ?",
      answer: false,
      type: 1
    },
    {
      question: "Is your stress level high ?",
      answer: false,
      type: 1
    },
    {
      question: "Is your Blood Pressure high?",
      answer: false,
      type: 1
    },
    {
      question: "Is your cholesterol level high?",
      answer: false,
      type: 1
    },
    {
      question: "Do you have Diabetes ?",
      answer: false,
      type: 1
    },
    {
      question: "Do you have any bone or joint injury in the past ?",
      answer: false,
      type: 1
    },
    {
      question: "Do you smoke ?",
      answer: "No",
      type: 2
    },
    {
      question: "Do you take alcohol ?",
      answer: "No",
      type: 2
    },
    {
      question: "Are you pregnant ?",
      answer: false,
      type: 3
    },
  ]);
  const formData=new FormData();

  
  const handleMedicalReportDocument=event=>{
    const file = event.target.files[0];
    if(file && file.type === "application/pdf"){ 
      formData.set('document',file);
      formData.set('uploadMedicalDocument',true);
      setMedicalReportDocument(file);
      UpdateUserInfo();
    }
  }

  const clearMedicalReportDocument=event=>{
    formData.set('uploadMedicalDocument',false);
    formData.delete('document');
    setMedicalReportDocument(null);
    UpdateUserInfo();
  }

  const handleModalDetailsType = (exerciseDetailsType) => {
    if(isCurrentModel.length == 0){
      setCurrentModel(exerciseDetailsType)
    }else{
      setCurrentModel('')
    }
  }

  const toggleInputFields = (currentInputField) => {
    let currentInput = document.getElementById(currentInputField);
    console.log(currentInput)

    if(currentInputField == 'workout-history-start-date'){
      currentInput.focus();

      console.log(currentInput == document.activeElement)

      setTimeout(() => { 
        if(currentInput == document.activeElement){
          setWorkoutHistoryStartDateActive(true)
        }else{
          setWorkoutHistoryStartDateActive(false)
        }
      }, 10);
    }
  }
  
  const handleQuestionResponse = (currentQuestionIndex,value='') => {
     try{
      let newArray = [...questionSet];
      newArray[currentQuestionIndex].answer = value?value:newArray[currentQuestionIndex].answer == true ? false : true;
      SetQuestionSet(newArray); 
      formData.set("questionSet",JSON.stringify(newArray))
      UpdateUserInfo();
     }catch(error){
     }
  }



  //API method started

  const GetUserInfo=async ()=>{
    let userInfo=await getUserInfo(user._id,token,user._id);
    if(!userInfo.error){

      if(userInfo.medicalHealthDetails && userInfo.medicalHealthDetails.questionSet.length){
        let updatedList=await Promise.all(userInfo.medicalHealthDetails.questionSet.map(data=>{
          return {
            question: data.question,
            answer: data.answer,
            type: data.type
          }
        }));
        SetQuestionSet(updatedList)
      }


      if(userInfo.medicalHealthDetails.medicalReportDocumentId){
        setMedicalReportDocument({
          name:userInfo.medicalHealthDetails.medicalReportDocumentName,
          medicalReportDocumentId:userInfo.medicalHealthDetails.medicalReportDocumentId
        })
      }
      setUserInfo({
        ...userInfo,
        profileURL:(userInfo && userInfo.profileID)?`${config.API_URL}${config.prefixStream}/media/file/${userInfo.profileID}?${Date.now()}`:""
      });
    }
  }

  const UpdateUserInfo=()=>{
    try{
      updateUserProfile(user._id,token,formData).then(response=>{
        if(response.error){
          // handle error
        }else{
          formData.forEach(data=>{
            formData.delete(data.name)
          })
        }
      }).catch(error=>{
        // do something
        console.error(error)
      })
    }catch(Error){
      // do something
    }
  }

  const handleProfileImage = e => {
     try{
      let imageUploader = document.getElementById(e);
      let file = imageUploader.files[0];
      formData.set("photo", file);
      UpdateUserInfo();
     }catch(error){
      // do something
     }
  }

  //end of API method 

  useEffect(async ()=>{
   try{
    await startLoader();
    await GetUserInfo()
    await stopLoader();
   }catch(error){
    console.error(error)
    await stopLoader()
   }
  },[]);




  

  return(
    <div className="w-full h-full member-profile">

      <div className="member-profile-inner-container">

        <div className="w-full profile-container">
          <div className="profile-image">
            {
              (userInfo && userInfo.profileID) ? (
                <img src={userInfo.profileURL}/>
              ) : (
                <svg width="100%" height="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M44 24C44 35.046 35.046 44 24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24ZM30 18C30 19.5913 29.3679 21.1174 28.2426 22.2426C27.1174 23.3679 25.5913 24 24 24C22.4087 24 20.8826 23.3679 19.7574 22.2426C18.6321 21.1174 18 19.5913 18 18C18 16.4087 18.6321 14.8826 19.7574 13.7574C20.8826 12.6321 22.4087 12 24 12C25.5913 12 27.1174 12.6321 28.2426 13.7574C29.3679 14.8826 30 16.4087 30 18ZM24 41C27.4326 41.0055 30.7856 39.9669 33.614 38.022C34.822 37.192 35.338 35.612 34.634 34.326C33.18 31.66 30.18 30 24 30C17.82 30 14.82 31.66 13.364 34.326C12.662 35.612 13.178 37.192 14.386 38.022C17.2144 39.9669 20.5674 41.0055 24 41Z" fill="#9A9A9A"/>
                </svg>
              )
            }
           
            <div className="upload-image-container d-flex">
              <svg className="flex-item" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 17.5C13.25 17.5 14.3127 17.0623 15.188 16.187C16.0633 15.3117 16.5007 14.2493 16.5 13C16.5 11.75 16.0623 10.6873 15.187 9.812C14.3117 8.93667 13.2493 8.49933 12 8.5C10.75 8.5 9.68734 8.93767 8.812 9.813C7.93667 10.6883 7.49934 11.7507 7.5 13C7.5 14.25 7.93767 15.3127 8.813 16.188C9.68833 17.0633 10.7507 17.5007 12 17.5ZM4 21C3.45 21 2.979 20.804 2.587 20.412C2.195 20.02 1.99934 19.5493 2 19V7C2 6.45 2.196 5.979 2.588 5.587C2.98 5.195 3.45067 4.99933 4 5H7.15L9 3H15L16.85 5H20C20.55 5 21.021 5.196 21.413 5.588C21.805 5.98 22.0007 6.45067 22 7V19C22 19.55 21.804 20.021 21.412 20.413C21.02 20.805 20.5493 21.0007 20 21H4Z" fill="#ffffff"/>
              </svg>
            </div>
            <input onInput={() => handleProfileImage("profile-image-uploader")} id="profile-image-uploader" className="upload-image-container" type="file" style={{zIndex: 2}} accept=".jpg,.jpeg,.png"/>
          </div>
          <p className="profile-name m-t-12">{userInfo && userInfo.name}</p>
          <p className="profile-expertise m-t-2">{userInfo.level?userInfo.level:"Beginner"}</p>
          <div className="d-flex w-full m-t-30 m-b-24 space-between">
            <div className="weight-height-container d-flex">
              <div className="flex-item">
                <p className="value">{(userInfo.height && userInfo.height.value)?userInfo.height.value:0} {(userInfo.height && userInfo.height.unit)?userInfo.height.unit:"Feet"}</p>
                <p className="value-type m-t-6">Height</p>
              </div>
            </div>
            <div className="weight-height-container d-flex">
              <div className="flex-item">
                <p className="value">{(userInfo.weight && userInfo.weight.value)?userInfo.weight.value:0} {(userInfo.weight && userInfo.weight.unit)?userInfo.weight.unit:"Kg"}</p>
                <p className="value-type m-t-6">Weight</p>
              </div>
            </div>
          </div>
         
          <div onClick={handleModalDetailsType.bind(this, 'medical-health')} className="custom-table-row d-flex space-between m-b-16">
            <div className="flex-item d-flex cursor-pointer">
              <svg className="flex-item" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.544 3.322C14.5022 3.19361 14.4209 3.08172 14.3117 3.00234C14.2025 2.92295 14.071 2.88013 13.936 2.88H12.48V0.64C12.48 0.286 12.194 0 11.84 0H4.16C3.806 0 3.52 0.286 3.52 0.64V2.88H2.064C1.92891 2.87982 1.79726 2.92252 1.68799 3.00195C1.57873 3.08138 1.4975 3.19345 1.456 3.322L0 7.8V15.36C0 15.714 0.286 16 0.64 16H15.36C15.714 16 16 15.714 16 15.36V7.8L14.544 3.322ZM10.96 10.32C10.96 10.408 10.888 10.48 10.8 10.48H8.64V12.64C8.64 12.728 8.568 12.8 8.48 12.8H7.52C7.432 12.8 7.36 12.728 7.36 12.64V10.48H5.2C5.112 10.48 5.04 10.408 5.04 10.32V9.36C5.04 9.272 5.112 9.2 5.2 9.2H7.36V7.04C7.36 6.952 7.432 6.88 7.52 6.88H8.48C8.568 6.88 8.64 6.952 8.64 7.04V9.2H10.8C10.888 9.2 10.96 9.272 10.96 9.36V10.32ZM11.04 2.88H4.96V1.44H11.04V2.88Z" fill="#055075"/>
              </svg>
              <p className="flex-item m-l-8">Medical Health</p>
            </div>
            <svg className="flex-item" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075"/>
            </svg>
          </div>
     
        </div>

        {
          isCurrentModel == "personal-details" && (
            <div onClick={handleModalDetailsType.bind(this, 'personal-details')} className="desktop-bottom-modal-container d-flex">
              <div onClick={e => e.stopPropagation()} className="desktop-exerise-steps-container flex-item">
                <div className="d-flex space-between flex-item m-b-20">
                  <p className="flex-item desktop-modal-heading">Personal Details</p>
                  <div onClick={handleModalDetailsType.bind(this, 'personal-details')} className="desktop-modal-close-button d-flex">
                    <svg className="flex-item" width="30" height="30" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="14.5107" y="4.46472" width="1.57856" height="14.2071" rx="0.789282" transform="rotate(45 14.5107 4.46472)" fill="#2B2B2B"/>
                      <rect x="4.46484" y="5.58093" width="1.57856" height="14.2071" rx="0.789282" transform="rotate(-45 4.46484 5.58093)" fill="#2B2B2B"/>
                    </svg>
                  </div>
                </div>
                <div className="steps-scroll-container">
                  
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={`${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Body Type</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={`${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Height</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={`${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Weight</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div className='d-flex m-t-30 m-b-14'>
                    <svg className='flex-item m-r-8' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 1.25C8.1773 1.25215 6.42987 1.97717 5.14102 3.26602C3.85218 4.55486 3.12716 6.3023 3.12501 8.125C3.12282 9.61452 3.60937 11.0636 4.51001 12.25C4.51001 12.25 4.69751 12.4969 4.72813 12.5325L10 18.75L15.2744 12.5294C15.3019 12.4963 15.49 12.25 15.49 12.25L15.4906 12.2481C16.3908 11.0623 16.8771 9.61383 16.875 8.125C16.8729 6.3023 16.1478 4.55486 14.859 3.26602C13.5701 1.97717 11.8227 1.25215 10 1.25ZM10 10.625C9.50555 10.625 9.0222 10.4784 8.61108 10.2037C8.19996 9.92897 7.87953 9.53852 7.69031 9.08171C7.50109 8.62489 7.45158 8.12223 7.54804 7.63727C7.64451 7.15232 7.88261 6.70686 8.23224 6.35723C8.58187 6.0076 9.02733 5.7695 9.51228 5.67304C9.99723 5.57657 10.4999 5.62608 10.9567 5.8153C11.4135 6.00452 11.804 6.32495 12.0787 6.73607C12.3534 7.1472 12.5 7.63055 12.5 8.125C12.4992 8.78779 12.2355 9.42319 11.7669 9.89185C11.2982 10.3605 10.6628 10.6242 10 10.625Z" fill="#F22000"/>
                    </svg>
                    <p className='flex-item personal-details-heading-txt-desktop'>Location</p>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Country</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>State</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>City</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Location</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 2.25H1.5C1.30109 2.25 1.11032 2.32902 0.96967 2.46967C0.829018 2.61032 0.75 2.80109 0.75 3V9C0.75 9.19891 0.829018 9.38968 0.96967 9.53033C1.11032 9.67098 1.30109 9.75 1.5 9.75H10.5C10.6989 9.75 10.8897 9.67098 11.0303 9.53033C11.171 9.38968 11.25 9.19891 11.25 9V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25V2.25ZM9.675 3L6 5.5425L2.325 3H9.675ZM1.5 9V3.34125L5.78625 6.3075C5.84902 6.35105 5.9236 6.37438 6 6.37438C6.0764 6.37438 6.15098 6.35105 6.21375 6.3075L10.5 3.34125V9H1.5Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
                      </svg>
                      <p className="placeholder-txt flex-item" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Email</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
                    <div className="flex-item d-flex">
                      <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.74967 10.875H9.68592C2.31717 10.4513 1.27092 4.23375 1.12467 2.33625C1.11288 2.18872 1.13032 2.04031 1.17599 1.89953C1.22166 1.75875 1.29466 1.62836 1.39081 1.51584C1.48696 1.40333 1.60436 1.31088 1.73629 1.24381C1.86823 1.17675 2.0121 1.13637 2.15967 1.125H4.22592C4.37612 1.12485 4.52292 1.16982 4.64728 1.25406C4.77164 1.33831 4.86784 1.45795 4.92342 1.5975L5.49342 3C5.5483 3.13633 5.56192 3.28578 5.53258 3.42978C5.50325 3.57379 5.43225 3.706 5.32842 3.81L4.52967 4.61625C4.65443 5.32528 4.99398 5.97888 5.50238 6.48861C6.01077 6.99834 6.66347 7.33961 7.37217 7.46625L8.18592 6.66C8.29148 6.55731 8.42494 6.48797 8.56965 6.46063C8.71436 6.43329 8.86391 6.44915 8.99967 6.50625L10.4134 7.0725C10.5509 7.12983 10.6681 7.22678 10.7503 7.351C10.8324 7.47522 10.8757 7.62108 10.8747 7.77V9.75C10.8747 10.0484 10.7561 10.3345 10.5452 10.5455C10.3342 10.7565 10.048 10.875 9.74967 10.875ZM2.24967 1.875C2.15021 1.875 2.05483 1.91451 1.9845 1.98484C1.91417 2.05516 1.87467 2.15054 1.87467 2.25V2.28C2.04717 4.5 3.15342 9.75 9.72717 10.125C9.77643 10.128 9.82581 10.1213 9.87248 10.1053C9.91914 10.0892 9.96218 10.064 9.99911 10.0313C10.036 9.99854 10.0661 9.95882 10.0877 9.91441C10.1092 9.87 10.1218 9.82178 10.1247 9.7725V7.77L8.71092 7.20375L7.63467 8.2725L7.45467 8.25C4.19217 7.84125 3.74967 4.57875 3.74967 4.545L3.72717 4.365L4.79217 3.28875L4.22967 1.875H2.24967Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
                      </svg>
                      <p className="placeholder-txt flex-item" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Phone</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    {/* style={{workoutHistoryStartDateActive == true ? "width: 46%; border: 1px solid #055075;" : 'width: 46%'}} */}
                    <div className="flex-item d-flex">
                      <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.74967 10.875H9.68592C2.31717 10.4513 1.27092 4.23375 1.12467 2.33625C1.11288 2.18872 1.13032 2.04031 1.17599 1.89953C1.22166 1.75875 1.29466 1.62836 1.39081 1.51584C1.48696 1.40333 1.60436 1.31088 1.73629 1.24381C1.86823 1.17675 2.0121 1.13637 2.15967 1.125H4.22592C4.37612 1.12485 4.52292 1.16982 4.64728 1.25406C4.77164 1.33831 4.86784 1.45795 4.92342 1.5975L5.49342 3C5.5483 3.13633 5.56192 3.28578 5.53258 3.42978C5.50325 3.57379 5.43225 3.706 5.32842 3.81L4.52967 4.61625C4.65443 5.32528 4.99398 5.97888 5.50238 6.48861C6.01077 6.99834 6.66347 7.33961 7.37217 7.46625L8.18592 6.66C8.29148 6.55731 8.42494 6.48797 8.56965 6.46063C8.71436 6.43329 8.86391 6.44915 8.99967 6.50625L10.4134 7.0725C10.5509 7.12983 10.6681 7.22678 10.7503 7.351C10.8324 7.47522 10.8757 7.62108 10.8747 7.77V9.75C10.8747 10.0484 10.7561 10.3345 10.5452 10.5455C10.3342 10.7565 10.048 10.875 9.74967 10.875ZM2.24967 1.875C2.15021 1.875 2.05483 1.91451 1.9845 1.98484C1.91417 2.05516 1.87467 2.15054 1.87467 2.25V2.28C2.04717 4.5 3.15342 9.75 9.72717 10.125C9.77643 10.128 9.82581 10.1213 9.87248 10.1053C9.91914 10.0892 9.96218 10.064 9.99911 10.0313C10.036 9.99854 10.0661 9.95882 10.0877 9.91441C10.1092 9.87 10.1218 9.82178 10.1247 9.7725V7.77L8.71092 7.20375L7.63467 8.2725L7.45467 8.25C4.19217 7.84125 3.74967 4.57875 3.74967 4.545L3.72717 4.365L4.79217 3.28875L4.22967 1.875H2.24967Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
                      </svg>
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Emergency Contact No.</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1C5.50555 1 5.0222 1.14662 4.61107 1.42133C4.19995 1.69603 3.87952 2.08648 3.6903 2.54329C3.50108 3.00011 3.45157 3.50277 3.54804 3.98773C3.6445 4.47268 3.8826 4.91814 4.23223 5.26777C4.58186 5.6174 5.02732 5.8555 5.51227 5.95196C5.99723 6.04843 6.49989 5.99892 6.95671 5.8097C7.41352 5.62048 7.80397 5.30005 8.07867 4.88893C8.35338 4.4778 8.5 3.99445 8.5 3.5C8.5 2.83696 8.23661 2.20107 7.76777 1.73223C7.29893 1.26339 6.66304 1 6 1ZM6 5C5.70333 5 5.41332 4.91203 5.16664 4.7472C4.91997 4.58238 4.72771 4.34811 4.61418 4.07403C4.50065 3.79994 4.47094 3.49834 4.52882 3.20736C4.5867 2.91639 4.72956 2.64912 4.93934 2.43934C5.14912 2.22956 5.41639 2.0867 5.70736 2.02882C5.99834 1.97094 6.29994 2.00065 6.57403 2.11418C6.84811 2.22771 7.08238 2.41997 7.2472 2.66664C7.41203 2.91332 7.5 3.20333 7.5 3.5C7.5 3.89782 7.34196 4.27936 7.06066 4.56066C6.77936 4.84196 6.39782 5 6 5ZM10.5 10.5V10C10.5 9.07174 10.1313 8.1815 9.47487 7.52513C8.8185 6.86875 7.92826 6.5 7 6.5H5C4.07174 6.5 3.1815 6.86875 2.52513 7.52513C1.86875 8.1815 1.5 9.07174 1.5 10V10.5H2.5V10C2.5 9.33696 2.76339 8.70107 3.23223 8.23223C3.70107 7.76339 4.33696 7.5 5 7.5H7C7.66304 7.5 8.29893 7.76339 8.76777 8.23223C9.23661 8.70107 9.5 9.33696 9.5 10V10.5H10.5Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
                      </svg>
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Emergency Contact Name</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                  <div onClick={toggleInputFields.bind(this, 'workout-history-start-date')} className={ `${workoutHistoryStartDateActive == true ? "member-global-input-field m-b-12" : "member-global-input-field m-b-12 d-flex"}`} style={{border : workoutHistoryStartDateActive == true ? "1px solid #055075" : ""}}> 
                    <div className="flex-item d-flex">
                      <svg className="flex-item m-r-8" width={workoutHistoryStartDateActive == true ? "10" : "18"} height={workoutHistoryStartDateActive == true ? "10" : "18"} style={{transition: "180ms"}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_733_1957)">
                        <path d="M8.25 4.875C8.25 4.77554 8.28951 4.68016 8.35983 4.60984C8.43016 4.53951 8.52554 4.5 8.625 4.5H9.375C9.47446 4.5 9.56984 4.53951 9.64017 4.60984C9.71049 4.68016 9.75 4.77554 9.75 4.875V5.625C9.75 5.72446 9.71049 5.81984 9.64017 5.89016C9.56984 5.96049 9.47446 6 9.375 6H8.625C8.52554 6 8.43016 5.96049 8.35983 5.89016C8.28951 5.81984 8.25 5.72446 8.25 5.625V4.875Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
                        <path d="M2.625 0C2.72446 0 2.81984 0.0395088 2.89016 0.109835C2.96049 0.180161 3 0.275544 3 0.375V0.75H9V0.375C9 0.275544 9.03951 0.180161 9.10983 0.109835C9.18016 0.0395088 9.27554 0 9.375 0C9.47446 0 9.56984 0.0395088 9.64017 0.109835C9.71049 0.180161 9.75 0.275544 9.75 0.375V0.75H10.5C10.8978 0.75 11.2794 0.908035 11.5607 1.18934C11.842 1.47064 12 1.85218 12 2.25V10.5C12 10.8978 11.842 11.2794 11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12H1.5C1.10218 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V2.25C0 1.85218 0.158035 1.47064 0.43934 1.18934C0.720644 0.908035 1.10218 0.75 1.5 0.75H2.25V0.375C2.25 0.275544 2.28951 0.180161 2.35984 0.109835C2.43016 0.0395088 2.52554 0 2.625 0V0ZM0.75 3V10.5C0.75 10.6989 0.829018 10.8897 0.96967 11.0303C1.11032 11.171 1.30109 11.25 1.5 11.25H10.5C10.6989 11.25 10.8897 11.171 11.0303 11.0303C11.171 10.8897 11.25 10.6989 11.25 10.5V3H0.75Z" fill={workoutHistoryStartDateActive == true ? "#055075" : "#6d6d6d"}/>
                        </g>
                        <defs>
                        <clipPath id="clip0_733_1957">
                        <rect width="12" height="12" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <p className="placeholder-txt" style={{fontSize : workoutHistoryStartDateActive == true ? "10px" : "14px", color : workoutHistoryStartDateActive == true ? "#055075" : "", transition: "180ms"}}>Date of Birth</p>
                    </div>
                    <input id="workout-history-start-date" className={`${workoutHistoryStartDateActive == true ? "active-input-field" : "inactive-input-field"}`} type="text"/>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        {
          isCurrentModel == "medical-health" && (
            <div onClick={handleModalDetailsType.bind(this, 'medical-health')} className="desktop-bottom-modal-container d-flex">
              <div onClick={e => e.stopPropagation()}  className="desktop-exerise-steps-container flex-item">
                <div className="d-flex space-between flex-item m-b-20">
                  <p className="flex-item desktop-modal-heading">Medical Health</p>
                  <div onClick={handleModalDetailsType.bind(this, 'personal-details')} className="desktop-modal-close-button d-flex">
                    <svg className="flex-item" width="30" height="30" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="14.5107" y="4.46472" width="1.57856" height="14.2071" rx="0.789282" transform="rotate(45 14.5107 4.46472)" fill="#2B2B2B"/>
                      <rect x="4.46484" y="5.58093" width="1.57856" height="14.2071" rx="0.789282" transform="rotate(-45 4.46484 5.58093)" fill="#2B2B2B"/>
                    </svg>
                  </div>
                </div>
                <div className="steps-scroll-container">
                    {
                      questionSet.map((data, index) => {
                        if(data.type == 1){
                          return(
                            <div className='medical-health-question-container d-flex-auto'>
                              <div 
                                className='d-flex question-container'>
                                <div className='question-number d-flex m-r-8'>
                                  <p className='flex-item'>{index + 1}</p>
                                </div>
                                <p className='question-txt'>{data.question}</p>
                              </div>
                              <div  onClick={handleQuestionResponse.bind(this, index,'')}  className='member-toggle-button flex-item d-flex m-t-4' style={{alignSelf: "flex-start", outline: data.answer == false ? "" : "0.5px solid #055075"}}>
                                <div style={{marginLeft: data.answer == false ? "" : "18px", background: data.answer == false ? "" : "#055075"}} className='toggle-button flex-item'/>
                              </div>
                            </div>
                          )
                        }else if(data.type == 3 && user.gender && user.gender.toLowerCase() == "female"){
                          return(
                            <div className='medical-health-question-container d-flex-auto'>
                            <div 
                              className='d-flex question-container'>
                              <div className='question-number d-flex m-r-8'>
                                <p className='flex-item'>{index + 1}</p>
                              </div>
                              <p className='question-txt'>{data.question}</p>
                            </div>
                            <div  onClick={handleQuestionResponse.bind(this, index,'')}  className='member-toggle-button flex-item d-flex m-t-4' style={{alignSelf: "flex-start", outline: data.answer == false ? "" : "0.5px solid #055075"}}>
                              <div style={{marginLeft: data.answer == false ? "" : "18px", background: data.answer == false ? "" : "#055075"}} className='toggle-button flex-item'/>
                            </div>
                          </div>
                          )
                        } else if(data.type == 2){
                          return(
                            <div className='medical-health-question-container'>
                              <div 
                                className='d-flex question-container'>
                                <div className='question-number d-flex m-r-8'>
                                  <p className='flex-item'>{index + 1}</p>
                                </div>
                                <div style={{width: "90%"}}>
                                  <p className='question-txt'>{data.question}</p>
                                  <div className="d-flex-wrap m-t-16">
                                    <div  onClick={handleQuestionResponse.bind(this, index,'No')} className={`global-button-container ${data.answer == 'No' && 'global-button-container-active'} m-r-12 m-b-12`}>
                                      <p>No</p>
                                    </div>
                                    <div onClick={handleQuestionResponse.bind(this, index,'Yes, Occasionally')} className={`global-button-container ${data.answer == 'Yes, Occasionally' && 'global-button-container-active'} m-r-12 m-b-12`}>
                                      <p>Yes, Occasionally</p>
                                    </div>
                                    <div onClick={handleQuestionResponse.bind(this, index,'Yes, Regularly')} className={`global-button-container ${data.answer == 'Yes, Regularly' && 'global-button-container-active'} m-r-12 m-b-12`}>
                                      <p>Yes, Regularly</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })
                    }

                    <div className='dashboard-data-saperator'></div>
                    
                    {
                      medicalReportDocument ? (
                        <div className="flex-item">
                          <p>Medical Report</p>
                          <div className="d-flex w-full space-between m-t-8">
                            <div className="flex-item d-flex" style={{width:"90%"}}>
                              <svg className="flex-item" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.106 20.35C14.106 19.59 13.578 19.136 12.647 19.136C12.267 19.136 12.01 19.173 11.875 19.209V21.65C12.035 21.686 12.231 21.699 12.5 21.699C13.493 21.699 14.106 21.196 14.106 20.35ZM19.872 19.16C19.455 19.16 19.185 19.197 19.025 19.234V24.642C19.185 24.679 19.442 24.679 19.675 24.679C21.367 24.691 22.471 23.759 22.471 21.785C22.484 20.068 21.478 19.16 19.872 19.16Z" fill="black"/>
                                <path d="M30.918 15.983H30.24V12.712C30.2394 12.6912 30.2374 12.6705 30.234 12.65C30.2351 12.5184 30.1886 12.3909 30.103 12.291L24.66 6.07499L24.656 6.07099C24.5869 5.99598 24.4988 5.94104 24.401 5.91199L24.371 5.90199C24.3295 5.89163 24.2868 5.88626 24.244 5.88599H10.867C10.256 5.88599 9.76 6.38299 9.76 6.99299V15.983H9.082C8.208 15.983 7.5 16.691 7.5 17.565V25.793C7.5 26.666 8.209 27.375 9.082 27.375H9.76V33.008C9.76 33.618 10.256 34.115 10.867 34.115H29.133C29.743 34.115 30.24 33.618 30.24 33.008V27.375H30.918C31.792 27.375 32.5 26.666 32.5 25.793V17.565C32.5 16.691 31.791 15.983 30.918 15.983ZM10.867 6.99299H23.69V12.657C23.69 12.963 23.938 13.21 24.244 13.21H29.134V15.983H10.867V6.99299ZM24.457 21.723C24.457 23.305 23.881 24.397 23.082 25.071C22.212 25.794 20.888 26.138 19.269 26.138C18.3 26.138 17.614 26.076 17.147 26.016V17.897C17.834 17.787 18.729 17.726 19.674 17.726C21.243 17.726 22.261 18.008 23.058 18.609C23.917 19.246 24.457 20.264 24.457 21.723ZM10.021 26.053V17.897C10.597 17.799 11.407 17.726 12.547 17.726C13.7 17.726 14.522 17.947 15.073 18.388C15.6 18.805 15.955 19.492 15.955 20.301C15.955 21.111 15.686 21.797 15.195 22.263C14.557 22.864 13.613 23.134 12.509 23.134C12.264 23.134 12.043 23.122 11.872 23.097V26.053H10.021ZM29.133 32.708H10.867V27.375H29.133V32.708ZM30.737 19.32H27.56V21.209H30.528V22.73H27.56V26.053H25.685V17.787H30.737V19.32Z" fill="black"/>
                              </svg>
                              <p className="flex-item" accept="application/pdf" style={{width:"70%",wordBreak:'break-all'}}>{medicalReportDocument.name}</p>
                            </div>
                            <div className="d-flex flex-item">
                              <div onClick={clearMedicalReportDocument} className="flex-item m-r-12">
                                <svg width="13" height="13" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11.4695 10.0041L19.7595 1.71409C19.9234 1.52279 20.009 1.27671 19.9993 1.02504C19.9895 0.773362 19.8852 0.534623 19.7071 0.356529C19.529 0.178434 19.2903 0.0741013 19.0386 0.0643802C18.7869 0.054659 18.5408 0.140266 18.3495 0.304092L10.0595 8.59409L1.76954 0.294092C1.58124 0.105788 1.32585 0 1.05954 0C0.793242 0 0.537847 0.105788 0.349543 0.294092C0.16124 0.482395 0.0554518 0.73779 0.0554518 1.00409C0.0554518 1.27039 0.16124 1.52579 0.349543 1.71409L8.64954 10.0041L0.349543 18.2941C0.244862 18.3837 0.159841 18.4941 0.0998181 18.6181C0.0397948 18.7422 0.00606468 18.8773 0.000745191 19.015C-0.0045743 19.1528 0.0186312 19.2901 0.0689057 19.4184C0.11918 19.5467 0.195439 19.6633 0.292893 19.7607C0.390348 19.8582 0.506895 19.9345 0.63522 19.9847C0.763546 20.035 0.900878 20.0582 1.0386 20.0529C1.17632 20.0476 1.31145 20.0138 1.43551 19.9538C1.55958 19.8938 1.6699 19.8088 1.75954 19.7041L10.0595 11.4141L18.3495 19.7041C18.5408 19.8679 18.7869 19.9535 19.0386 19.9438C19.2903 19.9341 19.529 19.8298 19.7071 19.6517C19.8852 19.4736 19.9895 19.2348 19.9993 18.9831C20.009 18.7315 19.9234 18.4854 19.7595 18.2941L11.4695 10.0041Z" fill="#055075"/>
                                </svg>
                              </div>
                              <div className="flex-item"  style={{position:'relative'}}>
                                <input onInput={handleMedicalReportDocument} type='file' style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',zIndex:4,cursor:'grab'}}/>
                                <svg className="flex-item" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.6448 0.597863C8.82628 0.410586 9.0432 0.261285 9.28294 0.158654C9.52267 0.0560221 9.78043 0.0021115 10.0412 6.07932e-05C10.302 -0.00198992 10.5606 0.0478603 10.8019 0.146709C11.0432 0.245557 11.2624 0.391428 11.4468 0.575828C11.6312 0.760228 11.7771 0.979471 11.876 1.22079C11.9748 1.46211 12.0247 1.72069 12.0226 1.98146C12.0206 2.24223 11.9666 2.49999 11.864 2.73973C11.7614 2.97946 11.6121 3.19639 11.4248 3.37786L10.6548 4.14786L7.8748 1.36786L8.6448 0.597863ZM7.3448 1.89786L1.2513 7.99136C1.0313 8.21136 0.871302 8.48436 0.787302 8.78436L0.0138021 11.5469C-0.00406937 11.6109 -0.00459458 11.6785 0.0122802 11.7428C0.0291551 11.8072 0.0628232 11.8658 0.109835 11.9128C0.156847 11.9598 0.215512 11.9935 0.279819 12.0104C0.344127 12.0273 0.411764 12.0267 0.475802 12.0089L3.2378 11.2354C3.53777 11.1514 3.81105 10.9916 4.0313 10.7714L10.1253 4.67836L7.3453 1.89836L7.3448 1.89786Z" fill="#055075"/>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex-item">
                          <p className="fs-16" style={{fontWeight:"600"}}>Upload your medical report here</p>
                          <div className="m-t-8" style={{width:'fit-content',position:'relative'}}>
                            <input onInput={handleMedicalReportDocument} type='file' style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',zIndex:4,cursor:'grab'}}/>
                            <div className="global-button-container d-flex flex-item">
                              <svg className="flex-item m-r-12" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5 15.577V6.927L9.17 9.257L8.462 8.538L12 5L15.538 8.538L14.831 9.258L12.5 6.927V15.577H11.5ZM6.615 19C6.155 19 5.771 18.846 5.463 18.538C5.15433 18.2293 5 17.845 5 17.385V14.962H6V17.385C6 17.5383 6.064 17.6793 6.192 17.808C6.32067 17.936 6.46167 18 6.615 18H17.385C17.5383 18 17.6793 17.936 17.808 17.808C17.936 17.6793 18 17.5383 18 17.385V14.962H19V17.385C19 17.845 18.846 18.229 18.538 18.537C18.2293 18.8457 17.845 19 17.385 19H6.615Z" fill="black"/>
                              </svg>
                              <p className="flex-item">Medical Report</p>
                            </div>
                          </div>                          
                        </div>
                      )
                    }
                </div>
              </div>
            </div>
          )
        }

      </div>

    </div>
  )
}