import React, {useState, useEffect} from 'react';
import Logo from '../assets/gymshim-full-logo.png';
import LogoMob from '../assets/gymshim-full-logo.png';
import Background from "../assets/background-login.png";

const UserSignUp = () => {

  const [currentElementId, setCurrentElementId] = useState("");
  const [UserRegistration, setUserRegistration] = useState({
    name: '',
    email: '',
    phone: '',
    password: ''
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (event) {
      let interval = setInterval(() => {
        let element = event.target.querySelector('input');
        if (element) {
          element.focus();
          clearInterval(interval)
        }
      }, 0);
    }
  }

  useEffect(() => {
    window.addEventListener("click", () => {
      setCurrentElementId('');
    })
  });

  if(window.innerWidth < 549){
    return(
      <div className='mob-dashboard mob-dashboard-scroll' style={{flexWrap: "wrap", paddingTop: "0"}}>
        <div className='setup-header-mob p-t-0 m-b-0'>
          <div className='w-full d-flex'>
            <img className='setup-header-mob p-l-0 p-b-0 flex-item' style={{width: "50%", height: "auto", margin: "0 0 0 0"}} src={LogoMob}/>
          </div>
        </div>
        <div className='w-full d-flex'>
          <img className='flex-item' style={{width: "90%", margin: "0 auto", maxHeight: "100%"}} src={Background} />
        </div>
        <div onClick={e => e.stopPropagation()} className='signin-container' style={{margin: "20px auto 0 auto"}}>
          <p className='signin-txt m-t-30 m-b-20'>Create Your Account</p>
          <div onClick={toggleInputFields('user-email')} className={`${(currentElementId == 'user-email' || UserRegistration.password) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'user-email' || UserRegistration.password) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className='m-r-4 flex-item' width={(currentElementId == 'user-email' || UserRegistration.password) ? "12" : "18"} height={(currentElementId == 'user-email' || UserRegistration.password) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 0.25H1.5C1.30109 0.25 1.11032 0.329018 0.96967 0.46967C0.829018 0.610322 0.75 0.801088 0.75 1V7C0.75 7.19891 0.829018 7.38968 0.96967 7.53033C1.11032 7.67098 1.30109 7.75 1.5 7.75H10.5C10.6989 7.75 10.8897 7.67098 11.0303 7.53033C11.171 7.38968 11.25 7.19891 11.25 7V1C11.25 0.801088 11.171 0.610322 11.0303 0.46967C10.8897 0.329018 10.6989 0.25 10.5 0.25ZM9.675 1L6 3.5425L2.325 1H9.675ZM1.5 7V1.34125L5.78625 4.3075C5.84902 4.35105 5.9236 4.37438 6 4.37438C6.0764 4.37438 6.15098 4.35105 6.21375 4.3075L10.5 1.34125V7H1.5Z" fill={(currentElementId == 'user-email' || UserRegistration.password ? "#055075" : "#6d6d6d")}/>
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-email' || UserRegistration.password) ? "10px" : "14px", color: (currentElementId == 'user-email' || UserRegistration.password) ? "#055075" : "", transition: "180ms" }}>Email</p>
            </div>
            <input value={UserRegistration.password} className={`${(currentElementId == 'user-email' || UserRegistration.password) ? "active-input-field" : "inactive-input-field"}`} type="text" />
          </div>
          <div onClick={toggleInputFields('user-name')} className={`${(currentElementId == 'user-name' || UserRegistration.password) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'user-name' || UserRegistration.password) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className='m-r-4 flex-item' width={(currentElementId == 'user-name' || UserRegistration.password) ? "12" : "18"} height={(currentElementId == 'user-name' || UserRegistration.password) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 2C10.9889 2 11.9556 2.29324 12.7779 2.84265C13.6001 3.39206 14.241 4.17295 14.6194 5.08658C14.9978 6.00021 15.0969 7.00555 14.9039 7.97545C14.711 8.94536 14.2348 9.83627 13.5355 10.5355C12.8363 11.2348 11.9454 11.711 10.9755 11.9039C10.0055 12.0969 9.00021 11.9978 8.08658 11.6194C7.17295 11.241 6.39206 10.6001 5.84265 9.77785C5.29325 8.95561 5 7.98891 5 7C5 5.67392 5.52678 4.40215 6.46447 3.46447C7.40215 2.52678 8.67392 2 10 2ZM10 0C8.61553 0 7.26215 0.410543 6.11101 1.17971C4.95986 1.94888 4.06266 3.04213 3.53284 4.32122C3.00303 5.6003 2.86441 7.00776 3.1345 8.36563C3.4046 9.7235 4.07129 10.9708 5.05025 11.9497C6.02922 12.9287 7.2765 13.5954 8.63437 13.8655C9.99224 14.1356 11.3997 13.997 12.6788 13.4672C13.9579 12.9373 15.0511 12.0401 15.8203 10.889C16.5895 9.73785 17 8.38447 17 7C17 5.14348 16.2625 3.36301 14.9497 2.05025C13.637 0.737498 11.8565 0 10 0ZM20 28H18V23C18 22.3434 17.8707 21.6932 17.6194 21.0866C17.3681 20.48 16.9998 19.9288 16.5355 19.4645C16.0712 19.0002 15.52 18.6319 14.9134 18.3806C14.3068 18.1293 13.6566 18 13 18H7C5.67392 18 4.40215 18.5268 3.46447 19.4645C2.52678 20.4021 2 21.6739 2 23V28H0V23C0 21.1435 0.737498 19.363 2.05025 18.0503C3.36301 16.7375 5.14348 16 7 16H13C14.8565 16 16.637 16.7375 17.9497 18.0503C19.2625 19.363 20 21.1435 20 23V28Z" fill={(currentElementId == 'user-name' || UserRegistration.password ? "#055075" : "#6d6d6d")}/>
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-name' || UserRegistration.password) ? "10px" : "14px", color: (currentElementId == 'user-name' || UserRegistration.password) ? "#055075" : "", transition: "180ms" }}>Name</p>
            </div>
            <input value={UserRegistration.password} className={`${(currentElementId == 'user-name' || UserRegistration.password) ? "active-input-field" : "inactive-input-field"}`} type="text" />
          </div>
          <div onClick={toggleInputFields('user-phone')} className={`${(currentElementId == 'user-phone' || UserRegistration.password) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'user-phone' || UserRegistration.password) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className='m-r-4 flex-item' width={(currentElementId == 'user-phone' || UserRegistration.password) ? "12" : "18"} height={(currentElementId == 'user-phone' || UserRegistration.phone) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.74967 10.875H9.68592C2.31717 10.4513 1.27092 4.23375 1.12467 2.33625C1.11288 2.18872 1.13032 2.04031 1.17599 1.89953C1.22166 1.75875 1.29466 1.62836 1.39081 1.51584C1.48696 1.40333 1.60436 1.31088 1.73629 1.24381C1.86823 1.17675 2.0121 1.13637 2.15967 1.125H4.22592C4.37612 1.12485 4.52292 1.16982 4.64728 1.25406C4.77164 1.33831 4.86784 1.45795 4.92342 1.5975L5.49342 3C5.5483 3.13633 5.56192 3.28578 5.53258 3.42978C5.50325 3.57379 5.43225 3.706 5.32842 3.81L4.52967 4.61625C4.65443 5.32528 4.99398 5.97888 5.50238 6.48861C6.01077 6.99834 6.66347 7.33961 7.37217 7.46625L8.18592 6.66C8.29148 6.55731 8.42494 6.48797 8.56965 6.46063C8.71436 6.43329 8.86391 6.44915 8.99967 6.50625L10.4134 7.0725C10.5509 7.12983 10.6681 7.22678 10.7503 7.351C10.8324 7.47522 10.8757 7.62108 10.8747 7.77V9.75C10.8747 10.0484 10.7561 10.3345 10.5452 10.5455C10.3342 10.7565 10.048 10.875 9.74967 10.875ZM2.24967 1.875C2.15021 1.875 2.05483 1.91451 1.9845 1.98484C1.91417 2.05516 1.87467 2.15054 1.87467 2.25V2.28C2.04717 4.5 3.15342 9.75 9.72717 10.125C9.77643 10.128 9.82581 10.1213 9.87248 10.1053C9.91914 10.0892 9.96218 10.064 9.99911 10.0313C10.036 9.99854 10.0661 9.95882 10.0877 9.91441C10.1092 9.87 10.1218 9.82178 10.1247 9.7725V7.77L8.71092 7.20375L7.63467 8.2725L7.45467 8.25C4.19217 7.84125 3.74967 4.57875 3.74967 4.545L3.72717 4.365L4.79217 3.28875L4.22967 1.875H2.24967Z" fill={(currentElementId == 'user-phone' || UserRegistration.phone ? "#055075" : "#6d6d6d")}/>
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'user-phone' || UserRegistration.phone) ? "10px" : "14px", color: (currentElementId == 'user-phone' || UserRegistration.phone) ? "#055075" : "", transition: "180ms" }}>Phone</p>
            </div>
            <input value={UserRegistration.phone} className={`${(currentElementId == 'user-phone' || UserRegistration.phone) ? "active-input-field" : "inactive-input-field"}`} type="text" />
          </div>
          <div onClick={toggleInputFields('new-password')} className={`${(currentElementId == 'new-password' || UserRegistration.password) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'new-password' || UserRegistration.password) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className='m-r-4 flex-item' width={(currentElementId == 'new-password' || UserRegistration.password) ? "12" : "18"} height={(currentElementId == 'gym-name' || UserRegistration.password) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 11C2.725 11 2.48967 10.9022 2.294 10.7065C2.098 10.5105 2 10.275 2 10V5C2 4.725 2.098 4.4895 2.294 4.2935C2.48967 4.09783 2.725 4 3 4H3.5V3C3.5 2.30833 3.74383 1.71867 4.2315 1.231C4.71883 0.743667 5.30833 0.5 6 0.5C6.69167 0.5 7.28133 0.743667 7.769 1.231C8.25633 1.71867 8.5 2.30833 8.5 3V4H9C9.275 4 9.5105 4.09783 9.7065 4.2935C9.90217 4.4895 10 4.725 10 5V10C10 10.275 9.90217 10.5105 9.7065 10.7065C9.5105 10.9022 9.275 11 9 11H3ZM6 8.5C6.275 8.5 6.5105 8.40217 6.7065 8.2065C6.90217 8.0105 7 7.775 7 7.5C7 7.225 6.90217 6.9895 6.7065 6.7935C6.5105 6.59783 6.275 6.5 6 6.5C5.725 6.5 5.48967 6.59783 5.294 6.7935C5.098 6.9895 5 7.225 5 7.5C5 7.775 5.098 8.0105 5.294 8.2065C5.48967 8.40217 5.725 8.5 6 8.5ZM4.5 4H7.5V3C7.5 2.58333 7.35417 2.22917 7.0625 1.9375C6.77083 1.64583 6.41667 1.5 6 1.5C5.58333 1.5 5.22917 1.64583 4.9375 1.9375C4.64583 2.22917 4.5 2.58333 4.5 3V4Z" fill={(currentElementId == 'new-password' || UserRegistration.password ? "#055075" : "#6d6d6d")} />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'new-password' || UserRegistration.password) ? "10px" : "14px", color: (currentElementId == 'new-password' || UserRegistration.password) ? "#055075" : "", transition: "180ms" }}>New Password</p>
            </div>
            <input value={UserRegistration.password} className={`${(currentElementId == 'new-password' || UserRegistration.password) ? "active-input-field" : "inactive-input-field"}`} type="text" />
          </div>
          <div onClick={toggleInputFields('confirm-password')} className={`${(currentElementId == 'confirm-password' || confirmPassword) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'confirm-password' || confirmPassword) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className='m-r-4 flex-item' width={(currentElementId == 'confirm-password' || confirmPassword) ? "12" : "18"} height={(currentElementId == 'confirm-password' || confirmPassword) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 11C2.725 11 2.48967 10.9022 2.294 10.7065C2.098 10.5105 2 10.275 2 10V5C2 4.725 2.098 4.4895 2.294 4.2935C2.48967 4.09783 2.725 4 3 4H3.5V3C3.5 2.30833 3.74383 1.71867 4.2315 1.231C4.71883 0.743667 5.30833 0.5 6 0.5C6.69167 0.5 7.28133 0.743667 7.769 1.231C8.25633 1.71867 8.5 2.30833 8.5 3V4H9C9.275 4 9.5105 4.09783 9.7065 4.2935C9.90217 4.4895 10 4.725 10 5V10C10 10.275 9.90217 10.5105 9.7065 10.7065C9.5105 10.9022 9.275 11 9 11H3ZM6 8.5C6.275 8.5 6.5105 8.40217 6.7065 8.2065C6.90217 8.0105 7 7.775 7 7.5C7 7.225 6.90217 6.9895 6.7065 6.7935C6.5105 6.59783 6.275 6.5 6 6.5C5.725 6.5 5.48967 6.59783 5.294 6.7935C5.098 6.9895 5 7.225 5 7.5C5 7.775 5.098 8.0105 5.294 8.2065C5.48967 8.40217 5.725 8.5 6 8.5ZM4.5 4H7.5V3C7.5 2.58333 7.35417 2.22917 7.0625 1.9375C6.77083 1.64583 6.41667 1.5 6 1.5C5.58333 1.5 5.22917 1.64583 4.9375 1.9375C4.64583 2.22917 4.5 2.58333 4.5 3V4Z" fill={(currentElementId == 'confirm-password' || confirmPassword ? "#055075" : "#6d6d6d")} />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'confirm-password' || confirmPassword) ? "10px" : "14px", color: (currentElementId == 'confirm-password' || confirmPassword) ? "#055075" : "", transition: "180ms" }}>Confirm Password</p>
            </div>
            <input value={confirmPassword} className={`${(currentElementId == 'confirm-password' || confirmPassword) ? "active-input-field" : "inactive-input-field"}`} type="text" />
          </div>
        </div>
        <div style={{width: "90%", margin: '0 auto'}}>
          <div className="member-button-global btn-center w-full d-flex m-b-16 flex-item">
            <p className='flex-item'>Create Account</p>
          </div>
          <div className="member-button-global btn-center w-full d-flex flex-item" style={{background: "#ffffff"}}>
            <div className='flex-item d-flex'>
              <svg className='flex-item m-r-6' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107"/>
                <path d="M3.15332 7.3455L6.43882 9.755C7.32782 7.554 9.48082 6 12.0003 6C13.5298 6 14.9213 6.577 15.9808 7.5195L18.8093 4.691C17.0233 3.0265 14.6343 2 12.0003 2C8.15932 2 4.82832 4.1685 3.15332 7.3455Z" fill="#FF3D00"/>
                <path d="M12.0002 22C14.5832 22 16.9302 21.0115 18.7047 19.404L15.6097 16.785C14.5719 17.5742 13.3039 18.001 12.0002 18C9.39916 18 7.19066 16.3415 6.35866 14.027L3.09766 16.5395C4.75266 19.778 8.11366 22 12.0002 22Z" fill="#4CAF50"/>
                <path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2"/>
              </svg>
              <p className='flex-item' style={{color: "#2b2b2b", fontWeight: "400"}}>Sign Up with Google</p>
            </div>
          </div>
          <div className='w-full space-between d-flex p-l-4 p-r-4 m-t-18 m-b-18'>
            <div className='or-line-saperator flex-item'/>
            <p className='or-text flex-item'>Already have an account ?</p>
            <div className='or-line-saperator flex-item'/>
          </div>
          <div className="member-button-global btn-center w-full d-flex flex-item" style={{background: "#ffffff"}}>
            <p className='flex-item' style={{color: "#055075"}}>Sign In</p>
          </div>
        </div>
      </div>
    )
  }else{
    return(
      <div className="login-window">
        <div className="login-container">
          <div className="login-background">
            <img className="background-img" alt="bg-img-rest-pswd" src={Background} />
          </div>
          <div className="login-page">
            <img className="logo-instafit" src={Logo} />
            <div className="welcome-message">Create Your Account</div>
            <div className="welcome-quote">Already have an account ? <span style={{color: "#0088da"}}>Sign in</span></div>
            <div className="header-divider" />
            <div className="input-field-container">
              <input className="input-field" id="email" type="text" placeholder="Email" />
            </div>
            <div className='m-t-20' />
            <div className="input-field-container">
              <input className="input-field" id="name" type="text" placeholder="Name" />
            </div>
            <div className='m-t-20' />
            <div className="input-field-container">
              <input className="input-field" id="phone" type="text" placeholder="Phone" />
            </div>
            <div className='m-t-20' />
            <div className="input-field-container">
              <input className="input-field" id="password" type="password" placeholder="New Password" />
            </div>
            <div className='m-t-20' />
            <div className="input-field-container">
              <input className="input-field" id="password" type="password" placeholder="Confirm Password" />
            </div>
            <div className="signin-action-container">
              <div className="signin-button">
                <div className="signin-text">
                  Create Account
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserSignUp;