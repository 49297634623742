import React, {useState, useEffect} from 'react';
import NoDataFound from "../../assets/no_data.png";


export default function MemberReport() {

  const [myReportType, SetMyReportType] = useState('body-composition');
  const [reportItemModal, SetReportItemModal] = useState('');

  const handleCurrentReportType = (currentReportType) => {
    SetMyReportType(currentReportType)
  }

  const handleReportItem = () => {
    if(reportItemModal == false){
      SetReportItemModal(true)
    }else{
      SetReportItemModal(false)
    }
  }

  return(
    <div className='w-full h-full' style={{ padding: '16px 12px' }}>
      <div className='w-full d-flex m-t-40'>
        <div className='w-full flex-item'>
          <img className="flex-item" style={{ width: "95%", margin: "0 auto" }} src={NoDataFound} />
          <div className='w-full' style={{margin: "20px auto 0 auto", textAlign:"center"}}>
            <p className='fs-14' style={{lineHeight:"18px", color:"#055075"}}>No Notification Available</p>
          </div>
        </div>
      </div>
    </div>
  )
}
