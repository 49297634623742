//member basic operations
import { API } from '../../backend';
import config from '../../config';
import { objectToQueryString } from '../../lib/utils';

export const getMember = (userId, token, memberId) => {
    return fetch(`${API}/member/${userId}/${memberId}`, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err))
};

export const createMember = (userId, token, member) => {

    return fetch(`${API}/member/create/${userId}`, {
        method: 'POST',
        headers: {
            'Content-type': "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(member)
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err))

};

export const userProfileUpdate = (userId, token, memberProfile) => {
    return fetch(`${API}/update/user/profile/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: memberProfile
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err))
}


export const updateUserProfileImage = (userId, memberId, token, userProfileImage) => {
    return fetch(`${API}/user/profile-update/${userId}/${memberId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: userProfileImage
    }).then(response => {
        return response.json();
    }).catch(err =>  console.log(err))
} 


export const getAllMembers = (userId, token, query) => {
    let str = objectToQueryString(query);
    return fetch(`${API}/members/${userId}?${str}`, {
        method: 'get',
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(res => res.json()).catch(console.error);
}

export const getMemberCount = (userId, token, query) => {
    let str = objectToQueryString(query);
    return fetch(`${API}/members/count/${userId}?${str}`, {
        method: 'get',
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(res => res.json()).catch(console.error);
}

export const getMemberWorkoutPlannerAnalytics = (userId, token, memberId) => {
    return fetch(`${API}/member/workout/planner/analytics/${userId}/${memberId}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json()).catch(console.error)
}


export const saveWorkoutReportFromWeb=(userId,token,memberId,scheduleId,body)=>{
    return fetch(`${API}/save/workout-report/web/${userId}/${memberId}/${scheduleId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            'Content-type':'application/json',
            Authorization:`Bearer ${token}`,
        },
        body:JSON.stringify(body)
    }).then(res=>res.json()).catch(console.error);
}


export const checkMemberAvailability = (userId, token, query) => {
    return fetch(`${API}/check/member/availibility/${userId}`, {
        method: 'post',
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
        body: JSON.stringify(query)
    }).then(res => res.json()).catch(console.error);
}

export const updateMember = (userId, token, memberId, body) => {
    return fetch(`${API}/member/update/${userId}/${memberId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).catch(console.error);
}

export const changeMemberStatus = (userId, token, status, body) => {
    return fetch(`${API}/change/member/status/${userId}/${status}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
        body: JSON.stringify(body)
    }).then(response => response.json()).catch(console.error)
}

//medicall report api calls

export const getMedicalReport = (userId, token, medicalreportId) => {
    return fetch(`${API}/get-medical-report/${userId}/${medicalreportId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json()
    }).catch(err => console.log(err))
}


export const getAllMedicalReport = (userId, token, medicalreportId) => {
    return fetch(`/get-all-medical-report/${userId}/${medicalreportId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err))
};

export const addMedicalReport = (userId, token, memberId, report) => {
    return fetch(`/add-medical-report/${userId}/${memberId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: report
    }).then(response => {
        return response.json();
    }).catch(err => console.log(err));
}

// appointment 

export const getAchivement = (userId, token, achivementId) => {
    return fetch(`${API}/achivement/${userId}/${achivementId}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json();
    }).catch(console.error)
}


export const getAllAchivements = (userId, token, query) => {
    let queryString = objectToQueryString(query);
    return fetch(`${API}/achivements/${userId}?${queryString}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json()
    }).catch(console.error)
}


export const getAchivementCount = (userId, token, query) => {
    let queryString = objectToQueryString(query);
    return fetch(`${API}/achivement/count/${userId}?${queryString}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json()).catch(console.error)
}

export const createAchivement = (userId, token, body) => {
    return fetch(`${API}/create/achivement/${userId}`, {
        method: 'post',
        headers: {
            Accept: `application/json`,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => response.json()).catch(console.error)
}

export const updateAchivement = (userId, token, achivementId, body) => {
    return fetch(`${API}/update/achivement/${userId}/${achivementId}`, {
        method: 'put',
        headers: {
            Accept: `application/json`,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => response.json()).catch(console.error)
}

export const removeAchivement = (userId, token, achivementId) => {
    return fetch(`${API}/delete/achivement/${userId}/${achivementId}`, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    }).then(response => response.json()).catch(console.error)
}

//schedule template

export const getScheduleTemplate = (userId, token, scheduletemplateId) => {
    return fetch(`${API}/schedule-template/${userId}/${scheduletemplateId}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json()
    }).catch(console.error)
}

export const getAllScheduleTemplates = (userId, token, query) => {
    let queryString = objectToQueryString(query);
    return fetch(`${API}/schedule-templates/${userId}?${queryString}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json()
    }).catch(console.error)
}


export const getScheduleTemplateCount = (userId, token, query) => {
    let queryString = objectToQueryString(query);
    return fetch(`${API}/schedule-template/count/${userId}?${queryString}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.json()
    }).catch(console.error)
}

export const removeSCheduleTemplate=(userId,token,scheduletemplateId)=>{
    return fetch(`${API}/remove/schedule-template/${userId}/${scheduletemplateId}`,{
        method:'delete',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        }
    }).then(response=>response.json()).catch(console.error);
}


// end of scheduletemplate id

// schedule -API

export const getSchedule = (userId, token, scheduleId) => {
    return fetch(`${API}/schedule/${userId}/${scheduleId}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json()).catch(console.error)
}

export const getSchedules = (userId, token, query) => {
    let queryString = objectToQueryString(query);
    return fetch(`${API}/schedules/${userId}?${queryString}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json()).catch(console.error);
}


export const getScheduleCount = (userId, token, query) => {
    let queryString = objectToQueryString(query);
    return fetch(`${API}/schedules/count/${userId}?${queryString}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json()).catch(console.error)
}

export const updateSchedule = (userId, token, scheduleId, body) => {
    return fetch(`${API}/update/schedule/${userId}/${scheduleId}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => response.json()).catch(console.error)
}


//end -Schedule API


//workout reports API - start method----
export const getWorkoutReport=(userId,token,workoutreportId)=>{
    return fetch(`${API}/workout-report/${userId}/${workoutreportId}`,{
        method:'get',
        headers:{
            Accept:"application/json",
            Authorization:`Bearer ${token}`
        }
    }).then(response=>response.json()).catch(console.error);
}
export const getWorkoutReports=(userId,token,query)=>{
    let queryString=objectToQueryString(query)
    return fetch(`${API}/workout-reports/${userId}?${queryString}`,{
        method:'get',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        }
    }).then(response=>response.json()).catch(console.error)
}
export const getWorkoutReportCount=(userId,token,query)=>{
    let queryString=objectToQueryString(query);
    return fetch(`${API}/workout-reports/count/${userId}?${queryString}`,{
        method:'get',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        } 
    }).then(response=>response.json()).catch(console.error);
}
export const saveWorkoutReport=(userId,token,memberId,body)=>{
    return fetch(`${API}/save/workout-report/${userId}/${memberId}`,{
        method:'get',
        headers:{
            Accept:'application/json',
            'Content-type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(body)
    }).then(response=>response.json()).catch(console.error)
}
//Workoutreport API - end of method

//Fitness Test Report - start of methods
export const getFitnessTestReport = (userId,token,fitnesstestreportId)=>{
    return fetch(`${API}/fitnesstest-report/${userId}/${fitnesstestreportId}`,{
        method:"GET",
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        }
    }).then(response=>response.json()).catch(console.log);
} 
export const getFitnessTestReports = (userId,token,query)=>{
    let queryString=objectToQueryString(query);
    return fetch(`${API}/fitnesstest-reports/${userId}?${queryString}`,{
        method:"GET",
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        }
    }).then(response=>response.json()).catch(console.log);
}
export const getFitnessTestReportCount = (userId,token,query)=>{
    let queryString=objectToQueryString(query);
    return fetch(`${API}/fitnesstest-report/count/${userId}?${queryString}`,{
        method:"GET",
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        }
    }).then(response=>response.json()).catch(console.log);
}
export const createFitnessTestReport=(userId,token,memberId,body)=>{
    return fetch(`${API}/create/fitnesstest/${userId}/${memberId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },
        body:body
    }).then(response=>response.json()).catch(console.log);
}
export const updateFitnessTestReport=(userId,token,memberId,fitnesstestreportId,body)=>{
    return fetch(`${API}/update/fitnesstest/${userId}/${memberId}/${fitnesstestreportId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },
        body:body
    }).then(response=>response.json()).catch(console.log);
}
export const deleteFitnessTestReport=(userId,token,fitnesstestreportId)=>{
    return fetch(`${API}/delete/fitnesstest-report/${userId}/${fitnesstestreportId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        }
    }).then(response=>response.json()).catch(console.error);
};
//Fitness Test Report - end of methods

export const getMemberInfoByToken=(memberId,token)=>{
    return fetch(`${API}/fetch/member/info/token?memberId=${memberId}&token=${token}`,{
        method:'GET',
        headers:{
            Accept:'application/json'
        }
    }).then(response=>response.json()).catch(console.error);
};

export const memberUserAccountUpdate=(userId,memberId,formData)=>{
    return fetch(`${API}/member/account-setup/${userId}/${memberId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
        },
        body:formData
    }).then(res=>res.json()).catch(console.error);
}

// sync with gos

export const syncMemberWithGos = (userId,token,branchId,gym_slug) =>{
    return fetch(`${API}/gos/sync-membr/${userId}/${branchId}/${gym_slug}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },
    }).then(res=>res.json()).catch(console.error);
}

export const updateMemberMembershipData = (userId,token,branchId,body) =>{
    return fetch(`${API}/gos/update/member-membership/${userId}/${branchId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`,
            "Content-Type":"application/json"
        },
        body:JSON.stringify(body)
    }).then(res=>res.json()).catch(console.error);
}

export const enrollGosMemberToBioMetric = (userId,token,body) =>{
    return fetch(`${API}/enroll/gos-member/biometric/${userId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`,
            "Content-Type":"application/json"
        },
        body:JSON.stringify(body)
    }).then(res=>res.json()).catch(console.error);
}

export const getAllGosEmployeesByGym = (branchId)=>{
    return fetch(`${API}/gos/gym-employees/${branchId}/${config.wpAuthToken}`,{
        method:'GET',
        headers:{
            Accept:'application/json',
        },
    }).then(res=>res.json()).catch(console.error);
}