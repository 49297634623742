import React, {useState, useEffect} from "react";
import SampleProfile from "../../assets/sample-profile.png";

export default function MemberAppointment(){

  const[modalType, setModalType] = useState("");
  const [isAppointmentInputActive, setWeightInputActive] = useState(false);
  const [appointmentReason, setWeightValue] = useState('Appointment Reason');

  const handleAppointmentModal = (currentModalType) => {
    if(modalType == currentModalType){
      setModalType("");
    }else{
      setModalType(currentModalType)
    }
  }

  const toggleInputFields = (currentInputField) => {
    let currentInput = document.getElementById(currentInputField);
    console.log(currentInput);

    if(currentInputField == 'appointment-reason-input-field'){
      currentInput.focus();
      
      setTimeout(() => { 
        if(currentInput == document.activeElement){
          setWeightInputActive(true)
        }else{
          setWeightInputActive(false);
        }
      }, 10);
    }
  }

  return(
    <div className='mob-dashboardmob-dashboard-scroll d-flex' style={{flexWrap: "wrap"}}>
      <div className='signin-container m-0 p-0'>
        <p className='signin-txt'>Appointment</p>
        <div className="d-flex m-t-4 m-b-20">
          <div className="flex-item d-flex m-r-4">
            <svg className="flex-item" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.4375 11.25H9.5625V13.5H8.4375V11.25ZM11.25 10.125H12.375V13.5H11.25V10.125ZM5.625 7.875H6.75V13.5H5.625V7.875Z" fill="#055075"/>
              <path d="M14.0625 2.8125H12.375V2.25C12.375 1.95163 12.2565 1.66548 12.0455 1.4545C11.8345 1.24353 11.5484 1.125 11.25 1.125H6.75C6.45163 1.125 6.16548 1.24353 5.95451 1.4545C5.74353 1.66548 5.625 1.95163 5.625 2.25V2.8125H3.9375C3.63913 2.8125 3.35298 2.93103 3.142 3.142C2.93103 3.35298 2.8125 3.63913 2.8125 3.9375V15.75C2.8125 16.0484 2.93103 16.3345 3.142 16.5455C3.35298 16.7565 3.63913 16.875 3.9375 16.875H14.0625C14.3609 16.875 14.647 16.7565 14.858 16.5455C15.069 16.3345 15.1875 16.0484 15.1875 15.75V3.9375C15.1875 3.63913 15.069 3.35298 14.858 3.142C14.647 2.93103 14.3609 2.8125 14.0625 2.8125V2.8125ZM6.75 2.25H11.25V4.5H6.75V2.25ZM14.0625 15.75H3.9375V3.9375H5.625V5.625H12.375V3.9375H14.0625V15.75Z" fill="#055075"/>
            </svg>
          </div>
          <div className="flex-item d-flex m-t-4">
            <p className="flex-item gym-calendar-txt">2022</p>
          </div>
        </div>
        <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="d-flex appointment-tile-mob w-full m-b-16">
          <img className="m-r-24" src={SampleProfile} />
          <div>
            <p className="appointment-user-mob">Gandhi James William</p>
            <p className="appointment-user-type-mob">Programmer</p>
            <div className="d-flex m-t-8">
              <div className="appointment-rating-container d-flex">
                <p className="flex-item m-r-4">74%</p>
                <svg className="flex-item" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                </svg>
              </div>
            </div>
            <p className="flex-item availability-txt-mob m-t-12">Available Tomorrow</p>
          </div>
        </div>
        <div className="d-flex appointment-tile-mob w-full m-b-16">
          <img className="m-r-24" src={SampleProfile} />
          <div>
            <p className="appointment-user-mob">Gandhi James William</p>
            <p className="appointment-user-type-mob">Programmer</p>
            <div className="d-flex m-t-8">
              <div className="appointment-rating-container d-flex">
                <p className="flex-item m-r-4">74%</p>
                <svg className="flex-item" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.66483 4.8321C8.84929 4.58304 8.94969 4.27919 8.94644 3.96415C8.94128 3.46433 8.65698 2.99411 8.20453 2.73493C8.08805 2.66822 7.95586 2.63384 7.82164 2.63535L5.15221 2.66291L5.20488 1.29411C5.21705 0.963336 5.09692 0.650624 4.86736 0.413634C4.75469 0.296818 4.61927 0.204348 4.46946 0.141938C4.31964 0.0795278 4.15862 0.048504 3.99635 0.0507835C3.41749 0.0567607 2.90944 0.451664 2.76158 1.01096L1.8411 4.48285L1.83776 4.48288L1.88696 9.24732L7.14455 9.19303C7.24696 9.19198 7.34694 9.1709 7.43892 9.12988C7.96647 8.89843 8.30287 8.37727 8.29694 7.80286C8.29549 7.6626 8.27403 7.52477 8.23257 7.3916C8.41704 7.14254 8.51744 6.83869 8.51418 6.52366C8.51273 6.38339 8.49127 6.24557 8.44982 6.1124C8.63428 5.86334 8.73468 5.55948 8.73143 5.24445C8.72775 5.10421 8.70628 4.96527 8.66483 4.8321V4.8321ZM0.0492086 4.85761L0.0910491 8.90961C0.0930837 9.10664 0.253913 9.26419 0.450948 9.26215L1.17452 9.25468L1.12532 4.49024L0.401751 4.49771C0.204716 4.49974 0.0471741 4.66057 0.0492086 4.85761Z" fill="white"/>
                </svg>
              </div>
            </div>
            <p className="flex-item availability-txt-mob m-t-12">Available Tomorrow</p>
          </div>
        </div>
      </div>
      
      {
        modalType == 'appointment-view-container' && (
          <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="mobile-bottom-modal-container">
            <div onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={handleAppointmentModal.bind(this, "appointment-view-container")} className="w-full">
                <div className="draggable-pointer" />
              </div>
              <div className="d-flex w-full space-between m-t-12">
                <div className="flex-item d-flex selected-user-type-modal">
                  <img className="flex-item m-r-12" src={SampleProfile} />
                  <div className="flex-item">
                    <p className="appointment-modal-name-mob">Jonathan Akbar</p>
                    <p className="appointment-modal-user-type-mob">Programmer</p>
                  </div>
                </div>
                <div className="flex-item d-flex">
                  <div className="d-flex switch-slot-date-btn m-r-30">
                    <svg className="flex-item" width="8" height="14" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 1L2 9.67347L12 18" stroke="#055075" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                  <div className="d-flex switch-slot-date-btn">
                    <svg className="flex-item" width="8" height="14" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 18L10 9.32653L1 0.999999" stroke="#055075" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="appointment-scroll-container-mob w-full m-t-20">
                <div className="m-b-12 w-full">
                  <p className="appointment-slot-date-mob m-b-2">Today</p>
                  <p className="appointment-slot-date-availability-mob">No Slots Available</p>
                </div>
                <div className="m-b-12 w-full">
                  <p className="appointment-slot-date-mob m-b-2">Tomorrow</p>
                  <p className="appointment-slot-date-availability-mob">12 Slots Available</p>
                  <div className="d-flex-wrap space-between" style={{padding: "2px"}}>
                    <div onClick={handleAppointmentModal.bind(this, "appointment-selected-date-container")} className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                  </div>
                </div>
                <div className="m-b-16 w-full">
                  <p className="appointment-slot-date-mob m-b-2">4th Oct</p>
                  <p className="appointment-slot-date-availability-mob">4 Slots Available</p>
                  <div className="d-flex-wrap space-between" style={{padding: "2px"}}>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                    <div className="slot-time-chips d-flex">
                      <p className="flex-item">10:00 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        modalType == "appointment-selected-date-container" && (
          <div onClick={handleAppointmentModal.bind(this, "appointment-selected-date-container")} className="mobile-bottom-modal-container">
            <div onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
              <div onClick={handleAppointmentModal.bind(this, "appointment-selected-date-container")} className="w-full">
                <div className="draggable-pointer" />
              </div>
              <div className="w-full">
                <div className="flex-item d-flex space-between w-full">
                  <p className="flex-item book-appointment-txt-mob">Book Appointment</p>
                  <svg className="flex-item" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 3C13.97 3 18 5.69 18 9C18 10.68 16.96 12.2 15.29 13.29C16.36 12.42 17 11.32 17 10.13C17 7.29 13.42 5 9 5V8L5 4L9 0V3ZM9 17C4.03 17 0 14.31 0 11C0 9.32 1.04 7.8 2.71 6.71C1.64 7.58 1 8.68 1 9.88C1 12.71 4.58 15 9 15V12L13 16L9 20V17Z" fill="black"/>
                  </svg>
                </div>
                <div className="flex-item d-flex m-b-20">
                  <svg className="flex-item m-r-8" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1383_2055)">
                    <path d="M8 3.5C8 3.36739 7.94732 3.24021 7.85355 3.14645C7.75979 3.05268 7.63261 3 7.5 3C7.36739 3 7.24021 3.05268 7.14645 3.14645C7.05268 3.24021 7 3.36739 7 3.5V9C7.00003 9.08813 7.02335 9.17469 7.06761 9.25091C7.11186 9.32712 7.17547 9.39029 7.252 9.434L10.752 11.434C10.8669 11.4961 11.0014 11.5108 11.127 11.4749C11.2525 11.4391 11.3591 11.3556 11.4238 11.2422C11.4886 11.1288 11.5065 10.9946 11.4736 10.8683C11.4408 10.7419 11.3598 10.6334 11.248 10.566L8 8.71V3.5Z" fill="#0088DA"/>
                    <path d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" fill="#0088DA"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1383_2055">
                    <rect width="16" height="16" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <p className="flex-item selected-slot-time">10:00 AM</p>
                </div>
              </div>
              <div className="flex-item d-flex selected-user-type-modal m-b-20">
                <img className="flex-item m-r-12" src={SampleProfile} />
                <div className="flex-item">
                  <p className="appointment-modal-name-mob">Jonathan Akbar</p>
                  <p className="appointment-modal-user-type-mob">Programmer</p>
                </div>
              </div>
              <div onClick={toggleInputFields.bind(this, 'appointment-reason-input-field')} className={`${isAppointmentInputActive == true || appointmentReason.length > 0 ? "member-global-input-field flex-item m-b-16" : "member-global-input-field flex-item d-flex m-b-16"}`} style={{border : isAppointmentInputActive == true ? "1px solid #055075" : "", width: "100%", height: "80px"}}> 
                <div className='d-flex w-full space-between'>
                  <div className='flex-item'>
                    <div className="flex-item d-flex">
                      <svg className='flex-item m-r-8' width={isAppointmentInputActive == true || appointmentReason.length > 0 ? "10" : "16"} height={isAppointmentInputActive == true || appointmentReason.length > 0 ? "10" : "16"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1384_2074)">
                        <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill={isAppointmentInputActive == true || appointmentReason.length > 0 ? "#055075" : "#9a9a9a"}/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1384_2074">
                        <rect width="12" height="12" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <p className="placeholder-txt flex-item" style={{fontSize : isAppointmentInputActive == true || appointmentReason.length > 0 ? "10px" : "", color : isAppointmentInputActive == true || appointmentReason.length > 0 ? "#055075" : "", transition: "180ms"}}>Appointment Reason</p>
                    </div>
                    <textarea id="appointment-reason-input-field" style={{width:"96%", resize: "none", height: "60%"}} value={appointmentReason} type="number" className={`${isAppointmentInputActive == true || appointmentReason.length > 0 ? "active-input-field" : "inactive-input-field"}`} />
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div className="d-flex">
                  <div className="d-flex" style={{margin: "0 0 0 auto"}}>
                    <p className="cursor-pointer m-r-24 flex-item">Cancel</p>
                    <div className="book-button d-flex flex-item">
                      <p>Book</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}