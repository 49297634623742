import {API}  from '../../../backend';
import { objectToQueryString } from "../../../lib/utils"


export const getDevice=(deviceId)=>{
  return fetch(`${API}/device/${deviceId}`,{
    method:'GET',
    headers:{
      Accept:"application/json"
    }
  }).then(response=>response.json()).catch(console.error);
} 

export const getAllDevice=(userId,token,query)=>{
  let qrStr=objectToQueryString(query);
  return fetch(`${API}/devices/${userId}?${qrStr}`,{
    method:'GET',
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(response=>response.json()).catch(console.error);
} 


export const getDeviceCount=(userId,token,query)=>{
  let qrStr=objectToQueryString(query);
  return fetch(`${API}/device/count/${userId}?${qrStr}`,{
    method:'GET',
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(response=>response.json()).catch(console.error);
} 


export const changeDeviceStatus=(userId,token,status,body)=>{
  return fetch(`${API}/change/device/status/${userId}/${status}`,{
    method:'POST',
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json",
      'Content-type':'application/json'
    },
    body:JSON.stringify(body)
  }).then(response=>response.json()).catch(console.error);
} 



export const removeDevice=(userId,token,deviceId)=>{
  return fetch(`${API}/device/delete/${userId}/${deviceId}`,{
    method:'DELETE',
    headers:{
      Authorization:`Bearer ${token}`,
      Accept:"application/json"
    }
  }).then(response=>response.json()).catch(console.error);
} 


export const createDevice=(userId,token,body)=>{
  return fetch(`${API}/device/create/${userId}`,{
    method:'POST',
    headers:{
      Authorization:`Bearer ${token}`,
      'Content-type':'application/json',
      Accept:"application/json"
    },
    body:JSON.stringify(body)
  }).then(response=>response.json()).catch(console.error);
} 





