import React, {useEffect, useState} from 'react';
import { getUserInfo, updateUserProfile } from '../../user/helper/api';
import { isAuthenticated } from '../../auth';
import config from '../../config';
import { startLoader, stopLoader } from '../../lib/utils';

export default function MedicalHealth(){
  const [medicalReportDocument, setMedicalReportDocument] = useState(null);
  const {user,token}=isAuthenticated();
  const [questionSet, SetQuestionSet] = useState([
    {
      question: "Are you currently under doctor's supervision ?",
      answer: false,
      type: 1
    },
    {
      question: "Have you ever had an exercise stress test ?",
      answer: false,
      type: 1
    },
    {
      question: "Is your stress level high ?",
      answer: false,
      type: 1
    },
    {
      question: "Is your Blood Pressure high?",
      answer: false,
      type: 1
    },
    {
      question: "Is your cholesterol level high?",
      answer: false,
      type: 1
    },
    {
      question: "Do you have Diabetes ?",
      answer: false,
      type: 1
    },
    {
      question: "Do you have any bone or joint injury in the past ?",
      answer: false,
      type: 1
    },
    {
      question: "Do you smoke ?",
      answer: "No",
      type: 2
    },
    {
      question: "Do you take alcohol ?",
      answer: "No",
      type: 2
    },
    {
      question: "Are you pregnant ?",
      answer: false,
      type: 3
    },
  ]);
  const formData=new FormData();

    
  const handleMedicalReportDocument=event=>{
    const file = event.target.files[0];
    if(file && file.type === "application/pdf"){ 
      formData.set('document',file);
      formData.set('uploadMedicalDocument',true);
      setMedicalReportDocument(file);
      UpdateUserInfo();
    }
  }

  const clearMedicalReportDocument=event=>{
    formData.set('uploadMedicalDocument',false);
    formData.delete('document');
    setMedicalReportDocument(null);
    UpdateUserInfo();
  }
  
  const handleQuestionResponse = (currentQuestionIndex,value='') => {
     try{
      let newArray = [...questionSet];
      newArray[currentQuestionIndex].answer = value?value:newArray[currentQuestionIndex].answer == true ? false : true;
      SetQuestionSet(newArray); 
      formData.set("questionSet",JSON.stringify(newArray))
      UpdateUserInfo();
     }catch(error){
     }
  }




  //API method started

  const GetUserInfo=async ()=>{
    let userInfo=await getUserInfo(user._id,token,user._id);
    if(!userInfo.error){

      if(userInfo.medicalHealthDetails && userInfo.medicalHealthDetails.questionSet.length){
        let updatedList=await Promise.all(userInfo.medicalHealthDetails.questionSet.map(data=>{
          return {
            question: data.question,
            answer: data.answer,
            type: data.type
          }
        }));
        SetQuestionSet(updatedList)
      }


      if(userInfo.medicalHealthDetails.medicalReportDocumentId){
        setMedicalReportDocument({
          name:userInfo.medicalHealthDetails.medicalReportDocumentName,
          medicalReportDocumentId:userInfo.medicalHealthDetails.medicalReportDocumentId
        })
      }
    }
  }

  const UpdateUserInfo=()=>{
    try{
      updateUserProfile(user._id,token,formData).then(response=>{
        if(response.error){
          // handle error
        }else{
          formData.forEach(data=>{
            formData.delete(data.name)
          })
        }
      }).catch(error=>{
        // do something
        console.error(error)
      })
    }catch(Error){
      // do something
    }
  }

  //end of API method 

  useEffect(async ()=>{
   try{
    await startLoader();
    await GetUserInfo()
    await stopLoader();
   }catch(error){
    console.error(error)
    await stopLoader()
   }
  },[]);



  return(
    <div className='mob-modal-scroll-container'>
      {
        questionSet.map((data, index) => {
          if(data.type == 1){
            return(
              <div className='medical-health-question-container d-flex-auto'>
                <div 
                  className='d-flex question-container'>
                  <div className='question-number d-flex m-r-8'>
                    <p className='flex-item'>{index + 1}</p>
                  </div>
                  <p className='question-txt'>{data.question}</p>
                </div>
                <div  onClick={handleQuestionResponse.bind(this, index,'')}  className='member-toggle-button flex-item d-flex m-t-4' style={{alignSelf: "flex-start", outline: data.answer == false ? "" : "0.5px solid #055075"}}>
                  <div style={{marginLeft: data.answer == false ? "" : "18px", background: data.answer == false ? "" : "#055075"}} className='toggle-button flex-item'/>
                </div>
              </div>
            )
          }else if(data.type == 3 && user.gender && user.gender.toLowerCase() == "female"){
            return(
              <div className='medical-health-question-container d-flex-auto'>
              <div 
                className='d-flex question-container'>
                <div className='question-number d-flex m-r-8'>
                  <p className='flex-item'>{index + 1}</p>
                </div>
                <p className='question-txt'>{data.question}</p>
              </div>
              <div  onClick={handleQuestionResponse.bind(this, index,'')}  className='member-toggle-button flex-item d-flex m-t-4' style={{alignSelf: "flex-start", outline: data.answer == false ? "" : "0.5px solid #055075"}}>
                <div style={{marginLeft: data.answer == false ? "" : "18px", background: data.answer == false ? "" : "#055075"}} className='toggle-button flex-item'/>
              </div>
            </div>
            )
          } else if(data.type == 2){
            return(
              <div className='medical-health-question-container'>
                <div 
                  className='d-flex question-container'>
                  <div className='question-number d-flex m-r-8'>
                    <p className='flex-item'>{index + 1}</p>
                  </div>
                  <div style={{width: "90%"}}>
                    <p className='question-txt'>{data.question}</p>
                    <div className="d-flex-wrap m-t-16">
                      <div  onClick={handleQuestionResponse.bind(this, index,'No')} className={`global-button-container ${data.answer == 'No' && 'global-button-container-active'} m-r-12 m-b-12`}>
                        <p>No</p>
                      </div>
                      <div onClick={handleQuestionResponse.bind(this, index,'Yes, Occasionally')} className={`global-button-container ${data.answer == 'Yes, Occasionally' && 'global-button-container-active'} m-r-12 m-b-12`}>
                        <p>Yes, Occasionally</p>
                      </div>
                      <div onClick={handleQuestionResponse.bind(this, index,'Yes, Regularly')} className={`global-button-container ${data.answer == 'Yes, Regularly' && 'global-button-container-active'} m-r-12 m-b-12`}>
                        <p>Yes, Regularly</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })
      }

      <div className='dashboard-data-saperator'></div>
      
      {
        medicalReportDocument ? (
          <div className="flex-item">
            <p>Medical Report</p>
            <div className="d-flex w-full space-between m-t-8">
              <div className="flex-item d-flex" style={{width:"90%"}}>
                <svg className="flex-item" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.106 20.35C14.106 19.59 13.578 19.136 12.647 19.136C12.267 19.136 12.01 19.173 11.875 19.209V21.65C12.035 21.686 12.231 21.699 12.5 21.699C13.493 21.699 14.106 21.196 14.106 20.35ZM19.872 19.16C19.455 19.16 19.185 19.197 19.025 19.234V24.642C19.185 24.679 19.442 24.679 19.675 24.679C21.367 24.691 22.471 23.759 22.471 21.785C22.484 20.068 21.478 19.16 19.872 19.16Z" fill="black"/>
                  <path d="M30.918 15.983H30.24V12.712C30.2394 12.6912 30.2374 12.6705 30.234 12.65C30.2351 12.5184 30.1886 12.3909 30.103 12.291L24.66 6.07499L24.656 6.07099C24.5869 5.99598 24.4988 5.94104 24.401 5.91199L24.371 5.90199C24.3295 5.89163 24.2868 5.88626 24.244 5.88599H10.867C10.256 5.88599 9.76 6.38299 9.76 6.99299V15.983H9.082C8.208 15.983 7.5 16.691 7.5 17.565V25.793C7.5 26.666 8.209 27.375 9.082 27.375H9.76V33.008C9.76 33.618 10.256 34.115 10.867 34.115H29.133C29.743 34.115 30.24 33.618 30.24 33.008V27.375H30.918C31.792 27.375 32.5 26.666 32.5 25.793V17.565C32.5 16.691 31.791 15.983 30.918 15.983ZM10.867 6.99299H23.69V12.657C23.69 12.963 23.938 13.21 24.244 13.21H29.134V15.983H10.867V6.99299ZM24.457 21.723C24.457 23.305 23.881 24.397 23.082 25.071C22.212 25.794 20.888 26.138 19.269 26.138C18.3 26.138 17.614 26.076 17.147 26.016V17.897C17.834 17.787 18.729 17.726 19.674 17.726C21.243 17.726 22.261 18.008 23.058 18.609C23.917 19.246 24.457 20.264 24.457 21.723ZM10.021 26.053V17.897C10.597 17.799 11.407 17.726 12.547 17.726C13.7 17.726 14.522 17.947 15.073 18.388C15.6 18.805 15.955 19.492 15.955 20.301C15.955 21.111 15.686 21.797 15.195 22.263C14.557 22.864 13.613 23.134 12.509 23.134C12.264 23.134 12.043 23.122 11.872 23.097V26.053H10.021ZM29.133 32.708H10.867V27.375H29.133V32.708ZM30.737 19.32H27.56V21.209H30.528V22.73H27.56V26.053H25.685V17.787H30.737V19.32Z" fill="black"/>
                </svg>
                <p className="flex-item" accept="application/pdf" style={{width:"70%",wordBreak:'break-all'}}>{medicalReportDocument.name}</p>
              </div>
              <div className="d-flex flex-item">
                <div onClick={clearMedicalReportDocument} className="flex-item m-r-12">
                  <svg width="13" height="13" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4695 10.0041L19.7595 1.71409C19.9234 1.52279 20.009 1.27671 19.9993 1.02504C19.9895 0.773362 19.8852 0.534623 19.7071 0.356529C19.529 0.178434 19.2903 0.0741013 19.0386 0.0643802C18.7869 0.054659 18.5408 0.140266 18.3495 0.304092L10.0595 8.59409L1.76954 0.294092C1.58124 0.105788 1.32585 0 1.05954 0C0.793242 0 0.537847 0.105788 0.349543 0.294092C0.16124 0.482395 0.0554518 0.73779 0.0554518 1.00409C0.0554518 1.27039 0.16124 1.52579 0.349543 1.71409L8.64954 10.0041L0.349543 18.2941C0.244862 18.3837 0.159841 18.4941 0.0998181 18.6181C0.0397948 18.7422 0.00606468 18.8773 0.000745191 19.015C-0.0045743 19.1528 0.0186312 19.2901 0.0689057 19.4184C0.11918 19.5467 0.195439 19.6633 0.292893 19.7607C0.390348 19.8582 0.506895 19.9345 0.63522 19.9847C0.763546 20.035 0.900878 20.0582 1.0386 20.0529C1.17632 20.0476 1.31145 20.0138 1.43551 19.9538C1.55958 19.8938 1.6699 19.8088 1.75954 19.7041L10.0595 11.4141L18.3495 19.7041C18.5408 19.8679 18.7869 19.9535 19.0386 19.9438C19.2903 19.9341 19.529 19.8298 19.7071 19.6517C19.8852 19.4736 19.9895 19.2348 19.9993 18.9831C20.009 18.7315 19.9234 18.4854 19.7595 18.2941L11.4695 10.0041Z" fill="#055075"/>
                  </svg>
                </div>
                <div className="flex-item"  style={{position:'relative'}}>
                  <input onInput={handleMedicalReportDocument} type='file' style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',zIndex:4,cursor:'grab'}}/>
                  <svg className="flex-item" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.6448 0.597863C8.82628 0.410586 9.0432 0.261285 9.28294 0.158654C9.52267 0.0560221 9.78043 0.0021115 10.0412 6.07932e-05C10.302 -0.00198992 10.5606 0.0478603 10.8019 0.146709C11.0432 0.245557 11.2624 0.391428 11.4468 0.575828C11.6312 0.760228 11.7771 0.979471 11.876 1.22079C11.9748 1.46211 12.0247 1.72069 12.0226 1.98146C12.0206 2.24223 11.9666 2.49999 11.864 2.73973C11.7614 2.97946 11.6121 3.19639 11.4248 3.37786L10.6548 4.14786L7.8748 1.36786L8.6448 0.597863ZM7.3448 1.89786L1.2513 7.99136C1.0313 8.21136 0.871302 8.48436 0.787302 8.78436L0.0138021 11.5469C-0.00406937 11.6109 -0.00459458 11.6785 0.0122802 11.7428C0.0291551 11.8072 0.0628232 11.8658 0.109835 11.9128C0.156847 11.9598 0.215512 11.9935 0.279819 12.0104C0.344127 12.0273 0.411764 12.0267 0.475802 12.0089L3.2378 11.2354C3.53777 11.1514 3.81105 10.9916 4.0313 10.7714L10.1253 4.67836L7.3453 1.89836L7.3448 1.89786Z" fill="#055075"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-item">
            <p className="fs-16" style={{fontWeight:"600"}}>Upload your medical report here</p>
            <div className="m-t-8" style={{width:'fit-content',position:'relative'}}>
              <input onInput={handleMedicalReportDocument} type='file' style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',zIndex:4,cursor:'grab'}}/>
              <div className="global-button-container d-flex flex-item">
                <svg className="flex-item m-r-12" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5 15.577V6.927L9.17 9.257L8.462 8.538L12 5L15.538 8.538L14.831 9.258L12.5 6.927V15.577H11.5ZM6.615 19C6.155 19 5.771 18.846 5.463 18.538C5.15433 18.2293 5 17.845 5 17.385V14.962H6V17.385C6 17.5383 6.064 17.6793 6.192 17.808C6.32067 17.936 6.46167 18 6.615 18H17.385C17.5383 18 17.6793 17.936 17.808 17.808C17.936 17.6793 18 17.5383 18 17.385V14.962H19V17.385C19 17.845 18.846 18.229 18.538 18.537C18.2293 18.8457 17.845 19 17.385 19H6.615Z" fill="black"/>
                </svg>
                <p className="flex-item">Medical Report</p>
              </div>
            </div>                          
          </div>
        )
      }
    </div>
  )
}