import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createGym, updateGym } from "../../../gym/helper/api";
import ModalBanner from '../../../assets/modal-banner-1.png';
import { isAuthenticated } from "../../../auth";
import { startLoader, stopLoader } from "../../../lib/utils";
import UserProfile from '../../../assets/sample-profile.png';
import config from "../../../config";
import { ToastPropObject, ToastTime, ToastType } from "../../ToastMessage";
import Toast from '../../../lib/toast';

const ManageGymForm = (props) => {
  const history = useHistory();
  const [isButtonLock, setButtonLock] = useState(false);
  const [workoutGymTypeOpenDropdown, setWorkoutGymTypeOpenDropdown] = useState(false);
  const [workoutHistoryStartDateActive, setWorkoutHistoryStartDateActive] = useState(false);
  const [currentElementId, setCurrentElementId] = useState("");
  const [Gym, setGym] = useState({
    gymId: "",
    name: "",
    email: "",
    optional_email: "",
    phone: "",
    optional_phone: "",
    address: "",
    type: "",
    ownerFirstname: "",
    ownerLastname: "",
    ownerEmail: "",
    ownerphone: "",
    managerFirstname: "",
    managerLastname: "",
    managerEmail: "",
    managerPhone: "",
    gymphoto: "",
    gymFormData: new FormData()
  });
  const { gymFormData } = Gym;
  const [gymProfileImage, setGymProfileImage] = useState(null);

  const getSelectedGymType = (gymType) => {
    if (gymType) {
      setGym({
        ...Gym,
        type: gymType
      })
      gymFormData.set('type', `${gymType}`.toLocaleLowerCase())
      setWorkoutGymTypeOpenDropdown(false);
      checkGymDataField()
    }
  }

  const checkGymDataField = () => {
    if (Gym.name && Gym.email && Gym.phone && Gym.type && Gym.address) {
      setButtonLock(false)
    } else {
      setButtonLock(true);
    }
  }


  const handleChangeGym = (keyName, value = null) => async event => {
    event.preventDefault()
    value = !value ? event.target.value : value;
    await setGym(oldstate => {
      oldstate[keyName] = value;
      gymFormData.set(keyName, value);
      return { ...oldstate };
    });
    checkGymDataField()
    event.stopPropagation();
  }

  const handleGymProfileImage = async event => {
    if (event.target.files[0]) {
      setGymProfileImage(event.target.files[0]);
      gymFormData.set("photo", event.target.files[0]);
      let gymphoto = URL.createObjectURL(event.target.files[0]).toString();
      setGym({
        ...Gym,
        gymphoto
      })
    }
  }

  const { user, token } = isAuthenticated();

  const addGym = async event => {
    try {
      event.preventDefault();
      await startLoader();
      setButtonLock(true);
      await createGym(user._id, token, gymFormData);
      props.toggleGymModal();
      await stopLoader()
      setButtonLock(false);
      history.push(`/admin/gym`);
      let toast={...ToastPropObject};
      toast.type=ToastType.success;
      toast.message="Gym created successfully";
      toast.duration=ToastTime.SHORT;
      new Toast(toast);
      event.stopPropagation()
    } catch (error) {
      // do something
      let toast={...ToastPropObject};
      toast.type=ToastType.error;
      toast.message="Failed to create gym";
      toast.duration=ToastTime.SHORT;
      new Toast(toast);
      await stopLoader()
      event.stopPropagation()
    }
  }

  const editGym=async event=>{
    try{
      event.preventDefault();
      await startLoader();
      setButtonLock(true);
      await updateGym(user._id,token,Gym.gymId,gymFormData);
      props.toggleGymModal();
      await stopLoader()
      setButtonLock(false);
      history.push(`/admin/gym`);
      let toast={...ToastPropObject};
      toast.type=ToastType.success;
      toast.message="Gym updated successfully";
      toast.duration=ToastTime.SHORT;
      new Toast(toast);
      event.stopPropagation();
    }catch(error){
      // do something
      let toast={...ToastPropObject};
      toast.type=ToastType.error;
      toast.message="Failed to update gym";
      toast.duration=ToastTime.SHORT;
      props.showToast(toast);
      await stopLoader();
    }
  }


  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (id == 'workout-gym-type') setWorkoutGymTypeOpenDropdown(!workoutGymTypeOpenDropdown);
    if (event) {
      let interval = setInterval(() => {
        let element = event.target.querySelector('input');
        if (element) {
          element.focus();
          clearInterval(interval)
        }
      }, 0);
    }
  }


  useEffect(() => {

    window.addEventListener("click", () => {
      setCurrentElementId('');
    })

    if (props.isEdit) {
      if (props.gym) {
        setGym(oldstate => {
          oldstate.name = props.gym.name;
          oldstate.phone = props.gym.phone;
          oldstate.email = props.gym.email;
          oldstate.gymId=props.gym._id;
          oldstate.address=props.gym.address;
          oldstate.optional_email = props.gym.optional_email;
          oldstate.optional_phone = props.gym.optional_phone;
          if (props.gym && props.gym.logoFileID) {
            oldstate.gymphoto = config.API_URL+"/image/file/" + props.gym.logoFileID + "/auto/auto/70";
          }
          oldstate.type = props.gym.type;
          return { ...oldstate };
        })
      }
    } else {
      setGym({
        ...Gym,
        gymId: "",
        name: "",
        email: "",
        optional_email: "",
        phone: "",
        optional_phone: "",
        address: '',
        type: "",
        ownerFirstname: "",
        ownerLastname: "",
        ownerEmail: "",
        ownerphone: "",
        managerFirstname: "",
        managerLastname: "",
        managerEmail: "",
        managerPhone: "",
        gymphoto: "",
      })
    }
    checkGymDataField();
  }, [props])

  return(
    <div className="mobile-bottom-modal-container"  onClick={props.toggleGymModal}>
      <div id="mobile-video-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
        <div onClick={props.toggleGymModal} className="w-full">
          <div className="draggable-pointer" />
        </div>
        <p className="flex-item modal-header-text">{props.isEdit?"Edit":"Add"} Gym</p>
        <div onClick={e => e.stopPropagation()} className="global-modal-scroll-container-mob">
          <div className="d-flex space-between w-full m-b-20">
            <div onClick={toggleInputFields('gym-name')} className={`${(currentElementId == 'gym-name' || Gym.name) ? "member-global-input-field flex-item" : "member-global-input-field flex-item d-flex"}`} style={{ border: (currentElementId == 'gym-name' || Gym.name) ? "1px solid #055075" : "", width: "76%" }}>
              <div className="flex-item d-flex">
                <svg className="flex-item m-r-8" width={(currentElementId == 'gym-name' || Gym.name) ? "18" : "18"} height={(currentElementId == 'gym-name' || Gym.name) ? "18" : "18"} style={{ transition: "180ms" }} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.73333 1H3.1C3.00717 1 2.91815 1.03687 2.85251 1.10251C2.78687 1.16815 2.75 1.25717 2.75 1.35V6.48333C2.75 6.5293 2.75905 6.57481 2.77664 6.61727C2.79423 6.65974 2.82001 6.69832 2.85251 6.73082C2.88501 6.76332 2.9236 6.7891 2.96606 6.80669C3.00852 6.82428 3.05404 6.83333 3.1 6.83333H4.73333C4.7793 6.83333 4.82481 6.82428 4.86727 6.80669C4.90974 6.7891 4.94832 6.76332 4.98082 6.73082C5.01332 6.69832 5.0391 6.65974 5.05669 6.61727C5.07428 6.57481 5.08333 6.5293 5.08333 6.48333V1.35C5.08333 1.25717 5.04646 1.16815 4.98082 1.10251C4.91518 1.03687 4.82616 1 4.73333 1V1ZM11.7333 1H10.1C10.0072 1 9.91815 1.03687 9.85251 1.10251C9.78687 1.16815 9.75 1.25717 9.75 1.35V6.48333C9.75 6.5293 9.75905 6.57481 9.77664 6.61727C9.79423 6.65974 9.82001 6.69832 9.85251 6.73082C9.88501 6.76332 9.9236 6.7891 9.96606 6.80669C10.0085 6.82428 10.054 6.83333 10.1 6.83333H11.7333C11.7793 6.83333 11.8248 6.82428 11.8673 6.80669C11.9097 6.7891 11.9483 6.76332 11.9808 6.73082C12.0133 6.69832 12.0391 6.65974 12.0567 6.61727C12.0743 6.57481 12.0833 6.5293 12.0833 6.48333V1.35C12.0833 1.25717 12.0465 1.16815 11.9808 1.10251C11.9152 1.03687 11.8262 1 11.7333 1V1Z" stroke={(currentElementId == 'gym-name' || Gym.name) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.08333 3.91675H9.75M1 5.31675V2.51675C1 2.42392 1.03687 2.3349 1.10251 2.26926C1.16815 2.20362 1.25717 2.16675 1.35 2.16675H2.4C2.49283 2.16675 2.58185 2.20362 2.64749 2.26926C2.71313 2.3349 2.75 2.42392 2.75 2.51675V5.31675C2.75 5.40957 2.71313 5.4986 2.64749 5.56424C2.58185 5.62987 2.49283 5.66675 2.4 5.66675H1.35C1.25717 5.66675 1.16815 5.62987 1.10251 5.56424C1.03687 5.4986 1 5.40957 1 5.31675V5.31675ZM13.8333 5.31675V2.51675C13.8333 2.42392 13.7965 2.3349 13.7308 2.26926C13.6652 2.20362 13.5762 2.16675 13.4833 2.16675H12.4333C12.3405 2.16675 12.2515 2.20362 12.1858 2.26926C12.1202 2.3349 12.0833 2.42392 12.0833 2.51675V5.31675C12.0833 5.40957 12.1202 5.4986 12.1858 5.56424C12.2515 5.62987 12.3405 5.66675 12.4333 5.66675H13.4833C13.5762 5.66675 13.6652 5.62987 13.7308 5.56424C13.7965 5.4986 13.8333 5.40957 13.8333 5.31675V5.31675Z" stroke={(currentElementId == 'gym-name' || Gym.name) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'gym-name' || Gym.name) ? "10px" : "14px", color: (currentElementId == 'gym-name' || Gym.name) ? "#055075" : "", transition: "180ms" }}>Gym Name*</p>
              </div>
              <input value={Gym.name} onChange={handleChangeGym('name')} className={`${(currentElementId == 'gym-name' || Gym.name) ? "active-input-field" : "inactive-input-field"}`} type="text" />
            </div>
            {/* <p>{gymProfileImage && gymProfileImage.value}</p> */}
            <div className="flex-item gym-logo-viewer d-flex" style={{ cursor: 'pointer', justifyContent: 'center' }}>
              <div className="flex-item gym-input-icon d-flex">
                <svg className="flex-item" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 6.44444C4.5 6.44444 4.92507 6.25007 5.2752 5.86133C5.62507 5.4723 5.8 5 5.8 4.44444C5.8 3.88889 5.62507 3.41659 5.2752 3.02756C4.92507 2.63881 4.5 2.44444 4 2.44444C3.5 2.44444 3.07493 2.63881 2.7248 3.02756C2.37493 3.41659 2.2 3.88889 2.2 4.44444C2.2 5 2.37493 5.4723 2.7248 5.86133C3.07493 6.25007 3.5 6.44444 4 6.44444ZM0.8 8C0.58 8 0.391733 7.91304 0.2352 7.73911C0.0784 7.56489 0 7.35556 0 7.11111V1.77778C0 1.53333 0.0784 1.32415 0.2352 1.15022C0.391733 0.976 0.58 0.888889 0.8 0.888889H2.06L2.56 0.288889C2.63333 0.192593 2.7216 0.120296 2.8248 0.072C2.92827 0.024 3.03667 0 3.15 0H4.85C4.96333 0 5.07173 0.024 5.1752 0.072C5.2784 0.120296 5.36667 0.192593 5.44 0.288889L5.94 0.888889H7.2C7.42 0.888889 7.6084 0.976 7.7652 1.15022C7.92173 1.32415 8 1.53333 8 1.77778V7.11111C8 7.35556 7.92173 7.56489 7.7652 7.73911C7.6084 7.91304 7.42 8 7.2 8H0.8Z" fill="white" />
                </svg>
              </div>
              {(!Gym.gymphoto && Gym.name) && <p className="flex-item" style={{
                color: '#4d4d4d'
              }}>{`${Gym.name}`.trim()[0]}</p>}
              {Gym.gymphoto && <img style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '100%' }} src={Gym.gymphoto} />}
              <input onInput={handleGymProfileImage} type="file" name="file" accept=".png, .jpg, .jpeg" className="image-input-field" />
            </div>
          </div>
          <div onClick={toggleInputFields('workout-gym-type')} className={`${(currentElementId == "workout-gym-type" || Gym.type) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == "workout-gym-type" || Gym.type) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == "workout-gym-type" || Gym.type) ? "18" : "18"} height={(currentElementId == "workout-gym-type" || Gym.type) ? "18" : "18"} style={{ transition: "180ms" }} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.73333 1H3.1C3.00717 1 2.91815 1.03687 2.85251 1.10251C2.78687 1.16815 2.75 1.25717 2.75 1.35V6.48333C2.75 6.5293 2.75905 6.57481 2.77664 6.61727C2.79423 6.65974 2.82001 6.69832 2.85251 6.73082C2.88501 6.76332 2.9236 6.7891 2.96606 6.80669C3.00852 6.82428 3.05404 6.83333 3.1 6.83333H4.73333C4.7793 6.83333 4.82481 6.82428 4.86727 6.80669C4.90974 6.7891 4.94832 6.76332 4.98082 6.73082C5.01332 6.69832 5.0391 6.65974 5.05669 6.61727C5.07428 6.57481 5.08333 6.5293 5.08333 6.48333V1.35C5.08333 1.25717 5.04646 1.16815 4.98082 1.10251C4.91518 1.03687 4.82616 1 4.73333 1V1ZM11.7333 1H10.1C10.0072 1 9.91815 1.03687 9.85251 1.10251C9.78687 1.16815 9.75 1.25717 9.75 1.35V6.48333C9.75 6.5293 9.75905 6.57481 9.77664 6.61727C9.79423 6.65974 9.82001 6.69832 9.85251 6.73082C9.88501 6.76332 9.9236 6.7891 9.96606 6.80669C10.0085 6.82428 10.054 6.83333 10.1 6.83333H11.7333C11.7793 6.83333 11.8248 6.82428 11.8673 6.80669C11.9097 6.7891 11.9483 6.76332 11.9808 6.73082C12.0133 6.69832 12.0391 6.65974 12.0567 6.61727C12.0743 6.57481 12.0833 6.5293 12.0833 6.48333V1.35C12.0833 1.25717 12.0465 1.16815 11.9808 1.10251C11.9152 1.03687 11.8262 1 11.7333 1V1Z" stroke={(currentElementId == "workout-gym-type" || Gym.type) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.08333 3.91675H9.75M1 5.31675V2.51675C1 2.42392 1.03687 2.3349 1.10251 2.26926C1.16815 2.20362 1.25717 2.16675 1.35 2.16675H2.4C2.49283 2.16675 2.58185 2.20362 2.64749 2.26926C2.71313 2.3349 2.75 2.42392 2.75 2.51675V5.31675C2.75 5.40957 2.71313 5.4986 2.64749 5.56424C2.58185 5.62987 2.49283 5.66675 2.4 5.66675H1.35C1.25717 5.66675 1.16815 5.62987 1.10251 5.56424C1.03687 5.4986 1 5.40957 1 5.31675V5.31675ZM13.8333 5.31675V2.51675C13.8333 2.42392 13.7965 2.3349 13.7308 2.26926C13.6652 2.20362 13.5762 2.16675 13.4833 2.16675H12.4333C12.3405 2.16675 12.2515 2.20362 12.1858 2.26926C12.1202 2.3349 12.0833 2.42392 12.0833 2.51675V5.31675C12.0833 5.40957 12.1202 5.4986 12.1858 5.56424C12.2515 5.62987 12.3405 5.66675 12.4333 5.66675H13.4833C13.5762 5.66675 13.6652 5.62987 13.7308 5.56424C13.7965 5.4986 13.8333 5.40957 13.8333 5.31675V5.31675Z" stroke={(currentElementId == "workout-gym-type" || Gym.type) ? "#055075" : "#6d6d6d"} stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "workout-gym-type" || Gym.type) ? "10px" : "14px", color: (currentElementId == "workout-gym-type" || Gym.type) ? "#055075" : "", transition: "180ms" }}>Gym Type*</p>
            </div>
            <div className='input-field-arrow-dropdown' style={{ margin: "12px 8px 4px 0", transition: "180ms" }}>
              <svg className="flex-item" style={{ transform: workoutGymTypeOpenDropdown ? "rotate(180deg)" : "", transition: "180ms" }} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round" />
              </svg>
            </div>
            <input id="workout-gym-type" className={`${(currentElementId == "workout-gym-type" || Gym.type) ? "active-input-field" : "inactive-input-field"}`} type="text" placeholder="Select Gym Type" value={Gym.type} style={{ pointerEvents: "none",textTransform:'capitalize' }} />
            {/* Dropdown Container */}
            {
              workoutGymTypeOpenDropdown && (
                <div className="global-input-field-arrow-dropdown global-input-field-arrow-dropdown-height-1">
                  <div onClick={getSelectedGymType.bind(this, "Commercial")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Commercial</p>
                  </div>
                  <div onClick={getSelectedGymType.bind(this, "Residential")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Residential</p>
                  </div>
                  <div onClick={getSelectedGymType.bind(this, "Corporate")} className="global-input-field-arrow-dropdown-item">
                    <p className="flex-item">Corporate</p>
                  </div>
                </div>
              )
            }
          </div>
          <div onClick={toggleInputFields('gym-email')} className={`${(currentElementId == "gym-email" || Gym.email) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == "gym-email" || Gym.email) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={"18"} height={"18"} style={{ transition: "180ms" }} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 0.25H1.5C1.30109 0.25 1.11032 0.329018 0.96967 0.46967C0.829018 0.610322 0.75 0.801088 0.75 1V7C0.75 7.19891 0.829018 7.38968 0.96967 7.53033C1.11032 7.67098 1.30109 7.75 1.5 7.75H10.5C10.6989 7.75 10.8897 7.67098 11.0303 7.53033C11.171 7.38968 11.25 7.19891 11.25 7V1C11.25 0.801088 11.171 0.610322 11.0303 0.46967C10.8897 0.329018 10.6989 0.25 10.5 0.25ZM9.675 1L6 3.5425L2.325 1H9.675ZM1.5 7V1.34125L5.78625 4.3075C5.84902 4.35105 5.9236 4.37438 6 4.37438C6.0764 4.37438 6.15098 4.35105 6.21375 4.3075L10.5 1.34125V7H1.5Z" fill={(currentElementId == "gym-email" || Gym.email) ? "#055075" : "#6d6d6d"} />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "gym-email" || Gym.email) ? "10px" : "14px", color: (currentElementId == "gym-email" || Gym.email) ? "#055075" : "", transition: "180ms" }}>Email*</p>
            </div>
            <input id="gym-emal" value={Gym.email} onChange={handleChangeGym("email")} className={`${(currentElementId == "gym-email" || Gym.email) ? "active-input-field" : "inactive-input-field"}`} type="email" />
          </div>
          <div onClick={toggleInputFields('gym-optional_email')} className={`${(currentElementId == "gym-optional_email" || Gym.optional_email) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == "gym-optional_email" || Gym.optional_email) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == "gym-optional_email" || Gym.optional_email) ? "12" : "18"} height={(currentElementId == "gym-optional_email" || Gym.optional_email) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 0.25H1.5C1.30109 0.25 1.11032 0.329018 0.96967 0.46967C0.829018 0.610322 0.75 0.801088 0.75 1V7C0.75 7.19891 0.829018 7.38968 0.96967 7.53033C1.11032 7.67098 1.30109 7.75 1.5 7.75H10.5C10.6989 7.75 10.8897 7.67098 11.0303 7.53033C11.171 7.38968 11.25 7.19891 11.25 7V1C11.25 0.801088 11.171 0.610322 11.0303 0.46967C10.8897 0.329018 10.6989 0.25 10.5 0.25ZM9.675 1L6 3.5425L2.325 1H9.675ZM1.5 7V1.34125L5.78625 4.3075C5.84902 4.35105 5.9236 4.37438 6 4.37438C6.0764 4.37438 6.15098 4.35105 6.21375 4.3075L10.5 1.34125V7H1.5Z" fill={(currentElementId == "gym-optional_email" || Gym.optional_email) ? "#055075" : "#6d6d6d"} />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "gym-optional_email" || Gym.optional_email) ? "10px" : "14px", color: (currentElementId == "gym-optional_email" || Gym.optional_email) ? "#055075" : "", transition: "180ms" }}>Optional Email</p>
            </div>
            <input id="gym-optional_email" onChange={handleChangeGym('optional_email')} value={Gym.optional_email} className={`${(currentElementId == "gym-optional_email" || Gym.optional_email) ? "active-input-field" : "inactive-input-field"}`} type="email" />
          </div>
          <div onClick={toggleInputFields('gym-phone')} className={`${(currentElementId == "gym-phone" || Gym.phone) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == "gym-phone" || Gym.phone) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == "gym-phone" || Gym.phone) ? "12" : "18"} height={(currentElementId == "gym-phone" || Gym.phone) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.74967 9.875H8.68592C1.31717 9.45125 0.270916 3.23375 0.124666 1.33625C0.112881 1.18872 0.130324 1.04031 0.175994 0.899529C0.221664 0.758749 0.294663 0.628364 0.39081 0.515845C0.486956 0.403325 0.60436 0.310883 0.736295 0.243815C0.868229 0.176747 1.0121 0.136371 1.15967 0.125H3.22592C3.37612 0.124855 3.52292 0.169816 3.64728 0.254061C3.77164 0.338306 3.86784 0.457951 3.92342 0.5975L4.49342 2C4.5483 2.13633 4.56192 2.28578 4.53258 2.42978C4.50325 2.57379 4.43225 2.706 4.32842 2.81L3.52967 3.61625C3.65443 4.32528 3.99398 4.97888 4.50238 5.48861C5.01077 5.99834 5.66347 6.33961 6.37217 6.46625L7.18592 5.66C7.29148 5.55731 7.42494 5.48797 7.56965 5.46063C7.71436 5.43329 7.86391 5.44915 7.99967 5.50625L9.41342 6.0725C9.55086 6.12983 9.66813 6.22678 9.75027 6.351C9.83241 6.47522 9.87572 6.62108 9.87467 6.77V8.75C9.87467 9.04837 9.75614 9.33452 9.54516 9.5455C9.33418 9.75647 9.04803 9.875 8.74967 9.875ZM1.24967 0.875C1.15021 0.875 1.05483 0.914509 0.984501 0.984835C0.914174 1.05516 0.874666 1.15054 0.874666 1.25V1.28C1.04717 3.5 2.15342 8.75 8.72717 9.125C8.77643 9.12805 8.82581 9.12133 8.87248 9.10525C8.91914 9.08917 8.96218 9.06403 8.99911 9.03128C9.03604 8.99854 9.06614 8.95882 9.08768 8.91441C9.10923 8.87 9.1218 8.82178 9.12467 8.7725V6.77L7.71092 6.20375L6.63467 7.2725L6.45467 7.25C3.19217 6.84125 2.74967 3.57875 2.74967 3.545L2.72717 3.365L3.79217 2.28875L3.22967 0.875H1.24967Z" fill={(currentElementId == "gym-phone" || Gym.phone) ? "#055075" : "#6d6d6d"} />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "gym-phone" || Gym.phone) ? "10px" : "14px", color: (currentElementId == "gym-phone" || Gym.phone) ? "#055075" : "", transition: "180ms" }}>Phone*</p>
            </div>
            <input id="gym-phone" value={Gym.phone} onChange={handleChangeGym("phone")} className={`${(currentElementId == "gym-phone" || Gym.phone) ? "active-input-field" : "inactive-input-field"}`} type="number" />
          </div>
          <div onClick={toggleInputFields('gym-optional_phone')} className={`${(currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "12" : "18"} height={(currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.74967 9.875H8.68592C1.31717 9.45125 0.270916 3.23375 0.124666 1.33625C0.112881 1.18872 0.130324 1.04031 0.175994 0.899529C0.221664 0.758749 0.294663 0.628364 0.39081 0.515845C0.486956 0.403325 0.60436 0.310883 0.736295 0.243815C0.868229 0.176747 1.0121 0.136371 1.15967 0.125H3.22592C3.37612 0.124855 3.52292 0.169816 3.64728 0.254061C3.77164 0.338306 3.86784 0.457951 3.92342 0.5975L4.49342 2C4.5483 2.13633 4.56192 2.28578 4.53258 2.42978C4.50325 2.57379 4.43225 2.706 4.32842 2.81L3.52967 3.61625C3.65443 4.32528 3.99398 4.97888 4.50238 5.48861C5.01077 5.99834 5.66347 6.33961 6.37217 6.46625L7.18592 5.66C7.29148 5.55731 7.42494 5.48797 7.56965 5.46063C7.71436 5.43329 7.86391 5.44915 7.99967 5.50625L9.41342 6.0725C9.55086 6.12983 9.66813 6.22678 9.75027 6.351C9.83241 6.47522 9.87572 6.62108 9.87467 6.77V8.75C9.87467 9.04837 9.75614 9.33452 9.54516 9.5455C9.33418 9.75647 9.04803 9.875 8.74967 9.875ZM1.24967 0.875C1.15021 0.875 1.05483 0.914509 0.984501 0.984835C0.914174 1.05516 0.874666 1.15054 0.874666 1.25V1.28C1.04717 3.5 2.15342 8.75 8.72717 9.125C8.77643 9.12805 8.82581 9.12133 8.87248 9.10525C8.91914 9.08917 8.96218 9.06403 8.99911 9.03128C9.03604 8.99854 9.06614 8.95882 9.08768 8.91441C9.10923 8.87 9.1218 8.82178 9.12467 8.7725V6.77L7.71092 6.20375L6.63467 7.2725L6.45467 7.25C3.19217 6.84125 2.74967 3.57875 2.74967 3.545L2.72717 3.365L3.79217 2.28875L3.22967 0.875H1.24967Z" fill={(currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "#055075" : "#6d6d6d"} />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "10px" : "14px", color: (currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "#055075" : "", transition: "180ms" }}>Optional Phone</p>
            </div>
            <input id="gym-optional_phone" value={Gym.optional_phone} onChange={handleChangeGym('optional_phone')} className={`${(currentElementId == "gym-optional_phone" || Gym.optional_phone) ? "active-input-field" : "inactive-input-field"}`} type="number" />
          </div>
          <div onClick={toggleInputFields('gym-address')} className={`${(currentElementId == "gym-address" || Gym.address) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field m-b-20 flex-item d-flex"}`} style={{ border: (currentElementId == "gym-address" || Gym.address) ? "1px solid #055075" : "" }}>
            <div className="flex-item d-flex">
              <svg className="flex-item m-r-8" width={(currentElementId == "gym-address" || Gym.address) ? "12" : "18"} height={(currentElementId == "gym-address" || Gym.address) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 0C1.565 0 0 1.565 0 3.5C0 6.125 3.5 10 3.5 10C3.5 10 7 6.125 7 3.5C7 1.565 5.435 0 3.5 0ZM1 3.5C1 2.12 2.12 1 3.5 1C4.88 1 6 2.12 6 3.5C6 4.94 4.56 7.095 3.5 8.44C2.46 7.105 1 4.925 1 3.5Z" fill={(currentElementId == "gym-address" || Gym.address) ? "#055075" : "#6d6d6d"} />
                <path d="M3.5 4C3.77614 4 4 3.77614 4 3.5C4 3.22386 3.77614 3 3.5 3C3.22386 3 3 3.22386 3 3.5C3 3.77614 3.22386 4 3.5 4Z" fill={(currentElementId == "gym-address" || Gym.address) ? "#055075" : "#6d6d6d"} />
              </svg>
              <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == "gym-address" || Gym.address) ? "10px" : "14px", color: (currentElementId == "gym-address" || Gym.address) ? "#055075" : "", transition: "180ms" }}>Address</p>
            </div>
            <input id="gym-address" onChange={handleChangeGym('address')} value={Gym.address ? Gym.address.placeName ? Gym.address.placeName : Gym.address.placeAddress ? Gym.address.placeAddress : Gym.address : ""} className={`${(currentElementId == "gym-address" || Gym.address) ? "active-input-field" : "inactive-input-field"}`} type="text" />
          </div>
          <div onClick={props.isEdit?editGym:addGym} className={`member-button-global btn-center m-t-20 d-flex ${isButtonLock ? "member-button-global-inactive" : ""}`} style={{
              width: "100%"
            }}>
            <p className="flex-item">{props.isEdit?"Edit":"Add"}</p>
          </div>
        </div>
      </div>  
    </div>
  )

}
export default ManageGymForm;