import React, {useState, useEffect} from "react";
import { useLongPress } from 'use-long-press';
import Dashboard from "../../core/Dashboard";
import MenuMob from '../../components/menu';
import BodyCompImg from '../../assets/body-composition.png';
import WorkoutHistoryImg from '../../assets/workout-history-member.png';
import FitnessMeasurement from '../../assets/fitness-mesurement.png';
import FitnessTest from '../../assets/fitness-test.png';

const WorkoutHistory = () => {

  const [isSelectedMembers, setSelectedMembers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [myReportType, SetMyReportType] = useState('body-composition');
  const [reportItemModal, SetReportItemModal] = useState('');
  
  const toggleMemberActions = () => {
    setSelectedMembers([])
  }

  const LongPress = useLongPress((e, memberId) => {
    setSelectedMembers(oldArray => [...oldArray,memberId.context]);
  });

  const handleMenuVisibility = () => {
    if(!isMenuOpen){
      setTimeout(() => {
        setIsMenuOpen(true)
      }, 50);
    }else{
      let container = document.getElementById("mobile-bottom-modal-container-menu");
      let menu = document.getElementById('menu-bar-mobile-id');
      container.style.background = "rgba(0, 0, 0, 0.10)";
      menu.style.marginRight = "-70%";
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 180);
    }
  }

  const handleCurrentReportType = (currentReportType) => {
    SetMyReportType(currentReportType)
  }

  const handleReportItem = () => {
    if(reportItemModal == false){
      SetReportItemModal(true)
    }else{
      SetReportItemModal(false)
    }
  }

  return(
    <Dashboard flag={0}>
      <div className='mob-dashboard'>
        {
          isSelectedMembers.length !== 0 ? (
            <div className='profile-header-mob d-flex' style={{boxShadow: "none"}}>
              <div onClick={toggleMemberActions} className='bell-menu-container header-menu-active d-flex flex-item m-r-12'>
                <svg className='menu flex-item' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#055075" stroke-linecap="round"/>
                </svg>
              </div>
              <div className='d-flex flex-item filter-horizontal-scroll' style={{width: "84%", height: "auto"}}>
                <div className='global-button-container d-flex flex-item p-l-12 p-r-12 m-r-20' style={{ background: "#FFFFFF"}}>
                  <div className='d-flex'>
                    <svg className='flex-item m-r-8' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="7" cy="7" r="6.5" stroke="#6D6D6D"/>
                      <rect x="4" y="9.65674" width="8" height="1" rx="0.5" transform="rotate(-45 4 9.65674)" fill="#6D6D6D"/>
                    </svg>
                    <p className='flex-item' style={{whiteSpace: "nowrap"}}>Mark Inactive</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='profile-header-mob d-flex space-between' style={{boxShadow: "none"}}>
              <div className='flex-item d-flex'>
                  <svg className='flex-item m-r-8' width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49974 3.09999C5.99016 3.09999 4.76641 4.32375 4.76641 5.83333C4.76641 7.34291 5.99016 8.56666 7.49974 8.56666C9.00932 8.56666 10.2331 7.34291 10.2331 5.83333C10.2331 4.32375 9.00932 3.09999 7.49974 3.09999ZM3.56641 5.83333C3.56641 3.66101 5.32742 1.89999 7.49974 1.89999C9.67206 1.89999 11.4331 3.66101 11.4331 5.83333C11.4331 8.00565 9.67206 9.76666 7.49974 9.76666C5.32742 9.76666 3.56641 8.00565 3.56641 5.83333Z" fill="#055075"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7525 2.4595C12.8347 2.13848 13.1615 1.94488 13.4825 2.02707C14.3286 2.2437 15.0785 2.73576 15.6141 3.42568C16.1496 4.11559 16.4403 4.96412 16.4403 5.83749C16.4403 6.71086 16.1496 7.55939 15.6141 8.2493C15.0785 8.93922 14.3286 9.43128 13.4825 9.64791C13.1615 9.7301 12.8347 9.5365 12.7525 9.21548C12.6703 8.89446 12.8639 8.5676 13.1849 8.48541C13.7729 8.33487 14.294 7.99293 14.6661 7.51349C15.0383 7.03406 15.2403 6.44441 15.2403 5.83749C15.2403 5.23057 15.0383 4.64092 14.6661 4.16149C14.294 3.68205 13.7729 3.34011 13.1849 3.18957C12.8639 3.10738 12.6703 2.78052 12.7525 2.4595ZM5.83372 13.1C5.1088 13.1 4.41356 13.388 3.90097 13.9006C3.38837 14.4132 3.10039 15.1084 3.10039 15.8333V17.5C3.10039 17.8314 2.83176 18.1 2.50039 18.1C2.16902 18.1 1.90039 17.8314 1.90039 17.5V15.8333C1.90039 14.7901 2.31479 13.7897 3.05244 13.052C3.79008 12.3144 4.79054 11.9 5.83372 11.9H9.16706C10.2102 11.9 11.2107 12.3144 11.9483 13.052C12.686 13.7897 13.1004 14.7901 13.1004 15.8333V17.5C13.1004 17.8314 12.8318 18.1 12.5004 18.1C12.169 18.1 11.9004 17.8314 11.9004 17.5V15.8333C11.9004 15.1084 11.6124 14.4132 11.0998 13.9006C10.5872 13.388 9.89198 13.1 9.16706 13.1H5.83372ZM14.4194 12.475C14.5023 12.1541 14.8295 11.9612 15.1504 12.044C15.991 12.2611 16.7361 12.7501 17.2697 13.4349C17.8033 14.1197 18.0954 14.9617 18.1004 15.8299L18.1004 15.8333L18.1004 17.5C18.1004 17.8314 17.8318 18.1 17.5004 18.1C17.169 18.1 16.9004 17.8314 16.9004 17.5V15.8352C16.8966 15.2325 16.6937 14.6479 16.3232 14.1724C15.9524 13.6966 15.4345 13.3568 14.8504 13.2059C14.5296 13.1231 14.3366 12.7958 14.4194 12.475Z" fill="#055075"/>
                  </svg>
                  <p className='flex-item mob-dashboard-heading m-r-8'>Member</p>
              </div>
              <div className='d-flex flex-item'>
                <div className='bell-menu-container header-menu-active d-flex flex-item m-r-12'>
                  <svg className='flex-item bell' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#000000"/>
                  </svg>
                </div>
                <div className='bell-menu-container header-menu-active d-flex m-r-14'>
                  <svg className='bell flex-item' width="16" height="16" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.49099 5.82435C7.78036 1.94382 6.89054 0.469739 4.09577 0.531423C3.10015 0.552775 3.33874 -0.229342 2.57946 0.0672142C1.82169 0.36377 2.47217 0.797927 1.69639 1.46933C-0.480905 3.35384 -0.280582 5.0968 0.729287 9.25095C1.15469 11.001 -0.296338 11.0864 0.277622 12.7819C0.697025 14.0188 3.78815 14.5368 7.04885 13.262C10.3103 11.9864 12.3653 9.45498 11.9459 8.21815C11.3719 6.52185 10.212 7.45817 9.49099 5.82435ZM6.69547 12.2165C3.7829 13.3553 1.38953 12.6863 1.28824 12.3881C1.11418 11.8741 2.22833 10.1612 5.55805 8.85871C8.88777 7.55623 10.7462 8.03942 10.9405 8.61276C11.0553 8.95202 9.60878 11.0769 6.69547 12.2165ZM5.75912 9.4534C4.23682 10.0489 3.17893 10.7298 2.49318 11.3427C2.97561 11.8045 3.87894 11.9168 4.77852 11.5649C5.92419 11.1181 6.62869 10.0892 6.35034 9.26835L6.33834 9.23988C6.15002 9.30473 5.9572 9.37511 5.75912 9.4534Z" fill="#2b2b2b"/>
                  </svg>
                </div>
                <div onClick={handleMenuVisibility} className='bell-menu-container header-menu-active d-flex'>
                  <svg className='flex-item menu' width="16" height="16" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="15" height="1" rx="0.5" fill="#2b2b2b"/>
                    <rect y="4" width="7" height="1" rx="0.5" fill="#2b2b2b"/>
                    <rect y="8" width="12" height="1" rx="0.5" fill="#2b2b2b"/>
                  </svg>
                </div>
              </div>
            </div>
          )
        }
          {/* <div className="d-flex w-full m-b-18">    
            <div className="d-flex branch-gym-logo m-r-8" style={{ background: "#5d883c" }}>
              <p className="flex-item" style={{margin: "0 auto", color: "#ffffff"}}>U</p>
            </div>
            <div className="flex-item">
              <p className="branch-gym-name" style={{fontWeight: 600}}>User Name</p>
              <p className="branch-gym-email">email@gmail.com</p>
            </div>
          </div> */}
          {/* <p className="fs-18" style={{fontWeight: 600, lineHeight: "22px"}}>Workout History</p> */}
          <div className='mobile-dashboard-scroll-container p-l-14 p-t-0 m-t-4'>
          <div className="d-flex m-b-16">
            <div className="d-flex flex-item branch-gym-logo m-r-8" style={{ background: "#5d883c", width: "30px", height: "30px" }}>
              <p className="flex-item" style={{margin: "0 auto", color: "#ffffff"}}>U</p>
            </div>
            <p className="fs-16 flex-item" style={{fontWeight: 600, lineHeight: "20px"}}>Anik Roy</p>
          </div>
          <div className='d-flex w-full space-between'>
            <div onClick={handleCurrentReportType.bind(this, "body-composition")} className='flex-item d-flex my-report-type-container-mob' style={{outline: myReportType == "body-composition" ? "1px solid #055075" : "", background: myReportType == "body-composition" ? "#ffffff" : ""}}>
              <img className='flex-item' src={BodyCompImg} />
            </div>
            <div onClick={handleCurrentReportType.bind(this, "fitness-test")} className='flex-item d-flex my-report-type-container-mob' style={{outline: myReportType == "fitness-test" ? "1px solid #055075" : "", background: myReportType == "fitness-test" ? "#ffffff" : ""}}>
              <img className='flex-item' src={FitnessTest} />
            </div>
            <div onClick={handleCurrentReportType.bind(this, "workout-history")} className='flex-item d-flex my-report-type-container-mob' style={{outline: myReportType == "workout-history" ? "1px solid #055075" : "", background: myReportType == "workout-history" ? "#ffffff" : ""}}>
              <img className='flex-item' src={WorkoutHistoryImg} />
            </div>
            <div onClick={handleCurrentReportType.bind(this, "fitness-measurement")} className='flex-item d-flex my-report-type-container-mob' style={{outline: myReportType == "fitness-measurement" ? "1px solid #055075" : "", background: myReportType == "fitness-measurement" ? "#ffffff" : ""}}>
              <img className='flex-item' src={FitnessMeasurement} />
            </div>
          </div>

          {
            myReportType == "body-composition" ? (
              <p className='report-type-heading-mob m-t-18 m-b-18'>Physiological parameters <br/>& Body Composition</p>
            ) : myReportType == "fitness-test" ? (
              <p className='report-type-heading-mob m-t-18 m-b-18'>Fitness Test</p>
            ) : myReportType == "workout-history" ? (
              <p className='report-type-heading-mob m-t-18 m-b-18'>Workout history</p>
            ) : myReportType == "fitness-measurement" ? (
              <p className='report-type-heading-mob m-t-18 m-b-18'>Fitness Measurement</p>
            ) :  null
          }

          {
            myReportType == "body-composition" ? (
              <div className='w-full'>
                <div onClick={handleReportItem.bind(this)} className="custom-table-row d-flex space-between m-b-16">
                  <div className="flex-item d-flex">
                    <svg className="flex-item" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_760_1721)">
                      <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_760_1721">
                      <rect width="12" height="12" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <p className="flex-item m-l-8">21/01/2022</p>
                  </div>
                  <svg className="flex-item" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round"/>
                  </svg>
                </div>
                <div className="custom-table-row d-flex space-between m-b-16">
                  <div className="flex-item d-flex">
                    <svg className="flex-item" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_760_1721)">
                      <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_760_1721">
                      <rect width="12" height="12" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <p className="flex-item m-l-8">21/01/2022</p>
                  </div>
                  <svg className="flex-item" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
            ) : myReportType == "fitness-test" ? (
              <div className='w-full'>
                <div onClick={handleReportItem.bind(this)} className="custom-table-row d-flex space-between m-b-16">
                  <div className="flex-item d-flex">
                    <svg className="flex-item" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_760_1721)">
                      <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_760_1721">
                      <rect width="12" height="12" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <p className="flex-item m-l-8">21/04/2022</p>
                  </div>
                  <svg className="flex-item" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
            ) : myReportType == "workout-history" ? (
              <div className='w-full'>
                <div onClick={handleReportItem.bind(this)} className="custom-table-row d-flex space-between m-b-16">
                  <div className="flex-item d-flex">
                    <svg className="flex-item" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_760_1721)">
                      <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_760_1721">
                      <rect width="12" height="12" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <p className="flex-item m-l-8">21/04/2022 - 24/04/2022</p>
                  </div>
                  <svg className="flex-item" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
            ) : myReportType == "fitness-measurement" ? (
              <div className='w-full'>
                <div onClick={handleReportItem.bind(this)} className="custom-table-row d-flex space-between m-b-16">
                  <div className="flex-item d-flex">
                    <svg className="flex-item" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_760_1721)">
                      <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_760_1721">
                      <rect width="12" height="12" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <p className="flex-item m-l-8">21/04/2022</p>
                  </div>
                  <svg className="flex-item" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6.5 6.5L12.5 1" stroke="#055075" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
            ) : null
          }

          {
            reportItemModal && (
              <div onClick={handleReportItem} className="mobile-bottom-modal-container">
                {
                  myReportType == "body-composition" ? (
                    <div id="persobal-details-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
                      <div onClick={handleReportItem} className="w-full">
                        <div className="draggable-pointer" />
                      </div>
                      <div className='mob-modal-scroll-container'>
                        <div className='d-flex m-b-16'>
                        <svg className='flex-item m-r-8' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_760_1736)">
                            <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_760_1736">
                            <rect width="12" height="12" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                          <p className='flex-item'>21/01/2022</p>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Waist to Hip Ratio</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>30</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Flag</p>
                            <p className='flex-item report-content-value'>Good</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Measuring Unit</p>
                            <p className='flex-item report-content-value'>Ratio</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Ideal Range</p>
                            <p className='flex-item report-content-value'>14 -22.2</p>
                          </div>  
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Systolic BP</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>128</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Flag</p>
                            <p className='flex-item report-content-value'>High</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Measuring Unit</p>
                            <p className='flex-item report-content-value'>mm Hg</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Ideal Range</p>
                            <p className='flex-item report-content-value'>{`< 120`}</p>
                          </div>  
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Diastolic BP</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>77</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Flag</p>
                            <p className='flex-item report-content-value'>Low</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Measuring Unit</p>
                            <p className='flex-item report-content-value'>mm Hg</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Ideal Range</p>
                            <p className='flex-item report-content-value'>{`< 120`}</p>
                          </div>  
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Heart Rate</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>75</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Flag</p>
                            <p className='flex-item report-content-value'>low</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Measuring Unit</p>
                            <p className='flex-item report-content-value'>BPM</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Ideal Range</p>
                            <p className='flex-item report-content-value'>150 - 180</p>
                          </div>  
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Basal Metabolic Rate</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>128</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Flag</p>
                            <p className='flex-item report-content-value'>High</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Measuring Unit</p>
                            <p className='flex-item report-content-value'>mm Hg</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Ideal Range</p>
                            <p className='flex-item report-content-value'>33 -46</p>
                          </div>  
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Body Mass Index</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>40</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Flag</p>
                            <p className='flex-item report-content-value'>Morbidly Obese</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Measuring Unit</p>
                            <p className='flex-item report-content-value'>mm Hg</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Ideal Range</p>
                            <p className='flex-item report-content-value'>18.5 - 24.9</p>
                          </div>  
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Percent Body Fat</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>30</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Flag</p>
                            <p className='flex-item report-content-value'>Good</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Measuring Unit</p>
                            <p className='flex-item report-content-value'>Percentage (%)</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Ideal Range</p>
                            <p className='flex-item report-content-value'>14 -22.2</p>
                          </div>  
                        </div>
                      </div>
                    </div> 
                  ) :  myReportType == "fitness-test" ? (
                    <div id="persobal-details-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
                      <div onClick={handleReportItem} className="w-full">
                        <div className="draggable-pointer" />
                      </div>
                      <div className='mob-modal-scroll-container'>
                        <div className='d-flex m-b-16'>
                        <svg className='flex-item m-r-8' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_760_1736)">
                            <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_760_1736">
                            <rect width="12" height="12" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                          <p className='flex-item'>21/01/2022</p>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Muscle Endurance</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>12</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Unit</p>
                            <p className='flex-item report-content-value'>cm</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Goal</p>
                            <p className='flex-item report-content-value'>Abs Muscles</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : myReportType == "workout-history" ? (
                    <div id="persobal-details-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
                      <div onClick={handleReportItem} className="w-full">
                        <div className="draggable-pointer" />
                      </div>
                      <div className='mob-modal-scroll-container'>
                        <div className='d-flex m-b-20'>
                        <svg className='flex-item m-r-8' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_760_1736)">
                            <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_760_1736">
                            <rect width="12" height="12" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                          <p className='flex-item'>21/04/2022 - 24/04/2022</p>
                        </div>
                        <div className='w-full d-flex'>
                          <p className='history-main-heading-txt flex-item m-b-20'>Planner Beginner Chest</p>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Planner Exercise Bi-Ceps</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Set</p>
                            <p className='flex-item report-content-value'>3</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Repetation</p>
                            <p className='flex-item report-content-value'>10</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Weight</p>
                            <p className='flex-item report-content-value'>45</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Distance</p>
                            <p className='flex-item report-content-value'>14 Km</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Count</p>
                            <p className='flex-item report-content-value'>3</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Duration</p>
                            <p className='flex-item report-content-value'>10 Min</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Date</p>
                            <p className='flex-item report-content-value'>21/04/2022</p>
                          </div>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Planner Exercise Bi-Ceps</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Set</p>
                            <p className='flex-item report-content-value'>3</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Repetation</p>
                            <p className='flex-item report-content-value'>10</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Weight</p>
                            <p className='flex-item report-content-value'>45</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Distance</p>
                            <p className='flex-item report-content-value'>14 Km</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Count</p>
                            <p className='flex-item report-content-value'>3</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Duration</p>
                            <p className='flex-item report-content-value'>10 Min</p>
                          </div>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Date</p>
                            <p className='flex-item report-content-value'>21/04/2022</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : myReportType == "fitness-measurement" ? (
                    <div id="persobal-details-modal" onClick={e => e.stopPropagation()} className="mobile-bottom-modal">
                      <div onClick={handleReportItem} className="w-full">
                        <div className="draggable-pointer" />
                      </div>
                      <div className='mob-modal-scroll-container'>
                        <div className='d-flex m-b-16'>
                        <svg className='flex-item m-r-8' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_760_1736)">
                            <path d="M3 0.375C3 0.275544 2.96049 0.180161 2.89016 0.109835C2.81984 0.0395088 2.72446 0 2.625 0C2.52554 0 2.43016 0.0395088 2.35984 0.109835C2.28951 0.180161 2.25 0.275544 2.25 0.375V0.75H1.5C1.10218 0.75 0.720644 0.908035 0.43934 1.18934C0.158035 1.47064 0 1.85218 0 2.25L0 3H12V2.25C12 1.85218 11.842 1.47064 11.5607 1.18934C11.2794 0.908035 10.8978 0.75 10.5 0.75H9.75V0.375C9.75 0.275544 9.71049 0.180161 9.64017 0.109835C9.56984 0.0395088 9.47446 0 9.375 0C9.27554 0 9.18016 0.0395088 9.10983 0.109835C9.03951 0.180161 9 0.275544 9 0.375V0.75H3V0.375ZM12 10.5V3.75H0V10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5ZM9.375 5.25H10.125C10.2245 5.25 10.3198 5.28951 10.3902 5.35984C10.4605 5.43016 10.5 5.52554 10.5 5.625V6.375C10.5 6.47446 10.4605 6.56984 10.3902 6.64017C10.3198 6.71049 10.2245 6.75 10.125 6.75H9.375C9.27554 6.75 9.18016 6.71049 9.10983 6.64017C9.03951 6.56984 9 6.47446 9 6.375V5.625C9 5.52554 9.03951 5.43016 9.10983 5.35984C9.18016 5.28951 9.27554 5.25 9.375 5.25Z" fill="#055075"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_760_1736">
                            <rect width="12" height="12" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                          <p className='flex-item'>21/01/2022</p>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Chest</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>12</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Unit</p>
                            <p className='flex-item report-content-value'>cm</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Goal</p>
                            <p className='flex-item report-content-value'>Biceps</p>
                          </div>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Thigh Circumference</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>12</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Unit</p>
                            <p className='flex-item report-content-value'>cm</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Goal</p>
                            <p className='flex-item report-content-value'>Biceps</p>
                          </div>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Mid Arm Circumference</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>12</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Unit</p>
                            <p className='flex-item report-content-value'>cm</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Goal</p>
                            <p className='flex-item report-content-value'>Biceps</p>
                          </div>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Waist</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>12</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Unit</p>
                            <p className='flex-item report-content-value'>cm</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Goal</p>
                            <p className='flex-item report-content-value'>Biceps</p>
                          </div>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Hip</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>12</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Unit</p>
                            <p className='flex-item report-content-value'>cm</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Goal</p>
                            <p className='flex-item report-content-value'>Biceps</p>
                          </div>
                        </div>
                        <div className='report-type-content-container m-b-16'>
                          <p className='heading-txt m-b-12'>Calf</p>
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Results</p>
                            <p className='flex-item report-content-value'>12</p>
                          </div>  
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Unit</p>
                            <p className='flex-item report-content-value'>cm</p>
                          </div> 
                          <div className='d-flex space-between m-b-10'>
                            <p className='flex-item report-content-type'>Goal</p>
                            <p className='flex-item report-content-value'>Biceps</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ): null
                }
              </div>
            )
          }

        </div>
      </div>

      {
        isMenuOpen && (
          // <div id="mobile-bottom-modal-container-menu" onClick={handleMenuVisibility} className="mobile-bottom-modal-container">
            // <div id="menu-bar-mobile-id" onClick={e => e.stopPropagation()} className="menu-bar-mobile right-slider">
              <MenuMob toggleMenuVisibility={handleMenuVisibility} />
            // </div>
          // </div>
        )
      }
    </Dashboard>
  )
} 

export default WorkoutHistory;