import { API } from "../../backend"
import { objectToQueryString } from "../../lib/utils";



export const getUser = (userId, token) => {
  return fetch(`${API}/user/${userId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}

export const getUserInfo = (userId, token, searchId) => {
  return fetch(`${API}/user/info/${userId}/${searchId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const createUser = (userId, token, payload) => {
  return fetch(`${API}/create/user/${userId}`, {
    method: "post",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      'Content-Type': "application/json"
    },
    body: JSON.stringify(payload)
  }).then(response => response.json()).catch(err => console.log(err));
}

export const updateUser = (userId, token, payload) => {
  return fetch(`${API}/update/user/${userId}`, {
    method: "put",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }).then(response => response.json()).catch(console.error)
}

export const deleteUser = (userId, token, deleteId) => {
  return fetch(`${API}/delete/user/${userId}/${deleteId}`, {
    method: "delete",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error);
}


export const getUsersList = (userId, token, query) => {
  let str = objectToQueryString(query);
  return fetch(`${API}/users/${userId}?${str}`, {
    method: "get",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error)
}

export const getUsersCount = (userId, token, query) => {
  let str = objectToQueryString(query);
  return fetch(`${API}/users/count/${userId}?${str}`, {
    method: 'get',
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).catch(console.error);
}

export const changeUserStatus = (userId, token, action, payload) => {
  return fetch(`${API}/change/user/status/${userId}/${action}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  }).then(res => res.json()).catch(console.error)
}


export const verifyUser = (token) => {
  return fetch(`${API}/user/verify/${token}`, {
    method: 'get',
    headers: {
      Accept: "application/json"
    }
  }).then(res => res.json()).catch(console.error);
}

export const setPassword=(token,body)=>{
  return fetch(`${API}/user/setpassword/${token}`,{
    method:"post",
    headers:{
      'Content-Type':'application/json',
      Accept:"application/json"
    },
    body:JSON.stringify(body)
  }).then(res=>res.json()).catch(console.error)
}


export const updatePassword=(userId,token,body)=>{
   return fetch(`${API}/user/update/password/${userId}`,{
    method:"put",
    headers:{
      Accept:"application/json",
      'Content-Type':"application/json",
      Authorization:`Bearer ${token}`
    },
    body:JSON.stringify(body)
   }).catch(console.error);
}

export const forgetpassword=(body)=>{
  return fetch(`${API}/user/forgetpassword`,{
    method:'post',
    headers:{
      Accept:"application/json",
      'Content-type':'application/json'
    },
    body:JSON.stringify(body)
  }).then(res=>res.json()).catch(console.error)
}


export const checkUserAvailability=(query)=>{
  return fetch(`${API}/check/user/availibility`,{
    method: 'post',
    headers: {
      'Content-Type':"application/json",
      Accept: "application/json"
    },
    body:JSON.stringify(query)
  }).then(res=>res.json()).catch(err=>console.log(err))
}


export const updateUserProfile = (userId, token, body) => {
  return fetch(`${API}/update/user/profile/${userId}`, {
      method: "PUT",
      headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
      },
      body: body
  }).then(response => {
      return response.json();
  }).catch(err => console.log(err))
}
