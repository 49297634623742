import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./index";

const AdminUserRoutes = ({ component: Component, ...rest }) => {



  return (
    <Route
      {...rest}
      render={props =>
        (isAuthenticated() && isAuthenticated().user && isAuthenticated().user.role < 8 && isAuthenticated().user.role > -1) ? (
          <Component  {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default AdminUserRoutes;