import React, {useState, useEffect} from 'react';
import Dashboard from '../../../core/Dashboard';
import { startLoader, stopLoader, useAsyncState } from '../../../lib/utils';
import { changeDeviceStatus, getAllDevice, getDeviceCount } from "../helper";
import { isAuthenticated } from '../../../auth';
import { getAllGym, getGymCount } from '../../../gym/helper/api';
import { getAllBranches, getBranchCount } from '../../../branch/helper/api';
import BlockIcon from "../../../assets/block.svg";
import config from '../../../config';
import ManageConfirmationModal from '../../Modal/ConfirmModal';
import _ from 'lodash';


const ManageSmartScreens = (props) => {

  const [isDeviceActive, setDeviceActive] = useAsyncState(true);
  const [deviceList, setDeviceList] = useAsyncState([]);
  const [selectedDevice,setSelectedDevice] = useState('');
  var [pageNo, setPageNo] = useState({
    device: 1,
    branch: 1,
    gym: 1
  });
  const [pageSize, setPageSize] = useState({
    device: 20,
    branch: 20,
    gym: 20
  });
  var [pageNo, setPageNo] = useState({
    device: 1,
    branch: 1,
    gym: 1
  });
  var [pageCount, setPageCount] = useState({
    device: 1,
    branch: 1,
    gym: 1
  });
  var [total, setTotal] = useState({
    device: 0,
    branch: 0,
    gym: 0
  });
  const [isDeviceFilterListOpen,setDeviceFilterListOpen]=useState(false);
  const [isStatusChangeModalOpen, setStatusChangeModal] = useState(false);
  const [isBulkStatusChangeAction, setBulkStatusChangeAction] = useState(false)
  const [selectedDeviceIDs, setSelectedDeviceIDs] = useState([]);
  const [startGymLoader, setStartGymLoader] = useState(false);
  const [startBranchLoader, setStartBranchLoader] = useState(false);
  const [isStartLoader, setStartLoader] = useState(false);
  const [currentActionItem, setCurrentActionItem] = useState('');
  const [isScreenApiAction, setScreenApiAction] = useState(false);
  const [isGymRegistrationOpen, setGymRegistrationOpen] = useState(false);
  const [searchKey,setSearchKey]=useState('');
  const [selectedGym, setSelectedGym] = useState("");
  const [sortByBranchIDs, setSortByBranchIDs] = useState([]);
  const [sortByGym, setSortByGym] = useState("");
  const [Gyms, setGyms] = useState([]);
  const [Branches, setBranches] = useState([]);
  const [searchGymKey, setSearchGymKey] = useState("");
  const [searchBranchKey, setSearchBranchKey] = useState("");
  const [isButtonLock, setButtonLock] = useState(true);
  const [prevCurrentActionItem, setPrevCurrentActionItem] = useState("");
  const [deviceActionDropdownId,setDeviceActionDropdownId]=useState('');
  const [tempIDs, setTempIDs] = useState([]);
  const { user, token } = isAuthenticated();

  const handleSearch= event =>{
    setSearchKey(event.target.value);
  }

  const toggleCurrentActionItem = (actionItem = "") => {
    setPrevCurrentActionItem(currentActionItem);
    if(sortByBranchIDs.length && actionItem == 'branch'){
      setTempIDs([...sortByBranchIDs]);
    }
    setCurrentActionItem(actionItem);
  }

  const handleDeviceFilterListOpen=()=>{
    setDeviceFilterListOpen(!isDeviceFilterListOpen);
  }

  const backToCurrentActionItem = event => {
    setCurrentActionItem(prevCurrentActionItem)
    setPrevCurrentActionItem("")
  }

  const handleAction = async event => {
    event.preventDefault();
    try {
      await startLoader()
      let status = (isBulkStatusChangeAction ? isDeviceActive : (!selectedDevice.deletedAt || selectedDevice.deletedAt == null)) ? "deactivate" : "activate";
      let deviceIDs = isBulkStatusChangeAction ? selectedDeviceIDs : [selectedDevice._id];
      await changeDeviceStatus(user._id, token, status, { deviceIDs });
      setStatusChangeModal(false)
      setBulkStatusChangeAction(false);
      setSelectedDeviceIDs([]);
      setSelectedDevice("")
      setScreenApiAction(!isScreenApiAction);
      await stopLoader()
    } catch (error) {
      await stopLoader()
    }
    event.stopPropagation()
  }
  
  const handleClearBranch = () => {
    setSortByBranchIDs([]);
  }
  const handleClearGym = async () => {
    setSortByGym("");
    setSortByBranchIDs([])
  }

  const toggleDeviceActionDropdown = device =>event =>{
    let deviceId = device._id;
    if (deviceActionDropdownId == device._id) deviceId = "";
    else setSelectedDevice(device);
    setDeviceActionDropdownId(deviceId);
  }

  const confirmTempSelectionIDs = async event => {
    setSortByBranchIDs(tempIDs);
    backToCurrentActionItem();
    setTempIDs([]);
  }

  const prev = async () => {
    try {
      if (pageNo.device <= pageCount.device && pageNo.device != 1) {
        pageNo.device = pageNo.device - 1;
        setPageNo({
          ...pageNo
        });
        await startLoader();
        await GetAllDevice();
        await stopLoader();
      }
    } catch (error) {
      await stopLoader()
    }
  };
  const next = async () => {
    try {
      if (pageNo.device < pageCount.device) {
        pageNo.device = pageNo.device + 1;
        setPageNo({
          ...pageNo
        })
        await startLoader()
        await GetAllDevice();
        await stopLoader()
      }
    } catch (error) {
      await stopLoader()
    }
  };

  const getParams = () => {
    let params = {
      pageNo: pageNo.device,
      type: "smartScreen",
      pageSize: pageSize.device
    }
  
    params.activationStatus = isDeviceActive ? "active" : "inactive";
  
    if(searchKey) params.q=searchKey.trim();
  
    if (sortByGym) {
      params.gymId = sortByGym._id;
    }
  
    if (sortByBranchIDs.length) {
      params.branchIds = sortByBranchIDs;
    }
  
    return params;
  
  }
  
  const GetAllDevice = async () => {
    try {
      let params = getParams()
      let response = await getAllDevice(user._id, token, params)
      if (response.error) {
        //handle error
      } else {
        setDeviceList(response);
      }
    } catch (error) {
      console.error(error)
    }
  }
  
  const GetDeviceCount = async () => {
    let params = getParams()
    delete params.pageNo;
    delete params.pageSize;
  
    let response = await getDeviceCount(user._id, token, params);
    if (response.error) {
      // handle error
    } else {
      pageNo.device = 1;
      setPageNo({
        ...pageNo
      });
      pageCount.device=Math.ceil(response.COUNT / pageSize.device);
      setPageCount({
        ...pageCount
      });
      total.device=response.COUNT;
      setTotal({
        ...total,
      });
    }
  }

  const getGymParams = () => {
    let params = {
      pageNo: pageNo.gym,
      pageSize: pageSize.gym
    };
  
    if (searchGymKey) {
      params.q = searchGymKey;
    }
  
    return params;
  }
  
  const GetAllGym = async (isReUpdateGym) => {
    try {
      let params = getGymParams();
      let responses = await getAllGym(user._id, token, params);
      if (responses.error) {
  
      } else {
        if (isReUpdateGym) setGyms(responses)
        else setGyms([...Gyms, ...responses])
      }
    } catch (Error) {
      console.error(Error)
    }
  }
  
  const GetGymCount = async () => {
    try {
      let params = getGymParams();
      let response = await getGymCount(user._id, token, params);
      if (response.error) {
  
      } else {
        pageNo.gym = 1;
        setPageNo({
          ...pageNo
        })
        pageCount.gym=Math.ceil(response.COUNT / pageSize.gym);
        setPageCount({
          ...pageCount
        });
        total.gym=response.COUNT;
        setTotal({
          ...total,
        });
      }
    } catch (Error) {
      console.error(Error)
    }
  }
  
  const toggleActiveInactiveButton = () => {
    if (isDeviceActive) {
      setDeviceActive(false);
    } else {
      setDeviceActive(true);
    }
  }
  
  const searchBranch = event => {
    setSearchBranchKey(event.target.value)
  }
  
  const getBranchParams = () => {
    let params = {
      pageNo: pageNo.branch,
      pageSize: pageSize.branch
    };
  
    if (searchBranchKey) {
      params.q = searchBranchKey;
    }
  
    if (isGymRegistrationOpen) {
      if (selectedGym) params.gymIds = selectedGym._id;
    } else {
  
    }
  
    return params;
  }
  
  const GetAllBranch = async (isReUpdateBranch) => {
    try {
      let params = getBranchParams();
      let responses = await getAllBranches(user._id, token, params);
      if (responses.error) {
  
      } else {
        if (isReUpdateBranch) setBranches(responses)
        else setBranches([...Branches, ...responses])
      }
    } catch (Error) {
      console.error(Error)
    }
  }
  
  const GetBranchCount = async () => {
    try {
      let params = getBranchParams();
      let response = await getBranchCount(user._id, token, params);
      if (response.error) {
        //handle error response
      } else {
        pageNo.branch = 1;
        setPageNo({
          ...pageNo
        });
        pageCount.branch=Math.ceil(response.COUNT / pageSize.branch);
        setPageCount({
          ...pageCount
        });
        total.branch=response.COUNT;
        setTotal({
          ...total,
        });
      }
    } catch (Error) {
      console.error(Error)
    }
  }

  const handleStatusChangeAction = device => event => {
    setSelectedDevice(device);
    setStatusChangeModal(!isStatusChangeModalOpen);
    setBulkStatusChangeAction(false);
    event.stopPropagation()
  }

  const searchGym = event => {
    setSearchGymKey(event.target.value);
  }

  const handleTempIDs = id => event => {
    let ids = [...tempIDs];
    let index = ids.findIndex(d => d == id);
    if (index == -1) {
        ids.push(id);
    } else {
        ids.splice(index, 1);
    }
    setTempIDs(ids);
  }

  const handleBranchScroll = async event => {
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
  
    if (offsetHeight + scrollTop === scrollHeight && pageNo.branch < pageCount.branch) {
      pageNo.branch = pageNo.branch + 1;
      setPageNo(pageNo)
      setStartBranchLoader(true)
      await GetAllBranch();
      setStartBranchLoader(false)
    }
  }

  const handleGymScroll = async event => {
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
  
    if (offsetHeight + scrollTop === scrollHeight && pageNo.gym < pageCount.gym) {
      pageNo.gym = pageNo.gym + 1;
      setPageNo(pageNo)
      setStartGymLoader(true)
      await GetAllGym();
      setStartGymLoader(false)
    }
  }

  const handleSelectSortByGym=gym=>event=>{
    setSortByGym(gym)
    backToCurrentActionItem();
    event.stopPropagation()
  }

  useEffect(async () => {
    try {
      await startLoader();
      await GetDeviceCount()
      await GetAllDevice()
      await stopLoader()
    } catch (error) {
      await stopLoader()
    }
  }, [props,isDeviceActive,searchKey, sortByGym, sortByBranchIDs, isScreenApiAction])

  useEffect(async () => {
    setStartGymLoader(true);
    await GetGymCount()
    await GetAllGym(true)
    setStartGymLoader(false)
  }, [searchGymKey]);

  useEffect(async () => {
    if (selectedGym || sortByGym) {
      setStartBranchLoader(true);
      await GetBranchCount()
      await GetAllBranch(true)
      setStartBranchLoader(false)
    }
  }, [searchBranchKey, selectedGym, sortByGym]);

  useEffect(async () => {
    let searchScreenNameInput = document.getElementById("search-screen-name-input");
    let searchContainer = document.getElementById("search-container");
    let searchIcon = document.getElementById("search-icon");
    if (searchScreenNameInput) {
      searchScreenNameInput.onfocus = e => {
        searchContainer.style.outline = "1px solid #055075";
        searchContainer.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.25)";
        searchIcon.classList.add("active-fill-svg");
      }
      searchScreenNameInput.onblur = e => {
        searchContainer.style.outline = "none";
        searchContainer.style.boxShadow = "none";
        searchIcon.classList.remove("active-fill-svg");
      }
    }
  }, []);


  return(
    <Dashboard flag={0} >
      <div className="header-bar d-flex space-between">
        <div className='d-flex'>
          <svg className='flex-item m-r-12 m-t-4' width="52" height="52" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.25 52.5C16.3511 52.5 11.4004 52.5 8.3265 49.4235C5.25 46.3496 5.25 41.3989 5.25 31.5C5.25 21.6011 5.25 16.6504 8.3265 13.5765C11.4004 10.5 16.3511 10.5 26.25 10.5H36.75C46.6489 10.5 51.5996 10.5 54.6735 13.5765C57.75 16.6504 57.75 21.6011 57.75 31.5C57.75 41.3989 57.75 46.3496 54.6735 49.4235C51.5996 52.5 46.6489 52.5 36.75 52.5H26.25Z" stroke="#055075" stroke-width="3"/>
            <path d="M39.375 44.625H23.625" stroke="#055075" stroke-width="3" stroke-linecap="round"/>
          </svg>
          <div className='flex-item'>
            <p className="dashboard-type-heading flex-item">Workout Plus Screens</p>
            <p className='dashboard-route-txt'>Manage all your Screens here</p>
          </div>

        </div>
        <div className='d-flex'>
          <div id="search-container" className='global-search-box m-r-12'>
            <svg id="search-icon" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1637_1318)">
                <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
              </g>
              <defs>
                <clipPath id="clip0_1637_1318">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input id="search-screen-name-input" onInput={_.debounce(handleSearch,1000)} className='flex-item' type="text" placeholder="Search Screen" />
          </div>
          <div onClick={handleDeviceFilterListOpen} className='global-round-btn d-flex m-r-12'>
            <svg className='flex-item fill-svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 4H12.95C12.7 2.85 11.7 2 10.5 2C9.3 2 8.3 2.85 8.05 4H1V5H8.05C8.3 6.15 9.3 7 10.5 7C11.7 7 12.7 6.15 12.95 5H15V4ZM10.5 6C9.65 6 9 5.35 9 4.5C9 3.65 9.65 3 10.5 3C11.35 3 12 3.65 12 4.5C12 5.35 11.35 6 10.5 6ZM1 12H3.05C3.3 13.15 4.3 14 5.5 14C6.7 14 7.7 13.15 7.95 12H15V11H7.95C7.7 9.85 6.7 9 5.5 9C4.3 9 3.3 9.85 3.05 11H1V12ZM5.5 10C6.35 10 7 10.65 7 11.5C7 12.35 6.35 13 5.5 13C4.65 13 4 12.35 4 11.5C4 10.65 4.65 10 5.5 10Z" fill="#6D6D6D" />
            </svg>
            {
              isDeviceFilterListOpen && (
                <div onClick={e => e.stopPropagation()} className='gym-filter-popup' style={{ width: "210px" }}>
                  <p className='filter-heading-text m-b-12'>Status</p>
                  <div className='d-flex'>
                    <div onClick={toggleActiveInactiveButton.bind(this)} className='flex-item d-flex active-inactive-button m-r-12' style={{ background: !isDeviceActive ? "rgba(255, 0, 85, 0.347)" : "" }}>
                      <div className='active-inactive-ball flex-item' style={{ background: !isDeviceActive ? "rgb(201, 0, 57)" : "", margin: !isDeviceActive ? "0 0 0 0" : "" }} />
                    </div>
                    <p className='flex-item active-inactive-txt'>{isDeviceActive ? "Active" : "Inactive"}</p>
                  </div>
                  
                  {
                    sortByGym && [
                      <div className='d-flex'>
                        <div className='d-flex m-t-20'>
                          <p className='filter-heading-text m-r-12 flex-item'>Selected Gym</p>
                          <svg onClick={toggleCurrentActionItem.bind(this, "gym")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                          </svg>
                          <span className="m-l-4 m-r-4 flex-item"></span>
                          <svg role={`button`} onClick={handleClearGym} className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#055075" />
                          </svg>
                        </div>
                      </div>,
                      <div className="d-flex w-full m-t-8">
                        {
                          sortByGym.logoFileID ? (
                            <img className="flex-item m-r-8 branch-gym-logo" style={{ width: 40, height: 40 }} src={`${config.API_URL}${config.prefixStream}/media/file/${sortByGym.logoFileID}`} />
                          ) : (
                            <div className="d-flex flex-item m-r-8 branch-gym-logo" style={{ background: sortByGym.logoPlaceholderColor ? sortByGym.logoPlaceholderColor : "#5d883c" }}>
                              <p className="flex-item" style={{ margin: '0 auto', color: '#ffffff' }}>{`${sortByGym.name}`.trim()[0]}</p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-name">{sortByGym.name}</p>
                          <p className="branch-gym-email">{sortByGym.email}</p>
                        </div>
                      </div>
                    ]
                  }

                  {
                    (sortByGym && sortByBranchIDs.length == 0) && [
                      <div onClick={toggleCurrentActionItem.bind(this, "branch")} className='member-button-global btn-center d-flex w-full m-t-16'>
                        <p className='flex-item'>Select Branch</p>
                      </div>
                    ]
                  }

                  {
                    sortByBranchIDs.length > 0 && [
                      <div className='d-flex'>
                        <div className='d-flex m-t-20'>
                          <p className='filter-heading-text m-r-12 flex-item'>Selected Branch</p>
                          <svg onClick={toggleCurrentActionItem.bind(this, "branch")} className='flex-item' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.6448 1.57501C9.82628 1.38773 10.0432 1.23843 10.2829 1.1358C10.5227 1.03316 10.7804 0.979254 11.0412 0.977203C11.302 0.975152 11.5606 1.025 11.8019 1.12385C12.0432 1.2227 12.2624 1.36857 12.4468 1.55297C12.6312 1.73737 12.7771 1.95661 12.876 2.19793C12.9748 2.43925 13.0247 2.69783 13.0226 2.9586C13.0206 3.21938 12.9666 3.47713 12.864 3.71687C12.7614 3.95661 12.6121 4.17353 12.4248 4.35501L11.6548 5.12501L8.8748 2.34501L9.6448 1.57501V1.57501ZM8.3448 2.87501L2.2513 8.96851C2.0313 9.18851 1.8713 9.46151 1.7873 9.76151L1.0138 12.524C0.995931 12.588 0.995405 12.6557 1.01228 12.72C1.02916 12.7843 1.06282 12.843 1.10983 12.89C1.15685 12.937 1.21551 12.9707 1.27982 12.9875C1.34413 13.0044 1.41176 13.0039 1.4758 12.986L4.2378 12.2125C4.53777 12.1286 4.81105 11.9688 5.0313 11.7485L11.1253 5.65551L8.3453 2.87551L8.3448 2.87501Z" fill="#055075" />
                          </svg>
                          <span className="m-l-4 m-r-4 flex-item"></span>
                          <svg role={`button`} onClick={handleClearBranch} className="flex-item" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.42402 4.79883H8.57852C8.50117 4.79883 8.43789 4.86211 8.43789 4.93946V9.77871C8.43789 9.82442 8.45898 9.8666 8.4959 9.89297L11.4016 12.0147C11.4648 12.0604 11.5527 12.048 11.5984 11.9848L12.1012 11.2992C12.1486 11.2342 12.1346 11.1463 12.0713 11.1023L9.56465 9.29004V4.93946C9.56465 4.86211 9.50137 4.79883 9.42402 4.79883ZM13.2877 6.12071L16.0439 6.79395C16.1318 6.81504 16.218 6.74824 16.218 6.6586L16.232 3.81973C16.232 3.70196 16.0967 3.63516 16.0053 3.70899L13.235 5.87285C13.214 5.88905 13.1981 5.91083 13.189 5.93568C13.1799 5.96054 13.1779 5.98746 13.1835 6.01335C13.189 6.03924 13.2017 6.06305 13.2201 6.08204C13.2386 6.10103 13.262 6.11444 13.2877 6.12071ZM16.2355 11.4135L15.2389 11.0707C15.2041 11.0588 15.1661 11.061 15.1329 11.0767C15.0997 11.0925 15.074 11.1206 15.0613 11.1551C15.0279 11.2447 14.9928 11.3326 14.9559 11.4205C14.643 12.1605 14.1947 12.8268 13.6217 13.398C13.055 13.9665 12.3834 14.4196 11.6441 14.7322C10.8783 15.056 10.0551 15.2222 9.22363 15.2209C8.3834 15.2209 7.56953 15.0574 6.80312 14.7322C6.06386 14.4196 5.39227 13.9665 4.82559 13.398C4.2543 12.8268 3.80605 12.1605 3.49141 11.4205C3.1694 10.6543 3.00443 9.83117 3.00625 9C3.00625 8.15977 3.16973 7.34414 3.49492 6.57774C3.80781 5.8377 4.25605 5.17149 4.8291 4.6002C5.39579 4.03175 6.06737 3.57866 6.80664 3.26602C7.56953 2.94082 8.38516 2.77735 9.22539 2.77735C10.0656 2.77735 10.8795 2.94082 11.6459 3.26602C12.3852 3.57866 13.0568 4.03175 13.6234 4.6002C13.8027 4.78125 13.9715 4.96934 14.1262 5.16797L15.1773 4.34531C13.7939 2.57696 11.6406 1.43965 9.22187 1.44141C5.01016 1.44317 1.62812 4.86387 1.67031 9.07735C1.7125 13.217 5.07871 16.5586 9.22539 16.5586C12.4861 16.5586 15.2635 14.4914 16.3217 11.5963C16.348 11.5225 16.3094 11.4398 16.2355 11.4135Z" fill="#055075" />
                          </svg>
                        </div>
                      </div>,
                      <div className="w-full m-t-8">
                        <p className="branch-gym-name">{`${sortByBranchIDs.length} Branch(s) Selected`}</p>
                      </div>
                    ]
                  }

                  <div className="w-full m-t-20 d-flex-wrap">
                    {
                      (!sortByGym) && <div onClick={toggleCurrentActionItem.bind(this, "gym")} className='member-button-global btn-center d-flex w-full' style={{ margin: "2%" }}>
                        <p className='flex-item'>Select Gym</p>
                      </div>
                    }


                  </div>
                </div>
              )
            }
          </div>
          <div className='global-round-btn d-flex'>
            <svg className='flex-item fill-svg' width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8637 7.48845C9.72545 2.49919 8.61317 0.60395 5.11971 0.683258C3.87519 0.71071 4.17343 -0.294868 3.22433 0.0864182C2.27711 0.467704 3.09022 1.02591 2.12049 1.88914C-0.601131 4.31208 -0.350728 6.55303 0.911608 11.8941C1.44337 14.1442 -0.370423 14.254 0.347027 16.4339C0.871281 18.0241 4.73519 18.6901 8.81106 17.0511C12.8879 15.4111 15.4566 12.1564 14.9324 10.5662C14.2149 8.38523 12.765 9.58908 11.8637 7.48845ZM8.36933 15.7069C4.72863 17.1711 1.73691 16.3109 1.6103 15.9276C1.39272 15.2667 2.78542 13.0644 6.94756 11.3898C11.1097 9.71516 13.4327 10.3364 13.6756 11.0736C13.8191 11.5097 12.011 14.2418 8.36933 15.7069ZM7.19891 12.1544C5.29602 12.92 3.97367 13.7954 3.11648 14.5834C3.71951 15.1772 4.84867 15.3216 5.97315 14.8691C7.40523 14.2947 8.28587 12.9718 7.93793 11.9164L7.92292 11.8798C7.68752 11.9632 7.4465 12.0537 7.19891 12.1544Z" fill="#6D6D6D" />
            </svg>
          </div>
        </div>
      </div>
      <div className='dashboard-data-saperator'></div>

      <div className="body-container">
        <div className='d-flex table-header-container'>
          <div className='d-flex flex-item gym-container'>
            <p className='flex-item table-heading-txt'>Device ID</p>
          </div>
          <div className='gym-container flex-item'>
            <p className='flex-item table-heading-txt'>Gym</p>
          </div>
          <div className='location-container flex-item'>
            <p className='flex-item table-heading-txt'>Branch’s</p>
          </div>
          <div className='number-container flex-item'>
            <p className='flex-item table-heading-txt'>Status</p>
          </div>
          <div className='action-container flex-item'>
            <p className='flex-item table-heading-txt'>Action</p>
          </div>
        </div>
        <div className='flex-item main-table-scroll-container w-full'>
          {
            deviceList.map((device,index)=>{
              return (
                <div className='flex-item table-row-container d-flex' style={{ backgroundColor:"#ffffff", boxShadow: "0px 0px 4px rgb(0, 0, 0, 0.05)" }}>
                  <div className='flex-item gym-container d-flex'>
                    <p>{device.name}</p>
                  </div>
                  <div className='flex-item gym-container d-flex'>
                  {
                    (device.gym && device.gym.length) && [
                      device.gym[0].logoFileID ? (
                        <div className='gym-logo flex-item m-r-8' style={{background:"#f5f5f5"}}>
                          <img id="gym-ImgWrapper" src={`${config.API_URL}${config.prefixStream}/media/file/${device.gym[0].logoFileID}`} loading='lazy' className="gym-logo" />
                        </div>
                      ) : (
                        <div className='gym-logo flex-item m-r-8 d-flex' style={{ backgroundColor: device.gym[0].logoPlaceholderColor ? device.gym[0].logoPlaceholderColor : "#5d883c" }}>
                          <p className='flex-item' style={{  color: "#ffffff", margin: "0 auto", fontSize: 14,  }}>{`${device.gym[0].name}`.trim()[0]}</p>
                        </div>
                      ),
                      <p className="flex-item">{device.gym[0].name}{device.gym[0].address ? device.gym[0].address.placeName ? ", " + device.gym[0].address.placeName : device.gym[0].address.placeAddress ? ", " + device.gym[0].address.placeAddress : ", " + device.gym[0].address : ""}</p>
                    ]
                  }
                  </div>
                  <div className='location-container flex-item'>
                    <p className='flex-item'>{(device.branch.length)?`${device.branch[0].name}${device.branch[0].address?" ,"+device.branch[0].address:""}`:''}</p>
                  </div>
                  <div className='number-container d-flex flex-item'>
                    <div className='flex-item m-r-6' style={{width: "6px", height: "6px", background: device.deletedAt != null ? "rgb(241, 93, 34)": "rgb(45, 227, 63)", borderRadius: "100%"}}/>
                    <p className='flex-item' style={{color:device.deletedAt != null ? "rgb(241, 93, 34)": "rgb(45, 227, 63)"}}>{device.deletedAt == null?'Active':'InActive'}</p>
                  </div>
                  <div className='action-container flex-item'>
                    <div onClick={toggleDeviceActionDropdown(device)} className='action-horizontain-dots d-flex'>
                      <span className='flex-item m-r-2'></span>
                      <span className='flex-item m-r-2'></span>
                      <span className='flex-item'></span>
                    </div>
                    <div id={"action-" + device._id} className="table-action-container" style={{ display: deviceActionDropdownId == device._id ? "block" : "none" }}>
                      <div onClick={handleStatusChangeAction(device)} role="button" className="d-flex spacing-22">
                        <img src={BlockIcon} className="body-content-two-action-icon" />
                        <div className="spacing-24">{(!device.deletedAt || device.deletedAt == null) ? `Inactive` : `Active`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className='bottom-pagination-bar'>
          <div className='d-flex flex-item space-between'>
            <div onClick={prev} className='global-button-container d-flex flex-item'>
              <p className='flex-item'>Previous</p>
            </div>
            <p className='flex-item pagination-txt'>{(pageNo.device - 1) * pageSize.device + 1} - {(pageNo.device - 1) * pageSize.device + deviceList.length}  of  {total.device}</p>
            <div onClick={next} className='global-button-container d-flex flex-item'>
              <p className='flex-item'>Next</p>
            </div>
          </div>
        </div>
      </div>
      {
        isStatusChangeModalOpen && <ManageConfirmationModal handleAction={handleAction} title={isBulkStatusChangeAction ? `${selectedDeviceIDs.length} device(s) selected` : selectedDevice.name} message="By performing this action all the user's under this member will get effected" buttonText={`Mark ${isBulkStatusChangeAction ? (isDeviceActive ? "inactive" : "active") : (selectedDevice.deletedAt == null ? "inactive" : "active")}`} handleClose={handleStatusChangeAction("")} />
      }

      {
        currentActionItem == "gym" && (
          <div className="global-modal-container d-flex">
            <div className="global-filter-modal flex-item">
              <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
                <p className="flex-item modal-header-text">Select Gym</p>
                <div id="search-container-gym" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                  <svg id="search-icon-gym" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1637_1318)">
                      <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1637_1318">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input id="search-gym-input" onChange={_.debounce(searchGym, 1000)} className='flex-item' type="text" placeholder="Search Gym" style={{ background: "#f5f5f5" }} />
                </div>
              </div>
              <div className='search-gym-modal-scroll' onScroll={handleGymScroll}>
                {
                  Gyms.map(data => {
                    return (
                      <div onClick={handleSelectSortByGym(data)} className="d-flex suggestion-list-items">
                        {
                          data.logoFileID ? (
                            <img className="flex-item branch-gym-user-profile m-r-8" src={`${config.API_URL}/image/file/${data.logoFileID}/25/25/70`} />
                          ) : (
                            <div className='d-flex flex-item branch-gym-user-profile m-r-8' style={{
                              backgroundColor: data.logoPlaceholderColor ? data.logoPlaceholderColor : "#5d883c"
                            }}>
                              <p className='flex-item fs-14' style={{
                                margin: '0 auto',
                                color: "#ffffff"
                              }}>{`${data.name}`.trim()[0]}</p>
                            </div>
                          )
                        }
                        <div className="flex-item">
                          <p className="branch-gym-user-name m-b-1">{data.name}</p>
                          <p className="branch-gym-details-value">{data.email}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {startGymLoader && <div className="loader-5 center"><span></span></div>}
              </div>
              <div onClick={toggleCurrentActionItem.bind(this)} className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
                <div className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                  <p className='flex-item'>Cancel</p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        currentActionItem == "branch" && (
          <div className="global-modal-container d-flex">
          <div className="global-filter-modal flex-item">
            <div className="d-flex w-full space-between global-modal-header-container" style={{ borderRadius: "8px 8px 0 0" }}>
              <p className="flex-item modal-header-text">Select Branch</p>
              <div id="search-container-branch" className='global-search-box flex-item' style={{ background: "#f5f5f5", width: "50%" }}>
                <svg id="search-icon-branch" className='flex-item m-b-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1637_1318)">
                    <path d="M11.7429 10.344C12.7112 9.0227 13.1449 7.3845 12.9572 5.75716C12.7695 4.12982 11.9743 2.63335 10.7307 1.56714C9.48701 0.50093 7.88665 -0.0563875 6.24973 0.00668566C4.61282 0.0697588 3.06008 0.748571 1.90217 1.90732C0.744249 3.06606 0.0665484 4.61929 0.00464653 6.25624C-0.0572553 7.8932 0.501207 9.49317 1.56831 10.7361C2.6354 11.9789 4.13244 12.7731 5.75992 12.9596C7.38739 13.1461 9.02528 12.7112 10.3459 11.742H10.3449C10.3749 11.782 10.4069 11.82 10.4429 11.857L14.2929 15.707C14.4804 15.8946 14.7348 16.0001 15 16.0002C15.2653 16.0003 15.5198 15.895 15.7074 15.7075C15.895 15.52 16.0005 15.2656 16.0006 15.0004C16.0007 14.7351 15.8954 14.4806 15.7079 14.293L11.8579 10.443C11.8221 10.4068 11.7837 10.3734 11.7429 10.343V10.344ZM12.0009 6.50001C12.0009 7.22228 11.8586 7.93748 11.5822 8.60477C11.3058 9.27206 10.9007 9.87837 10.39 10.3891C9.87926 10.8998 9.27295 11.3049 8.60566 11.5813C7.93837 11.8577 7.22317 12 6.5009 12C5.77863 12 5.06343 11.8577 4.39614 11.5813C3.72885 11.3049 3.12253 10.8998 2.61181 10.3891C2.10109 9.87837 1.69596 9.27206 1.41956 8.60477C1.14316 7.93748 1.0009 7.22228 1.0009 6.50001C1.0009 5.04132 1.58036 3.64237 2.61181 2.61092C3.64326 1.57947 5.04221 1.00001 6.5009 1.00001C7.95959 1.00001 9.35853 1.57947 10.39 2.61092C11.4214 3.64237 12.0009 5.04132 12.0009 6.50001Z" fill="#6D6D6D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1637_1318">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input id="search-branch-input" onChange={_.debounce(searchBranch, 1000)} className='flex-item' type="text" placeholder="Search" style={{ background: "#f5f5f5" }} />
              </div>
            </div>
            <div className='search-gym-modal-scroll' onScroll={handleBranchScroll}>
                <div className="d-flex-wrap w-full m-t-12">
                  {
                    Branches.map(data => {
                      return (
                        <div onClick={handleTempIDs(data._id)} className={`global-button-container ${tempIDs.findIndex(id => id == data._id) != -1 && `global-button-container-active`} d-flex flex-item p-l-12 p-r-12 m-r-16 m-b-16`}>
                          <p className='flex-item'>{data.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
                {startBranchLoader && <div className="loader-5 center"><span></span></div>}
            </div>
            <div className='flex-item w-full p-r-12 p-b-12 p-t-12' style={{ position: "absolute", bottom: 0, right: 0, left: 0, background: "rgba(255, 255, 255, 0.90)", borderRadius: "0 0 8px 8px" }}>
              <div className="flex-item d-flex">
                <div onClick={toggleCurrentActionItem.bind(this)} className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 auto" }}>
                  <p className='flex-item'>Cancel</p>
                </div>
                {
                  tempIDs.length > 0 &&
                  <div onClick={confirmTempSelectionIDs} className='member-button-global btn-center d-flex' style={{ width: "100px", margin: "0 0 0 14px" }}>
                    <p className='flex-item'>Add</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        )
      }

    </Dashboard>
  )
}

export default ManageSmartScreens;