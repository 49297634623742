import { API } from "../../../backend"
import { objectToQueryString } from "../../../lib/utils"

export const getContent=(userId,token,contentId)=>{
  return fetch(`${API}/content/${userId}/${contentId}`,{
    method:'get',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    }
  }).then(res=>res.json()).catch(error=>{
    console.error(error)
  })
}


export const getAllContents=(userId,token,query)=>{
  let str=objectToQueryString(query);
  return fetch(`${API}/contents/${userId}?${str}`,{
    method:'get',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    }
  }).then(res=>res.json()).catch(console.error)
}

export const getContentsCount=(userId,token,query)=>{
  let str=objectToQueryString(query);
  return fetch(`${API}/contents/count/${userId}?${str}`,{
    method:'get',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    }
  }).then(res=>res.json()).catch(console.error)
}


export const addContent=(userId,token,formData)=>{
  return fetch(`${API}/create/content/${userId}`,{
    method:'post',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    },
    body:formData
  }).then(res=>res.json()).catch(console.error)
}


export const updateContent=(userId,token,contentId,formData)=>{
  return fetch(`${API}/update/content/${userId}/${contentId}`,{
    method:'put',
    headers:{
      Accept:"application/json",
      Authorization:`Bearer ${token}`
    },
    body:formData
  }).then(res=>res.json()).catch(console.error)
}



export const changeContentStatus=(userId,token,action,body)=>{
  return fetch(`${API}/change/content/status/${userId}/${action}`,{
    method:'post',
    headers:{
      Accept:"application/json",
      'Content-type':'application/json',
      Authorization:`Bearer ${token}`
    },
    body:JSON.stringify(body)
  }).then(res=>res.json()).catch(console.error)
}