import { useState } from "react";
import config from "../config";
import libphonenumber from 'google-libphonenumber';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();


export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber=(phoneNumber,countryCode='IN')=>{
   try{
    const parsedNumber = phoneUtil.parse(`${phoneNumber}`, countryCode);
    const isValid = phoneUtil.isValidNumber(parsedNumber);
    return isValid;
   }catch(error){
    return false;
   }
}

export const objectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const useAsyncState = initialState => {
  const [state, setState] = useState(initialState);

  const asyncSetState = value => {
    return new Promise(resolve => {
      setState(value);
      setState((current) => {
        resolve(current);
        return current;
      });
    });
  };

  return [state, asyncSetState];
};

export const startLoader = () => {
  let element = document.getElementById("app-loader");
  if (element) {
    element.style.display = "block";
  }
}



export const stopLoader = () => {
  let element = document.getElementById("app-loader");
  if (element) {
    element.style.display = "none";
  }
}

export const getUserRole = role => {
  let keyNames = ['Workout Plus Admin', 'Gym Owner', 'Gym Admin', 'Gym Programmer', 'Gym Instructor', 'Branch Admin', 'Branch Programmer', 'Branch Instructor', 'Member'];
  //let keyNames = ['Workout Plus Admin', 'Gym Owner', 'Gym Admin', 'Branch Admin', 'Branch Instructor', 'Member'];
  return keyNames[role];
}


export const getUserRoleList = role => {
  var keyNames = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  if (role == 1) {
    keyNames = keyNames.splice(1, 1);
  }
  if (role == 2) {
    keyNames = keyNames.splice(2, keyNames.length);
  }
  if (role == 5) {
    keyNames = keyNames.splice(0, 5)
  }
  return keyNames;
}


export function lazy_load_css(path) {
  //The function responsible for lazy loading our css files
  let type_of_path = typeof path; //This refers to the type of the path. It can be string or object 
  switch (type_of_path) {
    case "object":
      var head = document.getElementsByTagName("head")[0]; //Get's the head element 
      for (let i = 0; i < path.length; i++) {
        //Loops through all the paths
        var link = document.createElement("link"); //Creates a link element 
        link.href = path[i]; //Assigns a path to the tag 
        link.rel = "stylesheet"; //Set it's rel to stylesheet 
        link.type = "text/css"; //Set's it's type to text/css
        head.appendChild(link); //Appends it to the head of the document.
      }
      break;
    case "string": //If the path is a single path
      var head = document.getElementsByTagName("head")[0]; //Get the head element from the html document
      var link = document.createElement("link"); //Create the link element
      link.href = path; //Assign the value of path to the link's href 
      link.rel = "stylesheet"; //Assign 'stylesheet' to the link 
      link.type = "text/css";
      head.appendChild(link); //Appends the link to the head of the document. 
      break;
  }
}

export function formatDateTime(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'Asia/Kolkata'
  };
  let formattedDate = '';
  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date));
  }
  return formattedDate;
}

export function formatDateTimeToText(dateString){
  let monthName=config.monthNames[parseInt(dateString.split('/')[1])-1];
  return `${dateString.split('/')[0]} ${monthName} ${dateString.split('/')[2]}`;
}

export function getShortString(value,length=0){
  if(!value) return "";
  if(length==0) length=value.length;
  return `${value.length>length?value.substring(0,length)+"...":value}`;
}