import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import Background from "../assets/background-login.png";
import Logo from '../assets/gymshim-full-logo.png';
import LogoMob from '../assets/gymshim-full-logo.png';
import SpinnerLoader from '../components/SpinnerLoader';
import { startLoader, stopLoader } from '../lib/utils';
import { setPassword, verifyUser } from '../user/helper/api';


const ResetPassword = (props) => {


  const history = useHistory();
  const [currentElementId, setCurrentElementId] = useState("");
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user, setUser] = useState('');
  const [isLoaderStart, setLoaderStart] = useState(false);
  const [isButtonLock,setButtonLock]=useState(false);



  const handleChange=name=>event=>{
    if(name=="newpassword"){
      setNewPassword(event.target.value);
    }else{
      setConfirmPassword(event.target.value);
    }
  }

  const savePassword = async event => {
    event.preventDefault();
    try {
    
      if (!props.match.params.token || !newPassword || !confirmPassword || (newPassword && confirmPassword && newPassword!=confirmPassword)) return "";
      await startLoader();
      setButtonLock(true);
      setPassword(props.match.params.token, { newPassword, confirmPassword }).then(async res => {
        await stopLoader();
        setButtonLock(false);
        history.push("/signin");
      }).catch(async err=>{
        await stopLoader();
        setButtonLock(false)
      })
    } catch (error) {
      await stopLoader()
      // do something
    }
    event.stopPropagation();
  }

  const VerifyUser = token => {
    if (!token) history.push("/signin")
    verifyUser(token).then(response => {
      if (response.error) {
        history.push("/signin")
      } else {
        setLoaderStart(false);
        setUser(response)
      }
    }).catch(error => {
      history.push("/signin")
    })
  }



  const toggleInputFields = (id) => event => {
    setCurrentElementId(id);
    if (event) {
      let interval = setInterval(() => {
        let element = event.target.querySelector('input');
        if (element) {
          element.focus();
          clearInterval(interval)
        }
      }, 0);
    }
  }

  useEffect(async () => {
    try {
      setLoaderStart(true);
      await VerifyUser(props.match.params.token);
    } catch (error) {
      history.push("/signin")
    }
  }, [])


  useEffect(() => {
    if (isLoaderStart) {
      startLoader();
    } else {
      stopLoader();
    }
  }, [isLoaderStart]);


  if (isLoaderStart) {
    return (
      <SpinnerLoader />
    )
  }


  if (window.innerWidth < 549) {
    return(
      <div className='mob-dashboard-scroll mob-dashboard'>
        <div className='w-full h-full'>
          <div className='setup-header-mob d-flex space-between'>
            {/* <div className='back-arrow-button d-flex flex-item'>
              <svg className='flex-item' width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 1L1 7.5L7.5 14.5" stroke="#055075" stroke-linecap="round" />
              </svg>
            </div> */}
            <img className='flex-item' src={LogoMob} />
          </div>
          <img className='mobile-login-background' style={{position:"relative", margin: "0 0 0 8%"}} src={Background} />
          <div className='w-full'>
            <div style={{ width: "90%", margin: "0 0 0 5%" }}>
              <div className='signin-container' style={{ padding: "0", margin: "0 0 20px 0" }}>
                <p className='signin-txt m-t-30 m-b-20'>Reset Password</p>
                <div onClick={toggleInputFields('new-password')} className={`${(currentElementId == 'new-password' || newPassword) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'new-password' || newPassword) ? "1px solid #055075" : "" }}>
                  <div className="flex-item d-flex">
                    <svg className='m-r-4 flex-item' width={(currentElementId == 'new-password' || newPassword) ? "12" : "18"} height={(currentElementId == 'gym-name' || newPassword) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 11C2.725 11 2.48967 10.9022 2.294 10.7065C2.098 10.5105 2 10.275 2 10V5C2 4.725 2.098 4.4895 2.294 4.2935C2.48967 4.09783 2.725 4 3 4H3.5V3C3.5 2.30833 3.74383 1.71867 4.2315 1.231C4.71883 0.743667 5.30833 0.5 6 0.5C6.69167 0.5 7.28133 0.743667 7.769 1.231C8.25633 1.71867 8.5 2.30833 8.5 3V4H9C9.275 4 9.5105 4.09783 9.7065 4.2935C9.90217 4.4895 10 4.725 10 5V10C10 10.275 9.90217 10.5105 9.7065 10.7065C9.5105 10.9022 9.275 11 9 11H3ZM6 8.5C6.275 8.5 6.5105 8.40217 6.7065 8.2065C6.90217 8.0105 7 7.775 7 7.5C7 7.225 6.90217 6.9895 6.7065 6.7935C6.5105 6.59783 6.275 6.5 6 6.5C5.725 6.5 5.48967 6.59783 5.294 6.7935C5.098 6.9895 5 7.225 5 7.5C5 7.775 5.098 8.0105 5.294 8.2065C5.48967 8.40217 5.725 8.5 6 8.5ZM4.5 4H7.5V3C7.5 2.58333 7.35417 2.22917 7.0625 1.9375C6.77083 1.64583 6.41667 1.5 6 1.5C5.58333 1.5 5.22917 1.64583 4.9375 1.9375C4.64583 2.22917 4.5 2.58333 4.5 3V4Z" fill={(currentElementId == 'new-password' || newPassword ? "#055075" : "#6d6d6d")} />
                    </svg>
                    <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'new-password' || newPassword) ? "10px" : "14px", color: (currentElementId == 'new-password' || newPassword) ? "#055075" : "", transition: "180ms" }}>New Password</p>
                  </div>
                  <input onChange={handleChange('newpassword')} value={newPassword} className={`${(currentElementId == 'new-password' || newPassword) ? "active-input-field" : "inactive-input-field"}`} type="text" />
                </div>
                <div onClick={toggleInputFields('confirm-password')} className={`${(currentElementId == 'confirm-password' || confirmPassword) ? "member-global-input-field m-b-20 flex-item" : "member-global-input-field flex-item m-b-20 d-flex"}`} style={{ border: (currentElementId == 'confirm-password' || confirmPassword) ? "1px solid #055075" : "" }}>
                  <div className="flex-item d-flex">
                    <svg className='m-r-4 flex-item' width={(currentElementId == 'confirm-password' || confirmPassword) ? "12" : "18"} height={(currentElementId == 'confirm-password' || confirmPassword) ? "12" : "18"} style={{ transition: "180ms" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 11C2.725 11 2.48967 10.9022 2.294 10.7065C2.098 10.5105 2 10.275 2 10V5C2 4.725 2.098 4.4895 2.294 4.2935C2.48967 4.09783 2.725 4 3 4H3.5V3C3.5 2.30833 3.74383 1.71867 4.2315 1.231C4.71883 0.743667 5.30833 0.5 6 0.5C6.69167 0.5 7.28133 0.743667 7.769 1.231C8.25633 1.71867 8.5 2.30833 8.5 3V4H9C9.275 4 9.5105 4.09783 9.7065 4.2935C9.90217 4.4895 10 4.725 10 5V10C10 10.275 9.90217 10.5105 9.7065 10.7065C9.5105 10.9022 9.275 11 9 11H3ZM6 8.5C6.275 8.5 6.5105 8.40217 6.7065 8.2065C6.90217 8.0105 7 7.775 7 7.5C7 7.225 6.90217 6.9895 6.7065 6.7935C6.5105 6.59783 6.275 6.5 6 6.5C5.725 6.5 5.48967 6.59783 5.294 6.7935C5.098 6.9895 5 7.225 5 7.5C5 7.775 5.098 8.0105 5.294 8.2065C5.48967 8.40217 5.725 8.5 6 8.5ZM4.5 4H7.5V3C7.5 2.58333 7.35417 2.22917 7.0625 1.9375C6.77083 1.64583 6.41667 1.5 6 1.5C5.58333 1.5 5.22917 1.64583 4.9375 1.9375C4.64583 2.22917 4.5 2.58333 4.5 3V4Z" fill={(currentElementId == 'confirm-password' || confirmPassword ? "#055075" : "#6d6d6d")} />
                    </svg>
                    <p className="placeholder-txt flex-item" style={{ fontSize: (currentElementId == 'confirm-password' || confirmPassword) ? "10px" : "14px", color: (currentElementId == 'confirm-password' || confirmPassword) ? "#055075" : "", transition: "180ms" }}>Confirm Password</p>
                  </div>
                  <input onChange={handleChange('confirmPassword')} value={confirmPassword} className={`${(currentElementId == 'confirm-password' || confirmPassword) ? "active-input-field" : "inactive-input-field"}`} type="text" />
                </div>
              </div>
              <div onClick={isButtonLock?null:savePassword} style={{position: "absolute", bottom: 0, width: "90%"}} className="member-button-global btn-center w-full d-flex m-b-16 flex-item">
                <p className='flex-item'>Reset Password</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return(
      <div className="login-window">
        <div className="login-container">
          <div className="login-background">
            <img className="background-img" alt="bg-img-rest-pswd" src={Background} />
          </div>
          <div className="login-page">
            <img className="logo-instafit" src={Logo} />
            <div className="welcome-message m-b-20">Reset Password</div>
            {/* <div className="welcome-quote"></div> */}
            <div className="header-divider" />
            <div className="input-field-container">
              <input onChange={handleChange('newpassword')} value={newPassword} className="input-field" id="newpassword" type="password" placeholder="New Password" />
            </div>
            <div className='m-t-20' />
            <div className="input-field-container">
              <input onChange={handleChange("confirmpassword")} value={confirmPassword} className="input-field" id="confirmpassword" type="password" placeholder="Confirm Password" />
            </div>
            <div className="signin-action-container">
              <div onClick={isButtonLock?null:savePassword} className={`signin-button ${isButtonLock &&`signin-button-inactive`}`}>
                <div className="signin-text">
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ResetPassword);