import React, { useEffect } from 'react';
import {Switch, Route, HashRouter} from "react-router-dom";
import Signin from "./core/Signin";
import SignUp from './core/Signup';
import ResetPassword from './core/ResetPassword';
import AdminUserRoutes from './auth/AdminUserRoutes';
import MemberRoute from './auth/MemberRoute';
import Gym from './gym/ManageGym';
import GymMob from './gym/mobile/ManageGym';
import GymDashboard from './gym/Dashboard';
import Branch from './branch/ManageBranch';
import BranchMob from './branch/mobile/ManageBranch';
import ManageContent from './components/content/ManageContent';
import ManageContentMob from './components/content/mobile/ManageContent';
import ContentView from './components/content/ContentViewer';
// import ExerciseType from './components/content/ManageExerciseType';
// import ExerciseLevel from './components/content/ManageExerciseLevel';
// import TargetMuscle from './components/content/ManageTargetMuscle';
// import TestDashboard from './components/TestDashboard';
import BranchAdmin from './components/admin/ManageBranchInstructor';
import Member from './member/manageMember';
import MemberMob from './member/mobile/manageMember';
// import MemberTestDashboard from './member/memberTestDashboard';
import ManagePlanner from './components/planner/ManagePlanner';
import PlannerDashboard from './components/planner/PlannerDashboard';
// import WorkoutReportDashboard from './member/workoutReportDashboard';
// import PlannerHistoryDashboard from './member/plannerHistoryDashboard';
import GymProfile from './gym/GymProfile';
import BranchProfile from './branch/BranchProfile';
import Appointment from './components/Appointment/MemberManageAppointment';
import AppointmentMob from './components/Appointment/MemberManageAppointmentMob';
import ManageAppointment from './components/Appointment/ManageAppointment';
import MemberAppointmentView from './member/memberAppointment';

//new components
import Parameters from './components/Parameters/Parameters';
import ParametersMob from './components/Parameters/ParameterMob';
import MemberProfile from './member/MemberProfile';
import memberReport from './member/memberReport';
import MemberDashboardMob from './member/mobile/memberDashboard';
import MemberDashboardDesktop from './member/desktop/memberDashboard';
import UserDashboardMob from './user/Mobile/UserDashboard';
import UserDashboardDesktop from './user/Desktop/UserDashboard';
import Scanner from './core/Scanner';
import MemberWorkoutSchedule from './member/mobile/memberWorkoutSchedule';
import ManageExercise from './components/planner/manageExercise';
import AddExercise from './components/planner/addExercise';
import MemberRegistration from "./member/mobile/memberRegistration";
import ManageUsers from "./user/ManageUsers";
import ManageUsersMob from './user/ManageUsersMob';
import UserRegistration from './core/UserSignup';
import SetPassword from './core/SetPassword';
import PhysiologicalParameter from './member/desktop/physiologicalParameter';
import FitnessMeasurement from './member/desktop/fitnessMeasurement';
import FitnessTest from './member/desktop/fitnessTest';
import WorkoutHistory from './member/desktop/workoutHistory';
import MemberHistory from './member/mobile/memberHistory';
import SmartScreensDashboardDesktop from './components/smartScreen/Desktop/ManageScreens';
import SmartScreensDashboardMobile from './components/smartScreen/Mobile/ManageScreens';
// import MemberNotificationDesktop from './member/desktop/memberNotification';
// import MemberNotificationMobile from './member/mobile/memberNotification';
// import io from "socket.io-client";
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom';
import UserNotificationMob from './components/Notification/mobileNotification';
import MemberReports from './member/desktop/memberReports';
import MobMemberReports from './member/mobile/memberReports';
// const SocketEndpoint = "http://localhost:5000";

import PrivacyPolicy from './privacy-policy/privacyPolicy';
import TermsAndConditions from './terms-and-condititons/termsAndCondition';
import Home from './home';
import ManageEnquiryDesktop from './components/enquiry/desktop/manageEnquiry';
import ManageEnquiryMobile from './components/enquiry/mobile/manageEnquiry';

// Enquiry Form
import Enquiry from './components/enquiry';

                                           

const Routes = () => {


    return (
        <BrowserRouter>
            <Switch>
                {/* <Route path="/" exact component={Home} /> */}
                <Route path="/scanner" exact component={Scanner}/>
                <Route path={["/signin","/"]} exact component={Signin}/>
                {/* <Route path="/signup" exact component={SignUp}/> */}
                <Route path="/resetpassword/:token" exact component={ResetPassword} />
                <Route path="/setpassword/:token" exact component={SetPassword}/>
                <Route path="/user/registration" exact component={UserRegistration} />
                <AdminUserRoutes path="/user/Dashboard" exact component={window.innerWidth < 549 ? UserDashboardMob : UserDashboardDesktop}/> 
                <AdminUserRoutes path="/admin/gym" exact component={window.innerWidth < 549 ? GymMob : Gym}/>
                <AdminUserRoutes path="/gym/profile/:gymId" exact component={GymProfile} />
                <AdminUserRoutes path="/branch/profile/:branchId" exact component={BranchProfile}/>
                <AdminUserRoutes path="/admin/branch" exact component={window.innerWidth < 549 ? BranchMob : Branch}/>
                <AdminUserRoutes path="/contents/:gymId?/:branchId?/:memberId?" exact component={window.innerWidth < 549 ? ManageContentMob : ManageContent}/>
                <AdminUserRoutes path="/content/:contentId" exact component={ContentView}/>
                {/* <AdminUserRoutes path="/:itemId/exercise-type" exact component={ExerciseType}/>
                <AdminUserRoutes path="/:itemId/exercise-level" exact component={ExerciseLevel}/>
                <AdminUserRoutes path="/:itemId/target-muscle" exact component={TargetMuscle}/>
                <AdminUserRoutes path="/:itemId/test-list" exact  component={TestDashboard}/> */}
                <AdminUserRoutes path="/members" exact component={window.innerWidth < 549 ? MemberMob : Member}/>
                <AdminUserRoutes path="/member/details/:memberId" exact component={window.innerWidth < 549 ? MobMemberReports : MemberReports}/>
                {/* <AdminUserRoutes path="/member/tests/:gymId/:branchId/:memberId" exact component={MemberTestDashboard}/> */}
                <AdminUserRoutes path="/planners" exact component={PlannerDashboard}/>
                <AdminUserRoutes path="/planner/:plannerId" exact component={ManagePlanner}/>
                {/* <AdminUserRoutes path="/workout/reports/:branchId/:memberId" exact component={WorkoutReportDashboard}/> */}
                <AdminUserRoutes path="/branch/admin/users" exact component={BranchAdmin}/>
                <AdminUserRoutes path="/gym/dashboard" exact component={GymDashboard}/>
                {/* <AdminUserRoutes path="/planner/historys/:branchId/:memberId" exact component={PlannerHistoryDashboard}/>             */}
                <AdminUserRoutes path="/manage/appointment/:gymId?/:branchId?/:memberId?" exact component={window.innerWidth < 549 ? AppointmentMob : Appointment}/>
                <AdminUserRoutes path="/member/appointment/:memberId" exact component={MemberAppointmentView}/>
                <AdminUserRoutes path="/appointment" exact component={ManageAppointment}/>
                {/* new routes */}
                <AdminUserRoutes path="/member/histoxry" exact component={MemberHistory} />
                <AdminUserRoutes path="/member/profile/:memberId" exact component={MemberProfile} />
                <AdminUserRoutes path="/member-report" exact component={memberReport} />
                <AdminUserRoutes path="/parameters/:userId" exact component={Parameters} />
                <AdminUserRoutes path="/parameters" exact component={window.innerWidth < 549 ? ParametersMob : Parameters} />
                <AdminUserRoutes path="/users" exact component={window.innerWidth < 549 ? ManageUsersMob : ManageUsers} />
                <AdminUserRoutes path="/members/physiological_parameter_and_body_composition/:memberID" exact component={PhysiologicalParameter}/>
                <AdminUserRoutes path="/members/fitness_measurement/:memberID" exact component={window.innerWidth > 549 && FitnessMeasurement} />
                <AdminUserRoutes path="/members/fitness_test/:memberID" exact component={window.innerWidth > 549 && FitnessTest} />
                <AdminUserRoutes path="/members/workout_history/:memberID" exact component={window.innerWidth > 549 && WorkoutHistory} />
                <AdminUserRoutes path="/members/history/:memberID" exact component={MemberHistory} />
                <MemberRoute path="/member/Dashboard" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} /> 
                <MemberRoute path="/member/workout-chart" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                <MemberRoute path="/member/reports" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                <MemberRoute path="/member/appointment" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                <MemberRoute path="/member/notification" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                <MemberRoute path="/member/workout-history" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                <MemberRoute path="/member/profile" exact component={MemberDashboardMob} />
                <MemberRoute path="/member/schedule/workout" exact component={MemberWorkoutSchedule} />
                <MemberRoute path="/member/planner/exercise" exact component={ManageExercise} />
                <MemberRoute path="/member/add/exercise" exact component={AddExercise} />
                <Route path={["/member/registration","/member/account-setup/:memberId/:token"]} exact component={MemberRegistration} />
                <AdminUserRoutes path="/screens" exact component={window.innerWidth > 549 ? SmartScreensDashboardDesktop : SmartScreensDashboardMobile}/>
                <Route path="/notifications" exact component={window.innerWidth > 549 ? null : UserNotificationMob}/>
                <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
                <Route path="/terms-condition" exact component={TermsAndConditions}/>
                <Route path="/enquiry/:branchId" exact component={Enquiry}/>
                <Route path="/enquiries" exact component={window.innerWidth < 549 ? ManageEnquiryMobile : ManageEnquiryDesktop} />
            </Switch>
        </BrowserRouter>
    )
};

export default Routes;