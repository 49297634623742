import { createSlice } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState:{
    value:{
      name:"",
      email:"",
      profileID:""
    }
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.value={
        ...state.value,
        ...action.payload
      };
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer